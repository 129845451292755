<template>
  <HeroSuccess v-if="showSuccess"/>
  <HeroOffer v-else />
</template>

<script>
import HeroOffer from '../../components/webv2/gifting/HeroOffer';
import HeroSuccess from '../../components/webv2/gifting/HeroSuccess';

export default {
  components: {
    HeroOffer,
    HeroSuccess,
  },
  data() {
    return {
      showSuccess: false,
      metaTitle: 'Give the Gift of Luminary Original Podcasts',
      metaDescription: 'Gift an annual subscription to Luminary Original Podcasts. You pick the date and we’ll email your gift recipient with instructions on how to redeem.',
    };
  },
  beforeMount() {
    this.checkQuery();
    this.setMetaInformation(this.metaTitle, this.metaDescription);
  },
  mounted() {
    // hack to for navbar
    const navbarElem = document.getElementById("app-navbar");
    if (navbarElem && navbarElem.className) navbarElem.className = "app-navbar";
  },
  destroyed() {
    this.setMetaInformation();
  },
  methods: {
    checkQuery() {
      if (this.$route.query.giftSent === 'true') {
        this.setShowSuccess(true);
      }
    },
    setShowSuccess(bool) {
      this.showSuccess = bool;
    },
  },
};

</script>
