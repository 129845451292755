<template>
  <el-dialog
    :visible.sync="display_modal"
    :center="true"
    :append-to-body="true"
    width="32%"
    class="premium-content-modal"
    data-test="modal-premiumcontent"
    @close="emitEvent"
  >
    <el-row>
      <el-col
        :span="24"
        justify="center"
        align="center"
      >
        <h3>This show is a <br> Luminary Original</h3>
        <p>Subscribe to listen.</p>
        <el-button
          class="btn-brand large"
          @click="handleClick">{{ (!user || user && user.is_eligible_for_free_trial ) ? 'Start Free Trial' : 'Go Premium' }}</el-button>
        <!-- <span>{{ formattedLocalPricingWithTax }}</span> -->
        <router-link
          to=""
          aria-label="Learn more"
          class="btn-link">Learn More</router-link>
      </el-col>
    </el-row>
  </el-dialog>
</template>
<script>
export default {
  name: 'PremiumContentModal',
  props: {
    show_modal: {
      default: false,
      type: Boolean,
    },
  },
  computed: {
    display_modal: {
      get() {
        return this.show_modal;
      },
      set() {

      },
    },
    user() {
      return this.$store.getters.userSubscription;
    },
  },
  methods: {
    handleClick() {
      this.emitEvent();
      this.handleCTARouting();
    },
    emitEvent() {
      this.$emit('hidePremiumModal');
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../assets/scss/variables.scss";
.premium-content-modal {
  border-radius: 8px;
  .el-dialog {
    background-color: $dark-background;
    .el-dialog__body {
      h3 {
        font-size: 28px;
        margin: 0 0;
        line-height: 36px;
        color: #ffffff;
        font-weight: 500;
      }
      p {
        margin: 16px 0 32px 0;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        color: #ffffff;
      }
      .el-button {
        font-family: $clear-sans;
        width: 85%;
      }
      span {
        font-size: 14px;
        display: block;
        margin: 8px 0 20px 0;
        color: $gray;
      }
      a:not(.el-button) {
        color: #ffffff;
        display: block;
        font-size: 14px;
        line-height: 20px;
        padding-bottom: 25px;
        margin-top: 24px;
      }
    }
  }
}
</style>
