<template>
  <router-link
    v-if="podcast.uuid"
    :to="`/listen/${podcast.publisher.slug}/${podcast.slug}/${podcast.uuid}`"
    :class="{
      'exclusive-podcast-basic': displayType === 'basic',
      'exclusive-podcast-expanded': displayType === 'expanded',
      'exclusive-podcast-tile': displayType === 'tile',
    }"
    data-test="exclusive-podcast"
    @click.native="onPodcastClick()"
  >
    <div
      v-if="displayType === 'basic'"
      class="content">
      <picture-component :src="podcast.display_image_url" />
      <div class="gradient-overlay"/>
      <p
        class="title">
        {{ podcast.title }}
      </p>
    </div>
    <div
      v-if="displayType === 'tile'"
      class="content">
      <picture-component :src="podcast.display_image_url" />
      <p
        class="title">
        {{ podcast.title }}
      </p>
    </div>
    <div
      v-if="displayType === 'expanded'"
      class="content">
      <picture-component :src="podcast.display_image_url" />
      <div class="text-content">
        <p
          class="card-title">
          {{ podcast.title }}
        </p>
        <span :class="['card-description', { 'truncate-description': logTitleOrLongDescription } ]">
          {{ description }}
        </span>
        <a class="card-link">See more</a>
      </div>
    </div>
  </router-link>
</template>

<script>
import Event from '../services/PlayerEvent';

export default {
  props: {
    podcast: {
      type: Object,
      default: () => {},
    },
    displayType: {
      type: String,
      default: () => 'basic',
    },
    bucket: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      eventProvider: new Event(),
    };
  },
  computed: {
    description() {
      const { podcast } = this;
      const descriptionText = podcast.short_description || podcast.description || 'A Luminary Exclusive';
      return `${descriptionText.slice(0, 150)}${descriptionText.length > 150 ? '..' : ''}`;
    },
    logTitleOrLongDescription() {
      const longTitle = this.podcast.title.length > 35;
      const longDescription = this.podcast.description.length > 120;
      return this.isMobile && (longTitle || longDescription);
    },
    isMobile() {
      return (window && window.innerWidth) <= this.screenSize.sm;
    },
  },
  methods: {
    onPodcastClick() {
      this.triggerSegmentEvent();
    },
    triggerSegmentEvent() {
      const bucketData = { ...this.bucket };
      bucketData.items = {};

      const bucketItemData = { ...this.podcast };
      bucketItemData.item_position = this.$vnode.key;

      this.eventProvider.triggerContentSelectEvent(bucketData, bucketItemData);
    },
  },
};
</script>

<style scoped lang="scss">
  @import "../assets/scss/variables.scss";
  @import "../assets/scss/mixins.scss";

  .exclusive-podcast-basic {
    height: 250px;
    position: relative;

    display: block;

    .content {
      width: 100%;
      height: 100%;
      .title {
        font-size: 16px;
        color: white;

        width: 100%;

        position: absolute;
        bottom: 12px;
        left: 16px;
      }

      .picture-component {
        > img {
          object-fit: cover;
        }
      }
      .gradient-overlay {
        height: 50%;
        width: 100%;

        position: absolute;

        bottom: 0;
        left: 0;

        background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0, 0, 0, 0.5) 100%);
      }
    }


    @media (max-width: 1024px) {
      height: 268px;
    }

    @media (max-width: 768px) {
      height: 200px;
    }

    @media (max-width: 768px) {
      height: 200px;
    }

    @media (max-width: 567px) {
      height: 181px;
    }
  }

  .exclusive-podcast-tile {
    height: 165px;
    width: 165px;
    margin: 0 0 65px 0;
    position: relative;
    display: inline-block;

    .content {
      width: 100%;
      height: 100%;
      overflow-y: visible;
      .title {
        font-family: $clear-sans;
        font-size: 15px;
        line-height: 20px;
        text-align: center;
        color: #111111;
        margin: 5px 0 0;
      }
      .picture-component {
        > img {
          object-fit: cover;
        }
      }
    }

    @media (min-width: 1024px) {
      height: 191px;
      width: 191px;
    }

    @media (min-width: 576px) {
      height: 191px;
      width: 191px;
      margin-bottom: 80px;
    }

    /* @media (max-width: 768px) {
      height: 200px;
    } */
  }

  .exclusive-podcast-expanded {
    background-color: #fff;
    display: block;
    position: relative;
    @media (min-width: 1600px) {
      width: 252px;
      height: 341px;
      margin-right: 16px;
    }
    @media (min-width: 768px) {
      width: 242px;
      height: 345px;
      margin-right: 16px;
    }
    @media (max-width: 767px) {
      width: 146px;
      height: 300px;
      margin-right: 16px;
    }
    .text-content {
      color: #363636;
      @media (min-width: 768px) {
        padding: 15px 12px 20px 12px;
      }
      padding: 12px 11px 20px 11px;
      .card-title {
        color: #363636;
        font-family: $clear-sans;
        margin: 0 0 8px;
        font-weight: 600;
        font-size: 12px;
        line-height: 18px;
        @media (min-width: 768px) {
          font-size: 15px;
          line-height: 20px;
        }
      }
      .card-description {
        font-family: $clear-sans;
        margin: 0px;
        display: block;
        font-size: 11px;
        line-height: 15px;
        @media (min-width: 768px) {
          font-size: 14px;
          line-height: 17px;
          max-height: 85px;
          margin-top: 8px;
        }
      }
      .truncate-description {
        @media (max-width: 768px) {
          /* max-height: 80px; */
          /* overflow-y: hidden; */
          @include multilinetruncate(11px, 15px, 5);
        }
      }
      .card-link {
        color: #363636;
        position: absolute;
        bottom: 20px;
        left: 12px;
        font-family: $clear-sans;
        font-size: 14px;
        line-height: 17px;
        border-bottom: 2px solid #363636;
        padding-bottom: 2px;
        &:hover {
          color: #00AECB;
          border-bottom-color: #00AECB;
        }
      }
    }
    .picture-component {
      @media (min-width: 1600px) {
        height: 151px;
      }
    }
  }
</style>
