<template>
  <div
    :data-podcast-uuid="show_data && show_data.uuid ? show_data.uuid : ''"
    :class="`show-details detail-page theme-background ${isShowExclusive ? 'is-exclusive' : ''}`"
    data-test="page-showdetail">
    <PremiumBanner
      v-if="show_data && show_data.uuid && show_data.is_exclusive && showPremiumBanner && data_loaded"
      :show="show_data"
      :title="show_data.title"
    />
    <loader
      v-show="!data_loaded"
      :center="true"
      label="Fetching Podcast Details.."
    />
    <div
      v-if="isShowExclusive"
      class="content-wrapper"
    >
      <div
        class="cover-section"
      >
        <div class="container">
          <el-row
            type="flex"
            align="middle"
            class="breadcrumb-and-social-icons">
            <el-col>
              <Breadcrumb
                v-if="breadcrumbTrail.length"
                :trail="breadcrumbTrail"
              />
            </el-col>
            <el-col :span="12">
              <SocialShareDialog
                v-if="!isMobileScreen"
                :show-data="show_data"
                type="podcast"
              />
            </el-col>
          </el-row>
          <div class="cover-section__content">
            <div class="show-image">
              <picture-component
                :src="show_data.display_image_url"
                :progressive-load="true"
                data-test="show-image"
              />
              <PremiumIndicator
                v-if="show_data.is_exclusive"
                size="x-large" />
            </div>

            <div class="cover-section__content__text">
              <h1
                class="show-title"
                data-test="showtitle">{{ show_data.title | truncate(82) }}
              </h1>
              <p
                v-if="show_data.publisher"
                class="publisher"
                data-test="publishertitle"
              >
                By <router-link
                  :to="`/listen/${show_data.publisher.slug}/${show_data.publisher.uuid}?page=1`"
                  class="text-blue"> {{ show_data.publisher.name }}</router-link>
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="content-wrapper__details">
        <div class="container">

          <div class="description">
            <p
              v-if="showingMoreDescription"
              data-test="show-description"
              v-html="$sanitize(showDescription)"
            />
            <p
              v-if="!showingMoreDescription"
              data-test="show-description"
              v-html="$sanitize($options.filters.truncate(showDescription, 400, min_truncate_threshold))"
            />
            <el-button
              v-if="showDescription.length > (400 + min_truncate_threshold)"
              class="read-more"
              type="text"
              @click="toggleDescription"
            >
              Read {{ showingMoreDescription ? 'Less' : 'More' }}
              <i :class="`el-icon-arrow-${showingMoreDescription ? 'up' : 'down'}`" />
            </el-button>
          </div>

          <div
            class="interact"
          >

            <el-button
              v-if="isLoggedIn && ((isShowExclusive && hasAccessToPremium) || !isShowExclusive || hasFreeEpisodes)"
              :icon="!inUserLibrary || !inUserLibrary.uuid ? 'el-icon-plus': 'el-icon-check'"
              :loading="adding_podcast"
              :class="`${!inUserLibrary || !inUserLibrary.uuid ? '' : 'added-in-library-btn'}`"
              type="primary"
              class="icon-button btn-primary"
              data-test="btn-addtomyshow"
              @click="addShowToLibrary(show_data.uuid)">
              {{ inUserLibrary && inUserLibrary.uuid ? 'My Shows' : 'My Shows' }}
            </el-button>

            <el-button
              v-if="!isLoadingTrailer && (season_trailers && season_trailers.length)"
              :class="isLoggedIn? 'btn-primary-transparent' : ''"
              type="primary"
              class="m-r play-button icon-button"
              @click="playTrailer">
              <img
                v-if="!isPlayingTrailer && !isLoggedIn"
                class="button-icon"
                src="./../assets/images/ic_play_symbol_white.svg">
              <img
                v-if="!isPlayingTrailer && isLoggedIn"
                class="button-icon"
                src="./../assets/images/ic_play_blue.svg">
              <img
                v-if="isPlayingTrailer && !isLoggedIn"
                class="button-icon"
                src="./../assets/images/ic_pause_white_icon.svg">
              <img
                v-if="isPlayingTrailer && isLoggedIn"
                class="button-icon"
                src="./../assets/images/ic_pause_blue.svg">
              <span>{{ isPlayingTrailer ? 'Pause Trailer' : 'Play Trailer' }}</span>
            </el-button>

            <el-button
              v-if="isLoadingTrailer && (season_trailers && season_trailers.length)"
              type="primary"
              class="m-r icon-button"
            >
              <i class="el-icon-loading"/>
              <span data-test="btn-playtrailer">Play Trailer</span>
            </el-button>
          </div>
          <rating
            v-if="isLoggedIn && ((isShowExclusive && hasAccessToPremium) || !isShowExclusive || hasFreeEpisodes)"
            :show_like="true"
            :show_dislike="true"
            :podcast_id="show_data.uuid"
            type="show"
            data-test="rating"/>
        </div>
      </div>
    </div>
    <div
      v-if="isShowExclusive"
      class="content-wrapper is-mobile"
    >
      <!-- <PodcastCover
        v-if="isShowExclusive"
        :src="show_data.display_image_url"
      /> -->
      <div
        v-if="data_loaded && show_data && show_data.uuid"
        class="show-details__info container"
      >
        <el-row
          type="flex"
          align="middle"
          class="breadcrumb-and-social-icons">
          <el-col>
            <Breadcrumb
              v-if="breadcrumbTrail.length"
              :trail="breadcrumbTrail"
            />
          </el-col>
          <el-col :span="12">
            <SocialShareDialog
              v-if="isMobileScreen"
              :show-data="show_data"
              type="podcast"
            />
          </el-col>
        </el-row>
        <div class="show-image">
          <picture-component
            :src="show_data.display_image_url"
            :progressive-load="true"
            data-test="show-image"
          />
          <PremiumIndicator
            v-if="show_data.is_exclusive"
            size="x-large" />
        </div>

        <div class="text">
          <h2
            class="show-title"
            data-test="showtitle">{{ show_data.title | truncate(82) }}
          </h2>
          <p
            v-if="show_data.publisher"
            class="publisher"
            data-test="publishertitle"
          >
            By <router-link
              :to="`/listen/${show_data.publisher.slug}/${show_data.publisher.uuid}?page=1`"
              class="text-blue"> {{ show_data.publisher.name }}</router-link>
          </p>

          <div class="description">
            <p
              v-if="showingMoreDescription"
              data-test="show-description"
              v-html="$sanitize(showDescription)"
            />
            <p
              v-if="!showingMoreDescription"
              data-test="show-description"
              v-html="$sanitize($options.filters.truncate(showDescription, 400, min_truncate_threshold))"
            />
            <el-button
              v-if="showDescription.length > (400 + min_truncate_threshold)"
              class="read-more"
              type="text"
              @click="toggleDescription"
            >
              Read {{ showingMoreDescription ? 'Less' : 'More' }}
              <i :class="`el-icon-arrow-${showingMoreDescription ? 'up' : 'down'}`" />
            </el-button>
          </div>

          <div
            :class="{'interact': isLoggedIn}"
          >

            <el-button
              v-if="isLoggedIn && ((isShowExclusive && hasAccessToPremium) || !isShowExclusive || hasFreeEpisodes)"
              :icon="!inUserLibrary || !inUserLibrary.uuid ? 'el-icon-plus': 'el-icon-check'"
              :loading="adding_podcast"
              :class="`${!inUserLibrary || !inUserLibrary.uuid ? '' : 'added-in-library-btn'}`"
              type="primary"
              class="icon-button btn-primary"
              data-test="btn-addtomyshow"
              @click="addShowToLibrary(show_data.uuid)">
              {{ inUserLibrary && inUserLibrary.uuid ? 'My Shows' : 'My Shows' }}
            </el-button>

            <el-button
              v-if="!isLoadingTrailer && (season_trailers && season_trailers.length)"
              :class="isLoggedIn? 'btn-primary-transparent' : ''"
              type="primary"
              class="play-button icon-button"
              @click="playTrailer">
              <img
                v-if="!isPlayingTrailer && !isLoggedIn"
                class="button-icon"
                src="./../assets/images/ic_play_symbol_white.svg">
              <img
                v-if="!isPlayingTrailer && isLoggedIn"
                class="button-icon"
                src="./../assets/images/ic_play_blue.svg">
              <img
                v-if="isPlayingTrailer && !isLoggedIn"
                class="button-icon"
                src="./../assets/images/ic_pause_white_icon.svg">
              <img
                v-if="isPlayingTrailer && isLoggedIn"
                class="button-icon"
                src="./../assets/images/ic_pause_blue.svg">
              <span>{{ isPlayingTrailer ? 'Pause Trailer' : 'Play Trailer' }}</span>
            </el-button>

            <el-button
              v-if="isLoadingTrailer && !isLoggedIn && (season_trailers && season_trailers.length)"
              type="primary"
              class="m-r btn-transparent icon-button"
            >
              <i class="el-icon-loading"/>
              <span data-test="btn-playtrailer">Play Trailer</span>
            </el-button>
          </div>
          <rating
            v-if="isLoggedIn && ((isShowExclusive && hasAccessToPremium) || !isShowExclusive || hasFreeEpisodes)"
            :show_like="true"
            :show_dislike="true"
            :podcast_id="show_data.uuid"
            type="show"
            data-test="rating"/>
        </div>
      </div>
    </div>
    <div
      v-if="!isShowExclusive"
      class="content-wrapper"
    >
      <div class="container">
        <el-row
          type="flex"
          align="middle"
          class="breadcrumb-and-social-icons">
          <el-col>
            <Breadcrumb
              v-if="breadcrumbTrail.length"
              :trail="breadcrumbTrail"
            />
          </el-col>
          <el-col :span="12">
            <SocialShareDialog
              v-if="data_loaded"
              :show-data="show_data"
              type="podcast"
            />
          </el-col>
        </el-row>
        <div
          v-if="data_loaded && show_data && show_data.uuid"
          class="show-details__info"
        >
          <div class="show-image">
            <picture-component
              :src="show_data.display_image_url"
              :progressive-load="true"
              data-test="show-image"
            />
          </div>

          <div class="text">
            <h1
              class="show-title"
              data-test="showtitle">{{ show_data.title | truncate(82) }}
            </h1>
            <p
              v-if="show_data.publisher"
              class="publisher full-width"
              data-test="publishertitle"
            >
              By <router-link
                :to="`/listen/${show_data.publisher.slug}/${show_data.publisher.uuid}?page=1`"
                class="text-blue"> {{ show_data.publisher.name }}</router-link>
            </p>

            <div class="description">
              <p
                v-if="showingMoreDescription"
                data-test="show-description"
                v-html="$sanitize(showDescription)"
              />
              <p
                v-if="!showingMoreDescription"
                data-test="show-description"
                v-html="$sanitize($options.filters.truncate(showDescription, 400, min_truncate_threshold))"
              />
              <el-button
                v-if="showDescription.length > (400 + min_truncate_threshold)"
                class="read-more"
                type="text"
                @click="toggleDescription"
              >
                Read {{ showingMoreDescription ? 'Less' : 'More' }}
                <i :class="`el-icon-arrow-${showingMoreDescription ? 'up' : 'down'}`" />
              </el-button>
            </div>

            <div
              class="interact"
            >
              <el-button
                v-if="isLoggedIn && ((isShowExclusive && hasAccessToPremium) || !isShowExclusive || hasFreeEpisodes)"
                :icon="!inUserLibrary || !inUserLibrary.uuid ? 'el-icon-plus': 'el-icon-check'"
                :loading="adding_podcast"
                :class="`${!inUserLibrary || !inUserLibrary.uuid ? '' : 'added-in-library-btn'}`"
                type="primary"
                class="icon-button btn-primary"
                data-test="btn-addtomyshow"
                @click="addShowToLibrary(show_data.uuid)">
                {{ inUserLibrary && inUserLibrary.uuid ? 'My Shows' : 'My Shows' }}
              </el-button>

              <el-button
                v-if="!isLoadingTrailer && (season_trailers && season_trailers.length)"
                :class="isLoggedIn? 'btn-primary-transparent' : ''"
                type="primary"
                class="m-r btn-primary play-button icon-button"
                @click="playTrailer">
                <img
                  v-if="!isPlayingTrailer && !isLoggedIn"
                  class="button-icon"
                  src="./../assets/images/ic_play_symbol_white.svg">
                <img
                  v-if="!isPlayingTrailer && isLoggedIn"
                  class="button-icon"
                  src="./../assets/images/ic_play_blue.svg">
                <img
                  v-if="isPlayingTrailer && !isLoggedIn"
                  class="button-icon"
                  src="./../assets/images/ic_pause_white_icon.svg">
                <img
                  v-if="isPlayingTrailer && isLoggedIn"
                  class="button-icon"
                  src="./../assets/images/ic_pause_blue.svg">
                <span>{{ isPlayingTrailer ? 'Pause Trailer' : 'Play Trailer' }}</span>
              </el-button>

              <el-button
                v-if="isLoadingTrailer && (season_trailers && season_trailers.length)"
                type="primary"
                class="m-r btn-primary icon-button"
              >
                <i class="el-icon-loading"/>
                <span data-test="btn-playtrailer">Play Trailer</span>
              </el-button>
            </div>
            <rating
              v-if="isLoggedIn && ((isShowExclusive && hasAccessToPremium) || !isShowExclusive || hasFreeEpisodes)"
              :show_like="true"
              :show_dislike="true"
              :podcast_id="show_data.uuid"
              type="show"
              data-test="rating"/>
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="showHolidayPromo"
      class="container promo-container">
      <HolidayPromo
        :promo_variant="'sdpPagePromoCta'"/>
    </div>

    <div
      v-if="data_loaded && episodesList.length"
      class="episodes-count container"
    >
      <p
        data-test="count-episode"
        class="theme-primary-header">{{ totalEpisodes + (totalEpisodes === 1 ? ' Episode' : ' Episodes') }} </p>
      <el-dropdown
        trigger="click"
      >
        <span class="el-dropdown-link">
          {{ filter_name }}
          <i class="el-icon-arrow-down el-icon--right" />
        </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item
            v-for="elem in select_filters"
            :key="elem.val"
            @click.native="onSelect(elem.val)"
          >
            {{ elem.name }}
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>

    <div
      v-show="data_loaded && show_data && show_data.uuid"
      class="episodes-list-container">
      <Loader
        v-if="loadingEpisodes"
        :absolute="true"
        :centralize="true"
      />
      <div class="episodes-list">
        <div v-if="show_data && data_loaded">
          <card-list-wrapper
            :episodes="episodesList"
            :podcast="show_data"
            :show-seasons="true"
            :show-premium-icon="false"
            :bucket="{
              bucket_uuid: null,
              name: 'Episodes',
              bucket_type:'sdp_episodes',
              position: 0,
              items_count: episodesList.length,
              media_size: 'episode_details'
            }"
            playlist-type="show"
          />
        </div>
      </div>
    </div>

    <el-row
      v-if="!no_more_data"
      :class="`container ${totalEpisodes <= page_size ? 'p' : ''}`"
      type="flex"
      justify="center"
    >
      <el-pagination
        v-show="totalEpisodes > page_size"
        v-if="data_loaded"
        :page-size="page_size"
        :total="totalEpisodes"
        :page-count="pager_count"
        layout="prev, pager, next"
        @current-change="loadNextPage($event)"/>
    </el-row>

    <MoreOfCategory
      v-if="show_data && podcastCategory && fetchCategoryPodcasts"
      :category="podcastCategory"
    />
    <el-alert
      v-show="data_loaded && show_data && !show_data.uuid"
      :closable="false"
      class="container"
      title="Podcast details not found."
      type="warning"
      show-icon
      center
    />
    <login-modal
      v-if="show_login"
      :show_modal="show_login"
      @modalClosed="show_login = false"/>
  </div>
</template>

<script>
import * as log from 'loglevel';
import Podcast from '../services/Podcast';
import Episode from '../services/Episode';
import User from '../services/User';
import Branch from '../services/Branch';
import Event from '../services/PlayerEvent';
import pageMixin from '../shared/pageMixin';
import Loader from '../components/Loader';
import CardListWrapper from '../components/CardListWrapper';
import PremiumBanner from '../components/PremiumBanner';
import PremiumIndicator from '../components/PremiumIndicator';
import PodcastCover from '../components/PodcastCover';
import Rating from '../components/Rating';
import DetailPageBreadcrumb from '../components/DetailPageBreadcrumb';
import HolidayPromo from '../components/holidaypromo/HolidayPromo';
import sanitizeHtml from '../shared/sanitizeHtmlString';
import showProvider from '../shared/showProvider';
import nr_analyticsManager from '../shared/new-relic/analyticsManager';
import nr_addPageActions from '../shared/new-relic/addPageActions';

export default {
  name: 'ShowDetails',
  components: {
    Loader,
    CardListWrapper,
    Rating,
    PremiumBanner,
    PremiumIndicator,
    Breadcrumb: DetailPageBreadcrumb,
    PodcastCover,
    HolidayPromo,
    LoginModal: () => import(/* webpackChunkName: "sdp" */ '../components/LoginModal'),
    MoreOfCategory: () => import(/* webpackChunkName: "sdp" */ '../components/MoreOfCategory'),
    PodcastCard: () => import(/* webpackChunkName: "sdp" */ '../components/PodcastCard'),
    PodcastList: () => import(/* webpackChunkName: "sdp" */ '../components/PodcastList'),
    SocialShareDialog: () => import(/* webpackChunkName: "sdp" */ '../components/SocialShare'),
  },
  mixins: [pageMixin],
  data() {
    return {
      pageName: 'Show Detail Page',
      show_data: {},
      data_loaded: false,
      show_nav: true,
      user_service: new User(),
      podcast_service: new Podcast(),
      episode_service: new Episode(),
      branch_service: new Branch(),
      libraryEpisodes: [],
      libraryPodcasts: [],
      relatedPodcasts: [],
      adding_podcast: false,
      podcasts_fetched: false,
      filter_value: 1,
      filter_name: 'Newest',
      show_thumbs_up_rating: false,
      show_thumbs_down_rating: false,
      episode_meta: {},
      episode_key_map: {},
      show_login: false,
      open_share_dialog: false,
      episodesList: [],
      allEpisodes: [],
      current_page: 1,
      page_size: 10,
      sort_order: '',
      pager_count: 7,
      min_truncate_threshold: 20,
      select_filters: [
        {
          name: 'Newest',
          val: 1,
          sortOrder: 'desc',
          sortBy: 'released_at',
          sort_order: 'release_desc',
        },
        {
          name: 'Oldest',
          val: 2,
          sortOrder: 'asc',
          sortBy: 'released_at',
          sort_order: 'release_asc',
        },
      ],
      selected_filter: {
        sortBy: 'released_at',
        sortOrder: 'desc',
      },
      season_trailers: [],
      no_more_data: false,
      totalEpisodes: 0,
      default_current_page: 1,
      breadcrumbTrail: [],
      podcastCategory: null,
      loadingEpisodes: false,
      eventProvider: new Event(),
      showingMoreDescription: false,
      fetchCategoryPodcasts: false,
    };
  },
  computed: {
    showDescription() {
      const { description, description_html } = this.show_data;
      if (!description) {
        return '';
      }
      const descriptionHtml = description_html;
      const descString = descriptionHtml ? sanitizeHtml(descriptionHtml) : description;
      const containsHTML = descriptionHtml && /<\/?[a-z][\s\S]*>/i.test(descriptionHtml);
      const containsATag = /<\/a>/i.test(descriptionHtml);
      return (!containsHTML || !containsATag) ? this.linkifyString(descString) : descString;
    },
    isLoggedIn() {
      return this.$store.getters.loggedIn;
    },
    showPremiumBanner() {
      const { user } = this.user_service;

      if (!user) {
        return true;
      }

      if (user && !user.is_subscribed) {
        return true;
      }

      return false;
    },
    hasAccessToPremium() {
      const { user } = this.user_service;

      if (!user) {
        return false;
      }

      if (user && user.is_subscribed) {
        return true;
      }

      return false;
    },
    isShowExclusive() {
      return this.show_data.is_exclusive;
    },
    inUserLibrary() {
      const userPodcast = this.$store.getters.searchPodcastInLibrary(this.show_data.uuid);
      if (userPodcast && userPodcast.uuid) {
        return userPodcast;
      }
      return null;
    },
    currentlyPlaying() {
      return this.$store.getters.episode;
    },
    isPlayingTrailer() {
      const { play } = this.$store.getters;

      return this.season_trailers.length && play && this.currentlyPlaying && (this.currentlyPlaying.uuid === this.season_trailers[0].uuid);
    },
    isLoadingTrailer() {
      const { status } = this.$store.getters;

      return this.season_trailers.length && status === 'loading' && this.currentlyPlaying && (this.currentlyPlaying.uuid === this.season_trailers[0].uuid);
    },
    hasFreeEpisodes() {
      return this.show_data && this.show_data.has_free_episodes;
    },
    coverImageUrl() {
      const coverImageUrl = this.show_data.display_image_url || '';

      if (coverImageUrl) {
        return `${coverImageUrl}?h=1000&w=1000`;
      }

      return '';
    },
    showHolidayPromo() {
      const { show_data } = this;
      const { promoData } = this.$store.getters;
      const isExclusive = show_data && show_data.uuid && show_data.is_exclusive;
      return promoData && promoData.showHolidayPromo && isExclusive;
    },
    showDetailsFlag() {
      const show_details_endpoint = this.$store.getters.flagWithDefault('show_details_endpoint', true);
      return show_details_endpoint;
    },
    isMobileScreen() {
      return this.currentScreenWidth < this.screenSize.sm;
    },
  },
  beforeMount() {
    if (this.showDetailsFlag) {
      this.getShowDetails();
    } else {
      this.getPodcast();
    }
  },
  mounted() {
    if (window.innerWidth <= 567) {
      this.pager_count = 3;
    }
    setTimeout(() => {
      this.handleOffScreenData();
    });
  },
  destroyed() {
    this.setMetaInformation();
  },
  methods: {
    linkifyString(text) {
      const urlRegex = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig; // eslint-disable-line
      return text.replace(urlRegex, url => `<a href="${url}">${url}</a>`);
    },
    toggleDescription() {
      this.showingMoreDescription = !this.showingMoreDescription;
      setTimeout(() => {
        this.mapTargetBlankToAnchorTags('.show-details__info .description a');
      }, 10);
    },
    getShowDetails() {
      const uuid = this.$route.params.podcastID;
      const params = this.constructPodcastParams(this.current_page, this.page_size);
      nr_analyticsManager.addPageAction(nr_addPageActions.Web_LoadShowDetailsV2);
      showProvider.getShowDetails(uuid, params).then((resp) => {
        this.show_data = resp.show_data;
        this.totalEpisodes = resp.totalEpisodes;
        this.data_loaded = true;
        this.episodesList = resp.episodesList;
        this.loadingEpisodes = false;

        this.getSeasonTrailers();
        this.setPodcastCategory();

        if (this.podcastCategory) {
          this.constructBreadcrumbTrail();
        }
        this.eventProvider.triggerShowDetailsViewedEvent(this.show_data);

        this.setMetaInformation(`${this.show_data.title} | Listen ${this.show_data.is_exclusive ? 'Only' : ''} on Luminary`, this.showDescription);
        nr_analyticsManager.addPageAction(nr_addPageActions.Web_LoadShowDetailsV2Success);
      }).catch(() => {
        this.data_loaded = true;
        this.loadingEpisodes = false;
        nr_analyticsManager.addPageAction(nr_addPageActions.Web_LoadShowDetailsV2Failure);
      });
    },
    constructPodcastParams(current_page, page_size) {
      const sortParams = this.sort_order ? { sort_order: this.sort_order } : null;

      return {
        from: ((current_page - 1) * page_size),
        size: page_size,
        ...sortParams,
      };
    },
    getPodcast() {
      const uuid = this.$route.params.podcastID;
      nr_analyticsManager.addPageAction(nr_addPageActions.Web_LoadShowDetailsV1);
      this.podcast_service.getPodcastById(uuid).then((resp) => {
        this.show_data = resp;
        this.data_loaded = true;

        this.getPodcastEpisodesByPodcastUuid();
        this.getSeasonTrailers();
        this.setPodcastCategory();

        if (this.podcastCategory) {
          this.constructBreadcrumbTrail();
        }
        this.eventProvider.triggerShowDetailsViewedEvent(this.show_data);

        this.setMetaInformation(`${this.show_data.title} | Listen ${this.show_data.is_exclusive ? 'Only' : ''} on Luminary`, this.showDescription);
      }).catch(() => {
        this.data_loaded = true;
      });
    },
    setPodcastCategory() {
      if (this.show_data && this.show_data.categories && this.show_data.categories.length) {
        [this.podcastCategory] = this.show_data.categories;
      } else if (this.show_data && this.show_data.categories && this.show_data.categories.uuid) {
        this.podcastCategory = this.show_data.categories;
      }
    },
    constructBreadcrumbTrail() {
      this.breadcrumbTrail = [
        {
          name: this.podcastCategory.name,
          link: `/listen/category/${this.podcastCategory.slug}/${this.podcastCategory.uuid}`,
        },
        {
          name: this.show_data.title,
        },
      ];
    },
    getPodcastEpisodesByPodcastUuid() {
      const podcastUUID = this.show_data.uuid;
      const params = {
        sortBy: this.selected_filter.sortBy,
        sortOrder: this.selected_filter.sortOrder,
        p: this.current_page++,
        limit: this.page_size,
      };
      this.getPodcastEpisodes(podcastUUID, params, true);
    },
    getSeasonTrailers() {
      this.podcast_service
        .getPodcastTrailers(this.show_data.uuid)
        .then((res) => {
          this.season_trailers = res.trailers || [];
          this.season_trailers = this.season_trailers.map((trailer) => {
            const podcast = {
              ...trailer.season.podcast,
              title: this.show_data.title,
              uuid: this.show_data.uuid,
            };
            trailer.season.podcast = podcast;
            return trailer;
          });
          this.season_trailers = this.season_trailers.sort((a, b) => new Date(b.released_at) - new Date(a.released_at));
        })
        .catch((error) => {
          log.warn(error.message);
        });
    },
    addShowToLibrary(id) {
      this.show_login = !this.isLoggedIn;
      if (this.show_login) return;

      // If user is not premium and show is exclusive, then show PremiumContentDialog.
      this.$store.dispatch('isAllowedPremiumShow', this.show_data);
      if (this.showPremiumModal) return;

      if (this.inUserLibrary && this.inUserLibrary.uuid) {
        this.deletePodcast();
      } else {
        this.adding_podcast = true;
        this.podcast_service
          .addPodcastsToLibrary(id)
          .then((res) => {
            if (res && res.success) {
              this.$store.dispatch('setUserLibraryPodcast', { uuid: res.uuid, podcast_uuid: id });
              this.$set(this.show_data, 'record_id', res.uuid);
            }
            this.adding_podcast = false;

            // this.showSuccessMsg('Successfully added to library.');
            this.eventProvider.triggerMyShowsAddEvent(this.show_data);
          })
          .catch((error) => {
            log.warn(error);
            this.adding_podcast = false;

            this.showErrorMsg('An error occurred. Please try again later.');
          });
      }
    },
    filterSelect() {
      this.select_filters.forEach((elem) => {
        if (this.filter_value === elem.val) {
          this.filter_name = elem.name;
          this.selected_filter.sortBy = elem.sortBy;
          this.selected_filter.sortOrder = elem.sortOrder;
          this.eventProvider.triggerSDPSortEvent(this.show_data, elem.name);
        }
      });
      this.getFilteredData();
    },
    deletePodcast() {
      this.adding_podcast = true;
      this.podcast_service
        .deletePodcastFromLibrary(this.inUserLibrary.uuid)
        .then((res) => {
          if (res && res.success) {
            // this.showSuccessMsg('Podcast deleted from your library.');
            this.$store.dispatch('removeUserLibraryPodcast', this.inUserLibrary.podcast_uuid);
            this.eventProvider.triggerMyShowsRemoveEvent(this.show_data);
          } else {
            this.showErrorMsg('An error occurred. Please try again later.');
          }
          this.adding_podcast = false;
        })
        .catch((error) => {
          this.adding_podcast = false;
          log.warn(error);
        });
    },
    playTrailer() {
      const trailer = this.season_trailers[0];
      trailer.episode_type = 'trailer';
      trailer.display_image_url = this.show_data.display_image_url;
      trailer.playedFrom = 'sdp';
      if (this.currentlyPlaying.uuid === trailer.uuid && this.currentlyPlaying.playedFrom === trailer.playedFrom) {
        const { play } = this.$store.getters;

        if (!play) {
          if (this.currentlyPlaying.episode_type !== 'trailer') {
            this.eventProvider.triggerEpisodePauseEvent(this.currentlyPlaying, 'trailer_select');
          }
          this.$store.dispatch('play');
          this.eventProvider.triggerTrailerPlayEvent(trailer, 'sdp_select');
        } else {
          this.$store.dispatch('pause');
          this.eventProvider.triggerTrailerPauseEvent(trailer, 'sdp_pause');
        }
      } else {
        this.$store.dispatch('setTrailerAsEpisode', false);

        if (this.currentlyPlaying.episode_type !== 'trailer') {
          this.eventProvider.triggerEpisodePauseEvent(this.currentlyPlaying, 'trailer_select');
        }
        this.$store.dispatch('setTrailerExperienceTriggered', false); // Make sure trailer experience does not trigger from here.
        this.loadMediaInPlayer(trailer.media_url, trailer.is_exclusive, trailer.episode_type, true);
        this.$store.dispatch('setNextEpisode', {}); // Empty next episode because playback should stop after trailer complete from SDP.
        this.$store.dispatch('setAndPlayEpisode', trailer);
        this.eventProvider.triggerTrailerPlayEvent(trailer, 'sdp_select');
      }
    },
    loadNextPage(event) {
      this.current_page = event;
      if (this.showDetailsFlag) {
        this.getShowDetails();
      } else {
        this.getPodcastEpisodesByPodcastUuid();
      }
      const episodesListContainer = document.querySelector('.episodes-list-container');
      if (episodesListContainer) {
        episodesListContainer.scrollIntoView({
          behavior: 'smooth',
        });
      }
    },
    getFilteredData() {
      if (this.showDetailsFlag) {
        this.current_page = 1;
        this.getShowDetails();
      } else {
        const podcastUUID = this.$route.params.podcastID;
        const params = {
          sortBy: this.selected_filter.sortBy,
          sortOrder: this.selected_filter.sortOrder,
          p: 1,
          limit: this.page_size,
        };
        this.getPodcastEpisodes(podcastUUID, params, true);
      }
    },
    getPodcastEpisodes(podcastUUID, params, orverride) {
      this.loadingEpisodes = true;
      this.podcast_service
        .getPodcastEpisodesByPodcastUuid(podcastUUID, params)
        .then((res) => {
          if (!res || !res.episodes || !res.episodes.length) {
            this.no_more_data = true;
          }
          this.totalEpisodes = res && res.total ? res.total : 0;
          if (res && res.episodes && res.episodes.length && orverride) {
            this.episodesList = res.episodes;
            this.current_page = 1;
          } else if (res && res.episodes && res.episodes.length && !orverride) {
            this.episodesList = this.episodesList.concat(res.episodes);
          }

          this.loadingEpisodes = false;
          // this.setupEpisodeMeta();
          this.mapTargetBlankToAnchorTags('.show-details__info .description a');
        })
        .catch((error) => {
          log.info(error);
          this.loadingEpisodes = false;
          this.mapTargetBlankToAnchorTags('.show-details__info .description a');
        });
    },
    onSelect(value) {
      this.filter_value = value;
      if (this.showDetailsFlag) {
        this.sort_order = this.select_filters.find(filter => filter.val === value).sort_order;
      }
      this.filterSelect();
    },
    scrollEventHandlerForOffScreenData() {
      this.fetchCategoryPodcasts = true;
      this.removeScroll();
    },
  },
};
</script>
<style lang="scss">
@import "../assets/scss/variables.scss";

.show-details {
  .content-wrapper {
    position: relative;
    padding-bottom: 50px;
    @media (max-width: $xs) {
      padding-bottom: 0px;
    }
  }
  .icon-button {
    i {
      font-size: 18px;
      margin-right: 16px;
      font-weight: bold;
    }
    i, span {
      display: inline-block;
      vertical-align: middle;
    }
    span {
      img {
        margin-right: 16px;
      }
    }
  }
  .play-button.icon-button {
    > span {
      display: flex;
      align-items: center;

      img {
        margin-right: 16px;
        height: 18px;
        @media (max-width: 567px) {
          margin-right: 16px;
        }
      }
    }
  }

  .podcast-card {
    height: 300px;
  }

  .read-more {
    padding: 0;
  }

  .description {
    p {
      color: #ffffff;
    }
    margin-bottom: 22px;
  }

  h1.show-title {
    margin: 20px 0;
    font-size: 40px;
    font-weight: 500;
    line-height: 44px;
    color: #ffffff;

    @media (max-width: 900px) {
      font-size: 35px;
    }

    @media (max-width: 767px) {
      font-size: 40px;
    }

    @media (max-width: 567px) {
      text-align: center;
    }
  }

  .interact {
    display: flex;
    align-items: center;

    .el-button {
      margin-right: 24px;
      &.btn-primary-transparent {
        background-color: transparent;
      }
      @media (max-width: 567px) {
        margin-right: 8px;
        padding: 11px;
        flex: 1;
      }
    }
  }

  &.is-exclusive {
    .content-wrapper {
      &.is-mobile {
        display: none;

        @media (max-width: 767px) {
          display: block;
        }

        .premium-indicator {
          margin-top: 20px;
        }
      }

      .cover-section {
        padding: 0;
        position: relative;

        > .container {
          padding: 0px;

          @media (max-width: 1024px) {
            padding: 0;
          }
        }

        background-size: cover;
        background-position: center center;
        background-color: $dark-background;

        width: 100%;

        box-sizing: border-box;
        @media (max-width: 1024px) {
        }

        &__content {
          display: flex;
          padding-left: 340px;
          position: relative;

          .show-image {
            position: absolute;
            left: 0;

            @media (max-width: 1024px) {
              height: 287px;
              width: 287px;
            }

            @media (max-width: 567px) {
              margin-top: 24px;
              max-width: 255px;
            }
          }

          &__text {
            width: 100%;
            z-index: 1;

            h1 {
              color: white;
              margin: 20px 0;

              @media (max-width: 900px) {
                font-size: 35px;
              }
            }

            .publisher {
              margin: 0 0 10px;
              color: $light-gray3;
              @media (max-width: 1023px) {
                font-size: 16px;
              }

              @media (max-width: 767px) {
                color: $gray;

                margin: 0 0 20px;
              }
            }

            .premium-indicator {
              margin-top: 20px;
            }
          }
        }

        &::before {
          content: "";
          top: 0;
          left: 0;

          width: 100%;
          height: 100%;

          position: absolute;
          z-index: 0;

          background-color: $dark-background;
        }
      }

      .breadcrumb-and-social-icons {
        padding-top: 24px;
        padding-bottom: 40px;
        .social-share {
          text-align: right;
        }
        @media(max-width: 567px) {
          padding-top: 22px;
          padding-bottom: 24px;
        }
      }

      &__details {
        > .container {
          padding-left: 340px;

          @media (max-width: 1024px) {
            padding-left: 340px;
          }
        }
      }

      @media (max-width: 767px) {
        &:not(.is-mobile) {
          display: none;
        }
      }
    }
  }
  .breadcrumb-and-social-icons {
    padding-top: 24px;
    padding-bottom: 40px;
    .social-share {
      text-align: right;
    }
    @media(max-width: 567px) {
      padding-top: 22px;
      padding-bottom: 24px;
    }
  }

  &__info {
    // margin-top: 40px !important;
    padding: 0;

    display: flex;

    @media (max-width: 767px) {
      flex-direction: column;
    }
    .text {
      overflow-x: hidden;
      margin-left: 53px;

      .publisher {
        color: $light-gray3;
        font-size: 16px;
        font-family: $clear-sans;
        font-weight: bold;
        letter-spacing: 0.2px;
        display: inline-block;
        margin: 0 0;
        &:hover{
          text-decoration: underline;
          cursor: pointer;
        }
        @media (max-width: 567px) {
          color: $light-gray3;
          text-align: center;
          font-size: 14px;
          line-height: 20px;
          width: 100%;
        }
      }

      .interact {
        @media(max-width: 349px) {
          display: flex;
          flex-direction: column;
          align-items: flex-start;

          .main-rating-content {
            margin-top: 10px;
          }
        }
      }

      @media (max-width: 1024px) {
        margin-left: 60px;
      }

      @media (max-width: 767px) {
        margin-left: 0;

        .el-breadcrumb {
          margin-top: 0px;
        }
      }
    }
    .show-image {
      @media (max-width: 567px) {
        max-width: 255px !important;
      }
    }

    @media (max-width: 1024px) {
      padding: 0 30px;
      width: 100% !important;
    }
  }

  &.is-exclusive {
    .content-wrapper {
      .show-details__info {
        padding: 40px 60px 0;
        margin-top: 0 !important;

        @media (max-width: 1024px) {
          padding: 40px 0 0;
        }

        @media (max-width: 767px) {
          padding: 40px 30px;
        }

        @media (max-width: 567px) {
          padding: 0px 30px;
        }
      }
    }

    .show-details__info {
      .el-breadcrumb {
        margin-top:40px;

        @media (max-width: 1023px) {
          margin-top: 0;
        }

        @media (max-width: 767px) {
          margin-top: 0px;
        }
      }

      h1 {
        color: white;
        margin: 20px 0;

        @media (max-width: 1023px) {
          font-size: 40px;
        }

        @media (max-width: 567px) {
          text-align: center;
          font-size: 18px;
          line-height: 24px;
        }
      }

      .description {
        > p {
          @media (max-width: 767px) {
            color: white !important;
          }
        }
      }

      .publisher {
        margin: 0 0 60px;
        color: white;

        @media (max-width: 1023px) {
          font-size: 16px;
        }

        @media (max-width: 767px) {
          margin: 0 0 20px;
        }

        @media (max-width: 567px) {
          color: $light-gray3;
          text-align: center;
          font-size: 14px;
          line-height: 20px;
          width: 100%;
        }
      }
    }
  }

  .episodes-count {
    padding: 0;

    display: flex;
    justify-content: space-between;
    align-items: center;

    > p {
      font-size: 24px;
      @media (max-width: 1024px) {
        padding: 0;
      }
    }

    @media (max-width: 567px) {
      margin-top: 48px;
    }
  }

  .promo-container {
    @media (min-width: $sm) {
      margin-top: 32px;
    }
  }

  .episodes-list-container {
    position: relative;

    @media (max-width: 1024px) {
      width: 100%;
    }

    .season-number {
      color: #ffffff;
      background-color: inherit;
      padding: 0;

      display: flex;
      align-items: center;

      .container {
        display: flex;
        align-items: center;
      }

      .horizontal-line {
        flex: 1;
        height: 1px;

        background-color: transparent;
      }

      p {
        margin: 0 15px 0 0;
      }

      .seasonTag {
        color: #ffffff;
        margin-top: 24px;
        margin-bottom: 24px;
      }
    }
  }

  .el-select.episode-sort {
    width: 130px;
    cursor: pointer;
    .el-input {
      .el-input__inner {
        border-radius: 20px !important;
        color: $black !important;
        border: 1px solid $black !important;
        font-weight: 500;
        padding: 0 15px 0px 30px;
        font-size: 14px !important;
        height: 32px;
        line-height: 4px;
      }
      .el-input__inner::placeholder {
        font-weight: 500 !important;
      }
      .el-input__suffix {
        i {
          font-size: 12px;
          color: $black !important;
          line-height: 0 !important;
        }
      }
    }
  }
  .el-select.episode-sort::before {
    content: "";
    position: absolute;
    z-index: 1;
    background: url(../assets/images/ic_sort_icon.svg) no-repeat;
    width: 13px;
    height: 13px;
    left: 12px;
    top: 12px;
    background-size: contain;
  }
  .el-dropdown {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    height: 32px;
    .el-dropdown-link {
      border-radius: 20px !important;
      color:  #ffffff !important;
      border: 1px solid #ffffff !important;
      font-weight: 500;
      padding: 8px 10px 6px 30px;
      font-size: 14px !important;
      height: 32px;
      line-height: 4px;
    }
  }
  .el-dropdown::before {
    content: "";
    position: absolute;
    z-index: 1;
    background: url(../assets/images/ic_sort_icon.svg) no-repeat;
    width: 13px;
    height: 13px;
    left: 12px;
    top: 12px;
    background-size: contain;
  }
  .added-in-library-btn {
    background: transparent !important;
    border-color: #ffffff !important;
  }
}
@media (max-width: $xs) {
  .show-details {
    .el-pager {
      padding:0 25px;
      margin: 0 0 !important;
      li {
        margin-right: 0 !important;
        padding-right: 22px;
        &:last-child {
          padding-right: 0 !important;
        }
      }
    }
  }
}
@media (max-width: $xxs) {
  .show-details {
    .el-pager {
      padding:0 8px;
      li {
        padding-right: 18px;
      }
    }
  }
}
// @import './ShowDetails.scss';
</style>
