<template>
  <!-- Added route condition to overcome slug errors -->
  <router-link
    ref="carousel-default-show-item"
    :data-segment-uuid="item.id"
    :to="redirect ? constructSDPSlug : $route.fullPath"
    :class="`carousel-default-show-item ${item.isExclusive ? ' original' : ''}`"
    @click.native="onPodcastClick()"
    @mouseover.native="onMouseOver"
    @mouseleave.native="onMouseLeave">
    <div :class="{'podcast-image': true, 'hovered': hovered}">
      <picture-component
        :src="itemImage"
        :alt-info="item.title" />
      <div
        v-if="isSpotlightEnabled"
        class="premium-logo">
        <img
          src="../../../assets/images/web2/luminary-dot.svg"
          alt="Premium Logo">
      </div>
      <div class="podcast-image--overlay">
        <div class="content">
          <h5 class="title">{{ item.title }}</h5>
          <p class="category">{{ item.categoryName }}</p>
          <p class="description">{{ item.description }}</p>
        </div>
      </div>
    </div>
  </router-link>
</template>
<script>
import _ from 'lodash';
import Event from '../../../services/PlayerEvent';
import widgetHelper from '../widgetHelper';

export default {
  name: 'CarouselDefaultShowItem',
  props: {
    hasHoverState: {
      type: Boolean,
      default: false,
    },
    firstItem: {
      type: Boolean,
      default: false,
    },
    redirect: {
      type: Boolean,
      default: true,
    },
    item: {
      type: Object,
      default() {
        return {};
      },
      id: {
        type: String,
        id: '',
      },
      title: {
        type: String,
        default: '',
      },
      description: {
        type: String,
        default: '',
      },
      publisherName: {
        type: String,
        default: '',
      },
      isExclusive: {
        type: Boolean,
        default: false,
      },
      images: {
        type: Object,
        default() {
          return {};
        },
      },
    },
    widget: {
      type: Object,
      default() {
        return {};
      },
    },
    pageId: {
      type: String,
      default: '',
    },
    pageTarget: {
      type: String,
      default: '',
    },
    index: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      hovered: false,
      eventProvider: new Event(),
    };
  },
  computed: {
    isSpotlightEnabled() {
      return this.item.isExclusive && this.$store.getters.featureFlag('is_spotlight_enabled');
    },
    constructSDPSlug() {
      const { publisherName, title, id } = this.item;
      return `/listen/${this.kebabCase(publisherName)}/${this.kebabCase(title)}/${id}`;
    },
    itemImage() {
      return this.item.images.thumbnail ? this.item.images.thumbnail : this.item.images.default;
    },
  },
  watch: {
    item(newVal) {
      this.item = newVal;
    },
  },
  mounted() {
    const ctx = this;
    let isScrolling;
    this.scrollListener = function scroll() {
      window.clearTimeout(isScrolling);
      isScrolling = setTimeout(() => {
        ctx.checkIfVisible();
      }, 66);
    };
    document.body.addEventListener('scroll', this.scrollListener);
    this.checkIfVisible();
  },
  destroyed() {
    document.body.removeEventListener('scroll', this.scrollListener);
  },
  methods: {
    checkIfVisible() {
      const viewportHeight = document.body.offsetHeight;
      const element = this.$refs['carousel-default-show-item'] ? this.$refs['carousel-default-show-item'].$el : null;
      if (element) {
        const elementRect = element.getBoundingClientRect();
        const elementPos = elementRect.top;
        const threshold = (element.offsetHeight / 3); // part of element within viewport
        const isVisible = elementPos >= (-1 * threshold) && (elementPos + threshold) <= viewportHeight;
        if (isVisible && this.item.id.length > 1) {
          element.classList.add('is-visible');
        }
      }
    },
    onPodcastClick() {
      if (!this.redirect) return;
      this.triggerSegmentEvent();
    },
    triggerSegmentEvent() {
      const { bucket, bucketItem } = widgetHelper.formatContentSelectEventData(this.pageId, this.pageTarget, this.widget, this.item, this.index);

      this.eventProvider.triggerContentSelectEvent(bucket, bucketItem);
    },
    onMouseOver() {
      if (this.hasHoverState) this.hovered = true;
    },
    onMouseLeave() {
      if (this.hasHoverState) this.hovered = false;
    },
    kebabCase(str) {
      return _.kebabCase(str);
    },
  },
};
</script>
<style lang="scss">
@import "../../../assets/scss/variables.scss", "../../../assets/scss/mixins.scss";

.carousel-default-show-item {
  height: auto;
  margin-right:15px;
  width: 227px;
  display: block;

  &.original {
    .podcast-image {
      position: relative;
      background-color: rgba(255,255,255, 0);
      border: 0px;
      .premium-logo {
        position: absolute;
        bottom: -16px;
        left: 0;
        right: 0;
        z-index: 101;
        display: flex;
        justify-content: center;
        pointer-events: none;
        @media(max-width: 768px) {
          bottom: -8px;
        }
        img {
          width: 32px;
          height: 32px;
          @media(max-width: 768px) {
            width: 18px;
            height: 18px;
          }
        }
      }
    }
  }

  .podcast-image {
    position: relative;
    background: #d4d4d4; /* for placeholder */
    background-clip: content-box; /* don't let background color go under the border */
    border: 1px solid rgba(17,17,17,0.15);
    box-sizing: border-box;
    height: 227px;
    position: relative;
    transform-origin: 50% 50%;
    transition: 100ms;
    &.hovered {
      .podcast-image--overlay {
        top: 0;
        transition: top 0.75s;
      }
      .content {
        opacity: 1;
        transition-property: opacity;
        transition-delay: .25s;
        transition-duration: .75s;
      }
    }

    &--overlay {
      position: absolute;
      top: 100%;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: #20293C;
      overflow: hidden;
      width: 100%;
      opacity: 1;
      transition: top 0.75s;
      @media (max-width: $sm) {
        display: none;
      }

      .content {
        margin: 16px;
        opacity: 0;
        transition-property: opacity;
        transition-duration: .25s;
      }

      .title {
        margin: 0;
        @include multilinetruncate(18px, 24px, 2);
        font-weight: bold;
        color: $white;
        height: 48px;
      }

      .category {
        font-size: 12px;
        line-height: 16px;
        color: #bababa;
        height: 16px;
      }

      .description {
        color: $white;
        @include multilinetruncate(14px, 21px, 5);
        @media (max-width: $md) {
          @include multilinetruncate(14px, 21px, 4);
        }
      }
    }
  }
}

@media (max-width: $md) {
  .carousel-default-show-item {
    height: auto;
    width: 200px;
    .podcast-image {
      height: 200px;
    }
  }
}
@media (max-width: $sm) {
  .carousel-default-show-item {
    height: auto;
    width: 181px;
    .podcast-image {
      height: 181px;
    }
  }
}
@media (max-width: 460px) {
  .carousel-default-show-item {
    height: auto;
    width: 115px;

    .podcast-image {
      height: 115px;
    }
  }
}
</style>
