<template>
  <p
    v-if="showListenFreeTag"
    class="tag">Listen Free</p>
</template>

<script>
export default {
  props: {
    episode: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  computed: {
    showListenFreeTag() {
      return this.episode.isFree && this.episode.isShowExclusive && this.userNotPremium;
    },
    userNotPremium() {
      return this.userSubscriptionState === 'anon' || this.userSubscriptionState === 'free' || this.userSubscriptionState === 'freeWasPremium';
    },
  },
};

</script>

<style lang="scss" scoped>
@import "../../../assets/scss/variables.scss";

.tag {
  font-family: $patron-bold;
  color: $peach;
  font-size: 12px;
  line-height: 13px;
  letter-spacing: 1px;
  text-transform: uppercase;
  margin-top: 0;
  margin-bottom: 6px;
}
</style>
