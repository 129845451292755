<template>
  <div class="pricing-plans-container">
    <plans-chooser
      :show-top-headers="false"
      :show-continue-free="isLoggedIn && !isSubscribed ? false : true"
      :show-sign-in-prompt="false"
      :display-as-stack="isApplePodcastsPlanEnabled"
      @onSelectPlan="onSelectPlan"/>
  </div>
</template>
<script>
import PlansChooser from './PlansChooser';

export default {
  name: 'PricingPlansPage',
  components: {
    'plans-chooser': PlansChooser,
  },
  computed: {
    isLoggedIn() {
      return this.$store.getters.loggedIn;
    },
    isSubscribed() {
      return this.$store.getters.user && this.$store.getters.user.is_subscribed;
    },
  },
  mounted() {
    this.setMetaInformation('Subscription Options | Luminary', 'Enjoy full access to Luminary Original Podcasts. Save 40% when you sign up for an annual plan.');
  },
  destroyed() {
    this.setMetaInformation();
  },
  methods: {
    onSelectPlan(selectedPlan) {
      if (!selectedPlan) {
        return this.$router.push({ path: '/listen' });
      }
      this.$store.dispatch('selectPricingPlan', selectedPlan);
      return this.$router.push({ path: '/premium-buy' });
    },
  },
};
</script>
<style lang="scss">
@import '../../assets/scss/variables.scss';
.signup-container {
  padding-top: 10px;
  padding-bottom: 233px;
}
</style>
