<template>
  <div class="brightback-cancel">
    Cancel subscription
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  mounted() {
    // process subscription here and redirect somewhere
  },
};
</script>
