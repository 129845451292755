<template>
  <div
    class="signin-page auth-page container"
    data-test="page-signin">
    <h4 class="t-c page-main-title">Log In</h4>
    <div
      v-if="googleSigninEnabled || facebookSigninEnabled || appleSigninEnabled"
      class="sm-auth t-c m-b-2">
      <a
        v-button-event
        v-if="appleSigninEnabled"
        class="google sm-auth__button m-b"
        @click="loginWithApple">
        <span class="sm-auth__button--icon">
          <img
            src="../../assets/images/apple.svg"
            alt="apple">
        </span>
        <span class="sm-auth__button--text">Log in with Apple</span>
      </a>
      <a
        v-button-event
        v-if="facebookSigninEnabled"
        class="fb sm-auth__button m-b"
        @click="loginWithFB">
        <span class="sm-auth__button--icon">
          <img
            src="../../assets/images/facebook.svg"
            alt="facebook">
        </span>
        <span class="sm-auth__button--text">Log in with Facebook</span>
      </a>
      <a
        v-button-event
        v-if="googleSigninEnabled"
        class="google sm-auth__button m-b"
        @click="loginWithGoogle">
        <span class="sm-auth__button--icon">
          <img
            src="../../assets/images/google.svg"
            alt="google">
        </span>
        <span class="sm-auth__button--text">Log in with Google</span>
      </a>
    </div>
    <el-form
      ref="loginForm"
      :model="user"
      :rules="rules"
      :status-icon="true"
      class="overflow-icon"
      @keyup.enter.native="onSubmit">
      <el-form-item
        prop="email"
        label="Email address">
        <el-input
          v-model.trim="user.email"
          label="Email address"
          placeholder="e.g: user@email.com"
          data-test="input-email"
          @input.native="checkValidation"/>
      </el-form-item>
      <el-form-item
        prop="password"
        label="Password">
        <el-input
          v-model="user.password"
          :type="show_pass ? 'text': 'password'"
          label="Password"
          placeholder="8+ characters, including 1 number"
          data-test="input-password"
          class="password"
          @input.native="checkValidation"/>
        <show-password @show="(e) => { show_pass = e }"/>
      </el-form-item>
      <div
        v-if="isAlexaFlow"
        class="alexa-consent">
        <p><span class="bold">Luminary’s Alexa skill contains some mature content not suitable for all ages</span></p>
      </div>
      <div class="t-c m-t-2 m-b-2 action-buttons">
        <el-button
          v-loading="loading"
          :disabled="disableSubmit"
          class="button block-btn spotlight"
          type="primary"
          data-test="btn-signin"
          @click="onSubmit">Sign In</el-button>
      </div>
      <div class="t-c m-t">
        <router-link
          :class="{'highlight': login_attempts > 3}"
          class="btn-link"
          to="/forgotPassword"
          aria-label="Forgot password"
          data-test="link-forgotPassword">
          <i
            v-show="login_attempts > 3"
            class="el-icon-arrow-right" />
          Forgot Password?</router-link>
      </div>
      <div class="t-c m-t-2">
        <p>Don't have an account? &nbsp;<router-link
          :to="{path: '/signup', query: $route.query}"
          class="btn-link"
          aria-label="Sign up"
          data-test="link-signup">Sign Up</router-link></p>
      </div>
    </el-form>
    <custom-info
      :show="showInfoModal"
      :header="infoHeader"
      :description="infoDescription"
      @closeInfoModal="closeInfoModal"
    />
  </div>
</template>

<script>

import User from '../../services/User';
import Episode from '../../services/Episode';
import Subscription from '../../services/Subscription';
import PageMixin from '../../shared/pageMixin';

export default {
  name: 'SignInPage',
  components: {
    'show-password': () => import('../../components/ShowPassword'),
    'custom-info': () => import('../../components/InfoCustomModal'),
  },
  mixins: [PageMixin],
  data() {
    return {
      pageName: 'Sign In',
      userProvider: new User(),
      user: {
        email: '',
        password: '',
      },
      rules: {
        email: [{ required: true, message: 'Email is Required', trigger: 'blur' },
          { type: 'email', message: 'Invalid Email Format', trigger: 'blur' }],
        password: [{ required: true, trigger: 'blur', message: 'Password is Required' }],
      },
      loading: false,
      form_valid: false,
      episodeProvider: new Episode(),
      subscriptionProvider: new Subscription(),
      fingerprint_id: '',
      login_attempts: 0,
      show_pass: 0,
      showInfoModal: false,
      infoHeader: '',
      infoDescription: '',
      error_map: {
        'auth/user-not-found': {
          title: 'Unrecognized Email',
          message: "That email isn't associated with a Luminary account. Double check it and try again.",
        },
        'auth/wrong-password': {
          title: 'Incorrect Password',
          message: 'The password you entered is incorrect.',
        },
        'auth/too-many-requests': {
          title: 'Too many unsuccessful login attempts',
          message: 'The password you entered is incorrect.',
        },
        'too-many-requests-custom': {
          title: 'Too many unsuccessful login attempts',
          message: 'Please click "Forgot Password" if you have forgotten it.',
        },
      },
    };
  },
  computed: {
    googleSigninEnabled() {
      return this.$store.getters.flagWithDefault('google_signin', true);
    },
    facebookSigninEnabled() {
      return this.$store.getters.flagWithDefault('facebook_signin', true);
    },
    appleSigninEnabled() {
      return this.$store.getters.flagWithDefault('apple_signin', true);
    },
    disableSubmit() {
      return !this.form_valid || !this.user.email.length || !this.user.password.length || this.user.password.length <= 7 || this.loading;
    },
  },
  mounted() {
    this.setMetaInformation('Account Sign In | Luminary', 'Log into Luminary & listen to original podcasts you won\'t find anywhere else, featuring award-winning creators, diverse voices, and important stories.');
    window.eventBus.$emit('onboarding-step-change', -1);
    this.fingerprint_id = localStorage.getItem('branch_fingerprint_id');
    if (!this.fingerprint_id) {
      this.getBrowsersFingerPrintId();
    }

    // hack to for navbar
    const navbarElem = document.getElementById("app-navbar");
    if (navbarElem && navbarElem.className) navbarElem.className = "app-navbar";
  },
  destroyed() {
    this.setMetaInformation();
  },
  methods: {
    onSubmit() {
      if (this.loading) return;
      this.$refs.loginForm.validate((valid) => {
        if (valid) {
          this.authenticate();
        }
      });
    },
    authenticate() {
      this.loading = true;
      this.userProvider.authenticate(this.user)
        .then(() => {
          this.registerToLuminary();
          localStorage.setItem('login_type', 'Email');
        })
        .catch((error) => {
          this.loading = false;
          if (error.code === 'auth/wrong-password' || error.code === 'auth/too-many-requests') this.login_attempts++;
          if (this.login_attempts > 3 && error.code !== 'auth/user-not-found') {
            this.showErrorMsg(this.error_map['too-many-requests-custom'].message);
            return;
          }
          this.showErrorMsg(this.error_map[error.code].message);
        });
    },
    getUserInfo() {
      this.userProvider.refreshUser(false).then((user) => {
        if (user && user.uuid) {
          this.registerToLuminary();
        } else {
          this.redirectUserToInfo();
        }
      }).catch((error) => {
        if (error.status === 404) {
          this.redirectUserToInfo();
        } else {
          this.showErrorMsg(error.message);
        }
      });
    },
    registerToLuminary() {
      // const branchData = localStorage.getItem('branch_data');

      // HACK: Temporarily switch to use registerToLuminary due to bug on
      // 06/12/2019 which left users with Firebase credentials but no row in
      // users table.
      this.userProvider.registerToLuminary(null, this.fingerprint_id)
        .then((resp) => {
          if (resp.created) {
            // user does not exist redirect to signup
            this.showErrorMsg('You are not registered yet, please sign up');
            this.$router.push('/signup/plan');
            return;
          }
          this.loading = false;
          this.showSuccessMsg('Login successful.');
          this.checkLastPlayedEpisode();
          this.getUserEpisodeListens();
          if (resp.user && resp.user.is_subscribed) {
            this.fetchSubscription();
            // this.disablePromocode();
          }
          this.$store.dispatch('populateUserLibraryPodcasts');
          this.$store.dispatch('populateUserLibraryEpisodes');
          if (resp.user && resp.user.is_subscribed && localStorage.getItem('promo_code') !== null) {
            this.disablePromocode();
          }
          this.redirectUser();
          this.$analytics.identify(resp.user.uuid);
          this.$analytics.track('login');
          this.$analytics.trackLogin('login');
        })
        .catch((error) => {
          this.loading = false;
          this.showErrorMsg(error);
        });
    },
    checkLastPlayedEpisode() {
      this.episodeProvider.playLastPlayedEpisode();
    },
    redirectUser() {
      const { redirect, state } = this.$route.query;
      const redirectUri = this.$route.query.redirect_uri;
      document.getElementById('header').focus();
      if (this.$route.query.isRedeemGift) {
        this.$router.push('/redeem-a-gift');
      } else if (redirectUri && state) { // Query params for Alexa account linking
        this.redirectUserToAlexa(redirectUri, state);
      } else if (redirect) {
        this.$router.replace({ path: redirect });
      } else {
        const redirectRoute = '/listen/my-shows';
        this.$router.replace({ path: this.$store.getters.redirect_user || redirectRoute });
      }
    },
    redirectUserToInfo() {
      this.$store.commit('setUser', this.user);
      this.$router.push('/user-info');
    },
    redirectUserToAlexa(redirectUri, state) {
      if (this.userProvider.isValidAlexaRedirectUri(redirectUri)) {
        const url = new URL(redirectUri);
        url.searchParams.set('state', state);
        url.searchParams.set('code', this.userProvider.getRefreshToken());
        window.location = url.href;
      }
    },
    checkValidation() {
      if (this.user.password.length > 7) {
        this.$refs.loginForm.validate((valid) => { this.form_valid = valid; });
      }
    },
    getUserEpisodeListens() {
      this.episodeProvider.getListenedEpisodes().then((episodesProgress) => {
        this.$store.dispatch('setAllEpisodesProgress', episodesProgress || []);
      });
    },
    fetchSubscription() {
      this.subscriptionProvider.getSubscription().then((resp) => {
        if (resp && resp.platform) {
          this.$store.commit('setUserSubscription', resp);

          const defaultPricing = {
            currency: resp.plan.currency,
            price: resp.plan.total_price,
          };
          if (resp.platform === 'web') {
            this.$store.commit('setPricing', defaultPricing);
          }
        }
      });
    },
    getBrowsersFingerPrintId() {
      if (!window.branch || (window.branch && !window.branch.getBrowserFingerprintId)) {
        return;
      }
      window.branch.getBrowserFingerprintId((err, data) => {
        if (data) {
          localStorage.setItem('branch_fingerprint_id', data);
          this.fingerprint_id = data;
        }
      });
    },
    disablePromocode() {
      localStorage.removeItem('promo_code');
      localStorage.removeItem('promo_trial_end_date');
      localStorage.removeItem('promo_trial_period');
      localStorage.removeItem('branch_promo');
      const promoData = {
        promoCode: '',
        trialPeriod: 1,
        trialEndDate: '',
        header: '',
        subheader: '',
      };
      this.$store.dispatch('setPromoData', promoData);
      this.showErrorMsg("Promo code not available: you've already subscribed using a promo code.");
    },
    displayInfoModal() {
      this.infoHeader = 'Not eligible for promotion';
      this.infoDescription = 'You have already signed up for Luminary Premium. This offer is limited to new subscribers only.';
      this.showInfoModal = true;
    },
    closeInfoModal() {
      this.showInfoModal = false;
      this.redirectUser();
    },
    loginWithFB() {
      this.userProvider.loginWithFb().then(() => {
        this.registerToLuminary();
        localStorage.setItem('login_type', 'Facebook');
      }).catch(this.firebaseAuthErrorHandler);
    },
    loginWithGoogle() {
      this.userProvider.loginWithGoogle().then(() => {
        this.registerToLuminary();
        localStorage.setItem('login_type', 'Google');
      }).catch(this.firebaseAuthErrorHandler);
    },
    loginWithApple() {
      this.userProvider.loginWithApple().then(() => {
        this.registerToLuminary();
        localStorage.setItem('login_type', 'Apple');
      }).catch(this.firebaseAuthErrorHandler);
    },
    firebaseAuthErrorHandler(error) {
      if (error.code !== 'auth/popup-closed-by-user') {
        this.showErrorMsg(error.message);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../assets/scss/variables.scss';

.signin-page {
  margin: 72px auto;
  @media(max-width: $sm) {
    max-width: 90vw !important;
  }
  .highlight {
    color: $black1;
    text-decoration: underline;
    font-size: 17px;
    transition: .4s all ease-in-out;
  }
  h4 {
    margin: 64px 0 32px 0;
    @media (max-width: $xs) {
      margin: 32px 0 8px 0;
    }
  }
  .action-buttons {
    margin-top: 24px;
    margin-bottom: 24px;
  }
  .alexa-consent {
    margin-top: 10px;
    text-align: center;
    .bold {
      color: black;
    }
  }
  .el-form {
    margin-top: 96px;
  }
}
</style>
