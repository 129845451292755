<template>
  <el-dialog
    :visible.sync="showModal"
    :center="true"
    data-test="subscription-success-dialog"
    class="subscription-success-dialog"
    append-to-body
    @closed="emitEvent"
  >
    <div class="subscription-success">
      <div class="header">You’re subscribed!</div>
      <div v-if="isNextChargePresent">
        <p>Your new plan is {{ data.next_charge.type }}</p>
        <p>Your card will be billed {{ data.next_charge.final_price | amountFilter(data.next_charge.currency, false, userNextPlanType.period) }} beginning {{ expiryOffset > 1 ? 'on' : '' }} {{ renewalDate | dateFormat }}.</p>
      </div>
      <div v-if="promoPlan" class="promo_message">
        <p>Check your email now for steps on how to redeem your limited edition vinyl.</p>
        <img class="vinyl_image" src="@/assets/images/promos/vinyl_image.png">
      </div>
      <a
        v-if="showEditLink"
        class="btn-link"
        @click="edit">Edit Billing Info</a>
      <br>
      <el-button
        type="primary"
        class="large"
        @click="emitEvent">Ok</el-button>
    </div>
  </el-dialog>
</template>
<script>

import SubscriptionMixin from '../../shared/subscriptionMixin';

export default {
  name: 'SubscriptionSuccessDialog',
  mixins: [SubscriptionMixin],
  props: {
    show_modal: {
      default: false,
      type: Boolean,
    },
    update_payment: {
      default: false,
      type: Boolean,
    },
    showEditLink: {
      default: true,
      type: Boolean,
    },
    promoPlan: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      showModal: this.show_modal,
      showPaymentForm: false,
    };
  },
  computed: {
    data() {
      return this.$store.getters.userSubscription || {};
    },
  },
  watch: {
    show_modal(newVal) {
      this.showModal = newVal;
    },
  },
  methods: {
    emitEvent() {
      this.$emit('close');
    },
    edit() {
      this.$emit('close');
      this.$emit('onEdit', {}, true);
    },
  },
};
</script>
<style lang="scss">
@import '../../assets/scss/variables.scss';
.subscription-success-dialog {
  .el-dialog {
    color: #ffffff;
    background-color: $dark-background;
    max-width: 562px;
    width: 100%;
    .el-dialog__close.el-icon {
      color: #ffffff;
      font-size: 24px;
    }
    .el-dialog__body {
      color: #ffffff;
      text-align: center;
      .subscription-success {
        .header {
          font-size: 28px;
          font-family: $clear-sans;
          font-weight: bold;
          line-height: 36px;
          text-align: center;
        }
        p {
          color: #ffffff;
        }
        .el-button {
          margin-top: 32px;
        }
      }
    }
  }
  .promo_message p {
    margin-top: 30px;
    font-size: 26px;
    line-height: 1.2em;
  }
  .vinyl_image {
    width: 251px;
    margin-left: 0px;
  }
}
</style>
