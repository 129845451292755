<template>
  <audio
    id="audio"
    ref="hls-player"
    :crossorigin="crossorigin"
    :title="title"
    controlsList="nodownload"
  >
    <source
      type="audio/mpeg">
  </audio>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import Hls from 'hls.js';
import PlaybackService from '@/services/PlaybackService';
import { getServices } from '@/services/serviceProvider';

const [
  playbackService,
] = getServices([PlaybackService]);

export const IS_HLS_SUPPORTED = Hls.isSupported();

export default {
  name: 'HlsAudio',
  props: {
    title: {
      type: String,
      default: '',
    },
    crossorigin: {
      type: String,
      default: null,
    },
    token: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      hlsSrc: null,
    };
  },
  computed: {
    ...mapGetters([
      'episode',
    ]),
  },
  mounted() {
    const node = this.$refs['hls-player'];
    // eslint-disable-next-line prefer-destructuring
    const token = this.token;
    if (IS_HLS_SUPPORTED) {
      // hls variable is not declared in data, this is correct since it should not be reactive
      this.hls = new Hls({
        debug: false,
        xhrSetup(xhr, url) {
          if (/media-content-key/.test(url)) {
            xhr.setRequestHeader('x-id-token', token);
          }
          xhr.withCredentials = true; // do send cookies
        },
        fetchSetup(context, initParams) {
          if (/media-content-key/.test(context.url)) {
            initParams.headers['x-id-token'] = token;
          }
          initParams.credentials = 'include';
          return new Request(context.url, initParams);
        },
      });
      this.hls.on(Hls.Events.MANIFEST_PARSED, (event, data) => {
        this.$emit('manifestParsed', data);
      });
      this.hls.on(Hls.Events.LEVEL_LOADED, (event, data) => {
        this.$emit('durationUpdated', data.details.totalduration);
      });
      this.hls.on(Hls.Events.ERROR, (event, data) => {
        // eslint-disable-next-line no-console
        console.warn('error', event, data);
        if (data.fatal) {
          // will not try to recover if error is fatal, just fallback to regular media
          if (this.episode) {
            this.setHlsFileUnavailable(this.hlsSrc);
            this.loadEpisodeInPlayer(this.episode);
          }
          switch (data.type) {
            case Hls.ErrorTypes.NETWORK_ERROR:
              // try to recover network error
              // eslint-disable-next-line no-console
              console.log('fatal network error encountered, try to recover');
              this.hls.startLoad();
              break;
            case Hls.ErrorTypes.MEDIA_ERROR:
              // eslint-disable-next-line no-console
              console.log('fatal media error encountered, try to recover');
              this.hls.recoverMediaError();
              break;
            default:
              // cannot recover
              // eslint-disable-next-line no-console
              console.log('cannot recover, destroy hls instance');
              this.hls.destroy();
              break;
          }
        }
      });
      this.hls.attachMedia(node);
      playbackService.setHlsComponent(this);
    }
    // non-reactive getters/setters
    const proxyNodeProp = (prop) => {
      Object.defineProperty(this, prop, {
        get: () => node[prop],
        set: (v) => {
          node[prop] = v;
        },
      });
    };
    proxyNodeProp('buffered');
    proxyNodeProp('duration');
    proxyNodeProp('playbackRate');
    proxyNodeProp('currentTime');
    proxyNodeProp('muted');
    proxyNodeProp('src');
    proxyNodeProp('paused');
    proxyNodeProp('ended');
    proxyNodeProp('readyState');
    proxyNodeProp('oncanplaythrough');
    proxyNodeProp('onloadedmetadata');
    proxyNodeProp('onloadeddata');
    proxyNodeProp('onerror');
    proxyNodeProp('onended');
    proxyNodeProp('ontimeupdate');
    proxyNodeProp('onseeking');
    proxyNodeProp('onseeked');
  },
  beforeDestroy() {
    this.hls.destroy();
    playbackService.setHlsComponent(undefined);
  },
  methods: {
    ...mapMutations([
      'setHlsFileUnavailable',
    ]),
    play() {
      return this.$refs['hls-player'].play();
    },
    pause() {
      return this.$refs['hls-player'].pause();
    },
    addEventListener(...args) {
      return this.$refs['hls-player'].addEventListener(...args);
    },
    setAttribute(key, value) {
      if (this.hls && key === 'src' && /\.m3u8/.test(value)) {
        this.hls.once(Hls.Events.MEDIA_DETACHED, () => {
          this.hls.once(Hls.Events.MEDIA_ATTACHED, () => {
            this.hlsSrc = value;
            this.hls.loadSource(value);
          });
          this.hls.attachMedia(this.$refs['hls-player']);
        });
        this.hls.detachMedia();
      } else if (this.hls && key === 'src') {
        this.hls.once(Hls.Events.MEDIA_DETACHED, () => {
          if (this.$refs['hls-player']) this.$refs['hls-player'].setAttribute(key, value);
        });
        this.hls.detachMedia();
      } else {
        this.$refs['hls-player'].setAttribute(key, value);
      }
    },
    load() {
      if (this.hlsSrc && /\.m3u8/.test(this.hlsSrc)) return;
      this.$refs['hls-player'].load();
    },
  },
};
</script>
