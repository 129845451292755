<template>
  <div
    v-if="plansLoaded"
    class="promo">
    <div class="promo__image">
      <img
        src="../../assets/images/new/holidaypromo/left-shark.svg"
        alt="promotional icon">
    </div>
    <div>
      <h4 class="promo__header">Limited-time</h4>
      <h2 class="promo__title">Special Offer</h2>
      <promo-content
        :promo_variant="promo_variant"
        button_color="black"
        button_variant="thin"/>
    </div>
    <div class="promo__image">
      <img
        src="../../assets/images/new/holidaypromo/right-shark.svg"
        alt="promotional icon">
    </div>
  </div>
</template>
<script>

import PromoContent from './PromoContent';

export default {
  name: 'HolidayPromo',
  components: {
    'promo-content': PromoContent,
  },
  props: {
    promo_variant: {
      type: String,
      default: null,
    },
  },
  data() {
    return {};
  },
  computed: {
    plansLoaded() {
      return this.$store.getters.plansLoaded;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "./HolidayPromo.scss";
</style>
