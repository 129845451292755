import countries from '@/assets/json/countries.json';

export default class HolidayPromo {
  correncyCountry = countries.reduce((acc, v) => ({ ...acc, [v.currency]: v }), {});

  startDate = new Date(2021, 10, 11);

  endDate = new Date(2022, 0, 4);

  affectedCountries = ['US', 'GB'];

  get isEnabled() {
    return this.startDate < new Date() && this.endDate > new Date();
  }

  modifications = {
    US: {
      yearly: {
        breakdownPrice: '$1.99',
        discountPercent: 59,
        displayPromoPrice: '$23.99',
        marketing_price: 1.99,
        marketing_price_str: '$1.99',
        price: 23.99,
      },
    },
    GB: {
      yearly: {
        breakdownPrice: '£1.66',
        discountPercent: 58,
        displayPromoPrice: '£19.49',
        marketing_price: 1.66,
        marketing_price_str: '£1.66',
        price: 19.49,
      },
    },
  };

  getPlanModification(country, type) {
    return this.modifications[country] && this.modifications[country][type];
  }

  modifyPlan(plan, country) {
    const modifications = this.getPlanModification(country, plan.planTypeString);
    return modifications ? {
      ...plan,
      isHolidayPromo: true,
      displayPrice: modifications.displayPromoPrice,
      displayOriginalPrice: plan.displayPrice,
      originalPlan: plan,
      ...modifications,
    } : plan;
  }

  processPlans(plans, country) {
    if (!this.affectedCountries.includes(country)) return plans;
    return plans
      .filter(p => this.getPlanModification(country, p.planTypeString) || p.planTypeString === 'monthly')
      .map(p => this.modifyPlan(p, country));
  }
}
