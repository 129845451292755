<template>
  <div :class="{'homepage-hero': true, 'homepage-hero--no-cta': !includeCta, 'updated-bg': isUpdatedBG}">
    <div class="container">
      <div class="content">
        <h1 class="title">Original podcasts you won't find anywhere else.</h1>
        <p class="subtitle">Luminary is a subscription podcast network. Subscribe now to start listening.</p>
        <div class="button-wrapper">
          <el-button
            v-if="includeCta"
            ref="cta"
            class="el-button--primary cta-button"
            @click="handleSubscribeClick('home_subscribe_click')">
            {{ buttonCTAText }}
          </el-button>
          <app-store-links v-else />
        </div>
        <div v-if="includeCta" class="disclaimer-text">
          <p> <span style="color: rgb(193,193,193);">Cancel Anytime.</span>
            <router-link
              :to="termsLink"
              color="primary"
              target="_blank"
              class= "terms"
              data-test="link-terms"
            >
              Terms apply
            </router-link>
          </p>
        </div>
      </div>
    </div>
    <transition name="el-fade-in-linear">
      <div
        v-show="!isScrollingBtnVisible"
        :class="{'button-wrapper': true, 'button-wrapper_fixed': true}"
      >
        <el-button
          class="el-button--primary cta-button"
          @click="handleSubscribeClick('home_subscribe_click')">
          {{ buttonCTAText }}
        </el-button>
      </div>
    </transition>
  </div>
</template>

<script>
import AppStoreLinks from '@/components/AppStoreLinks';

export default {
  components: {
    AppStoreLinks,
  },
  props: {
    includeCta: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      scrollingBtnBB: null,
      isUpdatedBG: true,
    };
  },
  computed: {
    isScrollingBtnVisible() {
      if (!this.scrollingBtnBB) return true;
      const { height, y } = this.scrollingBtnBB;
      return y + height > 0;
    },
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll, { passive: true });
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    handleSubscribeClick(btnId) {
      this.$analytics.track('Home Subscribe Click', {
        screen_name: this.$route.name,
        path: this.$route.path,
        btnId,
      });
      this.onStartFreeTrialClick();
    },
    handleScroll() {
      this.updateScrollingBtn();
    },
    updateScrollingBtn() {
      if (!this.$refs.cta) return;
      this.scrollingBtnBB = this.$refs.cta.$el.getBoundingClientRect();
    },
  },
};

</script>

<style lang="scss" scoped>
@import "../../../assets/scss/variables.scss";
$vertical-shift: 0px;

.homepage-hero {
  width: 100%;
  position: relative;
  margin: 0;
  padding: 0;
  @media (max-width: $xs) {
    padding-top: 133px;
    max-height: 420px;
    padding-bottom: 32px;
  }
  &.homepage-hero--no-cta {
    .subtitle {
      font-size: 18px;
    }
    @media (max-width: $xs) {
      .container {
        height: 190px;
        margin-top: -$vertical-shift;
      }
      .subtitle {
        display: none;
      }
    }
  }
}

.homepage-hero:before {
  background-image: url(../../../assets/images/web2/home/TMM-Luminary-homepage-XL-1600x625x2.jpg), linear-gradient(180deg, #1B263C 0%, #010D25 78.12%, #1B263C 89.58%);
  background-size: auto 100%;
  content: "";
  position: absolute;
  top: 0;
  background-repeat: no-repeat;
  background-position: right;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  opacity: 1;
  @media (max-width: 1360px){
    background-image:  url(../../../assets/images/web2/home/TMM-Luminary-homepage-M-1024x325x2.jpg), linear-gradient(180deg, #1B263C 0%, #010D25 78.12%, #1B263C 89.58%);
    background-position: left top;
    background-size: auto 100%;
  }
  @media (max-width: $md){
    background-image:  url(../../../assets/images/web2/home/TMM-Luminary-homepage-M-1024x325x2.jpg), linear-gradient(180deg, #1B263C 0%, #010D25 78.12%, #1B263C 89.58%);
    background-position: left top;
    background-size: auto 100%;
  }
  @media (max-width: $sm){
    background-image: url(../../../assets/images/web2/home/TMM-Luminary-homepage-M-768x278x2.jpg);
    background-position: left top;
    background-size: auto 278px;
  }
  @media (max-width: $xs){
    background-position: top;
    background-size: 100%;
    background-image: url(../../../assets/images/web2/home/TMM-Luminary-Homepage-S-375x150x2.jpg);
  }
  @media (max-width: $xxs){
    background-position: top;
    background-size: contain;
    background-image: url(../../../assets/images/web2/home/TMM-Luminary-Homepage-S-375x150x2.jpg);
  }
}

.homepage-hero.updated-bg:before {
  background-image: url(../../../assets/images/web2/home/TMM-Luminary-homepage-1600x625x2.png),linear-gradient(180deg,#041027 0%,#1b243c 100%)!important;
}

/* Mobile Styles */
@media (max-width: $xs) {
  .homepage-hero.updated-bg:before {
    background-image: none !important;
    background-position: 0 0;
    background-size: auto 278px;
  }
}

@media (max-width: 700px) {
  .homepage-hero.updated-bg:before {
    background-position: -100px 0;
  }
}

.homepage2 {
  width: 100%;
  z-index: 1;
}

.container {
  max-width: 1600px;
  margin: 0 auto;
  width: auto;
  display: flex;
  flex-flow: row nowrap;
  padding-left: 87px;
  align-items: center;
  justify-content: left;
  height: 467px;
  @media (max-width: 1410px){
    height: 420px;
  }
  @media (max-width: 1300px){
    height: 380px;
  }
  @media (max-width: $lg) {
    height: 380px;
  }
  @media (max-width: 1079px){
    height: 380px;
  }
  @media (max-width: $md) {
    height: 323px;
  }
  @media (max-width: $sm) {
    padding-left: 55px;
  }
  @media (max-width: $xs) {
    padding-left: 0;
  }
}

.content {
  opacity: 1;
  position: relative;
  z-index: 2;
  flex: 0 0 420px;
  @media (max-width: $md) {
    flex: 0 0 312px;
  }
  @media (max-width: $sm) {
    flex: 0 0 270px;
  }
  @media (max-width: $xs) {
    flex: 1 1 auto;
  }
}

.title {
  color: $white;
  //font-family: $serif;
  font-size: 40px;
  line-height: 40px;
  font-weight: 500;
  letter-spacing:0px;
  text-align: left;
  margin: 0 auto 6px;
  max-width: 600px;
  @media (max-width: $md) {
    max-width: 500px;
    font-size: 28px;
    line-height: 33px;
    margin: 0 auto 12px;
  }
  @media (max-width: $sm) {
    font-size: 24px;
    line-height: 28px;
  }
  @media (max-width: $xs) {
    margin: 0 auto 20px;
    max-width: 320px;
    text-align: center;
    font-size: 36px;
    line-height: 42px;
  }
}

.subtitle {
  color: $white;
  max-width: 428px;
  text-align: left;
  font-size: 14px;
  line-height: 21px;
  margin-bottom: 25px;
  @media (max-width: $sm) {
    margin-bottom: 12px;
    font-size: 12px;
    line-height: 19px;
  }
  @media (max-width: $xs) {
    text-align: center;
    margin: 0 auto;
    font-size: 18px;
    line-height: 23px;
    margin-bottom: 36px;
    max-width: 250px;
  }
}

.cta-button {
  min-width: 232px;
  padding: 16px 28px;
  font-size: 16px;
}

.disclaimer-text p{
  text-align: left;
  color: $white;
  font-size: 14px;
  line-height: 40px;
  @media (max-width: $xs) {
    text-align: center;
  }
  .terms {
    text-decoration: underline;
    color: $white;
  }
}

.button-wrapper {
  display: flex;
  justify-content: left;
  margin-left: 0;
  @media (max-width: $xs) {
    text-align: center;
    display: block;
    margin-left: auto;
  }
  .el-button--primary {
    margin-left: 0;
    @media (max-width: $sm) {
      margin-bottom: 20px;
    }
  }
  &_fixed {
    display: none;
    position: fixed;
    width: 100%;
    bottom: 0;
    z-index: 1000;
    @media (max-width: $xs) {
      display: block;
    }
  }
}

</style>
