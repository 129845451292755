const getDefaultState = () => ({
  accordionSections: [
    {
      title: 'Your Info',
      name: 'yourInfo',
      componentName: 'YourInfoSection',
      formData: {
        firstName: '',
        lastName: '',
        email: '',
      },
      isComplete: false,
      isEditable: true,
    },
    {
      title: 'Recipient Info',
      name: 'recipientInfo',
      componentName: 'RecipientInfoSection',
      formData: {
        firstName: '',
        lastName: '',
        email: '',
        confirmEmail: '',
        delivered_at: '',
        message: '',
      },
      isComplete: false,
      isEditable: true,
    },
    {
      title: 'Payment',
      name: 'payment',
      componentName: 'PaymentSection',
      formData: {},
      isComplete: false,
      isEditable: true,
    },
    {
      title: 'Order Summary',
      name: 'orderSummary',
      componentName: 'OrderSummarySection',
      isComplete: false,
      isEditable: false,
    },
  ],
  activeAccordionSection: 0,
  giftConfirmation: {
    delivered_at: '',
    yourEmail: '',
  },
});

const getters = {
  accordionSections: state => state.accordionSections,
  activeAccordionSection: state => state.activeAccordionSection,
  giftConfirmation: state => state.giftConfirmation,
};

const mutations = {
  setAccordionSectionData: (state, payload) => {
    const { formData, index } = payload;
    state.accordionSections = state.accordionSections.map((section, sectionIndex) => {
      if (sectionIndex === index) {
        section.formData = formData;
        section.isComplete = true;
      }
      return section;
    });
  },
  setActiveAccordionSection: (state, index) => {
    state.activeAccordionSection = index;
  },
  updateAccordionSectionsComplete: (state, index) => {
    state.accordionSections = state.accordionSections.map((section, sectionIndex) => {
      if (sectionIndex >= index) {
        section.isComplete = false;
      }
      return section;
    });
  },
  resetAccordion: (state) => {
    state.accordionSections = getDefaultState().accordionSections;
    state.activeAccordionSection = getDefaultState().activeAccordionSection;
  },
  setGiftConfirmation: (state, payload) => {
    state.giftConfirmation = payload;
  },
};

const state = getDefaultState();

const actions = {
  saveAccordionSectionData: (context, payload) => {
    context.commit('setAccordionSectionData', payload);
    context.commit('setActiveAccordionSection', payload.index + 1);
  },
  editAccordionSectionData: (context, index) => {
    context.commit('setActiveAccordionSection', index);
    context.commit('updateAccordionSectionsComplete', index);
  },
  resetAccordion: (context) => {
    context.commit('resetAccordion');
  },
  setGiftConfirmation: (context, payload) => {
    context.commit('setGiftConfirmation', payload);
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
