<template>
  <div class="highlighted">
    <div class="highlighted-header">And more, only on Luminary</div>
    <div class="highlighted-container">
      <div
        v-for="(item, i) in cardsData"
        :key="i"
        class="highlighted-item"
      >
        <carousel-default-show-item
          v-bind="item"
        />
        <div class="highlighted-item-title">{{ item.item.title }}</div>
      </div>
    </div>
    <div class="highlighted-more">
      <el-button
        class="el-button btn-transparent cta-button"
        @click="handleShowsClick('home_shows_click')">
        Browse Originals
      </el-button>
    </div>
  </div>
</template>

<script>
import PodcastService from '@/services/Podcast';
import PodcastList from '@/components/PodcastList';
import CarouselDefaultShowItem from '@/components/widgets/carousel/CarouselDefaultShowItem.vue';

const serv = new PodcastService();

export default {
  name: 'HomePageHighlighted',
  components: {
    'podcast-list': PodcastList,
    CarouselDefaultShowItem,
  },
  data() {
    return {
      itemList: [],
    };
  },
  computed: {
    cardsData() {
      return this.itemList.map((v, i) => ({
        hasHoverState: false,
        firstItem: false,
        redirect: true,
        pageId: v.uuid,
        pageTarget: 'originals',
        index: i,
        item: v,
      }));
    },
  },
  beforeMount() {
    this.getShows();
  },
  methods: {
    async getShows() {
      const { default: ids } = await import('@/config/globalConfigStaticData/curated_list');
      this.itemList = await serv.getV2Podcasts(ids);
    },
    handleShowsClick() {
      this.$router.push('/shows');
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../assets/scss/variables.scss";
@import "../../../assets/scss/mixins.scss";

@mixin showItem($size, $margin, $amountInRow) {
    &-container {
      max-width: $amountInRow*($margin*2+$size)+px;
    }
    &-item-title {
      max-width: $size+$margin*2+px;
    }
    .carousel-default-show-item {
      margin-right: $margin+px;
      margin-left: $margin+px;
      margin-top: 0;
      width: $size+px !important;
      height: $size+px !important;
    }

    .carousel-default-show-item /deep/ .podcast-image {
      width: $size+px !important;
      height: $size+px !important;
    }
}
.highlighted {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;

  &-header {
    //font-family: "ITC Clearface", serif;
    font-size: 40px;
    line-height: 47px;
    color: #FFFFFF;
    margin: 40px 0 32px 0;
    text-align: center;
  }
  &-container {
    display: flex;
    flex-flow: row wrap;
    max-width: 1024px;
    justify-items: center;
  }
  &-item-title {
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: #FFFFFF;
    margin-top: 10px;
    margin-bottom: 25px;
    max-width: 100%;
    @media (max-width: 1023px) {
      margin-bottom: 20px;
    }
    @media (max-width: 767px) {
      margin-bottom: 22px;
    }
  }
  .cta-button {
    min-width: 232px;
    padding: 16px 28px;
    font-size: 16px;
    margin-left: 6px;
    margin-right: 6px;
    margin-top: 5px;
    @media (max-width: 1023px) {
      margin-top: 10px;
    }
    @media (max-width: 767px) {
      margin-top: 8px;
    }
  }

  @include showItem(204, 12, 4);
  @media (max-width: 1279px) {
    @include showItem(204, 12, 4);
  }
  @media (max-width: 1023px) {
    @include showItem(154, 9, 4);
  }
  @media (max-width: 767px) {
    @include showItem(164, 7, 2);
  }
  .carousel-default-show-item /deep/ .premium-logo {
    display: none;
  }
  @media (max-width: 768px) {
    .carousel-default-show-item /deep/ .podcast-image--overlay {
      display: block;
    }
  }
}
</style>
