import Episode from '../services/Episode';

const episode_service = new Episode();

export default {
  async getEpisodeDetails(episodeUuid) {
    try {
      const episode = await episode_service.getEpisodeDetailsV2(episodeUuid);

      let episodeData = {};

      episodeData = {
        released_at: episode.releasedAt,
        description: episode.description,
        season: {
          season: episode.season,
          podcast: {
            is_exclusive: episode.isShowExclusive,
            title: episode.title,
          },
        },
        title: episode.title,
        is_exclusive: episode.isExclusive,
        is_free: episode.isFree,
        media_url: episode.mediaUrl,
        runtime: episode.runtime,
        season_number: episode.season,
        display_image_url: episode.images.thumbnail,
        number: episode.number,
        uuid: episode.id,
        podcast_uuid: episode.showId,
        show_title: episode.showTitle,


      };

      return {
        episodeData,
      };
    } catch (err) {
      return err;
    }
  },
};
