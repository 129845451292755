<template>
  <div>
    <div
      v-if="showPanel"
      class="panel-background"
      @click="toggleDevPanel"/>
    <div
      v-if="showPanel"
      class="dev-panel">
      <el-card
        class="dev-card"
        shadow="always">
        <div
          slot="header"
          class="clearfix">
          <span>Dev Panel</span>
          <el-button
            style="float: right; padding: 3px 0"
            type="text"
            @click="toggleDevPanel">
            Hide
          </el-button>
        </div>
        <div>
          <span>Access to developer configuration options.</span>
        </div>
        <el-tabs
          v-model="currentTab"
          class="dev-tabs"
          @tab-click="switchTab">
          <el-tab-pane
            label="Feature Flags"
            name="features"/>
          <el-tab-pane
            label="Future Config."
            name="other"/>
        </el-tabs>
      </el-card>
      <el-card
        v-if="currentTab === 'features'"
        class="dev-card feature-card">
        <div
          slot="header"
          class="clearfix">
          <span>Feature Flags</span>
        </div>
        <div class="functional-area">
          <div class="description">
            <span style="color: red;"><sup>*</sup>Optimizely removed</span>
            Manually set the Optimizely datafile JSON to test a configuration locally then Refresh to load. (Click "Clear" then reload to disable manual datafile.)
          </div>
          <div class="input-container">
            <el-input
              v-model="dataFileJson"
              class="text-area-input"
              type="textarea"
              rows="2"
              label="Datafile JSON content"
              placeholder="Datafile JSON content..."/>
            <div class="btn-container">
              <el-button
                class="text-submit-btn"
                @click="optimizelyOverrideDatafile">
                Save
              </el-button>
              <el-button
                class="text-submit-btn"
                @click="() => optimizelyOverrideDatafile(true)">
                Clear State
              </el-button>
            </div>
          </div>
        </div>
        <div class="functional-area">
          <div class="description">
            <span style="color: red;"><sup>*</sup>Optimizely removed</span>
            <span>Enable Optimizely debug logger mode in console (reload page after changing value).</span>
          </div>
          <div class="toggle-container">
            <span style="color: red;"><sup>*</sup>Optimizely removed</span>
            <span class="toggle-name">Optimizely Debug Mode: </span>
            <el-switch
              :value="optimizelyDebugMode"
              class="feature-toggle"
              active-color="#13ce66"
              inactive-color="#ff4949"
              @change="(value) => toggleDebugMode(value)"/>
          </div>
        </div>
        <div class="functional-area">
          <div class="description">
            <span style="color: red;"><sup>*</sup>Optimizely removed</span>
            <span>Disable optimizely from initializing at app launch. (Do this if you're manually setting flags below for testing.)</span>
          </div>
          <div class="toggle-container">
            <span class="toggle-name">Disable Optimizely: </span>
            <el-switch
              :value="disableOptimizely"
              class="feature-toggle"
              active-color="#13ce66"
              inactive-color="#ff4949"
              @change="(value) => optimizelyOverride(value)"/>
          </div>
        </div>
        <div class="functional-area">
          <div class="description">
            <span>You can manually set feature flags here to test new features as you develop.</span>
          </div>
          <div
            v-for="feature in Object.keys(featureFlags)"
            :key="feature"
            class="toggle-container">
            <span class="toggle-name">{{ feature }}: </span>
            <el-switch
              :value="featureFlags[feature]"
              class="feature-toggle"
              active-color="#13ce66"
              inactive-color="#ff4949"
              @change="(value) => featureUpdated({ [`${feature}`]: value })"/>
          </div>
        </div>
      </el-card>
      <el-card
        v-if="currentTab === 'other'"
        class="dev-card other-card">
        <div
          slot="header"
          class="clearfix">
          <span>Other Config.</span>
        </div>
        <div class="description">
          <span>This is a placeholder for future dev config stuff.</span>
        </div>
      </el-card>
    </div>

  </div>
</template>

<script>
export default {
  data() {
    return {
      showPanel: false,
      currentTab: 'features',
      dataFileJson: '',
    };
  },
  computed: {
    featureFlags() {
      return this.$store.getters.featureFlags;
    },
    disableOptimizely() {
      return this.$store.getters.manualDisableOptimizely;
    },
    optimizelyDebugMode() {
      return this.$store.getters.optimizelyDebugMode;
    },
  },
  /* eslint-disable */
  watch: {
    '$store.getters.manualDatafileJson': function (newVal) {
      this.dataFileJson = JSON.stringify(newVal);
    },
  },
  /* eslint-enable */
  mounted() {
    const ctx = this;
    document.addEventListener('keydown', (event) => {
      if (event.ctrlKey && event.key === 'd') {
        ctx.toggleDevPanel();
      }
    });
    this.dataFileJson = JSON.stringify(this.$store.getters.manualDatafileJson);
  },
  methods: {
    toggleDevPanel() {
      this.showPanel = !this.showPanel;
    },
    switchTab(tab) {
      this.currentTab = tab.name;
    },
    featureUpdated(updatedFeature) {
      // eslint-disable-next-line no-console
      console.warn('features update disabled!', updatedFeature);
      // this.$store.dispatch('updateFeatureFlags', updatedFeature);
    },
    optimizelyOverride(value) {
      this.$store.dispatch('disableOptimizely', value);
    },
    toggleDebugMode(value) {
      this.$store.dispatch('toggleOptimizelyDebug', value);
    },
    optimizelyOverrideDatafile(clearState) {
      const { dataFileJson } = this;
      if (clearState === true) {
        this.$store.dispatch('setManualDatafile', null);
      } else {
        this.$store.dispatch('setManualDatafile', JSON.parse(dataFileJson));
      }
    },
  },
};
</script>

<style lang="scss" scoped>

.panel-background {
  position: fixed;
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
  z-index: 100000;
  background-color: rgba(0,0,0,.1);
}

.dev-panel {
  position: fixed;
  top: 25px;
  right: 25px;
  z-index: 100000;
  width: 400px;
  bottom: 25px;
  overflow-y: scroll;
}

.dev-tabs {
  margin: 15px 0px 0px;
}

.dev-card {
  margin-bottom: 16px;
  animation-name: slideInRight;
  animation: slideInRight .3s;
  .description {
    font-family: "ClearSans", sans-serif;
    margin-bottom: 12px;
  }
}

.functional-area {
  margin-bottom: 40px;
}

.toggle-container {
  padding: 11px 0px;
  border-bottom: 1px solid #ececec;
  margin: 7px 0px;
  &:last-child {
    border-bottom: unset;
  }
  .toggle-name {
    font-size: 14px;
    font-family: "ClearSans", sans-serif;
  }
  .feature-toggle {
    float: right;
  }
}

.btn-container {
  display: flex;
  justify-content: flex-end;
  padding: 12px 0px 0px;
}

@keyframes slideInRight {
  from {
    transform: translate3d(100%, 0, 0);
    visibility: visible;
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}
</style>
