<template>
  <div class="bespoke-show">
    <section
      class="show-hero">
      <el-row>
        <grid-col
          :xs="24"
          :sm="24"
          :md="24"
          :lg="12"
          class="hero-image-container">
          <div class="image-container">
            <img
              :src="srcSet('src', heroImages)"
              :srcset="srcSet('srcset', heroImages)">
          </div>
        </grid-col>
        <grid-col
          :xs="24"
          :sm="24"
          :md="24"
          :lg="12">
          <h1 v-html="heroHeader"/>
          <h3 v-html="heroSubheader" />
          <div class="hero-btns">
            <div
              class="play-trailer-btn"
              @click="playTrailer">
              <img
                v-if="isPlayingTrailer"
                src="../../assets/images/ic_pause_teal_icon.svg"
                alt="Pause"
                class="pause-btn">
              <img
                v-else
                :class="{'play-btn': true, 'pulse': isLoadingTrailer }"
                src="../../assets/images/bespoke-play-btn-teal.svg"
                alt="Play">
              <i
                v-if="false"
                class="el-icon-loading text-blue"/>
              <span>{{ playBtnText }}</span>
            </div>
            <div class="cta-btn">
              <button
                ref="cta"
                @click="() => handleCTAClick(stickyTs ? 'sticky' : 'hero')">
                {{ heroCTAText }}
              </button>
            </div>
          </div>
        </grid-col>
      </el-row>
    </section>
    <section class="featured-section">
      <img
        v-if="isXsViewport"
        src="../../assets/images/trevor/left-ornament-mobile.png"
        class="left-ornament">
      <img
        v-else
        src="../../assets/images/trevor/left-ornament.png"
        class="left-ornament">
      <img
        v-if="isXsViewport"
        src="../../assets/images/trevor/right-ornament.png"
        class="right-ornament">
      <img
        v-else
        src="../../assets/images/trevor/right-ornament-mobile.png"
        class="right-ornament">
      <div class="content-container">
        <p>The Trevor Noah Podcast explores unfamiliar angles, embraces differing viewpoints, and celebrates the contradictions that make living in this modern world bewildering. And exciting.</p>
        <img
          :src="srcSet('src', featuredImageSrcSets)"
          :srcset="srcSet('srcset', featuredImageSrcSets)">
        <p class="second-text">Listen as Trevor and David turn catchphrases and soundbites into real conversation, inviting guests from every walk of life to take part.</p>
      </div>
    </section>
    <section class="big-image">
      <img
        :src="srcSet('src', bigImageSrcSets)"
        :srcset="srcSet('srcset', bigImageSrcSets)">
    </section>
  </div>
</template>

<script>
import * as log from 'loglevel';
import throttle from 'lodash/throttle';
import Podcast from '../../services/Podcast';
import PageMixin from '../../shared/pageMixin';
import Event from '../../services/PlayerEvent';
import heroImage1x from '../../assets/images/trevor/podcast_art@1x.jpg';
import heroImage2x from '../../assets/images/trevor/podcast_art@2x.jpg';
import featuredImage1x from '../../assets/images/trevor/featured_image@1x.jpg';
import featuredImage2x from '../../assets/images/trevor/featured_image@2x.jpg';
import bigImage1x from '../../assets/images/trevor/big_image@1x.jpg';
import bigImage2x from '../../assets/images/trevor/big_image@2x.jpg';


export default {
  name: 'TrevorNoahLandingPage',
  mixins: [PageMixin],
  data() {
    return {
      season_trailers: [],
      stickyCTA: false,
      stickyTs: null,
      podcast_service: new Podcast(),
      eventProvider: new Event(),
      display_image_url: 'https://img3.stage.luminarypodcasts.com/v1/podcast/8607a31a-cab7-4c9f-9df8-b6110ba93b52/thumbnail/h_360,w_360/image/s--e4L691d5--/aHR0cHM6Ly9tZWdhcGhvbmUtcHJvZC5zMy5hbWF6b25hd3MuY29tL3BvZGNhc3RzLzUxZjRhYzI2LTQxMWEtMTFlOS05Nzg3LWRmNTI4N2IyYjM2Mi9pbWFnZS91cGxvYWRzXzJGMTU4MDE2NjczMDg2MS03NjNoem9ibXg4bC02NGNiOTI2MjQ5NWI1MDE3NzJhNDgzYTlhZmM4YzhiNF8yRlMyX0ZJQVNDT19UZW1wU2hvd0FydF8zMDAweDMwMDAuanBn.jpg',
      showUUID: '13a59e33-974a-4ccd-a3a2-e17f8ac638f8',
      windowWidth: null,
      windowHeight: null,
      heroImages: [heroImage1x, heroImage2x],
      heroHeader: 'ALWAYS RELEVANT. <br>ALWAYS FUNNY.',
      heroSubheader: 'Trevor Noah and David Kibuuka add some much-needed thought—and thought-provoking humor—to the current cultural moment.',
      heroCTAText: 'START FREE TRIAL',
      bigImageSrcSets: [
        bigImage1x,
        bigImage2x,
      ],
      featuredImageSrcSets: [
        featuredImage1x,
        featuredImage2x,
      ],
      isPlaying: false,
    };
  },
  computed: {
    isMdViewport() {
      return this.windowWidth >= 1024;
    },
    isXsViewport() {
      return this.windowWidth <= 576;
    },
    playBtnText() {
      return `${this.isPlaying ? 'Pause' : 'Play'} Trailer`;
    },
    isLoading() {
      return false;
    },
    currentlyPlaying() {
      return this.$store.getters.episode;
    },
    isLoadingTrailer() {
      const { status } = this.$store.getters;

      return this.season_trailers.length && status === 'loading' && this.currentlyPlaying && (this.currentlyPlaying.uuid === this.season_trailers[0].uuid);
    },
    isPlayingTrailer() {
      const { play } = this.$store.getters;
      if (!this.season_trailers) {
        return false;
      }
      return this.season_trailers.length && play && this.currentlyPlaying && (this.currentlyPlaying.uuid === this.season_trailers[0].uuid);
    },
  },
  watch: {
    /* eslint-disable */
    windowHeight: function (newHeight) {
      this.adjustStickyCta();
    },
    /* eslint-enable */
  },
  beforeMount() {
    this.getSeasonTrailers();
  },
  mounted() {
    if (!this.$store.getters.hidePlayer) {
      this.togglePlayerVisiblity(true);
    }
    this.windowWidth = window.innerWidth;
    this.windowHeight = window.innerHeight;
    window.addEventListener('resize', () => {
      this.windowWidth = window.innerWidth;
      this.windowHeight = window.innerHeight;
    });
    window.addEventListener('scroll', () => this.handleScroll());
    window.addEventListener('scroll', throttle(this.checkVisibleSections, 1000));
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.togglePlayerVisiblity(true);
      vm.addNoIndexMeta();
    });
  },
  beforeRouteLeave(to, from, next) {
    this.togglePlayerVisiblity(false);
    this.removeNoIndexMeta();
    next();
  },
  methods: {
    handleCTAClick(btnId) {
      const { isFreeUser, isPremiumUser } = this.promoMeta;
      const routeTo = { path: '/signup' };
      if (isFreeUser) {
        routeTo.path = '/plans';
      } else if (isPremiumUser) {
        routeTo.path = '/listen/trevor-noah/on-second-thought-the-trevor-noah-podcast-luminary-exclusive/13a59e33-974a-4ccd-a3a2-e17f8ac638f8';
      }
      this.$analytics.track('Bespoke CTA Click', {
        screen_name: this.$route.name,
        path: this.$route.path,
        btnId,
      });
      this.$router.push(routeTo);
    },
    adjustStickyCta() {
      if (this.$refs.cta && this.stickyTs && this.isXsViewport) {
        this.$refs.cta.style.top = `${window.innerHeight - 110}px`;
      }
    },
    togglePlayerVisiblity(bool) {
      this.$store.dispatch('toggleHidePlayer', bool);
    },
    checkVisibleSections() {
      if (!this.viewTracker) {
        this.viewTracker = [...document.getElementsByTagName('section')]
          .map(section => section.className)
          .reduce((tracker, sectionName) => {
            tracker[sectionName] = false;
            return tracker;
          }, {});
      }
      const ctx = this;
      Object.entries(this.viewTracker)
        .forEach(([secName, hasViewed]) => {
          if (hasViewed) {
            return;
          }
          const e = document.getElementsByClassName(secName) && document.getElementsByClassName(secName)[0];
          const isVisible = (e && e.getBoundingClientRect() && e.getBoundingClientRect().y < window.innerHeight) || false;
          if (isVisible) {
            ctx.viewTracker[secName] = true;
            ctx.$analytics.track('Scrolled Into View', {
              screen_name: ctx.$route.name,
              path: ctx.$route.path,
              section: secName,
            });
          }
        });
    },
    handleScroll() {
      if (!this.isXsViewport) {
        return;
      }
      const ctaYPos = (this.$refs.cta && this.$refs.cta.getBoundingClientRect().y) || 1000;
      const ctaXPos = (this.$refs.cta && this.$refs.cta.getBoundingClientRect().x) || 1000;
      const viewPortThreshold = window.innerHeight - 110;
      const isSticky = ctaYPos < viewPortThreshold;
      const refParentDivYPos = (this.$refs.cta && this.$refs.cta.parentElement && this.$refs.cta.parentElement.getBoundingClientRect().y) || 1000;
      if (isSticky && !this.stickyTs) {
        this.$refs.cta.style.top = `${window.innerHeight - 110}px`;
        this.$refs.cta.style.left = `${ctaXPos}px`;
        this.$refs.cta.style.position = 'fixed';
        this.stickyTs = Date.now();
      }
      if (refParentDivYPos > viewPortThreshold && this.stickyTs && (Date.now() - this.stickyTs > 1000)) {
        this.$refs.cta.style.top = 'unset';
        this.$refs.cta.style.left = 'unset';
        this.$refs.cta.style.position = 'relative';
        this.stickyTs = null;
      }
    },
    next() {
      this.$refs.flickity.next();
    },
    previous() {
      this.$refs.flickity.previous();
    },
    playPauseTrailer() {
      this.isPlaying = !this.isPlaying;
    },
    playTrailer() {
      const trailer = this.season_trailers[0];
      trailer.episode_type = 'trailer';
      trailer.display_image_url = this.display_image_url;
      trailer.playedFrom = 'bespoke_sdp';
      this.togglePlayerVisiblity(true);
      if (this.currentlyPlaying.uuid === trailer.uuid && this.currentlyPlaying.playedFrom === trailer.playedFrom) {
        const { play } = this.$store.getters;

        if (!play) {
          if (this.currentlyPlaying.episode_type !== 'trailer') {
            this.eventProvider.triggerEpisodePauseEvent(this.currentlyPlaying, 'trailer_select');
          }
          this.$store.dispatch('play');
          this.eventProvider.triggerTrailerPlayEvent(trailer, 'sdp_select');
        } else {
          this.$store.dispatch('pause');
          this.eventProvider.triggerTrailerPauseEvent(trailer, 'sdp_pause');
        }
      } else {
        this.$store.dispatch('setTrailerAsEpisode', false);

        if (this.currentlyPlaying.episode_type !== 'trailer') {
          this.eventProvider.triggerEpisodePauseEvent(this.currentlyPlaying, 'trailer_select');
        }
        this.$store.dispatch('setTrailerExperienceTriggered', false); // Make sure trailer experience does not trigger from here.
        this.loadMediaInPlayer(trailer.media_url, trailer.is_exclusive, trailer.episode_type, true);
        this.$store.dispatch('setNextEpisode', {}); // Empty next episode because playback should stop after trailer complete from SDP.
        this.$store.dispatch('setAndPlayEpisode', trailer);
        this.eventProvider.triggerTrailerPlayEvent(trailer, 'sdp_select');
      }
    },
    getSeasonTrailers() {
      this.podcast_service
        .getPodcastTrailers(this.showUUID)
        .then((res) => {
          this.season_trailers = res.trailers || [];
          this.season_trailers = this.season_trailers.sort((a, b) => new Date(b.released_at) - new Date(a.released_at));
        })
        .catch((error) => {
          log.error(error);
        });
    },
  },
};
</script>

<style lang='scss' scoped>
@import "../../assets/scss/variables.scss";

$heroBgColor: #023778;
$ctaBtnColor: #FFFFFF;
$ctaBtnTextColor: #e17f38;
$ctaBtnFontSize: 16px;
$playBtnTextColor: #0eadad;
$playBtnFontSize: 16px;
$bespokeFont: 'CaslonsEgyptian', sans-serif;

.bespoke-show {
  background-color: $heroBgColor;
  color: white;
  font-family: $bespokeFont;
  display: flex;
  flex-direction: column;
  p, h1, h3, button {
    font-family: $bespokeFont;
    color: white;
  }
  .hidden-div {
    visibility: hidden;
  }
  .sticky-div {
    position: fixed;
    .cta-btn {
      display: flex;
      @media(max-width: $md) {
        width: 100%;
        justify-content: center;
        margin-bottom: 28px;
      }
      width: 50%;
      button {
        width: 251px;
        height: 55px;
        background-color: $ctaBtnColor;
        font-size: $ctaBtnFontSize;
        text-align: center;
        border: 0;
        border-radius: 30px;
        color: $ctaBtnTextColor;
      }
    }
  }
  .show-hero {
    margin-right: auto;
    margin-left: auto;
    padding-top: 78px;
    padding-bottom: 78px;
    width: 95vw;
    max-width: 1138px;
    @media(max-width: $xs) {
      width: 100%;
      padding-top: 0px;
      padding-bottom: 16px;
    }
    .hero-image-container {
      .image-container {
        width: 100%;
        display: flex;
        @media(max-width: $md) {
          justify-content: center;
        }
        @media(max-width: $xs) {
          width: 100vw;
          height: 100vw;
        }
        img {
          width: 100%;
          @media(max-width: $xs) {
            object-fit: contain;
          }
          @media(min-width: $xs) {
            width: 520px;
          }
          @media(min-width: 1024px) and (max-width: 1200px) {
            max-width: 85%;
            padding-left: 5%;
          }
        }
      }
    }
    h1 {
      margin-top: 30px;
      font-size: 46px;
      font-weight: 900;
      margin-bottom: 32px;
      padding-left: 18px;
      padding-right: 18px;
      @media(max-width: $md) {
        margin-top: 24px;
        margin-bottom: 16px;
        text-align: center;
        font-size: 24px;
        max-width: 80%;
        margin-left: auto;
        margin-right: auto;
      }
      @media(max-width: $xs) {
        font-size: 24px !important;
      }
      @media(max-width: $lg) {
        font-size: 37px;
      }
    }
    h3 {
      font-size: 24px;
      line-height: 1.5;
      margin-bottom: 69px;
      padding-left: 18px;
      padding-right: 22px;
      text-align: left;
      @media(max-width: $xs) {
        font-size: 16px;
        padding-left: 0px;
        padding-right: 0px;
        line-height: 1.5;
        text-align: left !important;
      }
      @media(max-width: $md) {
        max-width: 80%;
        margin-left: auto;
        margin-right: auto;
        line-height: 30px;
        text-align: center;
        margin-bottom: 38px;
      }
    }
    .hero-btns {
      display: flex;
      padding-left: 18px;
      padding-right: 22px;
      @media(max-width: $xs) {
        flex-direction: column;
        justify-content: center;
      }
      .play-trailer-btn {
        width: 50%;
        display: flex;
        align-items: center;
        @media(max-width: $md) {
          width: 100%;
          justify-content: center;
          margin-bottom: 28px;
        }
        img, svg {
          width: 59px;
          height: 59px;
          margin-right: 19px;
        }
        span {
          color: $playBtnTextColor;
          font-size: $playBtnFontSize;
        }
        .pause-btn {
          transform: scale(.5) translateX(-3px);
        }
      }
      .cta-btn {
        display: flex;
        z-index: 9980;
        height: 55px;
        @media(max-width: $md) {
          width: 100%;
          justify-content: center;
          margin-bottom: 28px;
        }
        width: 50%;
        button {
          transition: 500ms ease-in-out all;
          width: 251px;
          height: 55px;
          background-color: $ctaBtnColor;
          font-size: $ctaBtnFontSize;
          text-align: center;
          border: 0;
          border-radius: 30px;
          color: $ctaBtnTextColor;
        }
      }
    }
  }
  .featured-section {
    background-color: #f8c744;
    color: black;
    position: relative;
    .left-ornament {
      position: absolute;
      top: 0;
      left: 0;
      width: 304px;
      z-index: 0;
      @media(max-width: $xs) {
        width: 100%;
      }
    }
    .right-ornament {
      position: absolute;
      bottom: 0;
      right: 0;
      width: 308px;
      z-index: 0;
      @media(max-width: $xs) {
        width: 100%;
      }
    }
    .content-container {
      max-width: 1138px;
      margin-left: auto;
      margin-right: auto;
      color: #091f58;
      position: relative;
      z-index: 2;
      h2 {
        max-width: 882px;
        color: #091f58;
        font-size: 24px;
        font-weight: 900;
        line-height: 1.21;
        margin-left: auto;
        margin-right: auto;
        z-index: 2;
        margin-top: 90px;
        margin-bottom: 40px;
        @media(max-width: $md) {
          max-width: 85%;
        }
        @media(max-width: $xs) {
          margin-top: 90px;
          padding-left: 23px;
          padding-right: 23px;
        }
      }
      .second-text {
        margin-top: 44px;
        margin-bottom: 116px;
      }

      p {
        max-width: 882px;
        color: #091f58;
        font-size: 20px;
        line-height: 1.45;
        font-weight: normal;
        margin-left: auto;
        margin-top: 115px;
        margin-bottom: 36px;
        z-index: 2;
        @media(max-width: $md) {
          max-width: 85%;
        }
        @media(max-width: $xs) {
          // margin-top: 38px;
          // margin-bottom: 160px;
          padding-left: 23px;
          padding-right: 35px;
          font-size: 16px;
        }
      }
      img {
        width: 707px;
        margin-right: auto;
        margin-left: auto;
        display: block;
        @media(max-width: $sm) {
          width: 613px;
        }
        @media(max-width: $xs) {
          width: 100%;
        }
      }
    }
    .cta-btn {
      display: flex;
      flex-grow: 1;
      justify-content: center;
      padding-bottom: 78px;
      @media(max-width: $xs) {
        display: none;
      }
      button {
        width: 251px;
        height: 55px;
        background-color: #c10000;
        font-size: 17px;
        text-align: center;
        border: 0;
        border-radius: 30px;
        color: white;
      }
    }
  }
  .big-image {
    width: 100%;
    background-color: black;
    img {
      width: 100%;
    }
  }

  .pulse {
    animation-name: pulse;
    animation-duration: 800ms;
    animation-fill-mode: both;
    animation-iteration-count: infinite;
  }
}
@keyframes pulse {
  from {
    transform: scale3d(1, 1, 1);
  }

  50% {
    transform: scale3d(1.05, 1.05, 1.05);
  }

  to {
    transform: scale3d(1, 1, 1);
  }
}
</style>
