<template>
  <div
    class="card-list-wrapper"
    data-test="card-list-wrapper"
  >
    <div
      v-for="(episode, index) in episodes"
      :key="index">
      <div
        v-if="showSeasons"
        class="season-heading container">
        <div
          v-if="index === 0 || (index > 0 && episodes[index - 1].season.number !== episodes[index].season.number)"
          class="season-number"
        >
          <p
            v-if="index === 0"
            class="seasonTag">
            Season {{ episode.season.number }}
          </p>
          <p
            v-if="index > 0 && episodes[index - 1].season.number !== episodes[index].season.number"
            class="seasonTag">
            Season {{ episode.season.number }}
          </p>
          <div class="horizontal-line" />
        </div>
      </div>
      <!-- <transition-group name="el-fade-in"> -->
      <card-list
        :key="index"
        :shows="episode"
        :podcast="podcast || episode.season.podcast"
        :season_no="(episode.season && episode.season.number) || 0"
        :episode_no="episode.episode_number"
        :is-exclusive="(episode.is_exclusive || isShowPremium(episode)) && showPremiumIcon"
        :is-free="isShowPremium(episode) && !episode.is_exclusive"
        :bucket="bucket"
        :playlist-type="playlistType"
        @deleted="deleteEpisode"
      />
      <!-- </transition-group> -->
    </div>
  </div>
</template>

<script>
import ScrollMixin from '../shared/scrollMixin';
import CardList from './CardList';

export default {
  name: 'CardListWrapperComponent',
  components: {
    CardList,
  },
  mixins: [ScrollMixin],
  props: {
    podcast: {
      type: Object,
      default: () => {},
    },
    episodes: {
      type: Array,
      default: () => [],
    },
    showSeasons: {
      type: Boolean,
      default: false,
    },
    bucket: {
      type: Object,
      default: () => {},
    },
    playlistType: {
      type: String,
      default: 'show',
    },
    showPremiumIcon: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
    };
  },
  watch: {
    episodes(newVal) {
      if (newVal.length) {
        this.contentList = this.episodes;
      }
    },
  },
  beforeMount() {
    this.parentSelector = 'card-list-wrapper';
    this.childSelector = '.card-list-wrapper .show-card-list.is-visible';
  },
  mounted() {
    this.contentList = this.episodes;
  },
  methods: {
    isShowPremium(episode) {
      return episode.season && episode.season.podcast && episode.season.podcast.is_exclusive;
    },
    deleteEpisode(episodeIndex) {
      this.$emit('deleted', episodeIndex);
    },
  },
};
</script>

<style lang="scss">
.card-list-wrapper {
  .season-heading {
    border-top: 3px solid transparent;
    margin-top: -4px;
    z-index: 998;
    position: relative;
  }
}
</style>
