<template>
  <div
    id="app"
    :class="appClasses">
    <dev-panel v-if="devMode" />
    <Loader
      v-if="showLoader && isRedirectedFromBranch && !isInternationalUser"
      label=""
      absolute
      center
      with-overlay
    />
    <div
      v-if="renderPages"
      class="main-container">
      <eyebrow />
      <Navbar
        v-if="isHeaderVisible"
      />
      <preload-payment-form />
      <router-view
        :key="$route.fullPath"
        style="position: relative; flex: 1 0 auto;"
        data-router-view
        @toggleNavBar="toggleNavBar"
      />
      <audio-player/>
      <FooterDark
        v-if="isFooterVisible"
      />
      <InternationalFooter
        v-else-if="isInternationalUser && !isApplePodcastsEnabled"
      />
    </div>
    <transition name="slide-up">
      <CookiePolicy
        v-if="showCookiePolicy"
      />
    </transition>
    <promo-offer-modal
      :show="showPromoOfferModal"
      :promo-data="promoData"
      @closePromoOfferModal="closePromoOfferModal"
    />
    <holiday-promo-modal
      :show_modal="showHolidayPromoModal"
      :promo_type="2"
      @onHolidayPromoClose="onHolidayPromoClose"/>
    <custom-info
      :show="showInfoModal"
      :header="infoHeader"
      :description="infoDescription"
      @closeInfoModal="closeInfoModal"
    />
    <premium-modal
      v-if="!holidayPromoEnabled"
      :show_modal="showPremiumModalLegacy"
      @hidePremiumModal="hidePremiumModal" />
    <holiday-promo-modal
      v-if="holidayPromoEnabled"
      :show_modal="holidayPromoEnabled && showPremiumModal"
      :promo_type="4"
      @hidePremiumModal="hidePremiumModal"/>
    <img
      v-if="winstarCustomPixel"
      :src="winstarCustomPixel"
      class="tracking-pixel"
    >
    <payment-second-step/>
  </div>
</template>
<script>
// import InstaBug from 'instabug-sdk'
import * as log from 'loglevel';
import { mapGetters } from 'vuex';
import Navbar from './components/Navbar';
import DevPanel from './components/DevPanel';
import FooterDark from './components/FooterDark';
import AudioPlayer from './components/AudioPlayer';
import InternationalFooter from './components/InternationalFooter';
import CookiePolicy from './components/CookiePolicy';
import PromoOfferModal from './components/PromoOfferModal';
import PremiumContentModal from './components/PremiumContentModal';
import Loader from './components/Loader';
import getFirebase from './config/firebase';
import EpisodeService from './services/Episode';
import PlaybackService from './services/PlaybackService';
import User from './services/User';
import Branch from './services/Branch';
import Event from './services/PlayerEvent';
import Subscription from './services/Subscription';
import utility from './shared/utility';
import Countries from './assets/json/countries.json';
import euCountries from './assets/json/eu_countries.json';
import Creators from './assets/json/creators.json';
import CustomInfoModal from './components/InfoCustomModal';
import PreloadAdyenBuyPremium from './pages/onboarding/PreloadAdyenBuyPremium';
import Eyebrow from './components/holidaypromo/Eyebrow';
import PromotionModal from './components/PromotionModal';
import PaymentSecondStep from '@/components/subscription/PaymentSecondStep';
import promocodeMixin from './shared/promocodeMixin';
import OptimizeService from '@/services/Optimize';
import { getServices } from '@/services/serviceProvider';

import 'vue-phone-number-input/dist/vue-phone-number-input.css';

const [optimizeService] = getServices([OptimizeService]);
const firebase = getFirebase();

export default {
  components: {
    CookiePolicy,
    'audio-player': AudioPlayer,
    Navbar,
    FooterDark,
    InternationalFooter,
    Loader,
    'promo-offer-modal': PromoOfferModal,
    'custom-info': CustomInfoModal,
    'premium-modal': PremiumContentModal,
    'preload-payment-form': PreloadAdyenBuyPremium,
    'dev-panel': DevPanel,
    eyebrow: Eyebrow,
    'holiday-promo-modal': PromotionModal,
    PaymentSecondStep,
  },
  mixins: [
    promocodeMixin,
  ],
  data() {
    return {
      showNavBar: false,
      isLoggedIn: false,
      userService: new User(),
      branchService: new Branch(),
      episodeService: new EpisodeService(),
      playbackService: new PlaybackService(),
      eventProvider: new Event(),
      subscriptionProvider: new Subscription(),
      countries: Countries,
      euCountries,
      defaultCurrency: '',
      showPromoOfferModal: false,
      promoData: {},
      loader: false,
      isSafari: !!navigator.userAgent.match(/safari/i) && !navigator.userAgent.match(/chrome/i) && typeof document.body.style.webkitFilter !== 'undefined' && !window.chrome,
      creators: Creators,
      clientX: 0,
      clientY: 0,
      firstClientX: 0,
      firstClientY: 0,
      showInfoModal: false,
      infoHeader: '',
      infoDescription: '',
      renderPages: false,
      retries: 0,
      holidayPromoHasShown: false,
      holidayPromoShownThisSession: false,
      postponedApiFetched: false,
      isConsentScriptReady: false,
    };
  },
  computed: {
    ...mapGetters('experiment', [
      'signUpFlow',
    ]),
    showLoader() {
      return this.isAuthRoute && this.loader;
    },
    isAuthRoute() {
      return window.LUM__isAuthRoute;
    },
    user() {
      return this.$store.getters.user;
    },
    libPodcastsIds() {
      return this.$store.getters.libraryPodcasts;
    },
    libEpisodesIds() {
      return this.$store.getters.libraryEpisodes;
    },
    showCookiePolicy() {
      const userCookiePolicy = !this.$store.getters.acceptedCookiePolicy && this.inEU;
    },
    inEU() {
      const { country } = this.$store.getters;

      if (country) {
        return euCountries.find(euCountry => euCountry.code === country.toUpperCase());
      }

      return false;
    },
    isRedirectedFromBranch() {
      const hasBranchMatchId = utility.getQueryParamValue('_branch_match_id');
      return hasBranchMatchId;
    },
    appClasses() {
      const { episode, playerExpanded, hidePlayer } = this.$store.getters;
      const { isSafari } = this;
      return {
        'is-safari': isSafari,
        'audio-player-collapsed': !playerExpanded,
        'audio-player-visible': episode && episode.uuid && !hidePlayer,
      };
    },
    userSubscription() {
      return this.$store.getters.userSubscription;
    },
    showPremiumModal() {
      return this.$store.getters.showPremiumModal;
    },
    isAcquisitionFlow() {
      return this.$store.getters.isAcquisitionFlow1;
    },
    isRedeemGiftFlow() {
      return this.$store.getters.isRedeemGiftFlow;
    },
    devMode() {
      const env = process.env.NODE_ENV;
      return (env === 'development') || (env === 'stage') || (env === 'preprod');
    },
    showHolidayPromoModal() {
      const { user } = this.$store.getters;
      const isPremiumUser = user && user.uuid && user.is_subscribed;
      const holiday_promo = this.$store.getters.flagWithDefault('holiday_promo', false);
      const { path, query } = this.$route;
      const isAlexaFlow = query.alexa === 'true';
      const isValidPath = [
        '/premium-buy',
        '/user-categories',
        '/account/subscription',
        '/account',
        '/terms',
        '/privacy',
        '/careers',
        '/contact',
        '/ios-terms',
        '/android-terms',
        '/promoterms',
        '/not-found/404',
      ].indexOf(path) < 0;
      return isValidPath && holiday_promo && !this.holidayPromoShownThisSession && !isPremiumUser && !isAlexaFlow;
    },
    holidayPromoEnabled() {
      return this.$store.getters.flagWithDefault('holiday_promo', false);
    },
    showPremiumModalLegacy() {
      const { showPremiumModal } = this.$store.getters;
      const holiday_promo = this.$store.getters.flagWithDefault('holiday_promo', false);
      return !holiday_promo && showPremiumModal;
    },
    hideHeader() {
      return this.$route.meta && this.$route.meta.hideHeader;
    },
    hideFooter() {
      return this.$route.meta && this.$route.meta.hideFooter;
    },
    isExperimentalSignup() {
      return this.signUpFlow && this.$route.matched[1] && this.$route.matched[1].name === 'account_create';
    },
    isHeaderVisible() {
      return !this.isAcquisitionFlow && !this.isRedeemGiftFlow && !this.hideHeader && !this.isExperimentalSignup;
    },
    isFooterVisible() {
      return (!this.isInternationalUser || this.isApplePodcastsEnabled) && !this.isAcquisitionFlow && !this.hideFooter && !this.isExperimentalSignup;
    },
  },
  watch: {
    userSubscription(newVal) {
      const usersCountry = this.$store.getters.country;
      if (newVal && newVal.uuid && newVal.country && usersCountry && newVal.country !== usersCountry) {
        // this.$store.dispatch('setUserCountry', newVal.country);
        this.fetchSubscriptionPlans(newVal.country, true);
      }
    },
    $route(newRoute, oldRoute) {
      if (newRoute.query.cancellation_flow) {
        const { query: { cancellation_flow, ...restQuery }, path } = newRoute;
        if (cancellation_flow === 'save') optimizeService.triggerCancellationPrimaryObjective();
        if (cancellation_flow === 'cancel') optimizeService.triggerCancellationSecondaryObjective();
        this.$router.replace({ path, query: { ...restQuery } });
        return;
      }
      if (!newRoute.meta.isStatic && oldRoute.meta.isStatic && !this.postponedApiFetched) {
        this.postponedApiFetched = true;
        this.initUser();
        this.fetchSubscriptionPlans();
      }
    },
  },
  mounted() {
    this.disableVerticalScrolling();
    const ctx = this;
    window.addEventListener('beforeunload', () => {
      ctx.eventProvider.triggerRefreshEvent();
    });
    if (!this.$route.meta.isStatic) {
      // postpone server requests
      this.postponedApiFetched = true;
      this.fetchSubscriptionPlans();
    }
    this.getBrowsersFingerPrintId();
    this.registerOnLogoutEvent(); // This event will be called when user will be logged out
    window.eventBus.$on('consentScriptLoaded', () => {
      this.isConsentScriptReady = true;
    });
    if (window.Luminary.promises.cookieProLoaded) {
      window.Luminary.promises.cookieProLoaded.then(() => {
        this.$store.dispatch('acceptCookiePolicy', window.OnetrustActiveGroups.split(',').includes('C0001'));
        window.OneTrust.OnConsentChanged(({ detail }) => {
          this.$store.dispatch('acceptCookiePolicy', detail.includes('C0001'));
        });
      });
    }
  },
  beforeMount() {
    // don't use optimize for this two features, experimental options approved and set in vuex by default
    // this.$store.dispatch('experiment/registerSignUpFlowCallback');
    // this.$store.dispatch('experiment/registerCancellationFlowCallback');
    this.setUserCountry();
    // attached with window to be available in services
    window.$store = this.$store;
    window.$analytics = this.$analytics;
    window.$analyticsV2 = this.$analyticsV2;
    if (!this.$route.meta.isStatic) {
      // postpone server requests
      this.postponedApiFetched = true;
      this.initUser();
    } else {
      this.renderPages = true;
    }
  },
  methods: {
    initUser() {
      // If user is logged in before and cookies is expired i.e. not found
      // then refresh the firebase session cookie
      const isLoggedIn = this.$store.getters.loggedIn;

      // if alexa query param is present, don't try to refresh the firebase session cookie
      if (this.$route.query.alexa || !window.LUM__ISAUTHROUTE) {
        this.renderPages = true;
        this.checkLastPlayedEpisode();
        if (isLoggedIn && !this.$route.query.alexa) {
          this.setFbAuthChangeObserver();
        }
      } else if (isLoggedIn && (!this.isFbsCookieExist() || this.isFbsCookieAboutToExpire()) && window.LUM__ISAUTHROUTE) {
        this.userService.loginToLuminary()
          .then(() => this.setFbAuthChangeObserver())
          .catch(() => {
            this.renderPages = true;
          });
      } else if (isLoggedIn && (!this.isFbsCookieExist() || this.isFbsCookieAboutToExpire()) && !window.LUM__ISAUTHROUTE) {
        firebase.onAuthStateChanged((user) => {
          if (user) {
            this.userService.loginToLuminary()
              .then(() => this.setFbAuthChangeObserver())
              .catch(() => {
                this.renderPages = true;
              });
          }
        });
      } else {
        this.setFbAuthChangeObserver();
      }
    },
    setFbAuthChangeObserver() {
      this.renderPages = true;
      firebase.onAuthStateChanged((user) => {
        if (user) {
          const isLoggedIn = this.$store.getters.loggedIn;
          if (isLoggedIn) {
            this.verifyUserToken();
            this.getUserEpisodeListens();
            this.$store.dispatch('populateUserLibrary');

            if (this.user && this.user.is_subscribed) {
              this.fetchSubscription();
            }
          }
        } else {
          this.userService.logout();
        }
      });
    },
    disableVerticalScrolling() {
      if (!window || !window.isTouch || !this.isBrowserSafari()) return;
      // Disable vertical scrolling during horizontal swipe. Introduced for safari.
      window.addEventListener('touchstart', this.touchStart);
      window.addEventListener('touchmove', this.preventTouch, { passive: false });
    },
    touchStart(e) {
      this.firstClientX = e.touches[0].clientX;
      this.firstClientY = e.touches[0].clientY;
    },
    /* eslint-disable */
    preventTouch(e) {
      const minValue = 5; // threshold

      this.clientX = e.touches[0].clientX - this.firstClientX;
      this.clientY = e.touches[0].clientY - this.firstClientY;

      // Vertical scrolling does not work when you start swiping horizontally.
      if (Math.abs(this.clientX) > minValue) {
        e.preventDefault();
        e.returnValue = false;
        return false;
      }
    },
    verifyUserToken() {
      this.userService.verifyAuthentication().then(() => {
        this.refreshUser();
        this.checkLastPlayedEpisode();
      });
    },
    toggleNavBar(show) {
      this.showNavBar = show;
    },
    checkLastPlayedEpisode() {
      if (this.$store.getters.loggedIn) {
        const eepisodeService = new EpisodeService();
        eepisodeService.playLastPlayedEpisode();
      }
    },
    refreshUser() {
      this.userService.refreshUser().then((user) => {
        this.$analytics.identify(user.uuid);
      });
    },
    getUserEpisodeListens() {
      this.episodeService.getListenedEpisodes().then((episodesProgress) => {
        this.$store.dispatch('setAllEpisodesProgress', episodesProgress || []);
      });
    },
    fetchSubscription() {
      this.subscriptionProvider.getSubscription().then((resp) => {
        if (resp && resp.platform) {
          this.$store.commit('setUserSubscription', resp);
          const defaultPricing = {
            currency: resp.plan.currency,
            price: resp.plan.total_price,
          };
          if (resp.platform === 'web') {
            this.$store.commit('setPricing', defaultPricing);
          }
        }
      });
    },
    fetchSubscriptionPlans(subscriptionCountry, doNotFetchBranchMetaData) {
      if (!doNotFetchBranchMetaData){
        this.loader = true;
      }
      const countryCode = subscriptionCountry || this.$store.getters.country || 'US';
      let country = this.countries.find(nextItem => nextItem.code === countryCode);
      // if user belongs to country other than US/UK/AU/CA then show pricing for US
      if (!country) {
        country = this.countries.find(nextItem => nextItem.code === 'US');
      }
      this.defaultCurrency = country.currency;
      this.$store.commit('setUserCurrency', this.defaultCurrency);
      this.subscriptionProvider.fetchSubscriptionPlans(country.currency, country.code).then((response) => {
        this.loader = false;
        this.$store.commit('setPlansLoaded', true);
        if (response.plan && response.plan.productRatePlanCharges && response.plan.productRatePlanCharges.length) {
          this.$store.commit('setActivePlan', response.plan);

          this.setDefaultPricing(response.plan);
          if (!this.supportedCountries.includes(countryCode)) {
            this.loader = false;
            return;
          }
          if (!doNotFetchBranchMetaData){
            this.readBranchLinkData();
          }
        }
      })
        .catch(() => {
          this.loader = false;
        });
    },
    setDefaultPricing(plan) {
      let countryCode = this.$store.getters.country || 'US';
      countryCode = countryCode.toLowerCase();
      let country = this.countries.find(nextItem => nextItem.code.toLowerCase() === countryCode);
      // if user belongs to country other than US/UK/AU/CA then show pricing for US
      if (!country) {
        country = this.countries.find(nextItem => nextItem.code === 'US');
      }

      const productRatePlanCharges = plan.productRatePlanCharges[plan.productRatePlanCharges.length - 1];
      let pricing = productRatePlanCharges.pricing.find(nextItem => nextItem.currency === country.currency);

      // if user have subscribed before and country in user's subscription record is different from default country
      // then set default pricing of country found in user's subscription record
      const { userSubscription } = this.$store.getters;
      if (userSubscription && userSubscription.platform) {
        const totalPrice = this.validateTotalPriceLocally(userSubscription.plan.country, userSubscription.plan.total_price);
        pricing = {
          currency: userSubscription.plan.currency,
          price: totalPrice,
        };
      }
      if (pricing && pricing.price && pricing.currency) {
        this.$store.commit('setPricing', pricing);
      }
    },
    readBranchLinkData() {
      // Read Deep link data and navigate user accordingly
      this.loader = true;
      const ctx = this;
      if (window.branch) {
        this.readLinkData();
      } else if (this.retries < 10) {
        this.retries++;
        setTimeout(() => { ctx.readBranchLinkData(); }, 2000);
      } else {
        this.loader = false;
      }
    },
    readLinkData() {
      this.branchService.readDeeplink()
        .then((data) => {
          this.loader = false;
          // this.storeBranchData(data.data);
          this.storeBranchLink(data.data_parsed);
          if (data.data_parsed && data.data_parsed.episode_uuid && !data.data_parsed.$fallback_url) {
            this.$router.push(`/listen/${data.data_parsed.publisher_slug || 'publisher'}/${data.data_parsed.show_slug || 'show'}/${data.data_parsed.episode_slug || 'episode'}/${data.data_parsed.episode_uuid}`);
          } else if (data.data_parsed && data.data_parsed.podcast_uuid && !data.data_parsed.$fallback_url) {
            this.$router.push(`/listen/${data.data_parsed.publisher_slug || 'publisher'}/${data.data_parsed.show_slug || 'show'}/${data.data_parsed.podcast_uuid}`);
          } else if (data.data_parsed && data.data_parsed.promo_code) {
            this.loader = true;
            // this.promoCodeHandling(data.data_parsed.promo_code, data.data_parsed.promo_header, data.data_parsed.promo_subheader);
            this.promoCodeHandling(data.data_parsed);
          } else if (data.data_parsed && data.data_parsed.referee) {
            this.creatorLinksHandling(data.data_parsed);
          }
        })
        .catch((error) => {
          log.warn(error.message);
          this.loader = false;
        });
    },
    setUserCountry() {
      const countryCode = utility.getQueryParamValue('country');
      let defaultCountry;
      if (this.$route && this.$route.query.country) {
        // Store it in the local variable
        defaultCountry = this.$route.query.country;
        // Remove country code from query param
        const query = Object.assign({}, this.$route.query);
        delete query.country;
        this.$router.replace({ query });
      } else if (countryCode) {
        defaultCountry = countryCode;
      } else if (window.Luminary && window.Luminary.country && window.Luminary.country !== '_COUNTRY_PLACEHOLDER_') {
        defaultCountry = window.Luminary.country;
      } else {
        defaultCountry = (this.$store && this.$store.getters && this.$store.getters.country) || 'US';
      }
      // set default pricing before it load from products
      let country = this.countries.find(nextItem => nextItem.code === defaultCountry);
      // if user belongs to country other than US/UK/AU/CA then show pricing for US
      if (!country) {
        country = this.countries.find(nextItem => nextItem.code === 'US');
      }
      this.$store.commit('setPricing', { currency: country.currency, price: country.price });

      // Store it for future references
      this.$store.dispatch('setUserCountry', defaultCountry);
      if (!this.supportedCountries.includes(defaultCountry) && !this.$store.getters.featureFlag('apple_podcasts')) {
        this.$router.push('/international');
      }
    },
    openPromoOfferModal() {
      if (this.user && this.user.is_on_boarded) return;
      this.showPromoOfferModal = true; // FIXME(jtabach): revert to false when holiday promo back on
    },
    closePromoOfferModal(flag, newAcquisitionFlow) {
      this.showPromoOfferModal = false;
      if (flag) {
        this.$router.push('/signup');
      }
    },
    promoLinkValidateResponse(resp) {
      this.loader = false;
      if (resp && resp.valid) {
        this.promoData = resp.promoData;
        this.openPromoOfferModal();
        if (!this.$store.getters.flagWithDefault('holiday_promo', false)) {
          this.openPromoOfferModal();
        }
      }
    },
    validateTotalPriceLocally(country, totalPrice) {
      const { pricing } = this.$store.getters;
      const targetCountry = this.countries.find(countryCode => countryCode === country);
      if (totalPrice > 0) {
        return totalPrice;
      }
      return targetCountry && targetCountry.price ? targetCountry.price : pricing.price;
    },
    getBrowsersFingerPrintId() {
      if (!window.branch || (window.branch && !window.branch.getBrowserFingerprintId)) {
        return;
      }
      window.branch.getBrowserFingerprintId((err, data) => {
        if (data) {
          localStorage.setItem('branch_fingerprint_id', data);
        }
      });
    },
    promoCodeHandling(branchMetaData) {
      const { country } = this.$store.getters;
      if (!this.supportedCountries.includes(country)) {
        this.loader = false;
        return;
      }
      const referring_link = branchMetaData['~referring_link'];
      const breaking_point = referring_link.indexOf('?');
      const referrer = breaking_point > -1 ? referring_link.substr(breaking_point) : '';
      const branch_match_id = utility.getQueryParamValue('_branch_match_id');
      const promoData = {
        promoCode: branchMetaData.promo_code,
        trialPeriod: 1,
        trialEndDate: '',
        header: branchMetaData.promo_header_new || 'We’ve applied a special offer for you.',
        subheader: branchMetaData.promo_subheader,
        referrer: referrer,
        search: referrer,
        firstname: branchMetaData.referee_first_name,
        lastname: branchMetaData.referee_last_name,
        branch_referrer: `${branchMetaData.referee_first_name} ${branchMetaData.referee_last_name}: ${branch_match_id}`,
        branch_referer_id: `/${localStorage.getItem('branch_promo')}`,
      };
      this.subscriptionProvider.validateAndStorePromoCode(promoData, this.defaultCurrency)
        .then(this.promoLinkValidateResponse)
        .catch(() => {
          this.loader = false;
          log.warn("Promo code not available: you've already subscribed using a promo code.");
        });
    },
    creatorLinksHandling(branchMetaData) {
      const creatorUsername = branchMetaData.referee;
      const flag = this.creators.includes(creatorUsername);
      if (!flag) {
        return;
      }
      const twoMonthsCreators = ['billsimmons','deathsexmoney','ideacast','hiddenbrain','MDWAP','overmy','planetmoney','podsaveamerica','popculture','radiolab','slowburn','tedart','tedbusiness','tedculture','tednews','rewatchables','upandvanished','upfirst','nightvale','gals','upfirst','nightvale','gals'];
      let promoCode = 'creators';
      const promoHeader = 'Welcome to Luminary';
      let promoSubheader = "Sign up for Luminary Premium and access your first month of premium content for free. After that, the service is $7.99 per month and you can cancel anytime. Terms apply.";
      if (twoMonthsCreators.includes(creatorUsername)) {
        promoCode = 'twomonthfree';
        promoSubheader = "Sign up for Luminary Premium and access your two months of premium content for free. After that, the service is $7.99 per month and you can cancel anytime. Terms apply.";
      }

      branchMetaData.promo_code = promoCode;
      branchMetaData.promo_header = promoHeader;
      branchMetaData.promo_subheader = promoSubheader;
      this.promoCodeHandling(branchMetaData);
    },
    storeBranchData(stringifiedData) {
      if (stringifiedData === '') {
        return;
      }
      const data = JSON.parse(stringifiedData);
      Object.getOwnPropertyNames(data).forEach((key) => {
        if (typeof data[key] === 'boolean' || typeof data[key] === 'number') {
          data[key] = data[key].toString();
        }
      });
      localStorage.setItem('branch_data', JSON.stringify(data));
    },
    storeBranchLink(dataParsed) {
      if (dataParsed && dataParsed['~referring_link'])  {
        const referring_link = dataParsed['~referring_link'];
        const branch_promo = referring_link.split('?')[0].split('/').pop();
        localStorage.setItem('branch_promo', branch_promo);
      }
    },
    displayInfoModal() {
      this.infoHeader = 'Not eligible for promotion';
      this.infoDescription = 'You have already signed up for Luminary Premium. This offer is limited to new subscribers only.';
      this.showInfoModal = true;
    },
    closeInfoModal() {
      this.showInfoModal = false;
      this.redirectUser();
    },
    isFbsCookieExist() {
      const cookies = document.cookie.split("; ");
      let cookieName = '';
      switch (process.env.NODE_ENV) {
        case 'development':
          cookieName = 'fbsdev';
          break;
        case 'stage':
          cookieName = 'fbsstage';
          break;
        case 'preprod':
          cookieName = 'fbspreprod';
          break;
        default:
          cookieName = 'fbs';
          break;
      }
      let fbsCookieFound = false;
      for (let i = 0; i < cookies.length; i++) {
        const key = cookies[i].split("=");
        if (key[0] === cookieName) {
          fbsCookieFound = true;
          break;
        }
      }
      return fbsCookieFound;
    },
    isFbsCookieAboutToExpire() {
      var lastLoggedIn = this.$store.getters.lastLoggedIn;
      if (!lastLoggedIn) {
        return true;
      }
      lastLoggedIn = new Date(lastLoggedIn).getTime();
      let currentTime = new Date();
      currentTime = currentTime.getTime();
      let difference = currentTime - lastLoggedIn;
      difference = (difference / (1000 * 3600 * 24));

      log.info('Cookie updated since ', difference);
      // if 2.5 days are passed since last logged in, return true to refresh cookie
      if (difference >= 2.5) {
        return true;
      }
      return false;
    },
    hidePremiumModal() {
      this.$store.dispatch('hidePremiumModal');
    },
    registerOnLogoutEvent() {
      /* This event will be fired when user logout. We will reset default pricing
      according to users origin country provided by cloudflare */
      const ctx = this;
      window.eventBus.$on('hide-player', () => {
        const originCountry = ctx.$store.getters.country;
        ctx.fetchSubscriptionPlans(originCountry, true);
      });
    },
    onContentPages() {
      return this.$route.path.includes('/listen');
    },
    isAlexaAccountLinking() {
      const { state } = this.$route.query;
      const redirectUri = this.$route.query.redirect_uri;
      return !!(redirectUri && state);
    },
    onHolidayPromoClose() {
      this.holidayPromoShownThisSession = true;
    },
  },
};
</script>

<style lang="scss">
@import "assets/scss/mixins.scss";
@import "assets/scss/variables.scss";

#app {
  margin-bottom: 0;
  padding-bottom: 0;
  @include transition(padding 0.5s ease 0s);

  img.tracking-pixel {
    height: 1px;
    width: 1px;
    display: none;
  }
}
#app.audio-player-collapsed,
#app.audio-player-visible.audio-player-collapsed {
  padding-bottom: 0;
}
#app.audio-player-visible {
  padding-bottom: 70px;
}
#app.auth-footer-visible {
  // margin-bottom: 60px;
}
#app.audio-player-visible.auth-footer-visible {
  // margin-bottom: 132px;

  // .audio-player {
  //   bottom: 61px;
  // }
}
.fade-enter-active {
  transition: .4s all ease-in;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}
.main-container {
  min-height:100vh;
  display: -webkit-flex;
  display: flex;
  flex-direction: column;
}

#segment-consent-manager {
  position: fixed;
  bottom: 0;
  left: 50%;

  display: none;

  transform: translateX(-50%);

  width: 92%;

  border: 1px solid #BABABA;
  border-bottom: none;

  border-radius: 10px;

  > div > div {
    background-color: white;
  }
}

div[data-consent-manager-dialog] {
  overflow-x: unset !important;
}

div[data-consent-manager-dialog] > div {
  z-index: 10001;
  pointer-events: none;
}

div[data-consent-manager-dialog] > div > section {
  z-index: 10001;
  pointer-events: auto;
  width: 92%;
  margin: 0;
  border-radius: 7px 7px 0 0;
  bottom: 0;
  position: absolute;
  max-width: none;

  font-family: $clear-sans;

  // animation: none;

  padding: 25px;
  box-sizing: border-box;

  height: 85vh;

  > div {
    border-bottom: none;
    padding: 0;
    margin-bottom: 20px;

    h2 {
      font-size: 20px;
      color: #111111;
    }

    button {
      display: none;
    }
  }

  > form {
    > div {
      padding: 0;
      color: #666 !important;

      @media (max-width: 567px) {
        font-size: 12px !important;
      }

      &:nth-child(1) {
        > div {
          margin-top: 30px;
        }

        thead {
          th {
            background-color: #F8F8F8;
            color: #111;
            font-size: 16px;
          }
        }

        tbody {
          font-size: 14px;
        }

        @media (max-width: 567px) {
          table {
            th, td {
              font-size: 12px;

              > p {
                line-height: 18px;
              }
            }
          }
        }
      }

      &:nth-child(2) {
        margin-top: 30px;
        display: flex;

        > div {
          button {
            border-radius: 25px;
            font-size: 16px;

            padding: 12px 30px;
            height: auto;
            min-width: 102px;

            &:first-child {
              display: none;
            }
            // cursor: pointer;
          }

          button[type=submit] {
            background-color: #00AECB;
            background-image: none;
          }
        }
      }
    }
  }
}
/* Disbale background body scrolling effect on modal open */
.el-popup-parent--hidden{
  height: 100vh;
}

/* Global styling */

.el-button.block-btn {
  width: 314px;
  color: #FFFFFF;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  border-radius: 24px;
  display: block;
  padding-top: 15px;
  padding-bottom: 15px;
  position: relative;
  letter-spacing: 0.5px;
  margin-left: auto;
  margin-right: auto;
  cursor: pointer;
  text-decoration: none;
  box-sizing: border-box;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2), 0px 1px 10px rgba(0, 0, 0, 0.12), 0px 4px 5px rgba(0, 0, 0, 0.14);
  @media (max-width: 567px) {
    max-width: 285px;
    width: 100%;
    margin: auto;
  }
  &:focus {
    padding: 14px 0;
    border: 2px solid #FFFFFF;
  }
}

.spotlight {
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2), 0px 1px 10px rgba(0, 0, 0, 0.12), 0px 4px 5px rgba(0, 0, 0, 0.14);
}
</style>
