import uuidv1 from 'uuid/v1';
import get from 'lodash/get';
import * as log from 'loglevel';
import store from '../store';
import AnalyticsApi from '../services/Analytics';
import utility from './utility';
import nr_analyticsManager from './new-relic/analyticsManager';
import nr_addPageActions from './new-relic/addPageActions';


class EventTracking {
  constructor(options = {}) {
    this.options = options;
    this.online = true;
    this.analyticsService = new AnalyticsApi();
    this.interval = 30;
    this.sessionUuid = null;
    this.fallbackMode = false;
    this.expData = {};
    this.getSessionId();
    this.getOnlineStatus();
    this.queueManager();
    this.sdkEvents = ['ga_subscription_created', 'ga_account_created', 'landing_page_viewed', 'Button Click'];

    this.getExperimentData = this.getExperimentData.bind(this);
    this.hydrateAttributes = this.hydrateAttributes.bind(this);
    this.trackEvent = this.trackEvent.bind(this);
    this.trackPage = this.trackPage.bind(this);
    this.setUserExperimentAttributes = this.setUserExperimentAttributes.bind(this);
  }

  getSessionId() {
    const mparticleSessionId = window && window.mParticle && window.mParticle.sessionManager
    && window.mParticle.sessionManager.getSession && window.mParticle.sessionManager.getSession();
    if (mparticleSessionId) {
      this.sessionUuid = mparticleSessionId;
    } else {
      const ctx = this;
      setTimeout(() => {
        ctx.getSessionId();
      }, 10);
    }
  }

  mparticleLogin(event) {
    const mpartAnalyticsDisabled = this.getStore() && !this.getStore().getters.featureFlags.mpart_analytics;
    if (mpartAnalyticsDisabled) {
      return;
    }
    if (event === 'login') {
      const userIdentities = {};
      userIdentities.customerid = this.getStore().getters.user.uuid;
      userIdentities.email = this.getStore().getters.user.email;
      const identityRequest = { userIdentities };
      if (window && window.mParticle && window.mParticle.Identity) {
        window.mParticle.Identity.login(identityRequest);
      }
    } else if (event === 'logout') {
      if (window && window.mParticle && window.mParticle.Identity) {
        window.mParticle.Identity.logout();
      }
    }
  }

  getExperimentData() {
    return '';
  }

  setUserExperimentAttributes(expData) {
    const validExpData = expData && expData.experiments && expData.experiments.length && expData.variations && expData.variations.length;
    const currentExperiments = get(this.expData, 'experiments', '');
    const currentVariations = get(this.expData, 'variations', '');
    const expDataHasChanged = expData.experiments !== currentExperiments || expData.variations !== currentVariations;
    const user = (window && window.mParticle && window.mParticle.Identity && window.mParticle.Identity.getCurrentUser()) || null;
    if (user && validExpData && expDataHasChanged) {
      this.expData = expData;
      user.setUserAttribute('opt_exps', expData.experiments);
      user.setUserAttribute('opt_vars', expData.variations);
    }
  }

  hydrateAttributes(eventName, attributes = {}) {
    try {
      const hydratedAttributes = {
        ...attributes,
      };
      const expData = this.getExperimentData(eventName);
      if (expData && expData.experiments && expData.variations) {
        hydratedAttributes.opt_experiments = expData.experiments || 'null';
        hydratedAttributes.opt_variations = expData.variations || 'null';
        this.setUserExperimentAttributes(expData);
      }
      return hydratedAttributes;
    } catch (err) {
      console.log(err); // eslint-disable-line no-console
      return attributes;
    }
  }

  async trackEvent(event, attributes) {
    const { fallbackMode, hydrateAttributes } = this;
    // this.addToQueue('track', event, hydrateAttributes(event, attributes))
    //   .then(() => {
    //     if (fallbackMode) {
    //       this.sendQueue();
    //     }
    //   });
    await this.addToQueue('track', event, hydrateAttributes(event, attributes));
    if (fallbackMode) {
      this.sendQueue();
    }
  }

  trackPage(pageName, params) {
    const { fallbackMode, hydrateAttributes } = this;
    const urlParams = new URLSearchParams(window.location.search);
    const contentParam = urlParams.get('utm_content');
    const mediumParam = urlParams.get('utm_medium');
    const sourceParam = urlParams.get('utm_source');
    const campaignParam = urlParams.get('utm_campaign');
    const currentUser = (window.mParticle && window.mParticle.Identity && window.mParticle.Identity.getCurrentUser && window.mParticle.Identity.getCurrentUser()) || null;
    if (currentUser) {
      currentUser.setUserAttribute('$utm_content', contentParam);
      currentUser.setUserAttribute('$utm_medium', mediumParam);
      currentUser.setUserAttribute('$utm_source', sourceParam);
      currentUser.setUserAttribute('$utm_campaign', campaignParam);
    }
    this.addToQueue('page', pageName, {
      name: pageName,
      title: document.title,
      url: window.location.href,
      search: window.location.search,
      referrer: document.referrer,
      path: window.location.pathname,
      content: contentParam,
      medium: mediumParam,
      source: sourceParam,
      campaign: campaignParam,
      ...hydrateAttributes(pageName, params),
    }).then(() => {
      if (fallbackMode) {
        this.sendQueue();
      }
    });
  }

  getOnlineStatus() {
    const ctx = this;
    if (window) {
      window.addEventListener('offline', () => {
        ctx.online = false;
      });
      window.addEventListener('online', () => {
        ctx.online = true;
      });
    }
  }

  trackEventSdk(eventName, attributes, flags, eventType, explicitMethod) {
    const defaultEventType = (window && window.mParticle && window.mParticle.EventType && window.mParticle.EventType.Transaction) || 4;
    const sdkMethodName = explicitMethod || 'logEvent';
    if (window && window.mParticle && window.mParticle[sdkMethodName]) {
      window.mParticle[sdkMethodName](eventName, eventType || defaultEventType, attributes, flags);
    }
  }

  getEventData(method, event, attributes) {
    let params = {};
    attributes = attributes || {};
    const itemUuid = uuidv1();
    switch (method) {
      case 'page':
        params = {
          uuid: itemUuid,
          name: attributes.name || attributes.title || attributes.path || 'screen_view',
          info: attributes,
          event_type: 'screen_view',
          created_at: new Date(),
        };
        break;
      case 'track':
        params = {
          uuid: itemUuid,
          name: event || attributes.title || attributes.name || 'unknown_event',
          info: attributes,
          created_at: new Date(),
        };
        break;
      default:
        break;
    }
    if (this.sessionUuid) {
      params.session_uuid = this.sessionUuid;
    }
    if (method === 'page' && params && params.name && window && window.mParticle && window.mParticle.logPageView) {
      const pageForEvent = attributes.path || attributes.title || (window && window.location && window.location.toString());
      window.mParticle.logPageView(
        'page_viewed',
        {
          ...params,
          ...(attributes || {}),
          event_type: 'page_viewed',
        },
        { 'Google.Page': pageForEvent },
      );
      return null;
    }
    if (params && params.name && this.sdkEvents.indexOf(params.name) > -1) {
      const flags = { 'DoubleClick.Counter': 'standard' };
      const eventType = (window.mParticle && window.mParticle.EventType.Other) || 8;
      this.trackEventSdk(params.name, attributes, flags, eventType);
      return null;
    }
    return params;
  }

  getStore() {
    return this.vuexStore || store;
  }

  queueManager() {
    const ctx = this;
    const mpartAnalyticsDisabled = this.getStore() && !this.getStore().getters.featureFlags.mpart_analytics;
    if (mpartAnalyticsDisabled || this.fallbackMode) {
      return;
    }
    setTimeout(() => {
      ctx.sendQueue();
      return ctx.queueManager();
    }, ctx.interval * 1000);
  }

  addToQueue(method, event, attributes) {
    const mpartAnalyticsEnabled = this.getStore() && this.getStore().getters.featureFlags.mpart_analytics;
    if (mpartAnalyticsEnabled) {
      const session = this.getEventData(method, event, attributes);
      if (session) {
        return this.getStore().dispatch('addSessionToQueue', session);
      }
    }
    return new Promise(resolve => resolve());
  }

  shapeData(eventsQueue) {
    let payload = {};
    payload = {
      platform: 'web',
      environment: process.env.NODE_ENV === 'production' ? 'production' : 'development',
      analytics_events: eventsQueue,
    };
    const currentUser = window && window.mParticle && window.mParticle.Identity && window.mParticle.Identity.getCurrentUser && window.mParticle.Identity.getCurrentUser();
    if (currentUser) {
      payload.device_id = currentUser.getMPID();
    }
    return payload;
  }

  sendQueue() {
    const { eventsQueue } = this.getStore().getters;
    const payload = this.shapeData(eventsQueue);
    const payloadByteSize = utility.jsonSize(payload);
    const eventQty = (payload.analytics_events || []).length;
    if (this.online && payload.analytics_events.length > 0) {
      this.analyticsService.sendEventTracking(payload)
        .then(() => {
          nr_analyticsManager.addPageAction(nr_addPageActions.Web_AnalyticsPayloadSuccess, {
            bytes: payloadByteSize,
            events: eventQty,
          });
          this.getStore().dispatch('clearQueue', payload);
        })
        .catch((error) => {
          this.fallbackMode = true;
          if (error.status && error.status === 400) {
            this.getStore().dispatch('clearQueue', payload);
          }
          nr_analyticsManager.addPageAction(nr_addPageActions.Web_AnalyticsPayloadFailure, {
            bytes: payloadByteSize,
            events: eventQty,
            eventTypes: (payload.analytics_events || []).map(e => e.name).join(','),
            statusCode: (error && error.status) || '',
          });
          log.error(error);
        });
    }
  }
}

export default EventTracking;
