<template>
  <div class="accordion-section">
    <div class="section-header">
      <div class="section-heading">
        <div :class="['section-number', {'section-number--active': isActive}]">
          <span>{{ index + 1 }}</span>
        </div>
        <div :class="['section-title', {'section-title--active': isActive}]">{{ section.title }}</div>
      </div>
      <div
        :class="['edit', {'edit--show': showEditCta}]"
        @click="handleEdit">Edit</div>
    </div>
    <div :class="['section-content', {'section-content--active': isActive}]">
      <component
        :is="sectionComponent"
        :index="index" />
    </div>
  </div>
</template>

<script>
import YourInfoSection from './sections/YourInfoSection';
import sectionDictionary from './sections/sectionDictionary';

export default {
  components: {
    YourInfoSection,
  },
  props: {
    section: {
      type: Object,
      default: () => {},
    },
    index: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      sectionComponent: null,
    };
  },
  computed: {
    activeAccordionSectionIndex() {
      return this.$store.getters.activeAccordionSection;
    },
    isActive() {
      return this.activeAccordionSectionIndex === this.index;
    },
    showEditCta() {
      return this.section.isComplete && this.section.isEditable;
    },
  },
  watch: {
    isActive(val) {
      if (val && 'scrollIntoView' in this.$el) {
        this.$el.scrollIntoView({ behavior: 'smooth' });
      }
    },
  },
  mounted() {
    this.setSectionComponent();
  },
  methods: {
    handleEdit() {
      this.$store.dispatch('editAccordionSectionData', this.index);
    },
    getComponentName() {
      return this.$store.getters.accordionSections[this.index].componentName;
    },
    setSectionComponent() {
      this.sectionComponent = sectionDictionary.components[this.getComponentName()];
    },
  },
};

</script>

<style lang="scss" scoped>
@import '../../../assets/scss/variables.scss';

.accordion-section {
  width: 100%;
  border-bottom: 1px solid #353C4A;
}

.section-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 54px;
}

.section-heading {
  display: flex;
  flex-direction: row;
}

.section-number {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 24px;
  height: 24px;
  color: $light-gray;
  border: 1px solid $light-gray;
  border-radius: 50%;
  margin-right: 12px;

  > span {
    font-size: 16px;
    line-height: 24px;
  }

  &--active {
    color: $white;
    border: 1px solid $white;
  }
}

.section-title {
  font-size: 16px;
  line-height: 26px;
  color: $light-gray;

  &--active {
    color: $white;
  }
}

.edit {
  display: none;

  &--show {
    display: block;
    font-size: 18px;
    line-height: 26px;
    color: $light-gray;
    cursor: pointer;
  }
}

.section-content {
  display: none;
  margin-top: 16px;
  margin-bottom: 30px;

  &--active {
    display: block;
  }
}

</style>
