import CarouselLayout from './carousel/CarouselLayout';
import CarouselDefaultShowItem from './carousel/CarouselDefaultShowItem';

export default {
  layouts: {
    'carousel-layout': CarouselLayout,
  },
  items: {
    'carousel-default-show-item': CarouselDefaultShowItem,
  },
};
