/* eslint-disable */
import * as log from 'loglevel';
import { mapGetters } from 'vuex';
import AxiosAPI from '../services/AxiosAPI';
import find from 'lodash/find';
import get from 'lodash/get';
import successIcon from '../assets/images/success.svg';
import Session from './session';
import User from '../services/User';
import PlaybackService from '../services/PlaybackService';
import { getServices } from '@/services/serviceProvider';
import amountFilter from './amountFilter';
import taxFilter from './taxFilter';
import utility from './utility';
import promoFilter from './promoFilter';
import { formatSubscriptionPlan } from '@/shared/formatters';
import { IS_HLS_SUPPORTED } from '@/components/HlsAudio';

const [
  playbackService,
] = getServices([PlaybackService]);

const UtilityMixins = {
  data() {
    return {
      irClickId: null,  // Impact radius click id
      supportedCountries : ['US','GB','CA','AU','PR','AS','GU','MP','VI','NZ','ZA','IE','NO','SE','DK','IS','FI'],
      sessionID: '',
      screenSize: {
        xl: 1440,
        lg: 1024,
        md: 768,
        sm: 576,
        xs: 375,
      },
      currentScreenWidth: window.innerWidth,
      show_premium_modal: false,
      isHlsAvailable: IS_HLS_SUPPORTED,
    };
  },
  created() {
  },
  beforeMount() {
    this.setImpactClickId();
    this.sessionID = Session.getSessionId();
    this.setCanonicalTag();
  },
  computed: {
    ...mapGetters([
      'featureFlag',
      'isHlsFileUnavailable',
    ]),
    isHlsAudio() {
      return this.isHlsAvailable && this.hlsToken && this.featureFlag('hls_player');
    },
    isWeb2() {
      return this.$store.getters.flagWithDefault('web_2', false);
    },
    isNewAcquistionFlow() {
      return this.$store.getters.flagWithDefault('acquisition_flow', false);
    },
    isAlexaFlow() {
      return this.$route.query.alexa === 'true';
    },
    isAcquisitionFlow() {
      return this.$route.query.flow === 'true';
    },
    isInternationalUser() {
      const { country } = this.$store.getters;

      return !this.supportedCountries.includes(country);
    },
    pricing() {
      const { pricing } = this.$store.getters;
      const currencySymbol = pricing.currency === 'GBP' ? '£' : '$'
      return `${currencySymbol}${pricing.price}`;
    },
    isMarketingPage() {
      const marketingPages = ['/', '/premium', '/international', '/shows'];
      return marketingPages.includes(this.$route.path);
    },
    localPricing() {
      if (this.annualPlanEnabled) {
        return this.$store.getters.marketingPrice;
      }
      return this.$store.getters.pricing;
    },
    userSubscriptionState() {
      let state;
      const { user } = window.$store.getters;

      if (!user) {
        state = 'anon';
      }
      if (user && user.is_eligible_for_free_trial) {
        state = 'free'
      }
      if (user && !user.is_eligible_for_free_trial && !user.is_subcribed) {
        state = 'freeWasPremium'
      }
      if (!!(user && user.is_subscribed)) {
        state = 'premium';
      }
      if (!!(user && user.in_grace_period)) {
        state = 'premiumChurned';
      }
      return state;
    },
    promoMeta() {
      const { userSubscription, featureFlags, promoData, user, trialPeriodDays} = window.$store.getters;
      if (this.annualPlanEnabled) {
        promoData.formattedTrialPeriod = `${trialPeriodDays} ${trialPeriodDays === 1 ? 'Day' : 'Days'}`;
      } else {
        promoData.formattedTrialPeriod = promoData.trialPeriod > 1 ? `${promoData.trialPeriod} months` : `${promoData.trialPeriod} month`;
      }
      const promoEnabled = this.$store.getters.flagWithDefault('holiday_promo', false);
      const isPremium = !!(user && user.is_subscribed);
      const isWithinSubscription = userSubscription && userSubscription.expire_at && new Date(userSubscription.expire_at) > new Date();
      // TODO: remove hard-coded true, using cause stage api broken
      const isChurned = isPremium && userSubscription && userSubscription.is_cancelled && isWithinSubscription;
      // const isChurned = true;
      const isFreeUser = user && user.uuid && !isPremium;
      promoData.isAnonUser = !user;
      promoData.isChurned = isChurned; // premium but has cancelled
      promoData.isPremiumCurrent = isPremium && userSubscription && !userSubscription.is_cancelled; // premium has not cancelled
      promoData.isFreeUser = isFreeUser;
      promoData.isPremiumUser = isPremium;
      promoData.isExpired = userSubscription && userSubscription.expire_at && new Date(userSubscription.expire_at) < new Date();
      promoData.showHolidayPromo = promoEnabled && !isPremium;
      promoData.showPromoOnBilling = promoEnabled && (isFreeUser || isChurned);
      promoData.showTrial = !(userSubscription && userSubscription.plan && userSubscription.plan.trial_start_date);
      if (this.annualPlanEnabled && !userSubscription) {
        const { user } = this.$store.getters;
        promoData.showTrial = !user || (user && user.is_eligible_for_free_trial);
      }
      if (this.$store.getters.country === 'IN') promoData.showTrial = false;
      // force no trial
      promoData.showTrial = false;
      return promoData;
    },
    promoEndDate() {
      return this.$store.getters.globalConfig.holiday_promo_end_date;
    },
    promoDiscount() {
      const { holiday_promo } = this.$store.getters.activePlan;
      const { price, currency: userCurrency } = this.$store.getters.pricing;
      if (holiday_promo && holiday_promo.pricing) {
        return promoFilter(holiday_promo.pricing, userCurrency, price);
      }
      return {};
    },
    termsLink() {
      return '/promoterms';
    },
    annualPlanEnabled() {
      return this.$store.getters.flagWithDefault('annual_plan', true);
    },
    pricingPlans() {
      return this.$store.getters.pricingPlans;
    },
    plansLoaded() {
      return this.$store.getters.pricingPlansLoaded;
    },
    displayPriceMonthly() {
      const { pricingPlans, pricingPlansLoaded } = this.$store.getters;
      if (pricingPlansLoaded) {
        const monthlyPrice = find(pricingPlans, { displayName: 'Monthly' });
        return monthlyPrice.displayPrice;
      }
      return '';
    },
    formattedLocalPricingWithTax() {
      const { pricing } = this.$store.getters;

      if (this.annualPlanEnabled) {
        return `${this.displayPriceMonthly}/month + applicable taxes`;
      }

      if (pricing && pricing.price && pricing.currency) {
        return `${amountFilter(pricing.price, pricing.currency)} ${taxFilter(pricing.price, pricing.currency)}`
      }

      return '';
    },
    userPlanType() {
      const { userSubscription } = this.$store.getters;
      return formatSubscriptionPlan(userSubscription && userSubscription.plan, userSubscription);
    },
    detectMobileOs() {
      var userAgent = navigator.userAgent || navigator.vendor || window.opera;

      if (/android/i.test(userAgent)) {
        return 'android';
      }

      if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        return 'ios';
      }

      return 'unknown';
    },
    isSafariMobile() {
      return document.querySelector('#app').classList.contains('is-safari') && this.currentScreenWidth <= this.screenSize.md;
    },
    showPremiumModal() {
      return this.$store.getters.showPremiumModal;
    },
    expiryOffset() {
      const { userSubscription } = this.$store.getters;
      const diffInDays = utility.getDiffInDays(new Date(), new Date(get(userSubscription, 'expire_at', null)));
      return diffInDays;
    },
    userNextPlanType() {
      const { userSubscription } = this.$store.getters;
      return formatSubscriptionPlan(userSubscription && userSubscription.next_charge, userSubscription);
    },
    isMobileViewport() {
      return utility.isMobile();
    },
    winstarCustomPixel() {
      if ((process && process.env && process.env.NODE_ENV || null) !== 'production') {
        return null;
      }
      const { winstarPixelConfig } = this.$store.getters.globalConfig || {};
      const fullPath = this.$route.path;
      const pixelSourceUrl = (pixelKey) => `https://pixelb.randi.adswizz.com/i/web/winstar/10/luminarypodcasts.com/${pixelKey}/?`;
      const pixelKeyForPage = Object.keys(winstarPixelConfig).reduce((resultPixelKey, currentPixelKey) => {
        if (winstarPixelConfig[currentPixelKey] === fullPath) {
          return currentPixelKey;
        } else if (resultPixelKey) {
          return resultPixelKey;
        }
        return null;
      }, null);
      return pixelKeyForPage ? pixelSourceUrl(pixelKeyForPage) : null;
    },
    isApplePodcastsEnabled() {
      return this.$store.getters.featureFlag('apple_podcasts');
    },
    isApplePodcastsPlanEnabled() {
      return this.$store.getters.featureFlag('apple_podcasts_plan');
    },
    buttonCTAText() {
      if (this.promoMeta.showTrial && (this.promoMeta.isAnonUser || this.promoMeta.isFreeUser)) return 'Start Free Trial';
      if (this.promoMeta.isExpired || this.promoMeta.isChurned) return 'Reactivate Now';
      return 'Listen Now';
    },
  },
  methods: {
    onStartFreeTrialClick() {
      if (this.isInternationalUser && this.isApplePodcastsEnabled) {
        this.$router.push('/unsupported');
        return;
      }
      const { isFreeUser, isPremiumUser } = this.promoMeta;
      const routeTo = { path: '/signup' };
      if (isFreeUser) {
        routeTo.path = '/plans';
      } else if (isPremiumUser) {
        routeTo.path = '/premium';
      }
      this.$router.push(routeTo);
    },
    handleCTARouting(trackClick) {
      const { analytic_events } = this.$store.getters.globalConfig;
      if (trackClick && analytic_events[trackClick]) {
        this.$analytics.track(analytic_events[trackClick]);
      } else if (this.$route.name && this.$route.name.includes('show')) {
        this.$analytics.track(analytic_events['sdp_subscribe_click']);
      }
      if (this.isInternationalUser && this.isApplePodcastsEnabled) {
        this.$analyticsV2.logEvent('cf_button_clicked', {
          is_cta: true,
          target_url: '/unsupported',
          button_text: trackClick && trackClick.target ? trackClick.target.innerText : 'unknown'
        })
        this.$router.push('/unsupported');
        return;
      }
      const { isAnonUser, isFreeUser } = this.promoMeta;
      let routeTo = { path: '/premium' };
      if (isAnonUser && !this.annualPlanEnabled) {
        routeTo = { path: '/user-categories', query: { showPaymentForm: true } };
      } else if (isAnonUser && this.annualPlanEnabled) {
        routeTo = { path: '/signup' };
      } else if (isFreeUser && this.annualPlanEnabled) {
        routeTo = { path: '/plans' };
      } else if (this.annualPlanEnabled) {
        routeTo = { path: '/plans' };
      } else {
        routeTo = { path: '/premium-buy' };
      }

      this.$analyticsV2.logEvent('cf_button_clicked', {
        is_cta: true,
        target_url: routeTo.path,
        button_text: trackClick && trackClick.target ? trackClick.target.innerText : 'unknown'
      })

      this.$router.push(routeTo);
    },
    trackSteps({ step, flowType }) {
      const { selectedPlan } = this.$store.getters;
      const planTypeString = (selectedPlan && selectedPlan.planTypeString) || null;
      console.log('signup_flow_step:', step); // eslint-disable-line
      if (step === 'stepOne') {
        this.$analytics.track('ga_plan_selected', {
          flow_type: flowType,
          plan_type: planTypeString,
        });
      }
      this.$analytics.track(`Signup_Flow_${step}`, {
        flow_type: flowType,
        plan_type: planTypeString,
      });
    },
    setMetaInformation(title, metaDesc) {
      if (!title) {
        document.title = 'Luminary Web';

        const metaDescription = document.querySelector('.meta-description');
        if (metaDescription) {
          metaDescription.content = "Luminary is a podcast streaming platform that gives you access to 500k+ shows, when and where you want. Sign up today and be the first to try @luminary!";
        }
      } else {
        document.title = title;
        var metaTitle = document.querySelectorAll('.meta-title');
        metaTitle = [...metaTitle]
        if (metaTitle) {
          metaTitle.forEach(item => {
            item.content = title;
          })
        }
        var metaDescription = document.querySelectorAll('.meta-description');
        metaDescription = [...metaDescription]
        if (metaDescription) {
          metaDescription.forEach(item => {
            item.content = this.$options.filters.truncate(metaDesc, 160);
          })
        }
      }
    },
    setCanonicalTag() {
      const canonicalTag = document.getElementById("canonical-tag");
      if (canonicalTag) {
        canonicalTag.setAttribute('href', window.location.href);
      }
    },
    isBrowserSafari() {
      // Bieng set in App.vue
      return document.querySelector('#app').classList.contains('is-safari');
    },

    setImpactClickId() {
      if (this.$route && (this.$route.query.IRCLICKID || this.$route.query.irclickid) && this.$store) {
        // Store it in the local variable
        let key = this.$route.query.IRCLICKID ? 'IRCLICKID' : 'irclickid';
        this.irClickId = this.$route.query[key];
        // Store it for future references
        this.$store.dispatch('setIrClickId', this.irClickId);
      }
    },

    getImpactClickId() {
      return this.$store.getters.irClickId;
    },

    getUserCountry() {
      return this.$store.getters.country;
    },

    showInfoMsg(message) {
      const div = `
        <div class="message">
          ${message}
        </div>
      `;

      const useOffset = document.querySelector('body').classList.contains('audio-player-visible');
      const playerCollapsed = document.querySelector('#app').classList.contains('audio-player-collapsed')

      this.$notify({
        title: '',
        message: div,
        position: 'bottom-left',
        type: 'info',
        dangerouslyUseHTMLString: true,
        offset: useOffset && !playerCollapsed? 75 : 0,
      });
    },

    showSuccessMsg(message, duration) {
      const div = `
        <div class="message">
          <img src=${successIcon} />
          <p>
            ${message}
          </p>
        </div>
      `;

      const useOffset = document.querySelector('body').classList.contains('audio-player-visible');
      const playerCollapsed = document.querySelector('#app').classList.contains('audio-player-collapsed')

      this.$notify({
        title: '',
        message: div,
        position: 'bottom-left',
        customClass: 'success',
        dangerouslyUseHTMLString: true,
        duration: duration || 4500, // 4500 is default duration
        offset: useOffset && !playerCollapsed? 75 : 0,
      });
    },

    showErrorMsg(message, duration) {
      const useOffset = document.querySelector('body').classList.contains('audio-player-visible');
      const playerCollapsed = document.querySelector('#app').classList.contains('audio-player-collapsed')
      this.$notify({
        title: '',
        message,
        position: 'bottom-left',
        customClass: 'error',
        duration: duration || 4500, // 4500 is default duration
        offset: useOffset && !playerCollapsed? 75 : 0,
      });
    },

    confirmDelete(_message) {
      const message = _message || 'This will permanently delete the item. Continue?';
      return this.$confirm(message, 'Warning', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel',
        type: 'warning',
        center: true,
      });
    },

    progressiveImageError(error) {
      log.error(error);
    },

    showLoginDialog() {
    },

    setMediaPlaybackNotification(_title, _artist, imgUrl) {
      // Only works for chrome android
      if ('mediaSession' in navigator) {
        navigator.mediaSession.metadata = new MediaMetadata({
          title: _title,
          artist: _artist,
          album: '',
          artwork: [
            { src: imgUrl },
          ],
        });
        navigator.mediaSession.setActionHandler('play', () => { this.$store.dispatch('play'); });
        navigator.mediaSession.setActionHandler('pause', () => { this.$store.dispatch('pause'); });
      }
    },

    isInObject(obj, key) {
      return obj && obj.hasOwnProperty(obj, key);
    },

    scrollToElement(element) {
      if (!window || !document.querySelector || !element) return;
      const node = typeof element === 'string' ? document.querySelector(element) : element;
      node.scrollIntoView({
        behavior: 'smooth',
      });
    },

    isNullOrWhiteSpace(str) {
      return !str || !str.trim().length;
    },

    async loadMediaInPlayer(media_url, is_exclusive, episode_type = 'full', trailerAsEpisode) {
      playbackService.loadMediaInPlayer(media_url, is_exclusive, episode_type, trailerAsEpisode);
    },

    async loadEpisodeInPlayer(episode, trailerAsEpisode = false) {
      const isExclusive = episode.isExclusive || episode.is_exclusive;
      const hlsUrl = episode.hlsUrl || episode.hls_url;
      const mediaUrl = episode.mediaUrl || episode.media_url;
      const type = episode.type || episode.episode_type;
      const isHls = !this.isHlsFileUnavailable(episode.uuid) && this.isHlsAudio && isExclusive && hlsUrl;
      playbackService.loadMediaInPlayer(isHls ? hlsUrl : mediaUrl, isExclusive, type, trailerAsEpisode);
    },

    isDeviceTouch() {
      return document.getElementsByTagName('html')[0].classList.contains('isTouch');
    },

    srcSet(type, filePathArray) {
      if (type === 'srcset') {
        return filePathArray.map((path, idx) => {
          return `${path} ${idx + 1}x`;
        }).join(', ');
      }
      return filePathArray[0];
    },

    fetchCurrencySymbol(currency) {
      let currencySymbol = '$';
      switch (currency) {
        case 'USD': {
          currencySymbol = '$';
          break;
        }
        case 'CAD': {
          currencySymbol = 'CAD$';
          break;
        }
        case 'GBP': {
          currencySymbol = '£';
          break;
        }
        case 'AUD': {
          currencySymbol = 'AU$';
          break;
        }
        case 'EUR': {
          currencySymbol = '€';
          break;
        }
        case 'NZD': {
          currencySymbol = 'NZ$';
          break;
        }
        case 'ZAR': {
          currencySymbol = 'R';
          break;
        }
        case 'INR': {
          currencySymbol = '₹';
          break;
        }
        default: {
          currencySymbol = '$';
        }
      }
      return currencySymbol;
    },

    disableVerticalScroll() {
      document.body.classList.add('vertical-scroll-disable');
      // document.body.style.overflowY = 'hidden';
    },

    enableVerticalScroll() {
      document.body.classList.remove('vertical-scroll-disable');
      // document.body.style.overflowY = 'auto';
    },
    mapTargetBlankToAnchorTags(cssSelector) {
      const aTags = document.querySelectorAll(cssSelector);
      for (let i = 0; i < aTags.length; i++) {
        aTags[i].setAttribute('target', '_blank');
      }
    },
    generateSlug(title = '') {
      title = title.replace(/[^\w\s]/gi, '');
      return title.replace(/\s/g, '-');
    },
    convertToSlug(str = '') {
      return str.toLowerCase().replace(/ /g,'-').replace(/[-]+/g, '-').replace(/[^\w-&]+/g,'');
    },
    addNoIndexMeta() {
      const head = document.querySelector('head');
      if (head) {
        const meta = document.createElement('meta');
        meta.setAttribute('id', 'noindex-meta')
        meta.setAttribute('name', 'robots');
        meta.setAttribute('content', 'noindex');
        head.appendChild(meta);
      }
    },
    removeNoIndexMeta() {
      const head = document.querySelector('head');
      const noIndexMeta = document.getElementById('noindex-meta');
      if (head && noIndexMeta) {
        head.removeChild(noIndexMeta);
      }
    },
    handleOffScreenData() {
      /* Our related shows in category carousal is offscreen,
      so we do not need to fetch its on page load just to avoid offscreen loading images
      latancy. */
      document.addEventListener('scroll', this.scrollEventHandlerForOffScreenData);
    },
    removeScroll() {
      document.removeEventListener('scroll', this.scrollEventHandlerForOffScreenData);
    },
  },
};

export default UtilityMixins;
