import BrightbackService from '../../services/Brightback';
import { getServices } from '@/services/serviceProvider';

const [brightbackService] = getServices([BrightbackService]);

export const BRIGHTBACK_STATES = {
  IDLE: 'idle',
  PRELOADING: 'preloading',
  AVAILABLE: 'is_available',
  UNAVAILABLE: 'is_unavailable',
  PROCESSING: 'processing',
  CANCELLED: 'cancelled',
  SAVED: 'saved',
};

const getDefaultState = () => ({
  status: BRIGHTBACK_STATES.IDLE,
  url: null,
});

const getters = {
  isReadyToProcess: state => state.status === BRIGHTBACK_STATES.AVAILABLE && state.url,
  isInitialState: state => state.status === BRIGHTBACK_STATES.IDLE,
};

const mutations = {
  setIsIdle: (state) => {
    state.status = BRIGHTBACK_STATES.IDLE;
  },
  setIsPreloading: (state) => {
    state.status = BRIGHTBACK_STATES.PRELOADING;
  },
  setIsAvailable: (state) => {
    state.status = BRIGHTBACK_STATES.AVAILABLE;
  },
  setIsUnavailable: (state) => {
    state.status = BRIGHTBACK_STATES.UNAVAILABLE;
  },
  setIsProcessing: (state) => {
    state.status = BRIGHTBACK_STATES.PROCESSING;
  },
  setIsCancelled: (state) => {
    state.status = BRIGHTBACK_STATES.CANCELLED;
  },
  setIsSaved: (state) => {
    state.status = BRIGHTBACK_STATES.SAVED;
  },
  setUrl: (state, payload) => {
    state.url = payload;
  },
};

const state = getDefaultState();

const actions = {
  async prepareProcessing({ commit }, data) {
    commit('setIsPreloading');
    try {
      const {
        first_name,
        last_name,
        email,
        user_uuid,
        plan_type,
        user_created_at,
        ...remoteData
      } = await brightbackService.getCancellationInfo();
      data.first_name = first_name;
      data.last_name = last_name;
      data.email = email;
      data.full_name = `${first_name} ${last_name}`;
      data.account.internal_id = user_uuid;
      data.account.plan = plan_type;
      data.account.plan_term = plan_type;
      data.account.created_at = user_created_at;
      data.custom = remoteData;
      const res = await brightbackService.prepareCancellation(data);
      if (res.valid) {
        commit('setIsAvailable');
        commit('setUrl', res.url);
      } else {
        commit('setIsUnavailable');
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
    }
  },
  startProcessing(context) {
    context.commit('setIsProcessing');
    brightbackService.startProcessing(context.state.url);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
