export default (value, opt) => {
  const hours = Math.floor((value % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  const minutes = Math.floor((value % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((value % (1000 * 60)) / 1000);
  if ((hours === 0) && (minutes !== 0)) {
    return `${minutes} mins `;
  }
  if ((hours === 0) && (minutes === 0)) {
    return `${seconds} sec`;
  }
  if (opt && opt === 'min') return `${(hours * 60) + minutes} mins`;
  return `${hours} hr ${minutes} mins`;
};
