<template>
  <div class="faq">
    <div class="faq-header">Frequently Asked Questions</div>
    <div class="faq-container">
      <div class="faq-items">
        <div
          v-for="(item, i) in items"
          :key="i"
          :class="{'faq-item': true, 'faq-item_opened': opened === i}"
        >
          <div class="faq-top" @click="toggleOpen(i)">
            <div class="faq-title">{{ item.title }}</div>
            <div class="faq-expand">
              <svg class="faq-icon" svg-inline="" width="32" focusable="false" role="presentation" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 15"><path d="M0.390524 0.439341C-0.130175 1.02513 -0.130175 1.97487 0.390524 2.56066L11.0572 14.5607C11.5779 15.1464 12.4221 15.1464 12.9428 14.5607L23.6095 2.56066C24.1302 1.97487 24.1302 1.02513 23.6095 0.439341C23.0888 -0.146446 22.2446 -0.146446 21.7239 0.439341L12 11.3787L2.27614 0.439341C1.75544 -0.146446 0.911223 -0.146446 0.390524 0.439341Z" clip-rule="evenodd"/></svg>
            </div>
          </div>
          <div :style="{ height: opened === i ? `${item.height}px` : 0 }" class="faq-text-wrapper">
            <div class="faq-text"><component :is="item.component"/></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import debounce from 'lodash/debounce';
import Faq1 from './Faq1';
import Faq2 from './Faq2';
import Faq3 from './Faq3';

let debouncedResize = null;

export default {
  name: 'HomePageFAQ',
  components: {
    Faq1,
    Faq2,
    Faq3,
  },
  data() {
    return {
      opened: null,
      items: [
        {
          title: 'What is Luminary?',
          component: Faq1,
        },
        {
          title: 'Where can I listen?',
          component: Faq2,
        },
        {
          title: 'How do I cancel?',
          component: Faq3,
        },
        /*
        {
          title: 'How much does Luminary Cost?',
          content: `<p>Luminary is $5.99 per month for new monthly subscribers and $34.99 per year for our annual subscribers (~$2.99 per month)</p>
<p>Monthly subscribers who signed up before May [], 2021 will retain the lower monthly price of $4.99.</p>`,
        },
         */
      ],
    };
  },
  computed: {

  },
  mounted() {
    this.recalcHeights();
    debouncedResize = debounce(this.recalcHeights, 100);
    window.addEventListener('resize', debouncedResize, { passive: true });
    this.$nextTick(() => {
      debouncedResize();
    });
  },
  beforeDestroy() {
    window.removeEventListener('resize', debouncedResize);
  },
  methods: {
    toggleOpen(index) {
      this.opened = this.opened === index ? null : index;
    },
    recalcHeights() {
      this.$el.querySelectorAll('.faq-text').forEach((el, i) => {
        this.$set(this.items, i, { ...this.items[i], height: el.offsetHeight });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
  @import "../../../assets/scss/variables.scss";
  @import "../../../assets/scss/mixins.scss";
  .faq {
    text-align: center;
    padding-bottom: 70px;
    @media (max-width: $xs) {
      padding-bottom: 45px;
    }
    &-header {
      //font-family: "ITC Clearface", serif;
      font-size: 40px;
      line-height: 47px;
      text-align: center;
      color: #FFFFFF;
      margin-top: 60px;
      margin-bottom: 40px;
    }
    &-container {
      max-width: $max-width-threshold;
      margin: 0 auto;
      padding: 0 67px;
      @media (max-width: 1023px) {
        padding: 0 45px;
      }
      @media (max-width: 767px) {
        padding: 0 16px;
      }
    }
    &-item {
      text-align: left;
    }
    &-top {
      cursor: pointer;
      display: flex;
      padding: 10px 0;
      border-bottom: 1px solid #343B4A;
    }
    &-title {
      flex: 1 0 auto;
      font-size: 20px;
      line-height: 120%;
      color: #FFFFFF;
      transition: color 0.5s ease;
    }
    &-expand {
      flex: 0 1 auto;
    }
    &-text {
      padding-bottom: 45px;
      overflow: auto;
      font-size: 18px;
      line-height: 24px;
      color: #FCFCFC;
      & /deep/ p {
        color: #FCFCFC;
      }
      @media (max-width: $xs) {
        padding-bottom: 30px;
        font-size: 20px;
      }
    }
    &-text-wrapper {
      overflow: hidden;
      transition: height 0.5s ease;
      height: 0;
    }
    &-icon {
      width: 24px;
      fill: #E5E5E5;
      transition: transform 0.5s ease, fill 0.5s ease;
    }
    &-item_opened {
      .faq-title {
        color: #00AECB;
      }
      .faq-icon {
        fill: #00AECB;
        transform: rotate(-180deg);
      }
    }
  }
</style>
