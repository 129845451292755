export default (value) => {
  // value will be in milliseconds
  if (value > 1953000) {
    const hours = parseInt(Math.floor(value / (1000 * 60 * 60)) % 24, 10);
    const minutes = parseInt(Math.floor((value / (1000 * 60)) % 60), 10);
    const seconds = parseInt((value / 1000) % 60, 10);
    const dHours = (hours > 9 ? hours : `0${!Number.isNaN(hours) ? hours : 0}`);
    const dMins = (minutes > 9 ? minutes : `0${!Number.isNaN(minutes) ? minutes : 0}`);
    const dSecs = (seconds > 9 ? seconds : `0${!Number.isNaN(seconds) ? seconds : 0}`);
    return dHours < 1 ? `${dMins}:${dSecs}` : `${dHours}:${dMins}:${dSecs}`;
  }
  const hours = parseInt(Math.floor((value / 1000) / 3600), 10);
  const minutes = parseInt(Math.floor(((value / 1000) % 3600) / 60), 10);
  const seconds = parseInt((value / 1000) % 60, 10);
  const dHours = (hours > 9 ? hours : `0${!Number.isNaN(hours) ? hours : 0}`);
  const dMins = (minutes > 9 ? minutes : `0${!Number.isNaN(minutes) ? minutes : 0}`);
  const dSecs = (seconds > 9 ? seconds : `0${!Number.isNaN(seconds) ? seconds : 0}`);
  return dHours < 1 ? `${dMins}:${dSecs}` : `${dHours}:${dMins}:${dSecs}`;
};
