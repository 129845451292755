export default function promoFilter(pricingArray, userCurrency, originalPrice, hidePeriod) {
  let currencySymbol;

  const priceObj = pricingArray.find(pricing => pricing.currency === userCurrency);
  const { discountAmount, currency } = priceObj;

  const promoAmount = (parseFloat(originalPrice) - parseFloat(discountAmount)).toString();

  switch (currency) {
    case 'USD': {
      currencySymbol = '$';
      break;
    }
    case 'CAD': {
      currencySymbol = 'CAD$';
      break;
    }
    case 'GBP': {
      currencySymbol = '£';
      break;
    }
    case 'AUD': {
      currencySymbol = 'AU$';
      break;
    }
    case 'EUR': {
      currencySymbol = '€';
      break;
    }
    case 'NZD': {
      currencySymbol = 'NZ$';
      break;
    }
    case 'ZAR': {
      currencySymbol = 'R';
      break;
    }
    case 'INR': {
      currencySymbol = '₹';
      break;
    }
    default: {
      currencySymbol = '$';
    }
  }

  const filteredObject = {
    symbol: currencySymbol,
    int: promoAmount.substring(0, 1),
    dec: promoAmount.substring(1, 4),
    frequency: hidePeriod ? '' : '/month',
  };

  const {
    symbol,
    int,
    dec,
    frequency,
  } = filteredObject;

  filteredObject.asString = `${symbol}${int}${dec}`;
  filteredObject.asStringRecurring = `${symbol}${int}${dec}${frequency}`;

  return filteredObject;
}
