<template>
  <div :class="{hero: true, 'hero-promo': isHolidayPromoGiftEnabled}">
    <div class="container">
      <div class="content">
        <h1 class="title">{{ title }}</h1>
        <p class="subtitle" v-html="subtitle"/>
        <div class="gift-card">
          <div class="gift-content">
            <img
              class="gift-icon"
              src="../../../assets/images/web2/gifting/icon-bow-blue-left.png">
            <p class="gift-copy">{{ giftCopy }}</p>
            <p class="gift-price" v-html="giftPrice"/>
          </div>
          <el-button
            v-button-event="{buttonName: 'Buy Now'}"
            class="el-button--primary gift-button"
            @click="handleBuyClick">
            {{ giftButtonText }}
          </el-button>
        </div>
        <div class="redeem-cta">
          <p class="redeem-copy">{{ ctaCopy }}</p>
          <el-button
            v-button-event
            class="btn-transparent gift-button"
            @click="handleRedeemClick">
            {{ redeemButtonText }}
          </el-button>
        </div>
        <p class="faq">
          {{ faqCopy }}
          <a
            v-button-event
            :href="faqLink"
            target="_blank"
            class="link">
            {{ faqLinkText }}
          </a>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import giftMixin from '@/shared/giftMixin';

export default {
  mixins: [giftMixin],
  data() {
    return {
      title: 'Give the gift of Luminary.',
      ctaCopy: 'Did you receive a Gift Subscription?',
      giftButtonText: 'Buy Now',
      redeemButtonText: 'Redeem My Gift',
      giftCopy: '1-Year Subscription',
      faqCopy: 'You can also',
      faqLink: 'https://luminary.zendesk.com/hc/en-us/sections/360010747534-Gifting',
      faqLinkText: 'visit our FAQ page.',
      giftUrl: '/gift-a-year',
      redeemUrl: '/redeem-a-gift',
    };
  },
  computed: {
    subtitle() {
      if (this.isHolidayPromoGiftEnabled) return 'For a <span style="font-weight: bold; color: white;">limited-time</span> give a full year of listening at 60% off the monthly price.<br/>Choose a delivery date and we’ll email your gift with instructions on how to redeem.';
      return 'Share your favorite podcasts with your favorite people. Choose a delivery date and we’ll email your gift with instructions on how to redeem.';
    },
  },
  methods: {
    handleBuyClick() {
      this.$router.push(this.giftUrl);
    },
    handleRedeemClick() {
      this.$router.push(this.redeemUrl);
    },
  },
};

</script>

<style lang="scss" scoped>
@import "../../../assets/scss/variables.scss";

.hero {
  width: 100%;
  background-color: $dark-background;
  background-image: linear-gradient(180.06deg, rgba(18, 28, 48, 0.65) 0.06%, rgba(18, 28, 48, 0.77) 27.18%, #121C30 58.44%), url(../../../assets/images/web2/gifting/bg-gifting-hero-new.png);
  background-position: top center;
  @media (min-width: $xs) {
    background-image: linear-gradient(0, #121C30 17.54%, rgba(18, 28, 48, 0.95) 69.07%, rgba(18, 28, 48, 0) 100%), url(../../../assets/images/web2/gifting/bg-gifting-hero-new.png);
  }
  &.hero-promo {
    padding-top: 80px;
    background-image: linear-gradient(180deg, rgba(18, 28, 48, 0) 0, rgba(18, 28, 48, 0.4) 220px, #121C30 460px), url(../../../assets/images/web2/home/HolidayPromo-2304.jpg);
    background-size: 100% auto;
    background-repeat: no-repeat;
    @media (max-width: $lg) {
      background-image: linear-gradient(180deg, rgba(18, 28, 48, 0) 0, rgba(18, 28, 48, 0.4) 180px, #121C30 380px), url(../../../assets/images/web2/home/HolidayPromo-2304.jpg);
    }
    @media (max-width: $md) {
      background-image: linear-gradient(180deg, rgba(18, 28, 48, 0) 0, rgba(18, 28, 48, 0.4) 120px, #121C30 300px), url(../../../assets/images/web2/home/HolidayPromo-2304.jpg);
    }
    @media (max-width: $sm) {
      background-image: linear-gradient(180deg, rgba(18, 28, 48, 0) 0, rgba(18, 28, 48, 0.4) 90px, #121C30 180px), url(../../../assets/images/web2/home/HolidayPromo-2304.jpg);
    }
    @media (max-width: $xs) {
      background-image: linear-gradient(0, #121C30 17.54%, rgba(18, 28, 48, 0.95) 79.07%, rgba(18, 28, 48, 0) 100%), url(../../../assets/images/web2/home/HolidayPromo-576.png);
    }
    .content {
      margin-top: 140px;
      @media (max-width: $lg) {
        margin-top: 102px;
      }
      @media (max-width: $md) {
        margin-top: 82px;
      }
      @media (max-width: $sm) {
        margin-top: 62px;
      }
      @media (max-width: $xs) {
        margin-top: 42px;
      }
    }
  }
}

.content {
  margin-top: 42px;
  @media (min-width: $xs) {
    margin-top: 200px;
  }
}

.title {
  color: $white;
  //font-family: $serif;
  text-align: center;
  font-size: 30px;
  line-height: 36px;
  margin: 0 0 16px;
  @media (min-width: $sm) {
    font-size: 36px;
    line-height: 42px;
  }
  @media (min-width: $md) {
    font-size: 40px;
    line-height: 48px;
  }
  @media (min-width: $lg) {
    font-size: 48px;
    line-height: 56px;
  }
}

.subtitle {
  color: $light-gray3;
  text-align: center;
  margin: 0 auto;
  max-width: 443px;
  font-size: 16px;
  line-height: 21px;
  margin-bottom: 36px;
  @media (min-width: $md) {
    font-size: 18px;
    line-height: 22px;
    margin-bottom: 48px;
  }
}

.gift-card {
  position: relative;
  background: $dark-overlay;
  padding: 20px 24px 30px 24px;
  max-width: 414px;
  margin: 0 auto;
  margin-bottom: 32px;
  border-radius: 8px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (min-width: $xs) {
    margin-bottom: 54px;
  }
  @media (min-width: $sm) {
    padding: 30px 30px 30px 56px;
    flex-direction: row;
    justify-content: space-between;
  }
}

.gift-icon {
  position: absolute;
  top: 0;
  left: 0;
  height: 48px;
}

.gift-content {
  margin-bottom: 20px;
  @media (min-width: $sm) {
    margin-bottom: 0;
  }
}

.gift-copy {
  color: $white;
  margin: 0;
  margin-bottom: 6px;
  font-size: 21px;
  line-height: 25px;
  text-align: center;
  @media (min-width: $sm) {
    text-align: left;
  }
}

.gift-price {
  color: $blue;
  margin: 0;
  font-size: 18px;
  line-height: 21px;
  text-align: center;
  @media (min-width: $sm) {
    text-align: left;
    font-size: 21px;
    line-height: 25px;
  }
}

.redeem-cta {
  text-align: center;
  margin-bottom: 56px;
}

.redeem-copy {
  color: $white;
  margin: 0 auto;
  margin-bottom: 20px;
}

.gift-button {
  width: 190px;
  height: 48px;
}

.faq {
  text-align: center;
  color: $light-gray;
  margin-bottom: 56px;
}
</style>
