import find from 'lodash/find';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import '@adyen/adyen-web/dist/adyen.css';
import Adyen from '@/services/Adyen';
import Subscription from '@/services/Subscription';
import validationRules from '@/shared/validationRules';
import countries from '@/assets/json/countries';
import usStates from '@/assets/json/usStates';
import caStates from '@/assets/json/caStates';
import inStates from '@/assets/json/inStates';
import usTerritories from '@/assets/json/usTerritories';
import ShapeProductsResponse from '@/shared/ShapeProductsResponse';
import { getServices } from '@/services/serviceProvider';
import adyenDropin from '@/shared/adyenDropin';
import OptimizeService from '@/services/Optimize';

const [
  subscriptionProvider,
  adyenService,
  optimizeService,
] = getServices([Subscription, Adyen, OptimizeService]);

export default {
  data() {
    const data = {
      paymentForm: {
        firstName: this.defaultFirstName,
        lastName: this.defaultLastName,
        address: '',
        houseNumberOrName: '',
        postalCode: '',
        country: this.$store.getters.country || '',
        state: '',
        city: '',
      },
      countries,
      activePlan: this.$store.getters.activePlan,
      loading: false,
      defaultPrice: this.$store.getters.pricing,
      showVermontModal: false,
      vermontTermsAccepted: false,
      isFormLoading: false,
      skipBilling: ['UM', 'CA', 'AU', 'NZ', 'ZA', 'GB'], // billing address required for any country
      isPaypalSelected: false,
      isBillingBeforeAdyenForUS: this.$store.getters.featureFlag('us_address_before_adyen'),
    };
    if (data.isBillingBeforeAdyenForUS) data.skipBilling.push('US');
    return data;
  },
  computed: {
    ...mapGetters([
      'proposedPricingPlans',
    ]),
    isBillingBeforeAdyenForUSAndUS() {
      return this.paymentForm.country === 'US' && this.isBillingBeforeAdyenForUS;
    },
    showBasicAddress() {
      return this.paymentForm && this.showState;
    },
    isNameFieldsVisible() {
      return !this.giftingFlow;
    },
    buttonText() {
      if (this.giftingFlow) {
        return 'Save and Continue';
      }
      if (this.annualPlanEnabled && !this.button_text) {
        return 'Agree & Subscribe';
      }
      if (!this.annualPlanEnabled && !this.button_text) {
        return 'Subscribe';
      }
      return this.button_text || 'Subscribe';
    },
    userSubscription() {
      return this.$store.getters.userSubscription;
    },
    showPromoOnBilling() {
      return this.$store.getters.promoData.showPromoOnBilling;
    },
    activeProduct() {
      return this.$store.getters.activePlan;
    },
    usersDefaultCountry() {
      return this.$store.getters.country;
    },
    states() {
      let states;
      if (this.isBillingBeforeAdyenForUSAndUS) {
        states = usStates;
      } else if (this.paymentForm.country === 'CA') {
        states = caStates;
      } else if (this.paymentForm.country === 'UM') {
        states = usTerritories;
      } else if (this.paymentForm.country === 'IN') {
        states = inStates;
      }
      return states;
    },
    showState() {
      return this.isBillingBeforeAdyenForUSAndUS
        || this.paymentForm.country === 'CA'
        || this.paymentForm.country === 'UM'
        || this.paymentForm.country === 'IN';
    },
    stateLabel() {
      let label = 'Billing State';
      if (this.paymentForm.country === 'CA') {
        label = 'Billing Province';
      }
      return label;
    },
    statePlaceholder() {
      let label = 'Select State';
      if (this.paymentForm.country === 'CA') {
        label = 'Select Province';
      }
      return label;
    },
    postalCodePlaceholder() {
      let placeholder = 'Enter postal code';
      if (this.isBillingBeforeAdyenForUSAndUS) {
        placeholder = 'Enter zip code';
      }
      return placeholder;
    },
    zipCodeLabel() {
      let label = 'Billing Postal Code';
      if (this.isBillingBeforeAdyenForUSAndUS) {
        label = 'Billing Zip Code';
      }
      return label;
    },
    isSubscribed() {
      return this.$store.getters.user && this.$store.getters.user.is_subscribed;
    },
    isCanceled() {
      return this.$store.getters.userSubscription && this.$store.getters.userSubscription.is_cancelled;
    },
    usersCountryPricing() {
      return this.$store.getters.pricing;
    },
    trailMonths() {
      let trialMonths = localStorage.getItem('promo_trial_period');
      if (this.isSubscribed) {
        trialMonths = 1;
      }
      return trialMonths || 1;
    },
    user() {
      return this.$store.getters.user;
    },
    isResubscribingAfterTrial() {
      const currentDate = new Date();
      const expireAt = new Date(this.userSubscription && this.userSubscription.expire_at ? this.userSubscription.expire_at : currentDate);
      let isPast = false;
      if (currentDate < expireAt) {
        isPast = false;
      } else {
        isPast = true;
      }
      return !this.update && this.user && !this.user.is_subscribed && this.user.is_on_boarded && this.userSubscription && this.userSubscription.status !== 'FREE TRIAL' && isPast;
    },
    isResubscribingInTrial() {
      const currentDate = new Date();
      const expireAt = new Date(this.userSubscription && this.userSubscription.expire_at ? this.userSubscription.expire_at : currentDate);
      let isPast = false;
      if (currentDate < expireAt) {
        isPast = false;
      } else {
        isPast = true;
      }
      let flag = false;
      if (this.user && this.user.is_subscribed) {
        flag = !this.update && this.user && this.user.is_subscribed && this.user.is_on_boarded && this.userSubscription && this.userSubscription.status === 'FREE TRIAL';
      } else {
        flag = !this.update && this.user && this.user.is_on_boarded && this.userSubscription && !isPast;
      }
      return flag;
    },
    isNewSubscription() {
      return !this.isSubscribed && !this.isCanceled && this.user && !this.user.is_on_boarded && !this.update;
    },
    selectedPlan() {
      return this.$store.getters.selectedPlan;
    },
    paymentValidationRules() {
      return this.extendValidationWithZip(validationRules.payment);
    },
    dropinValidationRules() {
      const { firstName, lastName } = validationRules.payment;
      return this.extendValidationWithZip({ firstName, lastName });
    },
    isBillingAddressInAdyen() {
      return this.$store.getters.featureFlag('adyen_drop_in_billing');
    },
  },
  watch: {
    show_modal() {
      this.populateUserName();
      // this.paymentForm.country = this.$store.getters.country;
    },
    activeProduct(newVal) {
      if (newVal) {
        this.activePlan = newVal;
      }
    },
    usersDefaultCountry(newVal) {
      if (newVal) {
        this.paymentForm.country = newVal;
      }
    },
    usersCountryPricing(newVal) {
      if (newVal) {
        this.defaultPrice = newVal;
      }
    },
    selectedPlan(plan) {
      if (plan) {
        this.initAdyenDropIn();
      }
    },
    // eslint-disable-next-line func-names
    'paymentForm.country': function (country) {
      this.initAdyenDropIn();
      this.paymentForm.state = '';
      this.paymentForm.postalCode = '';
      const countryData = this.countries.find(nextItem => nextItem.code === country);
      if (countryData && this.$store.getters.country !== country) {
        this.loadProposedPricingPlans({ country, currency: countryData.currency }).then(() => {
          const targetPlan = this.proposedPricingPlans.find(plan => plan.planTypeString === this.$store.getters.selectedPlan.planTypeString);
          if (targetPlan) {
            this.openPriceAlert(targetPlan);
          } else {
            this.openPriceUnavailableAlert();
          }
        });
      }
    },
    defaultFirstName(v) {
      this.paymentForm.firstName = v;
    },
    defaultLastName(v) {
      this.paymentForm.lastName = v;
    },
  },
  beforeMount() {
    const { pricingPlans, userSubscription, selectedPlan } = this.$store.getters;
    if (this.annualPlanEnabled && !selectedPlan && userSubscription) {
      const { type } = userSubscription && userSubscription.plan;
      const newSelectedPlan = find(pricingPlans, { planTypeString: type });
      this.$store.dispatch('selectPricingPlan', newSelectedPlan);
    }
  },
  mounted() {
    this.checkCountryIsWhiteListed();
    this.populateUserName();
    this.activePlan = this.$store.getters.activePlan;
    this.initAdyenDropIn();
  },
  beforeDestroy() {
    clearTimeout(this.adyenForm.loadWatcher); // Just to make sure setTimeout reference is cleared before leave
  },
  methods: {
    ...mapActions([
      'loadProposedPricingPlans',
    ]),
    ...mapMutations([
      'setUserCountry',
    ]),
    validateAdyenForm() {
      return new Promise((res) => {
        if (!this.update) {
          optimizeService.triggerAnlyticsEvent({
            action: 'PaymentFormValidationAttempt',
            category: 'Signup',
          });
        }
        this.$refs.paymentForm.validate((isValid, data) => {
          if (!isValid) {
            const keys = Object.keys(data);
            if (keys.length) {
              const dd = data[keys[0]];
              if (dd && dd.length) {
                this.scrollToElement(this.$el.querySelector(`[data-form-prop="${dd[0].field}"]`));
              }
            }
            if (!this.update) {
              optimizeService.triggerAnlyticsEvent({
                action: 'PaymentFormInvalid',
                category: 'Signup',
                label: keys.join(','),
              });
            }
            // process all errors here rej(new Error('Validation not passed'));
          } else {
            if (!this.update) {
              optimizeService.triggerAnlyticsEvent({
                action: 'PaymentFormValid',
                category: 'Signup',
              });
            }
            res(true);
          }
        });
      });
    },
    extendValidationWithZip(paymentRules) {
      const { state, postalCode } = validationRules.payment;
      if (this.isBillingBeforeAdyenForUSAndUS) {
        return {
          ...paymentRules,
          state,
          postalCode: [
            {
              required: true,
              pattern: '^[0-9]{5}$',
              message: 'Enter valid zip code.',
              trigger: 'blur',
            },
          ],
        };
      }

      if (this.paymentForm.country === 'CA') {
        return {
          ...paymentRules,
          state: [
            {
              required: true,
              message: 'Province is required.',
              trigger: 'change',
            },
          ],
          postalCode: [
            {
              required: true,
              validator: this.validatePostalCode,
              trigger: 'blur',
            },
          ],
        };
      }

      if (this.paymentForm.country === 'UM') {
        return {
          ...paymentRules,
          postalCode,
          state: [
            {
              required: true,
              message: 'State is required.',
              trigger: 'blur',
            },
          ],
        };
      }

      if (!this.isBillingBeforeAdyenForUSAndUS && this.paymentForm.country !== 'CA') {
        return {
          ...paymentRules,
          state: [
            {
              required: false,
            },
          ],
        };
      }
      return paymentRules;
    },
    onAcceptVermontTerms() {
      this.vermontTermsAccepted = true;
      this.showVermontModal = false;
      this.onSubmit();
    },
    onFormItemChange() {
      this.validateAdyenForm();
    },
    onSubmit() {
      if (this.loading) return;
      if (this.giftingFlow) {
        this.doSaveAndContinue();
      } else {
        this.doSubmit();
      }
    },
    doSaveAndContinue() {
      this.validateAdyenForm().then(() => {
        if (adyenDropin.isReady) {
          if (this.$refs.paymentForm && this.$refs.paymentForm.state === 'VT' && !this.vermontTermsAccepted) {
            this.showVermontModal = true;
            return;
          }
          if (!adyenDropin.isValid) {
            adyenDropin.instance.showValidation();
            return;
          }
          this.$emit('success', adyenDropin.instance, this.formatSubscribeRequestData(adyenDropin.instance.data));
        }
      });
    },
    doSubmit() {
      const { showTrial } = this.promoMeta;
      this.validateAdyenForm().then(() => {
        if (adyenDropin.isReady) {
          if (this.$refs.paymentForm && this.$refs.paymentForm.state === 'VT' && !this.vermontTermsAccepted && showTrial) {
            this.showVermontModal = true;
            return;
          }
          adyenDropin.instance.submit();
        }
      });
    },
    formatUpdatePaymentRequestData(paymentInfo) {
      const country = this.countries.find(nextItem => nextItem.code === this.paymentForm.country);
      let countryCode = this.paymentForm.country;
      let stateCode = this.paymentForm.state;
      if (countryCode === 'UM') {
        countryCode = stateCode;
        stateCode = '';
      }
      const requestData = {
        billToContact: {
          country: countryCode,
          firstName: this.paymentForm.firstName,
          lastName: this.paymentForm.lastName,
          postalCode: this.paymentForm.postalCode,
          state: stateCode,
        },
        currency: country.currency,
        paymentInfo,
        returnUrl: adyenDropin.getReturnUrl(this.$route, window.location),
        executeThreeD: false,
      };
      return requestData;
    },
    updatePaymentInfo(paymentInfo) {
      const subscriptionData = this.formatUpdatePaymentRequestData(paymentInfo);
      this.loading = true;
      this.subscriptionProvider.updatePaymentInfo(subscriptionData)
        .then(this.updatePaymentResponse)
        .catch((error) => {
          this.loading = false;
          this.showErrorMsg(error || 'D’oh. We’re experiencing technical difficulties. Please try again later or contact support@luminary.audio');
        });
    },
    subscribe(paymentInfo) {
      const subscriptionData = this.formatSubscribeRequestData(paymentInfo);
      this.loading = true;
      const clickedID = this.getImpactClickId();

      // Pass ImpactRadius ClickedID if present query param
      if (clickedID) {
        const pricing = this.fetchTargetCountryPrice();
        subscriptionData.total_price = parseFloat(pricing.price);
        subscriptionData.ir_click_id = clickedID;
      }

      this.subscriptionProvider.createSubscription(subscriptionData)
        .then(this.subscriptionResponse)
        .catch((error) => {
          this.loading = false;
          this.showErrorMsg(error || 'D’oh. We’re experiencing technical difficulties. Please try again later or contact support@luminary.audio');
        });
    },
    formatSubscribeRequestData(paymentInfo) {
      // const { activePlan } = this.$store.getters;
      const country = this.countries.find(nextItem => nextItem.code === this.paymentForm.country);
      let countryCode = this.paymentForm.country;
      let stateCode = this.paymentForm.state;
      if (countryCode === 'UM') {
        countryCode = stateCode;
        stateCode = '';
      }
      const promoCode = localStorage.getItem('promo_code');
      const billingAddress = this.isBillingAddressInAdyen ? paymentInfo.billingAddress || {} : {
        city: this.paymentForm.city,
        country: this.paymentForm.country,
        houseNumberOrName: this.paymentForm.houseNumberOrName,
        postalCode: this.paymentForm.postalCode,
        stateOrProvince: this.paymentForm.state,
        street: this.paymentForm.address,
      };
      // set country anyway since backend requires it here
      billingAddress.country = countryCode;
      if (this.showState) {
        // for US, CA, UM copy data from external fields to billing
        billingAddress.stateOrProvince = stateCode;
        billingAddress.postalCode = this.paymentForm.postalCode;
      }
      const requestData = {
        paymentMethod: paymentInfo.paymentMethod,
        billingAddress,
        planId: (this.selectedPlan && this.selectedPlan.id) || '',
        planType: (this.selectedPlan && this.selectedPlan.planTypeString) || '',
        timezoneOffset: new Date().getTimezoneOffset(),
        total_price: (this.selectedPlan && this.selectedPlan.price) || null,
        currency: country.currency,
        firstName: this.paymentForm.firstName,
        lastName: this.paymentForm.lastName,
        returnUrl: adyenDropin.getReturnUrl(this.$route, window.location),
      };
      if (this.promocode) {
        requestData.promoCode = this.promocode;
      } else if (promoCode) {
        requestData.promoCode = promoCode;
        requestData.branchPromo = localStorage.getItem('branch_promo');
      }
      const currentDate = new Date();
      const timeOffset = currentDate.getTimezoneOffset();
      requestData.timezoneOffset = timeOffset;
      return requestData;
    },
    onCountryChange() {
      const country = this.countries.find(nextItem => nextItem.code === this.paymentForm.country);
      if (country && country.currency) {
        this.fetchZuoraProduct(country.currency, country.code);
      }
    },
    gotoPlanSelectionStep() {
      // implement for new and old flow
    },
    openPriceUnavailableAlert() {
      const targetCountry = this.countries.find(country => country.code === this.paymentForm.country);
      this.$confirm(`${this.$store.getters.selectedPlan.displayName} plan is unavailable for ${targetCountry.name}, you can change plan or cancel country selection`, {
        confirmButtonText: 'Change Plan',
        cancelButtonText: 'Continue w/o Country',
        type: 'warning',
      }).then(() => {
        this.gotoPlanSelectionStep();
      }).catch(() => {
        this.paymentForm.country = this.$store.getters.country;
        this.$nextTick(() => {
          this.$refs.country.blur();
        });
      });
    },
    openPriceAlert(targetPlan) {
      const usersCountry = this.$store.getters.country;
      const targetCountry = this.countries.find(country => country.code === this.paymentForm.country);

      this.$confirm(`You will be charged ${targetPlan.displayPrice} because your card is from ${targetCountry && targetCountry.name ? targetCountry.name : this.paymentForm.country}.`, {
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel',
        type: 'warning',
      }).then(() => {
        this.setUserCountry(this.paymentForm.country);
        this.$store.dispatch('selectPricingPlan', targetPlan);
        this.verifyAddress();
      }).catch(() => {
        this.paymentForm.country = this.userSubscription && this.userSubscription.plan && this.userSubscription.plan.country ? this.userSubscription.plan.country : usersCountry;
        this.activePlan = this.$store.getters.activePlan;
        this.defaultPrice = this.fetchTargetCountryPrice();
        this.verifyAddress();
      });
    },
    fetchTargetCountryPrice() {
      const country = this.countries.find(nextItem => nextItem.code === this.paymentForm.country);
      const productRatePlanCharges = this.activePlan.productRatePlanCharges[this.activePlan.productRatePlanCharges.length - 1];
      const pricing = productRatePlanCharges.pricing.find(nextItem => nextItem.currency === country.currency);
      return pricing;
    },
    checkCountryIsWhiteListed() {
      const countryCode = this.supportedCountries.find(code => code === this.paymentForm.country);
      if (!countryCode) {
        this.paymentForm.country = '';
      }
    },
    fetchZuoraProduct(currencyCode, countryCode) {
      this.loading = true;
      this.subscriptionProvider.fetchSubscriptionPlans(currencyCode, countryCode).then((resp) => {
        this.loading = false;
        if (resp.plan && resp.plan.id) {
          this.activePlan = resp.plan;
          if (this.$refs.countryDD1) {
            this.$refs.countryDD1.blur();
          } else if (this.$refs.countryDD2) {
            this.$refs.countryDD2.blur();
          }
          const shaper = new ShapeProductsResponse();
          const pricingPlans = shaper.getPricingPlans({ response: resp.originalResponse, currencyCode });
          const pln = this.$store.getters.selectedPlan;
          const targetPlan = pricingPlans.find(plan => plan.planTypeString === pln.planTypeString);
          this.openPriceAlert(targetPlan);
          this.$refs.paymentForm.clearValidate();
        }
      }).catch(() => {
        this.loading = false;
      });
    },
    populateUserName() {
      const { user } = this.$store.getters;
      if (user && user.uuid) {
        if (this.isNameFieldsVisible) {
          this.paymentForm.firstName = user.first_name || '';
          this.paymentForm.lastName = user.last_name || '';
        }
        this.paymentForm.postalCode = user.postal_code || '';
        this.paymentForm.state = user.state || '';
        this.paymentForm.country = this.userSubscription && this.userSubscription.plan && this.userSubscription.plan.country ? this.userSubscription.plan.country : this.$store.getters.country;
      }
    },
    verifyAddress() {
      const { user } = this.$store.getters;
      if ((this.userSubscription && this.userSubscription.plan && this.paymentForm.country !== this.userSubscription.plan.country) || !user) {
        this.paymentForm.postalCode = '';
        this.paymentForm.state = '';
      } else {
        this.paymentForm.postalCode = user.postal_code;
        this.paymentForm.state = user.state;
      }
      setTimeout(() => {
        this.$refs.paymentForm.clearValidate();
      }, 10);
    },
    validatePostalCode(rule, value, callback) {
      value = value.toString().trim();
      if (value === '') {
        callback(new Error('Postal code is required'));
      } else if (value.length > 10) {
        callback(new Error('Postal code is too long'));
      } else {
        const strongRegex = new RegExp(/^[ABCEGHJ-NPRSTVXY]\d[ABCEGHJ-NPRSTV-Z]\s?\d[ABCEGHJ-NPRSTV-Z]\d$/i);

        if (strongRegex.test(value)) {
          callback();
        } else {
          callback(new Error('Enter valid postal code.'));
        }
      }
    },
    initAdyenDropIn() {
      if (!this.selectedPlan) return;
      this.isFormLoading = true;
      adyenService.getPaymentMethods({
        country: this.paymentForm.country,
        currency: this.selectedPlan.currency,
      }).then((res) => {
        this.isFormLoading = false;
        const config = {
          ...this.getAdyenDropInConfig(res),
          onSubmit: this.onAdyenSubmit,
          onAdditionalDetails: this.onAdyenAdditionalDetails,
          onError: this.onAdyenError,
          onChange: this.onAdyenChange,
        };
        config.paymentMethodsConfiguration.card.billingAddressRequired = this.isBillingAddressInAdyen && !this.skipBilling.includes(this.paymentForm.country);
        if (config.paymentMethodsConfiguration.card.billingAddressRequired) config.paymentMethodsConfiguration.card.billingAddressAllowedCountries = [this.paymentForm.country];
        if (this.giftingFlow) config.removePaymentMethods = ['paypal', 'paywithgoogle', 'applepay'];
        const dropinConf = {
          openFirstPaymentMethod: config.paymentMethodsResponse.paymentMethods.length === 1,
          showPayButton: false,
          onSelect: this.onAdyenSelect,
        };
        if (adyenDropin.isReady) adyenDropin.instance.unmount();
        adyenDropin.create(config, dropinConf).mount(this.$refs.adyenForm);
      }).catch((err) => {
        this.isFormLoading = false;
        this.showErrorMsg(err || 'D’oh. We’re experiencing technical difficulties. Please try again later or contact support@luminary.audio');
      });
    },
    onAdyenError(error) {
      // eslint-disable-next-line no-console
      console.error('error!', error);
      if (this.update) return;
      optimizeService.triggerAnlyticsEvent({
        action: 'AdyenDropinError',
        category: 'Signup',
        label: error.name,
      });
    },
    onAdyenSubmit(state, dropin) {
      if (!this.update) {
        optimizeService.triggerAnlyticsEvent({
          action: 'AdyenSubmitAttempt',
          category: 'Signup',
        });
      }
      if (!state.isValid) {
        dropin.setStatus('ready');
        return;
      }
      if (!this.update) {
        optimizeService.triggerAnlyticsEvent({
          action: 'AdyenSubmitValid',
          category: 'Signup',
        });
      }
      this.isFormLoading = true;
      this.loading = true;
      const method = this.update ? 'updateDropInSubscriptionMethod' : 'createDropInSubscription';
      const processResponse = this.update ? 'updatePaymentResponse' : 'subscriptionResponse';
      const requestData = this.formatSubscribeRequestData(state.data);
      this.$store.commit('payment/setLastParams', requestData);
      this.$store.commit('payment/setLastMethod', method);
      this.$store.commit('payment/setLastResponseProcessor', processResponse);
      subscriptionProvider[method](requestData).then((res) => {
        if (res.action) {
          // Drop-in handles the action object from the /payments response
          this.onAdyenAction(res.action);
          dropin.handleAction(res.action);
        } else {
          // Your function to show the final result to the shopper
          this.onAdyenResult(res);
          this[processResponse](res);
        }
      }).catch((err) => {
        dropin.setStatus('ready');
        const errorText = (err && err.message ? err.message : err) || 'D’oh. We’re experiencing technical difficulties. Please try again later or contact support@luminary.audio';
        window.$analyticsV2.logEvent('cf_subscription_creation_failed', {
          error_details: err.message,
        });
        this.showErrorMsg(errorText);
      }).finally(() => {
        this.isFormLoading = false;
        this.loading = false;
      });
    },
    onAdyenAdditionalDetails(state, dropin) {
      // eslint-disable-next-line no-console
      console.error('additional details not supported!', state, dropin);
      if (this.update) return;
      optimizeService.triggerAnlyticsEvent({
        action: 'AdyenAdditionalDetailsRequested',
        category: 'Signup',
      });
    },
    onAdyenChange() {
      if (this.update) return;
      optimizeService.triggerAnlyticsEvent({
        action: 'AdyenDropinChanged',
        category: 'Signup',
      });
    },
    onAdyenSelect(component) {
      this.isPaypalSelected = component.type === 'paypal';
      if (this.update) return;
      optimizeService.triggerAnlyticsEvent({
        action: 'SelectedPaymentMethod',
        category: 'Signup',
        label: component.type,
      });
    },
    onAdyenAction(action) {
      if (this.update) return;
      optimizeService.triggerAnlyticsEvent({
        action: 'AdyenDropinAction',
        category: 'Signup',
        label: action.type,
      });
    },
    onAdyenResult() {
      if (this.update) return;
      optimizeService.triggerAnlyticsEvent({
        action: 'AdyenDropinResult',
        category: 'Signup',
      });
    },
  },
};
