const getDefaultState = () => ({
  playlist: [],
  continueListeningPlaylist: [],
  playlistType: 'show',
  currentPlayingEpisodeId: null,
  continuousListeningActivePodcast: null,
  loadPlaylist: true,
  trailerExperienceTriggered: false,
});

const getters = {
  playlist: state => state.playlist,
  continueListeningPlaylist: state => state.continueListeningPlaylist,
  playlistType: state => state.playlistType,
  currentPlayingEpisodeId: state => state.currentPlayingEpisodeId,
  continuousListeningActivePodcast: state => state.continuousListeningActivePodcast,
  loadPlaylist: state => state.loadPlaylist,
  trailerExperienceTriggered: state => state.trailerExperienceTriggered,
};

const mutations = {
  setPlaylist: (state, playlist) => { state.playlist = playlist; },
  setContinueListeningPlaylist: (state, continueListeningPlaylist) => { state.continueListeningPlaylist = continueListeningPlaylist; },
  setPlaylistType: (state, playlistType) => { state.playlistType = playlistType; },
  setCurrentPlayingEpisodeId: (state, currentPlayingEpisodeId) => { state.currentPlayingEpisodeId = currentPlayingEpisodeId; },
  setContinuousListeningActivePodcast: (state, continuousListeningActivePodcast) => { state.continuousListeningActivePodcast = continuousListeningActivePodcast; },
  setLoadPlaylist: (state, loadPlaylist) => { state.loadPlaylist = loadPlaylist; },
  updateContinuousListeningPlaylistOrder: (state) => {
    // const { continuousListeningActivePodcast, continueListeningPlaylist } = state;
    const { continuousListeningActivePodcast } = state;
    const continueListeningPlaylist = [...state.continueListeningPlaylist];
    const index = continueListeningPlaylist.findIndex((item) => {
      if (item.season && item.season.podcast) {
        return item.season.podcast.uuid === continuousListeningActivePodcast;
      }

      return false;
    });

    if (index > 0) {
      const spliced = continueListeningPlaylist.splice(index, 1);
      continueListeningPlaylist.unshift(spliced[0]);
    }
    state.continueListeningPlaylist = [...continueListeningPlaylist];
  },
  AddEpisodeToCLBucket: (state, episode) => {
    state.continueListeningPlaylist.unshift(episode);
  },
  resetCLState: (state) => {
    Object.assign(state, getDefaultState());
  },
  setTrailerExperienceTriggered: (state, trigger) => { state.trailerExperienceTriggered = trigger; },
};

const state = getDefaultState();

const actions = {
  setPlaylist: (context, playlist) => {
    context.commit('setPlaylist', playlist);
  },

  setContinueListeningPlaylist: (context, continueListeningPlaylist) => {
    // const CLplaylist = context.getters.continueListeningPlaylist;
    // if (!CLplaylist || !CLplaylist.length) {
    // context.commit('setContinueListeningPlaylist', continueListeningPlaylist);
    // }

    const CLplaylist = context.getters.continueListeningPlaylist;
    if (CLplaylist && CLplaylist.length) {
      const activePodcast = context.getters.continuousListeningActivePodcast;
      const oldCLactivePodcastIndex = CLplaylist.findIndex((episode) => {
        if (episode.season && episode.season.podcast) {
          return episode.season.podcast.uuid === activePodcast;
        }

        return false;
      });

      const newCLactivePodcastIndex = continueListeningPlaylist.findIndex((episode) => {
        if (episode.season && episode.season.podcast) {
          return episode.season.podcast.uuid === activePodcast;
        }

        return false;
      });

      if (oldCLactivePodcastIndex > -1 && newCLactivePodcastIndex > -1) {
        const temp = CLplaylist[oldCLactivePodcastIndex];
        continueListeningPlaylist[newCLactivePodcastIndex] = temp;
      }
    }
    context.commit('setContinueListeningPlaylist', continueListeningPlaylist);
  },

  setPlaylistType: (context, playlistType) => {
    context.commit('setPlaylistType', playlistType);
  },

  setCurrentPlayingEpisodeId: (context, currentPlayingEpisodeId) => {
    context.commit('setCurrentPlayingEpisodeId', currentPlayingEpisodeId);
  },

  setContinuousListeningActivePodcast: (context, continuousListeningActivePodcast) => {
    context.commit('setContinuousListeningActivePodcast', continuousListeningActivePodcast);

    const { continueListeningPlaylist } = context.getters;

    if (continueListeningPlaylist && continueListeningPlaylist.length) {
      context.commit('updateContinuousListeningPlaylistOrder');
    }
  },

  setLoadPlaylist: (context, loadPlaylist) => {
    context.commit('setLoadPlaylist', loadPlaylist);
  },
  setAllCaughtUp: (context, podcastUuid) => {
    const activePodcast = podcastUuid; // context.getters.continuousListeningActivePodcast;
    const continueListening = context.getters.continueListeningPlaylist;

    const currentIndex = continueListening.findIndex((episode) => {
      if (episode.season && episode.season.podcast) {
        return episode.season.podcast.uuid === activePodcast;
      }

      return false;
    });

    if (currentIndex > -1) {
      const tempItem = {
        season: continueListening[currentIndex].season,
        all_caught_up: true,
        show_temp_title: true,
        display_image_url: continueListening[currentIndex].display_image_url,
        type: continueListening[currentIndex].type,
      };
      continueListening[currentIndex] = tempItem;
      context.commit('setContinueListeningPlaylist', continueListening);
    }
  },
  setContinuousListeningActiveEpisode: (context, nextEpisode) => {
    const activePodcast = context.getters.continuousListeningActivePodcast;
    const continueListening = [...context.getters.continueListeningPlaylist];

    const currentIndex = continueListening.findIndex((episode) => {
      if (episode.season && episode.season.podcast) {
        return episode.season.podcast.uuid === activePodcast;
      }

      return false;
    });

    if (currentIndex > -1) {
      const temp = continueListening[currentIndex];

      nextEpisode.show_temp_title = false;
      nextEpisode.type = temp.type;

      if (nextEpisode && nextEpisode.season && nextEpisode.season.podcast && !nextEpisode.season.podcast.publisher) {
        nextEpisode.season.podcast.publisher = temp.season.podcast.publisher;
      }
      continueListening[currentIndex] = Object.assign({}, nextEpisode);
      context.commit('setContinueListeningPlaylist', continueListening);
    }
  },
  syncCLBucketWithPlayerLocally: (context, episode) => {
    const { continueListeningPlaylist } = context.getters;
    const targetIndex = continueListeningPlaylist.findIndex(epi => epi.season.podcast.uuid === episode.season.podcast.uuid);
    if (targetIndex < 0) {
      context.commit('AddEpisodeToCLBucket', episode);
    }
  },
  resetUserCLState: (context) => {
    context.commit('resetCLState');
  },
  setTrailerExperienceTriggered: (context, trigger) => {
    context.commit('setTrailerExperienceTriggered', trigger);
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
