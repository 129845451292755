<script>
export default {
  name: 'DynamicLink',
  props: {
    link: {
      type: [String, Object, Function],
      default: null,
    },
    target: {
      type: [String],
      default: null,
    },
  },
  render(component) {
    const isString = typeof this.link === 'string';
    const isFunction = typeof this.link === 'function';

    if (!this.link || (!isString && !isFunction)) {
      return null;
    }

    const type = ((isString && this.link.includes('http')) || isFunction) ? 'a' : 'router-link';
    const props = {};

    if (type !== 'router-link') {
      props.attrs = {
        href: isFunction ? '#' : this.link,
      };
      if (this.target) {
        props.attrs.target = this.target;
      }
    } else {
      props.props = {
        to: this.link,
      };
    }

    if (isFunction) {
      props.on = {
        click: this.link,
      };
    }

    return component(type, props, this.$slots.default);
  },
};
</script>
