<template>
  <div
    v-if="showFullCollection"
    class="container">
    <el-row>
      <grid-col
        :xs="{ span: 24, offset: 0 }"
        :sm="{ span: 22, offset: 1 }"
        :md="{ span: 18, offset: 3 }"
        :lg="{ span: 12, offset: 6 }"
        :xl="{ span: 12, offset: 6 }">
        <div class="full-collection">
          <p class="full-collection-copy">{{ full_collection_copy }}
            <router-link
              :to="full_collection_link"
              class="full-collection-cta">
              {{ full_collection_cta }}
            </router-link>
          </p>
        </div>
      </grid-col>
    </el-row>
  </div>
</template>

<script>
export default {
  props: {
    podcast: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      full_collection_copy: `${this.podcast.title} is one of 30+ Luminary original podcasts.`,
      full_collection_cta: 'See the full collection.',
      full_collection_link: '/shows',
    };
  },
  computed: {
    showFullCollection() {
      return this.podcast.isExclusive && this.userNotPremium;
    },
    userNotPremium() {
      return this.userSubscriptionState === 'anon' || this.userSubscriptionState === 'free' || this.userSubscriptionState === 'freeWasPremium';
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../assets/scss/variables.scss";

.full-collection {
  text-align: center;
  margin-bottom: 30px;
}

.full-collection-copy {
  //font-family: $serif;
  color: $white;
  font-size: 24px;
  line-height: 34px;
  @media(min-width: $sm) {
    font-size: 28px;
    line-height: 38px;
  }
}

.full-collection-cta {
  color: $white;
  border-bottom: 1px solid $blue;
  &:hover {
    color: $blue;
  }
}
</style>
