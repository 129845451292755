<template>
  <div class="homepage-cta">
    <div class="container">
      <el-row>
        <grid-col
          :xs="{ span: 20, offset: 2 }"
          :sm="{ span: 15, offset: 5}"
          :md="{ span: 12, offset: 2 }"
          :lg="{ span: 12, offset: 3 }"
          :xl="{ span: 12, offset: 2 }">
          <div>
            <h1 class="title">Listen wherever you are.</h1>
            <p class="subtitle">Download the Luminary app to start listening to the full collection of Luminary Originals. Available on iOS and Android. Or subscribe to the <a href="https://podcasts.apple.com/us/channel/luminary/id6442463604?itsct=luminary_podcasts&itscg=30200&ls=1&at=1000l36wW&ct=lum_hp_appsection" target="_blank">Luminary Channel on Apple Podcasts</a></p>
          </div>
          <div>
            <app-store-links
              :homepage-display="true"/>
          </div>
        </grid-col>
        <grid-col
          :xs="{ span: 24, offset: 0 }"
          :sm="{ span: 22, offset: 6 }"
          :md="{ span: 8, offset: 2 }"
          :lg="{ span: 8, offset: 1 }"
          :xl="{ span: 8, offset: 1 }">
          <div class="homepage-cta__device">
            <img
              :srcset="srcSet('srcset', deviceImages)"
              src="../../../assets/images/home/Homescreen-Devices-L.png"
              alt="">
          </div>
        </grid-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import AppStoreLinks from '../../AppStoreLinks';
import deviceS from '../../../assets/images/home/HomeScreen-Devices-S.png';
import deviceL from '../../../assets/images/home/Homescreen-Devices-L.png';

export default {
  name: 'Insta',
  components: {
    'app-store-links': AppStoreLinks,
  },
  data() {
    return {
      deviceImages: [
        deviceS,
        deviceL,
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
@import "../../../assets/scss/variables.scss";

.homepage-cta {
  background: #121C30;
  padding: 45px 0 28px;
  background: linear-gradient(180deg, #1B263C 0%, #010D25 78.12%, #1B263C 89.58%);
  .title {
    color: $white;
    font-family: $serif;
    font-size: 36px;
    line-height: 47px;
    text-align: center;
    font-weight: 500;
    @media (min-width: $xs) {
      max-width: initial;
      font-size: 36px;
      line-height: 42px;
      padding-top: 60px;
    }
    @media (min-width: $sm) {
      max-width: initial;
      font-size: 36px;
      line-height: 42px;
      padding-top: 20px;
      text-align: left;
    }
    @media (min-width: $md) {
      font-size: 48px;
      line-height: 48px;
      padding-top:20px;
      text-align: left;
    }
    @media (min-width: $lg) {
      font-size: 48px;
      line-height: 56px;
      padding-top:20px;

    }
  }

  .subtitle {
    color: $white;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 36px;
    text-align: center;
    @media (min-width: $xs) {
      max-width: initial;
    }
    @media (min-width: $sm) {
      max-width: 428px;
      text-align: left;
    }
    @media (min-width: $md) {
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 48px;
    }
  }

  &__device {
      display: block;
      width: 280px;
      margin-top: 30px;
      margin-left: auto;
      margin-right: auto;
      @media (min-width: $xs) {
        margin-left: 0px;
      }
      @media (min-width: $sm) {
        width: 300px;
        margin-top: 0px;
        margin-left:0px;
      }
      @media (min-width: $md) {
        width: 400px;
      }
    img {
      width: 100%;
    }
  }
}
</style>
