<template>
  <el-dialog
    :visible.sync="showModal"
    :append-to-body="true"
    class="vermont-modal"
  >
    <h2 class="vermont-modal__title">Vermont residents</h2>
    <div
      class="vermont-modal__description"
    >
      <p v-html="legalCopy"/>
    </div>

    <el-button
      class="vermont-modal__explore"
      type="primary"
      @click="onClickHandler"
    >
      I agree
    </el-button>

  </el-dialog>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    onAcceptTerms: {
      type: Function,
      default: () => null,
    },
  },
  computed: {
    showModal: {
      get() {
        return this.show;
      },
      set() {

      },
    },
    legalCopy() {
      const { selectedPlan } = this.$store.getters;
      const { displayName, displayPrice } = selectedPlan || {};
      const { showTrial } = this.promoMeta;
      const annualCopyTrial = `<span>Your 7-day free trial is about to begin.  If you do not cancel before the end of the 7-day free trial period, your annual Luminary Premium subscription will automatically start and you will be charged ${displayPrice} for your first year. Your annual subscription will automatically renew annually at Luminary's then-current rate. You can cancel your subscription in your Account Settings.</span>`;
      const annualCopyNoTrial = `<span>Your annual Luminary Premium subscription will automatically start and you will be charged ${displayPrice} for your first year. Your annual subscription will automatically renew annually at Luminary's then-current rate. You can cancel your subscription in your Account Settings.</span>`;
      const monthlyCopyTrial = `<span>Your 7-day free trial is about to begin.  If you do not cancel before the end of the 7-day free trial period, your monthly Luminary Premium subscription will automatically start and you will be charged ${displayPrice} for your first month. Your subscription will automatically renew monthly at Luminary's then-current rate. You can cancel your subscription in your Account Settings.</span>`;
      const monthlyCopyNoTrial = `<span>Your monthly Luminary Premium subscription will automatically start and you will be charged ${displayPrice} for your first month. Your subscription will automatically renew monthly at Luminary's then-current rate. You can cancel your subscription in your Account Settings.</span>`;
      if (showTrial) {
        return displayName === 'Monthly' ? monthlyCopyTrial : annualCopyTrial;
      }
      return displayName === 'Monthly' ? monthlyCopyNoTrial : annualCopyNoTrial;
    },
  },
  methods: {
    onClickHandler() {
      this.$analytics.track('Vermont Disclaimer Click');
      this.$emit('onAcceptVermontTerms');
    },
  },
};
</script>

<style lang="scss">
@import "../assets/scss/variables.scss";

.vermont-modal {
  .el-dialog {
    /* background-color: $brand; */
    border-radius: 7px;
    text-align: center;
    padding: 70px;
    max-width: 680px;
    margin-top: 0 !important;

    display: inline-block;

    width: auto;

    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);

    &__body {
      padding: 0;
    }

    &__header {
      padding: 0;
    }

    @media (max-width: 767px) {
      top: 0;
      left: 0;
      transform: none;

      width: 100%;
      height: 100%;
      border-radius: 0;

      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  &__title {
    font-size: 40px;
    color: $black;

    margin: 0;
  }

  &__description {
    margin: 30px 0 45px;

    > p {
      color: $black;
      font-size: 16px;
    }
  }

  &__explore {
    padding: 14px 30px;
    font-size: 18px;
    background-color: #ffffff;
    border-color: #ffffff;
    color: #111111;
  }
}
</style>
