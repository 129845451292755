<template>
  <div class="homepage-feature">
    <div class="container feature">
      <div class="content">
        <img class="feature-show" src="../../../assets/images/web2/home/ttd_logo.png">
        <h2 class="feature-show">New Episode · Only on Luminary</h2>
        <div class="feature-show info-block">
          <img src="../../../assets/images/web2/home/Till-This-Day-Tile_no-spot.png" alt="Til This Day with Radio Rahim"/>
          <div class="info">
            <p><span>Just released</span><br>Radio sits down with the late Bob Saget for a poignant and special conversation.</p>
          </div>
        </div>
        <div class="feature-show cta-block" @click="onClickCTA">Listen Now</div>
      </div>
    </div>
    <div class="homepage-feature-banner">
      <div class="container feature">
        <div class="content">
          <p>Matching donations for <b>The <span>Bob Saget</span> Memorial Fund</b>.</p>
          <div class="button-wrapper">
            <button @click="onClickDonate" type="button" class="el-button el-button--primary cta-button el-button--default">
              <span>Donate</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    onClickCTA() {
      this.$analytics.track('Home TilThisDay Click', {
        screen_name: this.$route.name,
        path: this.$route.path,
        btnId: 'home_tilthisday_click',
      });
      window.location.href = 'https://luminarypodcasts.com/listen/pilot-boy-productions-salt-and-radio-rahim/til-this-day-with-radio-rahim/bob-saget-ch-1/11cbe6e3-066f-4bfe-9b31-9c6d3d39e006';
    },
    onClickDonate() {
      this.$analytics.track('Donate Click', {
        screen_name: this.$route.name,
        path: this.$route.path,
        btnId: 'home_donate_click',
      });
      // window.location.href = 'https://support.srfcure.org/give/411758/#!/donation/checkout';
      window.open(
          'https://support.srfcure.org/give/411758/#!/donation/checkout',
          '_blank'
      );
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../assets/scss/variables.scss";

.button-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: left;
  -ms-flex-pack: left;
  justify-content: left;
  margin-left: 0;
}

/* Featured Banner */
.homepage-feature {
  background-image: url(../../../assets/images/web2/home/Featured-Luminary-homepage-1600x656x2.png),linear-gradient(180deg,#595758 0%,#595758 100%)!important;
  background-size: auto 100%;
  content: "";
  top: 0;
  background-repeat: no-repeat;
  background-position: 100%;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  opacity: 1;
  padding-top:72px;
  border-bottom: 0.5px solid rgba(252, 252, 252, .2)!important;
}

.homepage-feature .container {
  height:557px!important;
}

.homepage-feature-banner .container {
  height: 100px!important;
}

.feature-show {

}

h1.feature-show {
  font-size: 3.8125rem!important;
  color: #ffffff;
  line-height: 4rem!important;
  font-family: brandon-grotesque, sans-serif!important;
  font-weight: 400!important;
  font-style: normal!important;
  padding: 0!important;
  margin: 0!important;
}

h2.feature-show {
  color: #FFE500;
  font-size: 1rem;
  line-height: 1.25rem;
  font-weight: 400!important;
  font-style: normal!important;
  margin-top:30px;
}

#star-of-life{
  font-size: 5rem;
  line-height: 1rem;
  position: relative;
  top: 1rem;
  vertical-align: sub;
}

.feature-show.info-block {
  margin: 0 auto;
  width: auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  flex-flow: row nowrap;
  padding: 10px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: left;
  -ms-flex-pack: left;
  justify-content: left;
  background-color: rgba(242, 241, 214, 0.3) !important;
  border-radius: 15px 15px 0px 0px;
  opacity: 1;
  backdrop-filter: blur(30px);
  -webkit-backdrop-filter: blur(30px);
  transition: background-color 0.5s cubic-bezier(0.28, 0.11, 0.32, 1);
  margin-top:40px;
}

.feature-show.cta-block {
  margin: 0 auto;
  width: auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-flow: row nowrap;
  flex-flow: row nowrap;
  padding: 18px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: left;
  -ms-flex-pack: left;
  justify-content: left;
  background-color: #FFE52C !important;
  border-radius: 0px 0px 15px 15px;
  font-weight: 700!important;
  flex-flow: column-reverse;
  font-size: 16px;
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  transition: all 0.2s linear;
  cursor:pointer;
}

.feature-show.cta-block:hover {
  background-color: #D3B900 !important;
  cursor: pointer;
}

.info-block img { width: 105px; height: auto; border-radius: 4px; }

.info-block p {
  font-size: 1rem!important;
  color: #fff!important;
  padding:10px;
  line-height:1.5rem!important;
}

.info-block p span {
  color: #FFE52C;
  font-size: 0.75rem;
  text-transform: uppercase;
  font-weight: 700!important;
}

.container {
  max-width: 1600px;
  margin: 0 auto;
  width: auto;
  display: flex;
  flex-flow: row nowrap;
  padding-left: 87px;
  align-items: center;
  justify-content: left;
  height: 467px;
  @media (max-width: 1410px){
    height: 420px;
  }
  @media (max-width: 1300px){
    height: 380px;
  }
  @media (max-width: $lg) {
    height: 380px;
  }
  @media (max-width: 1079px){
    height: 380px;
  }
  @media (max-width: $md) {
    height: 323px;
  }
  @media (max-width: $sm) {
    padding-left: 55px;
  }
  @media (max-width: $xs) {
    padding-left: 0;
  }
}

.content {
  opacity: 1;
  position: relative;
  z-index: 2;
  flex: 0 0 420px;
  @media (max-width: $md) {
    flex: 0 0 312px;
  }
  @media (max-width: $sm) {
    flex: 0 0 270px;
  }
  @media (max-width: $xs) {
    flex: 1 1 auto;
  }
}

img.feature-show {
  width: 100%;
}

/* Donation Banner */
.homepage-feature-banner {
  background:#37373B;
  border-bottom: 0.5px solid rgba(252, 252, 252, .2)!important;
}

.homepage-feature-banner .container {
  height:100px!important;
}

.homepage-feature-banner p {
  color:#FFFFFF;
  font-size: 1.5rem;
  float:left;
}

.homepage-feature-banner .content { display:contents; }

.homepage-feature-banner .button-wrapper {
  float: right;
  right: 87px;
  position: absolute;
}

.homepage-feature-banner p span{ color:#FFE500; }

/*  */

.homepage-hero:before {
  background-image: url(../../../assets/images/web2/home/TMM-Luminary-homepage-XL-1600x625x2.png),linear-gradient(180deg,#041027 0%,#1b243c 100%)!important;
}

/* Mobile Styles */
@media (max-width: 768px) {
  .homepage-hero:before {
    background-image: url(../../../assets/images/web2/home/TMM-Luminary-homepage-M-768x278x2.b6c76fc.png)!important;
    background-position: 0 0;
    background-size: auto 278px;
  }

  .homepage-feature {
    background-image: url(../../../assets/images/web2/home/Featured-Luminary-homepage-Mobile-678x278x2.png),linear-gradient(180deg,#595758 0%,#595758 100%)!important;
    background-repeat: no-repeat;
    background-position: center;
  }

  .feature .content {
    max-width: 320px;
    margin: 0 auto;
  }

  img.feature-show { width:90%; margin:0 auto; display:block;}

  h2.feature-show {
    font-size: 1.125rem;
    text-align: center;
    margin-top: 14px;
  }
  h1.feature-show {
    font-size: 2.0625rem!important;
    line-height: 2.0625rem!important;
    margin: 0 auto!important;
    width: fit-content;
  }

  .container.feature {
    align-items: flex-end!important;
    margin-bottom: 30px!important;
  }

  #star-of-life {
    font-size: 3rem;
    top: 0.7rem;
  }

  .container.feature {
    align-items: flex-end!important;
    margin-bottom: 30px!important;
  }

  .homepage-feature-banner .container {
    height: auto!important;
    padding-top: 30px!important;
  }

  .homepage-feature-banner .content { display:block;}

  .homepage-feature-banner p {
    text-align: center;
    margin-bottom: 30px;
    font-size: 1.3rem;
  }

  .homepage-feature-banner .button-wrapper{
    position: relative;
    float:none;
    right:auto;
  }

  .homepage-feature-banner .button-wrapper .el-button--primary {
    margin-bottom:0px!important;
  }

}

@media (max-width: 576px) {
  .button-wrapper {
    text-align: center;
    display: block;
    margin-left: auto;
  }
}

.cta-button {
  min-width: 232px;
  padding: 16px 28px;
  font-size: 16px;
}

</style>
