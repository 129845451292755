<template>
  <div id="vinyl_box">
    <div class="vinyl_image_box">
      <img class="vinyl_image" src="@/assets/images/promos/vinyl_image.png">
    </div>
    <div class="vinyl_description">
      <span v-if="!now">Check your email after purchase for steps on how to redeem your limited edition vinyl.</span>
      <span v-if="now">Check your email now for steps on how to redeem your limited edition vinyl.</span>
    </div>
  </div>
</template>
<script>
export default {
  name: 'VinylPromoMessage',
  props: {
    now: {type: Boolean, default: false}
  }
};
</script>
<style lang="scss" scoped>
@import '../../assets/scss/variables.scss';

#vinyl_box {
  padding: 5px;
  display: flex;
  align-items: center;
  border-radius: 10px;
  background-color: #5476a8;
  color: #fff;
  margin-bottom: 20px;
  width: 50%;
  @media (max-width: $xs) {
    width: 90%;
  }
}

.vinyl_image {
  width: 251px;
  margin-left: 0px;
}

.vinyl_image_box {
  width: 50%;
  @media (max-width: 986px) {
    width: 100%;
    display: none;
  }
}

.vinyl_description {
  width: 50%;
  display: flex;
  //height: 160px;
  color: #fff;
  justify-content: left;
  padding: 10px;
  margin: 0px;
  font-size: 18px;
  text-align: left;
  align-items: center;
  line-height: 2rem;
  @media (max-width: 986px) {
    width: 100%;
  }
}

</style>
