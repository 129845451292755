const isSameYear = (date) => {
  const now = new Date();

  return now.getFullYear() === date.getFullYear();
};

const isSameDay = (date1, date2) => (
  (date1.getFullYear() === date2.getFullYear())
  && (date1.getMonth() === date2.getMonth())
  && (date1.getDate() === date2.getDate())
);

export default (val) => {
  const weekDays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

  if (val) {
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    const givenDate = new Date(val);
    givenDate.setHours(0, 0, 0, 0);

    if (isSameDay(today, givenDate)) {
      return 'New Episode Today';
    }

    const timeDiff = Math.abs(today.getTime() - givenDate.getTime());
    const days = Math.floor(timeDiff / (1000 * 3600 * 24));

    if (today > givenDate) {
      if (days < 7) {
        if (days === 1) {
          return 'New Episode 1 Day Ago';
        }

        return `New Episode ${days} Days Ago`;
      }

      if (isSameYear(givenDate)) {
        return `Latest Episode ${months[givenDate.getMonth()]} ${givenDate.getDate()}`;
      }

      return `Latest Episode ${months[givenDate.getMonth()]} ${givenDate.getDate()}, ${givenDate.getFullYear()}`;
    }

    if (days < 7) {
      return ((days === 1) ? 'Tomorrow' : `Available ${weekDays[givenDate.getDay()]}`);
    }

    if (isSameYear(givenDate)) {
      return `Available ${months[givenDate.getMonth()]} ${givenDate.getDate()}`;
    }

    return `Available ${months[givenDate.getMonth()]} ${givenDate.getDate()}, ${givenDate.getFullYear()}`;
  }

  return 'Date Not Available';
};
