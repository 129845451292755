
const defaultGlobalConfig = {
  analytic_events: {
    promoOne: 'promo_one_cta_click',
    promoTwo: 'promo_two_cta_click',
    promoThree: 'promo_three_cta_click',
    promoFour: 'promo_four_cta_click',
    homePageCtaBtnOne: 'home_page_cta_one_click',
    homePageCtaBtnTwo: 'home_page_cta_two_click',
    showsPageCtaBtnOne: 'shows_page_cta_one_click',
    showsPageCtaBannerBtn: 'shows_page_cta_banner_btn_click',
    sdpPagePromoCta: 'sdp_page_promo_cta_click',
    listenPagePromoCta: 'listen_page_promo_cta_click',
    subscriptionPageCtaOne: 'subscription_cta_one',
    subscriptionPageCtaTwo: 'subscription_cta_two',
    subscriptionPageCtaThree: 'subscription_cta_three',
    subscriptionPageCtaFour: 'subscription_cta_four',
    subscriptionPageCtaFive: 'subscription_cta_five',
    eyebrowCta: 'eyebrow-cta-btn-click',
    sdpSubscribeBtn: 'sdp_subscribe_click',
    homeSubscribeBtn: 'home_subscribe_click',
    homeShowsBtn: 'home_shows_click',
  },
  holiday_promo_end_date: '12/25/2019',
  winstarPixelConfig: {
    Fiasco: '/listen/leon-neyfakh/fiasco/8607a31a-cab7-4c9f-9df8-b6110ba93b52',
    Homepage: '/',
    Originals: '/shows',
    Signup: '/signup',
  },
};

export default defaultGlobalConfig;
