function isElementVisible(el) {
  const viewportHeight = document.body.offsetHeight;

  const rect = el.getBoundingClientRect();
  const elementPos = rect.top;
  const elementHeight = el.offsetHeight;
  const threshold = (elementHeight / 3); // part of element within viewport
  const isVisible = el.clientHeight > 0 && elementPos >= (-1 * threshold) && (elementPos + threshold) <= viewportHeight;

  if (isVisible && !el.trackedOnce) {
    el.trackedOnce = true;
    el.callback(el.getAttribute('data-segment-uuid'));
  }
}

const directive = {
  bind(el, binding) {
    let isScrolling;
    el.scrollEventListener = function () {
      window.clearTimeout(isScrolling);
      isScrolling = setTimeout(() => {
        isElementVisible(el);
      }, 66);
    };
    el.callback = binding.value;
    el.trackedOnce = false;

    // TODO: wait for dom rendering without timeout
    setTimeout(() => {
      isElementVisible(el);
    }, 100);

    document.body.addEventListener('scroll', el.scrollEventListener);
  },
  unbind(el) {
    document.body.removeEventListener('scroll', el.scrollEventListener);
  },
};

export default directive;
