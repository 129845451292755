<template>
  <el-popover
    ref="speed-options"
    :popper-options="{ boundariesElement: 'body', gpuAcceleration: false, positionFixed: false }"
    :append-to-body="false"
    placement="top-end"
    popper-class="play-speed"
    trigger="click"
    @hide="hide">
    <ul>
      <li
        v-for="(speed, index) in speedsList"
        :key="index"
        @click="changeAudioRate(speed)">{{ speed }}x</li>
    </ul>
    <div
      slot="reference"
      :class="'speed-button cursor-pointer' + (audio_rate !== '1.0' ? ' show_speed_adjusted' : '')"
      @click="tooglePopup">{{ audio_rate }}x
      <!-- <span>
        <i class="el-icon-caret-bottom" v-if="!show_popup"></i>
        <i class="el-icon-caret-top" v-if="show_popup"></i>
      </span> -->
    </div>
  </el-popover>
</template>
<script>

export default {
  name: 'PlayerSpeedComponent',
  props: {
    playerId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      show_popup: false,
      audio_rate: '1.0',
      speedsList: ['0.5', '0.75', '1.0', '1.25', '1.5', '2.0', '2.5', '3.0'],
    };
  },
  methods: {
    changeAudioRate(speed) {
      const player = document.getElementById(this.playerId);
      this.audio_rate = speed;
      player.playbackRate = speed;
      this.$refs['speed-options'].doClose();
      this.$emit('speedChange', speed);
    },
    tooglePopup() {
      this.show_popup = !this.show_popup;
    },
    hide() {
      this.show_popup = false;
    },
  },
};
</script>
<style lang="scss">
@import "../assets/scss/variables.scss";

.speed-button.cursor-pointer{
  border-radius: 0px;
  position: relative;
}
.div.speed-button:focus{
  outline: none;
}
div.speed-button.show_speed_adjusted{
  color: #ffe500;
}
.el-popover.play-speed{
  min-width: 148px;
  padding: 0px;
  z-index: 10000 !important;
  border-radius: 8px;
  transform: none !important;
  position: absolute !important;
  bottom: 48px !important;
  top: auto !important;
  ul{
    margin: 0;
    list-style: none;
    padding: 0;
    li{
      display: block;
      cursor: pointer;
      padding: 0.75rem 0.5rem;
      border-bottom: 1px solid #d4d4d4;
      text-align: center;
      font-size: 13px;
      font-weight: bold;
    }
    li:hover{
      background: #00AECB; // linear-gradient(135deg, rgba(39,39,39,0.93) 0%, rgba(48,65,69,0.88) 100%);
      color: $white;
    }
    li:last-child{
      border-bottom: 0px;
    }
    .icon{
      margin-right: 4px;
      visibility: hidden;
    }
    .active{
      visibility: visible;
    }
  }
}
</style>
