<template>
  <div class="container">
    <div class="hero">
      <el-row>
        <grid-col
          :xs="{ span: 16, offset: 4 }"
          :sm="{ span: 12, offset: 6 }"
          :md="{ span: 10, offset: 7 }"
          :lg="{ span: 6, offset: 1 }"
          :xl="{ span: 6, offset: 3 }">
          <div class="image-wrapper">
            <picture-component
              :src="podcast.images.default"
              :progressive-load="true"
            />
            <PremiumIndicator
              v-if="podcast.isExclusive"
              size="x-large" />
          </div>
          <div class="play-episode-wrapper-desktop">
            <PlayEpisodeButton
              v-if="showPlayButton"
              :episode="episode"
              :episode-legacy="episode_legacy_formatted_object"
              playlist-type="show" />
            <el-button
              v-button-event="{ buttonText: 'Subscribe to Listen', buttonLink: null }"
              v-else
              type="primary"
              @click="handleCTARouting">
              Subscribe to Listen
            </el-button>
          </div>
        </grid-col>
        <grid-col
          :xs="{ span: 24, offset: 0 }"
          :sm="{ span: 22, offset: 1 }"
          :md="{ span: 22, offset: 1 }"
          :lg="{ span: 15, offset: 1 }"
          :xl="{ span: 11, offset: 1 }">
          <div class="episode-top">
            <div class="listen-free-wrapper">
              <ListenFreeTag :episode="episode" />
            </div>
            <h1
              class="title"
              v-html="$sanitize(episode.title)" />
            <p class="publisher">
              By <router-link
                :to="`/listen/${podcast.publisherSlug}/${podcast.publisherId}?page=1`"
                class="publisher-link">{{ podcast.publisherName }}
              </router-link>
            </p>
            <p class="details">{{ episodeDetails }}</p>
            <div class="play-episode-wrapper-mobile">
              <PlayEpisodeButton
                v-if="showPlayButton"
                :episode="episode"
                :episode-legacy="episode_legacy_formatted_object"
                playlist-type="show" />
              <el-button
                v-button-event="{ buttonText: 'Subscribe to Listen', buttonLink: null }"
                v-else
                type="primary"
                @click="handleCTARouting">
                Subscribe to Listen
              </el-button>
            </div>
            <div class="bookmark-wrapper-desktop">
              <Bookmark
                :episode="episode"
                :episode-legacy-formatted-object="episode_legacy_formatted_object"
                :user-bookmarks="userBookmarks"
                position="left" />
            </div>
            <div class="bookmark-wrapper-mobile">
              <Bookmark
                :episode="episode"
                :episode-legacy-formatted-object="episode_legacy_formatted_object"
                :user-bookmarks="userBookmarks"
                position="center" />
            </div>
          </div>
          <div class="episode-bottom">
            <p
              class="description"
              v-html="$sanitize(episode.description)" />
            <el-button
              class="btn-transparent"
              @click="handleMoreClick">More from this podcast</el-button>
          </div>
        </grid-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import PremiumIndicator from '../../PremiumIndicator';
import ListenFreeTag from '../shared/ListenFreeTag';
import Bookmark from '../shared/Bookmark';
import PlayEpisodeButton from './PlayEpisodeButton';

import showDetailsHelper from '../showDetails/showDetailsHelper';

export default {
  components: {
    PremiumIndicator,
    ListenFreeTag,
    Bookmark,
    PlayEpisodeButton,
  },
  props: {
    episode: {
      type: Object,
      default() {
        return {};
      },
    },
    podcast: {
      type: Object,
      default() {
        return {};
      },
    },
    userBookmarks: {
      type: Array,
      default() {
        return [];
      },
    },
    showData: { // legacy podcast object
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      episode_legacy_formatted_object: {},
    };
  },
  computed: {
    showPlayButton() {
      return !this.episode.isExclusive || this.userPremium;
    },
    userPremium() {
      return this.userSubscriptionState === 'premium' || this.userSubscriptionState === 'chrunedPremium';
    },
    episodeDetails() {
      const episodeDetailsArray = [];
      if (this.episode.season && this.episode.number && this.episode.mediaType !== 'music') {
        const seasonText = `S${this.episode.season} E${this.episode.number}`;
        episodeDetailsArray.push(seasonText);
      }
      if (this.episode.releasedAt) {
        const date = this.$options.filters.dateFormat(this.episode.releasedAt * 1000);
        episodeDetailsArray.push(date);
      }
      if (this.episode.runtime) {
        const runtime = this.$options.filters.humanizedTimeFilter(this.episode.runtime);
        episodeDetailsArray.push(runtime);
      }
      return episodeDetailsArray.join(' | ');
    },
  },
  beforeMount() {
    this.formatLegacyEpisodeObject(this.episode);
  },
  methods: {
    formatLegacyEpisodeObject(episode) {
      this.episode_legacy_formatted_object = showDetailsHelper.formatLegacyEpisodeObject(episode);
    },
    handleMoreClick() {
      if (this.podcast && this.episode) {
        this.$router.push(`/listen/${this.convertToSlug(this.podcast.publisherName)}/${this.convertToSlug(this.podcast.categoryName)}/${this.episode.showId}`);
      }
    },
  },
};

</script>

<style lang="scss" scoped>
@import "../../../assets/scss/variables.scss";

.hero {
  padding-top: 30px;
  padding-bottom: 30px;
  margin-bottom: 30px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  @media(min-width: $md) {
    padding-top: 40px;
    padding-bottom: 40px;
    margin-bottom: 40px;
  }
}

.image-wrapper {
  position: relative;
  margin-bottom: 40px;
}

.play-episode-wrapper-desktop {
  display: none;
  @media(min-width: $md) {
    display: block;
    margin: 0 auto;
    text-align: center;
  }
}

.play-episode-wrapper-mobile {
  margin: 0 auto;
  text-align: center;
  @media(min-width: $md) {
    display: none;
  }
}

.bookmark-wrapper-desktop {
  display: none;
  @media(min-width: $md) {
    display: block;
    margin-top: 24px;
    margin-left: -5px;
  }
}

.bookmark-wrapper-mobile {
  display: block;
  margin-top: 20px;
  @media(min-width: $md) {
    display: none;
  }
}

.episode-top {
  padding-bottom: 22px;
  @media(min-width: $md) {
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  }
}

.listen-free-wrapper {
  text-align: center;
  @media(min-width: $md) {
    text-align: left;
  }
}

.title {
  font-family: $patron-bold;
  font-size: 30px;
  line-height: 32px;
  color: $white;
  margin-bottom: 10px;
  text-align: center;
  @media(min-width: $sm) {
    margin-top: 0;
    margin-bottom: 16px;
  }
  @media(min-width: $md) {
    font-size: 36px;
    line-height: 39px;
    text-align: left;
  }
  @media(min-width: $lg) {
    font-size: 36px;
    line-height: 39px;
  }
}

.publisher {
  font-family: $patron;
  color: $light-gray;
  margin-top: 0;
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 16px;
  text-align: center;
  @media(min-width: $sm) {
    font-size: 18px;
    margin-bottom: 14px;
  }
  @media(min-width: $md) {
    text-align: left;
  }
}

.publisher-link {
  color: $blue;
  &:hover {
    text-decoration: underline;
  }
}

.details {
  font-family: $patron;
  color: $light-gray3;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  margin-top: 0;
  margin-bottom: 30px;
  @media(min-width: $sm) {
    font-size: 16px;
  }
  @media(min-width: $md) {
    text-align: left;
    margin-bottom: 0;
  }
}

.episode-bottom {
  padding-top: 26px;
}

.description {
  font-family: $patron;
  color: $white;
  margin-top: 0;
  margin-bottom: 36px;
  font-size: 14px;
  line-height: 22px;
  @media(min-width: $sm) {
    font-size: 18px;
    line-height: 26px;
  }
  @media(min-width: $lg) {
    font-size: 20px;
    line-height: 28px;
  }
}

.description /deep/ p {
  font-family: $patron;
  color: $white;
  margin-top: 0;
  font-size: 14px;
  line-height: 22px;
  @media(min-width: $sm) {
    font-size: 18px;
    line-height: 26px;
  }
  @media(min-width: $lg) {
    font-size: 20px;
    line-height: 28px;
  }
}

</style>
