<template>
  <el-dialog
    :visible.sync="showModal"
    class="custom-info-modal"
    data-test="custom-info-modal"
    append-to-body
    @close="closeModal"
  >
    <h2 class="custom-info-modal__title">
      {{ header }}
    </h2>

    <div class="custom-info-modal__description">
      <p>{{ description }}</p>
    </div>

    <!-- <el-button
      class="welcome-to-premium__explore"
      type="primary"
      data-test="btn-explorePodcasts"
      @click="explorePodcasts"
    >
      Explore Podcasts
    </el-button> -->
  </el-dialog>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    header: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
  },
  computed: {
    showModal: {
      get() {
        return this.show;
      },
      set() {

      },
    },
  },
  methods: {
    closeModal() {
      this.$emit('closeInfoModal');
    },
  },
};
</script>

<style lang="scss">
@import "../assets/scss/variables.scss";

.custom-info-modal {
  .el-dialog {
    background-color: $brand;
    border-radius: 7px;
    text-align: center;
    padding: 70px;
    margin-top: 0 !important;

    display: inline-block;

    width: auto;

    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);

    &__body {
      padding: 0;
    }

    &__header {
      padding: 0;
    }

    // @media (max-width: 767px) {
    //   top: 0;
    //   left: 0;
    //   transform: none;

    //   width: 100%;
    //   height: 100%;
    //   border-radius: 0;

    //   display: flex;
    //   justify-content: center;
    //   align-items: center;
    // }
  }

  &__title {
    font-size: 40px;
    color: $black;

    margin: 0;
  }

  &__description {
    margin: 30px 0 45px;

    > p {
      color: $black;
      font-size: 16px;
    }
  }

  &__explore {
    padding: 14px 30px;
    font-size: 18px;
  }
}
</style>
