<template>
  <div v-if="showBanner">
    <div
      v-if="isSticky"
      :style="{ height: bannerHeight + 'px' }" />
    <div
      ref="premiumBanner"
      :class="['premium-banner-wrapper', {'sticky': isSticky}]">
      <div class="container">
        <div class="premium-banner">
          <div v-if="!isSpecialPromoEnabled">
            <p class="premium-copy">{{ premium_copy }}</p>
            <p class="premium-copy-mobile">{{ premium_copy_mobile }}</p>
          </div>
          <div v-else>
            <p class="premium-copy">{{ specialPromoCopy }}</p>
            <p class="premium-copy-mobile">{{ specialPromoCopyMobile }}</p>
          </div>
          <div>
            <el-button
              v-button-event="{ buttonText: subscribeCTA, buttonLink: null }"
              class="subscribe-button"
              @click="handleCTARouting">
              {{ subscribeCTA }}
            </el-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  props: {
    podcast: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      subscribe_cta_regular: 'Get Started',
      subscribe_cta_free_or_anon: 'Start Free Trial',
      subscribe_cta_free_was_premium: 'Subscribe to Listen',
      premium_copy: 'This show is a Luminary Original. Subscribe to enjoy more episodes.',
      premium_copy_mobile: 'Subscribe to enjoy more episodes.',
      isSticky: false,
      bannerHeight: 0,
    };
  },
  computed: {
    ...mapGetters({
      countryCode: 'country',
      featureFlag: 'featureFlag',
      isSpecialPromoEnabled: 'isSpecialPromoEnabled',
      specialPromoCopy: 'specialPromoCopy',
      specialPromoCopyMobile: 'specialPromoCopyMobile'
    }),
    showBanner() {
      return this.podcast.isExclusive && this.userNotPremium;
    },
    subscribeCTA() {
      let cta = this.subscribe_cta_regular;

      if ((this.userSubscriptionState === 'anon' || this.userSubscriptionState === 'free') && this.promoMeta.showTrial) {
        cta = this.subscribe_cta_free_or_anon;
      }
      if (this.userSubscriptionState === 'freeWasPremium') {
        cta = this.subscribe_cta_free_was_premium;
      }
      return cta;
    },
    userNotPremium() {
      return this.userSubscriptionState === 'anon' || this.userSubscriptionState === 'free' || this.userSubscriptionState === 'freeWasPremium';
    },
  },
  mounted() {
    window.addEventListener('scroll', this.getStickyState);
    window.addEventListener('resize', this.getBannerHeight);
    this.getBannerHeight();
    this.getStickyState();
  },
  destroyed() {
    window.removeEventListener('scroll', this.getStickyState);
    window.addEventListener('resize', this.getBannerHeight);
  },
  methods: {
    getStickyState() {
      this.isSticky = window.scrollY >= 72; // navbar height is 72
    },
    getBannerHeight() {
      if (this.$refs.premiumBanner) {
        this.bannerHeight = this.$refs.premiumBanner.clientHeight;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../assets/scss/variables.scss";

.premium-banner-wrapper {
  background: linear-gradient(90deg, #FFE500 12.73%, #FFF179 68.55%);
}

.premium-banner {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
  @media(min-width: $sm) {
    flex-direction: row;
    padding: 16px 0;
  }
}

.sticky {
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 1000; // this is arbitrary but seems to work
}

.premium-copy-mixin {
  font-family: $patron;
  color: $black;
  margin-top: 0;
}

.premium-copy {
  @extend .premium-copy-mixin;
  display: none;
  @media(min-width: $sm) {
    display: initial;
    text-align: left;
    font-size: 18px;
    margin-right: 16px;
    margin-bottom: 0;
  }
}
.premium-copy-mobile {
  @extend .premium-copy-mixin;
  text-align: center;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 6px;
  @media(min-width: $sm) {
    display: none;
  }
}

.subscribe-button {
  font-family: $patron;
  letter-spacing: 1px;
  text-align: center;
  display: inline-block;
  transition: .3s ease;
  background: $black;
  border: 1px solid $black;
  color: $white;
  &:hover,
  &:focus {
    background: $hover-black;
    color: $white;
    border: 1px solid $hover-black;
  }
}
</style>
