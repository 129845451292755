import User from '@/services/User';
import { getServices } from '@/services/serviceProvider';

const [userProvider] = getServices([User]);

export const EMAIL_VERIFICATION_STAGE_INITIAL = 'initial';
export const EMAIL_VERIFICATION_STAGE_LOADING = 'loading';
export const EMAIL_VERIFICATION_STAGE_DONE = 'done';
export const EMAIL_VERIFICATION_STAGE_ERROR = 'error';

const getters = {
  stateTrigger: state => state.stateTrigger,
  stateVerify: state => state.stateVerify,
  isLoading: state => state.stateTrigger === EMAIL_VERIFICATION_STAGE_LOADING || state.stateVerify === EMAIL_VERIFICATION_STAGE_LOADING,
  isError: state => state.stateTrigger === EMAIL_VERIFICATION_STAGE_ERROR || state.stateVerify === EMAIL_VERIFICATION_STAGE_ERROR,
  error: state => state.error,
};

const mutations = {
  setStateTrigger: (state, value) => { state.stateTrigger = value; },
  setStateVerify: (state, value) => { state.stateVerify = value; },
  setError: (state, value) => { state.error = value; },
};

const state = {
  stateTrigger: EMAIL_VERIFICATION_STAGE_INITIAL,
  stateVerify: EMAIL_VERIFICATION_STAGE_INITIAL,
  error: null,
};

const actions = {
  triggerEmailVerification: async (context) => {
    context.commit('setStateTrigger', EMAIL_VERIFICATION_STAGE_LOADING);
    context.commit('setError', null);
    try {
      await userProvider.triggerEmailVerification();
      context.commit('setStateTrigger', EMAIL_VERIFICATION_STAGE_DONE);
    } catch (e) {
      context.commit('setError', e);
      context.commit('setStateTrigger', EMAIL_VERIFICATION_STAGE_ERROR);
    }
  },
  verifyEmail: async (context, oobCode) => {
    context.commit('setStateVerify', EMAIL_VERIFICATION_STAGE_LOADING);
    context.commit('setError', null);
    try {
      await userProvider.verifyEmail(oobCode);
      context.commit('setStateVerify', EMAIL_VERIFICATION_STAGE_DONE);
      context.commit('setIsEmailVerified', true, { root: true });
    } catch (e) {
      context.commit('setError', e);
      context.commit('setStateVerify', EMAIL_VERIFICATION_STAGE_ERROR);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
