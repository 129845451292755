<template>
  <div class="stacked-cards">
    <podcast-card
      v-for="(podcast, idx) in podcasts"
      :key="idx"
      :redirect="redirect"
      :podcast="podcast"
      :publisher-name="podcast.publisher_name || ''"
      :type="podcast.item_type || podcast.type"
      :bucket="bucket"
      :media-size="bucket && bucket.media_size ? bucket.media_size : ''"
      :first-item="firstItem && idx === 0"
      :has-hover-state="hasHoverState"
      data-test="podcast-card"
      @onSelect="selectionToggle"/>
  </div>
</template>

<script>
import PodcastCard from './PodcastCard';

export default {
  name: 'StackedPodcastCard',
  components: {
    'podcast-card': PodcastCard,
  },
  props: {
    podcasts: {
      type: Array,
      default: () => [],
    },
    hasHoverState: {
      type: Boolean,
      default: false,
    },
    firstItem: {
      type: Boolean,
      default: false,
    },
    grid: {
      type: Boolean,
      default: false,
    },
    redirect: {
      type: Boolean,
      default: true,
    },
    hidden: {
      type: Boolean,
      default: false,
    },
    selectable: {
      type: Boolean,
      default: false,
    },
    selected: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: '',
    },
    bucket: {
      type: Object,
      default: () => {},
    },
    mediaSize: {
      type: String,
      default: () => 'thumbnail',
    },
    publisherName: {
      type: String,
      default: '',
    },
    selectionToggle: {
      type: Function,
      default: () => null,
    },
  },
};
</script>

<style lang="scss">
@import "../assets/scss/mixins.scss";

.stacked-cards {
  .podcast-card {
    margin-bottom: 80px;
    .podcast-content {
      .podcast-image {
        margin-bottom: 8px;
      }
      .podcast-title {
        margin-bottom: 8px;
        text-align: left;
        display: inline-block;
        font-size: 18px;
      }
      .publisher-name {
        padding-right: 21px;
        /* @include multilinetruncate($lineHeight: 16px, $lineCount: 3, $bgColor: white); */
      }
    }
  }
}
</style>
