<template>
  <section class="promo-section">
    <el-row
      type="flex"
      justify="center">
      <grid-col
        :xs="22"
        :sm="22"
        :md="20"
        :lg="14"
        :xl="10">
        <div class="section-title">
          <h1>{{ title }}</h1>
          <h2>{{ subtitle }}</h2>
          <div
            v-if="promoMeta.isAnonUser || promoMeta.isFreeUser"
            :class="{ 'cta-btn': true, 'floating': floatingBtn }">
            <l-button
              v-button-event
              :on_click="handleClick"
              variant="normal"
              color="blue">
              {{ buttonCTAText }}
            </l-button>
            <p class="content-disclaimer">
              <span> Cancel anytime. <router-link
                v-button-event
                to="/terms"
                aria-label="Terms apply"
                target="_blank">Terms Apply.</router-link></span>
            </p>
          </div>
          <div
            v-if="promoMeta.isChurned"
            :class="{ 'cta-btn': true, 'floating': floatingBtn }">
            <l-button
              v-button-event
              :on_click="handleClick"
              variant="normal"
              color="blue">
              Renew Subscription
            </l-button>
            <p class="content-disclaimer">
              <span> Cancel anytime. <router-link
                v-button-event
                to="/terms"
                aria-label="Terms apply"
                target="_blank">Terms Apply.</router-link></span>
            </p>
          </div>
        </div>
      </grid-col>
    </el-row>
  </section>
</template>

<script>
import Button from '../common/Button';

export default {
  name: 'PromoSection',
  components: {
    'l-button': Button,
  },
  props: {
    title: {
      type: String,
      default: 'Only on Luminary',
    },
    subtitle: {
      type: String,
      default: "Access original shows you won't find anywhere else.",
    },
    ctaClickHandler: {
      type: Function,
      default: null,
    },
    parentPage: {
      type: String,
      default: '',
    },
  },
  computed: {
    floatingBtn() {
      return this.$route.path === '/' && this.$store.getters.flagWithDefault('float_cta_btn', true);
    },
  },
  methods: {
    handleClick() {
      if (this.ctaClickHandler) {
        return this.ctaClickHandler();
      }
      if (this.parentPage === 'Home') {
        return this.handleCTARouting('homeSubscribeBtn');
      }
      return this.handleCTARouting();
    },
  },
};

</script>

<style lang="scss">
@import "../../assets/scss/variables.scss";
@import "../../assets/scss/mixins.scss";

.promo-section {
  .section-title {
    @media (min-width: 1600px) {
      padding: 104px 0px 54px;
    }
    @media (min-width: 1024px) {
      padding: 104px 0px 44px;
    }
    @media (min-width: 768px) {
      padding: 88px 0px 32px;
    }
    padding: 80px 0px 40px 0px;
    text-align: center;
    h1 {
      transform: translateX(0px);
      //font-family: $serif;
      font-size: 40px;
      line-height: 48px;
      text-align: center;
      letter-spacing: -1px;
      color: #FFFFFF;
      margin: 0 0 15px;
      @media (min-width: 576px) and (max-width: 768px) {
        margin: 0 0 31px;
        font-size: 34px;
        line-height: 38px;
      }
    }
    h2 {
      //font-family: $clearsans;
      font-style: normal;
      font-size: 24px;
      line-height: 22px;
      text-align: center;
      color: #BBBBBB;
      @media (max-width: 768px) {
        /* padding: 0 5%; */
        font-size: 20px;
        line-height: 22px;
      }
    }
    .cta-btn {
      padding: 28px 0px;
      &.floating {
        @media(max-width: 576px) {
          padding: 0px;
        }
        button {
          @media(max-width: 576px) {
            position: fixed;
            bottom: 55px;
            left: 50%;
            z-index: 99999;
            margin-left: -150px;
          }
        }
        .content-disclaimer {
          @media(max-width: 576px) {
            display: none;
          }
        }
      }
      button {
        width: 299px;
        height: 48px;
        background-color: $brand;
        //font-family: $clearsans;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: black;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border: 0px;
        padding: 14px 57px;
        &:focus {
          padding: 13px 56px;
          border: 2px solid #FFFFFF;
        }
      }
      .content-disclaimer {
        font-size: 14px;
        line-height: 40px;
        text-align: center;
        color: #CCCCCC;
        //font-family: $clearsans;
        margin-top: 17px;
        margin-right: 16px;
        margin-left: 16px;
        a {
          color: #FFFFFF !important;
          text-decoration: underline;
        }
      }
    }
  }
}
</style>
