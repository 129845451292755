<template>
  <div class="container">
    <el-button
      class="update-password-back-button"
      data-test="update-password-back-button"
      type="text"
      @click="goBack"
    >
      <i class="el-icon-arrow-left" />
      Back
    </el-button>
    <div class="change-password">
      <loader v-show="!data_loaded"/>
      <div v-show="data_loaded">
        <div class="m-b-3">
          <div v-if="!showThankyou">
            <h4 class="t-c font-normal">Update Password</h4>
            <el-form
              ref="changePasswordForm"
              :model="changePassword"
              :rules="changePasswordValidationRules"
              @keyup.enter.native="onSubmit">
              <el-form-item
                label="Enter current password"
                prop="old_password"
                class="m-t-2">
                <el-input
                  v-model="changePassword.old_password"
                  :type="showPassword.old_password ? 'text': 'password'"
                  label="Current password"
                  placeholder="Enter current password"
                  auto-complete="off"
                  data-test="oldPassword-field"
                  @keyup.native="checkValidation"/>
                <show-password @show="(e) => { showPassword.old_password = e }"/>
              </el-form-item>
              <el-form-item
                label="Enter a new password"
                prop="new_password"
                class="">
                <el-input
                  v-model="changePassword.new_password"
                  :type="showPassword.new_password ? 'text': 'password'"
                  label="New password"
                  placeholder="Enter new password"
                  auto-complete="off"
                  data-test="newPassword-field"
                  @keyup.native="checkValidation"/>
                <show-password @show="(e) => { showPassword.new_password = e }"/>
              </el-form-item>
              <el-form-item
                label="Confirm new password"
                prop="confirm_password"
                class="">
                <el-input
                  v-model="changePassword.confirm_password"
                  :type="showPassword.confirm_password ? 'text': 'password'"
                  label="Confirm new password"
                  placeholder="Confirm new password"
                  auto-complete="off"
                  data-test="confirmPassword-field"
                  @keyup.native="checkValidation"/>
                <show-password @show="(e) => { showPassword.confirm_password = e }"/>
              </el-form-item>
              <div class="submit-btn t-c">
                <!-- :type="readyToSubmit ? 'primary' : 'default'" -->
                <el-button
                  v-loading="saving_data"
                  :disabled="!form_valid"
                  class="button large block-btn spotlight"
                  type="primary"
                  data-test="changePassword-submit"
                  @click="onSubmit">Submit</el-button>
              </div>
            </el-form>
          </div>
          <div
            v-if="showThankyou"
            class="t-c thank-you-section"
            data-test="thankyou-section">
            <h4>You’re all set.</h4>
            <p>Your password has been updated.</p>
            <el-button
              class="button large block-btn spotlight"
              data-test="continue-button"
              type="primary"
              @click="redirectToHomePage">Back to Home</el-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as log from 'loglevel';
import User from '../../services/User';
import Loader from '../../components/Loader';
import PageMixin from '../../shared/pageMixin';

export default {
  components: {
    Loader,
    'show-password': () => import('../../components/ShowPassword'),
  },
  mixins: [PageMixin],
  data() {
    const validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('Confirm password required'));
      } else if (value !== this.changePassword.new_password) {
        callback(new Error("Passwords don't match"));
      } else {
        callback();
      }
    };
    const passwordStrength = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('Password Required'));
      } else {
        const strongRegex = new RegExp('^(?=.*[a-zA-Z])(?=.*[0-9])(?=.{8,})');

        if (strongRegex.test(value)) {
          callback();
        } else {
          callback(new Error('Weak Password Strength (8+ characters, including 1 number)'));
        }
      }
    };
    return {
      pageName: 'Change Password',
      user_service: new User(),
      data_loaded: false,
      saving_data: false,
      showThankyou: false,
      invalid: false,
      form_valid: false,
      changePassword: {
        old_password: '',
        new_password: '',
        confirm_password: '',
      },
      changePasswordValidationRules: {
        old_password: [
          { required: true, message: 'Current Password required' },
        ],
        new_password: [
          { required: true, validator: passwordStrength },
        ],
        confirm_password: [
          { required: true, validator: validatePass2 },
        ],
      },
      showPassword: {
        old_password: false,
        new_password: false,
        confirm_password: false,
      },
    };
  },
  computed: {
    readyToSubmit() {
      return !!(this.changePassword.old_password && this.changePassword.new_password && this.changePassword.confirm_password && this.changePassword.new_password === this.changePassword.confirm_password);
    },
  },
  mounted() {
    this.data_loaded = true;
  },
  methods: {
    goBack() {
      this.$router.push('/account/info');
    },
    onSubmit() {
      this.$refs.changePasswordForm.validate((valid) => {
        if (valid) {
          this.updateUserPassword();
        }
      });
    },
    resetFields() {
      this.$refs.changePasswordForm.resetFields();
    },
    updateUserPassword() {
      this.invalid = false;
      this.saving_data = true;
      this.user_service.reAuthenticateUser(this.changePassword.old_password)
        .then(() => {
          this.updatePassword();
        })
        .catch((err) => {
          log.info(err.message);
          this.form_valid = false;
          this.saving_data = false;
          this.showErrorMsg('The current password you entered is wrong!');
          this.resetFields();
        });
      this.trackPasswordUpdate();
    },
    trackPasswordUpdate() {
      const data = {
        email: this.user_service.user.email,
      };
      this.$analytics.track('Password Updated', data);
    },
    updatePassword() {
      this.user_service.updateUserPassword(this.changePassword.new_password)
        .then(() => {
          this.user_service.loginToLuminary().then(() => {
            this.showThankyou = true;
          });
        })
        .catch((err) => {
          log.info(err.message);
          this.resetFields();
          this.form_valid = false;
          this.showErrorMsg('The current password you entered is wrong!');
        })
        .then(() => {
          this.saving_data = false;
        });
    },
    redirectToHomePage() {
      this.$router.push('/listen');
    },
    checkValidation() {
      if (this.changePassword.old_password.length && this.changePassword.new_password.length && this.changePassword.confirm_password.length) {
        this.$refs.changePasswordForm.validate((valid) => { this.form_valid = valid; });
      }
    },
  },
};
</script>

<style lang="scss">
@import '../../assets/scss/variables.scss';

.container > .update-password-back-button {
  padding-left: 0;
  margin-top: 20px;
}

.change-password{
  max-width: 414px;
  width: 90%;
  margin: auto;
  // margin-top: 80px;
  .el-form{
    .el-form-item{
      margin-bottom: 16px;
      .el-form-item__label{
        color: #111111;
        font-size: 16px;
        line-height: 28px;
      }
      .el-form-item__label:before{
        content: none !important;
      }
      .el-input{
        .el-input__inner{
          height: 48px;
          border-radius: 8px;
        }
      }
    }
    .submit-btn {
      margin-top: 32px;
    }
  }
  .title{
    margin: 0px !important;
    margin-bottom: 24px !important;
    font-size: 24px !important;
    font-weight: normal !important;
    line-height: 28px;
    color: #111111;
  }
  .el-button{
    min-width: 110px;
    margin-top: 16px;
  }
  .el-button.el-button--default{
    min-width: 110px;
    background-color: #bbbbbb;
    border-color: inherit;
    color: #ffffff;
    margin-top: 16px;
  }
  .thank-you-section {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    > div{
      color: #bbbbbb;
    }
    .title{
      margin-bottom: 18px !important;
    }
    .el-button{
      margin-top: 24px;
    }
  }
  h4 {
    font-family: $clear-sans;
    color: $black !important;
    font-size: 40px !important;
    letter-spacing: -1px;
    line-height: 44px;
    margin: 46px 0 22px 0;
  }
}
.el-form-item__error{
  margin: 0px;
}
@media (max-width: 1024px) {
  .change-password{
    max-width: 414px;
    width: 414px;
  }
}
@media (max-width: 768px) {
  .change-password{
    max-width: 378px;
    width: 378px;
  }
}
@media (max-width: 567px) {
  .change-password{
    max-width: 311px;
    width: 311px;
    margin: auto;
    margin-top: 40px;
  }
}
</style>
