<template>
  <div
    :class="{'e1-ap-item': true, 'e1-ap-item--horizontal': isHorizontal}"
    @click="onPlanSelect"
  >
    <h1 class="e1-ap-item__header">Luminary Channel on Apple Podcasts</h1>
    <div class="e1-ap-item__description">You can now listen to the entire collection of Luminary Originals in Apple Podcasts.</div>
    <apple-podcasts-link :url="url" :img-styles="btnStyles" />
  </div>
</template>

<script>
import ApplePodcastsLink from '@/components/ApplePodcastsLink';
import OptimizeService from '@/services/Optimize';
import { getServices } from '@/services/serviceProvider';

const [optimizeService] = getServices([OptimizeService]);

export default {
  name: 'E1ApplePodcastItem',
  components: {
    ApplePodcastsLink,
  },
  props: {
    isHorizontal: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      btnStyles: {
        width: '155px',
      },
      url: 'https://podcasts.apple.com/us/channel/luminary/id6442463604?itsct=luminary_podcasts&itscg=30200&ls=1&at=1000l36wW&ct=lum_su_flow',
    };
  },
  computed: {},
  methods: {
    onPlanSelect() {
      const toAP = () => { window.location.href = this.url; };
      optimizeService.triggerAnlyticsEvent({
        action: 'SelectPlan',
        category: 'Signup',
        label: 'applePodcasts',
        cb: toAP,
      });
      this.$analyticsV2.logEvent('cf_button_clicked', { is_cta: true, button_text: 'Listen on Apple Podcasts', target_url: this.url });
      // duplicate to avoid blockers in case analytics request won't be triggered
      setTimeout(toAP, 1000);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../../assets/scss/variables.scss";

.e1-ap-item {
  margin: 16px;
  padding: 32px;
  width: 300px;
  height: 350px;
  background: $dark-background;
  color: #ffffff;
  background: #121C30;
  border: 1px solid #000000;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  position: relative;
  transition: border 1s;
  cursor: pointer;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  align-items: center;
  &--horizontal {
    flex: 1 1 100%;
    height: 94px;
    flex-flow: row;
  }
  &--horizontal &__header {
    font-size: 24px;
    line-height: 22px;
    width: 187px;
  }
  &__header {
    margin: 0;
    font-family: $patron;
    font-size: 28px;
    line-height: 34px;
    text-align: center;
    color: #FFFFFF;
    margin-bottom: 10px;
  }
  &__description {
    font-family: $patron;
    font-size: 13px;
    text-align: center;
  }
  &--horizontal &__description {
    width: 268px;
  }

  .e1-ap-item-horizontal {
    height: 150px;
  }

}
</style>
