import Subscription from '@/services/Subscription';
import GiftService from '@/services/Gift';
import { getServices } from '@/services/serviceProvider';

const [subscriptionProvider, giftProvider] = getServices([Subscription, GiftService]);

const getters = {
  activePlan: state => state.activePlan,
  activePlanCharges: state => state.activePlanCharges,
  pricing: (state) => {
    if (state.userSubscription && state.userSubscription.plan && state.userSubscription.plan.total_price) {
      const { total_price, currency } = state.userSubscription.plan;
      return {
        price: total_price,
        currency,
      };
    }
    return state.pricing;
  },
  plansLoaded: state => state.plansLoaded,
  userSubscription: state => state.userSubscription,
};

const mutations = {
  setActivePlan: (state, activePlan) => { state.activePlan = activePlan; },
  setPricing: (state, pricing) => { state.pricing = pricing; },
  setPlansLoaded: (state, plansLoaded) => { state.plansLoaded = plansLoaded; },
  setUserSubscription: (state, userSubscription) => { state.userSubscription = userSubscription; },
  setSubscriptionLoadingState: (state, v) => { state.subscriptionLoadingState = v; },
  setSubscriptionError: (state, err) => { state.subscriptionError = err; },
  setSubscriptionErrorCode: (state, err) => { state.subscriptionErrorCode = err; },
  setSubscriptionStep2Error: (state, err) => { state.subscriptionStep2Error = err; },
  setStep2PaymentResult: (state, payload) => { state.step2PaymentResult = payload; },
};

const state = {
  activePlan: null,
  activePlanCharges: null,
  pricing: { currency: 'USD', price: 7.99 },
  plansLoaded: false,
  userSubscription: null,
  subscriptionLoadingState: 'initial', // idle, loading, error
  subscriptionError: '',
  subscriptionErrorCode: null,
  subscriptionStep2Error: null,
  step2PaymentResult: null,
};

const actions = {
  setActivePlan: (context, activePlan) => {
    context.commit('setActivePlan', activePlan);
  },
  loadUserSubscription(context) {
    context.commit('setSubscriptionLoadingState', 'loading');
    context.commit('setSubscriptionError', '');
    context.commit('setSubscriptionErrorCode', null);
    return subscriptionProvider.getSubscription().then((subscription) => {
      context.commit('setSubscriptionLoadingState', 'idle');
      context.commit('setUserSubscription', subscription);
      context.commit('setSubscriptionErrorCode', null);
      context.commit('setSubscriptionError', '');
      if (subscription.plan && context.getters.pricingPlansLoaded && context.getters.pricingPlans) {
        const plan = context.getters.pricingPlans.find(p => p.planTypeString === subscription.plan.type);
        if (plan) context.commit('setActivePlan', plan);
      }
    }).catch((err) => {
      context.commit('setSubscriptionLoadingState', 'error');
      context.commit('setSubscriptionError', err.message);
      context.commit('setSubscriptionErrorCode', err.code);
      context.commit('setUserSubscription', null);
    });
  },
  pauseSubscription(context) {
    context.commit('setSubscriptionLoadingState', 'loading');
    context.commit('setSubscriptionError', '');
    context.commit('setSubscriptionErrorCode', null);
    return subscriptionProvider
      .pauseSubscription()
      .then(() => context.dispatch('loadUserSubscription'))
      .catch((err) => {
        context.commit('setSubscriptionLoadingState', 'error');
        context.commit('setSubscriptionError', err.message);
        context.commit('setSubscriptionErrorCode', err.code);
      });
  },
  unpauseSubscription(context) {
    context.commit('setSubscriptionLoadingState', 'loading');
    context.commit('setSubscriptionError', '');
    context.commit('setSubscriptionErrorCode', null);
    return subscriptionProvider
      .unpauseSubscription()
      .then(() => context.dispatch('loadUserSubscription'))
      .catch((err) => {
        context.commit('setSubscriptionLoadingState', 'error');
        context.commit('setSubscriptionError', err.message);
        context.commit('setSubscriptionErrorCode', err.code);
      });
  },
  makeStep2Payment(context, { method, data }) {
    // eslint-disable-next-line no-unused-vars
    const finalizingMethods = {
      createDropInSubscription: 'createDropInSubscriptionFinal',
      updateDropInSubscriptionMethod: 'updateDropInSubscriptionMethodFinal',
      purchaseGift: 'purchaseGiftFinal',
    };
    const finalMethod = finalizingMethods[method];
    context.commit('setSubscriptionLoadingState', 'loading');
    context.commit('setSubscriptionStep2Error', null);
    context.commit('setStep2PaymentResult', null);
    if (!finalMethod) {
      context.commit('setSubscriptionLoadingState', 'error');
      context.commit('setSubscriptionStep2Error', { message: 'Can\'t finalize payment, method not found' });
      return Promise.resolve(null);
    }
    if (method === 'purchaseGift') {
      return giftProvider[finalMethod](data)
        .then((res) => {
          context.commit('setSubscriptionLoadingState', 'idle');
          // here we commit request data for legacy code (NOT A BUG)
          context.commit('setStep2PaymentResult', data);
          return res;
        })
        .catch((err) => {
          context.commit('setSubscriptionLoadingState', 'error');
          context.commit('setSubscriptionStep2Error', err);
        });
    }
    return subscriptionProvider[finalMethod](data)
      .then((res) => {
        context.commit('setStep2PaymentResult', res);
        context.commit('setSubscriptionLoadingState', 'idle');
        return res;
      })
      .catch((err) => {
        context.commit('setSubscriptionLoadingState', 'error');
        context.commit('setSubscriptionStep2Error', err);
      });
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
