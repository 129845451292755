import { render, staticRenderFns } from "./SpecialPromoOffer.vue?vue&type=template&id=796705d1&scoped=true&"
import script from "./SpecialPromoOffer.vue?vue&type=script&lang=js&"
export * from "./SpecialPromoOffer.vue?vue&type=script&lang=js&"
import style0 from "./SpecialPromoOffer.vue?vue&type=style&index=0&id=796705d1&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "796705d1",
  null
  
)

export default component.exports