<template>
  <div class="widget-wrapper">
    <loading-layout
      v-for="(n, index) in loadingArray"
      v-if="isLoadingWidgets"
      :num-loading="numLoadingPerWidget"
      :key="index" />
    <div
      v-for="(widget, index) in widgets"
      v-if="!isLoadingWidgets"
      :key="widget.id">
      <widget-container
        :widget="widget"
        :page-id="pageId"
        :page-target="pageTarget"
        :row="index"
        :num-loading-per-widget="numLoadingPerWidget"
      />
    </div>
  </div>
</template>

<script>
import * as log from 'loglevel';
import WidgetContainer from './WidgetContainer';
import Widget from '../../services/Widget';
import widgetHelper from './widgetHelper';
import LoadingLayout from './LoadingLayout';

export default {
  name: 'WidgetWrapper',
  components: {
    'widget-container': WidgetContainer,
    'loading-layout': LoadingLayout,
  },
  props: {
    cmsPage: {
      type: String,
      default: 'marketing-home',
    },
    pageTarget: {
      type: String,
      default: 'home',
    },
    numLoadingWidgets: {
      type: Number,
      default: 6,
    },
    numLoadingPerWidget: {
      type: Number,
      default: 8,
    },
  },
  data() {
    return {
      widgetService: new Widget(),
      widgets: [],
      pageId: '',
      isLoadingWidgets: true,
      loadingArray: new Array(this.numLoadingWidgets),
    };
  },
  mounted() {
    this.getPageWidgets(this.cmsPage);
  },
  methods: {
    getPageWidgets(page) {
      this.widgetService.getPageWidgets(page)
        .then((resp) => {
          this.pageId = resp.id;
          this.widgets = widgetHelper.mapPreloadedWidgetsItems(resp);
          this.isLoadingWidgets = false;
        })
        .catch((error) => {
          log.error(error);
          this.isLoadingWidgets = false;
        });
    },
  },
};

</script>
