import SegmentEvent from '../services/PlayerEvent';

export default {
  data() {
    return {
      contentViewed: [],
      isWindowScrolling: null,
      parentSelector: null,
      childSelector: null,
      contentList: [],
      eventProvider: new SegmentEvent(),
    };
  },
  mounted() {
    document.body.addEventListener('scroll', this.windowScrollListener);
  },
  destroyed() {
    document.body.removeEventListener('scroll', this.windowScrollListener);
  },
  methods: {
    windowScrollListener() {
      const ctx = this;
      window.clearTimeout(this.isWindowScrolling);
      this.isWindowScrolling = setTimeout(() => {
        ctx.onWindowScrollComplete();
      }, 66);
    },
    onWindowScrollComplete() {
      let parentEle = document.getElementsByClassName(this.parentSelector);
      if (parentEle && parentEle.length) {
        [parentEle] = parentEle;
        const visibleElements = parentEle.querySelectorAll(this.childSelector);
        if (visibleElements.length) {
          const lastVisibleChild = visibleElements[visibleElements.length - 1];
          const dataUUID = lastVisibleChild.getAttribute('data-segment-uuid');
          let dataIndex = this.contentList.findIndex(item => item.uuid === dataUUID);
          if (dataIndex > -1 && this.contentViewed.indexOf(dataUUID) < 0) {
            dataIndex = (dataIndex + 1 > this.contentList.length) ? dataIndex : (dataIndex + 1);

            const bucketItemsSeen = this.contentList.slice(0, dataIndex);
            this.contentViewed = bucketItemsSeen.map(item => item.uuid);

            this.bucket.items_count = this.contentList.length;
            this.eventProvider.triggerContentViewedEvent(this.bucket, this.contentViewed);
          }
        }
      }
    },
  },
};
