<template>
  <div
    class="premium-banner"
    data-test="premium-banner">
    <el-row
      type="flex"
      justify="space-between"
      align="middle"
      class="banner-row container"
    >
      <div>
        <h4
          v-if="title"
          class="font-normal"
        >
          This show is a Luminary Original.
        </h4>
        <h4
          v-if="title"
          class="free-trial font-normal"
        >
          Subscribe to unlock more episodes.
        </h4>
      </div>
      <div class="learn-more">
        <el-button
          v-button-event="{buttonText: 'Start Your Free Trial', buttonLink: null}"
          type="primary"
          class="el-button large"
          data-test="btn-learnmore"
          @click="handleCTARouting('sdpSubscribeBtn')"
        >
          Start Your Free Trial
        </el-button>
      </div>
    </el-row>
    <div class="t-c">
      <el-button
        type="primary"
        class="learn-more-mobile large"
        @click="handleCTARouting('sdpSubscribeBtn')">
        <el-button
          v-button-event="{buttonText: 'Start Your Free Trial', buttonLink: null}"
          class="btn-transparent"
        >
          Start Your Free Trial
        </el-button>
      </el-button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    show: {
      type: Object,
      default: () => {},
    },
    title: {
      type: String,
      default: () => '',
    },
  },
  data() {
    return {

    };
  },
  methods: {

  },
};
</script>

<style lang="scss" scoped>
  @import "../assets/scss/util.scss";
  @import "../assets/scss/variables.scss";

  .premium-banner {
    background-color: #ffe500;
    padding: 16px 0;
    box-sizing: border-box;

    @include media(767px) {
      .banner-row {
        flex-direction: column;
      }
    }

    .el-button {
      @include media(950px) {
        padding: 11px 11px;
        font-size: 14px;
      }
    }

    h4 {
      font-size: 18px;
      line-height: 20px;
      margin: 0 6px 0 0;
      color: $black;

      &:nth-child(1) {
        @include media (767px) {
          margin-right: 0;
        }
      }

      &:nth-child(2) {
        margin-right: 25px;

        @include media (767px) {
          margin-right: 0;
        }
      }

      display: inline-block;

      @include media(950px) {
        font-size: 16px;
      }

      @include media(767px) {
        display: block;
        text-align: center;

        margin-bottom: 12px;
      }

      &.free-trial {
        @include media(567px) {
          font-size: 14px;
        }
      }
    }

    .learn-more-mobile {
      display: none;
    }

    .greetings {
      font-size: 16px;
      font-weight: normal;
    }

    .title {
      margin: 0;
    }

    .learn-more {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }
</style>
