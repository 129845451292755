<template>
  <div class="hero">
    <div class="container">
      <div class="content">
        <h1 class="title">{{ title }}</h1>
        <p class="subtitle">{{ subtitle }}</p>
        <div class="button-wrapper">
          <el-button
            class="el-button--primary large"
            @click="handleClick">
            {{ buttonText }}
          </el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { format } from 'date-fns';

export default {
  data() {
    return {
      title: 'Thanks for giving the gift of Luminary!',
      buttonText: 'Browse Originals',
    };
  },
  computed: {
    giftConfirmation() {
      return this.$store.getters.giftConfirmation;
    },
    subtitle() {
      return `Your gift will be sent on ${this.formattedDate}. We’ve sent a confirmation to ${this.giftConfirmation.yourEmail}`;
    },
    formattedDate() {
      return format(new Date(this.giftConfirmation.delivered_at), 'MM/dd/yyyy');
    },
  },
  methods: {
    handleClick() {
      this.$router.push('/shows');
    },
  },
};

</script>

<style lang="scss" scoped>
@import "../../../assets/scss/variables.scss";

.hero {
  width: 100%;
  background-image: linear-gradient(180.06deg, rgba(18, 28, 48, 0.65) 0.06%, rgba(18, 28, 48, 0.77) 27.18%, #121C30 58.44%), url(../../../assets/images/web2/gifting/bg-gifting-hero-new.png);
  background-position: top center;
  @media (min-width: $xs) {
    background-image: linear-gradient(0, #121C30 17.54%, rgba(18, 28, 48, 0.95) 62.07%, rgba(18, 28, 48, 0) 100%), url(../../../assets/images/web2/gifting/bg-gifting-hero-new.png);
  }
}

.content {
  margin-top: 42px;
  margin-bottom: 42px;
  @media (min-width: $xs) {
    margin-top: 200px;
    margin-bottom: 200px;
  }
}

.title {
  color: $white;
  font-family: $serif;
  text-align: center;
  font-size: 30px;
  line-height: 36px;
  margin: 0 auto 24px;
  max-width: 295px;
  @media (min-width: $sm) {
    max-width: initial;
    font-size: 36px;
    line-height: 42px;
  }
  @media (min-width: $md) {
    font-size: 40px;
    line-height: 48px;
  }
  @media (min-width: $lg) {
    font-size: 48px;
    line-height: 56px;
  }
}

.subtitle {
  color: $white;
  text-align: center;
  margin: 0 auto;
  font-size: 16px;
  line-height: 21px;
  margin-bottom: 36px;
  max-width: 295px;
  @media (min-width: $sm) {
    max-width: 428px;
  }
  @media (min-width: $md) {
    font-size: 18px;
    line-height: 22px;
    margin-bottom: 48px;
  }
}

.button-wrapper {
  text-align: center;
}

</style>
