<template>
  <div class="container">
    <div class="hero">
      <el-row>
        <grid-col
          :xs="{ span: 16, offset: 4 }"
          :sm="{ span: 12, offset: 6 }"
          :md="{ span: 12, offset: 0 }"
          :lg="{ span: 8, offset: 3 }"
          :xl="{ span: 8, offset: 4 }">
          <div>
            <picture-component
              :src="podcast.images.default"
              :progressive-load="true"
            />
            <PremiumIndicator
              v-if="podcast.isExclusive"
              size="x-large" />
          </div>
        </grid-col>
        <grid-col
          :xs="{ span: 24, offset: 0 }"
          :sm="{ span: 22, offset: 1 }"
          :md="{ span: 11, offset: 1 }"
          :lg="{ span: 10, offset: 1 }"
          :xl="{ span: 7, offset: 1 }">
          <h1
            class="title"
            v-html="$sanitize(podcast.title)" />
          <p class="publisher">
            By <router-link
              :to="`/listen/${podcast.publisherSlug}/${podcast.publisherId}?page=1`"
              class="publisher-link">{{ podcast.publisherName }}
            </router-link>
          </p>
          <div class="description-wrapper">
            <p
              v-if="!showReadMoreButton || !isDescriptionTruncated"
              class="description"
              v-html="podcastDescription" />
            <p
              v-if="showReadMoreButton && isDescriptionTruncated"
              class="description"
              v-html="podcastDescriptionTruncated" />
            <el-button
              v-if="showReadMoreButton"
              class="read-more"
              type="text"
              @click="togglePodcastDescription"
            >
              Read {{ isDescriptionTruncated ? 'More' : 'Less' }}
              <i :class="`el-icon-arrow-${isDescriptionTruncated ? 'down' : 'up'}`" />
            </el-button>
          </div>
          <div class="cta-wrapper">
            <el-button
              v-if="showTrailerCTA"
              class="btn-transparent trailer-cta"
              @click="playTrailer">
              <span v-if="!isTrailerPlaying">
                <img src="./../../../assets/images/ic_play_blue.svg">
                <span class="trailer-copy">{{ trailer_cta }}</span>
              </span>
              <span v-if="isTrailerPlaying">
                <img src="./../../../assets/images/ic_pause_blue.svg">
                <span class="trailer-copy">{{ trailer_cta }}</span>
              </span>
            </el-button>
            <el-button
              v-show="showMyShowsCTA"
              v-if="!isInLibrary"
              :loading="isLoadingLibrary"
              class="btn-transparent"
              @click="addToMyShows">
              {{ ctaMyShowsLabel }}
            </el-button>
            <el-button
              v-show="showMyShowsCTA"
              v-if="isInLibrary"
              :loading="isLoadingLibrary"
              class="el-button--primary"
              @click="removeFromMyShows">
              {{ ctaMyShowsLabel }}
            </el-button>
          </div>
        </grid-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import { mapActions, mapGetters } from 'vuex';
import * as log from 'loglevel';
import PremiumIndicator from '../../PremiumIndicator';
import PlayerEvent from '../../../services/PlayerEvent';
import showDetailsHelper from './showDetailsHelper';
import { getServices } from '@/services/serviceProvider';

const [eventProvider] = getServices([PlayerEvent]);


export default {
  components: {
    PremiumIndicator,
  },
  props: {
    podcast: {
      type: Object,
      default() {
        return {};
      },
    },
    showData: { // legacy podcast object
      type: Object,
      default() {
        return {};
      },
    },
    trailer: {
      type: Object,
      default() {
        return {};
      },
    },
    parentPage: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      hero_is_exclusive_copy: 'This show is a Luminary Original. Subscribe to listen',
      trailer_cta: 'Trailer',
      is_in_my_shows_cta: 'Added',
      is_not_in_my_shows_cta: 'Add to My Shows',
      trailer_legacy_formatted_object: {},
      showReadMoreButton: false,
      isDescriptionTruncated: false,
      podcastDescription: '',
      podcastDescriptionTrunacted: '',
      podcastDescriptionTruncateLength: 400,
    };
  },
  computed: {
    ...mapGetters({
      isLoggedIn: 'loggedIn',
      isPodcastInLibrary: 'libraryv2/isPodcastInLibrary',
      isItemLoading: 'libraryv2/isItemLoading',
      isLibraryPodcastsLoading: 'libraryv2/isLibraryPodcastsLoading',
    }),
    ctaMyShowsLabel() {
      return this.isInLibrary ? this.is_in_my_shows_cta : this.is_not_in_my_shows_cta;
    },
    isInLibrary() {
      return this.isPodcastInLibrary(this.podcast.id);
    },
    isLoadingLibrary() {
      return this.isItemLoading(this.podcast.id) || this.isLibraryPodcastsLoading;
    },
    showTrailerCTA() {
      return !_.isEmpty(this.trailer) && this.userNotAnon && this.podcast.isExclusive;
    },
    showMyShowsCTA() {
      return this.userNotAnon;
    },
    userPremium() {
      return this.userSubscriptionState === 'premium' || this.userSubscriptionState === 'chrunedPremium';
    },
    userNotPremium() {
      return this.userSubscriptionState === 'anon' || this.userSubscriptionState === 'free' || this.userSubscriptionState === 'freeWasPremium';
    },
    userNotAnon() {
      return (this.userSubscriptionState !== 'anon');
    },
    isTrailerPlaying() {
      const { play, episode } = this.$store.getters;
      return !_.isEmpty(this.trailer) && play && episode && (episode.uuid === this.trailer.id);
    },
  },
  watch: {
    isLoggedIn(v) {
      if (v) this.checkPodcastsInLibrary([this.podcast.id]);
    },
  },
  beforeMount() {
    if (this.isLoggedIn) this.checkPodcastsInLibrary([this.podcast.id]);
    this.setPodcastDescription();
  },
  methods: {
    ...mapActions({
      addPodcastToLibrary: 'libraryv2/addPodcastToLibrary',
      removePodcastFromLibrary: 'libraryv2/removePodcastFromLibrary',
      checkPodcastsInLibrary: 'libraryv2/checkPodcastsInLibrary',
    }),
    playTrailer() {
      const { episode } = this.$store.getters;
      this.trailer_legacy_formatted_object = showDetailsHelper.formatLegacyTrailerObject(this.trailer, this.showData);
      this.trailer_legacy_formatted_object.playedFrom = this.parentPage;

      if (episode.uuid === this.trailer_legacy_formatted_object.uuid && episode.playedFrom === this.trailer_legacy_formatted_object.playedFrom) {
        const { play } = this.$store.getters;

        if (!play) {
          if (episode.episode_type !== 'trailer') {
            eventProvider.triggerEpisodePauseEvent(episode, 'trailer_select');
          }
          this.$store.dispatch('play');
          eventProvider.triggerTrailerPlayEvent(this.trailer_legacy_formatted_object, 'sdp_select');
        } else {
          this.$store.dispatch('pause');
          eventProvider.triggerTrailerPauseEvent(this.trailer_legacy_formatted_object, 'sdp_pause');
        }
      } else {
        this.$store.dispatch('setTrailerAsEpisode', false);

        if (episode.episode_type !== 'trailer') {
          eventProvider.triggerEpisodePauseEvent(episode, 'trailer_select');
        }
        this.$store.dispatch('setTrailerExperienceTriggered', false); // Make sure trailer experience does not trigger from here.
        this.loadMediaInPlayer(
          this.trailer_legacy_formatted_object.media_url,
          this.trailer_legacy_formatted_object.is_exclusive,
          this.trailer_legacy_formatted_object.episode_type,
          true,
        );
        this.$store.dispatch('setNextEpisode', {}); // Empty next episode because playback should stop after trailer complete from SDP.
        this.$store.dispatch('setAndPlayEpisode', this.trailer_legacy_formatted_object);
        eventProvider.triggerTrailerPlayEvent(this.trailer_legacy_formatted_object, 'sdp_select');
      }
    },
    setPodcastDescription() {
      if (!this.podcast.isExclusive) {
        if (this.podcast.description.length > this.podcastDescriptionTruncateLength) {
          this.showReadMoreButton = true;
          this.isDescriptionTruncated = true;
        }
      }
      this.podcastDescription = this.$sanitize(this.podcast.description);
      this.podcastDescriptionTruncated = this.$sanitize(this.$options.filters.truncate(this.podcast.description, this.podcastDescriptionTruncateLength));
    },
    togglePodcastDescription() {
      this.isDescriptionTruncated = !this.isDescriptionTruncated;
    },
    async addToMyShows() {
      try {
        await this.addPodcastToLibrary(this.podcast.id);
        eventProvider.triggerMyShowsAddEvent(this.showData);
      } catch (err) {
        log.error(err);
      }
    },
    async removeFromMyShows() {
      try {
        await this.removePodcastFromLibrary(this.podcast.id);
        eventProvider.triggerMyShowsRemoveEvent(this.showData);
      } catch (err) {
        log.error(err);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../assets/scss/variables.scss";

.hero {
  padding-top: 30px;
  padding-bottom: 30px;
  margin-bottom: 30px;
  @media(min-width: $md) {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}

.title {
  font-family: $patron-bold;
  font-size: 30px;
  line-height: 32px;
  color: $white;
  margin-top: 30px;
  margin-bottom: 10px;
  text-align: center;
  @media(min-width: $sm) {
    margin-top: 0;
    margin-bottom: 16px;
    text-align: left;
  }
  @media(min-width: $md) {
    font-size: 36px;
    line-height: 39px;
  }
  @media(min-width: $lg) {
    font-size: 40px;
    line-height: 43px;
  }
}

.publisher {
  font-family: $patron;
  color: $light-gray;
  margin-top: 0;
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 16px;
  text-align: center;
  @media(min-width: $sm) {
    font-size: 18px;
    margin-bottom: 24px;
    text-align: left;
  }
}

.publisher-link {
  color: $blue;
  &:hover {
    text-decoration: underline;
  }
}

.description-wrapper {
  margin-bottom: 30px;
}

.description {
  font-family: $patron;
  color: $white;
  margin-top: 0;
  font-size: 14px;
  line-height: 22px;
  @media(min-width: $sm) {
    font-size: 18px;
    line-height: 26px;
  }
  @media(min-width: $lg) {
    font-size: 20px;
    line-height: 28px;
  }
}

.read-more {
  padding: 0;
  border: 0;
}

.cta-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  @media(min-width: $sm) {
    justify-content: flex-start;
  }
  .trailer-cta {
    img {
      height: 18px;
      margin-right: 12px;
    }
  }
  .trailer-cta /deep/ span { // /deep/ allows styling of nested element inside scoped style tag
    display: flex;
    align-items: center;
  }
}

</style>
