<template>
  <section>
    <section v-if="isMiracleVinylPromoEnabled">
      <div class="home-page">
        <home-page-top-hero />
        <FeaturedLargeCards />
        <home-page-media-quotes />
        <div class="mobile-cta-container hide-desktop">
          <div class="header-btn fill" @click="onClickCTA">
            Start Free Trial
          </div>
          <div class="header-btn header-btn-apple fill-apple" @click="onClickAppleCTA">
            Listen on Apple
          </div>
        </div>
      </div>
    </section>
    <div v-else>
      <div class="home-page">
        <BlurredBackground />
        <home-page-top-hero />
        <FeaturedLargeCards />
        <home-page-midnight />
        <div style="padding-bottom: 5px; display: block;"/>
        <home-page-media-quotes />
        <div class="mobile-cta-container hide-desktop">
          <div class="header-btn fill" @click="onClickCTA">
            Start Free Trial
          </div>
          <div class="header-btn header-btn-apple fill-apple" @click="onClickAppleCTA">
            Listen on Apple
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters } from 'vuex';
import PageMixin from '../../shared/pageMixin';
import HomePageCTA from '../../components/webv2/homeexp/HomePageCTA';
import HomePageHero from '../../components/webv2/homeexp/HomePageHero';
import HomePageHignlighted from '../../components/webv2/homeexp/HomePageHignlighted';
import HomePageMediaQuotes from '../../components/webv2/homeexp/HomePageMediaQuotes';
import HomePageFAQ from '../../components/webv2/homeexp/HomePageFAQ';
import HomePageHolidayPromoBanner from '../../components/webv2/homeexp/HomePageHolidayPromoBanner';
import HomePageFeatureBlackstar from '../../components/webv2/homeexp/HomePageFeatureBlackstar';
import HomePageSpecialPromo from '../../components/webv2/homeexp/HomePageSpecialPromo';
import HomePageFeatureBobSaget from '../../components/webv2/homeexp/HomePageFeatureBobSaget';
import HomePageFeatureMiracleVinyl from '../../components/webv2/homeexp/HomePageFeatureMiracleVinyl';
import HomePageMiracleVinylCard from '../../components/webv2/homeexp/HomePageMiracleVinylCard';
import FeaturedLargeCards from '../../components/webv2/homeexp/FeaturedLargeCards';
import HomePageHeroSimple from '../../components/webv2/homeexp/HomePageHeroSimple';
import HomePageTopHero from '../../components/webv2/homeexp/HomePageTopHero';
import BlurredBackground from '../../components/webv2/homeexp/BlurredBackground';

export default {
  name: 'Home',
  components: {
    HomePageTopHero,
    HomePageMiracleVinylCard,
    HomePageHero,
    HomePageFeatureBobSaget,
    'home-page-cta': HomePageCTA,
    'home-page-midnight': HomePageHero,
    HomePageHignlighted,
    HomePageMediaQuotes,
    HomePageFAQ,
    HomePageHolidayPromoBanner,
    HomePageFeatureBlackstar,
    HomePageSpecialPromo,
    HomePageFeatureMiracleVinyl,
    FeaturedLargeCards,
    HomePageHeroSimple,
    BlurredBackground,
  },
  mixins: [PageMixin],
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      featureFlag: 'featureFlag',
      isSpecialPromoEnabledGlobal: 'isSpecialPromoEnabled',
    }),
    isBlackstarEnabled() {
      return this.featureFlag('blackstar_promo');
    },
    isSpecialPromoEnabled() {
      return false; // this.isSpecialPromoEnabledGlobal;
    },
    TilThisDayPromoEnabled() {
      return this.featureFlag('tilthisday_promo');
    },
    MidnightMiracleBannerEnabled() {
      return this.featureFlag('midnight_miracle_promo');
    },
    isMiracleVinylPromoEnabled() {
      const { country } = this.$store.getters;
      return this.featureFlag('miraclevinyl_promo') && country === 'US';
    },
  },
  mounted() {
    this.setMetaInformation('Luminary | Listen to Luminary Original Podcasts', 'Luminary is a subscription podcast network with an award-winning collection of original shows you won\'t find anywhere else.');
    // hack for navbar
    const navbarElem = document.getElementById('app-navbar');
    if (navbarElem && navbarElem.className) navbarElem.className = 'app-navbar-home app-navbar';
  },
  destroyed() {
    this.setMetaInformation();
  },
  methods: {
    onClickCTA() {
      this.$analytics.track('Home Page Start Click', {
        screen_name: this.$route.name,
        path: this.$route.path,
        btnId: 'home_start_click',
      });
      if (this.isInternationalUser) {
        this.$router.push('/unsupported');
        return;
      }
      window.location.href = '/signup/plan?banner=top';
    },
    onClickAppleCTA() {
      this.$analyticsV2.logEvent('cf_button_clicked', {
        is_cta: true,
        target_url: 'https://apple.co/luminary',
        button_text: 'Listen on Apple',
      });

      window.location.href = 'https://apple.co/luminary';
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/scss/variables.scss";

.home-page {
  background-color: $bg-color;
  font-family: $patron !important;
  /deep/ button {
    font-family: $patron;
  }
}

</style>

<style lang="scss">
@import "../../assets/scss/variables.scss";

.app-navbar-home {
  position: absolute!important;
  background-color: transparent!important;
}

/* Fixed Mobile CTA */
.hide-mobile { display: block; }
.hide-desktop { display: none; }

.mobile-cta-container {
  height:auto;
  width:100vw;
  bottom:0;
  position:fixed;
  background-color: rgba(18, 28, 48, 0.4) !important;
  backdrop-filter: blur(30px);
  -webkit-backdrop-filter: blur(30px);
  z-index:1000;
  border-top: 1px solid rgba(255,255,255,0.1);
}

.mobile-cta-container p {
  color: #ffffff;
  opacity: 0.5;
  text-align: center;
  font-size: 0.875rem;
  margin:0 auto;
  display:flex;
  width:fit-content;
  padding: 20px;
}

.header-btn {
  font-size:1rem;
  color: #121C30;;
  background: $brand;
  border-radius: 15px;
  padding: 16px 40px;
  width: fit-content;
  margin-top: 20px;
  font-weight: 700;
  font-family: $patron;
  position: relative;
  overflow:hidden;
  z-index: 1;
  cursor: pointer;
  transition:         0.1s ease-in;
  -o-transition:      0.1s ease-in;
  -ms-transition:     0.1s ease-in;
  -moz-transition:    0.1s ease-in;
  -webkit-transition: 0.1s ease-in;
}

.header-btn-apple {
  color: #ffffff;
  margin-left: 10px;
}

.fill-apple {
  background-color: #852dc4;

  &:hover {
    opacity: .93;
  }
}

@media (max-width: 1080px) {
  .hide-mobile {
    display: none;
  }
  .hide-desktop {
    display: block;
  }

  .header-btn {
    margin: 20px auto;
    width: initial;
    text-align: center;
    margin-bottom: 20px;
    margin-left: 20px;
    margin-right: 20px;
  }

  .mobile-cta-container {
    display: flex;
    justify-content: center;

    .header-btn {
      width: 100%;
    }
  }
}

@media (max-width: 500px) {
  .mobile-cta-container {
    flex-direction: column;
    padding-top: 20px;

    .header-btn {
        width: initial;
        margin-top: 0;
      }
  }
}
</style>
