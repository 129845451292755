import * as log from 'loglevel';
import Event from '../services/PlayerEvent';

export default {
  data() {
    return {
      bucketData: {},
      bucketItems: [],
      bucketItemsSeen: [],
      eventProvider: new Event(),
    };
  },
  methods: {
    triggerSegmentContentViewedEvent() {
      // find index of last element currently in flicity carousel viewport
      const flickity = this.$refs.flickity && this.$refs.flickity.$el;
      if (!flickity) return;
      const selectedElements = flickity.querySelectorAll('.is-selected');
      const lastElement = selectedElements[selectedElements.length - 1];
      const lastElementUUID = lastElement.getAttribute('data-segment-uuid');
      const lastElementIndex = this.bucketItems.findIndex(item => item.uuid === lastElementUUID);

      // find uuids of all items before last element
      let bucketItemsUUID = this.bucketItems.slice(0, lastElementIndex + 1);
      bucketItemsUUID = bucketItemsUUID.map(ele => ele.uuid);

      bucketItemsUUID.forEach((bucketItemUUID) => {
        if (this.bucketItemsSeen.indexOf(bucketItemUUID) < 0) {
          this.bucketItemsSeen.push(bucketItemUUID);
        }
      });

      this.eventProvider.triggerContentViewedEvent(this.bucketData, this.bucketItemsSeen);
    },
    triggerSegmentContentSelectEvent(bucketItem, position) {
      let bucketItemData = {};
      bucketItemData = Object.assign(bucketItemData, bucketItem);
      bucketItemData.item_position = position;

      this.eventProvider.triggerContentSelectEvent(this.bucketData, bucketItemData);
    },
    onVisibleInViewport() {
      this.triggerSegmentContentViewedEvent();
    },
    sliderChange(totalItemsReference, displayedItemsReference) {
      // Load all next items when user interacts with slider
      this.$nextTick()
        .then(() => {
          this.$refs.flickity.on('change', () => {
            if (this[displayedItemsReference].length < this[totalItemsReference].length) {
              this[displayedItemsReference] = this[totalItemsReference];
              setTimeout(() => {
                this.$refs.flickity.next();
              });
            }
          });
        })
        .catch((err) => { log.warn(err.message); });
    },
  },
};
