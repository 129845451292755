<template>
  <div class="info">
    <loader
      v-show="!data_loaded"
      :center="true"/>
    <div
      v-show="data_loaded"
      class="account-information"
    >
      <div
        v-if="!is_updating_password"
        class="form-container"
      >
        <el-form
          ref="emailForm"
          :model="userData"
          :rules="emailValidationRules">
          <el-form-item
            prop="email"
            label="Email Address">
            <el-input
              v-model="userData.email"
              :readonly="true"
              label="Email address"
              placeholder="Email Address"
              data-test="email-field">
              <email-verification slot="suffix" :code="code" :email="userData.email"/>
            </el-input>
          </el-form-item>
        </el-form>
        <el-row
          v-if="isPlatformEmail"
          type="flex"
          align="middle"
          justify="space-between"
          class="update-password-section"
          data-test="updatepassword"
          @click.native="updatePassword">
          <div class="title">Update Password</div>
          <div
            class="arrow"><i class="el-icon-arrow-right"/></div>
        </el-row>
      </div>
      <div
        v-if="shouldShowUpdatePayment && !isAlexaSubscriber && userSubscription.platform === 'web'"
        class="payment-method"
        data-test="payment-methods-section">
        <h5
          class="header"
        >
          Payment method
        </h5>
        <el-row
          v-loading="!paymentDataLoaded"
          type="flex"
          justify="space-between"
          align="middle"
          class="card-item"
        >
          <el-col
            class="d-flex flex-middle-align">
            <span>
              <img
                v-if="transaction.card_type === 'visa'"
                src="../../assets/images/visa.png"
                class="card-type">
              <img
                v-if="transaction.card_type === 'mc'"
                src="../../assets/images/mastercard.png"
                class="card-type">
              <img
                v-if="transaction.card_type === 'amex'"
                src="../../assets/images/amex.png"
                class="card-type">
              <img
                v-if="transaction.card_type === 'discover'"
                src="../../assets/images/discover_card.png"
                class="card-type">
            </span>
            <span>
              <span class="muted">ending in </span>{{ transaction.last_four_numbers }}
            </span>
          </el-col>
          <el-col class="t-r cursor-pointer">
            <a
              data-test="btn-update"
              class="btn-link"
              @click="OpenUpdatePaymentModal">Update</a>
          </el-col>
        </el-row>
        <el-alert
          v-if="updateSuccess"
          :title="successMessage"
          type="success"
          show-icon />
        <el-alert
          v-if="showError"
          title="success alert"
          type="error"
          show-icon />
      </div>
    </div>
    <adyen-modal
      :show_modal="showAdyenUpgradeModel"
      :show_text="showTextOnUpdatePaymentModal"
      @close="closeUpgradeModel"
    />
  </div>
</template>

<script>
import User from '../../services/User';
import Subscription from '../../services/Subscription';
import PageMixin from '../../shared/pageMixin';
import EmailVerification from '@/components/EmailVerification';

export default {
  components: {
    Loader: () => import('../../components/Loader'),
    'adyen-modal': () => import('../../components/subscription/UpgradeModal'),
    EmailVerification,
  },
  mixins: [PageMixin],
  props: {
    code: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      pageName: 'Account Info',
      user_service: new User(),
      data_loaded: false,
      saving_data: false,
      userData: {},
      is_updating_password: false,
      userInfoValidationRules: {
        first_name: [
          { required: true, message: 'First name Required', trigger: 'blur' },
        ],
        last_name: [
          { required: true, message: 'Last name Required', trigger: 'blur' },
        ],
      },
      emailValidationRules: {
        email: [
          { required: true, message: 'Email Required', trigger: 'blur' },
          { type: 'email', message: 'Invalid Email Format', trigger: 'blur' },
        ],
      },
      subscriptionProvider: new Subscription(),
      transaction: {},
      showAdyenUpgradeModel: false,
      updateSuccess: false,
      successMessage: 'Your payment information has been updated',
      showError: false,
      paymentDataLoaded: true,
      showTextOnUpdatePaymentModal: false,
    };
  },
  computed: {
    shouldShowUpdatePayment() {
      return this.isSubscribed && !this.isCanceled;
    },
    isSubscribed() {
      return this.$store.getters.user && this.$store.getters.user.is_subscribed;
    },
    isCanceled() {
      return this.$store.getters.userSubscription && this.$store.getters.userSubscription.is_cancelled;
    },
    userSubscription() {
      return this.$store.getters.userSubscription || {};
    },
    isAlexaSubscriber() {
      return this.$store.getters.userSubscription && this.$store.getters.userSubscription.platform === 'alexa';
    },
    isPlatformEmail() {
      return localStorage.getItem('login_type') === 'Email';
    },
    showUpdatePaymentModal() {
      return this.$store.getters.showUpdatePaymentModal;
    },
  },
  watch: {
    '$store.getters.user': function () {
      this.userData = this.$store.getters.user || {};
    },
    userSubscription(newVal) {
      if (newVal && newVal.uuid) {
        this.fetchTransactionHistory();
      }
    },
    showUpdatePaymentModal(newVal) {
      if (newVal) {
        this.showTextOnUpdatePaymentModal = true;
        this.OpenUpdatePaymentModal();
      } else {
        this.showTextOnUpdatePaymentModal = false;
        this.closeUpgradeModel();
      }
    },
  },
  mounted() {
    window.eventBus.$emit('settings-tab-change', 'info');
    this.data_loaded = true;
    this.userData = this.$store.getters.user || {};

    // fetch transaction history if user subscriptions found
    const { userSubscription } = this.$store.getters;
    if (userSubscription) {
      this.fetchTransactionHistory();
    }
    if ('paymethod' in this.$route.query) {
      this.OpenUpdatePaymentModal();
    }
  },
  methods: {
    updatePassword() {
      // this.is_updating_password = true
      this.$router.push('/account/updatepassword');
    },
    fetchTransactionHistory() {
      this.paymentDataLoaded = false;
      this.subscriptionProvider.fetchTransactionHistory({ txn_type: 'card_added', limit: 1 }).then((resp) => {
        this.loading = false;
        this.paymentDataLoaded = true;
        if (resp && resp.length) {
          [this.transaction] = resp;
        }
      })
        .catch(() => {
          this.paymentDataLoaded = true;
        });
    },
    closeUpgradeModel(success) {
      this.showAdyenUpgradeModel = false;
      if (success) {
        this.updateSuccess = true;
      }
      this.$store.dispatch('hideUpdatePaymentModal');
    },
    OpenUpdatePaymentModal() {
      this.showAdyenUpgradeModel = true;
    },
  },
};
</script>

<style lang="scss">
@import "../../assets/scss/variables.scss";
.info {
  display: flex;
  justify-content: center;

  .account-information {
    width: 50%;

    @media (max-width: 1024px) {
      width: 60%;
    }

    @media (max-width: 768px) {
      width: 80%;
    }

    @media (max-width: 567px) {
      width: 100%;
    }
  }

  .form-container {
    width: 90%;
    margin: 0 auto;
  }

  .arrow {
    font-size: 24px;
    cursor: pointer;
  }
  .el-form {
    margin-top: 48px;
    margin-bottom: 30px;
    .el-form-item {
      margin-bottom: 16px;
      .el-form-item__label {
        color: $black;
        font-size: 16px;
        line-height: 28px;
      }
      .el-form-item__label:before{
        content: none !important;
      }
      .el-input {
        .el-input__inner {
          height: 48px;
          border-radius: 8px;
          background-color: $xxlight-gray;
        }
      }
    }
  }
  .update-password-section {
    padding: 19px 0;
    border-top: 1px solid $light-gray;
    border-bottom: 1px solid $light-gray;
    cursor: pointer;
    .title {
      height: 20px;
      width: 128px;
      color: $black;
      font-family: $clear-sans;
      font-size: 16px;
      font-weight: normal;
      line-height: 20px;
      margin: 0px;
    }
  }
  .payment-method{
    width: 90%;
    margin: auto;
    margin-top: 65px;
    margin-bottom: 20px;
    .header{
      font-size: 24px;
      font-family: $clear-sans;
      line-height: 28px;

      @media (max-width: 567px) {
        margin: 0 25px 10px;
      }
    }
    @media (max-width: 567px) {
      width: 100%;
    }
  }
  img.card-type{
    width: 36px;
    height: 24px;
    margin-right: 12px;
    vertical-align: middle;
  }
  .card-item{
    padding: 14px 16px 16px 16px;
    background-color: #F8F8F8;
    margin-bottom: 20px;
  }
  .muted{
    color: #c3c3c3;
  }
}

@media (max-width: 567px) {
  .form-container {
    margin: 0 25px;
  }
}
</style>
