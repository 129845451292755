<template>
  <div
    v-if="isEnabled"
    :class="size"
    class="premium-indicator"
    data-test="premium-indicator">
    <img
      src="../assets/images/premium_indicator.svg"
      alt="Premium">
  </div>
</template>

<script>

export default {
  props: {
    size: {
      type: String,
      default: 'small',
    },
  },
  computed: {
    isEnabled() {
      return this.$store.getters.featureFlag('is_spotlight_enabled');
    },
  },
};

</script>

<style lang="scss" scoped>
@import '../assets/scss/variables.scss';

  .premium-indicator {
    font-size: 10px;
    max-width: 36px;
    padding: 0px;
    background-color: $brand;
    color: $black;
    display: flex;
    align-items: center;
    margin: auto;
    border-radius: 50%;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: -8px;
    z-index: 998;
    box-sizing: border-box;
    img {
      width: 100%;
      object-fit: fill;
      margin: 0px;
      background: transparent !important
    }
    &.x-small {
      width: 16px;
      padding: 0px;
    }
    &.small {
      width: 24px;
      padding: 0px;
    }
    &.medium {
      width: 30px;
      bottom: -16px;
    }
    &.large {
      width: 32px;
      bottom: -16px;
    }
    &.x-large {
      width: 36px;
      bottom: -20px;
      padding: 0px;
      // @media (max-width: $md) {
      //   width: 32px;
      // }
      // @media (max-width: $sm) {
      //   width: 24px;
      // }
      @media (max-width: $xs) {
        width: 30px;
        bottom: -15px;
      }
    }
  }
</style>
