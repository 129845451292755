<template>
  <div
    v-if="showEyebrow"
    class="eyebrow-container">
    <div
      v-if="!isMobile"
      class="eyebrow">
      <p>✨Special Offer ✨ Subscribe for {{ promoDiscount.asStringRecurring }} for a year</p>
      <span
        class="cta-link"
        @click="clickHandler">
        Get Started
      </span>
    </div>
    <div
      v-if="isMobile"
      class="eyebrow"
      @click="clickHandler">
      <p><span class="mobile-white">✨Special Offer ✨</span><span class="mobile-yellow"> Subscribe for {{ promoDiscount.asStringRecurring }} for a year</span></p>
    </div>
  </div>
</template>
<script>

export default {
  name: 'Eyebrow',
  computed: {
    plansLoaded() {
      return this.$store.getters.plansLoaded;
    },
    routeValue() {
      const { isAnonUser } = this.promoMeta;
      let routeTo = { path: '/premium' };
      if (isAnonUser) {
        routeTo = { path: '/user-categories', query: { showPaymentForm: true } };
      } else {
        routeTo = { path: '/premium-buy' };
      }
      return routeTo;
    },
    showHolidayPromo() {
      return this.$store.getters.promoData.showHolidayPromo;
    },
    showEyebrow() {
      const { alexa } = this.$route.query;
      const isAlexaFlow = alexa === 'true';
      return this.plansLoaded && this.showHolidayPromo && this.allowedRoute && !isAlexaFlow;
    },
    allowedRoute() {
      const { path } = this.$router.currentRoute;
      // move disallowed routes to
      const disallowedRoutes = ['/international', '/terms', '/privacy', '/ios-terms'];

      if (disallowedRoutes.find(route => route === path)) {
        return false;
      }
      return true;
    },
    isMobile() {
      return (window && window.innerWidth) <= this.screenSize.sm;
    },
  },
  methods: {
    clickHandler() {
      this.handleCTARouting('eyebrowCta');
      // const { analytic_events } = this.$store.getters.globalConfig;
      // if (analytic_events['eyebrowCta']) {
      //   this.$analytics.track(analytic_events['eyebrowCta']);
      // }
    },
  },
};
</script>
<style lang="scss" scoped>
@import './Eyebrow.scss'
</style>
