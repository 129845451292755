const getters = {
  presearch: state => state.presearch,
};

const mutations = {
  setPresearch: (state, presearch) => { state.presearch = presearch; },
};

const state = {
  presearch: null,
};

const actions = {
  setPresearch: (context, presearch) => {
    context.commit('setPresearch', presearch);
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
