<template>
  <div
    class="payment-info-adyen"
    data-test="page-payment-info-adyen">
    <h2 class="m-b t-c font-normal heading">
      {{ heading }}
    </h2>
    <div v-if="showText">
      <div v-if="annualPlanEnabled && plansLoaded">
        <div :class="{'plan-description': !acquisitionFlow, 'acquisition-plan-description': acquisitionFlow}">
          <p
            class="m-b"
            v-html="billingInstruction"/>
          <p v-html="billingDescription"/>
        </div>
      </div>
      <div v-else>
        <div
          v-if="!isSubscribed || isCanceled"
          class="sub-head t-c">
          <div v-if="!isSubscribed && !isCanceled">Enter your billing information to start your free trial</div>
          <br>
          <div v-if="showPromoOnBilling">For a limited time, <span>Luminary Premium is only {{ promoDiscount.asStringRecurring }} </span>{{ defaultPrice.price | taxFilter(defaultPrice.currency) }} for your first 12 months when you sign up by {{ promoEndDate }}. Cancel anytime.</div>
          <div v-else>Premium is {{ defaultPrice.price | amountFilter(defaultPrice.currency) }} {{ defaultPrice.price | taxFilter(defaultPrice.currency) }}. Cancel Anytime.</div>
        </div>
      </div>
    </div>
    <el-form
      ref="paymentForm"
      :model="paymentForm"
      :rules="isBillingAddressInAdyen ? dropinValidationRules : paymentValidationRules"
      :class="{'form-info-acquisition': acquisitionFlow}"
      div
      label-position="top"
      @keyup.enter.native="onSubmit"
    >
      <el-row v-show="isNameFieldsVisible" :gutter="12">
        <el-col :span="12">
          <el-form-item
            prop="firstName"
            label="First Name"
            data-form-prop="firstName"
          >
            <el-input
              v-model="paymentForm.firstName"
              aria-label="First name"
              placeholder="First name"
              data-test="input-firstname"
            />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item
            prop="lastName"
            label="Last Name"
            data-form-prop="lastName"
          >
            <el-input
              v-model="paymentForm.lastName"
              aria-label="Last name"
              placeholder="Last name"
              data-test="input-lastname"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <div v-if="!giftingFlow && !isBillingAddressInAdyen" class="payment-info-adyen__divider">Billing Address</div>
      <el-row :gutter="12">
        <el-col>
          <el-form-item
            prop="country"
            label="Country"
            data-form-prop="country"
          >
            <el-select
              ref="country"
              :popper-append-to-body="false"
              v-model="paymentForm.country"
              placeholder="Select country"
              no-match-text="Not found"
              filterable
              data-test="select-country-for-adyen"
              style="width: 100%;"
              @keyup.native.enter="preventSubmit"
              @click="preventSubmit"
            >
              <el-option
                v-for="(country) in countries"
                :key="country.code"
                :label="country.name"
                :value="country.code"
                data-test="option-country-for-adyen" />
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row v-if="showBasicAddress" :gutter="12">
        <el-col :span="12">
          <el-form-item
            :label="stateLabel"
            prop="state"
            label-position="top"
            data-form-prop="state"
          >
            <el-select
              :popper-append-to-body="false"
              v-model="paymentForm.state"
              :placeholder="statePlaceholder"
              no-match-text="Not found"
              filterable
              data-input="select-state"
              style="width: 100%;"
              @change="onFormItemChange"
              @keyup.native.enter="preventSubmit">
              <el-option
                v-for="(state) in states"
                :key="state.code"
                :label="state.name"
                :value="state.code"
                data-test="option-state"/>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item
            :label="zipCodeLabel"
            prop="postalCode"
            label-position="top"
            data-form-prop="postalCode"
          >
            <el-input
              v-model="paymentForm.postalCode"
              :placeholder="postalCodePlaceholder"
              aria-label="Zip code"
              data-test="input-zipcode"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row v-if="!isBillingAddressInAdyen" :gutter="12">
        <el-col>
          <el-form-item
            prop="address"
            label="Address"
            data-form-prop="address"
          >
            <el-input
              v-model="paymentForm.address"
              aria-label="Address"
              placeholder=""
              data-test="input-address"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row v-if="!isBillingAddressInAdyen" :gutter="12">
        <el-col>
          <el-form-item
            prop="apartment"
            label="Apartment / Suite (optional)"
            data-form-prop="apartment"
          >
            <el-input
              v-model="paymentForm.houseNumberOrName"
              aria-label="Apartment / Suite (optional)"
              placeholder=""
              data-test="input-houseNumberOrName"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row v-if="!isBillingAddressInAdyen" :gutter="12">
        <el-col>
          <el-form-item
            prop="city"
            label="City"
            data-form-prop="city"
          >
            <el-input
              v-model="paymentForm.city"
              aria-label="City"
              placeholder=""
              data-test="input-city"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row v-if="!isBillingAddressInAdyen" :gutter="12">
        <el-col
          v-show="showState"
          :span="12">
          <el-form-item
            :label="stateLabel"
            prop="state"
            label-position="top"
            data-form-prop="state"
          >
            <el-select
              :popper-append-to-body="false"
              v-model="paymentForm.state"
              :placeholder="statePlaceholder"
              no-match-text="Not found"
              filterable
              data-input="select-state"
              style="width: 100%;"
              @keyup.native.enter="preventSubmit">
              <el-option
                v-for="(state, index) in states"
                :key="index"
                :label="state.name"
                :value="state.code"
                data-test="option-state"/>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col
          :span="12"
        >
          <el-form-item
            :label="zipCodeLabel"
            prop="postalCode"
            label-position="top"
            data-form-prop="postalCode"
          >
            <el-input
              v-model="paymentForm.postalCode"
              :placeholder="postalCodePlaceholder"
              aria-label="Zip code"
              data-test="input-zipcode"
            />
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <div
      v-loading="isFormLoading"
      ref="adyenForm"
      class="payment-info-adyen__form"
    />
    <el-alert
      v-if="zuoraErrorMessage"
      :title="zuoraErrorMessage"
      type="error"
      class="m-b"
      show-icon
    />
    <div v-if="showText">
      <div v-if="!annualPlanEnabled && showPromoOnBilling">
        <p
          v-if="isNewSubscription"
          class="legal-terms">
          <span v-if="!isSubscribed && !isCanceled">By clicking "Subscribe" you are signing up for a free <span
            v-if="trailMonths > 1"
            class="line-through">1 month</span> {{ trailMonths }} {{ trailMonths > 1 ? 'months' : 'month' }} trial. Your trial will begin immediately. If you don’t cancel before the end of the trial period, you authorize Luminary to automatically charge your payment method {{ promoDiscount.asStringRecurring }} {{ defaultPrice.price | taxFilter(defaultPrice.currency) }} on a continuous monthly basis for the first 12 months of your subscription. After your first 12 months your monthly price will increase to {{ defaultPrice.price | amountFilter(defaultPrice.currency) }} {{ defaultPrice.price | taxFilter(defaultPrice.currency) }}  for as long as you remain a subscriber. The subscription automatically renews unless it is cancelled at least 24 hours before the end of the current period. Your account will be charged for renewal within 24 hours prior to the end of the current period.</span>
          You can cancel anytime by visiting your Account Settings. No partial refunds.
          <router-link
            v-button-event
            :to="termsLink"
            target="_blank"
            color="primary"
            data-test="link-additionalterms"
            class="font-normal t-u"
            aria-label="Additional terms apply"
          >
            Additional terms apply.
          </router-link>
        </p>
        <p
          v-if="isResubscribingAfterTrial"
          class="legal-terms"
        >
          By clicking on “subscribe” you are purchasing a monthly Luminary Premium subscription. Your subscription will begin and you will be charged immediately. You authorize Luminary to automatically charge your payment method {{ promoDiscount.asStringRecurring }} {{ defaultPrice.price | taxFilter(defaultPrice.currency) }} on a continuous monthly basis for the first 12 months of your subscription. After your first 12 months your monthly price will increase to {{ defaultPrice.price | amountFilter(defaultPrice.currency) }} {{ defaultPrice.price | taxFilter(defaultPrice.currency) }}  for as long as you remain a subscriber. The subscription automatically renews unless it is cancelled at least 24 hours before the end of the current period. Your account will be charged for renewal within 24 hours prior to the end of the current period. You can cancel anytime by visiting your account settings. No partial refunds.
          <router-link
            v-button-event
            :to="termsLink"
            target="_blank"
            color="primary"
            data-test="link-additionalterms"
            class="font-normal t-u"
            aria-label="Additional terms apply"
          >
            Additional terms apply.
          </router-link>
        </p>
        <p
          v-if="isResubscribingInTrial"
          class="legal-terms"
        >
          By clicking on “subscribe” you are purchasing a monthly Luminary Premium subscription. Your subscription will begin and you will be charged when your free trial ends. You authorize Luminary to automatically charge your payment method {{ promoDiscount.asStringRecurring }} {{ defaultPrice.price | taxFilter(defaultPrice.currency) }} on a continuous monthly basis for the first 12 months of your subscription. After your first 12 months your monthly price will increase to {{ defaultPrice.price | amountFilter(defaultPrice.currency) }} {{ defaultPrice.price | taxFilter(defaultPrice.currency) }}  for as long as you remain a subscriber. The subscription automatically renews unless it is cancelled at least 24 hours before the end of the current period. Your account will be charged for renewal within 24 hours prior to the end of the current period. You can cancel anytime by visiting your account settings. No partial refunds.
          <router-link
            v-button-event
            :to="termsLink"
            target="_blank"
            color="primary"
            data-test="link-additionalterms"
            class="font-normal t-u"
            aria-label="Additional terms apply"
          >
            Additional terms apply.
          </router-link>
        </p>
      </div>
      <div v-if="!annualPlanEnabled && !showPromoOnBilling">
        <p
          v-if="isNewSubscription"
          class="legal-terms">
          <span v-if="!isSubscribed && !isCanceled">By clicking "Subscribe" you are signing up for a free <span
            v-if="trailMonths > 1"
            class="line-through">1 month</span> {{ trailMonths }} {{ trailMonths > 1 ? 'months' : 'month' }} trial. Your trial will begin immediately. If you don’t cancel before the end of the trial period, you authorize Luminary to automatically charge your payment method {{ defaultPrice.price | amountFilter(defaultPrice.currency) }} {{ defaultPrice.price | taxFilter(defaultPrice.currency) }} on a continuous monthly basis for as long as you remain a subscriber. The subscription automatically renews unless it is cancelled at least 24 hours before the end of the current period. Your account will be charged for renewal within 24 hours prior to the end of the current period.</span>
          You can cancel anytime by visiting your Account Settings. No partial refunds.
          <router-link
            :to="termsLink"
            target="_blank"
            color="primary"
            data-test="link-additionalterms"
            class="font-normal t-u"
            aria-label="Additional terms apply"
          >
            Additional terms apply.
          </router-link>
        </p>
        <p
          v-if="isResubscribingAfterTrial"
          class="legal-terms"
        >
          By clicking on “subscribe” you are purchasing a monthly Luminary Premium subscription. Your subscription will begin and you will be charged immediately. You authorize Luminary to automatically charge your payment method {{ defaultPrice.price | amountFilter(defaultPrice.currency) }} {{ defaultPrice.price | taxFilter(defaultPrice.currency) }} on a continuous monthly basis for as long as you remain a subscriber. The subscription automatically renews unless it is cancelled at least 24 hours before the end of the current period. Your account will be charged for renewal within 24 hours prior to the end of the current period. You can cancel anytime by visiting your account settings. No partial refunds.
          <router-link
            v-button-event
            :to="termsLink"
            target="_blank"
            color="primary"
            data-test="link-additionalterms"
            class="font-normal t-u"
            aria-label="Additional terms apply"
          >
            Additional terms apply.
          </router-link>
        </p>
        <p
          v-if="isResubscribingInTrial"
          class="legal-terms"
        >
          By clicking on “subscribe” you are purchasing a monthly Luminary Premium subscription. Your subscription will begin and you will be charged when your free trial ends. You authorize Luminary to automatically charge your payment method {{ defaultPrice.price | amountFilter(defaultPrice.currency) }} {{ defaultPrice.price | taxFilter(defaultPrice.currency) }} on a continuous monthly basis for as long as you remain a subscriber. The subscription automatically renews unless it is cancelled at least 24 hours before the end of the current period. Your account will be charged for renewal within 24 hours prior to the end of the current period. You can cancel anytime by visiting your account settings. No partial refunds.
          <router-link
            v-button-event
            :to="termsLink"
            target="_blank"
            color="primary"
            data-test="link-additionalterms"
            class="font-normal t-u"
            aria-label="Additional terms apply"
          >
            Additional terms apply.
          </router-link>
        </p>
      </div>
      <div
        v-if="annualPlanEnabled"
        :class="{'legal-copy': !acquisitionFlow, 'acquisition-legal-copy': acquisitionFlow}">

        <p v-html="legalCopy"/>
        <div>
          <router-link
            v-button-event
            :to="termsLink"
            target="_blank"
            color="primary"
            data-test="link-additionalterms"
            class="font-normal t-u"
            aria-label="Additional terms apply"
          >
            Terms apply.
          </router-link>
        </div>
      </div>
    </div>
    <div class="action-controls">
      <el-button
        v-button-event
        v-loading="loading"
        v-show="!isPaypalSelected"
        :class="[{ 'action-button': true }, { 'annual-plan-btn': annualPlanEnabled }]"
        :disabled="loading"
        class="block-btn"
        type="primary"
        data-test="btn-subscribe"
        @click="onSubmit"
      >
        {{ buttonText }}
      </el-button>
    </div>
    <vermont-modal
      :show="showVermontModal"
      @onAcceptVermontTerms="onAcceptVermontTerms"
    />
  </div>
</template>
<script>
import { mapActions, mapMutations } from 'vuex';
import '@adyen/adyen-web/dist/adyen.css';
import SubscriptionMixin from '../../shared/subscriptionMixin';
import adyendropinMixin from '../../shared/adyendropinMixin';
import VermontModal from '../VermontGoHardModal';

export default {
  name: 'PaymentInfoAdyen',
  components: {
    'vermont-modal': VermontModal,
  },
  mixins: [SubscriptionMixin, adyendropinMixin],
  props: {
    show_modal: {
      default: false,
      type: Boolean,
    },
    button_text: {
      default: null,
      type: String,
    },
    update: {
      default: false,
      type: Boolean,
    },
    heading: {
      default: '',
      type: String,
    },
    acquisitionFlow: {
      default: false,
      type: Boolean,
    },
    giftingFlow: {
      default: false,
      type: Boolean,
    },
    showText: {
      default: true,
      type: Boolean,
    },
    defaultFirstName: {
      default: '',
      type: String,
    },
    defaultLastName: {
      default: '',
      type: String,
    },
  },
  methods: {
    ...mapMutations([
      'setUserCountry',
    ]),
    ...mapActions([
      'invalidatePricingPlans',
      'loadPricingPlans',
    ]),
    gotoPlanSelectionStep() {
      const { code, currency } = this.countries.find(c => c.code === this.paymentForm.country);
      this.setUserCountry(this.paymentForm.country);
      this.invalidatePricingPlans().then(() => this.loadPricingPlans({ country: code, currency })).then(() => {
        this.$router.push('/plans');
      });
    },
  },
};
</script>
<style lang="scss">
@import "../../assets/scss/variables.scss";
$form-items-background-color: #ffffff;
.payment-info-adyen {
  max-width: 480px;
  margin-left: auto;
  margin-right: auto;
  color: #333333;
  .plan-description {
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    margin-bottom: 38px;
    color: #333333;
    .strike-through {
      text-decoration: line-through;
      opacity: .6;
      margin-right: 4px;
    }
    p {
      margin: 0;
      &:first-child {
        margin-bottom: 16px;
      }
    }
  }
  .acquisition-plan-description {
    font-size: 14px;
    line-height: 20px;
    text-align: left;
    margin-bottom: 38px;
    .strike-through {
      text-decoration: line-through;
      opacity: .6;
      margin-right: 4px;
    }
    p {
      margin: 0;
      color: $white !important;
      &:first-child {
        margin-bottom: 16px;
      }
    }
  }

  &__divider {
    color: #cccccc;
  }

  .heading {
    font-size: 36px;
    letter-spacing: -1;
    line-height: 49px;
    font-family: $clear-sans;
    margin-top: 0px !important;
    font-weight: bold;
    font-style: normal;
    color: #333333;
  }
  .sub-head {
    margin-bottom: 24px;
    @media(max-width: 567px) {
      text-align: left;
    }
    div:first-child{
      font-size: 14px;
      color: #111111;
    }
    div:last-child{
      font-size: 14px;
      color: #666666;
    }
  }
  .action-button {
    margin-top: 24px;
  }
  .action-controls {
    text-align: center;
    display: inline-block;
    padding-bottom: 50px;
    width: 100%;
  }
  .annual-plan-btn {
    width: 353px;
    max-width: 95%;
  }
  .legal-terms {
    color: #666 !important;
    font-size: 12px;
    line-height: 18px;

    a {
      color: #111111;
    }
  }
  .legal-copy {
    max-width: 480px !important;
    width: 100%;
    margin: auto;
    background: #ffffff;
    border-radius: 5px;
    padding: 23px 0px 28px;
    margin-top: 31px;
    margin-bottom: 8px;
    span, a, p {
      font-size: 12px;
      line-height: 18px;
      font-family: $clear-sans;
    }
    b, p b {
      color: #333333;
      font-family: $clear-sans;
      font-size: 12px;
      line-height: 18px;
    }
  }
  .acquisition-legal-copy {
    max-width: 480px !important;
    width: 100%;
    background: $bg-color;
    border-radius: 5px;
    padding: 23px 0px 28px;
    margin-top: 31px;
    margin-bottom: 8px;
    span, a, p {
      font-size: 12px;
      line-height: 18px;
      font-family: $clear-sans;
      color: $white;
    }
    b, p b {
      color: $white;
      font-family: $clear-sans;
      font-size: 12px;
      line-height: 18px;
    }
  }
  &__form {
    margin-top: 10px;
    min-height: 60px;
  }
  .form-info-acquisition {
    .el-form-item {
      .el-form-item__label {
        color: white !important;
      }
    }
  }
  .el-form{
    max-width: 480px !important;
    width: 100% !important;
    margin-top: 30px;
    .el-form-item {
      margin-bottom: 16px;
      .el-form-item__label {
        color: #333333;
        font-size: 14px;
        font-weight: normal;
        line-height: 20px;
        padding: 0 0 8px 0;
      }
      .el-form-item__label:before{
        content: none !important;
      }
      .el-input {
        .el-input__inner {
          height: 48px;
          border-radius: 8px;
          background-color: $form-items-background-color;
        }
      }
    }
  }
}
</style>
