import * as log from 'loglevel';
import uuidv1 from 'uuid/v1';
import BranchService from '@/services/Branch';
import { getServices } from '@/services/serviceProvider';
import visa from '../assets/images/visa.png';
import mastercard from '../assets/images/mastercard.png';
import amex from '../assets/images/amex.png';
import discover from '../assets/images/discover_card.png';
import { formatSubscriptionPlan } from '@/shared/formatters';
import OptimizeService from '@/services/Optimize';
import amountFilter from './amountFilter';

const [
  branchService,
  optimizeService,
] = getServices([BranchService, OptimizeService]);

export default {
  data() {
    return {
      activePlanId: '2c92c0f96832337a0168389651e40bf4',
      activePlan: null,
      activePlanCharges: null,
      cardBrand: null,
      // pricing: null,
      loading: false,
      zuoraErrorMessage: '',
      visa,
      mastercard,
      amex,
      discover,
      adyenForm: {
        loading: false,
        loadEndTime: 0,
        loadStartTime: 0,
        loadingThreshold: 10000, // wait for 10 seconds for form load before throwing error
        loadWatcher: null,
      },
    };
  },
  computed: {
    plansLoaded() {
      if (this.$store.getters.plansLoaded) {
        // this.setPricingPlans();
      }
      return this.$store.getters.plansLoaded;
    },
    cardType() {
      if (this.cardBrand === 'visa') {
        return 'visa';
      }
      if (this.cardBrand === 'mc') {
        return 'mc';
      }
      if (this.cardBrand === 'amex') {
        return 'amex';
      }
      if (this.cardBrand === 'discover') {
        return 'discover';
      }
      return null;
    },
    cardIcon() {
      if (this.cardBrand === 'visa') {
        return this.visa;
      }
      if (this.cardBrand === 'mc') {
        return mastercard;
      }
      if (this.cardBrand === 'amex') {
        return amex;
      }
      if (this.cardBrand === 'discover') {
        return discover;
      }
      return null;
    },
    isAnnualGift() {
      const { userSubscription } = this.$store.getters;
      return !!(userSubscription && userSubscription.plan && userSubscription.plan.type === 'gift_annual');
    },
    isNextChargeAnnualGift() {
      const { userSubscription } = this.$store.getters;
      return !!(userSubscription && userSubscription.next_charge && userSubscription.next_charge.type === 'gift_annual');
    },
    currentPlan() {
      const { userSubscription } = this.$store.getters;
      const { displayName } = formatSubscriptionPlan(userSubscription.plan, userSubscription);
      return displayName;
    },
    nextChargePlan() {
      const { userSubscription } = this.$store.getters;
      const { displayName } = formatSubscriptionPlan(userSubscription.next_charge, userSubscription);
      return displayName;
    },
    isNextChargePresent() {
      const { userSubscription } = this.$store.getters;
      return !!(userSubscription && userSubscription.next_charge);
    },
    renewalDate() {
      const { userSubscription } = this.$store.getters;
      if (!userSubscription) return '';
      if (userSubscription.status === 'paused') return userSubscription.paused_until ? userSubscription.paused_until : null;
      return userSubscription.next_charge && userSubscription.next_charge.renewal_date ? userSubscription.next_charge.renewal_date : null;
    },
    billingDescription() {
      const { selectedPlan } = this.$store.getters;
      if (!selectedPlan) return '';
      const {
        displayName,
        displayPrice,
        trialPeriodDays,
        displayPaymentPeriod,
      } = selectedPlan;
      if (!trialPeriodDays) {
        return `After you successfully submit your payment, you will be charged a payment of ${displayPrice} for ${displayPaymentPeriod} of access.`;
      }
      const { showTrial } = this.promoMeta;
      const annualTrialText = `After your free trial, your ${displayName} Plan will begin and you will automatically be charged <b>${displayPrice}</b> for your first year.`;
      const annualNoTrialText = `You will be charged <b>${displayPrice}</b> for your first year.`;
      const monthlyTrialText = `After your free trial, your ${displayName} Plan will automatically begin and you will automatically be charged <b>${displayPrice}/month</b>.  Cancel anytime.`;
      const monthlyNoTrialText = `You will automatically be charged <b>${displayPrice}/month</b>.  Cancel anytime.`;
      if (showTrial) {
        return displayName === 'Monthly' ? monthlyTrialText : annualTrialText;
      }
      return displayName === 'Monthly' ? monthlyNoTrialText : annualNoTrialText;
    },
    billingInstruction() {
      const { selectedPlan } = this.$store.getters;
      if (!selectedPlan) return '';
      const {
        showTrial, promoCode, trialPeriod, trialEndDate,
      } = this.promoMeta;
      let withTrial = '<span>Enter your billing information to start your 7-day free trial.<span>';
      if (promoCode && promoCode !== '' && trialEndDate !== '') {
        withTrial = `<span>Enter your billing information to start your </span><span class="strike-through">7-day</span><span>${trialPeriod}-month free trial.</span><span>`;
      }
      const noTrial = '<span>Enter your billing information to start your Luminary subscription.</span>';
      return showTrial && selectedPlan.trialPeriodDays ? withTrial : noTrial;
    },
    legalCopy() {
      const { selectedPlan, promoCode: altPromoCode } = this.$store.getters;
      if (!selectedPlan) return '';
      const {
        displayName, displayPrice, price, currency, trialPeriodDays, displayPaymentPeriod,
      } = selectedPlan;
      const {
        showTrial, promoCode, trialPeriod, trialEndDate,
      } = this.promoMeta;
      let finalDisplayPrice = displayPrice;
      if (!trialPeriodDays) {
        return `<span>By clicking "Agree & Subscribe", you are signing up for a Luminary subscription. <b>Your access will begin immediately. You authorize Luminary to automatically charge your payment method ${displayPrice} plus applicable taxes for the first ${displayPaymentPeriod} of your subscription. After your first ${displayPaymentPeriod}, your subscription automatically renews at the then-current rate, charged to your payment method, unless you cancel at least 24 hours before the end of the current period.</b> You can cancel anytime by visiting your Account Settings. No partial refunds.</span>`;
      }
      let trialText = '7-day';
      if (promoCode && promoCode !== '' && trialEndDate !== '') {
        trialText = `${trialPeriod}-month`;
      }

      try {
        if (altPromoCode && altPromoCode.properties.code === 'fallonliberation') {
          const { discount } = altPromoCode.properties;
          const discountedPrice = parseFloat(price * (1 - (discount / 100)));
          finalDisplayPrice = amountFilter(discountedPrice, currency, true);
        }
      } catch (e) {
        console.log(e);
      }


      const annualCopyTrial = `<span>By clicking "Agree & Subscribe", you are signing up for a ${trialText} free trial. Your trial will begin immediately. <b>If you don’t cancel before the end of the ${trialText} trial period, you authorize Luminary to automatically charge your payment method ${finalDisplayPrice} plus applicable taxes for the first 12 months of your subscription. After your first 12 months, your subscription automatically renews annually at the then-current rate, charged to your payment method, unless you cancel at least 24 hours before the end of the current period.</b> You can cancel anytime by visiting your Account Settings. No partial refunds.</span>`;
      const annualCopyNoTrial = `<span>By clicking "Agree & Subscribe", <b>you authorize Luminary to automatically charge your payment method ${finalDisplayPrice} plus applicable taxes for the first 12 months of your subscription. After your first 12 months, your subscription automatically renews annually at the then-current rate, charged to your payment method, unless you cancel at least 24 hours before the end of the current period.</b> You can cancel anytime by visiting your Account Settings. No partial refunds.</span>`;
      const monthlyCopyTrial = `<span>By clicking "Agree & Subscribe" you are signing up for a free ${trialText} trial. Your trial will begin immediately. <b>If you don’t cancel before the end of the ${trialText} trial period, you authorize Luminary to automatically charge your payment method ${finalDisplayPrice}/month plus applicable taxes. Your subscription automatically renews monthly at the then-current rate, charged to your payment method, unless you cancel at least 24 hours before the end of the current period.</b> You can cancel anytime by visiting your Account Settings. No partial refunds.</span>`;
      const monthlyCopyNoTrial = `<span>By clicking "Agree & Subscribe" <b>you authorize Luminary to automatically charge your payment method ${finalDisplayPrice}/month plus applicable taxes. Your subscription automatically renews monthly at the then-current rate, charged to your payment method, unless you cancel at least 24 hours before the end of the current period.</b> You can cancel anytime by visiting your Account Settings. No partial refunds.</span>`;
      if (showTrial) {
        return displayName === 'Monthly' ? monthlyCopyTrial : annualCopyTrial;
      }
      return displayName === 'Monthly' ? monthlyCopyNoTrial : annualCopyNoTrial;
    },
  },
  methods: {
    // setPricingPlans() {
    //   this.activePlan = this.$store.getters.activePlan;
    //   this.activePlanCharges = this.$store.getters.activePlanCharges;
    //   if (this.activePlan && this.activePlanCharges) {
    //     this.pricing = this.activePlanCharges.pricing;
    //   }
    // },
    initAdyenForm() {
      const styleObject = {
        base: {
          color: 'black',
          fontSize: '16px',
          fontWeight: '400',
          fontSmoothing: 'antialiased',
          fontFamily: 'inherit',
          maxHeight: '48px',
          minHeight: '48px',
          padding: '0px 0px 0px 0px',
        },
        error: {
          color: 'red',
        },
        placeholder: {
          color: '#c7cbd2',
        },
        validated: {
          color: '#000',
        },
      };
      const csfSetupObj = {
        rootNode: '.cards-div',
        configObject: {
          originKey: process.env.LUMINARY_ADYEN_ORIGIN_KEY,
        },
        paymentMethods: {
          card: {
            sfStyles: styleObject,
            placeholders: {
              encryptedCardNumber: '0000 0000 0000 0000',
              encryptedExpiryMonth: 'MM',
              encryptedExpiryYear: 'YY',
              encryptedSecurityCode: 'CCV',
            },
          },
        },
      };
      this.loading = true;
      this.adyenForm.loading = true;
      this.adyenForm.loadStartTime = (new Date()).getTime();
      this.adyenForm.loadEndTime = 0;
      clearTimeout(this.adyenForm.loadWatcher);
      this.trackAdyenFormLoaded(); // This will log if payment form is not loaded for threshold time window.
      const securedFields = window.csf(csfSetupObj);
      securedFields.onLoad(this.adyenFormLoaded);
      securedFields.onFieldValid(this.adyenFieldValid);
      securedFields.onBrand(this.adyenBrand);
      securedFields.onError(this.adyenError);
    },
    adyenFormLoaded() {
      this.loading = false;
      this.adyenForm.loading = false;
      this.adyenForm.loadEndTime = (new Date()).getTime();
      const diff = (this.adyenForm.loadEndTime - this.adyenForm.loadStartTime) / 1000;
      this.sendAdyenFormLoadTimeInNewRelic(diff);
    },
    adyenFieldValid() {
    },
    adyenBrand(brandObject) {
      this.cardBrand = brandObject.brand;
    },
    adyenError() {
    },
    preventSubmit(e) {
      e.stopPropagation();
    },
    validateForm() {
      let paymentMethod = null;
      this.$refs.paymentForm.validate((valid, data) => {
        if (valid) {
          const encNumber = document.getElementById('card-encrypted-encryptedCardNumber');
          const encSecurityCode = document.getElementById('card-encrypted-encryptedSecurityCode');
          const encExpMonth = document.getElementById('card-encrypted-encryptedExpiryMonth');
          const encExpYear = document.getElementById('card-encrypted-encryptedExpiryYear');

          this.zuoraErrorMessage = '';
          if (!encNumber || !encNumber.value.length) {
            this.zuoraErrorMessage = 'Card number is missing or invalid';
          } else if (!encExpMonth || !encExpMonth.value.length) {
            this.zuoraErrorMessage = 'Card exp. month is missing or invalid';
          } else if (!encExpYear || !encExpYear.value.length) {
            this.zuoraErrorMessage = 'Card exp. year is missing or invalid';
          } else if (!encSecurityCode || !encSecurityCode.value.length) {
            this.zuoraErrorMessage = 'Card security code is missing or invalid';
          }

          if (!this.zuoraErrorMessage.length) {
            paymentMethod = {
              type: 'scheme',
              encryptedCardNumber: encNumber.value,
              encryptedExpiryMonth: encExpMonth.value,
              encryptedExpiryYear: encExpYear.value,
              encryptedSecurityCode: encSecurityCode.value,
            };
            // if (this.update) {
            //   this.updatePaymentInfo(paymentMethod);
            // } else {
            //   this.subscribe(paymentMethod);
            // }
          } else if (document.querySelector('.el-alert')) {
            setTimeout(() => {
              this.scrollToElement('.el-alert');
            }, 500);
          } else {
            setTimeout(() => {
              this.scrollToElement('.el-alert');
            }, 500);
          }
        } else if (data) {
          const keys = Object.keys(data);
          if (keys.length) {
            const dd = data[keys[0]];
            if (dd && dd.length) {
              this.scrollToElement(`#${dd[0].field}`);
            }
          }
        }
      });
      return paymentMethod;
    },
    sendAdyenFormLoadTimeInNewRelic(diff = null) {
      const nrInteraction = window.newrelic.interaction();
      nrInteraction
        .setName('Payment Form')
        .setAttribute('loadTime', diff);
      nrInteraction.save();
      nrInteraction.end();
    },
    trackAdyenFormLoaded() {
      const ctx = this;
      ctx.adyenForm.loadWatcher = setTimeout(() => {
        if (ctx.adyenForm.loading) {
          ctx.sendAdyenFormLoadTimeInNewRelic();
        }
      }, ctx.adyenForm.loadingThreshold);
    },
    resetAdyenEncryptedCardFields() {
      const adyenCardFieldsParent = document.getElementById('js-chckt-pm__pm-holder');
      const encNumber = document.getElementById('card-encrypted-encryptedCardNumber');
      const encSecurityCode = document.getElementById('card-encrypted-encryptedSecurityCode');
      const encExpMonth = document.getElementById('card-encrypted-encryptedExpiryMonth');
      const encExpYear = document.getElementById('card-encrypted-encryptedExpiryYear');

      try {
        if (adyenCardFieldsParent) {
          adyenCardFieldsParent.removeChild(encNumber);
          adyenCardFieldsParent.removeChild(encSecurityCode);
          adyenCardFieldsParent.removeChild(encExpMonth);
          adyenCardFieldsParent.removeChild(encExpYear);
          this.cardBrand = null;
        }
      } catch (err) {
        log.error(err);
      }
    },
    getAdyenDropInConfig(paymentMethods) {
      const conf = {
        paymentMethodsResponse: paymentMethods, // The `/paymentMethods` response from the server.
        clientKey: process.env.LUMINARY_ADYEN_CLIENT_KEY, // Web Drop-in versions before 3.10.1 use originKey instead of clientKey.
        locale: 'en-US',
        environment: process.env.NODE_ENV === 'production' ? 'live' : 'test',
        onSubmit: (state, dropin) => {
          // eslint-disable-next-line no-console
          console.error('onSubmit', state, dropin);
        },
        onAdditionalDetails: (state, dropin) => {
          // eslint-disable-next-line no-console
          console.error('onAdditionalDetails', state, dropin);
        },
        paymentMethodsConfiguration: {
          card: { // Example optional configuration for Cards
            hasHolderName: true,
            holderNameRequired: true,
            // billingAddressRequired: this.$store.getters.featureFlag('adyen_drop_in_billing'),
            // billingAddressAllowedCountries: ['GB', 'CA'],
            data: {
              billingAddress: {
                country: this.paymentForm.country,
              },
            },
          },
          paypal: {
            showPayButton: true,
            environment: process.env.NODE_ENV === 'production' ? 'live' : 'test',
          },
        },
      };
      if (this.paymentForm.firstName || this.paymentForm.lastName) {
        conf.paymentMethodsConfiguration.card.data.holderName = `${this.paymentForm.firstName} ${this.paymentForm.lastName}`;
      }
      return conf;
    },
    calculateTrialEndDateLocally() {
      if (this.annualPlanEnabled) {
        const daysFromNow = this.$store.getters.trialPeriodDays;
        const dateOfTrialExpiry = new Date();
        dateOfTrialExpiry.setDate(dateOfTrialExpiry.getDay() + daysFromNow);
        return dateOfTrialExpiry;
      }
      const promoCode = localStorage.getItem('promo_code');
      const promoTrailEndDate = localStorage.getItem('promo_trial_end_date');
      let currentDate = new Date();
      currentDate.setMonth(currentDate.getMonth() + 1);
      if (promoCode && promoTrailEndDate) {
        currentDate = promoTrailEndDate;
      }
      return currentDate;
    },
    logConversionOnGooglePixels(subscription) {
      if (window && window.gtag) {
        // window.gtag('event', 'conversion', { send_to: `${process.env.LUMINARY_GOOGLE_ADS_CONVERSION_ID}/${process.env.LUMINARY_GOOGLE_ADS_CONVERSION_LABEL}`, value: subscription.plan.total_price, currency: subscription.plan.currency });
      }
    },
    logBranchCustomEvent(subscription) {
      const customData = {
        uuid: subscription.uuid,
        user_uuid: subscription.user_uuid,
        zuora_plan_id: subscription.zuora_plan_id,
        total_price: subscription.plan.total_price,
        promo_code: subscription.promo_code,
        currency: subscription.plan.currency,
        branch_promo: localStorage.getItem('branch_promo'),
      };
      branchService.logCustomEvent(branchService.subscriptionCreatedEventName, customData);
    },
    updatePaymentResponse(resp) {
      this.resetAdyenEncryptedCardFields();
      if (resp) {
        this.$store.dispatch('loadUserSubscription').then(() => {
          this.loading = false;
          const { user } = this.$store.getters;
          user.is_subscribed = true;
          if (this.paymentForm) {
            user.first_name = this.paymentForm.firstName;
            user.last_name = this.paymentForm.lastName;
          }
          this.showSuccessMsg('Successfully updated payment info.');
          this.$store.commit('setUser', user);
          // TODO: update required data in vuex instead of triggering success and handle in parent
          this.$emit('success');
        });
      } else this.loading = false;
    },
    subscriptionResponse(resp) {
      this.loading = false;
      this.resetAdyenEncryptedCardFields();
      if (resp && resp.is_provisional_subscription) {
        if (resp && resp.plan && !resp.plan.trial_end_date) {
          resp.plan.trial_end_date = this.calculateTrialEndDateLocally();
          resp.plan.trial_end_date_from_client = true;
          resp.plan.is_first_subscription = true;
        }

        // check if user's selected country on subscription is different from previous subscription record
        // then show default pricing of selected country throughtout app
        const userSubscription = this.userSubscription || {};
        if (userSubscription && userSubscription.plan && resp.plan && resp.plan.country !== userSubscription.plan.country) {
          const defaultPricing = {
            currency: resp.plan.currency,
            price: resp.plan.total_price,
          };
          this.$store.commit('setPricing', defaultPricing);
        }

        // If resubscribing
        if (resp.has_resubscribed) {
          this.showSuccessMsg('Successfully subscribed  to Luminary Premium.');
        }

        this.$store.commit('setUserSubscription', resp);
        const { user, featureFlag } = this.$store.getters;
        if (user && !user.is_on_boarded) {
          this.$store.dispatch('setNewSubscription', true);
          this.logConversionOnGooglePixels(resp); // Event snippet for Subscription_web_pixel conversion page
        }
        user.is_subscribed = true;
        user.is_on_boarded = true;
        if (this.paymentForm) {
          user.first_name = this.paymentForm.firstName;
          user.last_name = this.paymentForm.lastName;
        }
        this.$store.commit('setUser', user);
        if (localStorage.getItem('branch_promo') !== null) {
          const params = {
            bpromo: localStorage.getItem('branch_promo'),
            monthsfree: localStorage.getItem('promo_trial_period'),
          };
          this.$analytics.identify(user.uuid, params);
        }
        // Use this data to send to branch custom event
        this.logBranchCustomEvent(resp);
        localStorage.removeItem('promo_code');
        localStorage.removeItem('promo_trial_end_date');
        localStorage.removeItem('promo_trial_period');
        localStorage.removeItem('branch_promo');
        this.$store.dispatch('setPromoData', {
          promoCode: '',
          trialPeriod: 1,
          trialEndDate: '',
          header: '',
          subheader: '',
        }); // Removing promo code data after user
        const selectedPlan = (this.$store.getters.selectedPlan && this.$store.getters.selectedPlan.planTypeString) || null;
        const planType = resp.plan.type || resp.planType || selectedPlan;
        const preloadPayment = featureFlag('preloadPayment', { impression: false });
        const eventData = {
          path: (window && window.location && window.location.pathname) || null,
          preloadPayment,
          platform: planType,
          email: user.email,
        };
        const price = resp.plan.final_price === 0 ? resp.plan.total_price : resp.plan.final_price;
        const product = {
          name: this.$store.getters.selectedPlan ? this.$store.getters.selectedPlan.productName : `Luminary Subscription - ${resp.plan.type}`,
          sku: this.$store.getters.selectedPlan ? this.$store.getters.selectedPlan.productSku : `luminary-sub-${resp.plan.type}`,
          price,
          quantity: 1,
        };
        const customAttributes = {
          email: user.email,
          subscription_name: product.name,
          url_path: (window && window.location && window.location.pathname) || null,
          predicted_ltv: Math.round(resp.plan.total_price * 3),
          ...resp.plan,
        };
        const transactionAttributes = {
          Id: uuidv1(),
          Revenue: price,
          Tax: 0,
        };
        this.$analytics.track('ga_subscription_created', eventData);
        this.$analyticsV2.logEvent('cf_subscription_created', customAttributes);
        this.$analyticsV2.logPurchase(product, customAttributes, transactionAttributes);
        optimizeService.triggerPrimaryObjective();
        // TODO: update required data in vuex instead of triggering success and handle in parent
        this.$emit('success');
      }
    },
    handleGiftPurchaseSuccess(requestData) {
      this.loading = false;
      this.dispatchGiftConfirmation(requestData);
      this.dispatchResetGiftAccordion();
      this.navigateUserToGiftConfimationPage();
      this.showSuccessMsg('Purchase completed successfully');
    },
    dispatchGiftConfirmation(requestData) {
      const payload = {
        delivered_at: requestData.delivered_at,
        yourEmail: requestData.purchaser.email,
      };
      this.$store.dispatch('setGiftConfirmation', payload);
    },
    navigateUserToGiftConfimationPage() {
      this.$router.push('/gift?giftSent=true');
    },
    dispatchResetGiftAccordion() {
      this.$store.dispatch('resetAccordion');
    },
  },
};
