<template>
  <div class="e1-billing">
    <div v-if="isPromoPlan" id="welcome_box">Welcome to Luminary!</div>
    <VinylPromoMessage v-if="isPromoPlan" :now="false"/>
    <h1 v-else class="e1-billing__header">Billing Info</h1>
    <div class="e1-billing__description">
      <span v-html="customBillingInstruction"/>
      <span v-html="customBillingDescription"/>
    </div>
    <e1-adyen-dropin
      :acquisition-flow="true"
      :show-text="false"
      :promocode="currentRedeeming"
      button_text="Agree & Subscribe"
      @success="onSuccess"
    />
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import E1AdyenDropin from './E1AdyenDropin';
import SubscriptionMixin from '@/shared/subscriptionMixin';
import VinylPromoMessage from './VinylPromoMessage';
import PageMixin from '../../shared/pageMixin';

export default {
  name: 'E1Billing',
  components: {
    VinylPromoMessage,
    E1AdyenDropin,
  },
  mixins: [SubscriptionMixin, PageMixin],
  data() {
    return {};
  },
  computed: {
    ...mapGetters('signupflow', [
      'isStepAvailable',
    ]),
    ...mapGetters({
      selectedPlan: 'selectedPlan',
      currentRedeeming: 'promocode/currentRedeeming',
      currentRedeemingData: 'promocode/currentRedeemingData',
    }),
    isAvailable() {
      return this.isStepAvailable('billing');
    },
    successRoute() {
      return { ...this.$route.meta.next };
    },
    customBillingInstruction() {
      if (this.selectedPlan && this.selectedPlan.isPromoPlan) return 'Enter your billing information to start your subscription.<br/><br/>After 12 months, your Annual Plan will auto-renew and you will automatically be charged <b>$34.99</b> every year. Cancel any time.';
      return this.selectedPlan && this.selectedPlan.isHolidayPromo
        ? 'Enter your billing information to complete your account set-up.'
        : this.billingInstruction;
    },
    isPromoPlan() {
      return this.selectedPlan && this.selectedPlan.isPromoPlan;
    },
    customBillingDescription() {
      return '';
      /*
      return this.selectedPlan && this.selectedPlan.isHolidayPromo
        ? ''
        : this.billingDescription;
       */
    },
  },
  watch: {
    isAvailable(v) {
      this.processAvailability(v);
    },
  },
  mounted() {
    if (this.$route.query.newSubscription) {
      // came from PaymentSecondStep after payment redirect
      this.$router.push(this.successRoute);
      return;
    }
    this.processAvailability(this.isAvailable);
    this.setMetaInformation('Sign Up | Luminary', 'Get your Luminary subscription today & listen to original podcasts you won\'t find anywhere else.');
  },
  destroyed() {
    this.setMetaInformation();
  },
  methods: {
    ...mapActions('signupflow', [
      'setCompleted',
    ]),
    processAvailability(isAvailable) {
      if (!isAvailable) {
        this.$router.replace(this.$route.meta.prev);
      }
    },
    onSuccess() {
      const { selectedPlan } = this.$store.getters;
      if (selectedPlan != null) {
        this.trackSteps({ step: 'stepThree', flowType: 'exp' });
      }
      this.$router.push(this.successRoute);
    },
  },
};
</script>
<style lang="scss" scoped>
@import '../../assets/scss/variables.scss';

.e1-billing {
  display: flex;
  align-items: center;
  flex: 1 1 auto;
  justify-content: start;
  flex-flow: column;
  &__header {
    font-family: $serif-bold;
    font-size: 36px;
  }
  &__description {
    font-size: 16px;
    max-width: 500px;
    text-align: center;
    margin-bottom: 30px;
  }
}

#welcome_box {
  padding: 15px 15px 15px 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  height: auto;
  background-color: $brand;
  color: $dark-background;
  margin-bottom: 20px;
  width: 50%;
  @media (max-width: $xs) {
    width: 90%;
  }
}

</style>
