<template>
  <div
    class="plans-chooser">
    <div
      v-if="showHero"
      class="plans-hero">
      <h1
        v-if="isHolidayPromoEnabled"
        class="page-main-title no-letter-space">Start Listening</h1>
      <h1
        v-else-if="hideMonthly"
        class="page-main-title no-letter-space">Get Started</h1>
      <h1
        v-else
        class="page-main-title no-letter-space">Choose a Subscription</h1>
      <h4 class="font-normal" v-html="subtitle" />
      <h4
        v-if="showSignInPrompt"
        class="font-normal">Already have an account?
        <router-link
          v-button-event
          to="/signin"
          class="btn-link btn-link_blue">Sign in</router-link>.</h4>
    </div>
    <div
      v-if="plansLoaded"
      class="plans-container">
      <div :class="{'plans': !displayAsStack, 'plans-stacked': displayAsStack, 'acquisition-plans-style': acquisitionFlowDisplay}">
        <template v-for="(plan, index) in plansSorted">
          <apple-podcasts-card
            v-if="plan.planTypeString === 'applePodcasts'"
            :plan="plan"
            :key="`plan-${index}`"
          />
          <plan-card
            v-button-event="{buttonName: `${plan.displayName} Plan Select`}"
            v-else
            :key="`plan-${index}`"
            :plan="plan"
            :plan-selected="planSelected"
            :show-top-header="showTopHeaders"
            :show-subscribe-button="showSubscribeButton"
            :show-select-button="showSelectButton"
            :show-best-value-label="showBestValueLabel"
            :hide-monthly="hideMonthly"
            :no-trial="noTrial"
            @selectPlan="selectPlan"/>
        </template>
      </div>
      <div
        v-if="showContinueFree && hideMonthly"
        class="free-plan"
        @click="toggleHideMonthly">
        <div
          v-button-event="{buttonName: 'See More Plans'}"
          class="free-desc">
          <span>See More Plans</span>
        </div>
      </div>
      <div
        v-if="showContinueFree && !hideMonthly"
        class="free-plan"
        @click="selectFreePlan">
        <div
          v-button-event="{buttonName: 'Free Plan Select'}"
          :class="{'fill' : freePlanSelected}"
          class="free-desc">
          <span>{{ freePlanText }}</span>
        </div>
      </div>
      <router-link
        v-button-event
        v-if="showTerms"
        aria-label="Terms apply"
        to="/promoterms">
        <div class="terms-link">Terms Apply</div>
      </router-link>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import sortBy from 'lodash/sortBy';
import PlanCard from '../../components/PlanCard';
import ApplePodcastsCard from '../../components/ApplePodcastsCard';

export default {
  name: 'PlanSignUp',
  components: {
    ApplePodcastsCard,
    'plan-card': PlanCard,
  },
  props: {
    showContinueFree: {
      type: Boolean,
      default: () => true,
    },
    showSignInPrompt: {
      type: Boolean,
      default: () => true,
    },
    showTopHeaders: {
      type: Boolean,
      default: () => false,
    },
    displayAsStack: {
      type: Boolean,
      default: () => false,
    },
    acquisitionFlowDisplay: {
      type: Boolean,
      default: () => false,
    },
    showHero: {
      type: Boolean,
      default: () => true,
    },
    planSelected: {
      type: Boolean,
      default: () => false,
    },
    freePlanText: {
      type: String,
      default: () => 'Continue with limited access to Luminary Originals.',
    },
    showSubscribeButton: {
      type: Boolean,
      default: () => true,
    },
    showSelectButton: {
      type: Boolean,
      default: () => false,
    },
    showTerms: {
      type: Boolean,
      default: () => true,
    },
    showBestValueLabel: {
      type: Boolean,
      default: () => true,
    },
    hideMonthly: {
      type: Boolean,
      default: () => false,
    },
    toggleHideMonthly: {
      type: Function,
      default: () => {},
    },
    noTrial: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      selectedPlan: null,
      freePlanTouched: false,
    };
  },
  computed: {
    ...mapGetters([
      'isHolidayPromoEnabled',
    ]),
    plansSorted() {
      const { userSubscription } = this.$store.getters;
      if (this.promoMeta.isAnonUser || this.promoMeta.isFreeUser) {
        return this.pricingPlans;
      }
      if (userSubscription) {
        const addSort = this.pricingPlans.map((plan) => {
          if (plan.planTypeString === userSubscription.plan.type) {
            plan.order = 0;
            plan.topHeader = 'Your current plan';
          } else {
            plan.order = 1;
            plan.topHeader = 'Choose a new plan';
          }
          return plan;
        });
        const sortedPlans = sortBy(addSort, 'order');
        return sortedPlans;
      }
      return null;
    },
    freePlanSelected() {
      return this.$store.getters.freePlanSelected;
    },
    subtitle() {
      return this.isHolidayPromoEnabled ? 'Save 60% off the normal monthly price with an annual plan.<br/>For a limited time only.' : 'Subscribers enjoy full access to Luminary Original Podcasts.';
    },
  },
  methods: {
    selectPlan(plan) {
      this.$store.dispatch('selectPricingPlan', plan);
      if (plan) {
        this.$store.dispatch('deselectFreePlan');
      }
      this.$emit('onSelectPlan', plan);
    },
    selectFreePlan() {
      this.$store.dispatch('selectFreePlan');
      this.selectPlan(null);
    },
  },
};
</script>
<style lang="scss">
@import '../../assets/scss/variables.scss';
.plans-chooser {
  @media(max-width: 767px) {
    padding: 0 27px;
  }
  .plans-hero {
    margin-top: 64px;
    margin-bottom: 52px;
    @media(max-width: 567px) {
      margin-top: 0px;
      margin-bottom: 32px;
    }
    h1 {
      text-align: center;
      margin: 38px 0 24px 0;
      // @media(max-width: $sm) {
      //   font-size: 32px;
      //   line-height: 38px;
      // }
      @media(max-width: 567px) {
        margin-top: 0px;
      }
    }
    h4 {
      font-family: $clear-sans;
      font-size: 16px;
      line-height: 20px;
      text-align: center;
      margin: 0px;
      margin-bottom: 17px;
    }
  }
  .plans-container {
    .plans {
      display: flex;
      justify-content: space-between;
      margin-right: auto;
      margin-left: auto;
      margin-bottom: 44px;
      .plan-card {
        flex: 1;
      }
      @media(min-width: $sm) {
        max-width: 940px;
        > .plan-card {
          margin-right: 30px;
        }
        > .plan-card ~ .plan-card {
          margin-right: auto;
        }
      }
      @media(max-width: $sm) {
        margin-bottom: 10px;
        width: 90vw;
        .plan-card {
          width: 100%;
          margin-bottom: 20px;
        }
      }
      @media(max-width: $md) {
        margin-bottom: 0px;
        flex-direction: column;
        .plan-card {
          margin-right: auto;
          margin-left: auto;
          margin-bottom: 20px;
        }
      }
    }
    .acquisition-plans-style {
      display: flex;
      margin-right: auto;
      margin-left: auto;
      flex-direction: column;
      margin-bottom: 24px;
      @media(max-width: $sm) {
        margin-bottom: 0px;
      }
      @media(min-width: $sm) {
        max-width: 830px;
      }
      .plan-card {
        width: 100%;
        margin-bottom: 20px;
        background: #1A263F;
        border-radius: 6px;
        border-color: #1A263F;
        h3 {
          color:#ffffff;
          font-family: $clear-sans;
        }
        p {
          color:#ffffff;
            font-family: $clear-sans;
        }
        .btn-brand{
          background-color: #00AECB !important;
          color: #fff !important;
          font-size: 16px;
          border-color: #1A263F !important;
          &:focus {
            border: 2px solid #FFFFFF !important;
          }
        }
        .btn-monthly{
          background-color: $bg-color;
          color: #00AECB;
          font-size: 16px;
          border-color: #00AECB;
        }
        .best-value-label {
          display: block;
          background-color: #ffffff !important;
          opacity: 20%;
          font-size: 14px;
          font-weight: bold;
          line-height: 19px;
          position: absolute;
          border-radius: 4px;
          top: 0px;
          left:22%;
          width: 18%;
          top: 22%;
          text-align: center;
          @media(max-width: $sm) {
            left:30%;
            width: 25%;
            top: 13%;
          }
        }
      }
    }
    .plans-stacked {
      display: flex;
      margin-right: auto;
      margin-left: auto;
      flex-direction: column;
      margin-bottom: 24px;
      @media(min-width: $sm) {
        max-width: 830px;
        :nth-child(1) {
          // margin-right: 0px;
        }
      }
      .plan-card {
        width: 100%;
        margin-bottom: 20px;
      }
    }
    .free-plan {
      display: block;
      position: relative;
      max-width: 480px;
      width: 90%;
      margin: auto;
      margin-bottom: 24px;
      .free-desc {
        background: $white;
        border: 1px solid $blue;
        box-sizing: border-box;
        border-radius: 30px;
        font-size: 14px;
        line-height: 20px;
        text-align: center;
        padding: 13px 0;
        color: $blue;
        cursor: pointer;
        @media(max-width: 767px) {
          padding: 10px 20px;
        }
      }
      .fill {
        color: #ffffff;
        background: $blue;
        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 1px rgba(0, 0, 0, 0.14);
      }
    }
    .terms-link {
      text-align: center;
      font-weight: 400;
      font-size: 14px;
      line-height: 19px;
      text-align: center;
      text-decoration-line: underline;
      color: #666666;
      margin-bottom:30px;
      &:hover {
        color: $blue;
      }
    }
  }
}

</style>
