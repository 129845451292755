/* eslint-disable import/no-cycle */
import AxiosAPI from './AxiosAPI';
import store from '@/store';

class AnalyticsApi extends AxiosAPI {
  sendQualifiedListening(eventData) {
    const apiPath = store.getters.featureVariable('qualified_listening', 'ql_api_path') || 'v1/events/listening-stats';
    return super.post(apiPath, eventData);
  }

  sendEventTracking(eventData) {
    return super.post('v1/events/analytics-events', eventData);
  }
}

export default AnalyticsApi;
