import Podcast from '../services/Podcast';

const podcast_service = new Podcast();

export default {
  async getShowDetails(uuid, params) {
    try {
      const podcast = await podcast_service.getShowDetailsById(uuid, params);

      let show_data = {};
      let totalEpisodes = 0;
      let episodesList = [];

      show_data = {
        categories: {
          name: podcast.categoryName,
          slug: podcast.categorySlug,
          uuid: podcast.categoryId,
        },
        description: podcast.description,
        description_html: podcast.description,
        display_image_url: podcast.displayImageUrl,
        feed_type: podcast.playSequence,
        has_free_episodes: podcast.hasFreeEpisodes,
        image_url: podcast.displayImageUrl,
        is_exclusive: podcast.isExclusive,
        publisher: {
          name: podcast.publisherName,
          slug: podcast.publisherSlug,
          uuid: podcast.publisherId,
        },
        slug: podcast.slug,
        title: podcast.title,
        uuid: podcast.id,
      };

      totalEpisodes = podcast.episodesTotalCount;

      episodesList = podcast.episodes.map(episode => ({
        uuid: episode.id,
        episode_number: episode.episodeNumber,
        title: episode.title,
        description: episode.description,
        description_html: episode.description,
        slug: episode.slug,
        image_url: episode.displayImageUrl,
        display_image_url: episode.displayImageUrl,
        media_url: episode.mediaUrl,
        runtime: episode.runtime,
        release_date: episode.releasedAt,
        released_at: episode.releasedAt,
        season: {
          number: episode.seasonNumber,
          podcast: show_data, // this seems like excessive nesting
        },
        is_exclusive: episode.isExclusive,
      }));

      return {
        show_data,
        totalEpisodes,
        episodesList,
      };
    } catch (err) {
      return err;
    }
  },
};
