<template>
  <div id="cards-feature">
    <div class="container">
      <div ref="scroll_element" class="content hide-mobile scrollme">
        <div
          class="cards-row animateme"
          data-when="exit"
          data-from="1"
          data-easing="easeout"
          data-to="0"
          data-translatex="0"
          style="opacity: 1; transform: translate3d(0px, 0px, 0px) rotateX(0deg) rotateY(0deg) rotateZ(0deg) scale3d(1, 1, 1);">

          <a
            class="content-card card1"
            href="https://luminary.link/wildlife"
            @click="onClick">
            <div class="card-contents">
              <ul class="badges">
                <li>#Culture</li>
              </ul>
              <h3>Pull the Thread</h3>
              <div class="logo">
                <img src="../../../assets/images/web2/summer/logo-twl.png" alt="The Wild Life" />
              </div>
              <p>A spy on the hunt for an ivory kingpin.</p>
            </div>
            <div class="gradients">
              <div class="gradient dark" />
              <div
                class="gradient light"
                style="background: linear-gradient(309deg, rgba(148, 39, 42, 0.75) 0%, rgba(148, 39, 42, 0) 30%);" />
            </div>
          </a>

          <a
            class="content-card card2"
            href="https://luminarypodcasts.com/listen/corinne-fisher-and-krystyna-hutchinson/guys-we-f-d/61ef47bf-07ce-4c3e-b175-e846e8f5707f"
            @click="onClick">
            <div class="card-contents">
              <ul class="badges">
                <li>#Culture</li>
              </ul>
              <h3>Corinne Fisher, Krystyna Hutchinson</h3>
              <div class="logo">
                <img src="../../../assets/images/web2/summer/sol-logo-guys-we-fcked.png" alt="Guys we fcked" />
              </div>
              <p>Forget everything you think you know about female sexuality.</p>
            </div>
            <div class="gradients">
              <div class="gradient dark" />
              <div
                class="gradient light"
                style="background: linear-gradient(309deg, rgba(148, 39, 42, 0.75) 0%, rgba(148, 39, 42, 0) 30%);" />
            </div>
          </a>


          <a
            class="content-card card3"
            href="https://luminary.link/hoops"
            @click="onClick">
            <div class="card-contents">
              <ul class="badges">
                <li>#Culture</li>
              </ul>
              <h3>Liz Lapp, Karen Uyenco</h3>
              <div class="logo">
                <img
                  src="../../../assets/images/web2/summer/logo-hoops-off.png"
                  alt="Hoops Off w/ Liz Lapp and Karen Uyenco" />
              </div>
              <p>Sports! Athletes! Activism! Hoops Off is sports talk from a woman’s point of view.</p>
            </div>
            <div class="gradients">
              <div class="gradient dark" />
              <div
                class="gradient light"
                style="background: linear-gradient(309deg, rgba(217,210,184,.75) 0%, rgba(217,210,184,0) 30%);" />
            </div>
          </a>

          <a
            class="content-card card4"
            href="https://luminary.link/nucci"
            @click="onClick">
            <div class="card-contents">
              <ul class="badges">
                <li>#Culture</li>
              </ul>
              <h3>Joe Nucci</h3>
              <div class="logo">
                <img src="../../../assets/images/web2/summer/logo-nucci.png" alt="Therapy vs. The World w/ Joe Nucci" />
              </div>
              <p>Join Joe as he speaks to experts in his field and outside of it, as we decipher what kind of mental
              health information is helpful for you and what isn’t.</p>
            </div>
            <div class="gradients">
              <div class="gradient dark" />
              <div
                class="gradient light"
                style="background: linear-gradient(309deg, rgba(86,79,75,.75) 0%, rgba(86,79,75,0) 30%);" />
            </div>
          </a>


        </div>
      </div>
      <div class="content hide-desktop">
        <div class="cards-row">

          <a
            class="content-card card1"
            href="https://luminary.link/wildlife"
            @click="onClick">
            <div class="card-contents">
              <ul class="badges">
                <li>#Culture</li>
              </ul>
              <h3>Pull the Thread</h3>
              <div class="logo">
                <img src="../../../assets/images/web2/summer/logo-twl.png" alt="The Wild Life" />
              </div>
              <p>A spy on the hunt for an ivory kingpin.</p>
            </div>
            <div class="gradients">
              <div class="gradient dark" />
              <div
                class="gradient light"
                style="background: linear-gradient(309deg, rgba(148, 39, 42, 0.75) 0%, rgba(148, 39, 42, 0) 30%);" />
            </div>
          </a>

          <a
            class="content-card card2"
            href="https://luminarypodcasts.com/listen/corinne-fisher-and-krystyna-hutchinson/guys-we-f-d/61ef47bf-07ce-4c3e-b175-e846e8f5707f"
            @click="onClick">
            <div class="card-contents">
              <ul class="badges">
                <li>#Culture</li>
              </ul>
              <h3>Corinne Fisher, Krystyna Hutchinson</h3>
              <div class="logo">
                <img src="../../../assets/images/web2/summer/sol-logo-guys-we-fcked.png" alt="Guys we fcked" />
              </div>
              <p>Forget everything you think you know about female sexuality.</p>
            </div>
            <div class="gradients">
              <div class="gradient dark" />
              <div
                class="gradient light"
                style="background: linear-gradient(309deg, rgba(148, 39, 42, 0.75) 0%, rgba(148, 39, 42, 0) 30%);" />
            </div>
          </a>

          <a
            class="content-card card3"
            href="https://luminary.link/hoops"
            @click="onClick">
            <div class="card-contents">
              <ul class="badges">
                <li>#Culture</li>
              </ul>
              <h3>Lize Lapp, Karen Uyenco</h3>
              <div class="logo">
                <img
                  src="../../../assets/images/web2/summer/logo-hoops-off.png"
                  alt="The People's Party with Talib Kweli" />
              </div>
              <p>Sports! Athletes! Activism! Hoops Off is sports talk from a woman’s point of view.</p>
            </div>
            <div class="gradients">
              <div class="gradient dark" />
              <div
                class="gradient light"
                style="background: linear-gradient(309deg, rgba(217,210,184,.75) 0%, rgba(217,210,184,0) 30%);" />
            </div>
          </a>

          <a
            class="content-card card4"
            href="https://luminary.link/nucci"
            @click="onClick">
            <div class="card-contents">
              <ul class="badges">
                <li>#Culture</li>
              </ul>
              <h3>Joe Nucci</h3>
              <div class="logo">
                <img
                  width="200"
                  src="../../../assets/images/web2/summer/logo-nucci.png"
                  alt="Therapy vs. The World w/ Joe Nucci" />
              </div>
              <p>Join Joe as he speaks to experts in his field and outside of it, as we decipher what kind of mental
              health information is helpful for you and what isn’t.</p>
            </div>
            <div class="gradients">
              <div class="gradient dark" />
              <div
                class="gradient light"
                style="background: linear-gradient(309deg, rgba(86,79,75,.75) 0%, rgba(86,79,75,0) 30%);" />
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      scroll_listener: null,
    };
  },
  mounted() {
    this.scroll_listener = () => {
      const scrollElement = this.$refs.scroll_element;
      window.requestAnimationFrame(() => {
        const top = 320;
        const scroll = 1 - (top - window.pageYOffset) / top;
        if (scrollElement) scrollElement.style = `transform: translate3d(${scroll * 100}px,0,0)`;
      });
    };
    window.addEventListener('load', this.scroll_listener);
    window.addEventListener('scroll', this.scroll_listener);
  },
  destroyed() {
    if (this.scroll_listener) {
      window.removeEventListener(this.scroll_listener);
      window.removeEventListener(this.scroll_listener);
    }
  },
  methods: {
    onClick(event) {
      event.preventDefault();
      const parent = event.target.closest('a');
      const buttonText = parent.querySelector('img').alt;
      this.$analyticsV2.logEvent('cf_button_clicked', {
        is_cta: true,
        target_url: parent.href,
        button_text: buttonText,
      });
      window.location.href = parent.href;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../assets/scss/variables.scss";

#cards-feature .container {
  height: 555px;
  margin-bottom: 4rem;
  max-width: none;
  overflow: hidden;
  position: relative;
  width: 100%;
}

.card1 {
  background-image: url(../../../assets/images/web2/summer/web-twl.jpg) !important;
}

.card2 {
  background-image: url(../../../assets/images/web2/summer/sol-web-guys-we-fcked.jpg)!important;
}

.card3 {
  background-image: url(../../../assets/images/web2/summer/web-hoops.jpg)!important;
}

.card4 {
  background-image: url(../../../assets/images/web2/summer/web-nucci.jpg)!important;
}


.cards-row {
  height: 555px;
  left: 50%;
  margin-left: calc((((426px * 4) + (12px * 10)) / 2) * -1);
  position: absolute;
  top: 0;
  /* 5 Cards + margin left and right + 2 margins between each. */
  width: calc((426px * 4) + (12px * 10));
}
.content-card {
  background: #192a48;
  background-size: cover;
  background-position: center top;
  border-radius: 6px;
  display: flex;
  float: left;
  height: 555px;
  margin: 0 12px;
  position: relative;
  width: 426px;
}
.content-card .gradients {
  border-radius: 6px;
  height: 100%;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;
}
.content-card .gradients .gradient {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
.content-card .gradients .gradient.dark {
  background: linear-gradient(48deg, rgba(18,28,48,1) 0%, rgba(18,28,48,0) 84%);
  z-index: 2;
}
.content-card .gradients .gradient.light {
  /* Set the background gradient values in markup, since it's unique for each card. */
  border-radius: 6px;
  box-shadow: inset 0 0 0 1px rgba(255, 255, 255, .12);
  z-index: 3;
}

.content-card .card-contents {
  align-self: flex-end;
  box-sizing: border-box;
  display: block;
  padding: 30px;
  position: relative;
  width: 100%;
  z-index: 2;
}
.content-card h3 {
  color: #fff;
  font-size: 1rem;
  line-height: 1.2rem;
  margin: 1rem 0;
}

.content-card .badges {
  font-size: 0;
  margin: 0;
  padding: 0;
}
.content-card .badges li {
  border: 1px solid #fff;
  border-radius: 3px;
  color: #fff;
  display: inline-block;
  font: bold 10px Patron, sans-serif !important;
  letter-spacing: 2px;
  line-height: 10px;
  list-style: none;
  margin: 0 10px 0 0;
  padding: 2px 5px 2px 6px;
  text-transform: uppercase;
}
.content-card .badges li.callout {
  background: #ffe500;
  border: none;
  color: #121c30;
  padding: 3px 6px 3px 7px;
}
.content-card .logo {
  height: 110px;
  margin: 1rem 0;
  max-width: 100%;
}
.content-card .logo img {
  display: block;
  height: auto;
  vertical-align: middle;
  width: 100%;
}

.card4 .logo img {
  width: 200px;
}

.card4 .logo {
  margin: 2rem 0;
}

.content-card p {
  color: rgba(255,255,255,.69);
  margin: 0;
  min-height: 50px;
}

.highlighted-header { text-align: center; }

.hide-mobile { display: block; }
.hide-desktop { display: none; }
@media (max-width: 567px) {

  .hide-mobile {display: none;}
  .hide-desktop {display: block;}

  #cards-feature .container {
    height: auto;
    max-width: 356px;
    width: 91.2%;
  }
  .cards-row {
    height: auto;
    left: auto;
    margin-left: 0;
    position: static;
    top: auto;
    width: auto;
  }
  .cards-row .content-card {
    height: calc(100vw / .7675);
    float: none;
    margin: 0 0 2rem 0;
    max-height: 475px;
    width: 100%;
  }
  //.cards-row .content-card:nth-child(4) { margin-bottom: 0; }
  //.cards-row .content-card:first-child, .cards-row .content-card:last-child {
  //  display: none;
  //}
  .content-card .logo {
    display: flex;
    height: auto;
  }
  .content-card p {
    font-size: 1rem;
  }
}

</style>
