<template>
  <div
    v-if="plansLoaded"
    class="content">
    <div class="content__regular">{{ regularPrice }}</div>
    <div class="content__offer">
      <div class="currency">{{ promoDiscount.symbol }}</div>
      <div class="cost-int">{{ promoDiscount.int }}</div>
      <div>
        <div class="cost-dec">{{ promoDiscount.dec }}</div>
        <div class="frequency">{{ promoDiscount.frequency }}</div>
      </div>
    </div>
    <div class="content__description">For 1 year{{ showTrial ? trialString : '' }}*</div>
    <div class="content__button-wrapper">
      <l-button
        :color="button_color"
        :variant="button_variant"
        :on_click="ctaClickHandler">
        {{ buttonCTAText }}
      </l-button>
    </div>
    <p class="content__disclaimer">* Plus applicable taxes. Expires {{ promoEndDate }}. New subscribers only. Cancel anytime. <router-link
      to="/promoterms"
      target="_blank">Terms Apply.</router-link></p>
  </div>
</template>

<script>
import Button from '../common/Button';
import amountFilter from '../../shared/amountFilter';

export default {
  name: 'PromoContent',
  components: {
    'l-button': Button,
  },
  props: {
    button_variant: {
      default: 'normal',
      type: String,
      validator(value) {
        return ['normal', 'thin'].indexOf(value) !== -1;
      },
    },
    button_color: {
      default: 'blue',
      type: String,
      validator(value) {
        return ['blue', 'black'].indexOf(value) !== -1;
      },
    },
    on_btn_click: {
      type: Function,
      default: null,
    },
    promo_variant: {
      type: String,
      default: null,
    },
    on_mounted: {
      type: Function,
      default: null,
    },
  },
  data() {
    return {
      isBranchReferrer: false,
    };
  },
  computed: {
    plansLoaded() {
      return this.$store.getters.plansLoaded;
    },
    isSubscribed() {
      return this.$store.getters.user && this.$store.getters.user.is_subscribed;
    },
    trialPeriod() {
      let trialMonths = localStorage.getItem('promo_trial_period') || 1;
      let trialCopy = 'month free';
      if (this.isSubscribed) {
        trialMonths = 1;
      }
      if (trialMonths !== 1) {
        trialCopy = 'months free';
      }
      return `${trialMonths} ${trialCopy}`;
    },
    regularPrice() {
      const { price, currency } = this.$store.getters.pricing;

      return amountFilter(price, currency, true);
    },
    regularPriceRecurring() {
      const { price, currency } = this.$store.getters.pricing;

      return amountFilter(price, currency, false);
    },
    showTrial() {
      const { showTrial } = this.$store.getters.promoData;

      return showTrial;
    },
    branchTrial() {
      const { promo_code } = this.$store.getters.branchData;
      const promo_map = {
        onemonthfree: 1,
        twomonthfree: 2,
        threemonthfree: 3,
        fourmonthfree: 4,
        fivemonthfree: 5,
        sixmonthfree: 6,
        sevenmonthfree: 7,
        eightmonthfree: 8,
        ninemonthfree: 9,
        tenmonthfree: 10,
        elevenmonthfree: 11,
        twelvemonthfree: 12,
      };
      let number = 1;
      if (promo_code && this.isBranchReferrer) {
        number = promo_map[promo_code] || 1;
      }
      return ` + ${number} month free`;
    },
    trialString() {
      if (this.isBranchReferrer) {
        return this.branchTrial;
      }
      return ' + 7 days free';
    },
  },
  mounted() {
    if (this.on_mounted) {
      this.on_mounted();
    }
    this.checkForBranchQueryParam();
  },
  methods: {
    checkForBranchQueryParam() {
      const urlParams = new URLSearchParams(window.location.search);
      const branchParam = urlParams.get('_branch_match_id');
      this.isBranchReferrer = !!this.$route.query._branch_match_id || !!branchParam; // eslint-disable-line
    },
    ctaClickHandler() {
      const { analytic_events } = this.$store.getters.globalConfig;
      if (this.promo_variant && analytic_events[this.promo_variant]) {
        this.$analytics.track(analytic_events[this.promo_variant]);
      }
      if (this.on_btn_click) {
        return this.on_btn_click();
      }
      return this.handleCTARouting();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./PromoContent.scss";
</style>
