<template>
  <el-dialog
    :visible.sync="display_modal"
    :center="true"
    :show-close="false"
    :append-to-body="true"
    :custom-class="`promotion-upsell-modal promo-${promo_type}`"
    @close="onCloseModal">
    <div
      v-if="plansLoaded"
      :class="[
        'promotion-modal',
        { 'promo-2': promo_type === 2 },
        { 'promo-3': promo_type === 3 },
        { 'promo-4': promo_type === 4 },
      ]"
      data-test="promomodal">
      <img
        src="../assets/images/promo_modal_close_btn.svg"
        class="close-btn"
        @click="onCloseModal">
      <h2
        v-if="show_banner"
        class="banner-title">
        Limited-time
      </h2>
      <img
        v-if="show_banner"
        src="../assets/images/holiday_banner.svg"
        class="banner-image">
      <div class="promo-content">
        <h1>{{ header_copy }}</h1>
        <h2 v-if="promo_type === 4">{{ subheader_copy }}</h2>
        <promo-content
          :on_btn_click="handleClick"
          :button_color="promo_type === 4 ? 'blue' : 'black'"
          :promo_variant="promoVariant"
          :on_mounted="onModalOpened"
          button_variant="normal"/>
      </div>
    </div>
  </el-dialog>
</template>
<script>
import PromoContent from './holidaypromo/PromoContent';

export default {
  name: 'PromotionModal',
  components: {
    'promo-content': PromoContent,
  },
  props: {
    show_modal: {
      default: false,
      type: Boolean,
    },
    promo_type: {
      default: 2,
      type: Number,
    },
    on_close: {
      default: () => null,
      type: Function,
    },
    click_handler: {
      default: null,
      type: Function,
    },
  },
  data() {
    return {
      subheader_copy: 'Limited-time special offer',
      closed: false,
    };
  },
  computed: {
    display_modal: {
      get() {
        return this.show_modal;
      },
      set() {

      },
    },
    show_banner() {
      return this.promo_type === 2;
    },
    plansLoaded() {
      return this.$store.getters.plansLoaded;
    },
    header_copy() {
      let copy = 'Special offer';
      if (this.promo_type === 4) {
        copy = 'This is a Luminary Premium Show';
      }
      return copy;
    },
    promoVariant() {
      const promoTypeSuffix = [null, null, 'Two', 'Three', 'Four'];
      return `promo${promoTypeSuffix[this.promo_type]}`;
    },
  },
  methods: {
    onModalOpened() {
      const ctx = this;
      setTimeout(() => {
        ctx.$analytics.track(`${this.promoVariant || 'promo'}_modal_viewed`);
      }, 200);
    },
    onCloseModal() {
      this.$emit('hidePremiumModal');
      this.$emit('onHolidayPromoClose');
      this.$emit('closePremiumUpsellModal');
    },
    defaultRouting() {
      const { user } = this.$store.getters;
      const isAnonUser = !user;
      const isFreeUser = user && user.uuid && !user.is_subscribed;

      let routeTo = { path: '/premium' };

      if (this.promo_type === 2) {
        if (isAnonUser) {
          routeTo = { path: '/user-categories' };
        }
        if (isFreeUser) {
          routeTo = { path: '/premium-buy' };
        }
      }

      if (this.promo_type === 3) {
        routeTo = { path: '/user-categories', query: { showPaymentForm: true } };
      }

      if (this.promo_type === 4) {
        if (isFreeUser) {
          routeTo = { path: '/premium-buy' };
        }
        if (isAnonUser) {
          routeTo = { path: '/user-categories', query: { showPaymentForm: true } };
        }
      }
      return this.$router.push(routeTo);
    },
    handleClick() {
      this.onCloseModal();
      if (this.click_handler) {
        return this.click_handler();
      }
      return this.defaultRouting();
    },
  },
};
</script>
<style lang="scss">
@import "../assets/scss/variables.scss";
.promotion-upsell-modal {
  overflow-x: hidden !important;
  width: 500px !important;
  height: 397px !important;
  top: 50%;
  transform: translateY(-50%);
  margin-top: 0px !important;
  background-color: transparent !important;
  box-shadow: transparent 0 0 !important;
  overflow-x: visible !important;
  .el-dialog__header{
    padding: 0px;
  }
  .el-dialog__body{
    padding: 0 0 0 15px !important;
    height: 100%;
    width: 100%;
  }
  .promotion-modal {
    color: black;
    position: relative;
    height: 100%;
    width: 100%;
    padding-bottom: 36px;
    background-color: white;
    &.promo-3 {
      background-color: $brand;
      padding-bottom: 0px;
      height: 341px;
      .promo-content {
        padding-top: 35px;
        h1 {
          margin-top: 0px;
        }
      }
    }
    &.promo-4 {
      margin: 0 auto;
      width: 100%;
      height: 438px;
      padding: 0 11px;
      .promo-content {
        padding-top: 29px;
        h1 {
          font-family: $clear-sans;
          font-weight: 600;
          font-size: 38px;
          line-height: 46px;
          letter-spacing: -1px;
          margin-top: 21px;
        }
        h2 {
          font-family: $clear-sans;
          font-size: 20px;
          line-height: 24px;
        }
      }
    }
    .promo-content {
      padding-top: 60px;
    }
    .close-btn {
      position: absolute;
      right: 0px;
      margin: 20px;
    }
    .banner-image {
      width: 250px;
      position: absolute;
      left: -13.4px;
      margin: 0;
      padding: 0;
    }
    .banner-title {
      font-family: $clear-sans;
      font-size: 24px;
      line-height: 29px;
      letter-spacing: -1px;
      color: #000000;
      display: block;
      position: absolute;
      z-index: 1;
      left: 48px;
      margin-top: 23px;
    }
    .promo-content {
      text-align: center;
      h1 {
        font-family: $clear-sans;
        text-align: center;
        font-size: 40px;
        line-height: 48px;
        margin-top: 30px;
        margin-bottom: 18px;
      }
    }
  }
}

@media (max-width: 576px) {
  .promotion-upsell-modal {
    width: 100% !important;
    margin-top: 0 !important;
    background-color: transparent !important;
    .el-dialog__body{
      padding: 0 !important;
    }
    .promotion-modal {
      background-color: white;
      width: 308px;
      height: 382px;
      margin: 0 auto;
    }
  }
}

@media (max-width: 768px) {
  .promotion-upsell-modal {
      .el-dialog__body {
        padding: 0 !important;
      }
      .promotion-modal {
        &.promo-3 {
          height: 333px;
          padding: 0 11px;
          width: 402px;
          margin: 0 auto;
        }
      }
  }
}
@media (max-width: 576px) {
  .promotion-upsell-modal {
      &.promo-3 {
        width: 327px !important;
        height: 317px !important;
      }
      &.promo-4 {
        width: 355px !important;
        height: 438px !important;
      }
      .promotion-modal {
        &.promo-3 {
          margin: 0 auto;
          width: 100%;
          height: 333px;
          padding: 0 11px;
          .promo-content {
            padding-top: 29px;
          }
        }
      }
  }
}
</style>
