<template>
  <div class="homepage-feature">
    <div class="container feature">
      <div class="content">
        <h1 class="feature-blackstar">black star <abbr id="star-of-life">*</abbr><br>no fear of time</h1>
        <h2 class="feature-blackstar">Album  ·  Only on Luminary</h2>
        <div class="feature-blackstar info-block">
          <img src="../../../assets/images/web2/home/blackstar-ka.png" alt="Black Star No Fear of Time"/>
          <div class="info">
            <p><span>Now Available</span><br>yasiin bey and Talib Kweli are <b>Black Star</b>, and back with their first album in 24 years.</p>
          </div>
        </div>
        <div class="feature-blackstar cta-block" @click="onClickCTA">Listen Now</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    onClickCTA() {
      this.$analytics.track('Home Blackstar Click', {
        screen_name: this.$route.name,
        path: this.$route.path,
        btnId: 'home_blackstar_click',
      });
      window.location.href = 'https://luminary.link/rYHEBS8uJpb';
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../assets/scss/variables.scss";

/* Featured Banner */
.homepage-feature {
  background-image: url(../../../assets/images/web2/home/blackstar/Featured-Luminary-homepage-1600x656x2.png),linear-gradient(180deg,#171919 0%,#171919 100%)!important;
  background-size: auto 100%;
  content: "";
  top: 0;
  background-repeat: no-repeat;
  background-position: 100%;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  opacity: 1;
  padding-top:72px;
  border-bottom: 0.5px solid rgba(252, 252, 252, .2)!important;
}

.homepage-feature .container {
  height:557px!important;
}

.feature-blackstar {

}

h1.feature-blackstar {
  font-size: 3.8125rem!important;
  color: #ffffff;
  line-height: 4rem!important;
  font-family: brandon-grotesque, sans-serif!important;
  font-weight: 400!important;
  font-style: normal!important;
  padding: 0!important;
  margin: 0!important;
}

h2.feature-blackstar {
  color: #FFE500;
  font-size: 1.25rem;
  line-height: 1.25rem;
  font-family: brandon-grotesque, sans-serif!important;
  font-weight: 400!important;
  font-style: normal!important;
  margin-top:30px;
}

#star-of-life{
  font-size: 5rem;
  line-height: 1rem;
  position: relative;
  top: 1rem;
  vertical-align: sub;
}

.feature-blackstar.info-block {
  margin: 0 auto;
  width: auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  flex-flow: row nowrap;
  padding: 10px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: left;
  -ms-flex-pack: left;
  justify-content: left;
  background-color: rgba(242, 241, 214, 0.3) !important;
  border-radius: 15px 15px 0px 0px;
  opacity: 1;
  backdrop-filter: blur(30px);
  -webkit-backdrop-filter: blur(30px);
  transition: background-color 0.5s cubic-bezier(0.28, 0.11, 0.32, 1);
  margin-top:40px;
}

.feature-blackstar.cta-block {
  margin: 0 auto;
  width: auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-flow: row nowrap;
  flex-flow: row nowrap;
  padding: 18px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: left;
  -ms-flex-pack: left;
  justify-content: left;
  background-color: #FFE52C !important;
  border-radius: 0px 0px 15px 15px;
  font-weight: 700!important;
  flex-flow: column-reverse;
  font-size: 16px;
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  transition: all 0.2s linear;
  cursor:pointer;
}

.feature-blackstar.cta-block:hover {
  background-color: #D3B900 !important;
  cursor: pointer;
}

.info-block img { width: 105px; height: auto; border-radius: 10px; }

.info-block p {
  font-size: 1rem!important;
  color: #fff!important;
  padding:10px;
  line-height:1.5rem!important;
}

.info-block p span {
  color: #FFE52C;
  font-size: 0.75rem;
  text-transform: uppercase;
  font-weight: 700!important;
}

.container {
  max-width: 1600px;
  margin: 0 auto;
  width: auto;
  display: flex;
  flex-flow: row nowrap;
  padding-left: 87px;
  align-items: center;
  justify-content: left;
  height: 467px;
  @media (max-width: 1410px){
    height: 420px;
  }
  @media (max-width: 1300px){
    height: 380px;
  }
  @media (max-width: $lg) {
    height: 380px;
  }
  @media (max-width: 1079px){
    height: 380px;
  }
  @media (max-width: $md) {
    height: 323px;
  }
  @media (max-width: $sm) {
    padding-left: 55px;
  }
  @media (max-width: $xs) {
    padding-left: 0;
  }
}

.content {
  opacity: 1;
  position: relative;
  z-index: 2;
  flex: 0 0 420px;
  @media (max-width: $md) {
    flex: 0 0 312px;
  }
  @media (max-width: $sm) {
    flex: 0 0 270px;
  }
  @media (max-width: $xs) {
    flex: 1 1 auto;
  }
}

/* Mobile Styles */
@media (max-width: 768px) {
  .homepage-feature {
    background-image: url(../../../assets/images/web2/home/blackstar/Featured-Luminary-homepage-Mobile-678x278x2.png),linear-gradient(180deg,#171919 0%,#171919 100%)!important;
    background-repeat: no-repeat;
    background-position: center;
  }

  .feature .content {
    max-width: 320px;
    margin: 0 auto;
  }

  h2.feature-blackstar {
    font-size: 1.125rem;
    text-align: center;
    margin-top: 14px;
  }
  h1.feature-blackstar {
    font-size: 2.0625rem!important;
    line-height: 2.0625rem!important;
    margin: 0 auto!important;
    width: fit-content;
  }

  .container.feature {
    align-items: flex-end!important;
    margin-bottom: 30px!important;

  }

  #star-of-life {
    font-size: 3rem;
    top: 0.7rem;
  }
}
</style>
