<template>
  <PurchaseGift />
</template>

<script>
import PurchaseGift from '../../components/webv2/purchaseGift/PurchaseGift';

export default {
  name: 'GiftAYear',
  components: {
    PurchaseGift,
  },
  data() {
    return {
      metaTitle: 'Give the Gift of Luminary Original Podcasts',
      metaDescription: 'Gift an annual subscription to Luminary Original Podcasts. You pick the date and we’ll email your gift recipient with instructions on how to redeem.',
    };
  },
  beforeMount() {
    this.setMetaInformation(this.metaTitle, this.metaDescription);
  },
  destroyed() {
    this.setMetaInformation();
  },
};

</script>
