<template>
  <div class="homepage-feature">
    <div class="hero">
      <picture>
        <source
          media="(min-width:840px)"
          srcset="../../../assets/images/tmm/tmm-web-desk.webp"
          type="image/webp">
        <source srcset="../../../assets/images/tmm/tmm-web-mobile.webp" type="image/webp">
        <img src="../../../assets/images/tmm/tmm-web-desk.jpg" alt="midnight miracle background">
      </picture>
      <div class="cta-wrapper">
        <div class="heading">
          <div class="flex">
            <img src="../../../assets/images/tmm/TMM-Logo.svg" alt="A luminary original - the midnight miracle" />
            <div class="badges">
              <div class="badge">New Season</div>
            </div>
            <div class="description">
              Music. Conversation. Debate.<span class="desktop-break">&nbsp;</span>A new genre of audio like nothing you've ever heard.
              <div class="subtext">
                Available now, <span class="bold white">only on</span> <span class="bold yellow">Luminary.</span>
              </div>
            </div>
            <div class="flex-row">
              <div class="header-btn fill hide-mobile" @click="onClickCTA">Start Free Trial</div>
              <div class="header-btn header-btn-apple fill-apple hide-mobile" @click="onClickAppleCTA">Listen on Apple</div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>
<script>

export default {
  data() {
    return {
      scroll_listener: null,
    };
  },
  mounted() {
    this.scroll_listener = () => {
      const parallaxElement = this.$refs.scroll_element;
      window.requestAnimationFrame(() => {
        if (parallaxElement) {
          const windowTop = window.pageYOffset; // window.scrollTop();
          const elementTop = parallaxElement.getBoundingClientRect().top;
          const elementHeight = parallaxElement.getBoundingClientRect().height;
          const viewPortHeight = window.innerHeight * 0.5 - elementHeight * 0.5;
          const scrolled = windowTop - elementTop + viewPortHeight;
          parallaxElement.style = `transform: translate3d(0,${scrolled * -0.15}px, 0)`;
        }
      });
    };
    window.addEventListener('load', this.scroll_listener);
    window.addEventListener('scroll', this.scroll_listener);
  },
  destroyed() {
    if (this.scroll_listener) {
      window.removeEventListener(this.scroll_listener);
      window.removeEventListener(this.scroll_listener);
    }
  },
  methods: {
    onClickCTA() {
      this.$analytics.track('Home Page Start Click', {
        screen_name: this.$route.name,
        path: this.$route.path,
        btnId: 'home_start_click',
      });
      this.$analyticsV2.logEvent('cf_button_clicked', {
        is_cta: true,
        target_url: '/signup/plan?banner=top',
        button_text: 'Start Free Trial',
      });
      if (this.isInternationalUser) {
        this.$router.push('/unsupported');
        return;
      }
      window.location.href = '/signup/plan?banner=top';
    },
    onClickAppleCTA() {
      this.$analyticsV2.logEvent('cf_button_clicked', {
        is_cta: true,
        target_url: 'https://apple.co/luminary',
        button_text: 'Listen on Apple',
      });

      window.location.href = 'https://apple.co/luminary';
    },
  },
};

</script>


<style lang="scss" scoped>
@import "../../../assets/scss/variables.scss";
$vertical-shift: 0px;

/* Featured Banner */
.homepage-feature {
  .hero {
    position: relative;
    min-height: 400px;

    img,
    source {
      object-fit: contain;
      width: 100%;
      max-height: 640px;
    }


    .cta-wrapper {
      position: absolute;
      width: 100%;
      top: 100px;
      margin: 0 auto;

      .heading {
        margin: 0 auto;
        width: 92%;
        max-width: 1322px;

        .flex {
          display: flex;
          justify-content: flex-start;
          flex-direction: column;

          img {
            width: 35%;
          }

          .badges {
            display: flex;
            margin-top: 40px;
            .badge {
                background: #ffe500;
                color: #121c30;
                padding: 3px 6px 3px 7px;
                border-radius: 3px;
                font-family: $patron;
                font-weight: 500;
                font-size: 10px;
                letter-spacing: 2px;
                text-transform: uppercase;
              }
          }

          .description {
            margin-top: 5px;
            color: rgba(255,255,255,.69);
            font-size: 16px;

            .desktop-break {
              line-height: 0;
              display: block;
            }
            .subtext {
              margin-top: 10px;
              .bold {
                font-weight: 700;
              }

              .white {
                color: $white;
              }

              .yellow {
                color: $brand;
              }
            }
          }
        }
      }
    }

    .flex-row {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
    }

    @media(min-width: 576px) and (max-width: 840px) {
      .cta-wrapper {
        max-width: 500px;
      }
    }

    @media(min-width: 516px) and (max-width: 840px) {
      background: transparent url(../../../assets/images/tmm/tmm-web-mobile-wide.webp) center top no-repeat;
      background-size: contain;

      picture img {
        opacity: 0;
      }
    }

    @media(max-width: 840px) {
      .cta-wrapper {
        position: relative;
        height: 0;
        width: 90%;

        img {
          display: none;
        }

        .flex {
          position: absolute;
          top: -280px;
          .description {
              font-size: 15px !important;
              .desktop-break {
                display: inline-block !important;
              }
            }
        }


      }
    }
  }


}

.homepage-feature .container {
  display: none;
}


.container {
  max-width: 1600px;
  margin: 0 auto;
  width: auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-flow: row nowrap;
  flex-flow: row nowrap;
  padding-left: 87px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: left;
  -ms-flex-pack: left;
  justify-content: left;
  height: 467px;
}

h1.feature-rebrand {
  font-size: 4.875rem!important;
  color: #ffffff;
  line-height: 5.875rem!important;
  font-family: patron, sans-serif!important;
  font-weight: 700!important;
  font-style: normal!important;
  padding: 0!important;
  margin: 0!important;
  //z-index: 10;
}

.feature .content {
  flex: 0 0 670px!important;
}

h2.feature-rebrand {
  color: #ffffff;
  font-size: 1rem;
  line-height: 1.875rem;
  font-family: patron, sans-serif!important;
  font-weight: 400!important;
  font-style: normal!important;
  margin-top: 30px;
  max-width: 420px!important;
  opacity: 0.7;
}

.header-btn {
  font-size:1rem;
  color: #121C30;
  background: $brand;
  border-radius: 15px;
  padding: 14px 60px;
  width: fit-content;
  margin-top: 20px;
  font-weight: 700;
  position: relative;
  overflow:hidden;
  z-index: 1;
  cursor: pointer;
  transition:         0.1s ease-in;
  -o-transition:      0.1s ease-in;
  -ms-transition:     0.1s ease-in;
  -moz-transition:    0.1s ease-in;
  -webkit-transition: 0.1s ease-in;
}

.header-btn-apple {
  color: #ffffff;
  margin-left: 10px;
}

.fill-apple {
  background-color: #852dc4;

  &:hover {
    opacity: .93;
  }
}

.fill:hover {
  background-color: rgba(255,229,0, .84);
}

.mobile-cta-container {
  height: auto;
  width: 100vw;
  bottom: 0;
  position: fixed;
  background-color: rgba(18, 28, 48, 0.4) !important;
  backdrop-filter: blur(30px);
  -webkit-backdrop-filter: blur(30px);
  z-index: 1000;
  border-top: 1px solid rgba(255,255,255,0.1);
}

/* Mobile Styles */
@media (max-width: 1080px) {

  .hide-mobile { display: none; }
  .hide-desktop { display: block; }
  .home-page { padding-bottom: 150px; }

  .header-btn {
    margin: 0 auto;
    width: initial;
    text-align: center;
    margin-bottom: 30px;
    margin-left: 20px;
    margin-right: 20px;
  }

  .container {
    padding-left:0!important;
  }

  .homepage-feature .container{
    height:300px!important;
  }

  .homepage-feature .content {
    //position: absolute;
    top: 20vh;
  }

  .feature .content {
    max-width: 320px;
    margin: 0 auto;
    top:0;
    flex:none;
  }

  .content {
    opacity: 1;
    position: relative;
    z-index: 2;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 420px;
    flex: 0 0 420px;
    padding: 0 20px!important;
  }

  h2.feature-rebrand {
    font-size: .875rem;
    line-height: 1.5rem;
    margin-top: 14px;
  }

  h1.feature-rebrand {
    font-size: 2.25rem!important;
    line-height: 2.5rem!important;
    margin: 0 auto!important;
    width: fit-content;
  }

  .container.feature {
    align-items: flex-end!important;
    margin-bottom: 30px!important;
  }
}
</style>
