<template>
  <div class="redeem-signup">
    <div class="container">
      <el-row>
        <grid-col
          :xs="{ offset: 1, span: 22 }"
          :sm="{ offset: 1, span: 22 }"
          :md="{ offset: 0, span: 10 }"
          :lg="{ offset: 0, span: 11 }"
          :xl="{ offset: 0, span: 11 }">
          <div class="redeem-content">
            <h1 class="title">{{ title }}</h1>
            <p class="copy">
              {{ copy }}
              <span class="email">{{ userEmail }}</span>
            </p>
            <el-form
              ref="redeemCode"
              :model="redeemCode"
              :rules="rules"
              class="redeem-form"
              @keyup.enter.native="onSubmit">
              <el-form-item
                ref="code"
                prop="code"
                label="Gift Code">
                <el-input
                  v-model="redeemCode.code"
                  placeholder="XXXXXX" />
              </el-form-item>
              <el-button
                v-button-event
                v-loading="loading"
                type="primary"
                class="block-btn"
                @click="onSubmit">Redeem My Subscription</el-button>
            </el-form>
          </div>
        </grid-col>
        <grid-col
          :xs="22"
          :sm="22"
          :md="{ offset: 3, span: 8 }"
          :lg="{ offset: 4, span: 9 }"
          :xl="{ offset: 4, span: 9 }"
          class="tile-col">
          <div class="tile-animation">
            <TileAnimation />
          </div>
        </grid-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import TileAnimation from '../../TileAnimation';
import GiftService from '../../../services/Gift';

export default {
  components: {
    TileAnimation,
  },
  data() {
    const codeValidator = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('Code Required'));
      } else {
        callback();
      }
    };
    return {
      gift_service: new GiftService(),
      title: 'Enter the gift code found in your gift email',
      copy: "We'll apply it to your Luminary account:",
      loading: false,
      redeemCode: {
        code: '',
      },
      rules: {
        code: [{ validator: codeValidator, trigger: 'blur' }],
      },
    };
  },
  computed: {
    userEmail() {
      return this.$store.getters.user.email;
    },
  },
  methods: {
    onSubmit() {
      if (this.loading) return;
      this.$refs.redeemCode.validate((valid) => {
        if (valid) {
          this.redeemSubscription();
        }
      });
    },
    redeemSubscription() {
      this.loading = true;
      this.gift_service.redeemGift(this.redeemCode.code)
        .then(() => {
          this.handleRedeemGiftSuccess();
        })
        .catch((error) => {
          this.handleRedeemGiftError(error);
        });
    },
    handleRedeemGiftSuccess() {
      this.showSuccessMsg('Gift redeemed successfully');
      this.loading = false;
      this.$router.push('/download-the-app');
    },
    handleRedeemGiftError(error) {
      this.showErrorMsg(error.status === 404 ? 'Not a valid gift code' : error.error);
      this.loading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../assets/scss/variables.scss";

.redeem-signup {
  min-height: 100vh;
  width: 100%;
  background-color: $bg-color;
  background-image: url(../../../assets/images/web2/gifting/bg-gifting-hero-mobile.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: top center;
  @media (min-width: $sm) {
    background-image: none;
  }
}

.redeem-content {
  margin-top: 34px;
  @media (min-width: $sm) {
    margin-top: 136px;
  }
}

.title {
  color: $white;
  font-family: $serif;
  margin-bottom: 30px;
  font-size: 32px;
  line-height: 37px;
}

.copy {
  color: $light-gray;
  margin-bottom: 30px;
}

.email {
  color: $white;
}

.redeem-form {
  max-width: 320px;

  /deep/ .el-form-item__label {
    color: $white;
  }
}

.tile-col {
  display: none;
  @media (min-width: $sm) {
    display: block;
  }
}

.tile-animation {
  height: 100vh;
}

</style>
