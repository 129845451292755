<template>
  <el-form
    ref="user"
    :model="user"
    :rules="rules"
    :status-icon="true"
    class="sign-up-email overflow-icon fade"
    @keyup.enter.native="onSubmit">
    <el-form-item
      ref="userEmail"
      prop="email"
      label="Email Address">
      <el-input
        ref="email"
        v-model="user.email"
        label="Email address"
        placeholder="user@email.com"
        data-test="input-email"
        @keyup.native="checkValidation"
      />
      <div
        v-if="emailSuggestion && user.email.length"
        class="el-form-item__error email-suggestion">
        <span>
          Did you mean
          <a
            v-button-event
            @click="setEmail">
            {{ emailSuggestion }}
          </a>
          ?
        </span>
      </div>
    </el-form-item>
    <el-form-item
      ref="repeatEmail"
      prop="repeat_email"
      label="Confirm Email">
      <el-input
        v-model="user.repeat_email"
        label="Confirm Email"
        placeholder="Confirm email"
        data-test="input-repeat-email"
        @keyup.native="checkValidation"
        @paste.native="event => event.preventDefault()"
      />
    </el-form-item>
    <el-form-item
      prop="password"
      label="Create a password">
      <el-input
        v-model="user.password"
        :type="show_pass ? 'text' : 'password'"
        label="Password"
        placeholder="8+ characters, including 1 number"
        data-test="input-password"
        class="password"
        @keyup.native="checkValidation"/>
      <show-password @show="(e) => {show_pass = e}"/>
    </el-form-item>
    <div class="consent">
      <p>By clicking below, you agree to our
        <router-link
          v-button-event
          target="_blank"
          to="/terms"
          class="btn-link btn-link_blue"
          data-test="link-termservice"
          aria-label="Terms of service"
        >
          Terms of Service
        </router-link> and
        <router-link
          v-button-event
          to="/privacy"
          target="_blank"
          class="btn-link btn-link_blue"
          data-test="link-privacypolicy"
          aria-label="Privacy policy"
        >
          Privacy Policy
        </router-link>
      </p>
    </div>
    <div class="t-c action-buttons">
      <el-button
        v-button-event
        v-loading="loading || !isReady"
        :disabled="disableSubmit"
        type="primary"
        class="block-btn spotlight btn-brand"
        data-test="btn-signup"
        @click="onSubmit">Continue</el-button>
    </div>
    <el-form-item v-if="isMarketingConsentEnaled">
      <el-row>
        <el-col :span="2">
          <el-checkbox
            v-model="user.marketing_consent"
            data-test="checkbox"/>
        </el-col>
        <el-col :span="22">
          <p>By checking this box you agree to receive updates and announcements about Luminary and our Luminary Original podcasts.</p>
        </el-col>
      </el-row>
    </el-form-item>
  </el-form>
</template>
<script>
import * as EmailValidator from 'email-validator';
import * as mailcheck from 'mailcheck';
import User from '../../services/User';
import PageMixin from '../../shared/pageMixin';
import { getServices } from '@/services/serviceProvider';

const [userProvider] = getServices([User]);

export default {
  name: 'SignUpEmail',
  components: {
    'show-password': () => import('../../components/ShowPassword'),
  },
  mixins: [PageMixin],
  props: {
    acquisitionFlowStyle: {
      type: Boolean,
      default: () => false,
    },
    isRedeemQuery: {
      type: String,
      default: '',
    },
    isMarketingConsentEnaled: {
      type: Boolean,
      default: () => true,
    },
    isReady: {
      type: Boolean,
      default: () => true,
    },
  },
  data() {
    const passwordStrength = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('Password Required'));
      } else {
        const strongRegex = new RegExp('^(?=.*[a-zA-Z])(?=.*[0-9])(?=.{8,})');

        if (strongRegex.test(value)) {
          callback();
        } else {
          callback(new Error('Weak Password Strength (8+ characters, including 1 number)'));
        }
      }
    };
    const emailValidator = (rule, value, callback) => {
      this.emailSuggestion = undefined;
      if (value === '') {
        callback(new Error('Email Required'));
      } else {
        if (value.indexOf('@') === -1) {
          callback(new Error('The email address must include @'));
          return;
        }
        if (!EmailValidator.validate(value)) {
          callback(new Error('Invalid Email Format'));
          return;
        }
        if (this.user.repeat_email !== '') {
          this.$refs.user.validateField('repeat_email');
        }
        const self = this;
        mailcheck.run({
          email: self.user.email,
          suggested: (suggestion) => {
            self.emailSuggestion = suggestion.full;
            self.$refs.userEmail.$el.classList.remove('is-success');
            self.$refs.userEmail.$el.classList.remove('is-error');
            self.$refs.userEmail.$el.classList.add('is-info');
          },
          empty: () => {
            /* nothing wrong with the email */
            self.$refs.userEmail.$el.classList.remove('is-info');
            self.$refs.userEmail.$el.classList.add('is-success');
          },
        });
        callback();
      }
    };
    const repeatEmailValidator = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('Email Confirmation Required'));
      } else if (value !== this.user.email) {
        callback(new Error('Emails don\'t match'));
        return;
      }
      callback();
    };
    return {
      pageName: 'Create Account',
      user: {
        email: '',
        repeat_email: '',
        password: '',
        marketing_consent: true,
      },
      rules: {
        email: [{ required: true, validator: emailValidator, trigger: 'change' }],
        repeat_email: [{ required: true, validator: repeatEmailValidator, trigger: 'change' }],
        password: [{ required: true, validator: passwordStrength, trigger: 'blur' }],
      },
      loading: false,
      form_valid: false,
      show_pass: false,
      emailSuggestion: undefined,
      loginFlow: false,
    };
  },
  computed: {
    disableSubmit() {
      return !this.form_valid || !this.user.email.length || !this.user.password.length || this.user.password.length <= 7 || this.loading || !this.isReady;
    },
  },
  mounted() {
    this.$refs.email.focus();
  },
  methods: {
    onSubmit() {
      this.$analytics.track('Signup');
      if (this.loading) return;
      const ctx = this;
      this.$refs.user.validate((valid, data) => {
        if (valid) {
          ctx.registerUser();
        } else {
          this.$emit('signupInvalid', data);
        }
      });
    },
    registerUser() {
      this.loading = true;
      this.user.username = this.user.email;
      userProvider.register(this.user).then(() => {
        this.$store.commit('setUser', this.user);
        this.$store.commit('setSignupCompleted', true);
        localStorage.setItem('login_type', 'Email');
        this.$emit('success', this.user.marketing_consent);
      }).catch((error) => {
        this.$emit('signupInvalid', error);
        this.showErrorMsg(error);
      }).finally(() => {
        this.loading = false;
      });
    },
    checkValidation() {
      if (this.user.password.length > 7) {
        this.$refs.user.validate((valid) => { this.form_valid = valid; });
      }
    },
    setEmail() {
      this.user.email = this.emailSuggestion;
      this.emailSuggestion = undefined;
    },
  },
};
</script>

<style lang="scss">
@import "../../assets/scss/variables.scss";
.sign-up-email {
  h4 {
    margin: 64px 0 32px 0;
    @media (max-width: $xs) {
      margin: 32px 0 8px 0;
    }
  }
  .el-checkbox__input.is-checked+.el-checkbox__label {
    color: $black !important;
  }
  .el-checkbox__inner {
    width: 18px;
    height: 18px;
    &::after {
      width: 6px;
      height: 8px;
    }
  }
  .consent {
    margin-top: 10px;
    text-align: center;
    .bold {
      color: black;
    }
  }
  .action-buttons {
    margin-top: 24px;
    margin-bottom: 24px;
  }
  .el-form-item.is-info {
    .el-input__inner {
      border-color: #ED6D00;
    }
  }
  .email-suggestion {
    color: #ED6D00;
    a {
      color: #000;
      cursor: pointer !important;
      text-decoration: underline;
    }
  }
}

.sign-up-email {
  @media(max-width: $sm) {
    max-width: 90vw !important;
  }
  .btn-link {
    color: $blue !important;
  }
  p {
    color: #666666;
    font-size: 14px;
  }
  .account {
    margin-top: 36px;
    p {
      color: white;
    }
  }
  h4 {
    margin: 64px 0 32px 0;
    @media (max-width: $xs) {
      margin: 32px 0 8px 0;
    }
  }
  .el-checkbox__input.is-checked+.el-checkbox__label {
    color: $black !important;
  }
  .el-form-item__error {
    color: $gray;
  }
  .el-checkbox__inner {
    width: 18px;
    height: 18px;
    &::after {
      width: 6px;
      height: 8px;
    }
  }
  .consent {
    margin-top: 10px;
    text-align: center;
    font-family: $clear-sans;
    color: $white;
    .bold {
      color: $white;
    }
    p {
      font-family: $clear-sans;
      color: #666666;
      font-size: 14px;
    }
  }
  .action-buttons {
    margin-top: 24px;
    margin-bottom: 24px;
  }
  .auth-page {
    max-width: 414px;
    width: 100%;
    margin: auto;
    margin-bottom: 128px;
  }
  .el-form-item {
    .el-form-item__label {
      font-size: 16px;
      line-height: 28px;
      color: $white;
      font-family: $clear-sans;
      &:before {
        content: none !important;
      }
    }
    .el-form-item {
      margin-bottom: 0px !important;
    }
    .el-input,
    .el-select {
      font-size: 16px;
      width: 100%;
    }
    .el-input__inner {
      height: 48px;
      line-height: 48px;
      border-radius: 8px;
      color: $black;
    }
    .helper-text,
    .error-msg {
      font-size: 13px;
      line-height: 18px;
      color: $gray;
    }
    .error-msg {
      color: #f56c6c;
    }
    a {
      cursor: pointer;
      text-decoration: underline;
    }
  }
  .el-form-item.no-margin {
    margin-bottom: 0;
  }
  .el-form-item.is-error {
    .helper-text {
      color: #f56c6c;
    }
  }
  .sub-header {
    color: $black;
    margin-bottom: 40px;
  }
  .action-buttons {
    .el-button.is-disabled,
    .el-button.is-disabled:hover {
      background-color: #AFE7F1;
      border-color: #AFE7F1;
    }
  }
  .sm-auth {
    margin: 30px 0;
    &__button {
      width: 299px;
      color: white;
      background-color: white;
      font-size: 16px;
      font-weight: 500;
      line-height: 20px;
      border-radius: 30px;
      display: block;
      padding: 16px 0;
      min-width: 300px;
      position: relative;
      letter-spacing: 0.5px;
      cursor: pointer;
      text-decoration: none;
      border: 1px solid #F3F3F3;
      box-sizing: border-box;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      color: $black;
      text-align: left;
      &--icon {
        color: #FFFFFF;
        font-weight: bold;
        position: absolute;
        left: 30px;
      }
      &--text {
        margin-left: 70px;
      }
    }
  }
  .apple {
    animation: slide-in .7s forwards;
  }
  .fb {
    animation: slide-in 1s forwards;
  }
  .google {
    animation: slide-in 1.3s forwards;
  }
  .email {
    animation: slide-in 1.6s forwards;
  }
  .fade {
    animation: 1s ease 0s normal forwards 1 fadein;
  }
  .sm-instructions {
    margin: 40px auto;
    display: block;
    text-align: center;
    height: 1px;
    background-color: #DDDFE6;
    position: relative;
    p {
      position: absolute;
      left: 50%;
      margin-top: -10px;
      transform: translateX(-50%);
      background: white;
      padding: 0 10px;
      font-size: 16px;
      color: black;
    }
  }
}

@-webkit-keyframes slide-in {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0%);
  }
}

@-webkit-keyframes fadein {
  0% {
    opacity: 0;
  }
  40% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}


</style>
