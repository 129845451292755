<template>
  <div
    :class="`picture-component${showPlaceholder ? ' has-placeholder' : ''}`"
  >
    <div
      v-if="shouldShowLoadingIndicator"
      :class="{'loaded': imgLoaded}"
      class="picture-component__loading-overlay">
      <div class="picture-component__loading-indicator">
        <div />
        <div />
        <div />
      </div>
    </div>

    <img
      v-lazy="{
        src: srcUrl,
        error: placeholder,
      }"
      :class="[{'fade-in': progressiveLoad}, {'loaded': imgLoaded && progressiveLoad}]"
      :srcset="srcsetUrl"
      :src="srcUrl"
      :alt="altText"
      data-test="picture-component-img"
      @load="onImgLoaded">
  </div>
</template>
<script>
import placeholder from '../assets/images/placeholder.png';

export default {
  name: 'PictureComponent',
  props: {
    src: {
      type: String,
      default: undefined,
    },
    dimensions: {
      type: String,
      default: undefined,
    },
    altInfo: {
      type: String,
      default: undefined,
    },
    mediaSize: {
      type: String,
      default: 'thumbnail',
    },
    cover: {
      type: Boolean,
      default: true,
    },
    progressiveLoad: {
      type: Boolean,
      default: false,
    },
    isLocal: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      placeholder,
      wideDefaultDimensions: 'w=348&h=217',
      dimensionsOverride: undefined,
      imgLoaded: false,
    };
  },
  computed: {
    shouldShowLoadingIndicator() {
      return this.progressiveLoad && !this.showPlaceholder && !this.imgLoaded;
    },
    hasDimensionsOverride() {
      return typeof this.dimensionsOverride !== 'undefined';
    },
    showPlaceholder() {
      return !this.src;
    },
    imgUrl() {
      let a;
      if (this.showPlaceholder) return this.placeholder;
      if (this.src.includes('https') || this.isLocal) a = this.src;
      else if (this.src.includes('http')) a = this.src.replace('http', 'https');
      else if (!this.src.includes('https') && !this.isLocal) a = `https://${this.src}`;
      return a;
    },
    altText() {
      let alt;
      if (this.altInfo) {
        alt = this.altInfo;
      }
      return alt;
    },
    srcUrl() {
      let url = this.imgUrl;

      if (this.hasDimensionsOverride && !this.showPlaceholder) {
        url += `?${this.dimensionsOverride}`;
      }

      return url;
    },
    srcsetUrl() {
      let url;
      if (this.showPlaceholder) return this.placeholder;
      if (this.isLocal) return this.imgUrl;
      // Turning off srcset with dpr2 and dpr3 to stop loading large preview images
      // if (this.hasDimensionsOverride) {
      //   url = `${this.imgUrl}?dpr=2&${this.dimensionsOverride} 2x,
      //   ${this.imgUrl}?dpr=3&${this.dimensionsOverride} 3x`;
      // } else {
      //   url = `${this.imgUrl}?dpr=2 2x, ${this.imgUrl}?dpr=3 3x`;
      // }
      return url;
    },
  },
  mounted() {
    this.dimensionsOverride = this.dimensions;

    // set all wide image url dimensions to be largest displayed
    // unless explicitly overridden
    if (this.mediaSize === 'wide' && !this.hasDimensionsOverride) {
      this.dimensionsOverride = this.wideDefaultDimensions;
    }
  },
  methods: {
    onImgLoaded() {
      this.imgLoaded = true;
      this.$emit('imageLoaded');
    },
  },
};
</script>
<style lang="scss">
@import "../assets/scss/variables.scss";

.picture-component {
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;

  img {
    width: 100%;
    height: 100%;
    min-height: inherit;
    object-fit: contain;

    &.fade-in {
      opacity: 0;
      transition: 350ms ease-in;
    }

    &.loaded {
      opacity: 1;
    }
  }

  &__loading-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #d4d4d4;

    /*
      Flash of unstyled content styles set on individual
      component level. The following are general resets
     */
    @media (max-width: 767px) {
      position: relative;
      &.loaded {
        min-height: unset;
        min-width: unset;
      }
    }
  }

  &__loading-indicator {
    position: absolute;
    width: 50%;
    top: 50%;
    left: 50%;
    display: flex;
    justify-content: center;
    transform: translateX(-50%) translateY(-50%);

    > div {
      width: 13px;
      height: 13px;
      border-radius: 50%;
      display: inline-block;
      background-color: white;

      &:not(:last-child) {
        margin-right: 8px;
      }

      &:nth-child(1) {
        animation: pulsate 600ms 0ms ease-out infinite;
      }

      &:nth-child(2) {
        animation: pulsate 600ms 200ms ease-out infinite;
      }

      &:nth-child(3) {
        animation: pulsate 600ms 400ms ease-out infinite;
      }
    }
  }

  @keyframes pulsate {
    0% {
      transform: scale(1);
    }

    50% {
      transform: scale(1.25);
    }

    100% {
      transform: scale(1);
    }
  }
}
</style>
