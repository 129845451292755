<template>
  <div class="box-flex">
    <div
      v-for="(v, i) in boxes"
      :key="i"
      class="box pulse"
    />
  </div>
</template>

<script>
export default {
  name: 'LoadingItems',
  props: {
    numLoading: {
      type: Number,
      default: 8,
    },
  },
  computed: {
    boxes() {
      return new Array(this.numLoading);
    },
  },
};
</script>

<style scoped lang="scss">
@import "../../../assets/scss/variables.scss";

.box-flex {
  display: flex;
  margin-bottom: 60px;
  @media (max-width: 768px) {
    margin-bottom: 30px;
  }
  @media (max-width: $xs) {
    margin-bottom: 15px;
  }
  .box {
    height: 227px;
    width: 227px;
    min-width: 227px;
    margin-right: 15px;
    background-color: #343B4A;
    overflow: visible;
    @media (max-width: $md) {
      height: 200px;
      width: 200px;
      min-width: 200px;
    }
    @media (max-width: $sm) {
      height: 181px;
      width: 181px;
      min-width: 181px;
    }
    @media (max-width: 460px) {
      height: 115px;
      width: 115px;
      min-width: 115px;
    }
  }
  .pulse {
    $from: #343B4A;
    $to: scale-color($from, $lightness: -10%);
    background: linear-gradient(
      -90deg,
      #2B3345 0%,
      #2E3950 50%,
      #2B3345 100%
    );
    background-size: 400% 400%;
    animation: pulse 2s ease-in-out infinite;
    @keyframes pulse {
      0% {
        background-position: 0% 0%;
      }
      100% {
        background-position: -135% 0%;
      }
    }
  }
}
</style>
