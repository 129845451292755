import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters([
      'isHolidayPromoGiftEnabled',
    ]),
    giftPrice() {
      const { pricingPlans } = this.$store.getters;
      if (pricingPlans.length) {
        const planFound = pricingPlans.find(plan => plan.planTypeString === 'yearly');
        if (this.isHolidayPromoGiftEnabled && planFound.originalPlan) {
          return `<span class="old-price" style="text-decoration: line-through;">${planFound.originalPlan.displayPrice}</span> ${planFound.displayPrice}`;
        }
        return planFound.originalPlan ? planFound.originalPlan.displayPrice : planFound.displayPrice;
      }
      return '$34.99';
    },
  },
};
