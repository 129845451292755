<template>
  <div
    class="detail-breadcrumb"
    data-test="detail-breadcrumb">
    <el-breadcrumb
      v-if="trail.length"
      separator-class="el-icon-arrow-right"
    >
      <template v-for="item in trail">
        <el-breadcrumb-item
          v-if="item.link"
          :key="item.name"
          :to="{path: item.link}"
          data-test="name-category"
        >
          {{ item.name }}
        </el-breadcrumb-item>
        <el-breadcrumb-item
          v-if="!item.link"
          :key="item.name"
          data-test="name-show"
        >
          {{ item.name }}
        </el-breadcrumb-item>
      </template>
    </el-breadcrumb>
  </div>
</template>

<script>
export default {
  props: {
    trail: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style lang="scss">
@import "../assets/scss/variables.scss";

.detail-breadcrumb {
  .el-breadcrumb {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    text-transform: capitalize;

    font-size: 16px;
    color: $light-gray2;

    &__item {
      float: none;
      text-overflow: ellipsis;
    }

    .el-breadcrumb__inner {
      color: $medium-gray !important;

      &.is-link {
        color: $white !important;
        &:hover {
          color: $blue !important;
          text-decoration: underline;
        }
      }
    }
    @media (max-width: $sm) {
      font-size: 12px;
    }
  }
}
</style>
