<template>
  <el-button
    :class="[`button--${color}`, `button--${variant}`]"
    class="button"
    @click="on_click">
    <slot>Start Free Trial</slot>
  </el-button>
</template>

<script>
export default {
  name: 'Button',
  props: {
    variant: {
      default: 'normal',
      type: String,
      validator(value) {
        return ['normal', 'thin'].indexOf(value) !== -1;
      },
    },
    color: {
      default: 'blue',
      type: String,
      validator(value) {
        return ['blue', 'black'].indexOf(value) !== -1;
      },
    },
    on_click: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {};
  },


};
</script>

<style lang="scss" scoped>
@import './Button.scss';
</style>
