import axios from 'axios';
import qs from 'qs';

class AxiosAPI {
  constructor() {
    // `${process.env.LUMINARY_API_HOST}`
    this.base_url = '';
  }

  setDefaultHeader(name, value) {
    axios.defaults.headers.common[name] = value;
  }

  paramsSerializerWithArray(params) {
    return qs.stringify(params, { arrayFormat: 'repeat' });
  }

  getList(endpoint, params, addConfig = null) {
    const url = `${this.base_url}/${endpoint}`;
    let config = {};
    config.params = params;
    if (addConfig) config = { ...config, ...addConfig };
    return axios.get(url, config).then(resp => resp.data);
  }

  get(endpoint, params, isFullURL = false) {
    let url = `${this.base_url}/${endpoint}`;
    if (isFullURL) {
      url = endpoint;
    }
    const config = {};
    config.params = params;

    return axios.get(url, config).then(resp => resp.data);
  }

  getWithHeaders(endpoint, params, isFullURL = false) {
    let url = `${this.base_url}/${endpoint}`;
    if (isFullURL) {
      url = endpoint;
    }
    const config = {};
    config.params = params;

    return axios.get(url, config).then(resp => resp);
  }

  post(endpoint, data, queryParams) {
    const url = `${this.base_url}/${endpoint}`;
    const config = {};
    if (queryParams) {
      config.params = queryParams;
    }
    return axios.post(url, data, config).then(resp => resp.data);
  }

  postWithConfig(endpoint, data, config) {
    const url = `${this.base_url}/${endpoint}`;
    return axios.post(url, data, config).then(resp => resp.data);
  }

  put(endpoint, data) {
    const url = `${this.base_url}/${endpoint}`;
    const config = {};
    return axios.put(url, data, config).then(resp => resp.data);
  }

  delete(endpoint, params) {
    const url = `${this.base_url}/${endpoint}`;
    const config = {};
    config.params = params;
    return axios.delete(url, config).then(resp => resp.data);
  }
}

export default AxiosAPI;
