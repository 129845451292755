<template>
  <el-button
    type="primary"
    class="play-button"
    @click="handleClick">
    <img
      v-if="audioIcon === 'play'"
      src="../../../assets/images/ic_play_symbol_white.svg"
      alt="play">
    <img
      v-if="audioIcon === 'pause'"
      src="../../../assets/images/ic_pause_white_icon.svg"
      alt="replay">
    <img
      v-if="audioIcon === 'replay'"
      src="../../../assets/images/is_replay_white.svg"
      alt="pause">
    <i
      v-if="isLoading"
      class="el-icon-loading"
      alt="loading" />
    <span>Play {{ mediaTypeName }}</span>
  </el-button>
</template>

<script>
import Event from '../../../services/PlayerEvent';

export default {
  props: {
    episode: {
      type: Object,
      default() {
        return {};
      },
    },
    episodeLegacy: {
      type: Object,
      default() {
        return {};
      },
    },
    playlistType: {
      type: String,
      default: 'show',
    },
  },
  data() {
    return {
      episode_was_played: false,
      eventProvider: new Event(),
    };
  },
  computed: {
    mediaTypeName() {
      return this.episode && this.episode.mediaType === 'music' ? 'Track' : 'Episode';
    },
    episodeProgress() {
      const progress = this.$store.getters.episodeProgressByUuid(this.episode.id);

      if (progress) {
        return progress.progress_percent;
      }
      return 0;
    },
    audioIcon() {
      if (this.isLoading) {
        return null;
      }

      if (this.isPlaying) {
        return 'pause';
      }

      if (this.episodeListened && !this.isPlaying) {
        return 'replay';
      }

      return 'play';
    },
    isLoading() {
      const { status } = this.$store.getters;
      return status === 'loading' && this.$store.getters.episode && (this.$store.getters.episode.uuid === this.episode.id);
    },
    isPlaying() {
      const currentlyPlayingEpisode = this.$store.getters.episode;
      const playing = this.$store.getters.play;

      return playing && (currentlyPlayingEpisode && (currentlyPlayingEpisode.uuid === this.episode.id));
    },
    episodeListened() {
      if (this.episode) {
        const progress = this.$store.getters.episodeProgressByUuid(this.episode.id);

        if (progress) {
          return progress.progress_percent >= 95;
        }
        return false;
      }
      return false;
    },
  },
  methods: {
    handleClick() {
      if (this.audioIcon === 'play') {
        this.playEpisode();
      }
      if (this.audioIcon === 'pause') {
        this.pauseEpisode();
      }
      if (this.audioIcon === 'replay') {
        this.replayEpisode();
      }
    },
    playEpisode() {
      this.episode_was_played = false;
      this.$store.dispatch('setPlaylistType', this.playlistType);

      const currentEpisode = this.$store.getters.episode;
      if (currentEpisode.uuid === this.episode.id) {
        // this.loadMediaInPlayer(this.episode.mediaUrl, this.episode.isExclusive, this.episode.type);
        this.loadEpisodeInPlayer(this.episode);
        this.$store.dispatch('play');
        this.eventProvider.triggerEpisodePlayEvent(this.episodeLegacy, 'resume_episode');
      } else {
        // this.loadMediaInPlayer(this.episode.mediaUrl, this.episode.isExclusive, this.episode.type);
        this.loadEpisodeInPlayer(this.episode);
        if (currentEpisode && currentEpisode.uuid) {
          this.eventProvider.triggerEpisodePauseEvent(currentEpisode, 'episode_select');
        }
        this.$store.dispatch('setAndPlayEpisode', this.episodeLegacy);
        this.eventProvider.triggerEpisodePlayEvent(this.episodeLegacy, 'press_play');
      }
      this.$store.commit('setContinuousListeningActivePodcast', this.episode.showId);
    },
    pauseEpisode() {
      this.episode_was_played = false;
      this.$store.dispatch('pause');
      this.eventProvider.triggerEpisodePauseEvent(this.episodeLegacy, 'pause');
    },
    replayEpisode() {
      this.$store.dispatch('setProgress', 0);
      this.playEpisode();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../assets/scss/variables.scss";

.play-button {
  /deep/ > span {
    display: flex;
    align-items: center;
  }

  img {
    height: 18px;
    margin-right: 7px;
  }

  i {
    font-size: 22px;
  }
}
</style>
