<template>
  <div class="purchase-gift">
    <div class="container">
      <el-row>
        <grid-col
          :xs="24"
          :sm="24"
          :md="{ offset: 2, span: 20 }"
          :lg="{ offset: 4, span: 16 }"
          :xl="{ offset: 6, span: 12 }">
          <PurchaseHero />
          <PurchaseGiftAccordion />
        </grid-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import PurchaseGiftAccordion from './PurchaseGiftAccordion';
import PurchaseHero from './PurchaseHero';

export default {
  name: 'PurchaseGift',
  components: {
    PurchaseGiftAccordion,
    PurchaseHero,
  },
  mounted() {
    // hack to for navbar
    const navbarElem = document.getElementById("app-navbar");
    if (navbarElem && navbarElem.className) navbarElem.className = "app-navbar";
  },
};

</script>

<style lang="scss" scoped>
@import "../../../assets/scss/variables.scss";

.purchase-gift {
  background: $bg-color;
}

</style>
