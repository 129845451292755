export function formatSubscriptionPlan(plan, subscription) {
  let displayName = plan ? plan.type : 'Free';
  const planType = plan ? plan.type : 'free';
  let period = plan ? 'month' : null;
  if (plan && plan.type === 'yearly') {
    displayName = 'Annual';
  }
  if (plan && plan.type === 'gift_annual') {
    displayName = 'Annual Gift';
  }
  if (plan && plan.country === 'IN') {
    if (plan.type === 'yearly') displayName = '12 months';
    if (plan.type === 'semi-annual') displayName = '6 months';
  }
  const cancelledAndExpired = subscription && subscription.is_cancelled && subscription.status !== 'PREMIUM';
  if (subscription && subscription.subscribed_at && !cancelledAndExpired) {
    if (planType === 'yearly') {
      period = 'year';
    }
    if (planType === 'semi-annual') {
      period = '6 months';
    }
  }
  return {
    ...plan,
    planType,
    displayName,
    period,
  };
}

export default {
  formatSubscriptionPlan,
};
