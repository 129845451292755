import Vue from 'vue';
import { Loading, MessageBox, Notification } from 'element-ui';
import VueClipboard from 'vue-clipboard2';
import VueLazyLoad from 'vue-lazyload';
import * as log from 'loglevel';
import VueSanitize from 'vue-sanitize';
import VueScrollTo from 'vue-scrollto';
import VuePhoneNumberInput from 'vue-phone-number-input';

import lang from 'element-ui/lib/locale/lang/en';
import locale from 'element-ui/lib/locale';

import App from './App.vue';
import router from './router';
import store from './store/index';
import getFirebase from './config/firebase';

import mixins from './shared/mixins';
import './assets/scss/theme.scss';
import TimeFilter from './shared/timeFilter';
import HumanizedTimeFilter from './shared/humanizedTimeFilter';
import DateFilter from './shared/dateFilter';
import TruncateFilter from './shared/truncateFilter';
import PodcastDateFilter from './shared/podcastDateFilter';
import TextFilter from './shared/textFilter';
import AmountFilter from './shared/amountFilter';
import TaxFilter from './shared/taxFilter';
import Analytics from './shared/analytics';
import AnalyticsV2 from './shared/analyticsV2';
import ButtonEventDirective from './directives/button-event';
import CheckVisibleViewportDirective from './directives/check-visible-viewport';
import ScrollToDirective from './directives/scroll-to';

import elementComponents from './element-components';

import axiosInterceptor from './shared/axiosInterceptor';

// import * as braze from "@braze/web-sdk";

Object.defineProperty(Vue.prototype, '$analytics', { value: Analytics });
Object.defineProperty(Vue.prototype, '$analyticsV2', { value: AnalyticsV2 });
Vue.prototype.$eventHub = new Vue();

axiosInterceptor();

const firebase = getFirebase();

function removeSplashScreen() {
  if (!window.splashRemoved) {
    const loader = document.getElementById('loader');
    if (loader) {
      loader.classList.add('hiding');
      loader.style.zIndex = -1;
      setTimeout(() => {
        loader.style.display = 'none';
        window.splashRemoved = true;
      }, 250);
    }
  }
}

function enablePageScrolling() {
  const element = document.getElementsByTagName('html')[0];
  element.style.overflow = 'auto';
}

function checkIfTouchDevice() {
  const isTouch = 'ontouchstart' in window || navigator.msMaxTouchPoints;
  if (isTouch) {
    const element = document.getElementsByTagName('html')[0];
    element.classList.add('isTouch');
    window.isTouch = true;
  }
}

function initBrazeSDK() {
  if (store.getters.featureFlag('braze_messaging_enabled')) {
    // Braze in-app messaging SDK
    console.log('braze endpoint: ', process.env.BRAZE_ENDPOINT);
    braze.initialize(process.env.BRAZE_API_KEY, {
      baseUrl: process.env.BRAZE_ENDPOINT,
      enableLogging: false,
    });
    braze.automaticallyShowInAppMessages();
    braze.openSession();
  }
}

const initLogger = () => {
  log.setLevel(log.levels.TRACE);

  if (process.env.NODE_ENV === 'production') {
    log.setLevel(log.levels.ERROR); // Log only ERROR level logs in production. Can be set to SILENT to disable all logs completely.
  }

  log.info('Logger Initialized.');
};

locale.use(lang);

elementComponents.forEach((component) => {
  Vue.component(component.name, component);
});
Vue.component('picture-component', () => import('./components/PictureComponent.vue'));
Vue.component('svg-component', () => import('./components/SvgComponent.vue'));
Vue.component('grid-col', () => import('./components/GridCol.vue' /* webpackPreload: true */));
Vue.component('vue-phone-number-input', VuePhoneNumberInput);

Vue.use(VueClipboard);
Vue.use(Loading.directive);
Vue.use(VueLazyLoad);
Vue.use(VueSanitize, {
  allowedTags: VueSanitize.defaults.allowedTags.concat(['img']),
});
Vue.use(VueScrollTo);

Vue.prototype.$loading = Loading.service;
Vue.prototype.$confirm = MessageBox.confirm;
Vue.prototype.$notify = Notification;

Vue.mixin(mixins);

Vue.filter('timeFormat', TimeFilter);
Vue.filter('humanizedTimeFilter', HumanizedTimeFilter);
Vue.filter('dateFormat', DateFilter);
Vue.filter('truncate', TruncateFilter);
Vue.filter('podcastDateFilter', PodcastDateFilter);
Vue.filter('textFilter', TextFilter);
Vue.filter('amountFilter', AmountFilter);
Vue.filter('taxFilter', TaxFilter);

Vue.directive('button-event', ButtonEventDirective);
Vue.directive('check-visible-viewport', CheckVisibleViewportDirective);
Vue.directive('scroll-to', ScrollToDirective);

window.eventBus = new Vue();

// eslint-disable-next-line no-unused-vars
function initFullStoryScript() {
  const head = document.getElementsByTagName('head')[0];
  const script = document.createElement('script');
  script.type = 'text/javascript';
  script.innerText = `window['_fs_debug'] = false;
    window['_fs_host'] = 'fullstory.com';
    window['_fs_script'] = 'edge.fullstory.com/s/fs.js';
    window['_fs_org'] = 'J7NW0';
    window['_fs_namespace'] = 'FS';
    (function(m,n,e,t,l,o,g,y){
      if (e in m) {if(m.console && m.console.log) { m.console.log('FullStory namespace conflict. Please set window["_fs_namespace"].');} return;}
      g=m[e]=function(a,b,s){g.q?g.q.push([a,b,s]):g._api(a,b,s);};g.q=[];
      o=n.createElement(t);o.async=1;o.crossOrigin='anonymous';o.src='https://'+_fs_script;
      y=n.getElementsByTagName(t)[0];y.parentNode.insertBefore(o,y);
      g.identify=function(i,v,s){g(l,{uid:i},s);if(v)g(l,v,s)};g.setUserVars=function(v,s){g(l,v,s)};g.event=function(i,v,s){g('event',{n:i,p:v},s)};
      g.anonymize=function(){g.identify(!!0)};
      g.shutdown=function(){g("rec",!1)};g.restart=function(){g("rec",!0)};
      g.log = function(a,b){g("log",[a,b])};
      g.consent=function(a){g("consent",!arguments.length||a)};
      g.identifyAccount=function(i,v){o='account';v=v||{};v.acctId=i;g(o,v)};
      g.clearUserCookie=function(){};
      g._w={};y='XMLHttpRequest';g._w[y]=m[y];y='fetch';g._w[y]=m[y];
      if(m[y])m[y]=function(){return g._w[y].apply(this,arguments)};
      g._v="1.2.0";
    })(window,document,window['_fs_namespace'],'script','user');`;
  head.appendChild(script);
}

function initServiceWorker() {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.getRegistrations()
      .then((registrations) => {
        registrations.forEach((registration) => {
          registration.unregister();
        });
      })
      .catch((err) => {
        log.warn(err);
      });
  }
}

function initializeApp() {
  // wait safari css processing
  setTimeout(() => {
    initServiceWorker();
    removeSplashScreen();
    enablePageScrolling();
    checkIfTouchDevice();
    window.Vue = new Vue({
      el: '#app',
      router,
      store,
      render: h => h(App),
    });
    if (window.LUM_LOAD_SCRIPTS) {
      window.LUM_LOAD_SCRIPTS();
    }
    initLogger();
    initBrazeSDK();
  }, 250);
}

const authRoutes = [
  '/account/info',
  '/account/subscription',
  '/account/feedback',
  '/account/updatepassword',
  '/user-categories',
  '/listen/my-shows',
  '/listen/bookmarks',
];

window.LUM__ISAUTHROUTE = true;
if (window && window.location && authRoutes.indexOf(window.location.pathname) < 0) {
  window.LUM__ISAUTHROUTE = false;
  initializeApp();
}

if (window.LUM__ISAUTHROUTE) {
  firebase.onAuthStateChanged(() => {
    initializeApp();
  });
}
