<template>
  <div
    v-if="showFreeEpisodes"
    class="container">
    <div class="listen-free">
      <el-row>
        <grid-col
          :xs="{ span: 24, offset: 0 }"
          :sm="{ span: 24, offset: 0 }"
          :md="{ span: 24, offset: 0 }"
          :lg="{ span: 18, offset: 3 }"
          :xl="{ span: 16, offset: 4 }">
          <p class="listen-free-cta">{{ listen_free_cta }}</p>
          <div class="card-wrapper">
            <ListenFreeCard
              v-for="(episode, index) in freeEpisodes"
              :key="index"
              :episode="episode"
              :podacst="podcast" />
          </div>
        </grid-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import ListenFreeCard from './ListenFreeCard';

export default {
  components: {
    ListenFreeCard,
  },
  props: {
    freeEpisodes: {
      type: Array,
      default() {
        return [];
      },
    },
    podcast: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      listen_free_cta: 'Listen before you subscribe',
    };
  },
  computed: {
    showFreeEpisodes() {
      return !!this.freeEpisodes.length;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../assets/scss/variables.scss";

.listen-free {
  margin-bottom: 60px;
  @media(min-width: $sm) {
    margin-bottom: 90px;
  }
}

.listen-free-cta {
  font-family: $patron-bold;
  color: $white;
  text-align: center;
  font-size: 18px;
  line-height: 28px;
  margin-top: 0;
  margin-bottom: 20px;
  @media(min-width: $sm) {
    font-size: 20px;
    margin-bottom: 30px;
  }
}

.card-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media(min-width: $md) {
    flex-direction: row;
  }
}

</style>
