let signup_flow = '2t4PF7MOSBW44c76ZIMSxA';
if (process.env.LUMINARY_IS_LOCALHOST) signup_flow = 'umPinTI9Q5CF9Q8M32e_aQ';
else if (process.env.NODE_ENV === 'production') signup_flow = 'l5jxIrBBQACT7rY2X6_Zbg';

let cancellation_flow = 'SXLq7pI4SGilL-b9UpcbIg';
if (process.env.LUMINARY_IS_LOCALHOST) cancellation_flow = 'lY5Ag3u1RLWzUv71dpHKiQ';
else if (process.env.NODE_ENV === 'production') cancellation_flow = 'LDs-jS_cSFSYMjylji1bBQ';

let brightback_return_host = 'https://consumer.stage.luminarypodcasts.com';
if (process.env.LUMINARY_IS_LOCALHOST) brightback_return_host = 'http://local-luminary-consumer.site';
else if (process.env.NODE_ENV === 'production') brightback_return_host = 'https://luminarypodcasts.com';

export default {
  flags: {
    acquisition_flow: false,
    adyen_drop_in: true,
    // adyen_drop_in_billing: !window.location.search.substr(1).split('&').includes('custom_billing'),
    adyen_drop_in_billing: true,
    alexa: true,
    annual_plan: true,
    apple_signin: true,
    apple_signup: true,
    cms_widgets: true,
    episode_details_endpoint: false,
    facebook_signin: true,
    facebook_signup: true,
    float_cta_btn: true,
    fullstory_enabled: true,
    google_signin: true,
    google_signup: true,
    holiday_promo: false,
    homepage_v2: true,
    mpart_analytics: true,
    old_cta_routing: false,
    onboarding_categories: true,
    qualified_listening: true,
    reset_password_endpoint: true,
    segment_policy: !process.env.LUMINARY_IS_ONETRUST_CONSENT,
    show_details_endpoint: true,
    test_feature_1: false,
    text_app_new_sub: true,
    tmta_full_modal_desktop: false,
    web_2: false,
    web_v2_edp: true,
    web_v2_sdp: true,
    apple_podcasts: true,
    apple_podcasts_plan: true,
    shop_links: true,
    brightback: false,
    email_verification: false,
    hls_player: true,
    us_address_before_adyen: false,
    is_spotlight_enabled: false,
    braze_messaging_enabled: false,
    dynamic_promo_code: false,
    blackstar_promo: true,
    tilthisday_promo: false,
    midnight_miracle_promo: true,
    special_promo: true,
    miraclevinyl_promo: true,
  },
  variables: {
    alexa: {
      link_alexa_skills: 'https://www.amazon.com/dp/B082MT778N/ref=nodl_',
      link_faqs: 'https://luminary.zendesk.com/hc/en-us/categories/360002749573-Alexa',
    },
    qualified_listening: {
      ql_api_path: 'v1/events/listening-stats',
      send_interval: 10,
      update_interval: 300,
    },
    experiment: {
      signup_flow,
      cancellation_flow,
    },
    brightback: {
      app_id: 'zeGobA9r7N',
      save_return_url: `${brightback_return_host}/?cancellation_flow=save`,
      cancel_confirmation_url: `${brightback_return_host}/?cancellation_flow=cancel`,
    },
  },
};
