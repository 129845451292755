import * as log from 'loglevel';
import Vue from 'vue';
import Episode from '../../services/Episode';
import Podcast from '../../services/Podcast';

const getDefaultState = () => ({
  libraryPodcasts: {},
  libraryEpisodes: {},
});

const getters = {
  libraryPodcasts: state => state.libraryPodcasts,
  libraryEpisodes: state => state.libraryEpisodes,
  searchEpisodeInLibrary: state => episodeUuid => state.libraryEpisodes[episodeUuid],
  searchPodcastInLibrary: state => podcastUuid => state.libraryPodcasts[podcastUuid],
};

const mutations = {
  setUserLibraryPodcasts: (state, libraryPodcasts) => {
    state.libraryPodcasts = libraryPodcasts;
  },
  setUserLibraryEpisodes: (state, libraryEpisodes) => {
    state.libraryEpisodes = libraryEpisodes;
  },
  setUserLibraryEpisode: (state, libraryEpisode) => {
    Vue.set(state.libraryEpisodes, libraryEpisode.episode_uuid, libraryEpisode);
  },
  setUserLibraryPodcast: (state, libraryPodcast) => {
    Vue.set(state.libraryPodcasts, libraryPodcast.podcast_uuid, libraryPodcast);
  },
  removeUserLibraryEpisode: (state, libraryEpisodeUuid) => {
    Vue.delete(state.libraryEpisodes, libraryEpisodeUuid);
  },
  removeUserLibraryPodcast: (state, libraryPodcastUuid) => {
    Vue.delete(state.libraryPodcasts, libraryPodcastUuid);
  },
  resetLibraryState: (state) => {
    Object.assign(state, getDefaultState());
  },
};

const state = getDefaultState();

const actions = {
  setUserLibraryPodcasts: (context, libraryPodcasts) => {
    context.commit('setUserLibraryPodcasts', libraryPodcasts);
  },
  setUserLibraryEpisodes: (context, libraryEpisodes) => {
    context.commit('setUserLibraryEpisodes', libraryEpisodes);
  },
  populateUserLibrary(context) {
    context.dispatch('populateUserLibraryPodcasts');
    context.dispatch('populateUserLibraryEpisodes');
  },
  populateUserLibraryEpisodes(context) {
    const episodeProvider = new Episode();
    episodeProvider.getUserLibraryEpisodeIds().then((resp) => {
      if (resp && resp.length) {
        const libraryEpisodes = {};
        resp.forEach((element) => {
          libraryEpisodes[element.episode_uuid] = element;
        });
        context.commit('setUserLibraryEpisodes', libraryEpisodes);
      }
    }).catch((error) => {
      log.info(error);
    });
  },
  populateUserLibraryPodcasts(context) {
    const podcastProvider = new Podcast();
    podcastProvider.getUserLibraryPodcastIds().then((resp) => {
      if (resp && resp.length) {
        const libraryPodcasts = {};
        resp.forEach((element) => {
          libraryPodcasts[element.podcast_uuid] = element;
        });
        context.commit('setUserLibraryPodcasts', libraryPodcasts);
      }
    }).catch((error) => {
      log.info(error);
    });
  },
  populateUserLibraryEpisodesPromise(context) {
    return new Promise((resolve, reject) => {
      const episodeProvider = new Episode();
      episodeProvider.getUserLibraryEpisodeIds().then((resp) => {
        if (resp && resp.length) {
          const libraryEpisodes = {};
          resp.forEach((element) => {
            libraryEpisodes[element.episode_uuid] = element;
          });
          context.commit('setUserLibraryEpisodes', libraryEpisodes);
          resolve(libraryEpisodes);
        }
      }).catch((error) => {
        log.info(error);
        reject(error.message);
      });
    });
  },
  populateUserLibraryPodcastsPromise(context) {
    return new Promise((resolve, reject) => {
      const podcastProvider = new Podcast();
      podcastProvider.getUserLibraryPodcastIds().then((resp) => {
        if (resp && resp.length) {
          const libraryPodcasts = {};
          resp.forEach((element) => {
            libraryPodcasts[element.podcast_uuid] = element;
          });
          context.commit('setUserLibraryPodcasts', libraryPodcasts);
          resolve(resp);
        }
      }).catch((error) => {
        log.info(error);
        reject(error.message);
      });
    });
  },
  setUserLibraryEpisode: (context, libraryEpisode) => {
    context.commit('setUserLibraryEpisode', libraryEpisode);
  },
  setUserLibraryPodcast: (context, libraryPodcast) => {
    context.commit('setUserLibraryPodcast', libraryPodcast);
  },
  removeUserLibraryEpisode: (context, libraryEpisodeUuid) => {
    context.commit('removeUserLibraryEpisode', libraryEpisodeUuid);
  },
  removeUserLibraryPodcast: (context, libraryPodcastUuid) => {
    context.commit('removeUserLibraryPodcast', libraryPodcastUuid);
  },
  resetUserLibraryState: (context) => {
    context.commit('resetLibraryState');
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
