<template>
  <div class="container">
    <div class="banner">
      <div class="breadcrumb-wrapper">
        <Breadcrumb
          :v-if="showBreadcrumb"
          :trail="breadcrumbTrail" />
      </div>
      <SocialShare
        :show-data="showData"
        :type="type" />
    </div>
  </div>
</template>

<script>
import DetailPageBreadcrumb from '../../DetailPageBreadcrumb';
import SocialShare from '../../SocialShare';

export default {
  name: 'Banner',
  components: {
    Breadcrumb: DetailPageBreadcrumb,
    SocialShare,
  },
  props: {
    breadcrumbTrail: {
      type: Array,
      default() {
        return [];
      },
    },
    showBreadcrumb: {
      type: Boolean,
      default: false,
    },
    showData: {
      type: Object,
      default() {
        return {};
      },
    },
    type: {
      type: String,
      default: '',
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../assets/scss/variables.scss";

.banner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 20px;
  @media(min-width: $sm) {
    align-items: initial;
    padding-top: 42px;
    padding-bottom: 12px;
    border-bottom: 1px solid $medium-gray;
  }
}

.breadcrumb-wrapper {
  max-width: calc(100% - 30px);
  margin-right: 16px;
  @media(min-width: 768px) {
    max-width: calc(100% - 200px);
  }
}
</style>
