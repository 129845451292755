<template>
  <div
    class="app-navbar">
    <div class="container">
      <div class="logo el-row is-justify-center el-row--flex">
        <a
          @click="backBtnHandler">
          <img
            class="img-fluid button hidden-sm-and-down"
            src="../assets/images/back_button.svg"
            alt="Back Button">
          <img
            class="img-fluid button hidden-sm-and-up"
            src="../assets/images/back_mweb.svg"
            alt="Back Button">
        </a>
        <router-link
          to="/shows">
          <img
            class="img-fluid"
            src="../assets/images/logo-new.svg"
            alt="Luminary Logo">
        </router-link>
        <router-link
          to="/shows">
          <img
            class="img-fluid button hidden-sm-and-down"
            src="../assets/images/close_button.svg"
            alt="Close Button">
          <img
            class="img-fluid button hidden-sm-and-up"
            src="../assets/images/close_mweb.svg"
            alt="Close Button">
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'AcquisitionNavbar',
  props: {
    signupStep: {
      type: String,
      default: () => 'stepOne',
    },
    resetFlow: {
      type: Function,
      default: () => null,
    },
    resetFlowUrl: {
      type: String,
      default: '/signup',
    },
  },
  methods: {
    clickHandler() {
      this.$router.go();
    },
    backBtnHandler() {
      if (this.signupStep === 'stepOne') {
        return this.$router.push('/');
      }
      this.resetFlow();
      return this.$router.push(this.resetFlowUrl);
    },
  },
};
</script>

<style lang="scss" scoped>

@import "../assets/scss/variables.scss";


.app-navbar {
  z-index: 998;
  height: 72px;
  min-height: 72px;
  padding: 6px 0;
  box-sizing: border-box;
  position: relative;
  display: flex;
  align-items: center;
  background-color: $dark-background;
  color: $brand;
  .logo {
    justify-content: space-between;
    width: 100%;
    img {
      width: 130px;
      height: 36px;
      @media (max-width: 567px) {
        width: 123px;
        height: 30px;
      }
    }
    .button {
      width: 50px;
      @media (max-width: 567px) {
        width: 15px;
        height: 30px;
        margin-right: 15px;
      }
    }
  }
}

</style>
