const sanitizeHtml = (htmlString) => {
  const checkUrl = url => /^(https?:\/\/|)(www\.|)luminarypodcasts\.com(.*)|^\/(.*)/.test(url);
  const replaceHtml = (el) => {
    const span = document.createElement('span');
    span.innerText = el.innerText;
    el.replaceWith(span);
  };

  /* allowedElements list matches blueMonday sanitization policy in API feed import */
  const allowedElements = ['a', 'p', 'ol', 'ul', 'em', 'i', 'b', 'li', 'br', 'strong',
    'span', 'div', 'blockquote', 'code', 'small'];
  const removeTheseElements = ['object', 'embed', 'script', 'iframe', 'img', 'param'];

  const descDiv = document.createElement('div');
  descDiv.innerHTML = htmlString;
  const aTags = descDiv.getElementsByTagName('a');
  const allElements = descDiv.getElementsByTagName('*');

  /*
    Extra sanitization at this level in case things escape the API level sanitization
   */
  [...allElements]
    .filter(el => !allowedElements.includes(el.tagName.toLowerCase())) // get disallowed elements
    .forEach((el) => {
      const elName = el.tagName.toLowerCase();
      if (removeTheseElements.includes(elName)) {
        /* overkill alert! */
        if (elName === 'img' && typeof el.parentNode !== 'undefined' && el.parentNode.tagName.toLowerCase() === 'a') {
          el.parentNode.remove();
        }
        el.remove();
      } else {
        replaceHtml(el);
      }
    });

  [...aTags].forEach((aTag) => {
    aTag.removeAttribute('onmouseover');
    aTag.removeAttribute('onmouseout');
    aTag.removeAttribute('onclick');
    aTag.removeAttribute('download');

    if (checkUrl(aTag.href || '')) {
      replaceHtml(aTag);
    }
  });

  return descDiv.outerHTML || '';
};

export default sanitizeHtml;
