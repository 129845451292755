import YourInfoSection from './YourInfoSection';
import RecipientInfoSection from './RecipientInfoSection';
import PaymentSection from './PaymentSection';
import OrderSummarySection from './OrderSummarySection';

export default {
  components: {
    YourInfoSection,
    RecipientInfoSection,
    PaymentSection,
    OrderSummarySection,
  },
};
