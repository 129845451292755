const deps = {
  store: null,
  router: null,
};

const services = new Map();

function addInstanceDeps(instance) {
  instance.deps = deps;
  return instance;
}

export function addDependencies({ store, router }) {
  deps.store = store;
  deps.router = router;
}

export function getServices(conf) {
  const processItem = (item) => {
    // already instantiated
    if (services.has(item)) return services.get(item);
    // it is constructor
    if (typeof item === 'function') {
      // eslint-disable-next-line new-cap
      const instance = addInstanceDeps(new item());
      services.set(item, instance);
      return instance;
    }
    // complex configuration
    if (typeof item === 'object') {
      const { type, args = [], inject = {} } = item;
      // eslint-disable-next-line new-cap
      const instance = services.has(item) ? services.get(item) : addInstanceDeps(new type(...args));
      Object.entries(inject).forEach(([k, v]) => {
        instance[k] = v;
      });
      services.set(item, instance);
      return instance;
    }
    return null;
  };
  if (Array.isArray(conf)) {
    return conf.map(processItem);
  }
  return Object.entries(conf).reduce((acc, [k, v]) => ({ ...acc, [k]: processItem(v) }), {});
}
