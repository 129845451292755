const orderedSteps = [
  'plan',
  'account',
  'billing',
];

const getDefaultState = () => ({
  completed: orderedSteps.reduce((acc, v) => ({ ...acc, [v]: false }), {}),
});

const getters = {
  isCompleted: state => id => state.completed[id],
  isStepAvailable: state => (id) => {
    const i = orderedSteps.indexOf(id);
    return !(i > 0 && !state.completed[orderedSteps[i - 1]]);
  },
};

const mutations = {
  setCompleted: (state, payload) => {
    state.completed[payload] = true;
  },
  setNotCompleted: (state, payload) => {
    state.completed[payload] = false;
  },
};

const state = getDefaultState();

const actions = {
  setCompleted(context, payload) {
    context.commit('setCompleted', payload);
  },
  dropAllSteps(context) {
    orderedSteps.forEach((step) => {
      context.commit('setNotCompleted', step);
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
