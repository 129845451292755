<template>
  <div class="order-summary">
    <div class="plan-wrapper">
      <div class="plan">
        <h3 class="plan-duration">1-Year Subscription</h3>
        <h3 class="plan-price" v-html="giftPrice"/>
      </div>
      <div class="plan-detail">
        <p class="heading">Send to:</p>
        <p class="copy">{{ summaryData.recipientInfo.firstName }} {{ summaryData.recipientInfo.lastName }}</p>
        <p class="copy">{{ summaryData.recipientInfo.email }}</p>
      </div>
      <div class="plan-detail">
        <p class="heading">Send on:</p>
        <p class="copy">{{ formattedDate }}</p>
      </div>
      <div class="plan-detail">
        <p class="heading">Send with message:</p>
        <p class="copy">{{ summaryData.recipientInfo.message }}</p>
      </div>
      <div class="plan-detail">
        <p class="heading">From:</p>
        <p class="copy">{{ summaryData.yourInfo.firstName }} {{ summaryData.yourInfo.lastName }}</p>
        <p class="copy">{{ summaryData.yourInfo.email }}</p>
      </div>
    </div>
    <el-button
      v-loading="loading"
      :disabled="loading"
      type="primary"
      class="block-btn spotlight"
      @click="purchaseGift">
      Complete Purchase
    </el-button>
    <p class="terms">
      Subscription codes valid for residents of the United States, United Kingdom, Ireland, South Africa, Australia, New Zealand, Canada, Demark, Sweden, Norway, Finland, and Iceland, who are 18 years of age or older. Not a gift card. Subscription code entitles the holder to 12 months of Luminary, which will be credited to the recipient’s account upon redemption. To redeem, visit
      <a
        href="/redeem-a-gift"
        target="_blank">
        luminarypodcasts.com/redeem-a-gift
      </a>
      and manually enter the code printed on the email. A condition of redemption is that holder has a Luminary account and accepts Luminary Terms and Conditions. Redemption is one-time use only, and non-transferable. Void where prohibited. Cannot be combined with other offers, coupons, discounts, or promotions. Subscription code is not refundable or redeemable for cash or any other goods or services and cannot be sold or transferred, or exchanged or credited towards purchase of other subscriptions, and will be honored if obtained from any third party. Visit
      <a
        href="/terms"
        target="_blank">
        luminarypodcasts.com/terms
      </a>
      for complete terms.
    </p>
  </div>
</template>

<script>
import { mapMutations } from 'vuex';
import { format } from 'date-fns';
import SubscriptionMixin from '@/shared/subscriptionMixin';
import GiftService from '@/services/Gift';
import adyenDropin from '@/shared/adyenDropin';
import giftMixin from '@/shared/giftMixin';

export default {
  mixins: [SubscriptionMixin, giftMixin],
  data() {
    return {
      gift_service: new GiftService(),
      loading: false,
    };
  },
  inject: ['getDropin'],
  computed: {
    summaryData() {
      return this.$store.getters.accordionSections.reduce(this.summaryReducer, {});
    },
    mapSummaryDataToRequest() {
      const { yourInfo, recipientInfo, payment } = this.summaryData;
      const verifiedDateTime = this.getVerifiedDate(recipientInfo.delivered_at);
      const verifiedDate = new Date(verifiedDateTime);
      const dateAsIsoString = verifiedDate.toISOString();

      return {
        purchaser: {
          ...yourInfo,
          billingAddress: payment.billingAddress,
        },
        recipient: {
          firstName: recipientInfo.firstName,
          lastName: recipientInfo.lastName,
          email: recipientInfo.email,
        },
        delivered_at: dateAsIsoString,
        message: recipientInfo.message,
        currency: payment.currency,
        paymentMethod: payment.paymentMethod,
        returnUrl: adyenDropin.getReturnUrl(this.$route, window.location),
      };
    },
    formattedDate() {
      if (!this.summaryData.recipientInfo.delivered_at) return '';
      return format(new Date(this.summaryData.recipientInfo.delivered_at), 'MM/dd/yyyy');
    },
  },
  methods: {
    ...mapMutations(['setActiveAccordionSection']),
    getVerifiedDate(selectedDate) {
      const selectedDateString = new Date(selectedDate);
      const selectedDateTime = selectedDateString.getTime();

      return selectedDateTime < Date.now() ? Date.now() : selectedDateTime;
    },
    summaryReducer(acc, section) {
      const { name, formData } = section;
      if (formData) {
        return { ...acc, [name]: formData };
      }
      return acc;
    },
    purchaseGift() {
      this.loading = true;
      const requestData = this.mapSummaryDataToRequest;
      const method = 'purchaseGift';
      const processResponse = 'handleGiftPurchaseSuccess';
      this.$store.commit('payment/setLastParams', requestData);
      this.$store.commit('payment/setLastMethod', method);
      this.$store.commit('payment/setLastResponseProcessor', processResponse);
      this.gift_service[method](requestData)
        .then((res) => {
          if (res.action && this.getDropin && this.getDropin()) {
            // Drop-in handles the action object from the /payments response
            this.getDropin().handleAction(res.action);
          } else {
            this[processResponse](requestData);
          }
        })
        .catch((error) => {
          this.handlePurchaseError(error);
          if (this.getDropin && this.getDropin()) this.getDropin().setStatus('ready');
        }).finally(() => { this.loading = false; });
    },
    handlePurchaseError(error) {
      this.showErrorMsg(error.error || error.message, 15000);
      this.setActiveAccordionSection(2);
    },
  },
};

</script>

<style lang="scss" scoped>
@import '../../../../assets/scss/variables.scss';

.order-summary {
  max-width: 480px;
  width: 100%;
  margin: 0 auto;
}

.plan-wrapper {
  margin-bottom: 36px;
  padding-left: 6px;
  @media (min-width: $xs) {
    padding-left: 32px;
    border-left: 1px solid #353c4a;
  }
}

.plan {
  margin-bottom: 20px;
}

.plan-duration {
  color: $white;
  font-size: 18px;
  line-height: 21px;
  margin: 0;
}

.plan-price {
  color: $blue;
  font-size: 18px;
  line-height: 21px;
  margin: 0;
}

.plan-detail {
  margin-bottom: 20px;

  .heading {
    color: $light-gray;
    font-size: 14px;
    line-height: 20px;
    margin: 0;
  }

  .copy {
    color: $white;
    font-size: 14px;
    line-height: 20px;
    margin: 0;
  }
}

.terms {
  font-size: 12px;
  color: #c4c4c4;
  margin-top: 24px;
}

</style>
