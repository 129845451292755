<template>
  <div
    v-if="isLoggedIn"
    class="library-shows container"
    data-test="libarary-shows">
    <loader
      v-show="isLoadingInitial"
      center
      label="Fetching Podcasts ..."/>
    <library-menu
      v-if="!isLoadingInitial"
      :display-grid="true"
      :text="totalPodcasts + ' Show' + (totalPodcasts > 1 || !totalPodcasts ? 's' : '')"/>
    <!-- Empty Shows Box -->
    <empty-shows
      v-show="!totalPodcasts && !isLoadingInitial"
      option="shows"/>
    <!-- Display Podcasts -->
    <div
      v-if="totalPodcasts"
      class="podcast-grid">
      <el-col
          v-for="(item,index) in formattedPodcastList"
          :md="6"
          :sm="6"
          :xs="12"
          :key="item.uuid"
          class="m-t-2"
          data-test="list-shows">
        <show-card
            :is-hover-enabled="false"
            :podcast="item.show"
            :last-viewed-at="item.lastViewedAt"
            :latest-episode-released-at="item.latestEpisodeReleasedAt"
            data-test="shows"
            @podcastClicked="updatePodcastLastViewedAt(item.show, index)"
        />
      </el-col>
      <div class="t-c">
        <el-button
          v-if="!isLoading && !isLastPage"
          class="btn-transparent large"
          data-test="load-more"
          @click="loadMore">Load More</el-button>
      </div>
      <loader
        v-show="isLoadingMore"
        :with-margin="true"/>
    </div>
  </div>
</template>
<script>
import * as log from 'loglevel';
import draggable from 'vuedraggable';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import Podcast from '../../services/Podcast';
import User from '../../services/User';
import SegmentEvent from '../../services/PlayerEvent';
import ScrollMixin from '../../shared/scrollMixin';
import PageMixin from '../../shared/pageMixin';

export default {
  name: 'Shows',
  components: {
    draggable,
    loader: () => import('../../components/Loader'),
    'show-card': () => import('../../components/ShowCard'),
    'empty-shows': () => import('../../components/EmptyShows'),
    'library-menu': () => import('../../components/LibraryMenu'),
  },
  mixins: [ScrollMixin, PageMixin],
  data() {
    return {
      pageName: 'My Shows',
      podcastService: new Podcast(),
      userService: new User(),
      podcasts: [],
      showLimit: 12,
      page: 0,
      podcastsLoaded: false,
      noMoreShows: false,
      loadingMoreShows: false,
      draggableOptions: {
        animation: 500,
        ghostClass: 'ghost',
        chosenClass: 'chosen',
        dragClass: 'drag',
        delay: 10,
        scrollSensitivity: 100,
        scrollSpeed: 10,
        scroll: true,
        forceFallback: true,
        direction: 'horizontal',
      },
      eventProvider: new SegmentEvent(),
      bucket: {},
    };
  },
  computed: {
    ...mapGetters({
      isLoggedIn: 'loggedIn',
      isLoaded: 'libraryv2/isLibraryPodcastsLoadingDone',
      isLoading: 'libraryv2/isLibraryPodcastsLoading',
      podcastAllList: 'libraryv2/libraryAllPodcastList',
      pager: 'libraryv2/libraryPodcastPager',
      totalPodcasts: 'libraryv2/totalPodcasts',
    }),
    isLoadingInitial() {
      return this.isLoading && !this.podcastAllList.length;
    },
    isLoadingMore() {
      return this.isLoading && this.podcastAllList.length;
    },
    isLastPage() {
      return this.pager && this.pager.currentPage === this.pager.totalPages;
    },
    formattedPodcastList() {
      return this.podcastAllList.map(p => (p ? ({
        uuid: p.show.id,
        ...p,
      }) : p));
    },
  },
  beforeMount() {
    if (this.isLoggedIn) {
      this.resetLibraryState();
      this.loadPodcasts(1);
    }
    if (window && window.innerWidth <= this.screenSize.sm) {
      this.draggableOptions.delay = 200;
    }
  },
  mounted() {
    this.bucket = {
      bucket_uuid: null,
      name: 'My Shows',
      bucket_type: 'podcasts',
      position: 0,
      media_size: 'thumbnail',
    };
  },
  methods: {
    ...mapActions({
      loadPodcasts: 'libraryv2/loadLibraryPodcasts',
      updatePodcastViewDate: 'libraryv2/updatePodcastViewDate',
      changePodcastPosition: 'libraryv2/changePodcastPosition',
      updatePodcastPosition: 'libraryv2/updatePodcastPosition',
    }),
    ...mapMutations({
      resetLibraryState: 'libraryv2/resetLibraryState',
    }),
    loadMore() {
      this.loadPodcasts(this.pager.currentPage + 1);
    },
    // TODO remove after testing
    // async onPodcastDragEnd(evt) {
    //   if (evt.oldIndex !== evt.newIndex) {
    //     try {
    //       const item = await this.updatePodcastPosition({ oldPosition: evt.oldIndex, newPosition: evt.newIndex });
    //       this.eventProvider.triggerMyShowsReorderEvent(item.id, evt.newIndex, evt.oldIndex);
    //     } catch (e) {
    //       log.warn(e);
    //     }
    //   }
    // },
    updatePodcastLastViewedAt(userPodcast, podcastPosition) {
      this.updatePodcastViewDate(userPodcast.id);
      const bucketItemData = {
        item_position: podcastPosition,
        uuid: userPodcast.id,
        title: userPodcast.title,
        display_image_url: userPodcast.images.default,
        beacon_id: userPodcast.beacon_id,
        item_type: userPodcast.mediaType,
        is_exclusive: userPodcast.isExclusive,
      };
      this.eventProvider.triggerContentSelectEvent(this.bucket, bucketItemData);
    },
    // TODO: implement this method in the UI
    deletePodcastFromLibrary() {
      // not implemented
    },
  },
};
</script>
<style lang="scss" scoped>
.library-shows {
  min-height: calc(100vh - 347px);
  .top-bar {
    margin: 0 0;
  }
  .ghost {
    opacity: 0 !important;
  }
}
@media (max-width: 576px) {
  .library-shows {
    .top-bar {
      padding-bottom: 0 !important;
      margin: 0 5px !important;
    }
    .container {
      width: 100%;
    }
  }
}
</style>
