<template>
  <RedeemCode v-if="isLoggedIn" />
  <RedeemSignUp v-else />
</template>

<script>
import RedeemSignUp from '../../components/webv2/redeemGift/RedeemSignup';
import RedeemCode from '../../components/webv2/redeemGift/RedeemCode';

export default {
  name: 'GiftAYear',
  components: {
    RedeemSignUp,
    RedeemCode,
  },
  data() {
    return {
      metaTitle: 'Redeem Your Luminary Gift Subscription',
      metaDescription: 'Redeem your Luminary gift subscription for unlimited access to Luminary Original Podcasts from your favorite creators. Start listening now!',
    };
  },
  computed: {
    isLoggedIn() {
      return this.$store.getters.loggedIn;
    },
  },
  watch: {
    isLoggedIn: function t() {
      if (this.$store.getters.loggedIn) {
        this.isRedeemGiftFlow(false);
      }
    },
  },
  beforeMount() {
    this.setMetaInformation(this.metaTitle, this.metaDescription);
    if (!this.$store.getters.loggedIn) {
      this.isRedeemGiftFlow(true);
    }
  },
  beforeDestroy() {
    this.isRedeemGiftFlow(false);
  },
  destroyed() {
    this.setMetaInformation();
  },
  methods: {
    isRedeemGiftFlow(bool) {
      this.$store.dispatch('isRedeemGiftFlow', bool);
    },
  },
};

</script>
