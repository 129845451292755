<template>
  <section
    class="originals-section-container"
    data-test="originals-section-container">
    <promo-section :parent-page="parentPage" />
    <el-row
      type="flex"
      justify="center">
      <grid-col
        :xs="24"
        :sm="24"
        :md="23"
        :lg="22"
        :xl="18">
        <exclusives-gallery
          :hide-other-titles-on-hover="true"
          :is-originals="true"
          :parent-page="parentPage" />
      </grid-col>
    </el-row>
  </section>
</template>

<script>
import ExclusivesGallery from '../ExclusivesGallery';
import PromoSection from './PromoSection';

export default {
  name: 'OriginalsSection',
  components: {
    'exclusives-gallery': ExclusivesGallery,
    'promo-section': PromoSection,
  },
  props: {
    parentPage: {
      type: String,
      default: '',
    },
  },
};
</script>

<style lang="scss">
@import "../../assets/scss/variables.scss";
@import "../../assets/scss/mixins.scss";

.originals-section-container {
  margin-bottom: 120px;
  .section-title {
    @media (min-width: 1600px) {
      padding: 64px 0 54px;
    }
    @media (min-width: 1024px) {
      padding: 64px 0 44px;
    }
    @media (min-width: 768px) {
      padding: 48px 0 32px;
    }
    padding: 40px 0 40px;
    text-align: center;
    h1 {
      transform: translateX(0px);
      font-family: $serif;
      font-size: 40px;
      line-height: 48px;
      text-align: center;
      letter-spacing: -1px;
      color: #FFFFFF;
      margin: 0 0 15px;
      @media (min-width: 576px) and (max-width: 768px) {
        margin: 0 0 31px;
        font-size: 34px;
        line-height: 38px;
      }
    }
    h3 {
      font-family: $clearsans;
      font-style: normal;
      font-size: 24px;
      line-height: 22px;
      text-align: center;
      color: #BBBBBB;
      @media (max-width: 768px) {
        /* padding: 0 5%; */
        font-size: 20px;
        line-height: 22px;
      }
    }
    .cta-btn {
      padding: 28px 0px;
      &.floating {
        @media(max-width: 576px) {
          padding: 0px;
        }
        button {
          @media(max-width: 576px) {
            position: fixed;
            bottom: 55px;
            left: 50%;
            z-index: 99999;
            margin-left: -150px;
          }
        }
        .content-disclaimer {
          @media(max-width: 576px) {
            display: none;
          }
        }
      }
      button {
        width: 299px;
        height: 48px;
        background-color: $brand;
        font-family: $clearsans;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: black;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border: 0px;
        padding: 14px 57px;
        &:hover {
          background: darken($color: $brand, $amount: 20%);
          border-color: darken($color: $brand, $amount: 20%);
        }
        &:focus {
          padding: 10px 53px;
          border: 2px solid #FFFFFF;
        }
      }
      .content-disclaimer {
        font-size: 14px;
        line-height: 40px;
        text-align: center;
        color: #CCCCCC;
        font-family: $clearsans;
        margin-top: 17px;
        margin-right: 16px;
        margin-left: 16px;
        a {
          color: $white;
          text-decoration: underline;
          &:hover {
            color: $blue;
          }
        }
      }
    }
  }
}
</style>
