import isNil from 'lodash/isNil';
import * as log from 'loglevel';
import localforage from 'localforage';
import store from '../store';
import utility from '../shared/utility';
import AnalyticsApi from './Analytics';

const DEFAULT_CONFIG = {
  updateInterval: 10,
  sendInterval: 60 * 5, // NOTE: optimizely overrides this ln 50
  events: [
    'seeking',
    'playing',
    'pause',
    'timeupdate',
    'ended',
  ],
  localStorageName: 'listening_stats',
  localStorageKey: 'lsQueue',
};
class QualifiedListening {
  constructor(options = {}) {
    this.options = options;
    this.online = true;
    this.events = DEFAULT_CONFIG.events;
    this.analyticsService = new AnalyticsApi();
    this.listeningQueueCache = null;
    this.listeningQueue = [];
    this.lastApiPayload = null;
    this.lfStore = null;
    this.initRetries = 0;
  }

  initConfig() {
    if (this.options && this.options.test) {
      this.config = {
        ...DEFAULT_CONFIG,
        ...(this.options.config || {}),
        qlEnabled: true,
      };
      return;
    }
    let FLAG_CONFIG = {};
    let qlEnabled = true;
    const { featureFlags, featureVariable } = window.$store.getters;
    qlEnabled = featureFlags && featureFlags.qualified_listening;
    FLAG_CONFIG.updateInterval = featureVariable('qualified_listening', 'update_interval');
    FLAG_CONFIG.sendInterval = featureVariable('qualified_listening', 'send_interval');
    FLAG_CONFIG = utility.removeEmpty(FLAG_CONFIG);
    this.config = {
      ...DEFAULT_CONFIG,
      ...FLAG_CONFIG,
      ...(this.options.config || {}),
      qlEnabled,
    };
  }

  async init(audioElement) {
    // Since QL is DOM-event and browser window dependent we must know
    // if running inside unit test env to properly run unit tests
    this.initConfig();
    await this.initLocalForage();
    if (!this.config.qlEnabled) return;
    this.audioElement = audioElement;
    this.attachListeners();
    this.sendIntervalManager();
  }

  async initLocalForage() {
    try {
      this.lfStore = localforage.createInstance({
        name: DEFAULT_CONFIG.localStorageName,
        storeName: DEFAULT_CONFIG.localStorageName,
      });
      const queue = await this.lfStore.getItem(DEFAULT_CONFIG.localStorageKey);
      if (!queue) {
        await this.storageManager().createQueue();
      } else if (queue && queue.length) {
        await this.sendQueue();
      }
    } catch (err) {
      log.error(`listening-stats initLocalForage err:\n${JSON.stringify(err) || ''}`);
    }
  }

  attachListeners() {
    const ctx = this;
    if (!ctx.audioElement) {
      throw new Error('No Audio Element Provided to Attach Listeners.');
    }
    ctx.events.forEach((eventName) => {
      ctx.audioElement.addEventListener(eventName, (event) => {
        ctx.eventFired = eventName;
        switch (eventName) {
          case 'playing':
            this.handleStart(event);
            break;
          case 'timeupdate':
            this.handleUpdate(event);
            break;
          case 'seeking':
          case 'pause':
          case 'ended':
            this.handleStop(event);
            break;
          default:
        }
      });
    });

    if (window) {
      window.addEventListener('offline', () => {
        ctx.online = false;
      });
      window.addEventListener('online', () => {
        ctx.online = true;
      });
    }
  }

  storageManager() {
    const ctx = this;
    return {
      createQueue: async () => {
        try {
          const queue = await ctx.lfStore.setItem(DEFAULT_CONFIG.localStorageKey, []);
          return queue;
        } catch (err) {
          log.error(`listening-stats createQueue err:\n${JSON.stringify(err) || ''}`);
          return [];
        }
      },
      getQueue: async () => {
        try {
          const queue = await ctx.lfStore.getItem(DEFAULT_CONFIG.localStorageKey);
          return queue;
        } catch (err) {
          log.error(`listening-stats getQueue err:\n${JSON.stringify(err) || ''}`);
          return [];
        }
      },
      addSession: async (session) => {
        try {
          const queue = await ctx.storageManager().getQueue();
          const updatedQueue = queue.concat([session]);
          const savedQueue = await ctx.lfStore.setItem(DEFAULT_CONFIG.localStorageKey, updatedQueue);
          return savedQueue;
        } catch (err) {
          log.error(`listening-stats addSession err:\n${JSON.stringify(err) || ''}`);
          return null;
        }
      },
      clearQueue: async () => {
        try {
          await ctx.lfStore.setItem(DEFAULT_CONFIG.localStorageKey, []);
        } catch (err) {
          log.error(`listening-stats clearQueue err:\n${JSON.stringify(err) || ''}`);
        }
      },
    };
  }

  sendIntervalManager() {
    const ctx = this;
    setTimeout(async () => {
      if (!ctx.getAudioElement()) {
        return ctx.sendIntervalManager();
      }
      await ctx.sendQueue();
      return ctx.sendIntervalManager();
    }, ctx.config.sendInterval * 1000);
  }

  prepareQueueData(queue) {
    // see QualifiedListening.test.js test 'Merges and shapes payload to correct schema'
    // for more details about what this function is doing
    const eventsMerged = queue.reduce((rollupObject, session) => {
      const currentBank = session.listeningBanks[0];
      const episodeRollup = rollupObject[session.episodeUuid];
      if (episodeRollup) {
        const lastBankInRollup = episodeRollup[episodeRollup.length - 1];
        // detect consecutive banks and merge them as one bank
        if (lastBankInRollup.endTime === currentBank.startTime) {
          const updatedBank = {
            ...lastBankInRollup,
            endTime: currentBank.endTime,
            duration: (lastBankInRollup.duration || 0) + currentBank.duration,
          };
          const updatedRollup = episodeRollup;
          updatedRollup[updatedRollup.length - 1] = updatedBank;
          return {
            ...rollupObject,
            [session.episodeUuid]: updatedRollup,
          };
        }
        // if not consecutive bank add as seperate bank to rollup
        const bankAddedToRollup = episodeRollup.concat([currentBank]);
        return {
          ...rollupObject,
          [session.episodeUuid]: bankAddedToRollup,
        };
      }
      // no banks for this episodeUuid in rollup, add first bank
      return {
        ...rollupObject,
        [session.episodeUuid]: session.listeningBanks,
      };
    }, {});


    const validateBank = bank => Object.keys(bank).reduce((result, currentVal) => {
      if (!result) return result;
      return !isNil(bank[currentVal]);
    }, true);

    const formattedEpisodeListens = Object.keys(eventsMerged).reduce((formattedQueue, episodeRollupUuid) => {
      const formattedListeningBanks = eventsMerged[episodeRollupUuid].map((bank) => {
        const formattedBank = {
          created_at: bank.created,
          duration: bank.duration,
          start_time: bank.startTime,
          end_time: bank.endTime,
          online: bank.online,
        };
        if (!validateBank(formattedBank)) {
          log.warn(`Attempted to send invalid listening bank:\n${JSON.stringify(formattedBank)}`);
          return null;
        }
        return formattedBank;
      }).filter(bank => !!bank);
      const formattedSession = {
        episode_uuid: episodeRollupUuid,
        listens: formattedListeningBanks,
      };
      return formattedQueue.concat([formattedSession]);
    }, []);
    const metaStructure = {
      platform: 'web',
      environment: process.env.NODE_ENV === 'production' ? 'production' : 'development',
      episode_listens: formattedEpisodeListens,
    };

    return metaStructure;
  }

  async sendQueue() {
    const listeningQueue = await this.storageManager().getQueue();
    if (this.online && listeningQueue.length > 0) {
      this.listeningQueueCache = this.prepareQueueData(listeningQueue);
      this.lastApiPayload = JSON.stringify(this.listeningQueueCache);
      await this.storageManager().clearQueue();
      this.analyticsService.sendQualifiedListening(this.listeningQueueCache)
        .catch((err) => {
          const failedRequestData = this.lastApiPayload;
          const errMsg = JSON.stringify(err);
          log.error(`listening-stats err resp:\n${failedRequestData}`);
          log.error(`listening-stats err resp msg:\n${errMsg}`);
          if (window.$analytics) {
            window.$analytics.track('listening-stats err res', { reqBody: failedRequestData, resErr: errMsg });
          }
        });
    }
  }

  getAudioElement(event) {
    return event ? event.target : this.audioElement;
  }

  getEpisodeUUID() {
    const { episode } = store.getters;
    return episode ? episode.uuid : null;
  }

  getIntervalState() {
    const lastUpdate = new Date(this.lastUpdate || Date.now());
    const lastUpdateWithInterval = lastUpdate.setSeconds(lastUpdate.getSeconds() + this.config.updateInterval);
    const currentTimeStamp = Date.now();
    return {
      currentTimeStamp,
      updateIntervalReached: currentTimeStamp > lastUpdateWithInterval,
    };
  }

  isPlaying(event) {
    const audioElement = this.getAudioElement(event);
    return audioElement
        && audioElement.currentTime > 0
        && !audioElement.paused
        && !audioElement.ended
        && audioElement.readyState > 2;
  }

  handleStart(event) {
    this.modifyBank({
      created: utility.getRFC3339TimeStamp(),
      startTime: Math.round(this.getAudioElement(event).currentTime),
      endTime: null,
      duration: 0,
      online: this.online,
    });
  }

  async handleUpdate(event) {
    const isPlaying = this.isPlaying(event);
    const { updateIntervalReached } = this.getIntervalState();
    if (isPlaying) {
      this.currentProgress = this.getAudioElement(event).currentTime;
    }
    if (isPlaying && updateIntervalReached && !!this.currentBank) {
      await this.pushBankToQueue({
        ...this.currentBank,
        online: this.online,
        endTime: Math.round(this.currentProgress),
        duration: this.config.updateInterval,
      });
    }
  }

  async handleStop(event) {
    try {
      const { updateIntervalReached } = this.getIntervalState();
      const seekEvent = this.eventFired === 'seeking';
      const endTime = seekEvent ? this.currentProgress : this.getAudioElement(event).currentTime;
      if (updateIntervalReached) {
        await this.pushBankToQueue({
          ...this.currentBank,
          online: this.online,
          duration: this.config.updateInterval,
          endTime: Math.round(endTime),
        });
      }
      this.modifyBank(null);
    } catch (err) {
      log.error(`listening-stats handleStop err:\n${JSON.stringify(err) || ''}`);
    }
  }

  modifyBank(bank) {
    this.currentBank = bank ? { ...bank } : null;
    this.lastUpdate = Date.now();
  }

  getCurrentBank() {
    return this.currentBank || null;
  }

  async pushBankToQueue(bank) {
    const episodeUuid = this.getEpisodeUUID();
    const session = {
      episodeUuid,
      listeningBanks: [bank],
    };
    await this.storageManager().addSession(session);
    if (this.isPlaying()) {
      this.modifyBank({
        startTime: bank.endTime,
        endTime: null,
        duration: 0,
        created: utility.getRFC3339TimeStamp(),
      });
    }
  }
}

export default QualifiedListening;
