const getters = {
  eventsQueue: state => state.eventsQueue,
  // payload: state => state.payload,
};

const mutations = {
  addSession: (state, session) => { state.eventsQueue = state.eventsQueue.concat([session]); },
  clearQueue: (state) => { state.eventsQueue = []; },
};

const state = {
  eventsQueue: [],
};

const actions = {
  clearQueue(context) {
    context.commit('clearQueue');
  },
  addSessionToQueue(context, session) {
    context.commit('addSession', session);
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
