import * as log from 'loglevel';
/* eslint-disable */
import axios from 'axios';
/* eslint-enable */
/* eslint-disable import/no-cycle */
import AxiosAPI from './AxiosAPI';
import Podcast from './Podcast';
import Episode from './Episode';
import User from './User';
import utility from '../shared/utility';

class PlaybackService extends AxiosAPI {
  constructor() {
    super();
    this.podcastObj = new Podcast();
    this.episodeObj = new Episode();
  }

  setHlsComponent(node) {
    this.hlsComponent = node;
  }

  getContinuousListeningShowLevelPlayList() {
    return new Promise((resolve) => {
      this.podcastObj
        .loadContinueListeningBucket()
        .then((resp) => {
          if (resp) {
            const categoryPodcasts = resp;
            const continueListeningIndex = categoryPodcasts.findIndex(item => item.bucket_type === 'podcast' && item.bundle_uuid === 'continue-listening');
            if (continueListeningIndex !== -1) {
              // const episodes = this.mapContListening(categoryPodcasts, continueListeningIndex) || [];
              const clBucketItems = this.mapContListening(categoryPodcasts[continueListeningIndex].items);
              resolve(clBucketItems);
            }
          } else {
            resolve([]);
          }
        })
        .catch((err) => {
          log.warn(err.message);
        });
    });
  }

  mapContListening(bucketItems) {
    // Map attributes of continue listening to pass to podcast-list component
    bucketItems = bucketItems.map((item) => {
      let tempObj = {};
      if (item && item.season && item.season.episodes) {
        [tempObj] = item.season.episodes;
      }
      tempObj.season = {
        podcast: {
          uuid: item.uuid,
          title: item.title,
          slug: item.slug,
          is_exclusive: item.is_exclusive,
          publisher: {
            uuid: item.publisher.uuid,
            name: item.publisher.name,
            slug: item.publisher.slug,
          },
        },
      };
      tempObj.display_image_url = item.display_image_url;
      tempObj.type = 'play';
      tempObj.show_temp_title = true;
      tempObj.all_caught_up = !tempObj.uuid;
      return tempObj;
    });
    return bucketItems;
  }

  fetchNextEpisode(episodeUUID, playContext) {
    return new Promise((resolve, reject) => {
      super.get(`v1/episode/${episodeUUID}/next`, { context: playContext }).then((resp) => {
        resolve(resp);
      })
        .catch((error) => {
          reject(error);
        });
    });
  }

  fetchAndStoreNextEpisode(episodeUuid) {
    const playContext = window.$store.getters.playlistType;
    this.fetchNextEpisode(episodeUuid, playContext).then((nextEpisode) => {
      if (nextEpisode && nextEpisode.episode_type === 'trailer') {
        nextEpisode.episode_type = 'full';
      }
      if (!nextEpisode || !nextEpisode.uuid) {
        if (playContext === 'continue-listening') {
          this.triggerNextPodcastOfContinueListening(episodeUuid);
        } else {
          if (!window.$store.getters.token) {
            window.$store.dispatch('setNextEpisode', null);
            return;
          }
          // TODO: Fetch CL bucket and store it into local store(vuex)
          this.getContinuousListeningShowLevelPlayList().then((continueListeningList) => {
            window.$store.dispatch('setContinueListeningPlaylist', continueListeningList);
            this.triggerContinuousListening(episodeUuid);
          })
            .catch((error) => {
              log.info(error);
            });
        }
      } else if (playContext === 'continue-listening' && nextEpisode.season.podcast.uuid !== window.$store.getters.continuousListeningActivePodcast) {
        this.triggerNextPodcastOfContinueListening(episodeUuid);
      } else if (playContext === 'show') {
        window.$store.dispatch('setNextEpisode', nextEpisode);
        // this.checkNextUnplayedEpisodeOfShow();
      } else {
        window.$store.dispatch('setNextEpisode', nextEpisode);
      }
    });
  }

  triggerNextPodcastOfContinueListening(episodeUuid) {
    log.info(episodeUuid);
    const { continueListeningPlaylist } = window.$store.getters;
    // const continueListeningIndex = continueListeningPlaylist.findIndex(episode => episode.uuid === episodeUuid);
    const continueListeningIndex = continueListeningPlaylist.findIndex((episode) => {
      if (episode.season && episode.season.podcast) {
        return episode.season.podcast.uuid === window.$store.getters.continuousListeningActivePodcast;
      }

      return false;
    });

    if (continueListeningIndex > -1) {
      let nextEpisode = continueListeningPlaylist[continueListeningIndex + 1];
      if (nextEpisode && nextEpisode.all_caught_up) {
        const subArray = continueListeningPlaylist.slice((continueListeningIndex + 1), continueListeningPlaylist.length);
        if (subArray && subArray.length) {
          nextEpisode = subArray.find(episode => !episode.all_caught_up);
        } else {
          nextEpisode = null;
        }
      }
      if (nextEpisode && nextEpisode.uuid) {
        // If next episode of CL bucket is listened then fetch next episode of that podcast
        const progress = this.episodeObj.getEpisodeProgressFromLocalStore(nextEpisode.uuid);
        if (nextEpisode && nextEpisode.uuid && progress && progress.progress_percent >= 95) {
          this.fetchNextEpisode(nextEpisode.uuid, 'continue-listening').then((ne) => {
            if (ne && ne.uuid) {
              // window.$store.dispatch('setContinuousListeningActivePodcast', ne.season.podcast.uuid);
              this.syncCLBucketWithPlayerLocally(ne);
              window.$store.dispatch('setNextEpisode', ne);
            }
          }).catch((error) => {
            log.info(error);
          });
        } else {
          window.$store.dispatch('setNextEpisode', nextEpisode);
        }
      } else {
        nextEpisode = null;
        window.$store.dispatch('setNextEpisode', nextEpisode);
      }
    }
  }

  triggerContinuousListening(episodeUuid) {
    this.fetchNextEpisode(episodeUuid, 'continue-listening').then((ne) => {
      window.$store.dispatch('setPlaylistType', 'continue-listening');
      if (ne && ne.uuid) {
        window.$store.dispatch('setContinuousListeningActivePodcast', ne.season.podcast.uuid);
        window.$store.dispatch('setNextEpisode', ne);
      } else {
        this.triggerNextPodcastOfContinueListening(episodeUuid);
      }
    });
  }

  syncCLBucketWithPlayerLocally(episode) {
    episode.type = 'play';
    episode.show_temp_title = false;
    episode.all_caught_up = false;
    window.$store.dispatch('syncCLBucketWithPlayerLocally', episode);
  }

  async loadMediaInPlayer(mediaUrl, isExclusive, episodeType = 'full', trailerAsEpisode = false) {
    if (episodeType !== 'trailer') {
      window.$store.dispatch('setTrailerExperienceTriggered', false);
    }
    if (!isExclusive || episodeType === 'trailer') {
      this.setAudioSrc(mediaUrl, trailerAsEpisode);
      return;
    }

    const userProvider = new User();
    const token = await userProvider.getUserToken();
    if (!token) {
      this.showErrorMsg('You cannot play premium content without login');
      return;
    }

    this.setAudioSrc(mediaUrl, trailerAsEpisode);
  }

  setAudioSrc(mediaUrl, trailerAsEpisode) {
    const player = this.hlsComponent || document.getElementById('audio');
    if (player.src === mediaUrl && !trailerAsEpisode) return;
    let urlWithUserId = '';
    if (mediaUrl) {
      const modifyUrl = new URL(mediaUrl);
      modifyUrl.searchParams.append('userId', utility.getUserUUID());
      modifyUrl.searchParams.append('traceId', utility.getRequestId());
      urlWithUserId = modifyUrl.toString();
    }
    player.setAttribute('src', urlWithUserId);
    player.load();
    window.$store.dispatch('setStatus', 'loading');
  }

  fetchAndStoreNextTrailer(trailerUUID) {
    const { playlist } = window.$store.getters;
    if (playlist && playlist.length) {
      const currentIndex = playlist.findIndex(item => item.uuid === trailerUUID);
      if ((currentIndex > -1) && ((currentIndex + 1) < playlist.length)) {
        const nextTrailer = playlist[currentIndex + 1];
        window.$store.dispatch('setNextEpisode', nextTrailer);
      } else {
        window.$store.dispatch('setTrailerExperienceTriggered', false);
        window.$store.dispatch('setNextEpisode', {});
      }
    }
  }
}

export default PlaybackService;
