<template>
  <div
    v-loading="loading"
    class="e1-adyen-dropin"
  >
    <div class="e1-adyen-dropin__col">
      <h2 v-if="heading" class="m-b t-c font-normal heading">
        {{ heading }}
      </h2>
      <el-form
        ref="paymentForm"
        :model="paymentForm"
        :rules="isBillingAddressInAdyen ? dropinValidationRules : paymentValidationRules"
        :class="{'form-info-acquisition': acquisitionFlow}"
        div
        label-position="top"
        @keyup.enter.native="onSubmit"
      >
        <el-row v-show="isNameFieldsVisible" :gutter="12">
          <el-col :span="12">
            <el-form-item
              prop="firstName"
              label="First Name"
              data-form-prop="firstName"
            >
              <el-input
                ref="firstName"
                v-model="paymentForm.firstName"
                aria-label="First name"
                placeholder="First name"
                data-test="input-firstname"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              prop="lastName"
              label="Last Name"
              data-form-prop="lastName"
            >
              <el-input
                v-model="paymentForm.lastName"
                aria-label="Last name"
                placeholder="Last name"
                data-test="input-lastname"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <div v-if="!giftingFlow && !isBillingAddressInAdyen" class="e1-adyen-dropin__divider">Billing Address</div>
        <el-row :gutter="12">
          <el-col>
            <el-form-item
              prop="country"
              label="Country"
              data-form-prop="country"
            >
              <el-select
                ref="country"
                :popper-append-to-body="false"
                v-model="paymentForm.country"
                placeholder="Select country"
                no-match-text="Not found"
                filterable
                data-test="select-country-for-adyen"
                style="width: 100%;"
                @keyup.native.enter="preventSubmit"
              >
                <el-option
                  v-for="(country, index) in countries"
                  :key="index"
                  :label="country.name"
                  :value="country.code"
                  data-test="option-country-for-adyen" />
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row v-if="showBasicAddress" :gutter="12">
          <el-col :span="12">
            <el-form-item
              :label="stateLabel"
              prop="state"
              label-position="top"
              data-form-prop="state"
            >
              <el-select
                :popper-append-to-body="false"
                v-model="paymentForm.state"
                :placeholder="statePlaceholder"
                no-match-text="Not found"
                filterable
                data-input="select-state"
                style="width: 100%;"
                @keyup.native.enter="preventSubmit">
                <el-option
                  v-for="(state, index) in states"
                  :key="index"
                  :label="state.name"
                  :value="state.code"
                  data-test="option-state"/>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              :label="zipCodeLabel"
              prop="postalCode"
              label-position="top"
              data-form-prop="postalCode"
            >
              <el-input
                v-model="paymentForm.postalCode"
                :placeholder="postalCodePlaceholder"
                aria-label="Zip code"
                data-test="input-zipcode"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row v-if="!isBillingAddressInAdyen" :gutter="12">
          <el-col>
            <el-form-item
              prop="address"
              label="Address"
              data-form-prop="address"
            >
              <el-input
                v-model="paymentForm.address"
                aria-label="Address"
                placeholder=""
                data-test="input-address"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row v-if="!isBillingAddressInAdyen" :gutter="12">
          <el-col>
            <el-form-item
              prop="apartment"
              label="Apartment / Suite (optional)"
              data-form-prop="apartment"
            >
              <el-input
                v-model="paymentForm.houseNumberOrName"
                aria-label="Apartment / Suite (optional)"
                placeholder=""
                data-test="input-houseNumberOrName"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row v-if="!isBillingAddressInAdyen" :gutter="12">
          <el-col>
            <el-form-item
              prop="city"
              label="City"
              data-form-prop="city"
            >
              <el-input
                v-model="paymentForm.city"
                aria-label="City"
                placeholder=""
                data-test="input-city"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row v-if="!isBillingAddressInAdyen" :gutter="12">
          <el-col
            v-show="showState"
            :span="12">
            <el-form-item
              :label="stateLabel"
              prop="state"
              label-position="top"
              data-form-prop="state"
            >
              <el-select
                :popper-append-to-body="false"
                v-model="paymentForm.state"
                :placeholder="statePlaceholder"
                no-match-text="Not found"
                filterable
                data-input="select-state"
                style="width: 100%;"
                @keyup.native.enter="preventSubmit">
                <el-option
                  v-for="(state, index) in states"
                  :key="index"
                  :label="state.name"
                  :value="state.code"
                  data-test="option-state"/>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col
            :span="12"
          >
            <el-form-item
              :label="zipCodeLabel"
              prop="postalCode"
              label-position="top"
              data-form-prop="postalCode"
            >
              <el-input
                v-model="paymentForm.postalCode"
                :placeholder="postalCodePlaceholder"
                aria-label="Zip code"
                data-test="input-zipcode"
              />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div v-if="isPaymentMethodSelected">
        <slot
          :termsLink="termsLink"
          :legalCopy="legalCopy"
          name="legal-copy"
        >
          <p class="e1-adyen-dropin__terms">
            <span v-html="legalCopy"/>
            <router-link
              v-button-event
              :to="termsLink"
              target="_blank"
              color="primary"
              data-test="link-additionalterms"
              class="font-normal t-u"
              aria-label="Additional terms apply"
            >
              Terms apply.
            </router-link>
          </p>
        </slot>
      </div>
    </div>
    <div class="e1-adyen-dropin__sep" />
    <div class="e1-adyen-dropin__col">
      <div
        v-loading="isFormLoading"
        ref="adyenForm"
        class="e1-adyen-dropin__form"
      />
      <el-alert
        v-if="zuoraErrorMessage"
        :title="zuoraErrorMessage"
        type="error"
        class="m-b"
        show-icon
      />
      <div class="action-controls">
        <el-button
          v-button-event
          v-show="!isPaypalSelected"
          :disabled="isSubmitDisabled"
          :class="{'btn-brand': true, 'e1-adyen-dropin__btn': true}"
          @click="onSubmit"
        >
          {{ buttonText }}
        </el-button>
      </div>
      <vermont-modal
        :show="showVermontModal"
        @onAcceptVermontTerms="onAcceptVermontTerms"
      />
    </div>
  </div>
</template>
<script>
import { mapMutations, mapActions } from 'vuex';
import '@adyen/adyen-web/dist/adyen.css';
import SubscriptionMixin from '../../shared/subscriptionMixin';
import adyendropinMixin from '../../shared/adyendropinMixin';
import VermontModal from '../VermontGoHardModal';
import { getServices } from '@/services/serviceProvider';
import OptimizeService from '@/services/Optimize';

const [
  optimizeService,
] = getServices([OptimizeService]);

export default {
  name: 'PaymentInfoAdyen',
  components: {
    'vermont-modal': VermontModal,
  },
  mixins: [SubscriptionMixin, adyendropinMixin],
  props: {
    show_modal: {
      default: false,
      type: Boolean,
    },
    button_text: {
      default: null,
      type: String,
    },
    update: {
      default: false,
      type: Boolean,
    },
    heading: {
      default: '',
      type: String,
    },
    acquisitionFlow: {
      default: true,
      type: Boolean,
    },
    giftingFlow: {
      default: false,
      type: Boolean,
    },
    showText: {
      default: true,
      type: Boolean,
    },
    defaultFirstName: {
      default: '',
      type: String,
    },
    defaultLastName: {
      default: '',
      type: String,
    },
    promocode: {
      default: '',
      type: String,
    },
  },
  data() {
    return {
      isPaymentMethodSelected: false,
    };
  },
  computed: {
    isSubmitDisabled() {
      return !this.isPaymentMethodSelected || this.loading;
    },
  },
  watch: {
    // eslint-disable-next-line func-names
    'paymentForm.country': function (v) {
      optimizeService.triggerAnlyticsEvent({
        action: 'SelectedCountry',
        category: 'Signup',
        label: v,
      });
    },
  },
  mounted() {
    this.$refs.firstName.focus();
  },
  methods: {
    ...mapMutations([
      'setUserCountry',
    ]),
    ...mapActions([
      'invalidatePricingPlans',
      'loadPricingPlans',
    ]),
    onAdyenSelect(component) {
      this.isPaymentMethodSelected = !!component.type;
      this.isPaypalSelected = component.type === 'paypal';
      optimizeService.triggerAnlyticsEvent({
        action: 'SelectedPaymentMethod',
        category: 'Signup',
        label: component.type,
      });
    },
    gotoPlanSelectionStep() {
      const { country, currency } = this.countries.find(c => c.code === this.paymentForm.country);
      this.setUserCountry(this.paymentForm.country);
      this.invalidatePricingPlans().then(() => this.loadPricingPlans({ country, currency })).then(() => {
        this.$router.push('/signup/plan');
      });
    },
  },
};
</script>
<style lang="scss">
@import "../../assets/scss/variables.scss";
$form-items-background-color: #ffffff;
.e1-adyen-dropin {
  max-width: 1024px;
  margin: 0;
  color: #333333;
  display: flex;
  flex-flow: row wrap;
  align-items: stretch;
  justify-content: center;
  .plan-description {
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    margin-bottom: 38px;
    color: #333333;
    .strike-through {
      text-decoration: line-through;
      opacity: .6;
      margin-right: 4px;
    }
    p {
      margin: 0;
      &:first-child {
        margin-bottom: 16px;
      }
    }
  }
  .acquisition-plan-description {
    font-size: 14px;
    line-height: 20px;
    text-align: left;
    margin-bottom: 38px;
    .strike-through {
      text-decoration: line-through;
      opacity: .6;
      margin-right: 4px;
    }
    p {
      margin: 0;
      color: $white !important;
      &:first-child {
        margin-bottom: 16px;
      }
    }
  }
  .form-info-acquisition {
    .el-form-item {
      .el-form-item__label {
        color: black !important;
      }
    }
  }
  &__divider {
    color: #cccccc;
  }
  &__col {
    max-width: 350px;
    padding: 0;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: space-between;
  }
  &__sep {
    width: 32px;
    height: 8px;
  }
  &__btn {
    height: 48px;
    width: 300px;
  }
  &__terms {
    font-size: 10px;
    line-height: 12px;
    color: #C4C4C4;
  }
  .heading {
    font-size: 36px;
    letter-spacing: -1;
    line-height: 49px;
    font-family: $clear-sans;
    margin-top: 0px !important;
    font-weight: bold;
    font-style: normal;
    color: #333333;
  }
  .sub-head {
    margin-bottom: 24px;
    @media(max-width: 567px) {
      text-align: left;
    }
    div:first-child{
      font-size: 14px;
      color: #111111;
    }
    div:last-child{
      font-size: 14px;
      color: #666666;
    }
  }
  .action-button {
    margin-top: 24px;
  }
  .action-controls {
    text-align: center;
    display: inline-block;
    padding-top: 29px;
    width: 100%;
  }
  .annual-plan-btn {
    width: 353px;
    max-width: 95%;
  }
  .legal-terms {
    color: #666 !important;
    font-size: 12px;
    line-height: 18px;

    a {
      color: #111111;
    }
  }
  .legal-copy {
    max-width: 480px !important;
    width: 100%;
    margin: auto;
    background: #ffffff;
    border-radius: 5px;
    padding: 23px 0px 28px;
    margin-top: 31px;
    margin-bottom: 8px;
    span, a, p {
      font-size: 12px;
      line-height: 18px;
      font-family: $clear-sans;
    }
    b, p b {
      color: #333333;
      font-family: $clear-sans;
      font-size: 12px;
      line-height: 18px;
    }
  }
  .acquisition-legal-copy {
    max-width: 480px !important;
    width: 100%;
    background: $bg-color;
    border-radius: 5px;
    padding: 23px 0px 28px;
    margin-top: 31px;
    margin-bottom: 8px;
    span, a, p {
      font-size: 12px;
      line-height: 18px;
      font-family: $clear-sans;
      color: $white;
    }
    b, p b {
      color: $white;
      font-family: $clear-sans;
      font-size: 12px;
      line-height: 18px;
    }
  }
  &__form {
    margin-top: 10px;
    min-height: 60px;
  }
  .el-form{
    width: 100% !important;
    .el-form-item {
      margin-bottom: 16px;
      .el-form-item__label {
        color: #333333;
        font-size: 14px;
        font-weight: normal;
        line-height: 20px;
        padding: 0 0 8px 0;
      }
      .el-form-item__label:before{
        content: none !important;
      }
      .el-input {
        .el-input__inner {
          height: 48px;
          border-radius: 8px;
          background-color: $form-items-background-color;
        }
      }
    }
  }
}
</style>
