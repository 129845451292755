<template>
  <div
    v-if="!isAlexaFlow"
    :class="{'signup-page auth-page container': !acquisitionFlowStyle, 'signup-acquisition auth-page container': acquisitionFlowStyle}"
    data-test="page-signup" >
    <h4
      v-if="!acquisitionFlowEnabled && !isAcquisitionFlow && !isRedeemGiftFlow"
      class="t-c m-b page-main-title no-letter-space">
      Create an account
    </h4>
    <div
      v-if="showSignUpButton"
      class="sm-auth t-c m-b-2">
      <a
        v-button-event
        v-if="appleSignupEnabled"
        class="apple sm-auth__button m-b"
        @click="loginWithApple">
        <span class="sm-auth__button--icon">
          <img
            src="../../assets/images/apple.svg"
            alt="">
        </span>
        <span class="sm-auth__button--text">Continue with Apple</span>
      </a>
      <a
        v-button-event
        v-if="facebookSignupEnabled"
        class="fb sm-auth__button m-b"
        @click="loginWithFB">
        <span class="sm-auth__button--icon">
          <img
            src="../../assets/images/facebook.svg"
            alt="">
        </span>
        <span class="sm-auth__button--text">Continue with Facebook</span>
      </a>
      <a
        v-button-event
        v-if="googleSignupEnabled"
        class="google sm-auth__button m-b"
        @click="loginWithGoogle">
        <span class="sm-auth__button--icon">
          <img
            src="../../assets/images/google.svg"
            alt="">
        </span>
        <span class="sm-auth__button--text">Continue with Google</span>
      </a>
      <a
        v-button-event
        v-if="(acquisitionFlowEnabled && isAcquisitionFlow) || isRedeemGiftFlow"
        class="email sm-auth__button m-b"
        @click="loginWithEmail">
        <span class="sm-auth__button--icon">
          <img
            src="../../assets/images/email_icon.svg"
            alt="">
        </span>
        <span class="sm-auth__button--text">Sign up with Email</span>
      </a>
      <div
        v-if="(acquisitionFlowEnabled && isAcquisitionFlow) || isRedeemGiftFlow"
        class="t-c m-t account">
        <p>Already have an account? &nbsp;
          <router-link
            v-button-event
            :to="{path: '/signin', query: isRedeemQuery ? { isRedeemGift : true } : this.$route.query }"
            data-test="link-login"
            aria-label="Log in"
            class="btn-link">Log In</router-link></p>
      </div>
    </div>
    <el-form
      v-if="loginFlow"
      ref="user"
      :model="user"
      :rules="rules"
      :status-icon="true"
      class="overflow-icon fade"
      @keyup.enter.native="onSubmit">
      <el-form-item v-if="getUserCountry() !== 'US'">
        <el-row>
          <el-col :span="2">
            <el-checkbox
              v-model="user.marketing_consent"
              data-test="checkbox"/>
          </el-col>
          <el-col :span="22">
            <p>By checking this box you opt in to receive regular updates, news, and marketing emails about Luminary.</p>
          </el-col>
        </el-row>
      </el-form-item>
      <el-form-item
        ref="userEmail"
        prop="email"
        label="Email Address">
        <el-input
          v-model="user.email"
          label="Email address"
          placeholder="user@email.com"
          data-test="input-email"
          @keyup.native="checkValidation"/>
        <div
          v-if="emailSuggestion && user.email.length"
          class="el-form-item__error email-suggestion">
          <span>
            Did you mean
            <a
              v-button-event
              @click="setEmail">
              {{ emailSuggestion }}
            </a>
            ?
          </span>
        </div>
      </el-form-item>
      <el-form-item
        ref="repeatEmail"
        prop="repeat_email"
        label="Confirm Email">
        <el-input
          v-model="user.repeat_email"
          label="Confirm Email"
          placeholder="Confirm email"
          data-test="input-repeat-email"
          @keyup.native="checkValidation"
          @paste.native="event => event.preventDefault()"
        />
      </el-form-item>
      <el-form-item
        prop="password"
        label="Create a password">
        <el-input
          v-model="user.password"
          :type="show_pass ? 'text' : 'password'"
          label="Password"
          placeholder="8+ characters, including 1 number"
          data-test="input-password"
          class="password"
          @keyup.native="checkValidation"/>
        <show-password @show="(e) => {show_pass = e}"/>
      </el-form-item>
      <el-form-item v-if="acquisitionFlowEnabled && isAcquisitionFlow">
        <el-row>
          <el-col :span="2">
            <el-checkbox
              v-model="user.marketing_consent"
              data-test="checkbox"/>
          </el-col>
          <el-col :span="22">
            <p>By checking this box you opt in to receive regular updates, news, and marketing emails about Luminary.</p>
          </el-col>
        </el-row>
      </el-form-item>
      <div class="consent">
        <p>By clicking below, you agree to our
          <router-link
            v-button-event
            target="_blank"
            to="/terms"
            class="btn-link btn-link_blue"
            data-test="link-termservice"
            aria-label="Terms of service"
          >
            Terms of Service
          </router-link> and <br>
          <router-link
            v-button-event
            to="/privacy"
            target="_blank"
            class="btn-link btn-link_blue"
            data-test="link-privacypolicy"
            aria-label="Privacy policy"
          >
            Privacy Policy
          </router-link>
        </p>
      </div>
      <div class="t-c action-buttons">
        <el-button
          v-button-event
          v-loading="loading"
          :disabled="disableSubmit"
          type="primary"
          class="block-btn spotlight"
          data-test="btn-signup"
          @click="onSubmit">Sign Up</el-button>
      </div>
      <div class="t-c m-t">
        <p>Already have an account? &nbsp;
          <router-link
            v-button-event
            :to="{path: '/signin', query: $route.query}"
            data-test="link-login"
            aria-label="Log in"
            class="btn-link btn-link_blue">Log In</router-link></p>
      </div>
    </el-form>
  </div>
  <div v-else>
    <div class="t-c m-t">
      <p>Already have an account? &nbsp;<router-link
        v-button-event
        :to="{path: '/signin', query: $route.query}"
        data-test="link-login"
        aria-label="Log in"
        class="btn-link btn-link_blue">Log In</router-link></p>
      <appstore-links />
    </div>
  </div>
</template>
<script>
import * as EmailValidator from 'email-validator';
import * as mailcheck from 'mailcheck';
import User from '../../services/User';
import BranchService from '../../services/Branch';
import PageMixin from '../../shared/pageMixin';

export default {
  name: 'RegisterPage',
  components: {
    'show-password': () => import('../../components/ShowPassword'),
    'appstore-links': () => import('../../components/AppStoreLinks'),
  },
  mixins: [PageMixin],
  props: {
    onSignUpSuccess: {
      type: Function,
      default: null,
    },
    acquisitionFlowStyle: {
      type: Boolean,
      default: () => false,
    },
    isRedeemQuery: {
      type: String,
      default: '',
    },
  },
  data() {
    const passwordStrength = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('Password Required'));
      } else {
        const strongRegex = new RegExp('^(?=.*[a-zA-Z])(?=.*[0-9])(?=.{8,})');

        if (strongRegex.test(value)) {
          callback();
        } else {
          callback(new Error('Weak Password Strength (8+ characters, including 1 number)'));
        }
      }
    };
    const emailValidator = (rule, value, callback) => {
      this.emailSuggestion = undefined;
      if (value === '') {
        callback(new Error('Email Required'));
      } else {
        if (value.indexOf('@') === -1) {
          callback(new Error('The email address must include @'));
          return;
        }
        if (!EmailValidator.validate(value)) {
          callback(new Error('Invalid Email Format'));
          return;
        }
        if (this.user.repeat_email !== '') {
          this.$refs.user.validateField('repeat_email');
        }
        const self = this;
        mailcheck.run({
          email: self.user.email,
          suggested: (suggestion) => {
            self.emailSuggestion = suggestion.full;
            self.$refs.userEmail.$el.classList.remove('is-success');
            self.$refs.userEmail.$el.classList.remove('is-error');
            self.$refs.userEmail.$el.classList.add('is-info');
          },
          empty: () => {
            /* nothing wrong with the email */
            self.$refs.userEmail.$el.classList.remove('is-info');
            self.$refs.userEmail.$el.classList.add('is-success');
          },
        });
        callback();
      }
    };
    const repeatEmailValidator = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('Email Confirmation Required'));
      } else if (value !== this.user.email) {
        callback(new Error('Emails don\'t match'));
        return;
      }
      callback();
    };
    return {
      pageName: 'Create Account',
      userProvider: null,
      branchService: new BranchService(),
      user: {
        email: '',
        repeat_email: '',
        password: '',
        marketing_consent: true,
      },
      rules: {
        email: [{ required: true, validator: emailValidator, trigger: 'change' }],
        repeat_email: [{ required: true, validator: repeatEmailValidator, trigger: 'change' }],
        password: [{ required: true, validator: passwordStrength, trigger: 'blur' }],
      },
      loading: false,
      form_valid: false,
      show_pass: false,
      fingerprint_id: '',
      emailSuggestion: undefined,
      loginFlow: false,
    };
  },
  computed: {
    googleSignupEnabled() {
      return this.$store.getters.flagWithDefault('google_signup', true);
    },
    facebookSignupEnabled() {
      return this.$store.getters.flagWithDefault('facebook_signup', true);
    },
    appleSignupEnabled() {
      return this.$store.getters.flagWithDefault('apple_signup', true);
    },
    acquisitionFlowEnabled() {
      return this.$store.getters.flagWithDefault('acquisition_flow', false);
    },
    isAcquisitionFlow() {
      return this.$store.getters.isAcquisitionFlow1;
    },
    isRedeemGiftFlow() {
      return this.$store.getters.isRedeemGiftFlow;
    },
    showSignUpButton() {
      return this.checkLoginFlow();
    },
    disableSubmit() {
      return !this.form_valid || !this.user.email.length || !this.user.password.length || this.user.password.length <= 7 || this.loading;
    },
  },
  mounted() {
    this.userProvider = new User();
    // window.eventBus.$emit('onboarding-step-change', 0);
    this.fingerprint_id = localStorage.getItem('branch_fingerprint_id');
    if (!this.fingerprint_id) {
      this.getBrowsersFingerPrintId();
    }
  },
  methods: {
    onSubmit() {
      this.$analytics.track('Signup');
      if (this.loading) return;
      const ctx = this;
      this.$refs.user.validate((valid) => {
        if (valid) {
          ctx.registerUser();
        }
      });
    },
    checkLoginFlow() {
      if ((this.isAcquisitionFlow && this.acquisitionFlowEnabled) || this.isRedeemGiftFlow) {
        return !this.loginFlow;
      }
      this.loginFlow = true;
      return this.loginFlow;
    },
    registerUser() {
      this.loading = true;
      this.user.username = this.user.email;
      this.userProvider.register(this.user).then(() => {
        this.$store.commit('setUser', this.user);
        this.$store.commit('setSignupCompleted', true);
        this.registerToLuminary(this.user.marketing_consent);
        localStorage.setItem('login_type', 'Email');
      }).catch((error) => {
        this.loading = false;
        this.showErrorMsg(error);
      });
    },
    registerToLuminary(mktConsent) {
      // const branchData = localStorage.getItem('branch_data');
      this.userProvider.registerToLuminary(mktConsent, this.fingerprint_id)
        .then((resp) => {
          this.loading = false;
          // localStorage.removeItem('branch_data');
          this.showSuccessMsg('Your account has been created!');
          this.$analytics.identify(resp.user.uuid);
          // Logging event to branch
          const analyticsPayload = {
            ...resp,
            marketing_consent: mktConsent,
          };
          this.trackAccountCreatedData(analyticsPayload);
          this.logBranchCustomEvent(resp);
          if (this.onSignUpSuccess && this.annualPlanEnabled && !this.isAlexaFlow) {
            return this.onSignUpSuccess();
          }
          if (this.$route && this.$route.query && this.$route.query.redirect && !this.isAlexaFlow) {
            return this.$router.push({ path: '/user-categories', query: { showPaymentForm: true } });
          }
          if (this.isAlexaFlow) {
            return this.redirectUserToAlexa();
          }
          return this.$router.push('/user-categories');
        })
        .catch((error) => {
          this.loading = false;
          this.showErrorMsg(error);
        });
    },
    trackAccountCreatedData(resp) {
      const data = {
        platform: localStorage.getItem('login_type'),
        email: resp.user.email,
        marketing_consent: resp.marketing_consent,
      };
      this.$analytics.track('ga_account_created', data);
      this.$analytics.track('Account Created', data);
      this.$analyticsV2.logEvent('cf_signup_successful', {
        email: data.email,
        marketing_consent: data.marketing_consent,
        signup_method: data.platform,
      });
    },
    redirectUserToAlexa() {
      const { state } = this.$route.query;
      const redirectUri = this.$route.query.redirect_uri;
      if (this.userProvider.isValidAlexaRedirectUri(redirectUri)) {
        const url = new URL(redirectUri);
        url.searchParams.set('state', state);
        url.searchParams.set('code', this.userProvider.getRefreshToken());
        window.location = url.href;
      }
    },
    checkValidation() {
      if (this.user.password.length > 7) {
        this.$refs.user.validate((valid) => { this.form_valid = valid; });
      }
    },
    getBrowsersFingerPrintId() {
      if (!window.branch || (window.branch && !window.branch.getBrowserFingerprintId)) {
        return;
      }
      window.branch.getBrowserFingerprintId((err, data) => {
        if (data) {
          localStorage.setItem('branch_fingerprint_id', data);
          this.fingerprint_id = data;
        }
      });
    },
    logBranchCustomEvent(resp) {
      const customData = {
        user_uuid: resp.user.uuid,
        user_email: resp.user.email,
        branch_promo: localStorage.getItem('branch_promo'),
      };
      this.branchService.logCustomEvent(this.branchService.accountCreatedEventName,
        customData);
    },
    loginWithFB() {
      this.userProvider.loginWithFb().then(() => {
        this.registerToLuminary();
        localStorage.setItem('login_type', 'Facebook');
      }).catch(this.firebaseAuthErrorHandler);
    },
    loginWithGoogle() {
      this.userProvider.loginWithGoogle().then(() => {
        this.registerToLuminary();
        localStorage.setItem('login_type', 'Google');
      }).catch(this.firebaseAuthErrorHandler);
    },
    loginWithApple() {
      this.userProvider.loginWithApple().then(() => {
        this.registerToLuminary();
        localStorage.setItem('login_type', 'Apple');
      }).catch(this.firebaseAuthErrorHandler);
    },
    loginWithEmail() {
      this.loginFlow = true;
      this.$emit('stepThreeMethod');
    },
    cancelLoginWithEmail() {
      this.loginFlow = false;
    },
    firebaseAuthErrorHandler(error) {
      if (error.code !== 'auth/popup-closed-by-user') {
        this.showErrorMsg(error.message);
      }
    },
    setEmail() {
      this.user.email = this.emailSuggestion;
      this.emailSuggestion = undefined;
    },
  },
};
</script>

<style lang="scss">
@import "../../assets/scss/variables.scss";
.signup-page {
  margin: 72px auto;
  @media(max-width: $sm) {
    max-width: 90vw !important;
  }
  h4 {
    margin: 64px 0 32px 0;
    @media (max-width: $xs) {
      margin: 32px 0 8px 0;
    }
  }
  .el-checkbox__input.is-checked+.el-checkbox__label {
    color: $black !important;
  }
  .el-checkbox__inner {
    width: 18px;
    height: 18px;
    &::after {
      width: 6px;
      height: 8px;
    }
  }
  .consent {
    margin-top: 10px;
    text-align: center;
    .bold {
      color: black;
    }
  }
  .action-buttons {
    margin-top: 24px;
    margin-bottom: 24px;
  }
  .el-form-item.is-info {
    .el-input__inner {
      border-color: #ED6D00;
    }
  }
  .email-suggestion {
    color: #ED6D00;
    a {
      color: #000;
      cursor: pointer !important;
      text-decoration: underline;
    }
  }
}

.signup-acquisition {
  @media(max-width: $sm) {
    max-width: 90vw !important;
  }
  .btn-link {
    color: $blue !important;
  }
  p {
    color: #C4C4C4;
  }
  .account {
    margin-top: 36px;
    p {
      color: white;
    }
  }
  h4 {
    margin: 64px 0 32px 0;
    @media (max-width: $xs) {
      margin: 32px 0 8px 0;
    }
  }
  .el-checkbox__input.is-checked+.el-checkbox__label {
    color: $black !important;
  }
  .el-form-item__error {
    color: $gray;
  }
  .el-checkbox__inner {
    width: 18px;
    height: 18px;
    &::after {
      width: 6px;
      height: 8px;
    }
  }
  .consent {
    margin-top: 10px;
    text-align: center;
    font-family: $clear-sans;
    color: $white;
    .bold {
      color: $white;
    }
    p {
      font-family: $clear-sans;
      color: #C4C4C4;
      font-size: 12px;
    }
  }
  .action-buttons {
    margin-top: 24px;
    margin-bottom: 24px;
  }
  .auth-page {
    max-width: 414px;
    width: 100%;
    margin: auto;
    margin-bottom: 128px;
  }
  .el-form-item {
    .el-form-item__label {
      font-size: 16px;
      line-height: 28px;
      color: $white;
      font-family: $clear-sans;
      &:before {
        content: none !important;
      }
    }
    .el-form-item {
      margin-bottom: 0px !important;
    }
    .el-input,
    .el-select {
      font-size: 16px;
      width: 100%;
    }
    .el-input__inner {
      height: 48px;
      line-height: 48px;
      border-radius: 8px;
      color: $black;
    }
    .helper-text,
    .error-msg {
      font-size: 13px;
      line-height: 18px;
      color: $gray;
    }
    .error-msg {
      color: #f56c6c;
    }
    a {
      cursor: pointer;
      text-decoration: underline;
    }
  }
  .el-form-item.no-margin {
    margin-bottom: 0;
  }
  .el-form-item.is-error {
    .helper-text {
      color: #f56c6c;
    }
  }
  .sub-header {
    color: $black;
    margin-bottom: 40px;
  }
  .action-buttons {
    .el-button.is-disabled,
    .el-button.is-disabled:hover {
      background-color: #AFE7F1;
      border-color: #AFE7F1;
    }
  }
  .sm-auth {
    margin: 30px 0;
    &__button {
      width: 299px;
      color: white;
      background-color: white;
      font-size: 16px;
      font-weight: 500;
      line-height: 20px;
      border-radius: 30px;
      display: block;
      padding: 16px 0;
      min-width: 300px;
      position: relative;
      letter-spacing: 0.5px;
      cursor: pointer;
      text-decoration: none;
      border: 1px solid #F3F3F3;
      box-sizing: border-box;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      color: $black;
      text-align: left;
      &--icon {
        color: #FFFFFF;
        font-weight: bold;
        position: absolute;
        left: 30px;
      }
      &--text {
        margin-left: 70px;
      }
    }
  }
  .apple {
    animation: slide-in .7s forwards;
  }
  .fb {
    animation: slide-in 1s forwards;
  }
  .google {
    animation: slide-in 1.3s forwards;
  }
  .email {
    animation: slide-in 1.6s forwards;
  }
  .fade {
    animation: 1s ease 0s normal forwards 1 fadein;
  }
  .sm-instructions {
    margin: 40px auto;
    display: block;
    text-align: center;
    height: 1px;
    background-color: #DDDFE6;
    position: relative;
    p {
      position: absolute;
      left: 50%;
      margin-top: -10px;
      transform: translateX(-50%);
      background: white;
      padding: 0 10px;
      font-size: 16px;
      color: black;
    }
  }
}

@-webkit-keyframes slide-in {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0%);
  }
}

@-webkit-keyframes fadein {
  0% {
    opacity: 0;
  }
  40% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}


</style>
