import AxiosAPI from '../services/AxiosAPI';
import EventTracking from './eventTracking';

/* global analytics */

export default {
  axiosAPI: new AxiosAPI(),
  eventTracking: new EventTracking(),
  retries: 0,

  isAdBlockerDetected() {
    // If we can find this id, that means our adframe.js file loaded successfully.
    // This indicates that adblocker is not present
    if (document.getElementById('aygHvTNdwLZt')) {
      return false;
    }
    return true;
  },

  trackLogin(event) {
    this.eventTracking.mparticleLogin(event);
  },

  async track(event, attributes) {
    await this.eventTracking.trackEvent(event, attributes);
    await this.call('track', event, attributes);
  },

  page(pageName, params) {
    this.eventTracking.trackPage(pageName, params);
    this.call('page', pageName, {
      name: pageName,
      title: document.title,
      url: window.location.href,
      search: window.location.search,
      referrer: document.referrer,
      path: window.location.pathname,
      ...params,
    });
  },

  identify(userId, attributes) {
    const { user } = window.$store.getters;
    attributes = attributes || {};
    if (user) {
      attributes.email = user.email;
    }
    this.call('identify', userId, attributes, {});
    // identify user in full story
    if (window.FS) {
      if (user && user.first_name && user.last_name) {
        attributes.displayName = `${user.first_name} ${user.last_name}`;
      }
      window.FS.identify(userId, attributes);
    }
  },

  async call(method, event, attributes, additionalData) {
    // let context = null;
    // const gaCookie = this.getGACookie();
    // if (gaCookie) {
    //   context = {
    //     context: {
    //       'Google Analytics': {
    //         clientId: gaCookie,
    //       },
    //     },
    //   };
    // }
    const { acceptedCookiePolicy, country } = window.$store.getters;
    const episodeStreamEvents = ['Episode Playback Speed', 'Episode Rewind', 'Episode Fast Forward', 'Episode Scrub', 'Episode Play', 'Episode Stop', 'Episode Heartbeat'];
    if (!acceptedCookiePolicy && country === 'GB' && episodeStreamEvents.indexOf(event) < 0) {
      return;
    }

    if (!this.isAdBlockerDetected() && episodeStreamEvents.indexOf(event) < 0 && window.analytics) {
      await analytics[method](event, attributes, additionalData);
    }
  },

  getGACookie() {
    let GACookie = null;
    let cookies = document.cookie;
    const cookiesMap = {};
    cookies = cookies.split('; ');
    if (cookies.length) {
      cookies.forEach((c) => {
        c = c.split('=');
        const [key, value] = c;
        cookiesMap[key] = value;
      });
    }
    const { _ga } = cookiesMap;
    if (_ga && _ga.length) {
      GACookie = _ga.substr(6);
    }
    return GACookie;
  },

  getParamsByMethod(method, event, attributes) {
    let params = {};
    attributes = attributes || {};
    switch (method) {
      case 'page':
        params = {
          page_name: attributes.name,
          page_title: attributes.title,
          page_url: attributes.url,
        };
        break;
      case 'identify':
        params = attributes;
        break;
      case 'track':
        params = {
          event_type: event,
          event_params: attributes,
        };
        break;
      default:
        break;
    }
    return params;
  },

  // loadSegmentAnonId() {
  //   const ctx = this;
  //   if (window.analytics && window.analytics.user && window.$store) {
  //     const anonymouseId = window.analytics.user().anonymousId();
  //     window.$store.dispatch('setSegmentAnonId', anonymouseId);
  //   } else if (ctx.retries < 5) {
  //     ctx.retries++;
  //     setTimeout(() => ctx.loadSegmentAnonId(), 300);
  //   }
  // },
};
