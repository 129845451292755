const getDefaultState = () => ({
  lastPlayedEpisode: null,
  episode: {},
  nextEpisode: {},
  progress: 0,
  play: false,
  canPlay: false,
  status: '',
  seeking: false,
  sessionId: '',
  reloadNext: false,
  isLastPlayed: false,
  playbackSpeed: 1.0,
  screenName: null,
  playerExpanded: false,
  trailerAsEpisode: false,
  hidePlayer: false,
  hlsFileUnavailable: {},
});

const getters = {
  lastPlayedEpisode: state => state.lastPlayedEpisode,
  episode: state => state.episode,
  nextEpisode: state => state.nextEpisode,
  progress: state => state.progress,
  play: state => state.play,
  canPlay: state => state.canPlay,
  status: state => state.status,
  seeking: state => state.seeking,
  sessionId: state => state.sessionId,
  reloadNext: state => state.reloadNext,
  isLastPlayed: state => state.isLastPlayed,
  playbackSpeed: state => state.playbackSpeed,
  screenName: state => state.screenName,
  playerExpanded: state => state.playerExpanded,
  trailerAsEpisode: state => state.trailerAsEpisode,
  hidePlayer: state => state.hidePlayer,
  isHlsFileUnavailable: state => filename => state.hlsFileUnavailable[filename],
};

const mutations = {
  setLastPlayedEpisode: (state, episode) => { state.lastPlayedEpisode = episode; },
  setEpisode: (state, episode) => { state.episode = episode; },
  setNextEpisode: (state, nextEpisode) => { state.nextEpisode = nextEpisode; },
  setProgress: (state, progress) => { state.progress = progress; },
  setPlay: (state, play) => { state.play = play; },
  setCanPlay: (state, canPlay) => { state.canPlay = canPlay; },
  setStatus: (state, status) => { state.status = status; },
  setSeeking: (state, seeking) => { state.seeking = seeking; },
  setSessionId: (state, sessionId) => { state.sessionId = sessionId; },
  setReloadNext: (state, reloadNext) => { state.reloadNext = reloadNext; },
  setIsLastPlayed: (state, flag) => { state.isLastPlayed = flag; },
  setPlaybackSpeed: (state, playbackSpeed) => { state.playbackSpeed = playbackSpeed; },
  setScreenName: (state, screenName) => { state.screenName = screenName; },
  togglePlayer: (state, manualValue) => { state.playerExpanded = (manualValue || !state.playerExpanded); },
  resetPlayerState: (state) => {
    Object.assign(state, getDefaultState());
  },
  setTrailerAsEpisode: (state, trailerAsEpisode) => { state.trailerAsEpisode = trailerAsEpisode; },
  setHidePlayer: (state, bool) => { state.hidePlayer = bool; },
  setHlsFileUnavailable: (state, filename) => {
    state.hlsFileUnavailable[filename] = true;
  },
};

const state = getDefaultState();

const actions = {
  setLastPlayedEpisode: (context, episode) => {
    context.commit('setLastPlayedEpisode', episode);
  },
  setEpisode: (context, episode) => {
    context.commit('setEpisode', episode);
    context.dispatch('generateSessionId');
    if (!context.state.playerExpanded) {
      context.dispatch('togglePlayer', true);
    }
  },
  setNextEpisode: (context, nextEpisode) => {
    context.commit('setNextEpisode', nextEpisode);
  },
  setAndPlayEpisode: (context, episode) => {
    context.commit('setPlay', false);
    context.commit('setCanPlay', true);
    context.commit('setEpisode', episode);
    context.dispatch('generateSessionId');
    context.commit('setScreenName', episode.screen_name);
  },
  setProgress: (context, progress) => {
    context.commit('setProgress', progress);
  },
  play: (context) => {
    context.commit('setCanPlay', true);
    context.commit('setPlay', true);
    if (!context.state.playerExpanded) {
      context.dispatch('togglePlayer', true);
    }
  },
  pause: (context) => {
    context.commit('setPlay', false);
    context.commit('setCanPlay', false);
  },
  setStatus: (context, status) => {
    context.commit('setStatus', status);
  },
  setSeeking: (context, seeking) => {
    context.commit('setSeeking', seeking);
  },
  /* eslint-disable */
  generateSessionId: (context) => {
    let dt = new Date().getTime();
    const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
      const r = (dt + Math.random()*16)%16 | 0;
      dt = Math.floor(dt / 16);
      return (c === 'x' ? r : (r&0x3|0x8)).toString(16);
    });
    context.commit('setSessionId', uuid);
  },
  /* eslint-enable */
  setReloadNext: (context) => {
    context.commit('setReloadNext', true);
  },
  setIsLastPlayed: (context, flag) => {
    context.commit('setIsLastPlayed', flag);
  },
  setPlaybackSpeed: (context, playbackSpeed) => {
    context.commit('setPlaybackSpeed', playbackSpeed);
  },
  togglePlayer: (context, manualValue) => {
    context.commit('togglePlayer', manualValue);
  },
  resetUserPlayerState: (context) => {
    context.commit('resetPlayerState');
  },
  setTrailerAsEpisode: (context, trailerAsEpisode) => {
    context.commit('setTrailerAsEpisode', trailerAsEpisode);
  },
  toggleHidePlayer: (context, bool) => {
    context.commit('setHidePlayer', bool);
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
