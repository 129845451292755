import {
  Autocomplete,
  Row,
  Col,
  Container,
  Header,
  Main,
  Button,
  Checkbox,
  Collapse,
  CollapseItem,
  DatePicker,
  Radio,
  RadioGroup,
  Input,
  Select,
  Slider,
  Form,
  FormItem,
  Progress,
  Option,
  Pagination,
  Alert,
  Menu,
  MenuItem,
  Breadcrumb,
  BreadcrumbItem,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  Dialog,
  Tooltip,
  Popover,
  Card,
  Switch,
  Tabs,
  TabPane,
} from 'element-ui';

export default [
  Autocomplete,
  Row,
  Col,
  Container,
  Header,
  Main,
  Button,
  Checkbox,
  Collapse,
  CollapseItem,
  DatePicker,
  Radio,
  RadioGroup,
  Input,
  Select,
  Slider,
  Form,
  FormItem,
  Progress,
  Option,
  Pagination,
  Alert,
  Menu,
  MenuItem,
  Breadcrumb,
  BreadcrumbItem,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  Dialog,
  Tooltip,
  Popover,
  Card,
  Switch,
  Tabs,
  TabPane,
];
