<template>
  <section
    v-check-visible-viewport="onVisibleInViewport"
    :class="`${bucket && bucket.media_size ? ` podcast-list-${bucket.media_size}` : ''}${hasHoverState ? ' carousel-has-hoverstate': ''}`"
    class="container component-list podcast-list"
    data-test="list-podcast">
    <div
      v-if="isOriginal"
      class="clipped-overlay-left"/>
    <div
      v-if="isOriginal"
      class="clipped-overlay-right"/>
    <header
      v-if="bucket.name"
      :class="['component-list__header', 'bucket-title', { 'hide-title': hideSectionTitle}]">
      <h1
        data-test="titlebucket"
        class="component-list__title">
        {{ bucket.name }}
      </h1>
      <p
        v-button-event="{buttonText: 'see all podcasts', buttonLink: 'search_results_shows'}"
        v-if="see_all"
        class="component-list__cta"
        data-test="see-all-shows"
        @click="gotoPage(see_all_link)">
        SEE ALL <i class="el-icon-caret-right el-icon-right"/>
      </p>
    </header>
    <flickity
      v-if="!isStacked"
      ref="flickity"
      :options="flickityOptions"
      :key="bucketItems.length">
      <div
        v-for="(podcast, index) in bucketItems"
        :key="index"
        :data-segment-uuid="podcast.uuid"
        class="carousel-container"
        data-test="carousel-container"
        @mouseover="() => cardHover(index)"
        @mouseleave="() => cardLeave(index)">
        <podcast-card
          v-if="!isExclusive && !isOriginal"
          :redirect="redirect"
          :podcast="podcast"
          :publisher-name="podcast.publisher_name || ''"
          :type="podcast.item_type || podcast.type"
          :bucket="bucketData"
          :media-size="bucket && bucket.media_size ? bucket.media_size : ''"
          :key="index"
          :first-item="index === 0"
          :has-hover-state="hasHoverState"
          data-test="podcast-card"/>
        <podcast-card
          v-if="isOriginal"
          :show-title="false"
          :redirect="redirect"
          :podcast="podcast"
          :publisher-name="podcast.publisher_name || ''"
          :type="podcast.item_type || podcast.type"
          :bucket="bucketData"
          :media-size="bucket && bucket.media_size ? bucket.media_size : ''"
          :key="index"
          :first-item="index === 0"
          :has-hover-state="hasHoverState"
          :is-original="true"
          data-test="podcast-card"/>
        <exclusive-card
          v-if="isExclusive"
          :podcast="podcast"
          :bucket="bucketData"
          :key="index"
          :first-item="index === 0"
          display-type="expanded"
          data-test="exclusive-card"/>
      </div>
    </flickity>
    <flickity
      v-if="isStacked"
      ref="flickity"
      :options="flickityOptions"
      :key="stackedBucketItems.length">
      <div
        v-for="(podcastSet, index) in stackedBucketItems"
        :key="index"
        class="carousel-container"
        data-test="carousel-container">
        <stacked-podcast-card
          :redirect="redirect"
          :podcasts="podcastSet"
          :bucket="bucketData"
          :media-size="bucket && bucket.media_size ? bucket.media_size : ''"
          :key="index"
          :first-item="index === 0"
          :has-hover-state="hasHoverState"
          data-test="podcast-card"/>
      </div>
    </flickity>
  </section>
</template>
<script>
import * as log from 'loglevel';
import Flickity from 'vue-flickity';
import PodcastCard from './PodcastCard';
import ExclusiveCard from './ExclusiveCard';
import StackedPodcastCard from './StackedPodcastCard';
import CarouselMixin from '../shared/carouselMixin';

export default {
  components: {
    Flickity,
    'podcast-card': PodcastCard,
    'exclusive-card': ExclusiveCard,
    'stacked-podcast-card': StackedPodcastCard,
  },
  mixins: [CarouselMixin],
  props: {
    hasHoverState: {
      type: Boolean,
      default: true,
    },
    podcast_offset: {
      type: Number,
      default: 0,
    },
    current_page: {
      type: Number,
      default: 0,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    publisher_uuid: {
      type: String,
      default: '',
    },
    see_all: {
      type: Boolean,
      default: false,
    },
    see_all_link: {
      type: String,
      default: '',
    },
    isStacked: {
      type: Boolean,
      default: false,
    },
    isExclusive: {
      type: Boolean,
      default: false,
    },
    isOriginal: {
      type: Boolean,
      default: false,
    },
    hideSectionTitle: {
      type: Boolean,
      default: false,
    },
    handleCardHover: {
      type: Function,
      default: null,
    },
    bucket: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      flickityOptions: {
        prevNextButtons: true,
        freeScroll: true,
        pageDots: false,
        wrapAround: false,
        cellAlign: 'left',
        initialIndex: 0,
        contain: true,
        draggable: true,
        groupCells: true,
      },
      redirect: true,
      arrow_hidden: false,
      drag_end: false,
      scrollCount: 1,
      prevIndex: 0,
      itemsShown: 0,
      scroll_sensitivity: 500, // more means less sensitive
      initiallyRenderedItems: 10,
      initialBucketItems: [],
    };
  },
  computed: {
    stackedBucketItems() {
      return this.bucketItems.map((item, idx, arr) => {
        if (idx % 2 === 0) {
          const items = [item];
          if (arr[idx + 1]) items.push(arr[idx + 1]);
          return items;
        }
        return null;
      }).filter(item => item);
    },
  },
  watch: {
    bucketItems() {
      if (this.bucketItems.length) {
        this.reloadCells();
      }
    },
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
    this.removeResizeEvent();
  },
  methods: {
    init() {
      if (this.bucket.items && this.bucket.items.length) {
        this.bucketItems = this.bucket.items;
        this.initialBucketItems = this.bucketItems.slice(0, this.initiallyRenderedItems);
        this.bucketData = { ...this.bucket };
        this.bucketData.items = [];
        this.bucketData.position = this.$vnode.key;
        this.bucketData.items_count = this.bucket.items.length;
        this.dragStart();
        this.carouselSettled();
        this.arrowVisibility();
        this.sliderChange('bucketItems', 'initialBucketItems');
      }
    },
    cardHover(cardIndex) {
      if (this.handleCardHover) {
        this.handleCardHover(cardIndex);
      }
    },
    cardLeave() {
      if (this.handleCardHover) {
        this.handleCardHover(null);
      }
    },
    reloadCells() {
      this.$refs.flickity.reloadCells();
      this.$forceUpdate();
    },
    disableVerticalScrolling() {
      if (!window || !window.isTouch || !this.isBrowserSafari()) return;
      // Disable vertical scrolling during horizontal swipe. Introduced for safari.
      window.addEventListener('touchstart', this.touchStart);
      window.addEventListener('touchmove', this.preventTouch, { passive: false });
    },
    enableVerticalScrolling() {
      window.removeEventListener('touchstart', this.touchStart);
      window.removeEventListener('touchmove', this.preventTouch);
    },
    touchStart(e) {
      this.firstClientX = e.touches[0].clientX;
      this.firstClientY = e.touches[0].clientY;
    },
    /* eslint-disable */
    preventTouch(e) {
      const minValue = 5; // threshold

      this.clientX = e.touches[0].clientX - this.firstClientX;
      this.clientY = e.touches[0].clientY - this.firstClientY;

      // Vertical scrolling does not work when you start swiping horizontally.
      if (Math.abs(this.clientX) > minValue) {
        e.preventDefault();
        e.returnValue = false;
        return false;
      }
    },
    dragStart() {
      this.$nextTick()
        .then(() => {
          this.$refs.flickity.on('dragStart', () => {
            this.drag_end = false;
            this.redirect = false;
          });
        })
        .catch((err) => { log.warn(err.message); })
    },
    dragEnd() {
      this.$nextTick()
        .then(() => {
          this.$refs.flickity.on('dragEnd', () => {
            this.drag_end = true;
          });
        })
        .catch((err) => { log.warn(err.message); })
    },
    carouselSettled() {
      this.$nextTick()
        .then(() => {
          this.$refs.flickity.on('settle', (currIndex) => {
            this.triggerSegmentContentViewedEvent();
            this.scrollCount = currIndex + 1;
            this.redirect = true;
          });
        })
        .catch((err) => { log.warn(err.message); });
    },
    scrollPodcasts() {
      this.$nextTick()
        .then(() => {
          this.$refs.flickity.on('scroll', (event) => {
            if (event >= 0.9) {
              this.$emit('scroll-right');
            }
          });
        })
        .catch((err) => { log.warn(err.message); });
    },
    mouseScroll(e) {
      if (Math.abs(e.wheelDeltaY)) {
        e.stopPropagation();
        return;
      }
      if (e.wheelDeltaX > 0) {
        const selectedCell = (this.itemsShown * (this.scrollCount - 1)) - 1;
        this.$refs.flickity.selectCell(selectedCell);
      } else {
        const selectedCell = (this.itemsShown * this.scrollCount) + 1;
        this.$refs.flickity.selectCell(selectedCell);
      }
    },
    bindMouseWheelEvent() {
      if (this.$refs.flickity) {
        this.$refs.flickity.$el.addEventListener('wheel', this.mouseScroll);
      } else {
        log.info('Flickity Not Yet Rendered');
      }
    },
    removeMouseWheelEvent() {
      if (this.$refs.flickity) {
        this.$refs.flickity.$el.removeEventListener('wheel', this.mouseScroll);
      } else {
        log.info('Flickity event does not exists');
      }
    },
    arrowVisibility() {
      this.checkWindowSizes();
      window.addEventListener('resize', this.checkWindowSizes);
    },
    removeResizeEvent() {
      window.removeEventListener('resize', this.checkWindowSizes);
    },
    checkWindowSizes() {
      if (!this.$refs.flickity) return;
      this.$nextTick()
        .then(() => {
          const pl = this.$el;
          const pc = this.$el.querySelector('.podcast-card');
          if (!pl || !pc) return;
          const a = pl.clientWidth;
          const b = pc.clientWidth;
          const c = Math.floor(a / b);
          this.itemsShown = c;
          if (this.bucketItems.length <= c || this.bucketItems.length < 3) {
            this.hideArrows();
            // this.removeMouseWheelEvent();
          } else {
            this.showArrows();
            // this.bindMouseWheelEvent();
          }
        })
        .catch(err => log.info(err.message));
    },
    hideArrows() {
      const a = this.$el.querySelector('.flickity-button.next');
      const b = this.$el.querySelector('.flickity-button.previous');
      if (a) this.$el.querySelector('.flickity-button.next').className += ' d-none';
      if (b) this.$el.querySelector('.flickity-button.previous').className += ' d-none';
    },
    showArrows() {
      const elem = this.$el.querySelectorAll('.flickity-button.next,.flickity-button.previous');
      if (!elem[0] || !elem[1]) return;
      if (elem[0].classList.contains('d-none')) {
        this.$el.querySelector('.flickity-button.next').classList.remove('d-none');
      }
      if (elem[1].classList.contains('d-none')) {
        this.$el.querySelector('.flickity-button.previous').classList.remove('d-none');
      }
    },
    gotoPage(link) {
      this.$emit('moreClicked');
      if (link) this.$router.push(link);
    },
  },
};
</script>
<style lang="scss">
@import "../assets/scss/variables.scss";

.podcast-list {
  /* Carousels that get a zoomed over hover state get this class */
  .clipped-overlay-left {
    position: absolute;
    margin-top: 38px;
    height: 397px;
    width: 30vw;
    background-color: $bg-color;
    background: linear-gradient(90deg, rgba(18,28,48,1) 87%, rgba(18,28,48,0) 100%);
    margin-left: -30vw;
    box-sizing: border-box;
    z-index: 1;
    opacity: .9;
    @media (max-width: 768px) {
      height: 230px;
    }
  }
  .clipped-overlay-right {
    position: absolute;
    margin-top: 38px;
    height: 397px;
    width: 30vw;
    right: calc(-30vw + 5%);
    background-color: $bg-color;
    background: linear-gradient(90deg, rgba(18,28,48,0) 0%, rgba(18,28,48,1) 14%);;
    box-sizing: border-box;
    z-index: 1;
    opacity: .9;
    @media (max-width: 768px) {
      height: 230px;
    }
  }
  &.carousel-has-hoverstate {
    .bucket-title {
      font-size: 36px;
      line-height: 40px;
      font-weight: bold;
      margin: 0 0 -70px; /* negative value makes room for hover state expansion */
      transition: 120ms ease-in-out;
      z-index: 90;
      &.hide-title {
        opacity: 0;
      }
      @media (max-width: 768px) {
        margin-bottom: -50px;
      }

      @media (max-width: $xs) {
        font-size: 26px;
        line-height: 35px;
        margin-bottom: 10px;
      }
    }

    div.flickity-viewport {
      padding: 0 0 100px; /* add padding for hover state expansion */
      margin-top: 80px;
      overflow: visible;

      @media (max-width: 768px) {
        padding: 0 0 30px;
        margin-top: 60px;
      }

      @media (max-width: $xs) {
        padding: 0 0 15px;
        margin-top: 0px;
      }
    }
  }

  .flickity-enabled {
    .flickity-button {
      z-index: 99;
      top: 32%;
      background-color: rgba(255,255,255, 0.9) !important;
      box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.2), 0px 3px 14px rgba(0, 0, 0, 0.12), 0px 8px 10px rgba(0, 0, 0, 0.14);
      &:hover {
        background-color: $blue !important;
        svg {
          fill: $white;
        }
      }
      @media (min-width: 768px) {
        display: block !important;
        &[disabled] {
          display: none !important;
        }
      }
      svg {
        fill: #00AECB;
        width: 50%;
        height: 50%;
        left: 25%;
        top: 25%;
      }
    }
  }
}
</style>
