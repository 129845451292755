const getters = {
  discover: state => state.discover,
};

const mutations = {
  setDiscover: (state, discover) => { state.discover = discover; },
};

const state = {
  discover: [],
};

const actions = {
  setDiscover: (context, discover) => {
    context.commit('setDiscover', discover);
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
