<template>
  <section class="container">
    <el-row
      type="flex"
      justify="center">
      <grid-col
        :xs="24"
        :sm="24"
        :md="23"
        :lg="22"
        :xl="22">
        <div class="loading-container">
          <div class="title pulse" />
          <loading-items :num-loading="numLoading" />
        </div>
      </grid-col>
    </el-row>
  </section>
</template>

<script>
import LoadingItems from './carousel/LoadingItems';

export default {
  name: 'LoadingLayout',
  components: {
    'loading-items': LoadingItems,
  },
  props: {
    numLoading: {
      type: Number,
      default: 8,
    },
  },
};

</script>

<style scoped lang="scss">
@import "../../assets/scss/variables.scss";

.loading-container {
  width: 100%;
  height: 100%;
  overflow: visible;
  .title {
    height: 40px;
    width: 340px;
    background-color: #343B4A;
    margin: 20px 0;
    @media (max-width: $xs) {
      height: 23px;
      width: 200px;
      margin: 16px 0;
    }
  }
  .pulse {
    $from: #343B4A;
    $to: scale-color($from, $lightness: -10%);

    // height: 100%;
    // width: 100%;
    background: linear-gradient(
      -90deg,
      #2B3345 0%,
      #2E3950 50%,
      #2B3345 100%
    );
    background-size: 400% 400%;
    animation: pulse 2s ease-in-out infinite;
    @keyframes pulse {
      0% {
        background-position: 0% 0%;
      }
      100% {
        background-position: -135% 0%;
      }
    }
  }
}
</style>
