<template>
  <div
    :class="'hidden-preloaded-page' + (visible ? ' page-visible' : '')">
    <div
      v-if="mountComponent"
      class="premium-buy-page"
      data-test="page-premium">
      <div class="m-t-2">
        <payment-info-adyen
          v-if="isAdyenDropInEnabled"
          heading="Billing Information"
          @success="successAction"
        />
        <add-payment-info
          v-else
          heading="Billing Information"
          @success="successAction"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import UpdatePaymentInfo from '../../components/subscription/UpdatePaymentInfo';
import PaymentInfoAdyen from '../../components/subscription/PaymentInfoAdyen';

export default {
  name: 'PreloadAdyenBuyPremiumPage',
  components: {
    'add-payment-info': UpdatePaymentInfo,
    PaymentInfoAdyen,
  },
  data() {
    return {
      pageName: 'Premium Subscription',
      visible: false,
      mountToDom: true,
    };
  },
  computed: {
    ...mapGetters([
      'isAdyenDropInEnabled',
    ]),
    mountComponent() {
      const { user, featureFlag, userSubscription } = this.$store.getters;
      const isSubscribed = user && user.is_subscribed;
      const preloadPayment = featureFlag('preloadPayment', { impression: false });
      const isCancelled = userSubscription && userSubscription.is_cancelled;
      const { mountToDom } = this;

      return (!isSubscribed || isCancelled) && preloadPayment && mountToDom;
    },
    user() {
      const { user } = this.$store.getters;

      return user;
    },
    featureEnabled() {
      return this.$store.getters.featureFlag('preloadPayment', { impression: false });
    },
  },
  watch: {
    $route(route) {
      const { path } = route;
      if (!this.mountToDom && !path.includes('/account')) {
        this.mountToDom = true;
      }
      const newVisibleValue = path === '/premium-buy' && this.featureEnabled;
      this.visible = newVisibleValue;
    },
  },
  beforeMount() {
    this.visible = this.$route.path === '/premium-buy' && this.featureEnabled;
    if (this.$route.path.includes('/account')) {
      this.mountToDom = false;
    }
    window.eventBus.$on('preload-payment-form', (toggle) => {
      this.mountToDom = toggle;
    });
  },
  methods: {
    successAction() {
      this.$analytics.track('Preloaded Payment Subscription');
      if (this.user && this.user.registration_state === 'onboarding_topics') {
        this.$router.push('/user-categories');
      } else {
        this.$router.push({ path: '/listen', query: { newSubscription: true } });
      }
    },
  },
};
</script>

<style lang='scss' scoped>
.hidden-preloaded-page {
  display: none;
}
.page-visible {
  display: block !important;
}
.premium-buy-page{
  max-width: 468px;
  width: 100%;
  margin: auto;
  padding: 52px 0 92px 0;
}
@media (max-width: 567px) {
  .premium-buy-page{
    width: auto;
    padding: 21px 32px;
  }
}
</style>
