import find from 'lodash/find';
import ShapeProductsResponse from '../../shared/ShapeProductsResponse';
import Subscription from '@/services/Subscription';
import HolidayPromo from '@/services/HolidayPromo';
import { getServices } from '@/services/serviceProvider';
import { applePodcastPlan, freePlan } from '../../shared/staticPlans';

const [subscriptionProvider, holidayPromo] = getServices([Subscription, HolidayPromo]);

const getters = {
  proposedPricingPlans: state => state.proposedPricingPlans,
  pricingPlans: (state, gtrs, rootState, rootGetters) => {
    const promocodes = rootGetters['promocode/promocodes'];
    const { country } = rootGetters;
    const processPlansWithPromocodes = (plan, codes) => {
      const applicableCode = codes.find((c) => {
        let res = c.country || c.plan_type;
        if (res && c.country && country !== c.country) res = false;
        if (res && c.plan_type && plan.planTypeString !== c.plan_type) res = false;
        return res;
      });
      if (!applicableCode) return plan;
      if (applicableCode.offer.type === 'trial') {
        plan.promoCode = applicableCode;
        plan.trialPeriodForDisplay = applicableCode.offer.value;
        if (/year/.test(plan.trialPeriodForDisplay)) plan.trialPeriodDays = Number(plan.trialPeriodForDisplay.match(/\d+/)[0] || 1) * 365;
        if (/month/.test(plan.trialPeriodForDisplay)) plan.trialPeriodDays = Number(plan.trialPeriodForDisplay.match(/\d+/)[0] || 1) * 30;
        if (/week/.test(plan.trialPeriodForDisplay)) plan.trialPeriodDays = Number(plan.trialPeriodForDisplay.match(/\d+/)[0] || 1) * 7;
        if (/day/.test(plan.trialPeriodForDisplay)) plan.trialPeriodDays = Number(plan.trialPeriodForDisplay.match(/\d+/)[0] || 1);
      }
      return plan;
    };
    return promocodes.length ? state.pricingPlans.map(p => processPlansWithPromocodes(p, promocodes)) : state.pricingPlans;
  },
  pricingPlansLoaded: state => state.pricingPlansLoaded,
  offers: state => state.offers,
  offersLoaded: state => state.offersLoaded,
  selectedPlan: state => state.selectedPlan,
  promoCode: state => state.promoCode,
  trialPeriodDays: (state) => {
    if (state.selectPricingPlan && state.selectPricingPlan.trialPeriodDays) {
      return state.selectPricingPlan.trialPeriodDays;
    }
    return 7;
  },
  marketingPrice: (state) => {
    if (state.pricingPlansLoaded) {
      return find(state.pricingPlans, { planTypeString: 'monthly' }, { price: 4.99 });
    }
    return { price: 4.99 };
  },
  freePlanSelected: state => (state.freePlanSelected || (state.selectedPlan && state.selectedPlan.planTypeString === freePlan.planTypeString)),
};

const mutations = {
  setProposedPricingPlans: (state, pricingPlans) => { state.proposedPricingPlans = pricingPlans; },
  setPricingPlans: (state, pricingPlans) => { state.pricingPlans = pricingPlans; },
  setPricingPlansLoaded: (state, pricingPlansLoaded) => { state.pricingPlansLoaded = pricingPlansLoaded; },
  selectPricingPlan: (state, plan) => { state.selectedPlan = plan; },
  selectFreePlan: (state) => { state.freePlanSelected = true; },
  deselectFreePlan: (state) => { state.freePlanSelected = false; },
  setOffers: (state, offers) => { state.offers = offers; },
  setOffersLoaded: (state, offersLoaded) => { state.offersLoaded = offersLoaded; },
  setPromoCode: (state, codeData) => { state.promoCode = codeData; },
};

const state = {
  pricingPlans: [],
  proposedPricingPlans: [],
  pricingPlansLoaded: false,
  selectedPlan: null,
  freePlanSelected: false,
  offers: [],
  offersLoaded: false,
  promoCode: null,
};

const actions = {
  convertPricingPlans: (context, { response, currencyCode, country }) => {
    const dataShaper = new ShapeProductsResponse();
    let pricingPlans = dataShaper.getPricingPlans({ response, currencyCode });
    const planOrder = {
      yearly: 1,
      'semi-annual': 2,
      monthly: 3,
    };
    pricingPlans.sort((a, b) => (planOrder[a.planTypeString] - planOrder[b.planTypeString]));
    if (context.getters.featureFlag('apple_podcasts') && context.getters.featureFlag('apple_podcasts_plan')) {
      pricingPlans.push(applePodcastPlan);
    }
    if (currencyCode === 'INR') {
      // hardcoded modification of India plans
      const newNames = {
        'Semi-Annual': '6 Months',
        Annual: '12 Months',
      };
      const isTimePrime = context.getters.promoData && context.getters.promoData.timesPrime;
      if (!isTimePrime) pricingPlans = pricingPlans.filter(p => p.planTypeString !== 'monthly');
      const maxMarketingPrice = Math.max(...pricingPlans.map(p => (p.marketing_price || 0)));
      pricingPlans = pricingPlans.map(p => ({
        ...p,
        displayName: newNames[p.displayName] || p.displayName,
        trialPeriodDays: isTimePrime ? p.trialPeriodDays : 0,
        trialPeriodForDisplay: isTimePrime ? p.trialPeriodForDisplay : '',
        displayPrice: p.planTypeString === applePodcastPlan.planTypeString ? '₹199/mo or ₹999/yr' : p.displayPrice,
        discountPercent: p.marketing_price ? Math.floor(((maxMarketingPrice - p.marketing_price) / maxMarketingPrice) * 100) : 0,
      }));
    }
    if (context.getters.isHolidayPromoEnabledForCountry(country) || context.getters.isHolidayPromoGiftEnabledForCountry(country)) {
      pricingPlans = holidayPromo.processPlans(pricingPlans, country);
    }
    return pricingPlans;
  },
  setPricingPlans: (context, { response, currencyCode, country }) => {
    context.dispatch('convertPricingPlans', { response, currencyCode, country }).then((pricingPlans) => {
      context.commit('setPricingPlans', pricingPlans);
      context.commit('setPricingPlansLoaded', true);
    });
  },
  selectPricingPlan: (context, plan) => {
    context.commit('selectPricingPlan', plan);
  },
  // saving promo code data in state
  setPromoCode: (context, codeData) => {
    context.commit('setPromoCode', codeData);
  },
  selectFreePlan: (context) => {
    context.commit('selectFreePlan');
  },
  deselectFreePlan: (context) => {
    context.commit('deselectFreePlan');
  },
  loadPricingPlans(context, { currency, country }) {
    if (context.getters.pricingPlansLoaded) return Promise.resolve(true);
    context.commit('setPricingPlansLoaded', false);
    return subscriptionProvider.getSubscriptionPlans(currency, country).then((resp) => {
      context.dispatch('setPricingPlans', { response: resp, currencyCode: currency, country });
    }).finally(() => context.commit('setPricingPlansLoaded', true));
  },
  invalidatePricingPlans(context) {
    context.commit('setPricingPlansLoaded', false);
  },
  loadProposedPricingPlans(context, { currency, country }) {
    context.commit('setPricingPlansLoaded', false);
    return subscriptionProvider.getSubscriptionPlans(currency, country)
      .then(resp => context.dispatch('convertPricingPlans', { response: resp, currencyCode: currency, country }))
      .then((pricingPlans) => {
        context.commit('setProposedPricingPlans', pricingPlans);
      })
      .finally(() => context.commit('setPricingPlansLoaded', true));
  },
  // loading all offers from backend
  loadOffers(context, { currency, country }) {
    context.commit('setOffersLoaded', false);
    return subscriptionProvider.getWebOffers(currency, country)
      .then((offers) => {
        context.commit('setOffers', offers.offers);
      })
      .finally(() => context.commit('setOffersLoaded', true));
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
