export default (_el, binding) => {
  const target = binding.value.replace('#', '');
  _el.onclick = () => {
    const targetEle = document.getElementById(target);
    setTimeout(() => {
      window.scrollTo({
        top: targetEle.offsetTop,
        left: 0,
        behavior: 'smooth',
      });
    });
  };
};
