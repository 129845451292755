function addDays(theDate, days) {
  return new Date(theDate.getTime() + days * 24 * 60 * 60 * 1000);
}

function minusDays(theDate, days) {
  return new Date(theDate.getTime() - days * 24 * 60 * 60 * 1000);
}

export default (val) => {
  const weekDays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

  if (val) {
    const date = new Date(val);
    const today = new Date();
    const yesterday = minusDays(today, 1);
    const tomorrow = addDays(today, 1);
    const startOfLastWeek = minusDays(today, 7);
    if (today.toDateString() === date.toDateString()) {
      return 'Today';
    }
    if (today.getTime() > date.getTime() && date.getTime() > startOfLastWeek.getTime()) {
      if (yesterday.getFullYear() === date.getFullYear()
          && yesterday.getMonth() === date.getMonth()
          && yesterday.getDate() === date.getDate()) {
        return 'Yesterday';
      }

      return weekDays[date.getDay()];
    }
    if (tomorrow.getFullYear() === date.getFullYear()
        && tomorrow.getMonth() === date.getMonth()
        && tomorrow.getDate() === date.getDate()) {
      return 'Tomorrow';
    }

    const formatedDate = `${months[date.getMonth()]} ${date.getDate()}, ${date.getFullYear()}`;
    return formatedDate;
  }

  return 'Date Not Available';
};
