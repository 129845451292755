export default {
  convertToSlug(str = '') {
    return str.toLowerCase().replace(/ /g, '-').replace(/[-]+/g, '-').replace(/[^\w-]+/g, '');
  },
  formatLegacyPodcastObject(podcast) {
    return {
      categories: {
        name: podcast.categoryName,
        slug: this.convertToSlug(podcast.categoryName),
        uuid: podcast.categoryId,
      },
      description: podcast.description,
      description_html: podcast.description,
      display_image_url: podcast.images.default,
      feed_type: podcast.playSequence,
      has_free_episodes: podcast.hasFreeEpisodes,
      image_url: podcast.images.default,
      is_exclusive: podcast.isExclusive,
      publisher: {
        name: podcast.publisherName,
        slug: this.convertToSlug(podcast.publisherName),
        uuid: podcast.publisherId,
      },
      slug: this.convertToSlug(podcast.title),
      title: podcast.title,
      uuid: podcast.id,
    };
  },

  formatLegacyEpisodeObject(episode) {
    return {
      released_at: episode.releasedAt,
      description: episode.description,
      season: {
        season: episode.season,
        podcast: {
          is_exclusive: episode.isShowExclusive,
          title: episode.title,
        },
      },
      title: episode.title,
      is_exclusive: episode.isExclusive,
      is_free: episode.isFree,
      media_url: episode.mediaUrl,
      runtime: episode.runtime,
      season_number: episode.season,
      display_image_url: episode.images.thumbnail || episode.images.default,
      number: episode.number,
      uuid: episode.id,
      podcast_uuid: episode.showId,
      show_title: episode.showTitle,
    };
  },

  formatLegacyTrailerObject(trailer, legacy_podcast) {
    return {
      display_image_url: trailer.images.thumbnail || trailer.images.default,
      image_url: trailer.images.thumbnail || trailer.images.default,
      uuid: trailer.id,
      is_exclusive: trailer.isExclusive,
      season: {
        podcast: legacy_podcast,
      },
      media_url: trailer.mediaUrl,
      released_at: trailer.releasedAt,
      runtime: trailer.runtime,
      podcast_is_exclusive: trailer.isShowExclusive,
      title: trailer.title,
      slug: this.convertToSlug(trailer.title),
      episode_type: trailer.type,
    };
  },
  getPodcastMetaTitle(podcastTitle, mediaType) {
    const hasPodcastInTitle = podcastTitle.includes('podcast') || podcastTitle.includes('Podcast');

    if (hasPodcastInTitle || mediaType === 'music') {
      return podcastTitle;
    }
    return `${podcastTitle} Podcast`;
  },
};
