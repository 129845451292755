export default function (price, currency, hidePeriod, planType) {
  if (!price) {
    price = 0;
  }
  let currencySymbol;
  let formattedAmount;
  if (!currency) {
    const { pricing } = window.$store.getters;
    const { defaultCurrency } = pricing;
    currency = defaultCurrency;
  }
  switch (currency) {
    case 'USD': {
      currencySymbol = '$';
      break;
    }
    case 'CAD': {
      currencySymbol = 'CAD$';
      break;
    }
    case 'GBP': {
      currencySymbol = '£';
      break;
    }
    case 'AUD': {
      currencySymbol = 'AU$';
      break;
    }
    case 'EUR': {
      currencySymbol = '€';
      break;
    }
    case 'NZD': {
      currencySymbol = 'NZ$';
      break;
    }
    case 'ZAR': {
      currencySymbol = 'R';
      break;
    }
    case 'SEK': {
      currencySymbol = 'kr';
      break;
    }
    case 'NOK': {
      currencySymbol = 'kr';
      break;
    }
    case 'DKK': {
      currencySymbol = 'kr';
      break;
    }
    case 'ISK': {
      currencySymbol = 'kr';
      break;
    }
    case 'INR': {
      currencySymbol = '₹';
      break;
    }
    default: {
      currencySymbol = '$';
    }
  }
  let planTypePeriod = '/month';
  if (planType) {
    planTypePeriod = `/${planType}`;
  }
  const zar = currency === 'ZAR';
  if (price < 0) {
    price *= -1;
    price = parseFloat(price).toFixed(2);
    formattedAmount = !hidePeriod ? `- ${currencySymbol}${zar ? ' ' : ''}${price}${planTypePeriod}` : `- ${currencySymbol}${zar ? ' ' : ''}${price}`;
    return formattedAmount;
  }
  price = parseFloat(price).toFixed(2);
  if (zar) {
    price = price.toString().split('.').join(',');
  }
  if (currencySymbol !== 'kr') {
    formattedAmount = !hidePeriod ? `${currencySymbol}${zar ? ' ' : ''}${price}${planTypePeriod}` : `${currencySymbol}${zar ? ' ' : ''}${price}`;
  } else {
    formattedAmount = !hidePeriod ? `${price} ${currencySymbol}${planTypePeriod}` : `${price} ${currencySymbol}`;
  }
  return formattedAmount;
}
