<template>
  <div class="theme-background">
    <holiday-promo-banner
      v-if="!sdpLoading && isHolidayPromoEnabled"
      :podcast="podcast"
    />
    <PremiumUpsellBanner
      v-else-if="!sdpLoading && !isHolidayPromoEnabled"
      :podcast="podcast" />
    <BannerLoading v-if="sdpLoading" />
    <Banner
      v-if="!sdpLoading"
      :breadcrumb-trail="breadcrumbTrail"
      :show-breadcrumb="!!podcast.categoryName"
      :show-data="podcast_legacy_formatted_object"
      type="podcast" />
    <HeroLoading v-if="sdpLoading" />
    <Hero
      v-if="!sdpLoading"
      :podcast="podcast"
      :show-data="podcast_legacy_formatted_object"
      :trailer="trailer"
      parent-page="sdp" />
    <FullCollectionCta
      v-if="!sdpLoading"
      :podcast="podcast" />
    <ListenFree
      v-if="!sdpLoading"
      :free-episodes="freeEpisodes"
      :podcast="podcast" />
    <EpisodeList
      v-if="!sdpLoading && !loadingEpisodes && !loadingUserBookmarks"
      :episodes="episodes"
      :episode-count="episodeCount"
      :podcast="podcast"
      :show-data="podcast_legacy_formatted_object"
      :user-bookmarks="userBookmarks"
      playlist-type="show" />
    <WidgetWrapper
      v-if="!sdpLoading && useCmsWidgets"
      :num-loading-widgets="numLoadingWidgets"
      cms-page="sdp" />
  </div>
</template>

<script>
import * as log from 'loglevel';
import { mapGetters } from 'vuex';
// services
import Podcast from '../../services/Podcast';
import Episode from '../../services/Episode';
import User from '../../services/User';
import Event from '../../services/PlayerEvent';
// components
import PremiumUpsellBanner from '../../components/webv2/shared/PremiumUpsellBanner';
import Banner from '../../components/webv2/shared/Banner';
import BannerLoading from '../../components/webv2/shared/BannerLoading';
import Hero from '../../components/webv2/showDetails/Hero';
import HeroLoading from '../../components/webv2/showDetails/HeroLoading';
import FullCollectionCta from '../../components/webv2/shared/FullCollectionCta';
import ListenFree from '../../components/webv2/showDetails/ListenFree';
import EpisodeList from '../../components/webv2/showDetails/EpisodeList';
import WidgetWrapper from '../../components/widgets/WidgetWrapper';
import HolidayPromoBanner from '../../components/webv2/shared/HolidayPromoBanner';
// helpers
import showDetailsHelper from '../../components/webv2/showDetails/showDetailsHelper';

export default {
  name: 'Webv2ShowDetails',
  components: {
    PremiumUpsellBanner,
    HolidayPromoBanner,
    Banner,
    BannerLoading,
    Hero,
    HeroLoading,
    FullCollectionCta,
    ListenFree,
    EpisodeList,
    WidgetWrapper,
  },
  data() {
    return {
      podcast_service: new Podcast(),
      episode_service: new Episode(),
      user_service: new User(),
      eventProvider: new Event(),
      podcast: {},
      podcast_legacy_formatted_object: {},
      loadingPodcast: true,
      episodes: [],
      episodeCount: 0,
      loadingEpisodes: true,
      isFirstEpisodesFetch: true,
      maxFreeEpisodes: 2,
      freeEpisodes: [],
      trailer: {},
      loadingTrailer: true,
      userBookmarks: [],
      loadingUserBookmarks: true,
      numLoadingWidgets: 1,
    };
  },
  computed: {
    ...mapGetters([
      'isHolidayPromoEnabled',
    ]),
    sdpLoading() {
      return this.loadingPodcast || this.loadingTrailer;
    },
    podcastUUID() {
      return this.$route.params.podcastID;
    },
    breadcrumbTrail() {
      return [
        {
          name: this.podcast.categoryName,
          link: `/listen/category/${this.convertToSlug(this.podcast.categoryName)}/${this.podcast.categoryId}`,
        },
        {
          name: this.podcast.title,
        },
      ];
    },
    useCmsWidgets() {
      const cms_widgets = this.$store.getters.flagWithDefault('cms_widgets', true);
      return cms_widgets;
    },
    userPremium() {
      return this.userSubscriptionState === 'premium' || this.userSubscriptionState === 'chrunedPremium';
    },
    getSortOrder() {
      if (this.userPremium) {
        return 'release_desc';
      }
      return 'free';
    },
  },
  beforeMount() {
    this.getPodcastDetails();
    this.getPodcastEpisodes();
    this.getPodcastTrailer();
    this.getUserBookmarks();
  },
  mounted() {
    // hack to for navbar
    const navbarElem = document.getElementById("app-navbar");
    navbarElem.className = "app-navbar";
  },
  destroyed() {
    this.setMetaInformation();
  },
  methods: {
    async getPodcastDetails() {
      // nr_analyticsManager.addPageAction(nr_addPageActions.Web_LoadShowDetailsV1);
      try {
        this.podcast = await this.podcast_service.getPodcastDetailsV2(this.podcastUUID);
        this.podcast_legacy_formatted_object = showDetailsHelper.formatLegacyPodcastObject(this.podcast);
        this.eventProvider.triggerShowDetailsViewedEvent(this.podcast_legacy_formatted_object);
        if (this.podcast.isExclusive) {
          this.setMetaInformation(`${showDetailsHelper.getPodcastMetaTitle(this.podcast.title, this.podcast.mediaType)} | Luminary Original`, `Listen to ${showDetailsHelper.getPodcastMetaTitle(this.podcast.title)}. Sign up and get full access to this and other Luminary Originals.`);
        } else {
          this.setMetaInformation(`${showDetailsHelper.getPodcastMetaTitle(this.podcast.title, this.podcast.mediaType)} | Luminary`, `Listen to ${showDetailsHelper.getPodcastMetaTitle(this.podcast.title)}. Get your Luminary subscription today & listen to our Original Podcasts you won't find anywhere else.`);
        }
        // NR log success here
      } catch (err) {
        log.error(err);
        // NR log faliure here
      }
      this.loadingPodcast = false;
    },
    async getPodcastEpisodes() {
      // nr_analyticsManager.addPageAction(nr_addPageActions.Web_LoadShowDetailsV1);
      const params = { size: 10, sortOrder: this.getSortOrder };
      try {
        const episodesResponse = await this.podcast_service.getPodcastEpisodesV2(this.podcastUUID, params);
        this.episodes = episodesResponse.episodes;
        this.episodeCount = episodesResponse.total;
        if (this.isFirstEpisodesFetch) {
          this.freeEpisodes = this.constructFreeEpisodes(this.episodes);
        }
        this.isFirstEpisodesFetch = false;
        // NR log success here
      } catch (err) {
        log.error(err);
        // NR log faliure here
      }
      this.loadingEpisodes = false;
    },
    async getPodcastTrailer() {
      try {
        this.trailer = await this.podcast_service.getPodcastTrailerV2(this.podcastUUID);
      } catch (err) {
        log.warn(err);
      }
      this.loadingTrailer = false;
    },
    async getUserBookmarks() {
      const { user } = this.$store.getters;

      // -- jtabach: This endpoint is not backwards compatible. Can only use once all v2 bookmark endpoints are available --

      // if (user) { // check if user to avoid 401 response for anon user
      //   try {
      //     this.userBookmarks = await this.user_service.getUserBookmarksV2();
      //     console.log(this.userBookmarks);
      //   } catch (err) {
      //     log.error(err);
      //   }
      // }
      // this.loadingUserBookmarks = false;

      if (user) { // check if user to avoid 401 response for anon user
        try {
          this.userBookmarks = await this.episode_service.getUserLibraryEpisodeIds();
        } catch (err) {
          log.error(err);
        }
      }
      this.loadingUserBookmarks = false;
    },
    constructFreeEpisodes(episodes) {
      if (this.userPremium) {
        return [];
      }

      let freeEpisodeCount = 0;
      return episodes.filter((episode) => {
        const isFreeAndExclusive = episode.isFree && episode.isShowExclusive && episode.type !== 'trailer';
        if (isFreeAndExclusive && freeEpisodeCount < 2) {
          freeEpisodeCount++;
          return true;
        }
        return false;
      });
    },
  },
};
</script>
