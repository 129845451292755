<template>
  <div
    v-if="!!plan.displayName && showPlanCard"
    :class="['plan-card', isSelectedPlan ? '' : '', (showTopHeader && plan.topHeader) ? 'with-top-header' : '']"
    @click="onPlanSelect">
    <span class="best-value-label">
      {{ plan.isHolidayPromo ? 'Limited Time Holiday Offer' : 'Listen on Luminary' }}
    </span>
    <div v-if="!plan.isHolidayPromo && plan.isBestValue && showBestValueLabel && discountPercentFormatted" class="discount-flag">
      Save
      {{ discountPercentFormatted }}%
    </div>
    <el-row
      type="flex"
      align="middle"
      justify="space-between"
      class="plan-card-content">
      <el-col
        :xl="16"
        :lg="16"
        :sm="16"
        :xs="24">
        <h3
          v-if="showTopHeader && plan.topHeader"
          class="top-header">{{ plan.topHeader }}</h3>
        <el-row
          type="flex"
          justify="space-between"
          align="middle">
          <h3
            :class="isSelectedPlan ? 'primary' : ''">{{ plan.displayName }}</h3>
          <div
            v-if="!showSubscribeButton && isSelectedPlan"
            class="hidden-sm-and-up">
            <img src="../assets/images/checkmark_blue.svg">
          </div>
        </el-row>
        <p
          class="plan-desc"
          v-html="monthlyCostDescription"/>
        <p class="plan-sub-desc">{{ additionalCostDescription }}</p>
      </el-col>
      <el-col
        :xl="8"
        :lg="8"
        :sm="8"
        :xs="24"
        class="t-r">
        <el-button
          v-if="showSubscribeButton"
          type="primary"
          class="btn-brand spotlight full-width block-btn">
          Subscribe
        </el-button>
        <el-button
          v-if="showSelectButton"
          type="primary"
          class="btn-brand spotlight full-width block-btn">
          Select
        </el-button>
        <div
          v-else-if="!showSubscribeButton && isSelectedPlan"
          class="hidden-xs-only">
          <img src="../assets/images/checkmark_blue.svg">
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  name: 'PlanCard',
  // components: {
  //   'sign-up': SignUp,
  // },
  props: {
    plan: {
      type: Object,
      default: () => {},
    },
    planSelected: {
      type: Boolean,
      default: () => false,
    },
    showTopHeader: {
      type: Boolean,
      default: () => false,
    },
    showSubscribeButton: {
      type: Boolean,
      default: () => false,
    },
    showSelectButton: {
      type: Boolean,
      default: () => false,
    },
    showBestValueLabel: {
      type: Boolean,
      default: () => true,
    },
    hideMonthly: {
      type: Boolean,
      default: () => false,
    },
    noTrial: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      selectedPlan: null,
      // selected: false,
    };
  },
  computed: {
    checked: {
      get() {
        return this.isSelectedPlan;
      },
      set() {

      },
    },
    discountPercentFormatted() {
      const roundTo = 1;
      return Math.floor(this.plan.discountPercent / roundTo) * roundTo;
    },
    annualPlanEnabled() {
      return this.$store.getters.flagWithDefault('annual_plan', true);
    },
    monthlyCostDescription() {
      const { marketing_price_str, trialPeriodDays } = this.plan;
      const { promoCode, trialPeriod, trialEndDate } = this.promoMeta;
      let trialText = ' after 7-day free trial';
      if (promoCode && promoCode !== '' && trialEndDate !== '') {
        trialText = ` after </span><span class="strike-through">7-day</span><span>${trialPeriod}-month free trial</span><span>`;
      }
      const showTrial = this.promoMeta.showTrial && trialPeriodDays && !this.noTrial;
      if (this.plan.isHolidayPromo) return `<span><span class="non-promo-price">${this.plan.displayOriginalPrice}</span> ${this.plan.displayPrice} for your first year${showTrial ? ` ${trialText}` : ''}.</span>`;
      return `<span>${marketing_price_str}/mo${showTrial ? trialText : ''}</span>`;
    },
    additionalCostDescription() {
      if (this.plan.isHolidayPromo) return 'Cancel anytime.';
      const { billingFrequency, displayPrice } = this.plan;
      if (billingFrequency === 'annually') {
        return `Auto-billed ${billingFrequency}. ${displayPrice} for your first 12 months.`;
      }
      if (billingFrequency === 'semi-annually') {
        return `Auto-billed ${billingFrequency}. ${displayPrice} for your first 6 months.`;
      }
      return 'Auto-billed monthly. Cancel Anytime';
    },
    isSelectedPlan() {
      const { userSubscription, selectedPlan } = this.$store.getters;
      if (this.promoMeta.isPremiumCurrent && !this.planSelected) {
        return this.plan.planTypeString === userSubscription.plan.type;
      }
      const planId = selectedPlan && selectedPlan.id;
      return this.plan.id === planId;
    },
    showPlanCard() {
      if (this.plan.displayName === 'Monthly' && this.hideMonthly) {
        return false;
      }
      return true;
    },
  },
  methods: {
    onPlanSelect() {
      this.$emit('selectPlan', this.plan);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../assets/scss/variables.scss";
.plan-card {
  background: #FFFFFF;
  border: 1px solid #F2F2F2;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  position: relative;
  transition: border 1s;
  cursor: pointer;
  &.selected-plan {
    border: 1px solid #02AECA;
  }
  &.with-top-header {
    margin-top: 55px;
  }
  @media(min-width: $sm) {
    width: 400px;
  }
  @media(max-width: $sm) {
    width: 100%;
    max-width: 480px;
    margin-left: auto;
    margin-right: auto;
  }
  &-content {
    margin-top: 7px;
    @media(min-width: $sm) {
      padding: 28px 41px 24px 41px;
    }
    @media(max-width: $sm) {
      padding: 28px 29px 24px 29px;
    }
    @media(max-width: $xs) {
      flex-direction: column;
    }
    @media(max-width: 767px) {
      padding: 28px 10px 24px;
    }
    .top-header {
      position: absolute;
      top: -55px;
      left: 0px;
      @media(max-width: $sm) {
        font-size: 30px;
        line-height: 40px;
        letter-spacing: -1px;
      }
    }
    h3 {
      display: inline-block;
      font-family: $clear-sans;
      font-weight: bold;
      font-style: normal;
      font-size: 24px;
      line-height: 32px;
      margin: 1px 14px 8px 0;
      @media(max-width: 767px) {
        text-align: center;
        margin: 14px 0 14px 0;
        width: 100%;
      }
    }
    p {
      font-family: $clear-sans;
      margin: 0px;
    }
    .plan-desc {
      font-size: 16px;
      line-height: 22px;
      margin-bottom: 4px;
      color: $black;
      @media(max-width: $sm) {
        font-size: 16px;
        line-height: 25px;
      }
      @media(max-width: 767px) {
        text-align: center;
        margin-bottom: 5px;
      }
      .strike-through {
        text-decoration: line-through;
        opacity: .6;
        margin-right: 4px;
      }
    }
    .plan-sub-desc {
      font-size: 12px;
      line-height: 16px;
      color: #888888;
      margin-bottom: 16px;
      @media(max-width: $sm) {
        font-size: 12px;
        letter-spacing: -.2px;
        /* line-height: 24px; */
      }
      @media(max-width: 767px) {
        text-align: center;
        margin-bottom: 21px;
      }
    }
  }
  .best-value-label {
    display: block;
    background-color: $brand;
    border-radius: 2px;
    font-size: 14px;
    font-weight: bold;
    line-height: 19px;
    padding: 2px 0px;
    position: absolute;
    top: 0px;
    width: 100%;
    text-align: center;
  }
  .spotlight {
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 1px rgba(0, 0, 0, 0.14);
  }
  .discount-flag {
    padding: 0;
    margin: 0;
    border: 0;
    font-family: Arial $clear-sans;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 12px;
    display: flex;
    align-items: center;
    text-align: center;
    background-color: $blue;
    width: 36px;
    height: 38px;
    color: #ffffff;
    position: absolute;
    right: 30px;
    z-index: 2;
    &:after {
      background-color: $blue;
      bottom: 0;
      content: '';
      display: block;
      height: 13px;
      left: 0;
      position: absolute;
      right: 50%;
      transform: skewY(-33deg);
      transform-origin: 100%;
      z-index: -1;
    }
    &:before {
      background-color: $blue;
      content: '';
      display: block;
      height: 13px;
      bottom: 0;
      left: 50%;
      position: absolute;
      right: 0;
      transform: skewY(33deg);
      transform-origin: 0;
      z-index: -1;
    }
  }
  &/deep/ .non-promo-price {
    text-decoration: line-through;
    color: #999999;
  }
}
</style>
