<template>
  <div class="your-info">
    <el-form
      ref="yourInfoForm"
      :model="yourInfo"
      :rules="rules"
      @keyup.enter.native="onSubmit(index)">
      <el-form-item
        label="Your First Name"
        prop="firstName">
        <el-input
          v-model="yourInfo.firstName"
          placeholder="First Name" />
      </el-form-item>
      <el-form-item
        label="Your Last Name"
        prop="lastName">
        <el-input
          v-model="yourInfo.lastName"
          placeholder="Last Name" />
      </el-form-item>
      <el-form-item
        label="Your Email"
        prop="email">
        <el-input
          v-model="yourInfo.email"
          placeholder="Email" />
      </el-form-item>
      <el-button
        type="primary"
        class="block-btn spotlight"
        @click="() => onSubmit(index)">Save and Continue</el-button>
    </el-form>
  </div>
</template>

<script>
import * as EmailValidator from 'email-validator';

export default {
  props: {
    index: {
      type: Number,
      default: 0,
    },
  },
  data() {
    const firstNameValidator = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('First Name Required'));
      }
      callback();
    };
    const lastNameValidator = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('Last Name Required'));
      }
      callback();
    };
    const emailValidator = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('Email Required'));
      } else if (!EmailValidator.validate(value)) {
        callback(new Error('Invalid Email Format'));
        return;
      }
      callback();
    };
    return {
      yourInfo: {
        firstName: '',
        lastName: '',
        email: '',
      },
      rules: {
        firstName: [{ validator: firstNameValidator, trigger: 'blur' }],
        lastName: [{ validator: lastNameValidator, trigger: 'blur' }],
        email: [{ validator: emailValidator, trigger: 'change' }],
      },
    };
  },
  mounted() {
    this.getInitialFormValues();
  },
  methods: {
    onSubmit(index) {
      this.$refs.yourInfoForm.validate((valid) => {
        if (valid) {
          this.$store.dispatch('saveAccordionSectionData', {
            formData: this.yourInfo,
            index,
          });
        }
      });
    },
    getInitialFormValues() {
      const { formData } = this.$store.getters.accordionSections[this.index];
      this.yourInfo = formData;
    },
  },
};

</script>

<style lang="scss" scoped>
@import '../../../../assets/scss/variables.scss';

.your-info {
  margin: 0 auto;
  max-width: 324px;
  width: 100%;

  /deep/ .el-form-item {
    margin-bottom: 16px;
    &:last-of-type {
      margin-bottom: 32px;
    }
    &__label {
      color: $white;
    }
  }
}

</style>
