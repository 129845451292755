<template>
  <!-- Added route condition to overcome slug errors -->
  <router-link
    ref="podcast-card"
    :data-segment-uuid="podcast_data.uuid"
    :to="type !== 'play' && redirect && !hidden
      ? (type === 'episode'
        ? constructEDPUrl
        : constructSDPUrl)
    : $route.fullPath"
    :class="`podcast-card${isPremium ? ' is-premium' : ''}${grid ? ' grid' : ''}${hidden ? ' hidden' : ''}${isOriginal ? ' original' : ''}`"
    @click.native="onPodcastClick(podcast_data.uuid,podcast.season_id)"
    @mouseover.native="onMouseOver"
    @mouseleave.native="onMouseLeave">
    <div :class="`podcast-image${firstItem && hasHoverState ? ' first-item' : ''}${hovered && hasHoverState ? ' hovered' : ''}${hasHoverState && (mediaSize === 'wide') ? ' wide' : ''}`">
      <picture-component
        :src="podcast_data.display_image_url"
        :alt-info="podcast_data.title"
        :media-size="mediaSize" />
      <div
        v-if="hasHoverState && showTitle"
        :class="`hover-state${hovered ? ' hovered' : ''}`">
        <div
          v-if="mediaSize !== 'wide'"
          class="hover-state__text">
          <p class="title">{{ podcast_data.title }}</p>
          <p
            v-if="podcast_data && podcast_data.publisher"
            class="publisher">
            {{ podcast_data.publisher.name }}
          </p>
        </div>
        <div
          v-if="mediaSize !== 'wide'"
          class="hover-state__overlay"
        />
      </div>
      <PremiumIndicator
        v-if="isPremium"
        size="small"/>
      <div
        v-if="isOriginal"
        class="premium-logo">
        <img
          src="../assets/images/web2/luminary-dot.svg"
          alt="Premium Logo">
      </div>
    </div>
    <div
      v-if="mediaSize === 'wide' && isPremium"
      class="podcast-content">
      <!-- <PremiumIndicator v-if="isPremium" /> -->
    </div>
    <div
      v-if="mediaSize !== 'wide'"
      class="podcast-content">
      <!-- <PremiumIndicator v-if="isPremium" /> -->
      <p
        v-if="showTitle && type !== 'play'"
        class="podcast-title"
        data-test="title-contentitem">{{ podcast_data.title || 'Title not Available' }}
      </p>

      <div
        v-if="hasHoverState"
        :class="`hover-state${firstItem ? ' first-item' : ''}${hovered ? ' hovered' : ''}`"
        data-test="hover-state">
        <p class="description">
          {{ podcast_data.description }}
        </p>
        <el-button
          type="text"
          color="primary">
          <span>EXPLORE</span>
          <i class="el-icon-caret-right el-icon-right"/>
        </el-button>
      </div>
    </div>
  </router-link>
</template>
<script>
import _ from 'lodash';
import Event from '../services/PlayerEvent';
import PremiumIndicator from './PremiumIndicator';

export default {
  name: 'PodcastCard',
  components: {
    PremiumIndicator,
  },
  props: {
    hasHoverState: {
      type: Boolean,
      default: false,
    },
    showTitle: {
      type: Boolean,
      default: true,
    },
    firstItem: {
      type: Boolean,
      default: false,
    },
    podcast: {
      type: Object,
      default: () => {},
    },
    grid: {
      type: Boolean,
      default: false,
    },
    redirect: {
      type: Boolean,
      default: true,
    },
    hidden: {
      type: Boolean,
      default: false,
    },
    isOriginal: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: '',
    },
    bucket: {
      type: Object,
      default: () => {},
    },
    mediaSize: {
      type: String,
      default: () => 'thumbnail',
    },
  },
  data() {
    return {
      hovered: false,
      podcast_data: this.podcast,
      text_limit: 40,
      podcast_title: this.$options.filters.truncate(this.podcast.title, this.text_limit),
      show_temp_title: true,
      eventProvider: new Event(),
    };
  },
  computed: {
    isPremium() {
      return this.podcast.is_exclusive;
    },
    constructSDPUrl() {
      return `/listen/${_.kebabCase(this.podcast_data.publisher.name)}/${_.kebabCase(this.podcast_data.title)}/${this.podcast_data.uuid}`;
    },
    constructEDPUrl() {
      return `/listen/${_.kebabCase(this.podcast_data.season.podcast.publisher.name)}/${_.kebabCase(this.podcast_data.season.podcast.title)}/${_.kebabCase(this.podcast_data.slug)}/${this.podcast_data.uuid}`;
    },
  },
  watch: {
    podcast(newVal) {
      this.podcast_data = newVal;
    },
  },
  mounted() {
    this.podcast_data = this.podcast;
    if (window.innerWidth <= 567) {
      this.text_limit = 20;
    } else if (window.innerWidth <= 768) {
      this.text_limit = 35;
    }

    const ctx = this;
    let isScrolling;
    this.scrollListener = function () {
      window.clearTimeout(isScrolling);
      isScrolling = setTimeout(() => {
        ctx.checkIfVisible();
      }, 66);
    };
    document.body.addEventListener('scroll', this.scrollListener);
    this.checkIfVisible();
  },
  destroyed() {
    document.body.removeEventListener('scroll', this.scrollListener);
  },
  methods: {
    checkIfVisible() {
      const viewportHeight = document.body.offsetHeight;
      const element = this.$refs['podcast-card'] ? this.$refs['podcast-card'].$el : null;
      if (element) {
        const elementRect = element.getBoundingClientRect();
        const elementPos = elementRect.top;
        const threshold = (element.offsetHeight / 3); // part of element within viewport
        const isVisible = elementPos >= (-1 * threshold) && (elementPos + threshold) <= viewportHeight;
        if (isVisible && this.podcast_data.uuid.length > 1) {
          element.classList.add('is-visible');
        }
      }
    },
    onPodcastClick() {
      if (!this.redirect || this.isMarketingPage) return;
      this.triggerSegmentEvent();
    },
    triggerSegmentEvent() {
      const bucketData = { ...this.bucket };
      bucketData.items = {};

      const bucketItemData = { ...this.podcast };
      bucketItemData.item_position = this.$vnode.key;

      this.eventProvider.triggerContentSelectEvent(bucketData, bucketItemData);
    },
    onMouseOver() {
      this.hovered = true;
    },
    onMouseLeave() {
      this.hovered = false;
    },
  },
};
</script>
<style lang="scss">
@import "../assets/scss/variables.scss" , "../assets/scss/mixins.scss";

.podcast-card {
  height: 280px;
  margin-right:15px;
  width: 227px;
  display: block;

  &.original {
    .podcast-image {
      background-color: rgba(255,255,255, 0);
      border: 0px;
      .premium-logo {
        display: flex;
        justify-content: center;
        transform: translateY(-16px);
        position: relative;
        pointer-events: none;
        @media(max-width: 768px) {
          transform: translateY(-8px);
        }
        img {
          width: 32px;
          height: 32px;
          @media(max-width: 768px) {
            width: 18px;
            height: 18px;
          }
        }
      }
    }
    .podcast-content {
      .hover-state {
        padding-top: 23px;
        button {
          float: right;
          padding-top: 9px;
          padding-right: 0px;
        }
      }
    }
  }

  .podcast-image {
    background: #d4d4d4; /* for placeholder */
    background-clip: content-box; /* don't let background color go under the border */
    border: 1px solid rgba(17,17,17,0.15);
    box-sizing: border-box;
    height: 227px;
    position: relative;
    transform-origin: 50% 100%;
    transition: 100ms;

    &.first-item {
      transform-origin: 0 100%;
    }

    &.hovered {
      transform: scale(1.185);
      z-index: 997;
    }

    &.wide {
      transform-origin: 50% 50%;

      &.first-item {
        transform-origin: 0 50%;
      }
    }

    .el-progress {
      position: absolute;
      bottom: 0;
      z-index: 10;
      width: 100%;
      left: -0.5px;
    }

    .hover-state {
      position: absolute;
      top: 0;
      left: 0;

      box-sizing: border-box;

      width: 100%;
      height: 100%;

      opacity: 0;
      z-index: 998;
      transition: 100ms;
      transform: scale(1);
      transform-origin: 50% 100%;

      &.hovered {
        opacity: 1;
      }

      &.first-item {
        transform-origin: 0 100%;
      }

      @media (max-width: 768px) {
        display: none;
      }

      &__text {
        position: absolute;
        bottom: 7px;
        left: 10px;
        z-index: 11;

        p.premium-indicator {
          transform: scale(0.84388185654);
          margin-bottom: 4px;
          margin-left: -5px;
        }

        p:not(.premium-indicator) {
          font-size: 11.8143459916px;
          line-height: 15.1898734177px;
          color: white;

          margin: 0;

          &:last-child {
            color: #666;
          }
        }
      }

      &__overlay {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 10;

        height: 100%;
        width: 100%;

        background: linear-gradient(to top, rgba(0, 0, 0, .8), rgba(0, 0, 0, 0));
      }
    }
    .premium-indicator {
      bottom: -10px;
    }
  }

  .podcast-list-wide & {
    height: 217px;
    width: 346px;

    .podcast-image {
      height: 217px;
    }

    @media (max-width: 1024px) {
      width: 243px !important;
      height: 151px !important;

      .podcast-image {
        height: 151px !important;
      }
    }

    @media (max-width: 768px) {
      width: 246px !important;
      height: 153px !important;

      .podcast-image {
        height: 153px !important;
      }
    }

    @media (max-width: 567px) {
      width: 180px !important;
      height: 112px !important;

      .podcast-image {
        height: 112px !important;
      }
    }
  }

  .podcast-content {
    position: relative;
    margin-top: 16px;

    .hover-state {
      position: absolute;
      top: -18px;
      left: 0;
      box-sizing: border-box;

      width: 100%;

      padding: 16px 12px 12px;

      opacity: 0;
      background-color: white;
      z-index: 9;
      transition: 100ms;
      transform: scale(1);
      transform-origin: 50% 0;

      border: 1px solid #eee;
      border-top: none;

      &.hovered {
        opacity: 1;
        transform: scale(1.185);
      }

      &.first-item {
        transform-origin: 0 0;
      }

      p:not(.premium-indicator) {
        margin: 0;
        width: 100%;

        &.title {
          font-size: 16px;
          margin-bottom: 10px;
          color: #111;
          font-weight: normal;

          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }

        &.publisher {
          font-size: 14px;
          color: #666;
          margin-top: -14px;
          margin-bottom: 10px;

          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }

        &.description {
          color: #111;

          @include multilinetruncate(11.8143459916px, 15.1898734177px, 5);
        }
      }

      button {
        font-size: 12px;
        padding-left: 0;
        padding-bottom: 0;

        display: flex;
        align-items: center;
        border-radius: 0 !important;
      }

      @media (max-width: 768px) {
        display: none;
      }

      .premium-indicator {
        margin-top: 0;
      }
    }

    .playable {
      p {
        color: $gray;
        font-size: 12px;
        text-align: center;
        line-height: 13px;
      }
    }

    .el-progress {
      margin-top:0;
      .el-progress-bar {
         .el-progress-bar__outer {
            border-radius: 0 !important;
            background-color: $xlight-gray !important;
            .el-progress-bar__inner {
              border-radius: 0 !important;
            }
          }
      }
    }

    .podcast-title {
      text-transform: capitalize;
      margin-bottom: 0 !important;
      color: #ffffff;
      font-family: $clear-sans;
      @include multilinetruncate(14px, 18px, 2);
    }

    .publisher-name {
      text-transform: capitalize;
      font-family: $clear-sans;
      font-size: 14px;
      line-height: 14px;
      margin: 7px 0;
      color: $gray;
    }
  }

  &.is-premium {
    .podcast-content {
      .podcast-title {
        margin-top: 7px;
      }
    }
  }
}

.podcast-card.grid {
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 207px;
  margin: 0 8px 32px 8px;
  width: auto;
  height: auto;
  .podcast-image {
    height: 207px;
  }
  &.hidden {
    height: 0;
    margin-bottom: 0;
    overflow: hidden;
  }
}

@media (max-width: $md) {
  .podcast-card:not(.grid) {
    height: 253px;
    width: 200px;
    .podcast-image {
      height: 200px;
    }
  }
  .podcast-card.grid {
    flex-basis: 172px;
    height: auto;
    width: auto;
    margin-bottom: 40px;
    .podcast-image {
      height: 172px;
    }
  }
}
@media (max-width: $sm) {
  .podcast-card:not(.grid) {
    height: 234px;
    width: 181px;
    .podcast-image {
      height: 181px;
    }

    &.is-premium {
      height: 260px;
    }
  }
  .podcast-card.grid {
    flex-basis: 162px;
    margin-bottom: 42px;
    .podcast-image {
      height: 162px;
    }
  }
}
@media (max-width: $xs) {
  .podcast-card.grid {
    flex-basis: 125px;
    .podcast-image {
      height: 125px;
    }
  }
}
@media (max-width: 460px) {
  .podcast-card:not(.grid) {
    height: 160px;
    width: 115px;

    &.is-premium {
      height: 190px;
    }

    .podcast-image {
      height: 115px;
    }
    .podcast-content {
      .podcast-title {
        margin-top: 5px !important;
        height: auto;
        white-space: normal;
      }
      .publisher-name {
        display: none;
      }
    }
  }
  .podcast-card.grid {
    flex-basis: 92px;
    margin-bottom: 32px;
    .podcast-image {
      height: 92px;
    }
  }
}
@media (max-width: 334px) {
  .podcast-card.grid {
    margin: 0 4px 10px 4px;
  }
}
</style>
