<template>
  <div class="purchase-hero">
    <div class="content">
      <h3 class="title">{{ title }}</h3>
      <p class="subtitle">{{ subtitle }}</p>
    </div>
    <div class="card">
      <div class="card-content">
        <img
          class="card-icon"
          src="../../../assets/images/web2/gifting/icon-bow-blue-left.png">
        <p class="card-copy">{{ cardCopy }}</p>
        <p class="card-price" v-html="giftPrice"/>
      </div>
    </div>
  </div>
</template>

<script>
import giftMixin from '@/shared/giftMixin';

export default {
  mixins: [giftMixin],
  data() {
    return {
      title: 'Gift a Subscription',
      subtitle: 'Choose a delivery date and we’ll email your gift with instructions on how to redeem.',
      cardCopy: '1-Year Subscription',
    };
  },
};

</script>

<style lang="scss" scoped>
@import "../../../assets/scss/variables.scss";

.purchase-hero {
  display: flex;
  flex-direction: column;
  margin-top: 32px;
  margin-bottom: 36px;
  @media (min-width: $sm) {
    flex-direction: row;
  }
}

.content {
  margin-bottom: 24px;
  text-align: center;
  @media (min-width: $sm) {
    margin-bottom: 0;
    margin-right: 44px;
    text-align: left;
  }
}

.title {
  color: $white;
  font-weight: bold;
  font-size: 21px;
  line-height: 28px;
  margin-top: 0;
  margin-bottom: 6px;
}

.subtitle {
  color: $light-gray;
  font-size: 14px;
  line-height: 20px;
  margin: 0;
}

.card {
  position: relative;
  background: $dark-overlay;
  padding: 14px 24px 14px 64px;
  max-width: 202px;
  width: 202px;
  margin: 0 auto;
  border-radius: 8px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  @media (min-width: $sm) {
    max-width: 252px;
    width: 252px;
  }
}

.card-icon {
  position: absolute;
  top: 0;
  left: 0;
  height: 48px;
}

.card-copy {
  color: $white;
  margin: 0;
  margin-bottom: 6px;
  font-size: 18px;
  font-weight: bold;
  line-height: 24px;
  text-align: left;
}

.card-price {
  color: $blue;
  margin: 0;
  text-align: left;
  font-size: 18px;
  line-height: 21px;
}

</style>
