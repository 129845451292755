import utility from '../shared/utility';
import AxiosAPI from './AxiosAPI';

class Branch extends AxiosAPI {
  accountCreatedEventName = 'br_account_created';

  subscriptionCreatedEventName = 'br_subscription_created';

  async createLink(type, uuid) {
    switch (type) {
      case 'podcast':
        return this.get(`v2/podcasts/branch/${uuid}`);
      case 'episode':
        return this.get(`v2/episodes/branch/${uuid}`);
      default:
        throw Error(`type ${type} not configured`);
    }
  }

  readDeeplink() {
    return new Promise((resolve, reject) => {
      if (window.branch) {
        window.branch.data((err, data) => {
          if (data) {
            if (window && window.$store) {
              window.$store.dispatch('setBranchData', data.data_parsed);
            }
            resolve(data);
          } else {
            reject(err);
          }
        });
      } else {
        reject(new Error('sdk not found'));
      }
    });
  }

  logCustomEvent(eventName, customData) {
    customData = utility.removeEmpty(customData);
    if (window.branch) {
      window.branch.logEvent(eventName, customData);
    }
  }
}

export default Branch;
