const getters = {
  showPremiumModal: state => state.showPremiumModal,
  branchData: state => state.branchData,
  showUpdatePaymentModal: state => state.showUpdatePaymentModal,
  isAcquisitionFlow1: state => state.isAcquisitionFlow1,
  isRedeemGiftFlow: state => state.isRedeemGiftFlow,
};

const mutations = {
  setShowPremiumModal: (state, showPremiumModal) => { state.showPremiumModal = showPremiumModal; },
  setNewBranchData: (state, branchData) => { state.branchData = branchData; },
  setShowUpdatePaymentModal: (state, show) => { state.showUpdatePaymentModal = show; },
  setisAcquisitionFlow1: (state, show) => { state.isAcquisitionFlow1 = show; },
  setisRedeemGiftFlow: (state, show) => { state.isRedeemGiftFlow = show; },
};

const state = {
  showPremiumModal: false,
  branchData: null,
  showUpdatePaymentModal: false,
  isAcquisitionFlow1: false,
  isRedeemGiftFlow: false,
};

const actions = {
  isAllowedPremiumEpisode(context, content) {
    const { user } = context.getters;
    const showPremiumModal = (!user && content.is_exclusive) || (user && !user.is_subscribed && content.is_exclusive);
    context.commit('setShowPremiumModal', showPremiumModal);

    return !this.showPremiumModal;
  },
  isAllowedPremiumShow(context, content) {
    const { user } = context.getters;
    const showPremiumModal = !user || (user && !user.is_subscribed && content.is_exclusive && !content.has_free_episodes);
    context.commit('setShowPremiumModal', showPremiumModal);

    return !this.showPremiumModal;
  },
  hidePremiumModal(context) {
    context.commit('setShowPremiumModal', false);
  },
  setBranchData: (context, branchdata) => {
    context.commit('setNewBranchData', branchdata);
  },
  showUpdatePaymentModal: (context) => {
    context.commit('setShowUpdatePaymentModal', true);
  },
  isAcquisitionFlow2: (context, bool) => {
    context.commit('setisAcquisitionFlow1', bool);
  },
  isRedeemGiftFlow: (context, bool) => {
    context.commit('setisRedeemGiftFlow', bool);
  },
  hideUpdatePaymentModal: (context) => {
    context.commit('setShowUpdatePaymentModal', false);
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
