<template>
  <div class="container four04">
    <el-row
      type="flex"
      justify="center">
      <el-col
        :span="24"
        class="t-c">
        <h4 class="heading">
          Page Not Found
        </h4>
        <p>Sorry, we couldn’t find the page you’re looking for.</p>
        <router-link
          class="el-button el-button--primary block-btn spotlight"
          aria-label="Back to home"
          to="/listen">Back to Home</router-link>
      </el-col>
    </el-row>
  </div>
</template>
<script>
import loader from '../components/Loader';
import PageMixin from '../shared/pageMixin';

export default {
  components: {
    loader,
  },
  data() {
    return {
      pageName: '404',
    };
  },
  mixin: [PageMixin],
};
</script>
<style lang="scss">
@import "../assets/scss/variables.scss";
.four04 {
  display: flex;
  justify-content: center;
  align-items: center;
  .el-row {
    // height: 78vh;
    .heading {
      font-size: 36px;
      margin-top: 0 !important;
      line-height: 40px;
      font-family: $clear-sans;
      margin-bottom: 0;
      letter-spacing: -1px;
      @media (max-width: $xs) {
        font-size: 26px;
        line-height: 35px;
      }
    }
    p {
      margin-top: 18px;
      color: $black;
    }
    a {
      font-family: $clear-sans;
      padding: 15px 25px;
      margin: 0 auto;
      display: block;
      margin-top: 40px;
      margin-bottom: 34px;
    }
    img {
      width: 325px;
      height: auto;
    }
    .circle {
      width: 10rem;
      height: 10rem;
      background-color: $brand;
      border-radius: 50%;
      margin:0 auto;
      animation: pulse 1s infinite;
    }
  }
}
@media (max-width: 576px) {
  .four04 {
    &.container {
      margin-top: 25px !important;
    }
    .el-row {
      .heading {
        font-size: 42px;
      }
      p {
        margin-top: 14px;
      }
      a {
        margin-top: 25px;
        margin-bottom: 15px;
      }
      img {
        width: 225px;
      }
    }
  }
}
@keyframes pulse
{
  0% {
    transform: translateY(2px);
    box-shadow: 0 0 0 0px rgba(255,229,0,.5);
  }
  100% {
    box-shadow: 0 0 0 35px rgba(255,229,0,0);
  }
}
</style>
