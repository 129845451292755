import uuidv1 from 'uuid/v1';
import * as log from 'loglevel';

const DEFAULT_PLAN = 'conversion_funnel';
const PLAN_VERSION = 1;

export default {
  logPageView() {
    const urlParams = new URLSearchParams(window.location.search);

    if (window.gtag) {
      window.gtag('event', 'page_view', {
        page_title: document.title,
        page_location: window.location.href,
      });

      window.gtag('set', 'campaign', {
        id: urlParams.get('utm_id'),
        source: urlParams.get('utm_source'),
        medium: urlParams.get('utm_medium'),
        name: urlParams.get('utm_name'),
        term: urlParams.get('utm_term'),
        content: urlParams.get('utm_content'),
      });
    }

    /* window.mParticle.logPageView(eventName, attributes, customFlags, {
      dataPlan: {
        planId: plan || DEFAULT_PLAN,
        planVersion: planVersion || PLAN_VERSION,
      },
    }); */
  },

  logEvent(eventName, customAttributes = {}, customFlags = {}, eventType = '') {
    customAttributes.url = window.location.href;
    const googleLabel = eventName === 'cf_button_clicked' ? customAttributes.button_text : eventName;
    // window.mParticle.logEvent(eventName, eventType, customAttributes, { 'Google.Label': googleLabel, ...customFlags });
    if (window.gtag) {
      window.gtag('event', eventName, {
        event_category: 'Other',
        event_label: googleLabel,
        ...customAttributes,
      });
    }

    if (window.fbq) {
      if (eventName === 'cf_signup_successful') {
        window.fbq('track', 'CompleteRegistration', { content_name: 'signup', status: true });
      } else if (eventName === 'cf_subscription_created') {
        // needed for FB signal even if not actually starting trial
        window.fbq('track', 'StartTrial', {
          currency: customAttributes.currency,
          value: customAttributes.total_price,
          predicted_ltv: customAttributes.predicted_ltv,
        });
        window.fbq('track', 'Subscribe', {
          currency: customAttributes.currency,
          value: customAttributes.total_price,
          predicted_ltv: customAttributes.predicted_ltv,
        });
      }
    }
  },

  /* logProductAction(type, product, customAttributes, customFlags, transactionAttributes) {
    try {
      const mpProduct = window.mParticle.eCommerce.createProduct(product.name, product.sku, product.price, product.quantity);
      window.mParticle.eCommerce.logProductAction(
        type,
        mpProduct,
        customAttributes,
        customFlags,
        transactionAttributes,
      );
    } catch (e) {
      log.error(e);
    }
  }, */

  logPurchase(product, customAttributes, transactionAttributes) {
    if (window.gtag) {
      window.gtag('event', 'purchase', {
        currency: customAttributes.currency,
        transaction_id: transactionAttributes.Id,
        value: transactionAttributes.Revenue,
        items: [{
          item_id: product.sku,
          item_name: product.name,
          price: product.price,
          quantity: 1,
        }],
      });
    }
    // this.logProductAction.apply(null, [window.mParticle.ProductActionType.Purchase, ...args]);
  },

  addToCart(product, plan) {
    if (window.gtag) {
      window.gtag('event', 'add_to_cart', {
        currency: plan.currency,
        value: product.price,
        items: [{
          item_id: product.sku,
          item_name: product.name,
          price: product.price,
          quantity: 1,
        }],
      });
    }
    // this.logProductAction.apply(null, [window.mParticle.ProductActionType.AddToCart, ...args]);
  },

};
