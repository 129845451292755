import axios from 'axios';
import User from '../services/User';
import utility from './utility';

export default () => {
  const userProvider = new User();

  axios.interceptors.request.use(async (config) => {
    if (!config.public) {
      if (config.url.includes('v1/user/register') || config.url.includes('v1/user/login') || process.env.NODE_ENV === 'development') {
        const token = await userProvider.getUserToken();
        userProvider.setUserToken(token);
        if (!config.headers.common['X-Id-Token']) {
          config.headers.common['X-Id-Token'] = token;
        }
      }
    }
    config.headers.common['X-Amzn-Trace-Id'] = utility.generateAmazonTraceId();
    return config;
  }, err => Promise.reject(err));

  axios.interceptors.response.use(response => response,
    (error) => {
      let errorMessage = error && error.response ? error.response.data : error;
      if (typeof errorMessage === 'string') errorMessage = { message: errorMessage };
      if (error.response && error.response.status) {
        errorMessage.status = error.response.status;
      }
      const responseUrl = (error && error.request && error.request.responseURL) || '';
      // To prevent infinite loop in the event analytics api responds 401 (which it should never do but is)
      const nonRedirectUrl = /\/v2\/user\/library\/is-in-my-shows|\/analytics-events/;
      if (error.response && error.response.status === 401 && !nonRedirectUrl.test(responseUrl)) {
        errorMessage = { message: 'Your session is expired. Please login again.' };
        // if currrent path is not signin or signup then set redirect path
        const currentPath = window.Vue.$route.path;
        if (!currentPath.includes('/signup') && !currentPath.includes('/signin')) {
          window.$store.dispatch('setRedirectPath', currentPath);
        }
        window.Vue.$store.dispatch('logout');
        // window.Vue.$router.push('/signin');
        if (window.location.href !== `${window.location.origin}/signin`) window.location.href = `${window.location.origin}/signin`;
      }
      return Promise.reject(errorMessage);
    });
};
