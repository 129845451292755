import countries from '@/assets/json/countries.json';
import featuresConfig from '@/config/featuresConfig';

export default class SpecialPromo {
  correncyCountry = countries.reduce((acc, v) => ({ ...acc, [v.currency]: v }), {});

  startDate = new Date(2022, 7, 8);
  endDate = new Date(2022, 12, 30);

  affectedCountries = ['US'];
  specialPromoCodeString = "vinylbundle";
  specialPromoCopy = 'This show is a Luminary Original. Subscribe to enjoy more episodes.';
  specialPromoCopyMobile = 'Subscribe to enjoy more episodes.';

  get isEnabled() {
    const today = new Date();
    return featuresConfig.flags.special_promo && this.startDate <= today && this.endDate >= today;
  }

  modifications = {
    // US: {
    //   yearly: {
    //     displayName: "Vinyl Bundle Special Promo",
    //     displayPrice: 50,
    //     breakdownPrice: '$50',
    //     // discountPercent: 59,
    //     price: 50,
    //     displayPromoPrice: '$50',
    //     marketing_price: 50,
    //     marketing_price_str: '$50',
    //   },
    // },
  };

  specialPromoPlan = {
    billingFrequency: "annually",
    breakdownPrice: "$2.99",
    currency: "USD",
    discountPercent: 40,
    displayName: "Vinyl Bundle Special Promo",
    displayPaymentPeriod: "12 months",
    displayPrice: "$50",
    id: "2c92c0f96ed4a563016ed50a266b519d",
    isBestValue: false,
    marketing_price: 2.99,
    marketing_price_str: "$2.99",
    name: "Yearly Premium Subscription - USA",
    paidPeriod: 12,
    planTypeString: "yearly",
    price: 50,
    trialPeriodDays: 0,
    trialPeriodForDisplay: "",
    displayPromoPrice: '$50',
    isPromoPlan: true,
  };

  getPlanModification(country, type) {
    return this.modifications[country] && this.modifications[country][type];
  }

  modifyPlan(plan, country) {
    const modifications = this.getPlanModification(country, plan.planTypeString);
    return modifications ? {
      ...plan,
      originalPlan: plan,
      ...modifications,
    } : plan;
  }

  // modify existing plans
  modifyPlans(plans, country) {
    if (!this.affectedCountries.includes(country)) return plans;
    return plans.map(p => this.modifyPlan(p, country));
  }

  // add a new plan
  addPromoPlan(plans, country) {
    if (!this.affectedCountries.includes(country)) return plans;
    return [
      this.specialPromoPlan,
      ...plans
    ];
  }

  // modify and add new plan
  processPlans(plans, country) {
    if (!this.affectedCountries.includes(country)) return plans;
    plans = plans.map(p => this.modifyPlan(p, country));
    return this.addPromoPlan(plans, country)
  }
}
