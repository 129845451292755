import PodcastService from '@/services/Podcast';
import OptimizeService, { CANCELLATION_BRIGHTBACK_VARIANT, NEW_SIGNUP_VARIANT } from '@/services/Optimize';
import { getServices } from '@/services/serviceProvider';

const [podcastService, optimizeService] = getServices([PodcastService, OptimizeService]);

export const PROMO_PODCASTS_STATES = {
  IDLE: 'idle',
  LOADING: 'loading',
  LOADED: 'loaded',
  ERROR: 'error',
};

const PROMO_PODCASTS_IDS = [
  'ddef3227-882b-40a3-be76-fafd0d71424f',
  'ab4fffb8-7835-4482-8b48-c9898818768f',
  '8b2a12d6-49e8-4338-8780-5c6ec217b1ed',
];

const getDefaultState = () => ({
  signUpFlow: NEW_SIGNUP_VARIANT, // experiment finished, set experimental value by default
  cancellationFlow: CANCELLATION_BRIGHTBACK_VARIANT, // experiment finished, set experimental value by default
  promoPodcasts: {
    state: PROMO_PODCASTS_STATES.IDLE, // idle|loading|loaded|error
    items: [],
    error: '',
  },
});

const getters = {
  signUpFlow: state => state.signUpFlow,
  cancellationFlow: state => state.cancellationFlow,
  isCancellationBrightBackVariant: state => (state.cancellationFlow === CANCELLATION_BRIGHTBACK_VARIANT),
  promoPodcasts: state => state.promoPodcasts,
  promoPodcastItems: state => state.promoPodcasts.items,
  isPromoPodcastsLoading: state => state.promoPodcasts.state === PROMO_PODCASTS_STATES.LOADING,
};

const mutations = {
  setSignUpFlow: (state, payload) => {
    state.signUpFlow = payload === null ? payload : Number(payload);
  },
  setCancellationFlow: (state, payload) => {
    state.cancellationFlow = payload === null ? payload : Number(payload);
  },
  setPromoPodcasts: (state, payload) => {
    state.promoPodcasts = { ...state.promoPodcasts, ...payload };
  },
};

const state = getDefaultState();

const actions = {
  registerSignUpFlowCallback(context) {
    optimizeService.registerSignUpExperimentCallback(val => context.commit('setSignUpFlow', val));
  },
  registerCancellationFlowCallback(context) {
    optimizeService.registerCancellationExperimentCallback(val => context.commit('setCancellationFlow', val));
  },
  loadPromoPodcasts(context) {
    context.commit('setPromoPodcasts', { state: PROMO_PODCASTS_STATES.LOADING });
    return podcastService.getV2Podcasts(PROMO_PODCASTS_IDS).then((res) => {
      context.commit('setPromoPodcasts', {
        state: PROMO_PODCASTS_STATES.LOADED,
        items: res,
      });
    }).catch((err) => {
      context.commit('setPromoPodcasts', {
        state: PROMO_PODCASTS_STATES.ERROR,
        items: err.message,
      });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
