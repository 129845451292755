import { mapActions } from 'vuex';

export default {
  watch: {
    $route() {
      this.processPromoGetParameter();
    },
  },
  mounted() {
    this.processPromoGetParameter();
  },
  methods: {
    ...mapActions('promocode', [
      'validatePromocode',
    ]),
    processPromoGetParameter() {
      if (this.$route.query.promocode) {
        const { promocode, ...rest } = this.$route.query;
        this.validatePromocode({ promocode }).finally(() => {
          this.$router.replace({ query: rest });
        });
      }
    },
  },
};
