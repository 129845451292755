const getDefaultState = () => ({
  lastParams: null,
  lastUrl: '',
  lastMethod: '',
  lastResponseProcessor: '',
  redirectResult: '',
});

const getters = {
  lastParams: state => ({ ...state.lastParams }),
  lastUrl: state => state.lastUrl,
  lastMethod: state => state.lastMethod,
  lastResponseProcessor: state => state.lastResponseProcessor,
  redirectResult: state => state.redirectResult,
};

const mutations = {
  setLastParams: (state, payload) => {
    state.lastParams = { ...payload };
  },
  setLastUrl: (state, payload) => {
    state.lastUrl = payload;
  },
  setLastMethod: (state, payload) => {
    state.lastMethod = payload;
  },
  setLastResponseProcessor(state, payload) {
    state.lastResponseProcessor = payload;
  },
  setRedirectResult(state, payload) {
    state.redirectResult = payload;
  },
  resetPayment: (state) => {
    Object.assign(state, getDefaultState());
  },
};

const state = getDefaultState();

const actions = {};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
