import Episode from './Episode';
import utilityService from '../shared/utility';

class Events {
  constructor() {
    this.episodeProvider = new Episode();
  }

  triggerContentSelectEvent(bucket, bucketItem) {
    const data = {
      screen_name: bucket.screen_name ? bucket.screen_name : window.Vue.$route.name,
      content_selected_position: bucketItem.item_position, // need to add manually in selected bucket item
      content_selected_title: bucketItem.title,
      content_selected_uuid: bucketItem.uuid,
      content_type: this.getContentType(bucket),
      display_image_url: bucketItem.display_image_url,
      content_beacon_id: bucketItem.beacon_id,
    };
    if (bucket) {
      data.bundle_uuid = bucket.bundle_uuid;
      data.bucket_uuid = bucket.uuid;
      data.bucket_title = bucket.name;
      data.bucket_type = this.getBucketType(bucket);
      data.bucket_position_in_bundle = bucket.position;
      data.media_size = bucket.media_size;
    }
    if (bucketItem.item_type === 'episode') {
      data.episode_exclusive = bucketItem.is_exclusive;
    }
    if (bucketItem.item_type === 'podcast') {
      data.podcast_exclusive = bucketItem.is_exclusive;
    }
    window.Vue.$analytics.track('Content Select', data);
  }

  triggerContentViewedEvent(bucket, bucketItemsSeen, pageUUID = null) {
    const data = {
      screen_name: bucket.screen_name ? bucket.screen_name : window.Vue.$route.name,
      bucket_last_item_index: bucket.items_count,
      bucket_media_size: bucket.media_size,
      bucket_position_in_bundle: bucket.position,
      bucket_title: bucket.name,
      bucket_type: this.getBucketType(bucket),
      bucket_uuid: bucket.uuid,
      content_items_seen: bucketItemsSeen,
      content_type: this.getContentType(bucket),
      nonInteraction: 1,
      media_size: bucket.media_size,
    };
    if (pageUUID) {
      data.page_uuid = pageUUID;
    }
    window.Vue.$analytics.track('Content Viewed', data);
  }

  triggerBundleBrowseEvent(bucketsSeen, bundleSize) {
    if (!bucketsSeen.length) {
      return;
    }

    const data = {
      screen_name: window.Vue.$route.name,
      buckets_seen: bucketsSeen,
      last_bucket_in_view: bundleSize,
      nonInteraction: 1,
    };
    window.Vue.$analytics.track('Bundle Browse', data);
  }

  triggerRefreshEvent() {
    window.Vue.$analytics.track('Refresh', {
      screen_name: window.Vue.$route.name,
      nonInteraction: 1,
    });
  }

  triggerTrailerPlayEvent(episode, reason) {
    const data = {
      screen_name: window.Vue.$route.name,
      trailer_uuid: episode.uuid,
      trailer_title: episode.title,
      content_type: 'trailer',
      play_reason: reason,
    };
    window.Vue.$analytics.track('Trailer Play', data);
  }

  triggerTrailerPauseEvent(episode, reason) {
    const data = {
      screen_name: window.Vue.$route.name,
      trailer_uuid: episode.uuid,
      trailer_title: episode.title,
      content_type: 'trailer',
      stop_reason: reason,
      nonInteraction: 1,
    };
    window.Vue.$analytics.track('Trailer Stop', data);
  }

  triggerEpisodePlaybackStartEvent(episode) {
    const { sessionId, screenName } = window.$store.getters;
    if (episode === null || (episode && (!episode.uuid || (episode.episode_type && episode.episode_type === 'trailer')))) {
      return;
    }
    const epProgress = this.episodeProvider.getEpisodeProgressFromLocalStore(episode.uuid);

    const data = {
      episode_uuid: episode.uuid,
      episode_type: episode.episode_type,
      player_time: epProgress ? parseInt(epProgress.elapsed, 10) : 0,
      stream_session_uuid: sessionId,
      episode_exclusive: episode.is_exclusive,
      screen_name: screenName || window.Vue.$route.name,
    };

    window.Vue.$analytics.track('Episode Playback Start', data);
  }

  triggerEpisodePlayEvent(episode, playReason = 'press_play') {
    const { sessionId, playbackSpeed, screenName } = window.$store.getters;
    if (!episode.uuid || (episode.episode_type && episode.episode_type === 'trailer')) {
      return;
    }
    const epProgress = this.episodeProvider.getEpisodeProgressFromLocalStore(episode.uuid);

    const data = {
      episode_exclusive: episode.is_exclusive,
      episode_type: episode.episode_type || 'full',
      episode_uuid: episode.uuid,
      play_reason: playReason,
      playback_speed: playbackSpeed,
      player_time: epProgress ? parseInt(epProgress.elapsed, 10) : 0,
      screen_name: screenName || window.Vue.$route.name,
      podcast_exclusive: episode.season.podcast.is_exclusive,
      stream_session_uuid: sessionId,
    };

    window.Vue.$analytics.track('Episode Play', data);
  }

  triggerEpisodePauseEvent(episode, stopReason = 'pause') {
    const { sessionId, screenName } = window.$store.getters;
    if (episode === null || (episode && (!episode.uuid || (episode.episode_type && episode.episode_type === 'trailer')))) {
      return;
    }
    const epProgress = this.episodeProvider.getEpisodeProgressFromLocalStore(episode.uuid);

    const data = {
      episode_uuid: episode.uuid,
      player_time: epProgress ? parseInt(epProgress.elapsed, 10) : 0,
      stop_reason: stopReason,
      stream_session_uuid: sessionId,
      episode_exclusive: episode.is_exclusive,
      screen_name: screenName || window.Vue.$route.name,
      account_type: utilityService.getAccountType(),
    };

    window.Vue.$analytics.track('Episode Stop', data);
  }

  triggerForwardEvent(episode) {
    const { sessionId, screenName } = window.$store.getters;
    if (!episode.uuid || (episode.episode_type && episode.episode_type === 'trailer')) {
      return;
    }
    const epProgress = this.episodeProvider.getEpisodeProgressFromLocalStore(episode.uuid);

    const data = {
      episode_exclusive: episode.is_exclusive,
      episode_uuid: episode.uuid,
      player_time: epProgress ? parseInt(epProgress.elapsed, 10) : 0,
      forward_value: 30000,
      screen_name: screenName || window.Vue.$route.name,
      podcast_exclusive: episode.season.podcast.is_exclusive,
      stream_session_uuid: sessionId,
    };

    window.Vue.$analytics.track('Episode Fast Forward', data);
  }

  triggerRewindEvent(episode) {
    const { sessionId, screenName } = window.$store.getters;
    if (!episode.uuid || (episode.episode_type && episode.episode_type === 'trailer')) {
      return;
    }
    const epProgress = this.episodeProvider.getEpisodeProgressFromLocalStore(episode.uuid);

    const data = {
      episode_exclusive: episode.is_exclusive,
      episode_uuid: episode.uuid,
      player_time: epProgress ? parseInt(epProgress.elapsed, 10) : 0,
      rewind_value: 15000,
      screen_name: screenName || window.Vue.$route.name,
      podcast_exclusive: episode.season.podcast.is_exclusive,
      stream_session_uuid: sessionId,
    };

    window.Vue.$analytics.track('Episode Rewind', data);
  }

  triggerScrubEvent(episode, playerTime, newPlayerTime) {
    const { sessionId, screenName } = window.$store.getters;
    if (!episode.uuid || (episode.episode_type && episode.episode_type === 'trailer')) {
      return;
    }

    const data = {
      episode_exclusive: episode.is_exclusive,
      episode_uuid: episode.uuid,
      new_player_time: parseInt(newPlayerTime, 10),
      player_time: parseInt(playerTime, 10),
      screen_name: screenName || window.Vue.$route.name,
      podcast_exclusive: episode.season.podcast.is_exclusive,
      stream_session_uuid: sessionId,
    };

    window.Vue.$analytics.track('Episode Scrub', data);
  }

  triggerHeartbeatEvent(episode) {
    const { sessionId, playbackSpeed } = window.$store.getters;
    if (!episode.uuid || (episode.episode_type && episode.episode_type === 'trailer')) {
      return;
    }
    const epProgress = this.episodeProvider.getEpisodeProgressFromLocalStore(episode.uuid);

    const data = {
      episode_uuid: episode.uuid,
      player_time: epProgress ? epProgress.elapsed : 0,
      playback_speed: playbackSpeed,
      stream_session_uuid: sessionId,
      nonInteraction: 1,
      account_type: utilityService.getAccountType(),
    };

    window.Vue.$analytics.track('Episode Heartbeat', data);
  }

  triggerPlaybackSpeedEvent(playbackSpeed, playerTime) {
    const { sessionId } = window.$store.getters;
    window.Vue.$analytics.track('Episode Playback Speed', {
      new_selected_speed: parseFloat(playbackSpeed),
      player_time: parseInt(playerTime, 10),
      stream_session_uuid: sessionId,
    });
  }

  triggerShowDetailsViewedEvent(show) {
    window.Vue.$analytics.track('Show Details Viewed', {
      podcast_uuid: show.uuid,
      podcast_exclusive: show.is_exclusive,
      nonInteraction: 1,
    });
  }

  triggerMyShowsAddEvent(podcast) {
    window.Vue.$analytics.track('My Shows Add', {
      screen_name: window.Vue.$route.name,
      podcast_uuid: podcast.uuid,
      podcast_exclusive: podcast.is_exclusive,
    });
  }

  triggerMyShowsRemoveEvent(podcast) {
    window.Vue.$analytics.track('My Shows Remove', {
      screen_name: window.Vue.$route.name,
      podcast_uuid: podcast.uuid,
      podcast_exclusive: podcast.is_exclusive,
    });
  }

  triggerSDPSortEvent(podcast, sortOrder) {
    window.Vue.$analytics.track('SDP Sort', {
      podcast_uuid: podcast.uuid,
      sort_order: sortOrder,
    });
  }

  triggerMyShowsReorderEvent(showUUID, newPos, oldPos) {
    window.Vue.$analytics.track('My Shows Reorder', {
      podcast_uuid: showUUID,
      new_position: newPos,
      original_position: oldPos,
    });
  }

  triggerEpisodesDetailsViewedEvent(episode) {
    window.Vue.$analytics.track('Episode Details Viewed', {
      screen_name: window.Vue.$route.name,
      episode_uuid: episode.uuid,
      episode_exclusive: episode.is_exclusive,
      podcast_exclusive: episode.season.podcast.is_exclusive,
      nonInteraction: 1,
    });
  }

  async triggerEpisodeAddEvent(episode) {
    await window.Vue.$analytics.track('Episode Add', {
      screen_name: window.Vue.$route.name,
      episode_uuid: episode.uuid,
    });
  }

  async triggerEpisodeRemoveEvent(episode) {
    await window.Vue.$analytics.track('Episode Remove', {
      screen_name: window.Vue.$route.name,
      episode_uuid: episode.uuid,
    });
  }

  triggerEpisodeReorderEvent(episodeUUID, newPos, oldPos) {
    window.Vue.$analytics.track('Episode Reorder', {
      episode_uuid: episodeUUID,
      new_position: newPos,
      old_position: oldPos,
    });
  }

  getBucketType(bucket) {
    const dynamicBuckets = ['continue-listening', 'new-releases', 'recommended-podcasts', 'user-recommended-categories'];
    if (dynamicBuckets.indexOf(bucket.bundle_uuid) > -1) {
      return 'dynamic';
    }
    return bucket.bucket_type;
  }

  getContentType(bucket) {
    const podcasts = ['seel_all_podcasts', 'sdp_podcasts', 'podcasts', 'featured'];
    const episodes = ['see_all_episodes', 'my_shows_episodes', 'sdp_episodes', 'edp_episodes', 'episodes', 'search_episodes'];
    const categories = ['category', 'categories', 'top_categories'];
    const publishers = ['publisher', 'publishers', 'search_publishers'];
    const trailer = ['trailer'];
    if (podcasts.indexOf(bucket.bucket_type) > -1) {
      return 'podcast';
    } if (episodes.indexOf(bucket.bucket_type) > -1) {
      return 'episode';
    } if (categories.indexOf(bucket.bucket_type) > -1) {
      return 'category';
    } if (publishers.indexOf(bucket.bucket_type) > -1) {
      return 'publisher';
    } if (trailer.indexOf(bucket.bucket_type) > -1) {
      return 'trailer';
    }
    return bucket.bucket_type;
  }
}

export default Events;
