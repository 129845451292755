import Subscription from '@/services/Subscription';
import { getServices } from '@/services/serviceProvider';

const [subscriptionProvider] = getServices([Subscription]);

const PROMOCODE_STATES = {
  IDLE: 'idle',
  LOADING: 'loading',
  ERROR: 'error',
  SUCCESS: 'success',
};

const getters = {
  isLoading: state => state.promocodeState === PROMOCODE_STATES.LOADING,
  isError: state => state.promocodeState === PROMOCODE_STATES.ERROR,
  isSuccess: state => state.promocodeState === PROMOCODE_STATES.SUCCESS,
  promocodeError: state => state.error,
  promocodeData: state => promocode => state.promocodes[promocode],
  promocodes: state => Object.values(state.promocodes),
  currentRedeeming: state => state.currentRedeeming,
  currentRedeemingData: state => state.promocodes[state.currentRedeeming],
};

const mutations = {
  setIsLoading: (state) => { state.promocodeState = PROMOCODE_STATES.LOADING; },
  setProcessDone: (state) => { state.promocodeState = PROMOCODE_STATES.SUCCESS; },
  setProcessFail: (state) => { state.promocodeState = PROMOCODE_STATES.ERROR; },
  setError: (state, error) => { state.error = error; },
  setPromocode: (state, payload) => { state.promocodes[payload.promocode] = payload; },
  setCurrentRedeeming: (state, payload) => { state.currentRedeeming = payload; },
  deletePromocode: (state, code) => { delete state.promocodes[code]; },
};

const state = {
  promocodeState: PROMOCODE_STATES.IDLE,
  promocodes: {},
  error: '',
  currentRedeeming: null,
};

const actions = {
  setCurrentRedeeming(context, codeData) {
    context.commit('setCurrentRedeeming', codeData);
  },
  validatePromocode(context, payload) {
    context.commit('setIsLoading');
    context.commit('setError', '');
    return subscriptionProvider.validatePromocodeV2(payload.promocode).then((promocodeData) => {
      if (!promocodeData.valid) {
        throw new Error('Promo code is not valid');
      }
      context.commit('setPromocode', { promocode: payload.promocode, ...promocodeData.properties });
      context.commit('setProcessDone');
      // will not use old promo code processing:
      /*
      const promoData = {
        promoCode: payload.promocode,
      };
      if (promocodeData.provider === 'timesprime') promoData.timesPrime = promocodeData;
      context.commit('setPromoData', promoData, { root: true });
       */
    }).catch((err) => {
      context.commit('setProcessFail');
      context.commit('setError', err.message);
    });
  },
  redeemPromocode(context, payload) {
    context.commit('setIsLoading');
    context.commit('setError', '');
    return subscriptionProvider.redeemPromocodeV2(payload.promocode).then((promocodeData) => {
      if (!promocodeData.valid) {
        throw new Error('Promo code is not valid');
      }
      context.commit('setPromocode', { promocode: payload.promocode, ...promocodeData.properties });
      context.commit('setCurrentRedeeming', payload.promocode);
      context.commit('setProcessDone');
      // will not use old promo code processing:
      /*
      const promoData = {
        promoCode: payload.promocode,
      };
      if (promocodeData.provider === 'timesprime') promoData.timesPrime = promocodeData;
      context.commit('setPromoData', promoData, { root: true });
       */
      return promocodeData;
    }).catch((err) => {
      context.commit('setProcessFail');
      context.commit('setError', err.error);
      throw err;
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
