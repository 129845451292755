<template>
  <div>
    <EpisodeDetails v-if="!is_edp_v2"/>
    <EpisodeDetailsV2 v-if="is_edp_v2"/>
  </div>
</template>
<script>
import EpisodeDetailsV2 from './EpisodeDetails';
import EpisodeDetails from '../EpisodeDetails';
import PageMixin from '../../shared/pageMixin';

export default {
  name: 'EpisodeDetailsWrapper',
  components: {
    EpisodeDetailsV2,
    EpisodeDetails,
  },
  mixins: [PageMixin],
  data() {
    return {
      is_edp_v2: false,
    };
  },
  beforeMount() {
    this.getFeatureFlag();
  },
  methods: {
    getFeatureFlag() {
      this.is_edp_v2 = this.$store.getters.flagWithDefault('web_v2_edp', true);
    },
  },
};
</script>
