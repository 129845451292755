const addPageActions = {
  // Home and Originals Pages
  Web_LoadPodcastsExclusiveSuccess: 'Web_LoadPodcastsExclusiveSuccess',
  Web_LoadPodcastsExclusiveFailure: 'Web_LoadPodcastsExclusiveFailure',
  // Category Details and Publisher Details Pages
  Web_LoadCategoryPodastsSuccess: 'Web_LoadCategoryPodastsSuccess',
  Web_LoadCategoryPodastsFailure: 'Web_LoadCategoryPodastsFailure',
  Web_LoadPublisherPodastsSuccess: 'Web_LoadPublisherPodastsSuccess',
  Web_LoadPublisherPodastsFailure: 'Web_LoadPublisherPodastsFailure',
  // Show Details Page
  Web_LoadShowDetailsV2Success: 'Web_LoadShowDetailsV2Success',
  Web_LoadShowDetailsV2Failure: 'Web_LoadShowDetailsV2Failure',
  Web_LoadShowDetailsV1: 'Web_LoadShowDetailsV1',
  Web_LoadShowDetailsV2: 'Web_LoadShowDetailsV2',
  // Episode Details Page
  Web_LoadEpisodeDetailsV2Success: 'Web_LoadEpisodeDetailsV2Success',
  Web_LoadEpisodeDetailsV2Failure: 'Web_LoadEpisodeDetailsV2Failure',
  Web_LoadEpisodeDetailsV1: 'Web_LoadEpisodeDetailsV1',
  Web_LoadEpisodeDetailsV2: 'Web_LoadEpisodeDetailsV2',
  // Login Page
  Web_LoginSuccess: 'Web_LoginSuccess',
  Web_LoginFailure: 'Web_LoginFailure',
  // Register Page
  Web_RegistrationSuccess: 'Web_RegistrationSuccess',
  Web_RegistrationFailure: 'Web_RegistrationFailure',
  Web_LoadProductsSuccess: 'Web_LoadProductsSuccess',
  Web_LoadProductsFailure: 'Web_LoadProductsFailure',
  // Search Page
  Web_LoadPreSearchSuccess: 'Web_LoadPreSearchSuccess',
  Web_LoadPreSearchFailure: 'Web_LoadPreSearchFailure',
  Web_SearchActionSuccess: 'Web_SearchActionSuccess',
  Web_SearchActionFailure: 'Web_SearchActionFailure',
  // Marketing Pages
  Web_LoadTrailerSuccess: 'Web_LoadTrailerSuccess',
  Web_LoadTrailerFailure: 'Web_LoadTrailerFailure',
  // Subscription Page
  Web_LoadSubscriptionSuccess: 'Web_LoadSubscriptionSuccess',
  Web_LoadSubscriptionFailure: 'Web_LoadSubscriptionFailure',
  Web_CancelSubscriptionSuccess: 'Web_CancelSubscriptionSuccess',
  Web_CancelSubscriptionFailure: 'Web_CancelSubscriptionFailure',
  // Our client event_tracking abstraction to custom event endpoint
  Web_AnalyticsPayloadSuccess: 'Web_AnalyticsPayloadSuccess',
  Web_AnalyticsPayloadFailure: 'Web_AnalyticsPayloadFailure',
  // Billing Page

  // Play Episode/Trailer

  // Login Error Types + Status Codes

  // Register Error Types + Status Codes
};

export default addPageActions;
