<template>
  <div class="install-skill">
    <div class="install-skill__container container">
      <div class="install-skill__content">
        <div class="install-skill__speach">
          <div class="install-skill__image">
            <img
              src="../../assets/images/alexa/bubble-luminary.png"
              alt="">
          </div>
          <div class="install-skill__copy">
            <div class="vertical-align">
              <p class="copy">Alexa, open Luminary</p>
            </div>
          </div>
        </div>
        <install-button />
        <p><router-link
          v-button-event
          to="/alexa"
          aria-label="Learn more"
        >Learn More</router-link></p>
      </div>
      <div class="install-skill__device">
        <img
          src="../../assets/images/alexa/speaker-regular.png"
          alt="">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {
    'install-button': () => import('../../pages/alexa/InstallButton'),
  },
};
</script>

<style lang="scss" scoped>
@import './InstallSkill.scss';
</style>
