<template>
  <div
    v-if="exclusive_podcasts_loaded"
    class="exclusives-gallery"
    data-test="exclusives-gallery">
    <div
      v-for="(section, index) in exclusiveSections"
      :key="`section-${index}`"
      :class="{ 'exclusives-section': !isOriginals, 'originals-section': isOriginals }"
      data-test="exclusives-section"
      @mouseover="() => sectionHover(index)"
      @mouseleave="() => sectionLeave(index)">
      <podcast-list
        :bucket="section.bucket"
        :is-exclusive="!isOriginals"
        :is-original="isOriginals"
        :has-hover-state="isOriginals"
        :hide-section-title="isOriginals && hoveredSection === index - 1 && hoveredCardIndex === 0"
        :handle-card-hover="handleCardHover"
        type="podcast"/>
    </div>

  </div>
</template>

<script>
import find from 'lodash/find';
import * as log from 'loglevel';
import PodcastService from '../services/Podcast';
import PodcastList from './PodcastList';
import staticExclusivesConfig from '../config/globalConfigStaticData/exclusives_sections';
import nr_analyticsManager from '../shared/new-relic/analyticsManager';
import nr_addPageActions from '../shared/new-relic/addPageActions';

export default {
  name: 'Home',
  components: {
    'podcast-list': PodcastList,
  },
  props: {
    hideOtherTitlesOnHover: {
      type: Boolean,
      default: false,
    },
    isOriginals: {
      type: Boolean,
      default: false,
    },
    parentPage: {
      type: String,
      default: 'Home',
    },
  },
  data() {
    return {
      exclusiveSections: [],
      podcast_service: new PodcastService(),
      exclusive_podcasts: {
        uuid: null,
        name: null,
        bucket_type: 'podcasts',
        media_size: 'thumbnail',
        items: [],
      },
      exclusive_podcasts_loaded: false,
      static_exclusives_config: staticExclusivesConfig,
      hoveredSection: null,
      hoveredCardIndex: null,
    };
  },
  beforeMount() {
    this.getLuminaryExclusiveShows();
  },
  methods: {
    handleCardHover(cardIndex) {
      if (this.isOriginals) {
        this.hoveredCardIndex = cardIndex;
      }
    },
    sectionHover(sectionIndex) {
      this.hoveredSection = sectionIndex;
    },
    sectionLeave() {
      this.hoveredSection = null;
    },
    getLuminaryExclusiveShows() {
      const ctx = this;
      this.podcast_service.getExclusivePodcasts()
        .then((res) => {
          const podcasts = res.podcasts || [];
          // NOTE: we're now only using the hard-coded json file, no optimizely integration
          this.exclusiveSections = ctx.static_exclusives_config.sections.map(section => ({
            ...section,
            bucket: {
              uuid: null,
              name: section.title,
              bucket_type: 'podcasts',
              media_size: 'thumbnail',
              items: section.shows
                .map((showUuid) => {
                  const hydratedPodcast = find(podcasts, { uuid: showUuid }, {});
                  if (hydratedPodcast) {
                    hydratedPodcast.publisher_name = hydratedPodcast.description;
                  } else {
                    log.info('podcast is not exclusive:', showUuid);
                  }
                  return hydratedPodcast;
                })
                .filter(podcast => !!podcast),
              items_count: section.shows.filter(showUuid => find(podcasts, { uuid: showUuid }, null)).length,
            },
          }));
          nr_analyticsManager.addPageAction(nr_addPageActions.Web_LoadPodcastsExclusiveSuccess, { parentPage: this.parentPage });
        })
        .catch((error) => {
          log.info(error.message);
          nr_analyticsManager.addPageAction(nr_addPageActions.Web_LoadPodcastsExclusiveFailure, { parentPage: this.parentPage });
        })
        .then(() => { this.exclusive_podcasts_loaded = true; });
    },
  },
};
</script>

<style lang="scss">
@import "../assets/scss/variables.scss";
@import "../assets/scss/mixins.scss";

// TODO: once we're fully migrated to web 2.0 remove .exclusives-section class
.exclusives-section {
  margin-bottom: 50px;
  .podcast-list {
    .bucket-title {
      margin: 0 0 7px;
      h1 {
        font-family: $clear-sans;
        font-size: 30px;
        line-height: 35px;
        letter-spacing: -0.6px;
        color: #111111;
        @media (max-width: 768px) {
          width: 100%;
          font-size: 20px !important;
          line-height: 23px !important;
          letter-spacing: -0.6px !important;
          text-align: center !important;
        }
      }
    }
    .picture-component {
      img {
        object-fit: cover;
      }
    }
    .flickity-enabled {
      .flickity-button {
        top: 50%;
        background-color: black !important;
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
        @media (min-width: 768px) {
          display: block !important;
          &[disabled] {
            display: none !important;
          }
        }
        svg {
          fill: white;
        }
      }
    }
  }
}

.originals-section {
  margin-bottom: -63px;
  background-color: $bg-color;
  @media (max-width: 575px) {
    margin-bottom: 10px;
  }
  .podcast-list {
    .bucket-title {
      margin: 0 0 7px;
      h1 {
        color: #FFFFFF;
        font-family: $clearsans;
        font-weight: 500;
        font-size: 24px;
        line-height: 40px;
        margin-bottom: -56px;
        transform: translateY(12px);
        @media (max-width: 575px) {
          margin-bottom: 14px;
          transform: translateY(0px);
          width: 100%;
          font-size: 20px !important;
          line-height: 23px !important;
          letter-spacing: -0.6px !important;
          /* text-align: center !important; */
        }
      }
    }
    .picture-component {
      img {
        object-fit: cover;
      }
    }
    .flickity-enabled {
      .flickity-button {
        z-index: 99;
        top: 32%;
        background-color: rgba(255,255,255, 0.9) !important;
        box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.2), 0px 3px 14px rgba(0, 0, 0, 0.12), 0px 8px 10px rgba(0, 0, 0, 0.14);
        @media (min-width: 768px) {
          display: block !important;
          &[disabled] {
            display: none !important;
          }
        }
        svg {
          fill: #00AECB;
          width: 50%;
          height: 50%;
          left: 25%;
          top: 25%;
        }
      }
    }
  }
}
</style>
