import AxiosAPI from './AxiosAPI';

class Gift extends AxiosAPI {
  redeemGift(code) {
    return new Promise((resolve, reject) => {
      super.post(`v1/gift/${code}/redeem`).then((response) => {
        resolve(response);
      }).catch((error) => {
        reject(error);
      });
    });
  }

  purchaseGift(requestData) {
    return new Promise((resolve, reject) => {
      super.post('v2/gift', requestData).then((response) => {
        resolve(response);
      }).catch((error) => {
        reject(error);
      });
    });
  }

  purchaseGiftFinal(requestData) {
    return super.post('v1/finalize/gift', requestData);
  }
}

export default Gift;
