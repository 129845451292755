import Vue from 'vue';
import Router from 'vue-router';
import $store from '../store/index';
import UserService from '../services/User';

import HomeWrapper from '../pages/webv2/HomeWrapper.vue';
import ShowDetailsWrapper from '../pages/webv2/ShowDetailsWrapper';
import EpisodeDetailsWrapper from '../pages/webv2/EpisodeDetailsWrapper';
import PricingPlans from '../pages/onboarding/PricingPlans';
import SignIn from '../pages/onboarding/SignIn';
import AdyenBuyPremium from '../pages/onboarding/AdyenBuyPremium';
import UpdatePassword from '../pages/account/UpdatePassword';
import PageNotFound from '../pages/404';
import BespokeShow from '../pages/bespoke/BespokeShow';
import BespokeShowTrevor from '../pages/bespoke/BespokeTrevor';
import BespokeShowTheBlackList from '../pages/bespoke/BespokeTheBlackList';
import BespokeShowCeleste from '../pages/bespoke/BespokeCeleste';
import BespokeShowLies from '../pages/bespoke/BespokeLies';
import MyShows from '../pages/user_library/Shows.vue';
import PodcastEpisodes from '../pages/user_library/PodcastEpisodes.vue';
import Episodes from '../pages/user_library/Episodes.vue';
import GiftAYear from '../pages/webv2/GiftAYear';
import Gift from '../pages/webv2/Gift';
import RedeemGift from '../pages/webv2/RedeemGift';
import DownloadApp from '../pages/webv2/DownloadApp';
import E1Plans from '../components/signup2/E1Plans';
import E1Account from '../components/signup2/E1Account';
import E1Billing from '../components/signup2/E1Billing';
import BrightbackCancel from '../pages/brightback/BrightbackCancel';
import BrightbackKeep from '../pages/brightback/BrightbackKeep';
import AccountInfo from '../pages/account/Info';

const { LS_KEY } = window.Luminary;
const ACAST_REDIRECTS = [
  {
    path: '/listen/talib-kweli-yasiin-bey-and-dave-chappelle/the-midnight-miracle/c88501e3-f783-43cc-b00a-022d2a3ad33b',
    redirect: 'https://try.luminarypodcasts.com/listen/the-midnight-miracle/653fda38bfecf800129c4337',
  },
];

function requireAuth(to, from, next) {
  if ($store.getters.loggedIn) {
    next();
  } else if (to.path === '/premium-buy' || to.path === '/account/subscription' || to.path === '/user-categories') {
    next({ path: '/signin', query: { redirect: to.fullPath } });
  } else {
    next({ path: '/signin', query: { redirect: to.fullPath } });
  }
}

Vue.use(Router);

const router = new Router({
  linkActiveClass: 'active',
  mode: 'history',
  base: __dirname,
  scrollBehavior() {
    document.querySelector('body').scrollTop = 0;
    document.querySelector('html').scrollTop = 0;
    return { x: 0, y: 0 };
  },
  routes: [
    ...ACAST_REDIRECTS.map(r => ({
      path: r.path,
      beforeEnter() {
        window.location.href = r.redirect;
      },
    })),
    { name: 'Premium Page', path: '/premium', component: HomeWrapper },
    {
      name: 'Landing Page',
      path: '/',
      beforeEnter: (to, from, next) => {
        if ($store.getters.loggedIn && $store.getters.user) {
          next({ path: '/shows', query: to.query });
        } else if (!$store.getters.loggedIn && $store.getters.user && to.path !== '/') {
          next({ path: '/', query: to.query });
        } else {
          next();
        }
      },
      component: HomeWrapper,
    },
    {
      name: 'settings_account',
      path: '/account',
      component: () => import('../pages/Account.vue'),
      beforeEnter: requireAuth,
      children: [
        {
          name: 'My Account - Info',
          path: 'info',
          component: () => import('../pages/account/Info.vue'),
        },
        {
          name: 'My Account - Feedback',
          path: 'feedback',
          component: () => import('../pages/account/Feedback.vue'),
        },
        {
          name: 'My Account - Subscriptions',
          path: 'subscription',
          component: () => import('../pages/account/SubscriptionsNew.vue'),
        },
      ],
    },
    {
      path: '/listen',
      component: () => import('../pages/Discover'),
      beforeEnter: (to, from, next) => {
        if (to.path === '/listen') {
          next({ path: '/shows', query: to.query });
        } else {
          next();
        }
      },
      children: [
        { name: 'search', path: 'search', component: () => import('../pages/Search.vue') },
        {
          name: 'Library',
          path: '',
          component: () => import('../pages/user_library/Index.vue'),
          children: [
            { name: 'user_shows', path: 'my-shows', component: MyShows },
            { name: 'user_shows_episodes', path: 'my-shows/list', component: PodcastEpisodes },
            { name: 'user_playlist', path: 'bookmarks', component: Episodes },
          ],
        },
        { name: 'categories_all', path: 'category', component: () => import('../pages/Categories.vue') },
        {
          name: 'category_detail', path: 'category/:categoryName/:categoryID', component: () => import('../pages/CategoryDetail.vue'), meta: { title: 'categoryPage' },
        },
        {
          name: 'publisher_detail', path: ':publisher_slug/:publisherID', component: () => import('../pages/CategoryDetail.vue'), meta: { title: 'publisherPage' },
        },
        { name: 'show_detail', path: ':publisher_slug/:show_slug/:podcastID', component: ShowDetailsWrapper },
        { name: 'episode_detail', path: ':publisher_slug/:show_slug/:episode_slug/:episodeID', component: EpisodeDetailsWrapper },
      ],
    },
    {
      path: '',
      component: () => import('../pages/Onboarding'),
      children: [
        {
          name: 'upgrade_plan',
          path: '/plans',
          beforeEnter: (to, from, next) => {
            if ($store.getters.loggedIn && $store.getters.user && $store.getters.user.is_subscribed) {
              next({ path: '/account/subscription', query: to.query });
            } else if ($store.getters.loggedIn && $store.getters.user && !$store.getters.user.is_subscribed) {
              next();
            } else {
              next({ path: '/signup' });
            }
          },
          component: PricingPlans,
        },
        {
          name: 'account_create',
          path: '/signup',
          beforeEnter: (to, from, next) => {
            const isExperimentalSignup = to.meta && to.meta.step;
            if ($store.getters.loggedIn && !window.localStorage.getItem(LS_KEY) && !isExperimentalSignup) {
              next({ path: '/shows' });
            } else {
              next();
            }
          },
          component: () => import('../pages/AcquisitionFlowWrapper'),
          children: [
            {
              name: 'signup_step1',
              path: 'plan',
              component: E1Plans,
              meta: {
                step: 1,
                prev: { path: '/' },
                next: { name: 'signup_step2' },
                hideHeader: true,
                hideFooter: true,
              },
            },
            {
              name: 'signup_step2',
              path: 'account',
              component: E1Account,
              meta: {
                step: 2,
                prev: { name: 'signup_step1' },
                next: { name: 'signup_step3' },
                hideHeader: true,
                hideFooter: true,
              },
            },
            {
              name: 'signup_step3',
              path: 'billing',
              component: E1Billing,
              meta: {
                step: 3,
                prev: { name: 'signup_step2' },
                next: { name: 'Originals', query: { newSubscription: true } },
                hideHeader: true,
                hideFooter: true,
              },
            },
            {
              name: 'signup_done',
              path: 'done',
              component: () => import('../components/signup2/E1Done'),
              meta: {
                step: 4,
                prev: { name: 'signup_step3' },
                next: { path: '/' },
                hideHeader: false,
                hideFooter: false,
              },
            },
          ],
        },
        { name: 'Verify', path: '/verify', component: () => import('../pages/onboarding/Verify') },
        {
          name: 'sign_in',
          path: '/signin',
          beforeEnter: (to, from, next) => {
            if ($store.getters.loggedIn) {
              next({ path: '/shows' });
            } else {
              next();
            }
          },
          component: SignIn,
        },
        { name: 'Forgot Password', path: '/forgotPassword', component: () => import('../pages/onboarding/ForgotPassword') },
        {
          path: '/sign-up',
          redirect: '/signup',
        },
        {
          name: 'Premium Subscribe',
          path: '/premium-buy',
          beforeEnter: (to, from, next) => {
            if ($store.getters.featureFlags.annual_plan && $store.getters.loggedIn && !$store.getters.selectedPlan) {
              next({ path: '/plans', query: to.query });
            } else if ($store.getters.featureFlags.annual_plan && !$store.getters.loggedIn && !$store.getters.selectedPlan) {
              next({ path: '/signup', query: to.query });
            }
            next();
          },
          component: AdyenBuyPremium,
        },
        {
          path: '',
          redirect: '/listen',
        },
      ],
    },
    {
      name: 'My Account Settings',
      path: '/account',
      component: () => import('../pages/Account.vue'),
      beforeEnter: requireAuth,
      children: [
        {
          name: 'My Account Settings - Info',
          path: 'info/:code?',
          component: AccountInfo,
          props: route => ({
            code: route.params.code,
          }),
        },
        { name: 'My Account Settings - Feedback', path: 'feedback', component: () => import('../pages/account/Feedback') },
        { name: 'My Account Settings - Subscriptions', path: 'subscription', component: () => import('../pages/account/Subscriptions') },
      ],
    },
    {
      name: 'My Account Settings - Change Password', beforeEnter: requireAuth, path: '/account/updatepassword', component: UpdatePassword,
    },
    { name: 'Password reset', path: '/resetpassword', component: () => import('../pages/ResetPassword') },
    {
      name: 'Third Party Language iOS',
      path: '/ios-terms',
      component: () => import('../pages/IOSThirdParty.vue'),
    },
    {
      name: 'Third Party Language Android',
      path: '/android-terms',
      component: () => import('../pages/AndroidThirdParty.vue'),
    },
    {
      name: 'Privacy',
      path: '/privacy',
      component: () => import('../pages/Privacy.vue'),
    },
    {
      name: 'Terms',
      path: '/terms',
      component: () => import('../pages/Terms.vue'),
    },
    {
      name: 'Terms Free Trial',
      path: '/promoterms',
      component: () => import('../pages/Terms.vue'),
    },
    // { name: 'Home Screen - Bundle Preview', path: '/bundle/:bundle_id', component: ListenHome },
    { name: 'Originals', path: '/shows', component: () => import('../pages/webv2/Originals.vue') },
    (function internationalRoute() {
      const res = { name: 'International', path: '/international' };
      if ($store.getters.featureFlags.apple_podcasts) {
        res.redirect = '/unsupported';
      } else {
        res.component = () => import('../pages/international/International.vue');
      }
      return res;
    }()),
    { name: 'Unsupported', path: '/unsupported', component: () => import('../pages/international/Unsupported.vue') },
    {
      name: 'Careers',
      path: '/careers',
      component: () => import('../pages/careers/Careers.vue'),
    },
    {
      name: 'JobDetails',
      path: '/job/:jobId',
      component: () => import('../pages/careers/JobDetail.vue'),
    },
    {
      name: 'Alexa',
      path: '/alexa',
      component: () => import('../pages/alexa/Alexa.vue'),
    },
    {
      name: 'Fiasco Season 2',
      path: '/fiasco',
      component: BespokeShow,
    },
    {
      name: 'Chappelle mlp',
      path: '/chappelle-mlp',
      component: () => import('@/pages/bespoke/BespokeChappelle2'),
      meta: {
        isStatic: true,
        hideHeader: true,
        hideFooter: true,
      },
    },
    {
      name: 'The Trevor Noah Podcast',
      path: '/trevor',
      component: BespokeShowTrevor,
    },
    {
      path: '/spooked',
      redirect: '/listen/snap-judgment-and-wnyc-studios/snap-judgment-presents-spooked/ddef3227-882b-40a3-be76-fafd0d71424f',
    },
    {
      name: 'The Black List',
      path: '/blacklist',
      component: BespokeShowTheBlackList,
    },
    {
      name: 'Celeste & Her Best',
      path: '/celeste',
      component: BespokeShowCeleste,
    },
    {
      name: 'Lies We Tell',
      path: '/lies',
      component: BespokeShowLies,
    },
    {
      name: 'Gift Payment',
      path: '/gift-a-year',
      component: GiftAYear,
    },
    {
      name: 'Gift a Subscription',
      path: '/gift',
      component: Gift,
    },
    {
      name: 'Redeem a Gift',
      path: '/redeem-a-gift',
      component: RedeemGift,
    },
    {
      name: 'Download the App',
      path: '/download-the-app',
      component: DownloadApp,
    },
    {
      name: 'Brightback keep',
      path: '/brightback/keep',
      component: BrightbackKeep,
    },
    {
      name: 'Brightback cancel',
      path: '/brightback/cancel',
      component: BrightbackCancel,
    },
    {
      name: 'HLS test',
      path: '/hls-poc',
      component: () => import('../pages/experimental/HlsPoc.vue'),
    },
    {
      name: 'Verify Email Initial',
      path: '/verify/:code',
      redirect: '/account/info/:code',
    },
    {
      name: 'Promo codes',
      path: '/redeem-promo',
      component: () => import('../pages/RedeemPromo.vue'),
      meta: {
        hideHeader: true,
        hideFooter: true,
      },
      children: [
        {
          name: 'redeem_promo_step_initial',
          path: '',
          component: () => import('../components/webv2/promoCodes/PromoCodeForm'),
          meta: {
            step: 1,
            prev: { path: '/' },
            next: { name: 'redeem_promo_step2' },
            hideHeader: true,
            hideFooter: true,
          },
        },
        {
          name: 'redeem_promo_step1',
          path: 'code',
          component: () => import('../components/webv2/promoCodes/PromoCodeForm'),
          meta: {
            step: 1,
            prev: { path: '/' },
            next: { name: 'redeem_promo_step2' },
            hideHeader: true,
            hideFooter: true,
          },
        },
        {
          name: 'redeem_promo_step2',
          path: 'account',
          component: () => import('../components/webv2/promoCodes/PromoCodeAccount'),
          meta: {
            step: 2,
            prev: { name: 'redeem_promo_step1' },
            next: { name: 'redeem_promo_step3' },
            hideHeader: true,
            hideFooter: true,
          },
        },
        {
          name: 'redeem_promo_step3',
          path: 'billing',
          component: () => import('../components/webv2/promoCodes/PromoCodeBilling'),
          meta: {
            step: 3,
            prev: { name: 'redeem_promo_step2' },
            next: { name: 'redeem_promo_done' },
            hideHeader: true,
            hideFooter: true,
          },
        },
        {
          name: 'redeem_promo_done',
          path: 'done',
          component: () => import('../components/webv2/promoCodes/PromoCodeDone'),
          meta: {
            step: 4,
            prev: { name: 'redeem_promo_step3' },
            next: { path: '/' },
            hideHeader: false,
            hideFooter: false,
          },
        },
      ],
    },
    { name: '404', path: '/not-found/404', component: PageNotFound },
    { path: '*', redirect: '/not-found/404' },
  ],
});

const hasQueryParams = route => !!Object.keys(route.query).length;

router.beforeEach((to, from, next) => {
  const isSignInorSignUp = to.path === '/signin' || to.path === '/signup';

  if (to.query.alexa && $store.getters.loggedIn) {
    const userService = new UserService();
    userService.logout();
  }

  // // Do not allow navigation to other pages besides signin or signup when directed from alexa app
  if (from.query.alexa && (to.path !== '/signin' && to.path !== '/signup')) {
    return;
  }
  if (to.path.includes('/account')) {
    // This event needs to fire as long as we're using PreloadAdyenBuyPremium component
    window.eventBus.$emit('preload-payment-form', false);
  }
  if (isSignInorSignUp && !hasQueryParams(to) && hasQueryParams(from)) {
    next({ path: to.path, query: from.query });
  }
  next();
});

export default router;
