<template>
  <div
    ref="library-episodes"
    class="library-episodes"
    data-test="library-episodes">
    <div
      v-if="isLoggedIn">
      <loader
        v-show="isLoadingInitial"
        center
        label="Fetching Episodes ..."/>
      <div class="container">
        <el-row
          type="flex"
          justify="space-between"
          align="middle"
          class="top-bar">
          <p
            class="theme-primary-header"
            data-test="count-episode">{{ totalItems }} Episode{{ totalItems > 1 || !totalItems ? 's' : '' }}</p>
          <div
            v-if="formattedItems.length"
            class="filter-select">
            <el-dropdown
              trigger="click"
              @command="onSortSelect"
            >
              <span class="el-dropdown-link">
                {{ filterName }}
                <i class="el-icon-arrow-down el-icon--right" />
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item
                  v-for="elem in selectFilters"
                  :key="elem.val"
                  :command="elem"
                >
                  {{ elem.name }}
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </el-row>
      </div>
      <!-- Empty Shows Box -->
      <empty-shows
        v-show="!formattedItems.length && isLoaded"
        option="playlist"
      />
      <!-- Display Episodes-->
      <div
        v-if="formattedItems.length"
        class="draggable-container">
        <draggable
          v-model="formattedItemsSorted"
          :options="draggableOptions"
          tag="ul"
          data-test="list-episodes"
          class="list-episodes"
          handle=".drag-button"
          @end="onDragEnd($event)"
        >
          <li
            v-for="(item, index) in formattedItemsSorted"
            :key="item.uuid"
            :index="index"
            data-test="episode"
            class="draggable-episode">
            <card-list
              :index="index"
              :shows="item"
              :podcast="item.season.podcast"
              :toggle-details="item.show_details"
              :draggable-icon="sorting.isDnDAvailable"
              :is-exclusive="item.season && item.season.podcast && item.season.podcast.is_exclusive"
              :is-free="(item.season && item.season.podcast && item.season.podcast.is_exclusive) && !item.is_exclusive"
              :season_no="item.season && item.season.number"
              :episode_no="item.episode_number"
              :bucket="bucket"
              playlist-type="my-episodes"
              @deleted="deleteEpisode($event,index)"
              @details="resizePlaceholder"
            />
          </li>
        </draggable>
        <loader
          v-show="isLoadingMore"
          :with-margin="true"/>
      </div>
    </div>
  </div>
</template>
<script>
import * as log from 'loglevel';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import draggable from 'vuedraggable';
import kebabCase from 'lodash/kebabCase';
import Episode from '../../services/Episode';
import User from '../../services/User';
import SegmentEvent from '../../services/PlayerEvent';
import ScrollMixin from '../../shared/scrollMixin';
import PageMixin from '../../shared/pageMixin';

export default {
  name: 'Shows',
  components: {
    draggable,
    'card-list': () => import('../../components/CardList'),
    'empty-shows': () => import('../../components/EmptyShows'),
    loader: () => import('../../components/Loader'),
  },
  mixins: [ScrollMixin, PageMixin],
  data() {
    const data = {
      pageName: 'My Playlist',
      episode_service: new Episode(),
      user_service: new User(),
      eventProvider: new SegmentEvent(),
      episodes: [],
      scroll_threshold: 200, // this is percentage
      page: 0,
      show_infinite_scroll: false,
      no_more_episodes: false,
      episodes_loaded: false,
      filter_value: null,
      filterName: 'Sort By',
      show_placeholder: false,
      show_details: false,
      limit: 25,
      totalEpisodes: 0,
      selectFilters: [
        {
          name: 'Position',
          val: 1,
          sortCB: (a, b) => (a.position - b.position),
          isDnDAvailable: true,
        },
        {
          name: 'Newest',
          val: 2,
          sortCB: (a, b) => (new Date(b.released_at) - new Date(a.released_at)),
          isDnDAvailable: false,
        },
        {
          name: 'Oldest',
          val: 3,
          sortCB: (a, b) => (new Date(a.released_at) - new Date(b.released_at)),
          isDnDAvailable: false,
        },
        {
          name: 'Shortest',
          val: 4,
          sortCB: (a, b) => (a.runtime - b.runtime),
          isDnDAvailable: false,
        },
        {
          name: 'Longest',
          val: 5,
          sortCB: (a, b) => (b.runtime - a.runtime),
          isDnDAvailable: false,
        },
      ],
      selected_filter_value: -1,
      bucket: {},
      draggableOptions: {
        animation: 300,
        ghostClass: 'ghost',
        chosenClass: 'chosen',
        dragClass: 'drag',
        delay: 0,
        scrollSensitivity: 100,
        scrollSpeed: 10,
        scroll: true,
        direction: 'horizontal',
        forceFallback: true,
      },
    };
    // eslint-disable-next-line prefer-destructuring
    data.sorting = data.selectFilters[0];
    return data;
  },
  computed: {
    ...mapGetters({
      isLoggedIn: 'loggedIn',
      isLoaded: 'libraryv2/isLibraryEpisodesLoadingDone',
      isLoading: 'libraryv2/isLibraryEpisodesLoading',
      allItems: 'libraryv2/libraryAllEpisodeList',
      pager: 'libraryv2/libraryEpisodePager',
      totalItems: 'libraryv2/totalEpisodes',
    }),
    formattedItems() {
      return this.allItems.map((v, i) => ({
        uuid: v.id,
        title: v.title,
        description: (v.description || '').replace(/<.*?>/g, ' '),
        display_image_url: v.images.default,
        media_url: v.mediaUrl,
        season: {
          number: v.season,
          podcast: {
            uuid: v.showId,
            title: v.showTitle,
            is_exclusive: v.isShowExclusive,
            slug: kebabCase(v.showTitle),
            publisher: {
              name: v.publisherName,
              slug: kebabCase(v.publisherName),
            },
          },
        },
        episode_number: v.number,
        is_exclusive: v.isExclusive,
        released_at: v.releasedAt * 1000,
        runtime: v.runtime,
        episode_type: v.type,
        slug: kebabCase(v.title),
        position: i,
      }));
    },
    formattedItemsSorted: {
      get() {
        return [...this.formattedItems].sort(this.sorting.sortCB);
      },
      set() {},
    },
    isLoadingInitial() {
      return this.isLoading && !this.allItems.length;
    },
    isLoadingMore() {
      return this.isLoading && this.allItems.length;
    },
    isLastPage() {
      return this.pager && this.pager.currentPage === this.pager.totalPages;
    },
  },
  beforeMount() {
    this.parentSelector = 'library-episodes';
    this.childSelector = '.library-episodes .show-card-list.is-visible';
  },
  mounted() {
    if (!this.isLoggedIn) return;
    this.resetLibraryState();
    this.loadItems().then(() => {
      this.infiniteScrollPlaylist();
    });
    // this.infiniteScrollPlaylist();
    this.bucket = {
      bucket_uuid: null,
      name: 'Episodes',
      bucket_type: 'episodes',
      position: 0,
      items_count: this.allItems.length,
      media_size: 'episode_details',
    };
  },
  beforeDestroy() {
    this.removeScrollEvent();
  },
  methods: {
    ...mapActions({
      loadItems: 'libraryv2/loadNextLibraryEpisodes',
      updateEpisodePosition: 'libraryv2/updateEpisodePosition',
      removeFromAllEpisodesList: 'libraryv2/removeFromAllEpisodesList',
    }),
    ...mapMutations({
      resetLibraryState: 'libraryv2/resetLibraryState',
    }),
    onSortSelect(item) {
      this.sorting = item;
    },
    async onDragEnd(event) {
      if (event.newIndex === event.oldIndex) return;
      try {
        const item = await this.updateEpisodePosition({ oldPosition: event.oldIndex, newPosition: event.newIndex });
        this.eventProvider.triggerEpisodeReorderEvent(item.id, event.newIndex, event.oldIndex);
      } catch (e) {
        log.warn(e);
      }
    },
    infiniteScrollPlaylist() {
      document.addEventListener('scroll', this.infiniteScrollPlaylistCallback);
    },
    infiniteScrollPlaylistCallback() {
      const scrollTop = this.isSafariMobile ? document.body.scrollTop : document.documentElement.scrollTop;
      const heightLimit = scrollTop + window.innerHeight + this.scroll_threshold >= document.documentElement.scrollHeight;
      if (heightLimit && !this.isLoading && !this.isLastPage) {
        this.loadItems();
      }
    },
    resizePlaceholder() {
      // what is this?
      log.info('resize placeholder');
    },
    deleteEpisode(id) {
      this.removeFromAllEpisodesList(id);
    },
    removeScrollEvent() {
      const { body } = document;
      body.style = {};
      document.removeEventListener('scroll', this.infiniteScrollPlaylistCallback);
    },
  },
};
</script>
<style lang="scss">
@import '../../assets/scss/variables.scss';
.el-select-dropdown__item {
  height: 30px !important;
  line-height: 30px !important;
}
.library-episodes {
  min-height: calc(100vh - 347px);
  .top-bar {
    margin: 35px 0px;
    >p {
      font-size: 36px;
      line-height: 40px;
      font-weight: bold;
      color: $header-color;
    }
  }
  .filter-select {
    .el-select {
      width: 130px;
      cursor: pointer;
      .el-input {
        .el-input__inner {
          border-radius: 20px !important;
          color: $black !important;
          border: 1px solid $black !important;
          font-weight: 500 !important;
          padding: 5px 15px 5px 30px;
          font-size: 14px !important;
          line-height: 20px !important;
          height: 32px !important;
        }
        .el-input__inner::placeholder {
          overflow: visible;
          color: $black !important;
        }
        .el-input__suffix {
          i {
            font-size: 10px;
            color: $black !important;
            line-height: 0 !important;
          }
        }
      }
    }
    .el-select::before {
      content: "";
      position: absolute;
      display: block;
      top: 11px;
      left: 11px;
      background-size: contain;
      z-index: 1;
      width: 15px;
      height: 15px;
      background: url(../../assets/images/ic_sort_icon.svg) no-repeat;
    }
    .el-dropdown {
      cursor: pointer;
      display: flex;
      flex-direction: column;
      height: 32px;
      .el-dropdown-link {
        border-radius: 20px !important;
        color: #fff !important;
        border: 1px solid #fff !important;
        font-weight: 500;
        padding: 8px 10px 6px 30px;
        font-size: 14px !important;
        height: 32px;
        line-height: 4px;
      }
    }
    .el-dropdown::before {
      content: "";
      position: absolute;
      z-index: 1;
      background: url(../../assets/images/ic_sort_icon.svg) no-repeat;
      width: 13px;
      height: 13px;
      left: 12px;
      top: 12px;
      background-size: contain;
    }
  }
  .draggable-container {
    position: relative;

    .list-episodes {
      padding-left: 0;
      // height: 60vh;
      overflow-x: hidden;
      overflow-x: hidden;

      @media (max-height: 800px) {
        // height: 55vh;
      }

      @media (max-height: 750px) {
        // height: 50vh;
      }
    }

    .placeholder-container {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      z-index: -3;
      .single-placeholder {
        background-color: $xxlight-gray;
        display: flex;
        justify-content: center;
        align-items: center;
        .placeholder {
          background-color: #fff;
          border: 2px solid $xlight-gray;
          border-radius: 4px;
          padding: 0.75rem;
          width: 90%;
          p {
            text-align: center;
            color: $light-gray;
            font-size: 1rem;
          }
        }
      }
    }

    .draggable-episode {
      list-style-type: none;

      &.ghost {
        height: 155px;
        box-sizing: border-box;
        .show-card-list {
          .draggable-element {
            display: none;
            visibility: hidden;
            height: 0;
          }
        }
      }

      &.chosen {
        padding: 30px;
        .show-card-list {
          display: inline-block;
          background: white;
          visibility: visible;
          padding: 0px;
          .draggable-element {
            height: 80px;
            visibility: visible;
          }
        }
        .main-content {
          display: none;
        }
      }

      &.sortable-fallback {
        opacity: 1;
        .show-card-list {
          display: inline-block;
          background: white;
          padding: 0px;
          opacity: 1;
          border-radius: 20px;
        }
      }
    }
  }
}

</style>
