<template>
  <div class="accordion">
    <AccordionSection
      v-for="(section, index) in accordionSections"
      :key="section.title"
      :index="index"
      :section="section" />
  </div>
</template>

<script>
import AccordionSection from './AccordionSection';

let dropin = null;

export default {
  name: 'PurchaseGiftAccordion',
  components: {
    AccordionSection,
  },
  data() {
    return {};
  },
  computed: {
    accordionSections() {
      return this.$store.getters.accordionSections;
    },
  },
  provide() {
    return {
      setDropin: this.setDropin,
      getDropin: this.getDropin,
    };
  },
  mounted() {
    // check if dropin initialized
    if (!this.getDropin() && this.$store.getters.activeAccordionSection > 2) {
      // switch to adyen dropin page if not
      this.$store.commit('setActiveAccordionSection', 2);
    }
  },
  methods: {
    setDropin(val) {
      dropin = val;
    },
    getDropin() {
      return dropin;
    },
  },
};
</script>


<style lang="scss" scoped>
@import '../../../assets/scss/variables.scss';

.accordion {
  width: 100%;
  margin-bottom: 58px;
}

</style>
