export default (_el, binding) => {
  const el = _el;
  // get current screen before click handler (sometimes it will return the next screen within onClick)
  // TO DO: Why is window.Vue sometimes undefined when the directive loads? for example "learn more" in the installSkill component
  const currentScreen = window.Vue ? window.Vue.$route.name : '';

  let buttonTxt = el.textContent || el.innerText;

  // check if button text value is manually set
  if (binding.value && binding.value.buttonText) {
    buttonTxt = binding.value.buttonText;
  }

  let custButtonName = buttonTxt; // default is the same as button text
  // check if button name value is manually set
  if (binding.value && binding.value.buttonName) {
    custButtonName = binding.value.buttonName;
  }

  el.onclick = () => {
    const data = {
      screen_name: currentScreen,
      button_text: buttonTxt,
      button_name: custButtonName,
    };

    if (binding.value != null) {
      switch (binding.value.contentType) {
        case 'podcast':
        case 'podcast-feedback':
        case 'podcast-feedback-submit':
        case 'podcast-share': {
          const podcast = binding.value.contentData;
          if (podcast && podcast.uuid) {
            data.podcast_uuid = podcast.uuid;
            data.podcast_title = podcast.title || '';
          }
          break;
        }
        case 'episode':
        case 'episode-share': {
          const episode = binding.value.contentData;
          if (episode && episode.uuid) {
            const podcast = episode.season && episode.season.podcast ? episode.season.podcast : null;
            data.episode_uuid = episode.uuid;
            data.episode_title = episode.title || '';

            if (podcast && podcast.uuid) {
              data.podcast_uuid = podcast.uuid;
              data.podcast_title = podcast.title || '';
            }
          }
          break;
        }
        default:
          break;
      }
    }
    window.$analytics.track('Button Click', data);
  };
};
