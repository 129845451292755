<template>
  <div class="animation-container">
    <div class="small-tiles tile-group">
      <img
        v-for="(tileConfig, idx) in smallTiles"
        :style="tileConfig.styles"
        :src="tileConfig.imgSrc"
        :key="idx"
        class="tile large-tile"
      >
    </div>
    <div class="medium-tiles tile-group">
      <img
        v-for="(tileConfig, idx) in mediumTiles"
        :style="tileConfig.styles"
        :src="tileConfig.imgSrc"
        :key="idx"
        class="tile large-tile"
      >
    </div>
    <div class="large-tiles tile-group">
      <img
        v-for="(tileConfig, idx) in largeTiles"
        :style="tileConfig.styles"
        :src="tileConfig.imgSrc"
        :key="idx"
        class="tile large-tile"
      >
    </div>
  </div>
</template>

<script>
import images from '../assets/images/tile/animation.json';

const isEven = n => n % 2 === 0;

export default {
  name: 'TileAnimation',
  data() {
    return {
      largeTiles: [],
      mediumTiles: [],
      smallTiles: [],
    };
  },
  mounted() {
    this.largeTiles = this.generateTiles(images.slice(0, 10), {
      width: '200px',
      paddingTop: 50,
      bigMargin: '35%',
      smallMargin: '5%',
    });
    this.mediumTiles = this.generateTiles(
      images.slice(10, 20),
      {
        width: '120px',
        paddingTop: 220,
        bigMargin: '45%',
        smallMargin: '10%',
      },
      false,
    );
    this.smallTiles = this.generateTiles(
      images.slice(20, 30),
      {
        width: '80px',
        paddingTop: 100,
        bigMargin: '35%',
        smallMargin: '5%',
      },
      true,
    );
  },
  methods: {
    generateTiles(imageUrls, styles, toggle) {
      const {
        width, paddingTop, bigMargin, smallMargin,
      } = styles;

      const imgElements = imageUrls.map((imgUrl, idx) => {
        const padding = (isEven(idx) ? paddingTop * 0.75 : paddingTop * 0.25) + paddingTop;
        const marginToggle = toggle ? isEven(idx) : !isEven(idx);

        return {
          imgSrc: imgUrl,
          styles: {
            marginLeft: marginToggle ? bigMargin : smallMargin,
            width,
            paddingTop: `${padding}px`,
          },
        };
      });
      return imgElements;
    },
  },
};
</script>


<style lang="scss">
@import '../assets/scss/variables.scss';
.animation-container {
  min-width: 400px;
  display: block;
  position: relative;
  height: 100%;
  overflow: hidden;
    @media(min-width: $sm) {
    width: 300px;
  }
  @media(min-width: $xl) {
    width: 1000px;
  }
}
.large-tiles {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  animation-duration: 22s;
  animation-name: floatUpLarge;
  animation-duration: 21s;
}
.medium-tiles {
  position: absolute;
  top: 0;
  left: 0;
  width: 80%;
  margin-left: 10%;
  animation-duration: 30s !important;
  animation-delay: 0.5s;
  animation-name: floatUpMedium;
}
.small-tiles {
  position: absolute;
  top: 0;
  left: 0;
  width: 90%;
  margin-left: 5%;
  animation-duration: 40s !important;
  animation-delay: 0.7s;
  opacity: 0.7;
  animation-name: floatUpSmall;
}
.tile {
  display: block;
}
.tile-group {
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes floatUpLarge {
  0% {
    transform: translateY(5vh);
    opacity: 0;
  }
  10% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    transform: translateY(-100vh);
    opacity: 0;
  }
}
@keyframes floatUpMedium {
  0% {
    transform: translateY(5vh);
    opacity: 0;
  }
  10% {
    opacity: 75;
  }
  90% {
    opacity: 0.75;
  }
  100% {
    transform: translateY(-100vh);
    opacity: 0;
  }
}
@keyframes floatUpSmall {
  0% {
    transform: translateY(5vh);
    opacity: 0;
  }
  10% {
    opacity: 0.4;
  }
  90% {
    opacity: 0.4;
  }
  100% {
    transform: translateY(-100vh);
    opacity: 0;
  }
}
</style>
