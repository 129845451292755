<template>
  <div class="bespoke-show">
    <section class="show-hero">
      <el-row>
        <grid-col :xs="24">
          <div class="bg-hero">
            <img
              :src="srcSet('src', heroImageSrcSetsMobile)"
              :srcset="srcSet('srcset', heroImageSrcSetsMobile)"
              class="bg-hero__mobile">
            <img
              :src="srcSet('src', heroImageSrcSetsDesktop)"
              :srcset="srcSet('srcset', heroImageSrcSetsDesktop)"
              class="bg-hero__desktop">
          </div>
        </grid-col>
      </el-row>
      <el-row>
        <grid-col :xs="24">
          <div class="hero-content">
            <p>Talking movies with people who love them and people who make them.</p>
          </div>
          <div class="hero-btns">
            <img
              :src="greenSliceImage"
              class="green-slice" >
            <div
              class="play-trailer-btn"
              @click="playTrailer">
              <img
                v-if="isPlayingTrailer"
                src="../../assets/images/ic_pause_black_icon.svg"
                alt="Pause"
                class="pause-btn">
              <img
                v-else
                :class="{'play-btn': true, 'pulse': isLoadingTrailer }"
                src="../../assets/images/bespoke-play-btn-blk.svg"
                alt="Play">
              <i
                v-if="false"
                class="el-icon-loading text-blue"/>
              <span>{{ playBtnText }}</span>
            </div>
            <div class="cta-btn">
              <button
                ref="cta"
                @click="() => handleCTAClick(stickyTs ? 'sticky' : 'hero')">
                {{ heroCTAText }}
              </button>
            </div>
          </div>
        </grid-col>
      </el-row>
    </section>
    <section class="show-feature">
      <el-row>
        <grid-col
          :xs="24"
          :sm="24"
          :md="24"
          :lg="12">
          <div class="feature-content">
            <p>Since founding the Black List in 2005, Franklin Leonard has helped bring more than 250 previously-passed-on scripts to the big screen.</p>
            <p>Now he’s joining forces with Black List Director of Community Kate Hagen to celebrate movie makers and movie lovers alike, hosting table reads and revisiting highly regarded films from the Black List.</p>
          </div>
        </grid-col>
        <grid-col
          :xs="24"
          :sm="24"
          :md="24"
          :lg="12">
          <div class="feature-images">
            <img
              :src="srcSet('src', featureImage1SrcSets)"
              :srcset="srcSet('srcset', featureImage1SrcSets)">
            <img
              :src="srcSet('src', featureImage2SrcSets)"
              :srcset="srcSet('srcset', featureImage2SrcSets)">
          </div>
        </grid-col>
      </el-row>
      <img
        :src="blueSliceImage"
        class="blue-slice" >
    </section>
  </div>
</template>

<script>
import * as log from 'loglevel';
import throttle from 'lodash/throttle';
import Podcast from '../../services/Podcast';
import PageMixin from '../../shared/pageMixin';
import Event from '../../services/PlayerEvent';
import heroImageDesktop1x from '../../assets/images/theblacklist/hero@1x.png';
import heroImageDesktop2x from '../../assets/images/theblacklist/hero@2x.png';
import heroImageMobile1x from '../../assets/images/theblacklist/heroMobile@1x.png';
import heroImageMobile2x from '../../assets/images/theblacklist/heroMobile@2x.png';
import featuredImage1_1x from '../../assets/images/theblacklist/feature1@1x.jpg';
import featuredImage1_2x from '../../assets/images/theblacklist/feature1@2x.jpg';
import featuredImage2_1x from '../../assets/images/theblacklist/feature2@1x.jpg';
import featuredImage2_2x from '../../assets/images/theblacklist/feature2@2x.jpg';
import greenSliceImage from '../../assets/images/theblacklist/green-slice.png';
import blueSliceImage from '../../assets/images/theblacklist/blue-slice.png';
import blueSliceImageMobile from '../../assets/images/theblacklist/blue-slice-mobile.png';

export default {
  name: 'TheBlackListLandingPage',
  mixins: [PageMixin],
  data() {
    return {
      season_trailers: [],
      stickyCTA: false,
      stickyTs: null,
      podcast_service: new Podcast(),
      eventProvider: new Event(),
      display_image_url: 'https://img1.luminarypodcasts.com/v1/podcast/09891360-3749-4049-837c-c10f67b1fa05/thumbnail/h_360,w_360/image/s--uTKemCYm--/aHR0cHM6Ly9pbWFnZXMubWVnYXBob25lLmZtL1ZhMW5oVFhZcExjOGxzcjVnUTh4b2pIR0xiSXY0ZG5XWENPXzVWR0ZYdncvcGxhaW4vczM6Ly9tZWdhcGhvbmUtcHJvZC9wb2RjYXN0cy9hYzYwYmVkMC01OTdkLTExZWEtOGY2YS03Mzg4YTY4Y2FlZmIvaW1hZ2UvdXBsb2Fkc18yRjE1ODM1MjUwMTU4MTgtNW9mZXZlNWJ3dW4tNjQzNzdhYzUxOGM1M2VhY2YyYTg0NGQ3NmI0ODk3MWFfMkZUaGVCbGFja0xpc3Rfc2hvd3RpbGVfMzAwMHgzMDAwLmpwZw==.jpg?dpr=2 2x, https://img1.luminarypodcasts.com/v1/podcast/09891360-3749-4049-837c-c10f67b1fa05/thumbnail/h_360,w_360/image/s--uTKemCYm--/aHR0cHM6Ly9pbWFnZXMubWVnYXBob25lLmZtL1ZhMW5oVFhZcExjOGxzcjVnUTh4b2pIR0xiSXY0ZG5XWENPXzVWR0ZYdncvcGxhaW4vczM6Ly9tZWdhcGhvbmUtcHJvZC9wb2RjYXN0cy9hYzYwYmVkMC01OTdkLTExZWEtOGY2YS03Mzg4YTY4Y2FlZmIvaW1hZ2UvdXBsb2Fkc18yRjE1ODM1MjUwMTU4MTgtNW9mZXZlNWJ3dW4tNjQzNzdhYzUxOGM1M2VhY2YyYTg0NGQ3NmI0ODk3MWFfMkZUaGVCbGFja0xpc3Rfc2hvd3RpbGVfMzAwMHgzMDAwLmpwZw==.jpg',
      showUUID: '09891360-3749-4049-837c-c10f67b1fa05',
      heroCTAText: 'START FREE TRIAL',
      windowWidth: null,
      windowHeight: null,
      heroImageSrcSetsDesktop: [
        heroImageDesktop1x,
        heroImageDesktop2x,
      ],
      heroImageSrcSetsMobile: [
        heroImageMobile1x,
        heroImageMobile2x,
      ],
      featureImage1SrcSets: [
        featuredImage1_1x,
        featuredImage1_2x,
      ],
      featureImage2SrcSets: [
        featuredImage2_1x,
        featuredImage2_2x,
      ],
      greenSliceImage,
      blueSliceImage,
      blueSliceImageMobile,
      isPlaying: false,
    };
  },
  computed: {
    isXsViewport() {
      return window.innerWidth <= 576;
    },
    playBtnText() {
      return `${this.isPlaying ? 'Pause' : 'Play'} Trailer`;
    },
    isLoading() {
      return false;
    },
    currentlyPlaying() {
      return this.$store.getters.episode;
    },
    isLoadingTrailer() {
      const { status } = this.$store.getters;

      return this.season_trailers.length && status === 'loading' && this.currentlyPlaying && (this.currentlyPlaying.uuid === this.season_trailers[0].uuid);
    },
    isPlayingTrailer() {
      const { play } = this.$store.getters;
      if (!this.season_trailers) {
        return false;
      }
      return this.season_trailers.length && play && this.currentlyPlaying && (this.currentlyPlaying.uuid === this.season_trailers[0].uuid);
    },
  },
  watch: {
    /* eslint-disable */
    windowHeight: function (newHeight) {
      this.adjustStickyCta();
    },
    /* eslint-enable */
  },
  beforeMount() {
    this.getSeasonTrailers();
  },
  mounted() {
    this.setMetaInformation('The Black List Podcast', 'The Black List founder Franklin Leonard talks movies with people who love them and people who make them. Listen as he and Black List Director of Community Kate Hagen speak with filmmakers, producers, and creators of all kinds, hosting table reads and revisiting highly regarded films from the Black List itself.');

    if (!this.$store.getters.hidePlayer) {
      this.togglePlayerVisiblity(true);
    }
    this.windowWidth = window.innerWidth;
    this.windowHeight = window.innerHeight;
    window.addEventListener('resize', () => {
      this.windowWidth = window.innerWidth;
      this.windowHeight = window.innerHeight;
    });
    window.addEventListener('scroll', () => this.handleScroll());
    window.addEventListener('scroll', throttle(this.checkVisibleSections, 1000));
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.togglePlayerVisiblity(true);
      vm.addNoIndexMeta();
    });
  },
  beforeRouteLeave(to, from, next) {
    this.togglePlayerVisiblity(false);
    this.removeNoIndexMeta();
    next();
  },
  destroyed() {
    this.setMetaInformation();
  },
  methods: {
    handleCTAClick(btnId) {
      const { isFreeUser, isPremiumUser } = this.promoMeta;
      const routeTo = { path: '/signup' };
      if (isFreeUser) {
        routeTo.path = '/plans';
      } else if (isPremiumUser) {
        routeTo.path = '/listen/trevor-noah/on-second-thought-the-trevor-noah-podcast-luminary-exclusive/13a59e33-974a-4ccd-a3a2-e17f8ac638f8';
      }
      this.$analytics.track('Bespoke CTA Click', {
        screen_name: this.$route.name,
        path: this.$route.path,
        btnId,
      });
      this.$router.push(routeTo);
    },
    adjustStickyCta() {
      if (this.$refs.cta && this.stickyTs && this.isXsViewport) {
        this.$refs.cta.style.top = `${window.innerHeight - 110}px`;
      }
    },
    togglePlayerVisiblity(bool) {
      this.$store.dispatch('toggleHidePlayer', bool);
    },
    checkVisibleSections() {
      if (!this.viewTracker) {
        this.viewTracker = [...document.getElementsByTagName('section')]
          .map(section => section.className)
          .reduce((tracker, sectionName) => {
            tracker[sectionName] = false;
            return tracker;
          }, {});
      }
      const ctx = this;
      Object.entries(this.viewTracker)
        .forEach(([secName, hasViewed]) => {
          if (hasViewed) {
            return;
          }
          const e = document.getElementsByClassName(secName) && document.getElementsByClassName(secName)[0];
          const isVisible = (e && e.getBoundingClientRect() && e.getBoundingClientRect().y < window.innerHeight) || false;
          if (isVisible) {
            ctx.viewTracker[secName] = true;
            ctx.$analytics.track('Scrolled Into View', {
              screen_name: ctx.$route.name,
              path: ctx.$route.path,
              section: secName,
            });
          }
        });
    },
    handleScroll() {
      if (!this.isXsViewport) {
        return;
      }
      const ctaYPos = (this.$refs.cta && this.$refs.cta.getBoundingClientRect().y) || 1000;
      const ctaXPos = (this.$refs.cta && this.$refs.cta.getBoundingClientRect().x) || 1000;
      const viewPortThreshold = window.innerHeight - 110;
      const isSticky = ctaYPos < viewPortThreshold;
      const refParentDivYPos = (this.$refs.cta && this.$refs.cta.parentElement && this.$refs.cta.parentElement.getBoundingClientRect().y) || 1000;
      if (isSticky && !this.stickyTs) {
        this.$refs.cta.style.top = `${window.innerHeight - 110}px`;
        this.$refs.cta.style.left = `${ctaXPos}px`;
        this.$refs.cta.style.position = 'fixed';
        this.stickyTs = Date.now();
      }
      if (refParentDivYPos > viewPortThreshold && this.stickyTs && (Date.now() - this.stickyTs > 1000)) {
        this.$refs.cta.style.top = 'unset';
        this.$refs.cta.style.left = 'unset';
        this.$refs.cta.style.position = 'relative';
        this.stickyTs = null;
      }
    },
    next() {
      this.$refs.flickity.next();
    },
    previous() {
      this.$refs.flickity.previous();
    },
    playPauseTrailer() {
      this.isPlaying = !this.isPlaying;
    },
    playTrailer() {
      const trailer = this.season_trailers[0];
      trailer.episode_type = 'trailer';
      trailer.display_image_url = this.display_image_url;
      trailer.playedFrom = 'bespoke_sdp';
      this.togglePlayerVisiblity(true);
      if (this.currentlyPlaying.uuid === trailer.uuid && this.currentlyPlaying.playedFrom === trailer.playedFrom) {
        const { play } = this.$store.getters;

        if (!play) {
          if (this.currentlyPlaying.episode_type !== 'trailer') {
            this.eventProvider.triggerEpisodePauseEvent(this.currentlyPlaying, 'trailer_select');
          }
          this.$store.dispatch('play');
          this.eventProvider.triggerTrailerPlayEvent(trailer, 'sdp_select');
        } else {
          this.$store.dispatch('pause');
          this.eventProvider.triggerTrailerPauseEvent(trailer, 'sdp_pause');
        }
      } else {
        this.$store.dispatch('setTrailerAsEpisode', false);

        if (this.currentlyPlaying.episode_type !== 'trailer') {
          this.eventProvider.triggerEpisodePauseEvent(this.currentlyPlaying, 'trailer_select');
        }
        this.$store.dispatch('setTrailerExperienceTriggered', false); // Make sure trailer experience does not trigger from here.
        this.loadMediaInPlayer(trailer.media_url, trailer.is_exclusive, trailer.episode_type, true);
        this.$store.dispatch('setNextEpisode', {}); // Empty next episode because playback should stop after trailer complete from SDP.
        this.$store.dispatch('setAndPlayEpisode', trailer);
        this.eventProvider.triggerTrailerPlayEvent(trailer, 'sdp_select');
      }
    },
    getSeasonTrailers() {
      this.podcast_service
        .getPodcastTrailers(this.showUUID)
        .then((res) => {
          this.season_trailers = res.trailers || [];
          this.season_trailers = this.season_trailers.sort((a, b) => new Date(b.released_at) - new Date(a.released_at));
        })
        .catch((error) => {
          log.error(error);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@import './BespokeTheBlackList.scss';
</style>
