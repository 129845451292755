<template>
  <div
    ref="episodeList"
    class="container episode-list">
    <el-row>
      <grid-col
        :xs="{ span: 24, offset: 0 }"
        :sm="{ span: 24, offset: 0 }"
        :md="{ span: 22, offset: 1 }"
        :lg="{ span: 16, offset: 4 }"
        :xl="{ span: 16, offset: 4 }">
        <h2 class="episode-list-title">Browse More {{ contentName }}</h2>
        <div class="episode-list-header">
          <p class="episode-total">{{ episode_count }} {{ contentName }}</p>
          <el-dropdown
            trigger="click">
            <span class="episode-sort">
              {{ sort_name }}
              <i class="el-icon-arrow-down el-icon--right" />
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item
                v-for="elem in sort_options"
                :key="elem.val"
                @click.native="onSelect(elem.val)">
                {{ elem.name }}
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
        <div
          v-for="(episode, index) in sorted_episodes"
          :key="index">
          <EpisodeListCardHorizontal
            :episode="episode"
            :podcast="podcast"
            :user-bookmarks="userBookmarks"
            :playlist-type="playlistType" />
        </div>
        <div class="episode-pagination">
          <el-pagination
            v-show="episode_count > page_size"
            v-if="is_pagination_mounted"
            :small="is_small_pagination"
            :page-size="page_size"
            :total="Number(episode_count)"
            :pager-count="pagination_count"
            :current-page="current_page"
            layout="prev, pager, next"
            @current-change="loadPaginatedEpisodes($event)"/>
        </div>
      </grid-col>
    </el-row>
  </div>
</template>

<script>
import * as log from 'loglevel';
import EpisodeListCardHorizontal from './EpisodeListCardHorizontal';
// services
import Podcast from '../../../services/Podcast';
import Event from '../../../services/PlayerEvent';

export default {
  components: {
    EpisodeListCardHorizontal,
  },
  props: {
    episodes: {
      type: Array,
      default() {
        return [];
      },
    },
    episodeCount: {
      type: String,
      default: '',
    },
    podcast: {
      type: Object,
      default() {
        return {};
      },
    },
    showData: { // legacy podcast object
      type: Object,
      default() {
        return {};
      },
    },
    userBookmarks: {
      type: Array,
      default() {
        return [];
      },
    },
    playlistType: {
      type: String,
      default: 'show',
    },
  },
  data() {
    return {
      podcast_service: new Podcast(),
      eventProvider: new Event(),
      episode_count: this.episodeCount,
      pagination_count: 7,
      is_small_pagination: false,
      is_pagination_mounted: false,
      sorted_episodes: this.episodes,
      sort_name: 'Newest',
      sort_value: 1,
      sort_order: 'release_desc',
      sort_options: [
        {
          name: 'Newest',
          val: 1,
          sortOrder: 'release_desc',
        },
        {
          name: 'Oldest',
          val: 2,
          sortOrder: 'release_asc',
        },
      ],
      default_page: 1,
      current_page: 1,
      page_size: 10,
    };
  },
  computed: {
    contentName() {
      return this.podcast && this.podcast.mediaType === 'music' ? 'Tracks' : 'Episodes';
    },
  },
  mounted() {
    if (window.innerWidth <= 567) {
      this.pagination_count = 4;
      this.is_small_pagination = true;
    }
    this.is_pagination_mounted = true;
  },
  methods: {
    onSelect(value) {
      if (value !== this.sort_value) {
        const sortSelected = this.sort_options.find(filter => filter.val === value);
        this.sort_order = sortSelected.sortOrder;
        this.sort_name = sortSelected.name;
        this.sort_value = sortSelected.val;
        this.current_page = this.default_page;
        this.eventProvider.triggerSDPSortEvent(this.showData, this.sort_name);
        this.getPodcastEpisodes();
      }
    },
    async getPodcastEpisodes() {
      const podcastId = this.podcast.id;
      const params = this.podcast_service.constructPodcastEpisodeParams(this.current_page, this.page_size, this.sort_order);
      try {
        const episodeResponse = await this.podcast_service.getPodcastEpisodesV2(podcastId, params);
        this.sorted_episodes = episodeResponse.episodes;
      } catch (err) {
        log.error(err);
      }
      this.loadingEpisodes = false;
    },
    loadPaginatedEpisodes(event) {
      this.current_page = event;
      this.getPodcastEpisodes();
      this.$refs.episodeList.scrollIntoView({
        behavior: 'smooth',
      });
    },
  },
};

</script>

<style lang="scss" scoped>
@import "../../../assets/scss/variables.scss";

.episode-list {
  margin-bottom: 40px;
}

.episode-list-title {
  font-family: $patron;
  color: $white;
  font-size: 22px;
  line-height: 22px;
  margin: 0 0 30px;
  text-align: center;
   @media(min-width: $sm) {
    font-size: 32px;
    line-height: 32px;
    margin: 0 0 50px;
  }
}

.episode-list-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 24px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

.episode-total {
  font-family: $patron;
  color: $white;
  margin-bottom: 0;
  font-size: 16px;
  line-height: 28px;
  @media(min-width: $sm) {
    font-size: 20px;
  }
}

.episode-sort {
  font-family: $patron;
  font-size: 16px;
  line-height: 28px;
  color: $blue;
  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
  @media(min-width: $sm) {
    font-size: 20px;
  }
}

.episode-pagination {
  display: flex;
  justify-content: center;
}

// this is some legacy styling that is very hard to override..
.episode-pagination /deep/ .el-pagination {
  margin: 40px 0 25px;
  background-color: $dark-background;
  .btn-prev .el-icon, .btn-next .el-icon{
    font-size: 24px;
    color: $light-gray2;
    background-color: $dark-background;
  }
  button.btn-prev {
    padding-right: 0px;
    background-color: inherit;
  }
  button.btn-next {
    padding-left: 0px;
    background-color: inherit;
  }
  .el-pager {
    background-color: inherit;
    padding: 0 6px !important;
    margin: 0 0 !important;
    @media (min-width: $sm) {
      margin: 0 40px;
      padding: 0 25px !important;
    }
  }
  .el-pager li {
    min-width: auto;
    padding-right: 22px;
    margin-right: 0 !important;
    &:last-child {
        padding-right: 0 !important;
      }
    @media(min-width: $sm) {
      margin-right: 32px !important;
      padding-right: 0;
    }
  }
  .el-pager li.number {
    font-size: 16px;
    color: #fff;
    min-width: 8px;
    background-color: $dark-background;
    &:hover {
      color: $blue;
      text-decoration: underline;
    }
  }
  .el-pager li.number:last-child {
    margin-right: 0px;
  }
  .el-pager li.number.active {
    font-size: 16px;
    color: $blue;
  }
  .el-pager li.el-icon {
    color: #fff;
    background-color: $dark-background;
  }
}
</style>
