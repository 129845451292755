import featuresConfig from '@/config/featuresConfig';

export const CANCELLATION_BRIGHTBACK_VARIANT = 1;

export const NEW_SIGNUP_VARIANT = 1;

// eslint-disable-next-line func-names, no-console
const gtag = window.gtag || function () { console.log('gtag is not defined'); };

export default class Optimize {
  registerSignUpExperimentCallback(cb) {
    // eslint-disable-next-line no-undef
    gtag('event', 'optimize.callback', {
      name: featuresConfig.variables.experiment.signup_flow,
      callback: cb,
    });
  }

  registerCancellationExperimentCallback(cb) {
    // eslint-disable-next-line no-undef
    gtag('event', 'optimize.callback', {
      name: featuresConfig.variables.experiment.cancellation_flow,
      callback: cb,
    });
  }

  triggerSignUpExperiment() {
    // eslint-disable-next-line no-undef
    dataLayer.push({ event: 'optimize.activate_signup' });
  }

  triggerCancellationExperiment() {
    // eslint-disable-next-line no-undef
    dataLayer.push({ event: 'optimize.activate_cancellation' });
  }

  triggerPrimaryObjective() {
    // eslint-disable-next-line no-undef
    gtag('event', 'Completed', {
      event_category: 'Signup',
    });
  }

  triggerCancellationPrimaryObjective() {
    // eslint-disable-next-line no-undef
    gtag('event', 'Retain', {
      event_category: 'Cancellation',
    });
  }

  triggerCancellationSecondaryObjective() {
    // eslint-disable-next-line no-undef
    gtag('event', 'Cancel', {
      event_category: 'Cancellation',
    });
  }

  triggerAnlyticsEvent({
    category,
    action,
    label,
    value,
    cb,
  }) {
    const data = {
      event_category: category,
      event_label: label,
      value,
    };
    if (cb) data.event_callback = cb;
    // eslint-disable-next-line no-undef
    gtag('event', action, data);
  }
}
