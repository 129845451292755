import Vue from 'vue';
import * as log from 'loglevel';
import Library from '@/services/Library';
import { getServices } from '@/services/serviceProvider';

const [libraryProvider] = getServices([Library]);

export const LIBRARYV2_STATUSES = {
  IDLE: 'IDLE',
  LOADING: 'LOADING',
  DONE: 'DONE',
  ERROR: 'ERROR',
};

const podcastConverter = state => p => ({
  ...p,
  lastViewedAt: state.lastViewedPodcasts[p.show.id],
});

const getDefaultState = () => ({
  status: {
    podcasts: LIBRARYV2_STATUSES.IDLE,
    podcastEpisodes: LIBRARYV2_STATUSES.IDLE,
    episodes: LIBRARYV2_STATUSES.IDLE,
    items: {},
  },
  error: {
    podcasts: '',
    podcastEpisodes: '',
    episodes: '',
  },
  podcasts: {},
  podcastEpisodes: {},
  episodes: {},
  podcastList: {
    pageItems: {},
    items: [],
    totalItems: 0,
    size: 12,
    from: 0,
  },
  podcastEpisodeList: {
    pageItems: {},
    items: [],
    totalItems: 0,
    size: 12,
    from: 0,
  },
  episodeList: {
    pageItems: {},
    items: [],
    totalItems: 0,
    size: 5,
    from: 0,
  },
  lastViewedPodcasts: {},
});

const getters = {
  isItemLoading: state => id => state.status.items[id] === LIBRARYV2_STATUSES.LOADING,
  isLibraryPodcastsLoading: state => state.status.podcasts === LIBRARYV2_STATUSES.LOADING,
  isLibraryEpisodesLoading: state => state.status.episodes === LIBRARYV2_STATUSES.LOADING,
  isPodcastEpisodesLoading: state => state.status.podcastEpisodes === LIBRARYV2_STATUSES.LOADING,
  isLibraryPodcastsLoadingDone: state => state.status.podcasts === LIBRARYV2_STATUSES.DONE,
  isLibraryEpisodesLoadingDone: state => state.status.episodes === LIBRARYV2_STATUSES.DONE,
  isPodcastEpisodesLoadingDone: state => state.status.podcastEpisodes === LIBRARYV2_STATUSES.DONE,
  libraryPodcasts: state => state.podcasts,
  libraryEpisodes: state => state.episodes,
  podcastEpisodes: state => state.podcastEpisodes,
  isEpisodeInLibrary: state => episodeUuid => state.episodes[episodeUuid],
  isPodcastInLibrary: state => podcastUuid => state.podcasts[podcastUuid],
  libraryPodcastList: state => state.podcastList.items.map(podcastConverter(state)),
  libraryAllEpisodeList: (state) => {
    const maxPage = Math.max(...Object.keys(state.episodeList.pageItems).map(v => Number(v)));
    let res = [];
    for (let i = 1; i <= maxPage; i++) {
      if (state.episodeList.pageItems[i]) res = res.concat(state.episodeList.pageItems[i]);
    }
    return res;
  },
  libraryAllPodcastList: (state) => {
    const maxPage = Math.max(...Object.keys(state.podcastList.pageItems).map(v => Number(v)));
    let res = [];
    for (let i = 1; i <= maxPage; i++) {
      if (state.podcastList.pageItems[i]) res = res.concat(state.podcastList.pageItems[i].map(podcastConverter(state)));
    }
    return res;
  },
  libraryEpisodeList: state => state.episodeList.items,
  podcastEpisodeList: state => state.podcastEpisodeList.items,
  libraryAllPodcastEpisodeList: (state) => {
    const maxPage = Math.max(...Object.keys(state.podcastEpisodeList.pageItems).map(v => Number(v)));
    let res = [];
    for (let i = 1; i <= maxPage; i++) {
      if (state.podcastEpisodeList.pageItems[i]) res = res.concat(state.podcastEpisodeList.pageItems[i]);
    }
    return res;
  },
  totalPodcasts: state => state.podcastList.totalItems,
  totalEpisodes: state => state.episodeList.totalItems,
  totalPodcastEpisodes: state => state.podcastEpisodeList.totalItems,
  libraryPodcastPager: state => ({
    currentPage: state.podcastList.totalItems ? (state.podcastList.from / state.podcastList.size) + 1 : 0,
    totalPages: state.podcastList.totalItems ? Math.ceil(state.podcastList.totalItems / state.podcastList.size) : 0,
  }),
  libraryEpisodePager: state => ({
    currentPage: state.episodeList.totalItems ? (state.episodeList.from / state.episodeList.size) + 1 : 0,
    totalPages: state.episodeList.totalItems ? Math.ceil(state.episodeList.totalItems / state.episodeList.size) : 0,
  }),
  podcastEpisodePager: state => ({
    currentPage: state.podcastEpisodeList.totalItems ? (state.podcastEpisodeList.from / state.podcastEpisodeList.size) + 1 : 0,
    totalPages: state.podcastEpisodeList.totalItems ? Math.ceil(state.podcastEpisodeList.totalItems / state.podcastEpisodeList.size) : 0,
  }),
};

const mutations = {
  setItemStatus: (state, { id, status }) => {
    state.status.items = { ...state.status.items, [id]: status };
  },
  setPodcastsStatus: (state, status) => {
    state.status.podcasts = status;
  },
  setEpisodesStatus: (state, status) => {
    state.status.episodes = status;
  },
  setPodcastEpisodesStatus: (state, status) => {
    state.status.podcastEpisodes = status;
  },
  setPodcastsError: (state, error) => {
    state.error.podcasts = error;
  },
  setEpisodesError: (state, error) => {
    state.error.episodes = error;
  },
  setPodcastEpisodesError: (state, error) => {
    state.error.podcastEpisodes = error;
  },
  setLibraryPodcastList: (state, libraryPodcasts) => {
    state.podcastList.items = libraryPodcasts;
  },
  setLibraryPodcastListTotal: (state, v) => {
    state.podcastList.totalItems = v;
  },
  setLibraryPodcastListFrom: (state, v) => {
    state.podcastList.from = v;
  },
  setLibraryPodcastListSize: (state, v) => {
    state.podcastList.size = v;
  },
  setLibraryEpisodeList: (state, libraryEpisodes) => {
    state.episodeList.items = libraryEpisodes;
  },
  setLibraryEpisodeListTotal: (state, v) => {
    state.episodeList.totalItems = v;
  },
  setLibraryEpisodeListFrom: (state, v) => {
    state.episodeList.from = v;
  },
  setLibraryEpisodeListSize: (state, v) => {
    state.episodeList.size = v;
  },
  setPodcastEpisodeList: (state, libraryEpisodes) => {
    state.podcastEpisodeList.items = libraryEpisodes;
  },
  setPodcastEpisodeListTotal: (state, v) => {
    state.podcastEpisodeList.totalItems = v;
  },
  setPodcastEpisodeListFrom: (state, v) => {
    state.podcastEpisodeList.from = v;
  },
  setPodcastEpisodeListSize: (state, v) => {
    state.podcastEpisodeList.size = v;
  },
  setLibraryPodcasts: (state, libraryPodcasts) => {
    state.podcasts = libraryPodcasts;
  },
  setLibraryEpisodes: (state, libraryEpisodes) => {
    state.episodes = libraryEpisodes;
  },
  setPodcastEpisodes: (state, val) => {
    state.podcastEpisodes = val;
  },
  setLibraryEpisode: (state, id) => {
    state.episodes = { ...state.episodes, [id]: true };
  },
  setLibraryPodcast: (state, id) => {
    state.podcasts = { ...state.podcasts, [id]: true };
  },
  removeLibraryEpisode: (state, libraryEpisodeUuid) => {
    state.episodes = { ...state.episodes, [libraryEpisodeUuid]: false };
  },
  removeLibraryPodcast: (state, libraryPodcastUuid) => {
    state.podcasts = { ...state.podcasts, [libraryPodcastUuid]: false };
  },
  resetLibraryState: (state, options = { dropLastViewed: false }) => {
    const defaultState = getDefaultState();
    if (!options.dropLastViewed) delete defaultState.lastViewedPodcasts;
    Object.assign(state, defaultState);
  },
  setLastViewedPodcast: (state, id) => {
    Vue.set(state.lastViewedPodcasts, id, new Date().toISOString());
  },
  setPagePodcasts: (state, { page, items }) => {
    Vue.set(state.podcastList.pageItems, page, items);
  },
  setPageEpisodes: (state, { page, items }) => {
    Vue.set(state.episodeList.pageItems, page, items);
  },
  setAllPagePodcasts: (state, itemDict) => {
    state.podcastList.pageItems = itemDict;
  },
  setAllPageEpisodes: (state, itemDict) => {
    state.episodeList.pageItems = itemDict;
  },
  setPagePodcastEpisodes: (state, { page, items }) => {
    Vue.set(state.podcastEpisodeList.pageItems, page, items);
  },
};

const state = getDefaultState();

const actions = {
  loadLibrary(context) {
    context.dispatch('loadUserLibraryPodcasts');
    context.dispatch('loadUserLibraryEpisodes');
  },
  loadLibraryEpisodes(context, page) {
    context.commit('setEpisodesError', '');
    context.commit('setEpisodesStatus', LIBRARYV2_STATUSES.LOADING);
    const from = context.state.episodeList.size * (page - 1);
    return libraryProvider.bookmarksList({
      from,
      size: context.state.episodeList.size,
    }).then((resp) => {
      const { ...libraryEpisodes } = context.state.episodes;
      resp.items.forEach((element) => {
        libraryEpisodes[element.id] = true;
      });
      context.commit('setLibraryEpisodes', libraryEpisodes);
      context.commit('setLibraryEpisodeList', resp.items);
      context.commit('setPageEpisodes', { page, items: resp.items });
      context.commit('setLibraryEpisodeListFrom', from);
      context.commit('setLibraryEpisodeListTotal', resp.totalItems);
      context.commit('setEpisodesStatus', LIBRARYV2_STATUSES.DONE);
    }).catch((error) => {
      context.commit('setEpisodesStatus', LIBRARYV2_STATUSES.ERROR);
      context.commit('setEpisodesError', error.message);
      log.info(error);
    });
  },
  loadNextLibraryEpisodes(context) {
    context.commit('setEpisodesError', '');
    context.commit('setEpisodesStatus', LIBRARYV2_STATUSES.LOADING);
    const [...allEpisodes] = context.getters.libraryAllEpisodeList;
    const loadedCount = allEpisodes.length;
    const requestSize = context.state.episodeList.size * Math.ceil(loadedCount / context.state.episodeList.size + 1) - loadedCount;
    return libraryProvider.bookmarksList({
      from: loadedCount,
      size: requestSize,
    }).then((resp) => {
      const { ...libraryEpisodes } = context.state.episodes;
      resp.items.forEach((element) => {
        libraryEpisodes[element.id] = true;
      });
      context.commit('setLibraryEpisodes', libraryEpisodes);
      context.commit('setLibraryEpisodeList', resp.items);
      const resultEpisodes = allEpisodes.concat(resp.items);
      const maxPage = Math.ceil(resultEpisodes.length / context.state.episodeList.size);
      const pageItems = {};
      for (let i = 1; i <= maxPage; i++) {
        pageItems[i] = resultEpisodes.slice((i - 1) * context.state.episodeList.size, i * context.state.episodeList.size);
      }
      context.commit('setAllPageEpisodes', pageItems);
      context.commit('setLibraryEpisodeListFrom', (maxPage - 1) * context.state.episodeList.size);
      context.commit('setLibraryEpisodeListTotal', resp.totalItems);
      context.commit('setEpisodesStatus', LIBRARYV2_STATUSES.DONE);
    }).catch((error) => {
      context.commit('setEpisodesStatus', LIBRARYV2_STATUSES.ERROR);
      context.commit('setEpisodesError', error.message);
      log.info(error);
    });
  },
  loadLibraryPodcasts(context, page) {
    context.commit('setPodcastsError', '');
    context.commit('setPodcastsStatus', LIBRARYV2_STATUSES.LOADING);
    const from = context.state.podcastList.size * (page - 1);
    return libraryProvider.showsList({
      from,
      size: context.state.podcastList.size,
    }).then((resp) => {
      const { ...dict } = context.state.podcasts;
      resp.items.forEach((element) => {
        dict[element.show.id] = true;
      });
      context.commit('setLibraryPodcasts', dict);
      context.commit('setLibraryPodcastList', resp.items);
      context.commit('setPagePodcasts', { page, items: resp.items });
      context.commit('setLibraryPodcastListFrom', from);
      context.commit('setLibraryPodcastListTotal', resp.total);
      context.commit('setPodcastsStatus', LIBRARYV2_STATUSES.DONE);
    }).catch((error) => {
      context.commit('setPodcastsStatus', LIBRARYV2_STATUSES.ERROR);
      context.commit('setPodcastsError', error.message);
      log.info(error);
    });
  },
  loadPodcastEpisodes(context, page) {
    context.commit('setPodcastEpisodesError', '');
    context.commit('setPodcastEpisodesStatus', LIBRARYV2_STATUSES.LOADING);
    const from = context.state.podcastEpisodeList.size * (page - 1);
    return libraryProvider.latestEpisodes({
      from,
      size: context.state.podcastEpisodeList.size,
    }).then((resp) => {
      context.commit('setPodcastEpisodeList', resp.items);
      context.commit('setPagePodcastEpisodes', { page, items: resp.items });
      context.commit('setPodcastEpisodeListFrom', from);
      context.commit('setPodcastEpisodeListTotal', resp.totalItems);
      context.commit('setPodcastEpisodesStatus', LIBRARYV2_STATUSES.DONE);
    }).catch((error) => {
      context.commit('setPodcastEpisodesStatus', LIBRARYV2_STATUSES.ERROR);
      context.commit('setPodcastEpisodesError', error.message);
      log.info(error);
    });
  },
  checkEpisodesInLibrary(context, ids) {
    if (ids.every(id => id in context.state.episodes)) return;
    context.commit('setEpisodesError', '');
    context.commit('setEpisodesStatus', LIBRARYV2_STATUSES.LOADING);
    libraryProvider.filterBookmarks(ids).then((res) => {
      context.commit('setLibraryEpisodes', { ...context.state.episodes, ...res });
      context.commit('setEpisodesStatus', LIBRARYV2_STATUSES.DONE);
    }).catch((err) => {
      context.commit('setEpisodesStatus', LIBRARYV2_STATUSES.ERROR);
      context.commit('setEpisodesError', err.message);
      log.info(err);
    });
  },

  checkPodcastsInLibrary(context, ids) {
    if (ids.every(id => id in context.state.podcasts)) return;
    context.commit('setPodcastsError', '');
    context.commit('setPodcastsStatus', LIBRARYV2_STATUSES.LOADING);
    libraryProvider.filterShows(ids).then((res) => {
      context.commit('setLibraryPodcasts', {
        ...context.state.podcasts,
        ...ids.reduce((acc, id) => ({ ...acc, [id]: res.settings[id].isInMyShows }), {}),
      });
      context.commit('setPodcastsStatus', LIBRARYV2_STATUSES.DONE);
    }).catch((err) => {
      context.commit('setPodcastsStatus', LIBRARYV2_STATUSES.ERROR);
      context.commit('setPodcastsError', err.message);
      log.info(err);
    });
  },

  addEpisodeToLibrary(context, id) {
    context.commit('setEpisodesError', '');
    context.commit('setItemStatus', { id, status: LIBRARYV2_STATUSES.LOADING });
    return libraryProvider.addBookmark(id).then(() => {
      context.commit('setLibraryEpisode', id);
      context.commit('setItemStatus', { id, status: LIBRARYV2_STATUSES.DONE });
    }).catch((err) => {
      context.commit('setItemStatus', { id, status: LIBRARYV2_STATUSES.ERROR });
      context.commit('setEpisodesError', err.message);
      throw err;
    });
  },

  addPodcastToLibrary(context, id) {
    context.commit('setPodcastsError', '');
    context.commit('setItemStatus', { id, status: LIBRARYV2_STATUSES.LOADING });
    return libraryProvider.addShow(id).then(() => {
      context.commit('setLibraryPodcast', id);
      context.commit('setItemStatus', { id, status: LIBRARYV2_STATUSES.DONE });
    }).catch((err) => {
      context.commit('setItemStatus', { id, status: LIBRARYV2_STATUSES.ERROR });
      context.commit('setPodcastsError', err.message);
      throw err;
    });
  },

  removeEpisodeFromLibrary(context, id) {
    context.commit('setEpisodesError', '');
    context.commit('setItemStatus', { id, status: LIBRARYV2_STATUSES.LOADING });
    return libraryProvider.removeBookmark(id).then(() => {
      context.commit('removeLibraryEpisode', id);
      context.commit('setItemStatus', { id, status: LIBRARYV2_STATUSES.DONE });
    }).catch((err) => {
      context.commit('setItemStatus', { id, status: LIBRARYV2_STATUSES.ERROR });
      context.commit('setEpisodesError', err.message);
      throw err;
    });
  },

  removePodcastFromLibrary(context, id) {
    context.commit('setPodcastsError', '');
    context.commit('setItemStatus', { id, status: LIBRARYV2_STATUSES.LOADING });
    return libraryProvider.removeShow(id).then(() => {
      context.commit('removeLibraryPodcast', id);
      context.commit('setItemStatus', { id, status: LIBRARYV2_STATUSES.DONE });
    }).catch((err) => {
      context.commit('setItemStatus', { id, status: LIBRARYV2_STATUSES.ERROR });
      context.commit('setPodcastsError', err.message);
      throw err;
    });
  },

  changePodcastPosition(context, { id, position }) {
    return libraryProvider.moveShow(id, position);
  },

  // TODO remove after testing
  // async updatePodcastPosition(context, { oldPosition, newPosition }) {
  //   const [...allPodcasts] = context.getters.libraryAllPodcastList;
  //   const [item] = allPodcasts.splice(oldPosition, 1);
  //   allPodcasts.splice(newPosition, 0, item);
  //   const { ...oldPageItems } = context.state.podcastList.pageItems;
  //   const maxPage = Math.max(...Object.keys(context.state.podcastList.pageItems).map(v => Number(v)));
  //   const pageItems = {};
  //   for (let i = 1; i <= maxPage; i++) {
  //     pageItems[i] = allPodcasts.slice((i - 1) * context.state.podcastList.size, i * context.state.podcastList.size);
  //   }
  //   context.commit('setAllPagePodcasts', pageItems);
  //   try {
  //     await context.dispatch('changePodcastPosition', { id: item.id, position: newPosition });
  //   } catch (e) {
  //     context.commit('setAllPagePodcasts', oldPageItems);
  //     throw e;
  //   }
  //   return item;
  // },

  changeEpisodePosition(context, { id, position }) {
    return libraryProvider.moveBookmark(id, position);
  },

  async updateEpisodePosition(context, { oldPosition, newPosition }) {
    const [...allEpisodes] = context.getters.libraryAllEpisodeList;
    const [item] = allEpisodes.splice(oldPosition, 1);
    allEpisodes.splice(newPosition, 0, item);
    const { ...oldPageItems } = context.state.episodeList.pageItems;
    const maxPage = Math.max(...Object.keys(context.state.episodeList.pageItems).map(v => Number(v)));
    const pageItems = {};
    for (let i = 1; i <= maxPage; i++) {
      pageItems[i] = allEpisodes.slice((i - 1) * context.state.episodeList.size, i * context.state.episodeList.size);
    }
    context.commit('setAllPageEpisodes', pageItems);
    try {
      await context.dispatch('changeEpisodePosition', { id: item.id, position: newPosition });
    } catch (e) {
      context.commit('setAllPageEpisodes', oldPageItems);
      throw e;
    }
    return item;
  },

  removeFromAllEpisodesList(context, id) {
    const [...allEpisodes] = context.getters.libraryAllEpisodeList;
    const index = allEpisodes.findIndex(v => v.id === id);
    if (index === -1) return;
    allEpisodes.splice(index, 1);
    const maxPage = Math.ceil(allEpisodes.length / context.state.episodeList.size);
    const pageItems = {};
    for (let i = 1; i <= maxPage; i++) {
      pageItems[i] = allEpisodes.slice((i - 1) * context.state.episodeList.size, i * context.state.episodeList.size);
    }
    const newFrom = Math.min((maxPage - 1) * context.state.episodeList.size, context.state.episodeList.from);
    // set "current page", not used in infinite scrolling approach, will be used with paging
    context.commit('setLibraryEpisodeList', allEpisodes.slice(newFrom, context.state.episodeList.size));
    // set per page dictionary used to calculate all episodes list in corresponding getter
    context.commit('setAllPageEpisodes', pageItems);
    // set "from" for current page, used only in per page downloading approach to calc current page
    context.commit('setLibraryEpisodeListFrom', newFrom);
    // update total items, decreased by one if item deleted
    context.commit('setLibraryEpisodeListTotal', context.state.episodeList.totalItems - 1);
  },

  updatePodcastViewDate(context, id) {
    context.commit('setLastViewedPodcast', id);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
