import AxiosAPI from './AxiosAPI';

class Subscription extends AxiosAPI {
  createSubscription(paymentOpts) {
    return new Promise((resolve, reject) => {
      super.post('v2/account/subscription/subscribe/web', paymentOpts).then((resp) => {
        resolve(resp);
      }).catch((error) => {
        reject(error && error.message ? error.message : error);
      });
    });
  }

  switchSubscription(paymentOpts) {
    return new Promise((resolve, reject) => {
      super.post('v3/account/subscription/switch-plan/web', paymentOpts).then((resp) => {
        resolve(resp);
      }).catch((error) => {
        reject(error && error.message ? error.message : error);
      });
    });
  }

  getSubscriptionPlans(currencyCode, countryCode) {
    return super.getList('v3/zuora/products', { product_type: 'WebStore', currency: currencyCode, country: countryCode });
  }

  fetchSubscriptionPlans(currencyCode, countryCode) {
    // const annualPlanEnabled = utility.annualPlanFlag();
    return new Promise((resolve, reject) => {
      this.getSubscriptionPlans(currencyCode, countryCode).then((resp) => {
        let plan = null;
        if (resp && resp.success && resp.products.length) {
          const product = resp.products[0];
          // looks like default selected plan (to refactor)
          [plan] = product.productRatePlans;
          if (window.$store.getters.userSubscription && window.$store.getters.userSubscription.plan) {
            plan = product.productRatePlans.find(p => p.PlanType === window.$store.getters.userSubscription.plan.type);
          }
        }
        // using new pricing vuex store post annual plan changes
        if (resp && resp.success && resp.products.length) {
          window.$store.dispatch('setPricingPlans', { response: resp, currencyCode, country: countryCode });
        }
        if (resp && resp.success && resp.holiday_promo && plan) {
          plan.holiday_promo = resp.holiday_promo;
        }
        // FIXME TEMPORARY HARD-CODED RESPONSE DATA --END
        resolve({ plan, originalResponse: resp });
      }).catch((error) => {
        reject(error && error.message ? error.message : error);
      });
    });
  }

  getSubscription() {
    return new Promise((resolve, reject) => {
      super.getList('v1/account/subscription', {}).then((resp) => {
        resolve(resp);
      }).catch((error) => {
        reject(error && error.message ? error.message : error);
      });
    });
  }

  cancelSubscription() {
    return new Promise((resolve, reject) => {
      super.post('v2/account/subscription/unsubscribe').then((resp) => {
        resolve(resp);
      }).catch((error) => {
        reject(error && error.message ? error.message : error);
      });
    });
  }

  pauseSubscription() {
    return super.post('v1/zuora/pause/web');
  }

  unpauseSubscription() {
    return super.post('v1/zuora/resume/web');
  }

  updatePaymentInfo(paymentOpts) {
    return new Promise((resolve, reject) => {
      super.post('v2/account/payment/credit-card', paymentOpts).then((resp) => {
        resolve(resp);
      }).catch((error) => {
        reject(error && error.message ? error.message : error);
      });
    });
  }

  fetchTransactionHistory(params) {
    return new Promise((resolve, reject) => {
      super.getList('v1/user/transactions', params).then((resp) => {
        resolve(resp);
      }).catch((error) => {
        reject(error && error.message ? error.message : error);
      });
    });
  }

  validateAndStorePromoCode(promoData, currency) {
    return new Promise((resolve, reject) => {
      const { activePlan } = window.$store.getters;
      super.get(`v1/zuora/promo/${promoData.promoCode}/validate`, { productRatePlans: activePlan && activePlan.id ? activePlan.id : '', currency }).then((resp) => {
        resolve(resp);
        if (resp && resp.valid) {
          localStorage.setItem('promo_code', promoData.promoCode);
          if (resp.promoData && resp.promoData.ratePlan) {
            const plan = resp.promoData.ratePlan[0];
            if (plan && plan.id) {
              const currentDate = new Date();
              currentDate.setMonth(currentDate.getMonth() + parseInt(plan.upToPeriods, 10));
              localStorage.setItem('promo_trial_end_date', currentDate);
              localStorage.setItem('promo_trial_period', parseInt(plan.upToPeriods, 10));
              promoData.trialPeriod = parseInt(plan.upToPeriods, 10);
              promoData.trialEndDate = currentDate;
              window.$store.dispatch('setPromoData', promoData);
            }
          }
        }
      }).catch((error) => {
        reject(error);
      });
    });
  }

  getTransactionDetails(transactionId) {
    return new Promise((resolve, reject) => {
      super.get(`v1/user/transactions-details/${transactionId}`).then((resp) => {
        if (resp) {
          resolve(resp);
        }
      }).catch((error) => {
        reject(error && error.message ? error.message : error);
      });
    });
  }

  subscriptionWithOldCard(paymentOpts) {
    return new Promise((resolve, reject) => {
      super.post('v1/zuora/resubscribe/web', paymentOpts).then((resp) => {
        resolve(resp);
      }).catch((error) => {
        reject(error && error.message ? error.message : error);
      });
    });
  }

  createDropInSubscription(paymentOpts) {
    return super.post('v3/account/subscription/subscribe/web', paymentOpts);
  }

  updateDropInSubscriptionMethod(paymentOpts) {
    return super.post('v3/account/payment/credit-card', paymentOpts);
  }

  createDropInSubscriptionFinal(paymentOpts) {
    return super.post('v1/account/finalize/subscribe/web', paymentOpts);
  }

  updateDropInSubscriptionMethodFinal(paymentOpts) {
    return super.post('v1/account/finalize/add-payment', paymentOpts);
  }

  validatePromocodeV2(promocode) {
    return super.get(`v2/promo/${promocode}/validate`);
  }

  redeemPromocodeV2(promocode) {
    /*
    return new Promise((res, rej) => {
      res({
        campaign_id: 'qwe',
        price: 123,
        duration: 12,
        expiration: 1649288095704,
        forced_expire: false,
        maximum_uses: 1,
        redemption_count: 0,
        references: [],
      });
    });
    */
    return super.get(`v2/promo/${promocode}/validate`);
  }

  getWebOffers(currency, countryCode) {
    return super.getList('v1/user/offer', { platform: 'web', currency: currency, country: countryCode });
  }

}

export default Subscription;
