import AxiosAPI from './AxiosAPI';

export default class Library extends AxiosAPI {
  bookmarksList({ from, size }) {
    return this.getList('v2/user/library/bookmarks', { from, size });
  }

  addBookmark(episodeId) {
    return this.post(`v2/user/library/bookmarks/${episodeId}`);
  }

  removeBookmark(episodeId) {
    return this.delete(`v2/user/library/bookmarks/${episodeId}`);
  }

  latestEpisodes({ from, size }) {
    return this.getList('v2/user/library/latest-episodes', { from, size });
  }

  showsList({ from, size }) {
    return this.getList('v2/user/library/shows', { from, size });
  }

  addShow(showId) {
    return this.post(`v2/user/library/shows/${showId}`);
  }

  removeShow(showId) {
    return this.delete(`v2/user/library/shows/${showId}`);
  }

  filterBookmarks(ids = []) {
    return this.getList('v2/user/is-in-my-bookmarks', { id: ids }, {
      paramsSerializer: this.paramsSerializerWithArray,
    });
  }

  filterShows(ids = []) {
    return this.getList('v2/user/library/is-in-my-shows', { id: ids }, {
      paramsSerializer: this.paramsSerializerWithArray,
    });
  }

  moveShow(showId, position) {
    return this.put('v2/user/library/shows', { id: showId, position });
  }

  moveBookmark(showId, position) {
    return this.put('v2/user/library/bookmarks', { id: showId, position });
  }

  notifications({ from, size }) {
    return this.getList('v2/user/library/notifications', { from, size });
  }

  enableNotification(showId) {
    return this.put('v2/user/library/notifications', { id: showId, enabled: true });
  }

  disableNotification(showId) {
    return this.put('v2/user/library/notifications', { id: showId, enabled: false });
  }
}
