<template>
  <component
    :is="widgetComponent"
    :widget="widget"
    :page-id="pageId"
    :page-target="pageTarget"
    :row="row"
    :num-loading="numLoadingPerWidget"
  />
</template>

<script>
import widgetHelper from './widgetHelper';
import widgetDictionary from './widgetDictionary';

export default {
  props: {
    widget: {
      type: Object,
      default() {
        return {};
      },
    },
    pageId: {
      type: String,
      default: '',
    },
    pageTarget: {
      type: String,
      default: '',
    },
    row: {
      type: Number,
      default: 0,
    },
    numLoadingPerWidget: {
      type: Number,
      default: 8,
    },
  },
  data() {
    return {
      widgetComponent: null,
    };
  },
  mounted() {
    this.getWidgetComponent(this.widget);
  },
  methods: {
    getWidgetComponent(widget) {
      const { layoutType } = widget;

      const componentString = widgetHelper.getWidgetLayoutString(layoutType);
      this.widgetComponent = widgetDictionary.layouts[componentString];
    },
  },
};
</script>
