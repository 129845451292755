<template>
  <div class="e1-plans">
    <h1 class="e1-plans__header">Choose a Plan</h1>
    <div v-loading="!pricingPlansLoaded" class="e1-plans__container">
      <template v-for="(v, index) in plansVisible">
        <E1PlanItem
          :key="index"
          :plan="v.plan"
          :is-selected="index === selectedPlan"
          :is-plans-extended="isPlansExtended"
          :index="index"
          @selectPlan="onSelectPlan"
          @selectSpecialPromoPlan="selectSpecialPromoPlan"
          @setSelectedPlan="setSelectedPlan"
          @onFootnoteTwoClick="onFootnoteTwoClick"
        />
      </template>
    </div>
    <div class="apple-podcasts" @click="onApplePodcastsClick">
      <div class="header">Luminary on Apple Podcasts</div>
      <div class="cta">
        <div class="cta-header">
          <div class="cta-header-left">
            <img
              width="260"
              height="48"
              src="../../assets/images/plans/ApplePodcasts.svg"
              alt="apple podcasts" />
          </div>
          <div class="cta-header-right">
            <img
              width="36"
              height="36"
              src="../../assets/images/plans/Icon-Arrow.svg"
              alt="apple podcasts" />
          </div>
        </div>
        <div class="hr">
          <hr />
        </div>
        <div class="main-text">
          Subscribe to the Luminary Channel on Apple Podcasts and get the same great content.
        </div>
        <div class="fine-print">
          (Subscriptions to the Luminary App and subscriptions to the Luminary channel on Apple Podcasts are separate. One does
          not provide access to the other.)
        </div>
      </div>
    </div>
    <div class="terms-fine-print">
      Free trials, if available, are for new subscribers only. <br />
      All plans auto-renew at the current rate after trial and until cancelled. <br />
      Terms apply.
    </div>
    <div class="limited-plans">
      <a
        role="button"
        tabindex="0"
        class="limited-plans-link"
        @click="onContinueLimited">
        Continue with Limited Access
      </a>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import E1PlanItem from './E1PlanItem';
import E1ApplePodcastItem from './E1ApplePodcastItem';
import countries from '@/assets/json/countries';
import { freePlan } from '../../shared/staticPlans';
import OptimizeService from '@/services/Optimize';
import { getServices } from '@/services/serviceProvider';
import SpecialPromoOffer from './SpecialPromoOffer';
import SpecialPromo from '@/services/SpecialPromo';
import PageMixin from '../../shared/pageMixin';

const [optimizeService] = getServices([OptimizeService]);

export default {
  name: 'E1PlanSignUp',
  components: {
    SpecialPromoOffer,
    E1PlanItem,
    E1ApplePodcastItem,
  },
  mixins: [PageMixin],
  data() {
    return {
      countries,
      isPlansExtended: false,
      specialPromoCode: null,
      specialRedeeming: null,
      specialPromoService: new SpecialPromo(),
      updatedPlans: false,
      selectedPlan: 1,
    };
  },
  computed: {
    ...mapGetters({
      countryCode: 'country',
      pricingPlansLoaded: 'pricingPlansLoaded',
      pricingPlans: 'pricingPlans',
      userSubscription: 'userSubscription',
      featureFlag: 'featureFlag',
      isHolidayPromoEnabled: 'isHolidayPromoEnabled',
      isSpecialPromoEnabled: 'isSpecialPromoEnabled',
      specialPromoCodeString: 'specialPromoCodeString',
      currentRedeeming: 'promocode/currentRedeeming',
    }),
    currentStep() {
      return (this.$route.meta && this.$route.meta.step) || 1;
    },
    currentProgress() {
      return this.currentStep / 4 * 100;
    },
    plansSorted() {
      const planOrder = {
        yearly: 1,
        'semi-annual': 2,
        monthly: 3,
        applePodcasts: 4,
      };
      const plans = this.updatedPlans ? this.updatedPlans : this.pricingPlans;
      const pricingPlans = [...plans].filter(plan => plan.planTypeString !== 'applePodcasts').sort((a, b) => (planOrder[a.planTypeString] - planOrder[b.planTypeString]));
      return pricingPlans.map(plan => ({
        plan,
      }));
    },
    plansVisible() {
      return this.isPlansExtended
        ? this.plansSorted
        : this.plansVisibleInitially;
    },
    plansVisibleInitially() {
      return this.plansSorted.filter(p => (!p.plan.isPromoPlan && p.plan.planTypeString !== 'applePodcasts'));
    },
    isAPHorizontal() {
      return (this.plansVisible.length - 1) % 3 === 0;
    },
    isMobileScreen() {
      return this.currentScreenWidth < this.screenSize.sm;
    },
    isMorePlansAvailable() {
      return !this.isPlansExtended && this.plansSorted.length > this.plansVisibleInitially.length;
    },
    isPromoOnTop() {
      const { banner } = this.$route.query;
      return banner === 'top';
    },
    // description() {
    //   return this.isHolidayPromoEnabled ? 'Take advantage of our best price ever for the annual plan.' : 'Subscribe for full access to our network of award-winning Luminary Originals.';
    // },
  },
  mounted() {
    this.setMetaInformation('Sign Up | Luminary', 'Get your Luminary subscription today & listen to original podcasts you won\'t find anywhere else.');
    const country = this.countries.find(v => v.code === this.countryCode) || this.countries.find(v => v.code === 'US');

    // Special Promo Code check
    if (this.isSpecialPromoEnabled) {
      try {
        this.redeemPromocode({ promocode: this.specialPromoCodeString })
          .then((codeData) => {
            // save promo code data in state
            this.specialPromoCode = codeData;
            this.specialRedeeming = this.currentRedeeming;
            this.isPlansExtended = false;

            // clear promo code until special promo will be selected
            this.setPromoCode(null);
            this.setCurrentRedeeming(null);

            // load pricing plan
            this.loadPricingPlans({ country: country.code, currency: country.currency }).then((plans) => {
              this.updatedPlans = this.specialPromoService.processPlans(this.pricingPlans, this.countryCode);
            });
          });
      } catch (e) {
        this.loadPricingPlans({ country: country.code, currency: country.currency });
      }
    } else this.loadPricingPlans({ country: country.code, currency: country.currency });
  },
  destroyed() {
    this.setMetaInformation();
  },
  methods: {
    ...mapActions([
      'loadPricingPlans',
      'selectPricingPlan',
      'selectFreePlan',
    ]),
    ...mapActions('signupflow', [
      'setCompleted',
    ]),
    ...mapActions({
      redeemPromocode: 'promocode/redeemPromocode',
      setPromoCode: 'setPromoCode',
      setCurrentRedeeming: 'promocode/setCurrentRedeeming',
    }),
    onSelectPlan(plan) {
      this.setPromoCode(null);
      this.setCurrentRedeeming(null);
      this.selectPlan(plan);
    },
    onExtendPlans() {
      optimizeService.triggerAnlyticsEvent({
        action: 'ShowMorePlans',
        category: 'Signup',
      });
      this.isPlansExtended = true;
    },
    onContinueLimited() {
      this.selectPlan(freePlan);
    },
    selectPlan(plan) {
      plan.isVinylBundle = plan.displayName && plan.displayName.match(/vinyl/i);
      plan.planType = plan.isVinylBundle ? `${plan.planTypeString}-with-vinyl-bundle` : plan.planTypeString;
      plan.productName = `Luminary Subscription - ${plan.planType}`;
      plan.productSku = `luminary-sub-${plan.planType}`;

      this.selectPricingPlan(plan);
      this.setCompleted('plan');
      optimizeService.triggerAnlyticsEvent({
        action: 'SelectPlan',
        category: 'Signup',
        label: plan.planTypeString,
        value: plan.paidPeriod,
      });

      this.$analyticsV2.logEvent('cf_plan_selected', {
        is_promo: plan.isPromoPlan || false,
        plan_type: plan.planType,
        is_vinyl_bundle: plan.isVinylBundle,
      });

      if (plan.planTypeString !== 'free') {
        this.$analyticsV2.addToCart({
          name: plan.productName,
          sku: plan.productSku,
          price: plan.price,
          quantity: 1,
        }, { ...plan });
      }

      this.$router.push(this.$route.meta.next);
    },
    selectSpecialPromoPlan() {
      const specialPlan = this.updatedPlans.find(p => p.isPromoPlan && p.planTypeString !== 'monthly');
      if (specialPlan) {
        this.setPromoCode(this.specialPromoCode);
        this.setCurrentRedeeming(this.specialRedeeming);
        this.selectPlan(specialPlan);
      }
    },
    setSelectedPlan(index) {
      this.selectedPlan = index;
    },
    onCTAClick() {
      const selected = this.plansVisible[this.selectedPlan];
      if (selected.plan.isPromoPlan) {
        this.selectSpecialPromoPlan();
      } else {
        this.selectPlan(selected.plan);
      }
    },
    onFootnoteOneClick(event) {
      event.preventDefault();
      document.getElementById('footnote-1').scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
    },
    onFootnoteTwoClick() {
      document.getElementById('footnote-2').scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
    },
    onApplePodcastsClick() {
      const appplePodcastsURL = 'https://podcasts.apple.com/us/channel/luminary/id6442463604?itsct=luminary_podcasts&itscg=30200&ls=1&at=1000l36wW&ct=lum_su_flow';
      this.$analyticsV2.logEvent('cf_button_clicked', {
        is_cta: false,
        target_url: appplePodcastsURL,
        button_text: 'Listen on Apple Podcasts',
      });
      setTimeout(() => {
        window.location.href = appplePodcastsURL;
      }, 1000);
    },
  },
};
</script>
<style lang="scss" scoped>
@import '../../assets/scss/variables.scss';

.e1-plans {
  display: flex;
  margin-top: 84px;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
  min-width: 320px;

  &__header {
    font-family: $patron-bold;
    font-size: 40px;
    text-align: center;
    margin: 0;
  }
  &__description {
    margin-top: 16px;
    font-size: 16px;
    text-align: center;
  }
  &__container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
    margin-top: 55px;
    gap: 20px;
  }
  &__links {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    width: 100%;
    padding: 15px 20px;
    box-sizing: border-box;
  }
  &__link {
    font-size: 12px;
    color: #BBBBBB;
    text-decoration: underline;
    cursor: pointer;
    &:hover {
      text-decoration: none;
    }
  }
  &__link-reg {
    font-size: 14px;
    color: $blue;
    text-decoration: underline;
    cursor: pointer;
    &:hover {
      text-decoration: none;
    }
  }
  &__see-more-container {
    flex: 1 1 100%;
    text-align: center;
  }


  .horizontal_line {
    opacity: 0.1;
    margin: 20px 0;
  }

  .e1-plans__see-more-container {
    margin-top:40px;
  }

  .e1-plans__link {
    margin:0 auto;
  }
  .e1-plans__fixed-cta {
    display: none;
    background-color: rgba(18,28,48, .84);
    background-image: linear-gradient(180deg, rgba(18,28,48, .21) 0%, rgba(18,28,48, .84) 100%);
    border-radius: 12px 12px 0 0;
    bottom: 0;
    box-shadow: inset rgba(255,255,255,.12) 0 1px 0, rgba(18,28,48, .48) 0 -3px 7px;
    left: 0;
    position: fixed;
    text-align: center;
    transition: opacity .34s ease, bottom .34s ease;
    width: 100%;
    z-index: 500;

    .wrapper {
      margin: 20px auto;
    }

    button {
      margin-bottom: 12px;
      width: calc(100% - (60px));
    }

    .fine-print {
      color: rgba(255,255,255,.39);
      font-size: 11px;
    }
  }

  .e1-plans__more-options {
    max-width: 460px;
    width: 100%;

    button {
      width: 100%;
    }
  }

  .e1-plans__faqs {
    padding-top: 60px;
    max-width: 700px;
    width: calc(100% - 30px);

    h2 {
      text-align: center;
      margin: 0;
      margin-bottom: .5em;
      font-weight: 700;
    }

    p {
      font-size: 1.1em;
      margin-bottom: 1em;
    }

    .item-header {
      font-size: 1.2em;
      font-weight: 400;
      font-family: $patron;
      margin: 0;
      transition: color .12s ease;
    }
  }

  .e1-plans__fine-print {
    display: none;
    max-width: 700px;
    width: calc(100% - 30px);
    margin-top: 60px;
    font-size: 12px;

    p {
      margin-bottom: 1.2em;
    }

    ol {
      list-style: decimal;
      list-style-position: outside;
      padding: 0 1.25rem;

      li {
        margin-bottom: 1.25em;
      }
    }
  }

  .apple-podcasts {
    display: flex;
    justify-content: flex-start;
    width: 90%;
    flex-direction: column;
    align-items: center;
    max-width: 680px;
    cursor: pointer;

    .header {
      margin-top: 115px;
      color: #121C30;
      font-family: $patron-bold;
      text-align: center;
      font-size: 40px;
    }

    .cta {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin-top: 50px;
      border: 1px solid #121C301F;
      border-radius: 10px;
      background: #192A4808;
      padding: 30px;
      width: 100%;
    }

    .cta-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .hr {
      margin-top: 23px;
    }

    .main-text {
      margin-top: 23px;
      font-size: 16px;
      font-family: $patron;
      text-align: left;
      color: #121C30;
    }

    .fine-print {
      margin-top: 23px;
      font-size: 12px;
      font-family: $patron;
      text-align: left;
      color: #121C30;
      opacity: .43;
    }
  }

  .terms-fine-print {
    margin-top: 50px;
    font-size: 12px;
    color: #121C30;
    opacity: .43;
    text-align: center;
  }

  .limited-plans {
    margin-top: 50px;
    margin-bottom: 50px;
    .limited-plans-link {
      color: #121C30;
      opacity: .43;
      text-align: center;
      font-size: 12px;
      color: #121C30;
      cursor: pointer;
      text-decoration: underline;
    }
  }


}

@media only screen and (max-width: 1024px) {
  .alt-col-xs-20 {
    width: 100%;
  }

  .push-top {
    margin-top: 20px !important;
  }


  .e1-plans {
    margin-top: 32px;

    &__container {
      flex-direction: column;
      width: 100%;
      margin-top: 45px;
    }

    .apple-podcasts {
      .header {
        margin-top: 60px;
      }

      .cta {
        margin-top: 22px;
      }

      .hr {
        margin-top: 20px;
      }

      .main-text {
        margin-top: 20px;
      }

      .fine-print {
        margin-top: 20px;
      }
    }

    .terms-fine-print {
      font-size: 10px;
    }
  }


}
</style>
