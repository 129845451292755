import featuresConfig from '../config/featuresConfig';
import AxiosAPI from './AxiosAPI';

export default class Brightback extends AxiosAPI {
  prepareCancellation(data) {
    if (window.Brightback) {
      return window.Brightback.handleDataPromise({
        app_id: featuresConfig.variables.brightback.app_id,
        save_return_url: featuresConfig.variables.brightback.save_return_url,
        cancel_confirmation_url: featuresConfig.variables.brightback.cancel_confirmation_url,
        ...data,
      });
    }
    return Promise.reject(new Error('Brightback not installed'));
  }

  getCancellationInfo() {
    return super.get('v1/account/subscription/cancel');
  }

  startProcessing(url) {
    window.location.href = url;
  }
}
