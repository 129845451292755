<template>
  <div class="listen-free-card">
    <div class="card-content">
      <ListenFreeTag :episode="episode" />
      <p class="episode-title">{{ episodeTitle }}</p>
      <p class="details">{{ episodeDetails }}</p>
    </div>
    <div class="card-player">
      <AudioIcon
        :episode="episode"
        :episode-legacy="episode_legacy_formatted_object"
        playlist-type="show" />
    </div>
  </div>
</template>

<script>
import ListenFreeTag from '../shared/ListenFreeTag';
import AudioIcon from '../shared/AudioIcon';

import showDetailsHelper from './showDetailsHelper';

export default {
  components: {
    ListenFreeTag,
    AudioIcon,
  },
  props: {
    episode: {
      type: Object,
      default() {
        return {};
      },
    },
    podcast: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      episode_legacy_formatted_object: {},
    };
  },
  computed: {
    episodeTitle() {
      if (!this.episode) return '';
      if (this.episode.mediaType === 'music') return `${this.episode.title} - ${this.episode.publisherName}`;
      return this.episode.title;
    },
    episodeDetails() {
      const episodeDetailsArray = [];
      if (this.episode.season && this.episode.number && this.episode.mediaType !== 'music') {
        const seasonText = `S${this.episode.season} E${this.episode.number}`;
        episodeDetailsArray.push(seasonText);
      }
      if (this.episode.releasedAt) {
        const date = this.$options.filters.dateFormat(this.episode.releasedAt * 1000);
        episodeDetailsArray.push(date);
      }
      if (this.episode.runtime) {
        const runtime = this.$options.filters.humanizedTimeFilter(this.episode.runtime);
        episodeDetailsArray.push(runtime);
      }
      return episodeDetailsArray.join(' | ');
    },
  },
  beforeMount() {
    this.formatLegacyEpisodeObject(this.episode);
  },
  methods: {
    formatLegacyEpisodeObject(episode) {
      this.episode_legacy_formatted_object = showDetailsHelper.formatLegacyEpisodeObject(episode);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../assets/scss/variables.scss";
@import "../../../assets/scss/mixins.scss";

.listen-free-card {
  flex:1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: $dark-overlay;
  border-radius: 4px;
  padding: 20px;
  width: 100%;
  max-width: 400px;
  margin-bottom: 16px;
  &:last-of-type {
    margin-bottom: 0;
  }
  @media(min-width: $md) {
    margin-right: 16px;
    margin-bottom: 0;
    &:last-of-type {
      margin-right: 0;
    }
  }
}

.card-content {
  margin-right: 20px;
}

.episode-title {
  font-family: $patron-bold;
  color: $white;
  @include multilinetruncate(16px,24px,1);
  margin-top: 0;
  @media(min-width: $sm) {
     @include multilinetruncate(18px,24px,1);
  }
}

.details {
  font-family: $patron;
  color: $light-gray3;
  font-size: 14px;
  line-height: 20px;
  margin-top: 6px;
  margin-bottom: 0;
  @media(min-width: $sm) {
    font-size: 16px;
  }
}
</style>
