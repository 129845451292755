var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"podcast-episodes",attrs:{"data-test":"podcast-episodes"}},[(_vm.isLoggedIn)?_c('div',[_c('loader',{directives:[{name:"show",rawName:"v-show",value:(_vm.isLoadingInitial),expression:"isLoadingInitial"}],attrs:{"center":"","label":"Fetching Podcasts Episodes ..."}}),_vm._v(" "),_c('div',{staticClass:"container"},[_c('library-menu',{attrs:{"display-list":true,"text":"Latest Episodes"}})],1),_vm._v(" "),_c('empty-shows',{directives:[{name:"show",rawName:"v-show",value:(!_vm.totalItems && !_vm.isLoadingInitial),expression:"!totalItems && !isLoadingInitial"}],attrs:{"option":"podcast-episodes"}}),_vm._v(" "),(_vm.totalItems)?_c('div',[_c('card-list-wrapper',{attrs:{"episodes":_vm.formattedItems,"bucket":{
          bucket_uuid: null,
          name: 'Latest Episodes',
          bucket_type:'episodes',
          position: 0,
          items_count: _vm.totalItems,
          media_size: 'episode_details'
        },"playlist-type":"my-shows","data-test":"latest-episodes"}})],1):_vm._e(),_vm._v(" "),_c('loader',{directives:[{name:"show",rawName:"v-show",value:(_vm.isLoadingMore),expression:"isLoadingMore"}],attrs:{"with-margin":true,"label":"Fetching Podcasts Episodes ..."}})],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }