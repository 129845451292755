<template>
  <div class="redeem-signup">
    <AcquisitionFlowNavbar
      :signup-step="currentStep"
      :reset-flow="resetSignupState"
      reset-flow-url="/redeem-a-gift" />
    <el-progress
      :percentage="currentProgress"
      :stroke-width="9"
      :show-text="false"
      color="#FFE500"/>
    <div class="container">
      <el-row>
        <grid-col
          :xs="{ offset: 1, span: 22 }"
          :sm="{ offset: 1, span: 22 }"
          :md="{ offset: 1, span: 9 }"
          :lg="{ offset: 1, span: 10 }"
          :xl="{ offset: 1, span: 10 }">
          <div class="signup-content">
            <h1 class="title">{{ title }}</h1>
          </div>
          <div class="signup-form">
            <SignUp
              ref="redeemSignup"
              :acquisition-flow-style="true"
              :on-sign-up-success="onSuccessfulSignUp"
              is-redeem-query="true"
              @stepThreeMethod="stepThreeMethod"
            />
          </div>
        </grid-col>
        <grid-col
          :xs="22"
          :sm="22"
          :md="{ offset: 3, span: 8 }"
          :lg="{ offset: 4, span: 9 }"
          :xl="{ offset: 4, span: 9 }"
          class="tile-col">
          <div class="tile-animation">
            <TileAnimation />
          </div>
        </grid-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import AcquisitionFlowNavbar from '../../AcquisitionFlowNavbar';
import SignUp from '../../../pages/onboarding/SignUp';
import TileAnimation from '../../TileAnimation';

export default {
  components: {
    AcquisitionFlowNavbar,
    TileAnimation,
    SignUp,
  },
  data() {
    return {
      title: 'Redeem your gift by creating an account.',
      currentStep: 'stepOne',
    };
  },
  computed: {
    currentProgress() {
      if (this.currentStep === 'stepOne') return 50;
      if (this.currentStep === 'stepThree') return 75;
      return 'null';
    },
  },
  methods: {
    stepThreeMethod() {
      this.currentStep = 'stepThree';
      return this.currentStep;
    },
    onSuccessfulSignUp() {
      this.$router.push({ path: '/redeem-a-gift' });
    },
    resetSignupState() {
      this.currentStep = 'stepOne';
      this.$refs.redeemSignup.cancelLoginWithEmail();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../assets/scss/variables.scss";

.redeem-signup {
  min-height: 100vh;
  width: 100%;
  background-color: $bg-color;
  background-image: url(../../../assets/images/web2/gifting/bg-gifting-hero-mobile.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: top center;
  @media (min-width: $sm) {
    background-image: none;
  }

  /deep/ .el-progress {
    .el-progress-bar {
      .el-progress-bar__outer {
        background-color: $bg-color !important;
        height: 6px !important;
      }
    }
  }
}

.signup-content {
  margin-top: 34px;
  margin-bottom: 48px;
  max-width: 340px;
  @media (min-width: $sm) {
    margin-top: 136px;
  }
}

.signup-form {
  max-width: 340px;
}

.title {
  color: $white;
  font-family: $serif;
  margin-bottom: 30px;
  font-size: 32px;
  line-height: 37px;
  margin-left: 16px;
}

.tile-col {
  display: none;
  @media (min-width: $sm) {
    display: block;
  }
}

.tile-animation {
  height: 100vh;
}

</style>
