import * as log from 'loglevel';

import Cookie from 'js-cookie';
import {
  EmailAuthProvider,
  getAuth,
  reauthenticateWithCredential,
  updatePassword,
} from 'firebase/auth';
import AxiosAPI from './AxiosAPI';
// import $store from '../store/index';
import getFirebase from '../config/firebase';
import utility from '../shared/utility';

const validAlexaRedirectHostnames = ['pitangui.amazon.com', 'alexa.amazon.co.jp', 'layla.amazon.com'];
const firebase = getFirebase();

class User extends AxiosAPI {
  constructor() {
    super();
    this.user = window.$store ? window.$store.getters.user : {};
    this.fbProvider = firebase.fbProvider;
    this.googleProvider = firebase.googleProvider;
    this.appleProvider = firebase.appleProvider;
  }

  getCurrentUser() {
    return window.$store.getters.user;
  }

  isLoggedIn() {
    return new Promise((resolve, reject) => {
      window.$cognitoAuth.isAuthenticated((err, authenticated) => {
        if (err || !authenticated) {
          return reject();
        }
        return resolve(true);
      });
    });
  }

  // getIdToken() {
  //   return new Promise((resolve, reject) => {
  //     firebase.auth().currentUser.getIdToken().then(resp => {
  //       resolve(ressp)
  //     }).catch(error => {
  //       reject(error.message)
  //     })
  //   })
  // }

  getRefreshToken() {
    const user = firebase.auth().currentUser;
    return user ? user.refreshToken : null;
  }

  getUserToken() {
    const user = firebase.auth().currentUser;
    if (!user) {
      return new Promise((resolve, reject) => {
        firebase.onAuthStateChanged((fbUser) => {
          if (fbUser) {
            fbUser.getIdToken()
              .then((res) => {
                resolve(res);
              })
              .catch((err) => {
                reject(err);
              });
          } else {
            resolve(fbUser);
          }
        });
      });
    }
    return user.getIdToken().then(res => res);
  }

  setUserToken(token) {
    window.$store.commit('setToken', token);
    // super.setDefaultHeader('X-Id-Token', token)
  }

  register(user) {
    return new Promise((resolve, reject) => {
      firebase.createUserWithEmailAndPassword(user.email, user.password).then(() => {
        this.verifyAuthentication().then(() => {
          resolve();
        }).catch((error) => {
          reject(error);
        });
      }).catch((error) => {
        reject(error.message);
      });
    });
  }

  getTrackingPreferencesCookie() {
    return Cookie.get('tracking-preferences');
  }

  getUserShowsV2() {
    return new Promise((resolve, reject) => {
      super.get('v2/user/is-in-my-shows').then((resp) => {
        resolve(resp);
      }).catch((error) => {
        reject(error);
      });
    });
  }

  postUserShowsV2(podcastArray) {
    return new Promise((resolve, reject) => {
      super.post('v2/user/shows', podcastArray).then((resp) => {
        resolve(resp);
      }).catch((error) => {
        reject(error);
      });
    });
  }

  deleteUserShowsV2(podcastId) {
    return new Promise((resolve, reject) => {
      super.delete(`v2/user/shows/${podcastId}`).then((resp) => {
        resolve(resp);
      }).catch((error) => {
        reject(error);
      });
    });
  }

  getUserBookmarksV2() {
    return new Promise((resolve, reject) => {
      super.get('v2/user/is-in-my-bookmarks').then((resp) => {
        resolve(resp);
      }).catch((error) => {
        reject(error);
      });
    });
  }

  registerToLuminary(marketingConsent, fingerprintId) {
    const { country, acceptedCookiePolicy } = window.$store.getters;

    let params = {
      country,
      branch_fingerprint_id: fingerprintId,
      marketing_consent: marketingConsent,
      gdpr_preferences: this.getTrackingPreferencesCookie(),
    };

    params = utility.removeEmpty(params);
    if (country === 'GB') {
      params.is_cookie_tracking_permission_granted = acceptedCookiePolicy;
    }

    return new Promise((resolve, reject) => {
      super.post('v1/user/register', params).then((resp) => {
        window.$store.commit('setUser', resp.user);
        window.$store.commit('setLoggedIn', true);
        window.$store.commit('setLastLoggedIn', new Date());
        if (typeof newrelic === 'object') {
          window.newrelic.setCustomAttribute('user_uuid', resp.user.uuid);
        }
        resolve(resp);
      }).catch((error) => {
        log.error(error);
        reject(error && error.message ? error.message : error.error);
      });
    });
  }

  loginToLuminary(fingerprintId) {
    const { country, acceptedCookiePolicy } = window.$store.getters;

    let params = {
      country,
      branch_fingerprint_id: fingerprintId,
      gdpr_preferences: this.getTrackingPreferencesCookie(),
    };

    params = utility.removeEmpty(params);

    if (country === 'GB') {
      params.is_cookie_tracking_permission_granted = acceptedCookiePolicy;
    }

    return new Promise((resolve, reject) => {
      super.post('v1/user/login', params).then((resp) => {
        window.$store.commit('setUser', resp.user);
        window.$store.commit('setLoggedIn', true);
        window.$store.commit('setLastLoggedIn', new Date());

        if (resp.user) {
          window.$store.commit('acceptCookiePolicy', resp.user.is_cookie_tracking_permission_granted);

          if (!resp.user.is_cookie_tracking_permission_granted && acceptedCookiePolicy) {
            window.$store.commit('acceptCookiePolicy', true);
          }
        }

        if (typeof newrelic === 'object') {
          window.newrelic.setCustomAttribute('user_uuid', resp.user.uuid);
        }

        resolve(resp);
      })
        .catch((error) => {
          log.error(error);
          this.logout();
          reject(error.message);
        });
    });
  }

  refreshUser(identify = true) {
    return new Promise((resolve, reject) => {
      super.getList('v1/user').then((resp) => {
        if (identify) {
          window.$store.commit('setUser', resp);
          // window.$analytics.identify(resp.uuid);
          if (typeof newrelic === 'object') {
            window.newrelic.setCustomAttribute('user_uuid', resp.uuid);
          }
        }
        resolve(resp);
      }).catch((error) => {
        if (identify) {
          this.logout();
        }
        reject(error);
      });
    });
  }

  authenticate(user) {
    return new Promise((resolve, reject) => {
      firebase.signInWithEmailAndPassword(user.email, user.password).then(() => {
        this.verifyAuthentication().then(() => {
          resolve();
        }).catch((error) => {
          reject(error.message);
        });
      }).catch((error) => {
        reject(error);
      });
    });
  }

  async verifyAuthentication() {
    const token = await this.getUserToken();
    return new Promise((resolve, reject) => {
      if (token) {
        // this.setUserToken(token)
        resolve();
      } else {
        this.logout(); // if token not found then logout user
        reject(new Error('Token not found'));
      }
    });
  }

  updateFirebaseInfo(userData) {
    return new Promise((resolve, reject) => {
      const user = firebase.auth().currentUser;
      if (user) {
        user.updateProfile({
          displayName: `${userData.first_name} ${userData.last_name}`,
          photoURL: userData.imageUrl,
        }).then((resp) => {
          resolve(resp);
        }).catch((error) => {
          reject(error.message);
        });
      }
    });
  }

  updateFirebaseEmail(userData) {
    return new Promise((resolve, reject) => {
      const user = firebase.auth().currentUser;
      if (user) {
        user.updateEmail(userData.email).then((resp) => {
          resolve(resp);
        }).catch((error) => {
          reject(error.message);
        });
      } else {
        reject(new Error('User not found'));
      }
    });
  }

  updateUserInfo(_userData) {
    const userData = _userData;
    return new Promise((resolve, reject) => {
      delete userData.imageUrl;
      const formData = userData;
      super.post('v1/user/update', formData)
        .then((resp) => {
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  updateUserPassword(newpass) {
    return new Promise((resolve, reject) => {
      const user = firebase.auth().currentUser;
      if (user) {
        updatePassword(user, newpass).then((resp) => {
          resolve(resp);
        }).catch((error) => {
          reject(error.message);
        });
      }
    });
  }

  forgotPasswordV1(email) {
    return new Promise((resolve, reject) => {
      super.put('v1/user/reset_password', { email })
        .then((resp) => {
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  forgotPassword(email) {
    return new Promise((resolve, reject) => {
      firebase.sendPasswordResetEmail(email)
        .then((resp) => {
          log.info(resp);
          resolve();
        })
        .catch(() => {
          reject(new Error('This email is not registered with us.'));
        });
    });
  }

  resetPassword(actionCode, newPassword) {
    return new Promise((resolve, reject) => {
      firebase.confirmPasswordReset(actionCode, newPassword)
        .then((resp) => {
          resolve(resp);
        })
        .catch(() => {
          reject(new Error('We were unable to reset your password.'));
        });
    });
  }

  reAuthenticateUser(password) {
    return new Promise((resolve, reject) => {
      const auth = getAuth();
      const credential = EmailAuthProvider.credential(auth.currentUser.email, password);
      // Prompt the user to re-provide their sign-in credentials
      reauthenticateWithCredential(auth.currentUser, credential)
        .then((_user) => {
          // User re-authenticated.
          log.info('Authenticated user confirmed.');
          resolve(_user);
        })
        .catch((error) => {
          // An error happened.
          reject(error);
        });
    });
  }

  loadUserCategories() {
    return new Promise((resolve, reject) => {
      super.getList('v1/user/categories').then((resp) => {
        resolve(resp);
      }).catch((err) => {
        reject(err);
      });
    });
  }

  loadUserTopics() {
    return new Promise((resolve, reject) => {
      super.getList('v1/user/onboarding_topics').then((resp) => {
        resolve(resp);
      }).catch((err) => {
        reject(err);
      });
    });
  }

  saveOnboardingTopics(topics) {
    return new Promise((resolve, reject) => {
      // const formData = {
      //   body: topics,
      // };
      super.put('v1/user/onboarding_topics', topics).then((resp) => {
        resolve(resp);
      }).catch((error) => {
        reject(error);
      });
    });
  }

  addUserCategory(categoryUuid) {
    return new Promise((resolve, reject) => {
      const formData = {
        categoryUuid,
      };
      super.post('v1/user/categories', formData).then((resp) => {
        resolve(resp);
      }).catch((error) => {
        reject(error);
      });
    });
  }

  removeUserCategory(userCategoryUUID) {
    return new Promise((resolve, reject) => {
      super.delete(`v1/user/categories/${userCategoryUUID}`).then((resp) => {
        resolve(resp);
      })
        .catch((error) => {
          reject(error);
        });
    });
  }

  sendLuminaryFeedback(feedback, userEmail) {
    return new Promise((resolve, reject) => {
      const formData = {
        comment: feedback,
        email: userEmail,
      };
      super.post('v1/user/account_feedback', formData).then((resp) => {
        resolve(resp);
      }).catch((error) => {
        reject(error);
      });
    });
  }

  logout() {
    return firebase.signOut()
      .then(() => {
        window.$store.dispatch('setDiscover', []);
        window.$store.dispatch('logout');
        window.$store.dispatch('resetUserLibraryState');
        window.$store.dispatch('resetUserCLState');
        window.$store.dispatch('resetUserPlayerState');
        window.$store.dispatch('resetUserEpisodeProgressState');
        window.$store.dispatch('selectPricingPlan', null);
        this.setUserToken(null);

        super.post('v1/user/logout');
      })
      .catch((error) => {
        log.error(error.message);
      });
  }

  isValidAlexaRedirectUri(redirectUri) {
    try {
      const url = new URL(redirectUri);
      return validAlexaRedirectHostnames.includes(url.hostname);
    } catch (e) {
      // ignore URL parsing error
    }

    return false;
  }

  loginWithFb() {
    return new Promise((resolve, reject) => {
      firebase.signInWithPopup(this.fbProvider).then((result) => {
        const user = firebase.auth().currentUser;
        if (user && user.email) {
          resolve(result);
        } else {
          firebase.auth().currentUser.delete();
          reject(new Error('Email is required'));
        }
      }).catch((error) => {
        reject(error);
      });
    });
  }

  loginWithGoogle() {
    return new Promise((resolve, reject) => {
      firebase.signInWithPopup(this.googleProvider).then((result) => {
        resolve(result);
      }).catch((error) => {
        reject(error);
      });
    });
  }

  loginWithApple() {
    return new Promise((resolve, reject) => {
      firebase.signInWithPopup(this.appleProvider).then((result) => {
        resolve(result);
      }).catch((error) => {
        reject(error);
      });
    });
  }

  trackInternationalUser(data) {
    return new Promise((resolve, reject) => {
      super.post('v1/user/track/international', data).then((resp) => {
        resolve(resp);
      }).catch((error) => {
        reject(error);
      });
    });
  }

  async triggerEmailVerification() {
    const token = await this.getUserToken();
    return super.postWithConfig('v1/user/trigger_email_verification', undefined, {
      headers: {
        'X-Id-Token': token,
      },
    });
  }

  async verifyEmail(oobCode) {
    const token = await this.getUserToken();
    return super.postWithConfig('v1/user/verify_email', { oobCode }, {
      headers: {
        'X-Id-Token': token,
      },
    });
  }
}

export default User;
