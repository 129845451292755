<template>
  <div class="e1-account">
    <h1 class="e1-account__header">{{ title }}</h1>
    <div
      class="e1-account__already">
      <p>Already have an account? &nbsp;
        <router-link
          v-button-event
          :to="{ path: '/signin' }"
          aria-label="Log in"
          class="btn-link">Log In</router-link>
      </p>
    </div>
    <div v-loading="isRegistering" :class="{ 'e1-account__3rdparty': true, 'e1-account__3rdparty--collapsed': loginFlow }">
      <a
        v-button-event
        v-if="appleSignupEnabled"
        class="apple sm-auth__button m-b"
        @click="loginWithApple">
        <div class="sm-auth__button--icon">
          <img
            src="../../assets/images/apple.svg"
            alt="">
        </div>
        <div class="sm-auth__button--text">Continue with Apple</div>
      </a>
      <a
        v-button-event
        v-if="facebookSignupEnabled"
        class="fb sm-auth__button m-b"
        @click="loginWithFB">
        <span class="sm-auth__button--icon">
          <img
            src="../../assets/images/facebook.svg"
            alt="">
        </span>
        <span class="sm-auth__button--text">Continue with Facebook</span>
      </a>
      <a
        v-button-event
        v-if="googleSignupEnabled"
        class="google sm-auth__button m-b"
        @click="loginWithGoogle">
        <span class="sm-auth__button--icon">
          <img
            src="../../assets/images/google.svg"
            alt="">
        </span>
        <span class="sm-auth__button--text">Continue with Google</span>
      </a>
      <a
        v-button-event
        v-if="!loginFlow"
        class="email sm-auth__button m-b"
        @click="loginWithEmail">
        <span class="sm-auth__button--icon" style="height: 27px;">
          <img
            src="../../assets/images/email_icon.svg"
            alt="">
        </span>
        <span class="sm-auth__button--text">Sign up with Email</span>
      </a>
    </div>
    <div v-if="loginFlow" class="e1-account__divider"/>
    <div v-if="loginFlow" class="e1-account__email">
      <sign-up-email
        :is-ready="!isRegistering"
        @success="onRegisterSuccess"
        @signupInvalid="onSignupValidationFail"
      />
    </div>
    <div class="e1-account__continue-existing">
      <el-button
        v-button-event
        v-loading="isRegistering"
        v-if="isCompleted('account')"
        :disabled="isRegistering"
        :class="{'btn-brand': true, 'e1-account__btn': true}"
        @click="onContinue"
      >
        Continue
      </el-button>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import User from '../../services/User';
import BranchService from '../../services/Branch';
import OptimizeService from '@/services/Optimize';
import PageMixin from '../../shared/pageMixin';
import { getServices } from '@/services/serviceProvider';
import SignUpEmail from '../../pages/onboarding/SignUpEmail';

const [branchService, userProvider, optimizeService] = getServices([BranchService, User, OptimizeService]);

export default {
  name: 'E1Account',
  components: {
    SignUpEmail,
  },
  mixins: [PageMixin],
  props: {
    title: {
      type: String,
      default: 'Create Account',
    },
  },
  data() {
    return {
      loginFlow: false,
      fingerprint_id: '',
      isRegistering: false,
    };
  },
  computed: {
    ...mapGetters({
      flagWithDefault: 'flagWithDefault',
      isAcquisitionFlow: 'isAcquisitionFlow1',
      isRedeemGiftFlow: 'isRedeemGiftFlow',
      freePlanSelected: 'freePlanSelected',
    }),
    ...mapGetters('signupflow', [
      'isStepAvailable',
      'isCompleted',
    ]),
    googleSignupEnabled() {
      return this.flagWithDefault('google_signup', true);
    },
    facebookSignupEnabled() {
      return this.flagWithDefault('facebook_signup', true);
    },
    appleSignupEnabled() {
      return this.flagWithDefault('apple_signup', true);
    },
    acquisitionFlowEnabled() {
      return this.flagWithDefault('acquisition_flow', false);
    },
    isAvailable() {
      return this.isStepAvailable('account');
    },
  },
  watch: {
    isAvailable(v) {
      this.processAvailability(v);
    },
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (vm.$store.getters.loggedIn) {
        vm.setCompleted('account');
        // pass to the next screen depending of direction (next/prev)
        if (from && from.meta && from.meta.prev.name === to.name) {
          // go back
          vm.$router.push(to.meta.prev);
        } else {
          vm.$router.push(to.meta.next);
        }
      }
    });
  },
  mounted() {
    this.processAvailability(this.isAvailable);
    this.setMetaInformation('Sign Up | Luminary', 'Get your Luminary subscription today & listen to original podcasts you won\'t find anywhere else.');
    this.fingerprint_id = localStorage.getItem('branch_fingerprint_id');
    if (!this.fingerprint_id) {
      this.getBrowsersFingerPrintId();
    }
  },
  destroyed() {
    this.setMetaInformation();
  },
  methods: {
    ...mapActions('signupflow', [
      'setCompleted',
    ]),
    processAvailability(isAvailable) {
      if (!isAvailable) {
        this.$router.replace(this.$route.meta.prev);
      }
    },
    onRegisterSuccess(mktConsent) {
      this.registerToLuminary(mktConsent);
    },
    onContinue() {
      if (this.isCompleted('account')) this.$router.push(this.$route.meta.next);
    },
    registerToLuminary(mktConsent) {
      // const branchData = localStorage.getItem('branch_data');
      this.isRegistering = true;
      userProvider.registerToLuminary(mktConsent, this.fingerprint_id)
        .then((resp) => {
          if (!resp.created) {
            // user already exists, do something
            this.showSuccessMsg('Welcome Back, You\'ve already registered');
            this.$router.push('/listen/my-shows');
            return;
          }
          // localStorage.removeItem('branch_data');
          this.showSuccessMsg('Your account has been created!');
          this.$analytics.identify(resp.user.uuid);
          // Logging event to branch
          const analyticsPayload = {
            ...resp,
            marketing_consent: mktConsent,
          };
          this.trackAccountCreatedData(analyticsPayload);
          this.logBranchCustomEvent(resp);
          this.setCompleted('account');
          if (this.freePlanSelected) {
            this.$router.push('/listen/my-shows');
            return;
          }
          this.$router.push(this.$route.meta.next);
        })
        .catch((error) => {
          this.showErrorMsg(error);
        }).finally(() => {
          this.isRegistering = false;
        });
    },
    trackAccountCreatedData(resp) {
      const data = {
        platform: localStorage.getItem('login_type'),
        email: resp.user.email,
        marketing_consent: resp.marketing_consent,
      };
      this.$analytics.track('ga_account_created', data);
      this.$analytics.track('Account Created', data);
      optimizeService.triggerAnlyticsEvent({
        action: 'CreatedAccount',
        category: 'Signup',
        label: data.platform,
      });

      this.$analyticsV2.logEvent('cf_signup_successful', {
        email: data.email,
        marketing_consent: data.marketing_consent,
        signup_method: data.platform,
      });
    },
    getBrowsersFingerPrintId() {
      if (!window.branch || (window.branch && !window.branch.getBrowserFingerprintId)) {
        return;
      }
      window.branch.getBrowserFingerprintId((err, data) => {
        if (data) {
          localStorage.setItem('branch_fingerprint_id', data);
          this.fingerprint_id = data;
        }
      });
    },
    logBranchCustomEvent(resp) {
      const customData = {
        user_uuid: resp.user.uuid,
        user_email: resp.user.email,
        branch_promo: localStorage.getItem('branch_promo'),
      };
      branchService.logCustomEvent(branchService.accountCreatedEventName,
        customData);
    },
    loginWithFB() {
      this.$analyticsV2.logEvent('cf_signup_attempted', { signup_method: 'Facebook' });
      userProvider.loginWithFb().then(() => {
        this.registerToLuminary();
        localStorage.setItem('login_type', 'Facebook');
      }).catch(this.firebaseAuthErrorHandler);
    },
    loginWithGoogle() {
      this.$analyticsV2.logEvent('cf_signup_attempted', { signup_method: 'Google' });
      userProvider.loginWithGoogle().then(() => {
        this.registerToLuminary();
        localStorage.setItem('login_type', 'Google');
      }).catch(this.firebaseAuthErrorHandler);
    },
    loginWithApple() {
      this.$analyticsV2.logEvent('cf_signup_attempted', { signup_method: 'Apple' });
      userProvider.loginWithApple().then(() => {
        this.registerToLuminary();
        localStorage.setItem('login_type', 'Apple');
      }).catch(this.firebaseAuthErrorHandler);
    },
    loginWithEmail() {
      this.loginFlow = true;
      this.$analyticsV2.logEvent('cf_signup_attempted', { signup_method: 'Email' });
      localStorage.setItem('login_type', 'Email');
      this.$emit('stepThreeMethod');
    },
    cancelLoginWithEmail() {
      this.loginFlow = false;
    },
    firebaseAuthErrorHandler(error) {
      if (error.code !== 'auth/popup-closed-by-user') {
        this.showErrorMsg(error.message);
        this.$analyticsV2.logEvent('cf_signup_failed', { error_details: error.message });
      }
    },
    onSignupValidationFail(data) {
      this.$analyticsV2.logEvent('cf_signup_failed', { error_details: data });
      optimizeService.triggerAnlyticsEvent({
        action: 'CreateAccountValidationFail',
        category: 'Signup',
        label: Object.keys(data).join(','),
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import '../../assets/scss/variables.scss';

.e1-account {
  display: flex;
  align-items: center;
  flex: 1 1 auto;
  justify-content: start;
  flex-flow: column;
  &__header {
    font-family: $serif-bold;
    font-size: 36px;
  }
  &__divider {
    height: 0;
    border-top: 1px solid #F2F2F2;
    min-width: 300px;
    margin: 17px 0;
  }
  &__3rdparty {
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    .sm-auth__button {
      padding: 0 24px;
      height: 48px;
      display: flex;
      flex-flow: column;
      align-items: start;
      justify-content: center;
      border-radius: 24px;
      &--icon {
        left: initial;
      }
      &--text {
        margin-left: 48px;
      }
    }
    &--collapsed {
      flex-flow: row;
      .sm-auth__button {
        margin: 8px;
        width: 48px;
        padding: 0;
        align-items: center;
        &--text {
          display: none;
        }
      }
    }
  }
  &__email {
    width: 300px;
  }
  &__continue-existing {
    margin-top: 30px;
  }
  &__btn {
    height: 48px;
    width: 300px;
  }
}
</style>
