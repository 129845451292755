export default {
  getWidgetLayoutString(widgetLayoutType) {
    let layoutComponent = null;

    switch (widgetLayoutType) {
      case 'carousel':
        layoutComponent = 'carousel-layout';
        break;
      default:
        layoutComponent = null;
        break;
    }
    return layoutComponent;
  },
  getWidgetItemString(widgetLayoutType, widgetItemStyle, widgetItemType) {
    let itemComponent = null;

    switch (widgetLayoutType && widgetItemStyle && widgetItemType) {
      case ('carousel' && 'default' && 'show'):
        itemComponent = 'carousel-default-show-item';
        break;
      default:
        itemComponent = null;
        break;
    }
    return itemComponent;
  },
  mapPreloadedWidgetsItems(page) {
    return page.widgets.map((widget) => {
      if (widget.serviceUrlFull in page.preloaded) {
        widget.items = page.preloaded[widget.serviceUrlFull];
        widget.isPreloaded = true;
      }
      return widget;
    });
  },
  formatContentSelectEventData(pageId, pageTarget, widget, item, index) {
    const bucket = {
      screen_name: pageTarget.charAt(0).toUpperCase() + pageTarget.slice(1) || 'unknown',
      bucket_type: widget.itemType === 'show' ? 'podcasts' : widget.itemType,
      bundle_uuid: pageId,
      uuid: widget.id,
      name: widget.title,
      media_size: item.images.thumbnail ? 'thumbnail' : 'default',
    };
    const bucketItem = {
      item_position: index,
      title: item.title,
      uuid: item.id,
      display_image_url: item.images.thumbnail || item.images.default,
      is_exclusive: item.isExclusive,
    };

    return { bucket, bucketItem };
  },
  formatConentViewedEventData(pageTarget, widget) {
    const bucket = {
      screen_name: pageTarget.charAt(0).toUpperCase() + pageTarget.slice(1) || 'unknown',
      bucket_type: widget.itemType === 'show' ? 'podcasts' : widget.itemType,
      uuid: widget.id,
      name: widget.title,
      media_size: widget.items[0].images.thumbnail ? 'thumbnail' : 'default',
      items_count: widget.items.length,
    };
    const bucketItemsSeen = widget.items.map(item => item.id);

    return { bucket, bucketItemsSeen };
  },
};
