<template>
  <div v-if="isEnabled" class="email-verification">
    {{ verifyStatus }}
    <el-button
      :icon="buttonIcon"
      :class="iconClass"
      circle
      @click="onIconClick"
    />
    <el-dialog
      :visible.sync="showModal"
      :append-to-body="true"
      class="email-verification__dialog"
    >
      <template v-if="isEmailVerified">
        <i type="secondary" class="el-icon-circle-check email-verification__status-cofirmed"/>
        <div class="email-verification__dialog-confirmation">
          Email Confirmed.
        </div>
      </template>
      <template v-else>
        <div v-if="showSendCode">
          <h3 slot="title" class="email-verification__dialog-title">Verify your email.</h3>
          <div class="email-verification__dialog-description">
            Verify your email to keep your account secure.
          </div>
          <div class="email-verification__dialog-prompt">
            We will send a code to this email.
          </div>
          <div class="email-verification__dialog-email">
            {{ email }}
          </div>
          <el-button
            v-loading="isTriggerLoading"
            type="primary"
            class="block-btn email-verification__dialog-send-email"
            @click="onSendEmailClick">Send code</el-button>
          <div class="email-verification__dialog-have-code">
            Already have a code?
            <el-button
              type="text"
              @click="onEnterCode">Verify Code</el-button>
          </div>
        </div>
        <div v-else>
          <h3 slot="title" class="email-verification__dialog-title">Verify your email.</h3>
          <div class="email-verification__dialog-description">
            Enter the code we emailed to {{ email }}.
          </div>
          <div class="email-verification__dialog-prompt">
            Enter your code.
          </div>
          <el-form-item prop="oobCode">
            <el-input
              v-model="oobCode"
              class="email-verification__dialog-input code_input"
              type="text"
            />
            <el-button
              v-loading="isVerificationLoading"
              type="primary"
              class="block-btn email-verification__dialog-send-email"
              @click="onSubmitClick">Submit Code</el-button>
            <div class="email-verification__dialog-have-code">
              Didn't get an email?
              <el-button
                type="text"
                @click="onResendVerification">Resend Verification</el-button>
            </div>
        </el-form-item></div>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { EMAIL_VERIFICATION_STAGE_LOADING } from '@/store/modules/emailVerification';

export default {
  props: {
    unverifiedOnly: {
      type: Boolean,
      default: false,
    },
    code: {
      type: String,
      default: '',
    },
    email: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    oobCode: '',
    showModal: false,
    showSendCode: true,
  }),
  computed: {
    ...mapGetters(['isEmailVerified', 'featureFlag']),
    ...mapGetters({
      isAnyLoading: 'emailVerification/isLoading',
      stateVerify: 'emailVerification/stateVerify',
      stateTrigger: 'emailVerification/stateTrigger',
      requestError: 'emailVerification/error',
    }),
    isVerificationLoading() {
      return this.stateVerify === EMAIL_VERIFICATION_STAGE_LOADING;
    },
    isTriggerLoading() {
      return this.stateTrigger === EMAIL_VERIFICATION_STAGE_LOADING;
    },
    isLoading() {
      return this.isEmailVerified === null || this.isAnyLoading;
    },
    buttonIcon() {
      if (this.isEmailVerified) return 'el-icon-circle-check';
      if (this.isLoading) return 'el-icon-loading';
      return 'el-icon-warning';
    },
    iconClass() {
      const res = ['email-verification__icon-button'];
      if (this.isEmailVerified) res.push('email-verification__status-done');
      else if (this.isLoading) res.push('email-verification__status-loading');
      else res.push('email-verification__status-warning');
      return res;
    },
    verifyStatus() {
      return this.isEmailVerified ? 'Verified' : 'Not Verified';
    },
    isFeatureEnabled() {
      return this.featureFlag('email_verification');
    },
    isEnabled() {
      return this.isFeatureEnabled && (!this.unverifiedOnly || this.isEmailVerified === false);
    },
  },
  watch: {
    requestError(val) {
      if (val) this.showErrorMsg(val.message);
    },
  },
  mounted() {
    if (this.isFeatureEnabled && this.code && !this.isEmailVerified) {
      this.verifyEmail(this.code);
    }
  },
  methods: {
    ...mapActions({
      triggerEmailVerification: 'emailVerification/triggerEmailVerification',
      verifyEmail: 'emailVerification/verifyEmail',
    }),
    onIconClick() {
      this.showModal = true;
    },
    onSubmitClick() {
      this.verifyEmail(this.oobCode);
    },
    onSendEmailClick() {
      this.triggerEmailVerification();
    },
    onResendVerification() {
      this.showSendCode = true;
    },
    onEnterCode() {
      this.showSendCode = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/scss/variables.scss";
.email-verification {
  height: 100%;
  display: flex;
  align-items: center;
  padding-right: 10px;
  &__icon-button {
    padding: 0px !important;
    margin-left: 15px;
    font-size: 24px;
    vertical-align: middle;
  }
  &__status-done {
    background-color: #ffffff;
    border-width: 0px;
    color: #00AECB;
  }
  &__status-cofirmed {
    font-size: 86px;
    background-color: #121C30;
    border-width: 0px;
    color: #00AECB;
    margin-top: 100px;
  }
  &__status-warning {
    background-color: #ffffff;
    border-width: 0px;
    color: red;
  }
  &__status-warning:hover {
    color: black;
  }
  &__status-loading {
    background-color: #ffffff;
    border-width: 0px;
    color: gray;
  }

  &__dialog-title {
    font-weight: bold;
    font-family: Patron;
    font-size: 34px;
  }
  &__dialog {
    @media (max-width: $xs) {
      font-size: 18px;
      width: 100%;
    }
    /deep/ .el-dialog {
      padding-top: 10px;
      padding-right: 20px;
      background-color: $dark-background;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 500px;
      border-radius: 4px;
      box-shadow: 0px 3px 30px #00000029;
      @media (max-width: $xs) {
        padding-top: 20px;
        padding-right: 0px;
        width: 90%;
      }
      h3 {
        padding-bottom: 10px;
        text-align: left;
        font-size: 32px;
        margin: 0 0;
        line-height: 36px;
        color: #ffffff;
        font-weight: 500;
        @media (max-width: $xs) {
          font-size: 24px;
        }
      }
      .el-dialog__body {
        text-align: center;
        margin-bottom: -10px;
        p {
          font-size: 16px;
          line-height: 20px;
          color: #ffffff;
        }
      }
      .el-dialog__headerbtn {
        color: #ffffff;
        border-radius: 50%;
        border: 1px solid #a8a7a77a;
        width: 35px;
        height: 35px;
        padding: 7px 7px;
        display: flex;
        justify-content: center;
        align-items: center;
        .el-dialog__close {
          color: #fcfcfc;
        }
        .el-dialog__close:hover {
          color: #00AECB;
        }
      }
    }
  }
  &__dialog-send-email {
    margin-left: 35px;
    width: 80%;
    @media (max-width: $xs) {
      margin-left: 15px;
    }
  }
  &__dialog-email {
    margin-left: 35px;
    margin-bottom: 1em;
    border-radius: 2rem;
    color: #F2F2F2;
    border: 2px solid #606266 !important;
    font-size: 14px;
    background: #3f3f40 0% 0% no-repeat padding-box;
    height: 2em;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 10px;
    width: 75%;
    @media (max-width: $sm) {
      margin-left: 15px;
    }
  }

  &__dialog-input {
    margin-left: 35px;
    margin-bottom: 1em;
    border-radius: 2rem;
    color: #F2F2F2;
    border: 2px solid #606266 !important;
    font-size: 14px;
    background: #3f3f40 0% 0% no-repeat padding-box;
    height: 2em;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 10px;
    width: 75%;
    @media (max-width: $sm) {
      margin-left: 15px;
    }

    /deep/ .el-input__inner {
      background-color: #ffffff00;
      color: #ffffff;
      border: 0px;
      text-align: center;
      font-size: 18px;
    }
  }

  &__dialog-input:hover {
    border: 2px solid #10AFCA !important;
  }

  &__dialog-email:hover {
    border: 2px solid #10AFCA !important;
  }

  &__dialog-description {
    text-align: left;
    font-weight: normal;
    font-family: Patron;
    font-size: 18px;
    color: #bebebe;
    padding-right: 20px;
    @media (max-width: $xs) {
      font-size: 14px;
    }
  }

  &__dialog-confirmation {
    margin-top: 10px;
    padding-top: 10px;
    margin-bottom: 100px;
    text-align: center;
    font-weight: bold;
    font-family: Patron;
    font-size: 18px;
    color: #ffffff;
    @media (max-width: $xs) {
      font-size: 14px;
    }
  }


  &__dialog-prompt {
    margin-top: 50px;
    color: #bebebe;
    font-size: 14px;
    margin-bottom: 1em;
    @media (max-width: $xs) {
      font-size: 12px;
    }
  }

  &__dialog-have-code {
    margin: 0px;
    margin-top: 30px;
    color: #ffffff;
    text-align: center;
  }

}
</style>
