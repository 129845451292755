<template>
  <div class="bespoke-show">
    <section
      class="show-hero">
      <el-row>
        <grid-col
          :xs="24"
          :sm="24"
          :md="24"
          :lg="12"
          class="hero-image-container">
          <div class="image-container">
            <img
              :src="srcSet('src', heroImages)"
              :srcset="srcSet('srcset', heroImages)">
          </div>
        </grid-col>
        <grid-col
          :xs="24"
          :sm="24"
          :md="24"
          :lg="12">
          <h1 v-html="heroHeader"/>
          <h3 v-html="heroSubheader" />
          <div class="hero-btns">
            <div
              class="play-trailer-btn"
              @click="playTrailer">
              <img
                v-if="isPlayingTrailer"
                src="../../assets/images/ic_pause_white_icon.svg"
                alt="Pause"
                class="pause-btn">
              <img
                v-else
                :class="{'play-btn': true, 'pulse': isLoadingTrailer }"
                src="../../assets/images/bespoke-play-btn.svg"
                alt="Play">
              <i
                v-if="false"
                class="el-icon-loading text-blue"/>
              <span>{{ playBtnText }}</span>
            </div>
            <div class="cta-btn">
              <button
                ref="cta"
                @click="() => handleCTAClick(stickyTs ? 'sticky' : 'hero')">
                {{ heroCTAText }}
              </button>
            </div>
          </div>
        </grid-col>
      </el-row>
    </section>
    <section class="show-carousel">
      <flickity
        ref="flickity"
        :options="flickityOptions">
        <img
          v-for="(srcSetImageGroup, index) in carouselImages"
          :key="`carousel-image-${index}`"
          :src="srcSet('src', srcSetImageGroup)"
          :srcset="srcSet('srcset', srcSetImageGroup)"
          class="carousel-image">
      </flickity>
    </section>
    <section class="show-more-info">
      <div class="content-container">
        <el-row
          v-if="isMdViewport">
          <grid-col
            :span="12">
            <p
              v-for="(paragraph, index) in infoParagraphsWithStyle"
              :key="`p-${index}`"
              v-html="paragraph"/>
          </grid-col>
          <grid-col
            :span="10"
            :push="2">
            <img
              :src="srcSet('src', moreInfoImageSrcSets)"
              :srcset="srcSet('srcset', moreInfoImageSrcSets)">
          </grid-col>
        </el-row>
        <div
          v-else
          class="mobile-layout">
          <div
            v-for="(paragraph, index) in infoParagraphsWithStyle"
            :key="`p-m-${index}`">
            <p
              v-if="index === 0"
              v-html="paragraph"/>
          </div>
          <img
            :src="srcSet('src', moreInfoImageSrcSets)"
            :srcset="srcSet('srcset', moreInfoImageSrcSets)">
          <div
            v-for="(paragraph, index) in infoParagraphsWithStyle"
            :key="`p-m2-${index}`">
            <p
              v-if="index > 0"
              v-html="paragraph"/>
          </div>
        </div>
      </div>
    </section>
    <section class="trailer-player">
      <div
        class="play-trailer-btn"
        @click="playTrailer">
        <img
          v-if="isPlayingTrailer"
          src="../../assets/images/ic_pause_black_icon.svg"
          alt="Pause"
          class="pause-btn">
        <img
          v-else
          :class="{'play-btn': true, 'pulse': isLoadingTrailer }"
          src="../../assets/images/bespoke-play-btn-blk.svg"
          alt="Play">
        <i
          v-if="false"
          class="el-icon-loading text-blue"/>
        <span>{{ playBtnText }}</span>
      </div>
    </section>
    <section class="episodes-list">
      <div class="list-container">
        <div
          v-for="(episode, index) in showEpisodes"
          :key="`ec-${index}`"
          class="episode-card-b">
          <div class="episode-meta">
            <span class="episode-number">Episode {{ (index + 1).toString() }}</span>
          </div>
          <div class="episode-content">
            <p>{{ episode.description }}</p>
          </div>
        </div>
        <div class="cta-btn">
          <button
            @click="() => handleCTAClick('episode-list')">
            {{ heroCTAText }}
          </button>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import * as log from 'loglevel';
import Flickity from 'vue-flickity';
import throttle from 'lodash/throttle';
import Podcast from '../../services/Podcast';
import PageMixin from '../../shared/pageMixin';
import Event from '../../services/PlayerEvent';
import heroImage1x from '../../assets/images/fiasco/hero_image@1x.png';
import heroImage2x from '../../assets/images/fiasco/hero_image@2x.png';
import carousel1Img1x from '../../assets/images/fiasco/carousel_01@1x.png';
import carousel1Img2x from '../../assets/images/fiasco/carousel_01@2x.png';
import carousel2Img1x from '../../assets/images/fiasco/carousel_02@1x.png';
import carousel2Img2x from '../../assets/images/fiasco/carousel_02@2x.png';
import carousel3Img1x from '../../assets/images/fiasco/carousel_03@1x.png';
import carousel3Img2x from '../../assets/images/fiasco/carousel_03@2x.png';
import carousel4Img1x from '../../assets/images/fiasco/carousel_04@1x.png';
import carousel4Img2x from '../../assets/images/fiasco/carousel_04@2x.png';
import moreInfoImage1x from '../../assets/images/fiasco/info_image@1x.png';
import moreInfoImage2x from '../../assets/images/fiasco/info_image@2x.png';


export default {
  name: 'BespokeShow',
  components: {
    Flickity,
  },
  mixins: [PageMixin],
  data() {
    return {
      season_trailers: [],
      stickyCTA: false,
      stickyTs: null,
      podcast_service: new Podcast(),
      eventProvider: new Event(),
      display_image_url: 'https://img3.stage.luminarypodcasts.com/v1/podcast/8607a31a-cab7-4c9f-9df8-b6110ba93b52/thumbnail/h_360,w_360/image/s--e4L691d5--/aHR0cHM6Ly9tZWdhcGhvbmUtcHJvZC5zMy5hbWF6b25hd3MuY29tL3BvZGNhc3RzLzUxZjRhYzI2LTQxMWEtMTFlOS05Nzg3LWRmNTI4N2IyYjM2Mi9pbWFnZS91cGxvYWRzXzJGMTU4MDE2NjczMDg2MS03NjNoem9ibXg4bC02NGNiOTI2MjQ5NWI1MDE3NzJhNDgzYTlhZmM4YzhiNF8yRlMyX0ZJQVNDT19UZW1wU2hvd0FydF8zMDAweDMwMDAuanBn.jpg',
      showUUID: '8607a31a-cab7-4c9f-9df8-b6110ba93b52',
      windowWidth: null,
      windowHeight: null,
      heroImages: [heroImage1x, heroImage2x],
      heroHeader: 'Fiasco Season 2<br>Iran-Contra',
      heroSubheader: "The story of a secret war, a secret deal, and a scandal that threatened to destroy Ronald Reagan’s presidency. <br><span>Until it didn't.<span>",
      heroCTAText: 'Start Free Trial >',
      carouselImages: {
        img1: [carousel1Img1x, carousel1Img2x],
        img2: [carousel2Img1x, carousel2Img2x],
        img3: [carousel3Img1x, carousel3Img2x],
        img4: [carousel4Img1x, carousel4Img2x],
      },
      infoParagraphs: [
        'Iran-Contra is a complicated story that spans the globe. If you’ve gone through life not knowing exactly what it was all about—what the Iran part has to do with the Contra part, or what the Contra part even refers to—you’re not alone.',
        "Using dozens of original interviews and reams of archival footage, Fiasco's second season breaks down this obscure scandal in a form that’s easy to follow, while also unearthing similarities to current political and international events.",
      ],
      moreInfoImageSrcSets: [
        moreInfoImage1x,
        moreInfoImage2x,
      ],
      isPlaying: false,
      showEpisodes: [
        {
          description: 'A preface to the Iran-Contra scandal, in which Oliver North tries to help Ronald Reagan turn the page on the Vietnam War by bringing regime change to a tiny Caribbean island.',
        },
        {
          description: 'The origins of the initiative that put the "Iran" in Iran-Contra, and set National Security Advisor Robert “Bud” McFarlane on a path he couldn’t go back from.',
        },
        {
          description: 'How the Reagan administration got involved in a secret war against the socialist government of Nicaragua—a war Congress had prohibited them from fighting.',
        },
        {
          description: 'How the Iran program collided with the Contra war when a retired general-turned-weapons dealer was installed to help Oliver North with the logistics of both operations.',
        },
        {
          description: 'What happened when the Iran and Contra operations were exposed, and how Reagan, “The Great Communicator,” struggled to explain them.',
        },
        {
          description: 'The Congressional hearings that turned Iran-Contra into a national obsession during the summer of 1987—and made Oliver North a star.',
        },
        {
          description: 'How Hollywood tried and failed to make an "All the President\'s Men" about Iran-Contra, and what that tells us about why the story faded from public consciousness.',
        },
        {
          description: 'Why the Independent Counsel appointed to explore criminal wrongdoing during Iran-Contra failed to put a single Reagan official in prison—and what William Barr had to do with it.',
        },
      ],
    };
  },
  computed: {
    isMdViewport() {
      return this.windowWidth >= 1024;
    },
    isXsViewport() {
      return this.windowWidth <= 576;
    },
    playBtnText() {
      return `${this.isPlaying ? 'Pause' : 'Play'} Trailer`;
    },
    isLoading() {
      return false;
    },
    flickityOptions() {
      return {
        prevNextButtons: false,
        draggable: window.innerWidth < 1024,
        contain: true,
        pageDots: false,
      };
    },
    infoParagraphsWithStyle() {
      if (!this.infoParagraphs) {
        return [];
      }
      try {
        const getRandomStyle = () => {
          const baseStyle = `margin: 0 7px; height: ${this.isMdViewport ? '19' : '13'}px; display: inline-block; background-color: black;`;
          const ranWidth = Math.floor(Math.random() * (110 - 50 + 1)) + 50;
          const ranRotate = Math.floor(Math.random() * (4 - (-4) + 1)) + (-4);
          return `${baseStyle} width: ${ranWidth}px; transform: scale(1.3) rotate(${ranRotate}deg)`;
        };
        return this.infoParagraphs.map((text) => {
          const wordsArray = text.split(' ');
          const randomIndex = Math.floor(Math.random() * wordsArray.length);
          const randomIndexTwo = Math.floor(Math.random() * wordsArray.length);
          wordsArray.splice(randomIndex, 0, `<span style="${getRandomStyle()}"> </span>`);
          wordsArray.splice(randomIndexTwo, 0, `<span style="${getRandomStyle()}"/> </span>`);
          return wordsArray.join(' ');
        });
      } catch (err) {
        return this.infoParagraphs;
      }
    },
    currentlyPlaying() {
      return this.$store.getters.episode;
    },
    isLoadingTrailer() {
      const { status } = this.$store.getters;

      return this.season_trailers.length && status === 'loading' && this.currentlyPlaying && (this.currentlyPlaying.uuid === this.season_trailers[0].uuid);
    },
    isPlayingTrailer() {
      const { play } = this.$store.getters;
      if (!this.season_trailers) {
        return false;
      }
      return this.season_trailers.length && play && this.currentlyPlaying && (this.currentlyPlaying.uuid === this.season_trailers[0].uuid);
    },
  },
  watch: {
    /* eslint-disable */
    windowHeight: function (newHeight) {
      this.adjustStickyCta();
    },
    /* eslint-enable */
  },
  beforeMount() {
    this.getSeasonTrailers();
  },
  mounted() {
    if (!this.$store.getters.hidePlayer) {
      this.togglePlayerVisiblity(true);
    }
    this.windowWidth = window.innerWidth;
    this.windowHeight = window.innerHeight;
    window.addEventListener('resize', () => {
      this.windowWidth = window.innerWidth;
      this.windowHeight = window.innerHeight;
    });
    window.addEventListener('scroll', () => this.handleScroll());
    window.addEventListener('scroll', throttle(this.checkVisibleSections, 1000));
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.togglePlayerVisiblity(true);
      vm.addNoIndexMeta();
    });
  },
  beforeRouteLeave(to, from, next) {
    this.togglePlayerVisiblity(false);
    this.removeNoIndexMeta();
    next();
  },
  methods: {
    handleCTAClick(btnId) {
      const { isFreeUser, isPremiumUser } = this.promoMeta;
      const routeTo = { path: '/signup' };
      if (isFreeUser) {
        routeTo.path = '/plans';
      } else if (isPremiumUser) {
        routeTo.path = '/listen/leon-neyfakh/fiasco-luminary-premium/8607a31a-cab7-4c9f-9df8-b6110ba93b52';
      }
      this.$analytics.track('Bespoke CTA Click', {
        screen_name: this.$route.name,
        path: this.$route.path,
        btnId,
      });
      this.$router.push(routeTo);
    },
    adjustStickyCta() {
      if (this.$refs.cta && this.stickyTs && this.isXsViewport) {
        this.$refs.cta.style.top = `${window.innerHeight - 110}px`;
      }
    },
    togglePlayerVisiblity(bool) {
      this.$store.dispatch('toggleHidePlayer', bool);
    },
    checkVisibleSections() {
      if (!this.viewTracker) {
        this.viewTracker = [...document.getElementsByTagName('section')]
          .map(section => section.className)
          .reduce((tracker, sectionName) => {
            tracker[sectionName] = false;
            return tracker;
          }, {});
      }
      const ctx = this;
      Object.entries(this.viewTracker)
        .forEach(([secName, hasViewed]) => {
          if (hasViewed) {
            return;
          }
          const e = document.getElementsByClassName(secName) && document.getElementsByClassName(secName)[0];
          const isVisible = (e && e.getBoundingClientRect() && e.getBoundingClientRect().y < window.innerHeight) || false;
          if (isVisible) {
            ctx.viewTracker[secName] = true;
            ctx.$analytics.track('Scrolled Into View', {
              screen_name: ctx.$route.name,
              path: ctx.$route.path,
              section: secName,
            });
          }
        });
    },
    handleScroll() {
      if (!this.isXsViewport) {
        return;
      }
      const ctaYPos = (this.$refs.cta && this.$refs.cta.getBoundingClientRect().y) || 1000;
      const ctaXPos = (this.$refs.cta && this.$refs.cta.getBoundingClientRect().x) || 1000;
      const viewPortThreshold = window.innerHeight - 110;
      const isSticky = ctaYPos < viewPortThreshold;
      const refParentDivYPos = (this.$refs.cta && this.$refs.cta.parentElement && this.$refs.cta.parentElement.getBoundingClientRect().y) || 1000;
      if (isSticky && !this.stickyTs) {
        this.$refs.cta.style.top = `${window.innerHeight - 110}px`;
        this.$refs.cta.style.left = `${ctaXPos}px`;
        this.$refs.cta.style.position = 'fixed';
        this.stickyTs = Date.now();
      }
      if (refParentDivYPos > viewPortThreshold && this.stickyTs && (Date.now() - this.stickyTs > 1000)) {
        this.$refs.cta.style.top = 'unset';
        this.$refs.cta.style.left = 'unset';
        this.$refs.cta.style.position = 'relative';
        this.stickyTs = null;
      }
    },
    next() {
      this.$refs.flickity.next();
    },
    previous() {
      this.$refs.flickity.previous();
    },
    playPauseTrailer() {
      this.isPlaying = !this.isPlaying;
    },
    playTrailer() {
      const trailer = this.season_trailers[0];
      trailer.episode_type = 'trailer';
      trailer.display_image_url = this.display_image_url;
      trailer.playedFrom = 'bespoke_sdp';
      this.togglePlayerVisiblity(true);
      if (this.currentlyPlaying.uuid === trailer.uuid && this.currentlyPlaying.playedFrom === trailer.playedFrom) {
        const { play } = this.$store.getters;

        if (!play) {
          if (this.currentlyPlaying.episode_type !== 'trailer') {
            this.eventProvider.triggerEpisodePauseEvent(this.currentlyPlaying, 'trailer_select');
          }
          this.$store.dispatch('play');
          this.eventProvider.triggerTrailerPlayEvent(trailer, 'sdp_select');
        } else {
          this.$store.dispatch('pause');
          this.eventProvider.triggerTrailerPauseEvent(trailer, 'sdp_pause');
        }
      } else {
        this.$store.dispatch('setTrailerAsEpisode', false);

        if (this.currentlyPlaying.episode_type !== 'trailer') {
          this.eventProvider.triggerEpisodePauseEvent(this.currentlyPlaying, 'trailer_select');
        }
        this.$store.dispatch('setTrailerExperienceTriggered', false); // Make sure trailer experience does not trigger from here.
        this.loadMediaInPlayer(trailer.media_url, trailer.is_exclusive, trailer.episode_type, true);
        this.$store.dispatch('setNextEpisode', {}); // Empty next episode because playback should stop after trailer complete from SDP.
        this.$store.dispatch('setAndPlayEpisode', trailer);
        this.eventProvider.triggerTrailerPlayEvent(trailer, 'sdp_select');
      }
    },
    getSeasonTrailers() {
      this.podcast_service
        .getPodcastTrailers(this.showUUID)
        .then((res) => {
          this.season_trailers = res.trailers || [];
          this.season_trailers = this.season_trailers.sort((a, b) => new Date(b.released_at) - new Date(a.released_at));
        })
        .catch((error) => {
          log.error(error);
        });
    },
  },
};
</script>

<style lang='scss' scoped>
@import "../../assets/scss/variables.scss";

.bespoke-show {
  background-color: black;
  color: white;
  font-family: 'Courier New', Courier, monospace;
  font-weight: bold;
  display: flex;
  flex-direction: column;
  p, h1, h3, button {
    font-weight: bold;
    font-family: 'Courier New', Courier, monospace;
    color: white;
  }
  .hidden-div {
    visibility: hidden;
  }
  .sticky-div {
    position: fixed;
    .cta-btn {
      display: flex;
      @media(max-width: $md) {
        width: 100%;
        justify-content: center;
        margin-bottom: 28px;
      }
      width: 50%;
      button {
        width: 251px;
        height: 55px;
        background-color: #c10000;
        font-size: 17px;
        text-align: center;
        border: 0;
        border-radius: 30px;
        color: white;
      }
    }
  }
  .show-hero {
    margin-right: auto;
    margin-left: auto;
    padding-top: 78px;
    padding-bottom: 78px;
    width: 95vw;
    max-width: 1138px;
    @media(max-width: $xs) {
      width: 100%;
      padding-top: 0px;
      padding-bottom: 16px;
    }
    .hero-image-container {
      .image-container {
        width: 100%;
        display: flex;
        @media(max-width: $md) {
          justify-content: center;
        }
        @media(max-width: $xs) {
          width: 100vw;
          height: 100vw;
        }
        img {
          width: 100%;
          @media(max-width: $xs) {
            object-fit: contain;
          }
          @media(min-width: $xs) {
            width: 520px;
          }
          @media(min-width: 1024px) and (max-width: 1200px) {
            max-width: 85%;
          }
        }
      }
    }
    h1 {
      margin-top: 52px;
      font-size: 35px;
      line-height: 46px;
      margin-bottom: 32px;
      @media(max-width: $md) {
        margin-top: 24px;
        margin-bottom: 16px;
        text-align: center;
        font-size: 24px;
        line-height: 30px;
      }
      @media(max-width: $xs) {
        font-size: 20px;
        line-height: 28px;
      }
      @media(min-width: 1024px) and (max-width: 1200px) {
        font-size: 24px;
        line-height: 30px;
        margin-top: 36px;
      }
    }
    h3 {
      font-size: 24px;
      line-height: 36px;
      margin-bottom: 69px;
      @media(max-width: $xs) {
        font-size: 16px;
        line-height: 25px;
      }
      @media(max-width: $md) {
        max-width: 75%;
        margin-left: auto;
        margin-right: auto;
        font-size: 18px;
        line-height: 30px;
        text-align: center;
        margin-bottom: 24px;
      }
      @media(min-width: 1024px) and (max-width: 1200px) {
        font-size: 18px;
        line-height: 30px;
      }
    }
    .hero-btns {
      display: flex;
      @media(max-width: $xs) {
        flex-direction: column;
        justify-content: center;
      }
      .play-trailer-btn {
        width: 50%;
        display: flex;
        align-items: center;
        @media(max-width: $md) {
          width: 100%;
          justify-content: center;
          margin-bottom: 28px;
        }
        img, svg {
          width: 59px;
          height: 59px;
          margin-right: 19px;
        }
        span {
          font-size: 17px;
        }
        .pause-btn {
          transform: scale(.5) translateX(-3px);
        }
      }
      .cta-btn {
        display: flex;
        z-index: 9980;
        height: 55px;
        @media(max-width: $md) {
          width: 100%;
          justify-content: center;
          margin-bottom: 28px;
        }
        width: 50%;
        button {
          transition: 500ms ease-in-out all;
          width: 251px;
          height: 55px;
          background-color: #c10000;
          font-size: 17px;
          text-align: center;
          border: 0;
          border-radius: 30px;
          color: white;
        }
      }
    }
  }
  .show-carousel {
    height: 322px;
    width: 100%;
    max-width: 1280px;
    margin-left: auto;
    margin-right: auto;
    img {
      height: 322px;
      width: 322px;
    }
  }
  .show-more-info {
    background-color: white;
    color: black;
    .content-container {
      max-width: 1138px;
      margin-left: auto;
      margin-right: auto;
      /* padding: 77px 20px; */
      padding: 77px 20px 34px 20px;
      @media(max-width: $xs) {
        padding: 28px 2.5%;
      }
      p {
        color: black;
        font-size: 24px;
        line-height: 36px;
        font-weight: bold;
        margin-bottom: 32px;
      }
      .mobile-layout {
        p {
          color: black;
          font-size: 16px;
          line-height: 22px;
          font-weight: bold;
          margin-bottom: 32px;
          padding: 0 5%;
        }
        img {
          display: block;
          width: 485px;
          max-width: 90%;
          margin: 0 auto 32px;
        }
      }
    }
    .cta-btn {
      display: flex;
      flex-grow: 1;
      justify-content: center;
      padding-bottom: 78px;
      @media(max-width: $xs) {
        display: none;
      }
      button {
        width: 251px;
        height: 55px;
        background-color: #c10000;
        font-size: 17px;
        text-align: center;
        border: 0;
        border-radius: 30px;
        color: white;
      }
    }
  }
  .trailer-player {
    flex-direction: column;
    justify-content: center;
    background-color: white;
    color: black;
    .play-trailer-btn {
      width: 50%;
      display: flex;
      align-items: center;
      /* margin-top: 54px; */
      margin-bottom: 76px;
      width: 100%;
      justify-content: center;
      @media(max-width: $md) {
        width: 100%;
        justify-content: center;
      }
      img, svg {
        width: 59px;
        height: 59px;
        margin-right: 19px;
      }
      span {
        font-size: 17px;
      }
      .pause-btn {
        transform: scale(.5) translateX(-3px);
      }
      }
  }
  .episodes-list {
    .list-container {
      max-width: 1280px;
      margin-left: auto;
      margin-right: auto;
      display: flex;
      flex-wrap: wrap;
      .episode-card-b {
        box-sizing: border-box;
        background-color: #1A1A1A;
        border: 1px solid black;
        width: 50%;
        min-height: 150px;
        @media(max-width: $xs) {
          width: 100%;
        }
        .episode-meta {
          box-sizing: border-box;
          width: 100%;
          margin-top: 19px;
          margin-bottom: 15px;
          border-left: 9px solid red;
          padding-left: 23px;
          padding-right: 20px;
          font-size: 19px;
          line-height: 21px;
          font-weight: bold;
          font-family: Arial, Helvetica, sans-serif;
          .episode-length {
            float: right;
          }
        }
        .episode-content {
          padding-left: 34px;
          padding-right: 20px;
          h1 {
            font-size: 19px;
            line-height: 17px;
            font-weight: bold;
            font-family: Arial, Helvetica, sans-serif;
          }
          p {
            font-size: 14px;
            line-height: 16px;
            font-weight: 400;
            font-family: Arial, Helvetica, sans-serif;
          }
        }
      }
      .cta-btn {
        display: flex;
        justify-content: center;
        padding: 78px 0;
        flex-grow: 1;
        @media(max-width: $xs) {
          display: none;
        }
        button {
          width: 251px;
          height: 55px;
          background-color: #c10000;
          font-size: 17px;
          text-align: center;
          border: 0;
          border-radius: 30px;
          color: white;
        }
      }
    }
  }
  .pulse {
    animation-name: pulse;
    animation-duration: 800ms;
    animation-fill-mode: both;
    animation-iteration-count: infinite;
  }
}
@keyframes pulse {
  from {
    transform: scale3d(1, 1, 1);
  }

  50% {
    transform: scale3d(1.05, 1.05, 1.05);
  }

  to {
    transform: scale3d(1, 1, 1);
  }
}
</style>
