import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersistence from 'vuex-persist';
import user from './modules/user';
import features from './modules/features';
import playlist from './modules/playlist';
import presearch from './modules/presearch';
import player from './modules/player';
import discover from './modules/discover';
import episodes from './modules/episodes';
import library from './modules/library';
import subscription from './modules/subscription';
import globals from './modules/globals';
import events from './modules/events';
import gifting from './modules/gifting';
import pricing from './modules/pricing';
import payment from './modules/payment';
import experiment from './modules/experiment';
import promocode from './modules/promocode';
import signupflow from './modules/signupflow';
import brightback from './modules/brightback';
import libraryv2 from './modules/libraryv2';
import emailVerification from './modules/emailVerification';

Vue.use(Vuex);

const vuexLocalStorage = new VuexPersistence({
  key: 'vuex',
  storage: window.localStorage,
  reducer: state => ({
    user: state.user,
    playlist: {
      playlistType: state.playlist.playlistType,
    },
    library: state.library,
    events: state.events,
    gifting: state.gifting,
    payment: state.payment,
    promocode: state.promocode,
    experiment: state.experiment,
    libraryv2: {
      lastViewedPodcasts: state.libraryv2.lastViewedPodcasts,
    },
  }),
});

export default new Vuex.Store({
  modules: {
    user,
    features,
    playlist,
    presearch,
    player,
    discover,
    episodes,
    subscription,
    library,
    globals,
    events,
    gifting,
    pricing,
    payment,
    experiment,
    signupflow,
    promocode,
    brightback,
    libraryv2,
    emailVerification,
  },
  plugins: [vuexLocalStorage.plugin],
});
