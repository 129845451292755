<template>
  <div class="theme-background">
    <holiday-promo-banner
      v-if="!edpLoading && isHolidayPromoEnabled"
      :podcast="podcast"
    />
    <PremiumUpsellBanner
      v-if="!edpLoading && !isHolidayPromoEnabled"
      :podcast="podcast" />
    <BannerLoading v-if="edpLoading" />
    <Banner
      v-if="!edpLoading"
      :breadcrumb-trail="breadcrumbTrail"
      :show-breadcrumb="!!podcast.categoryName"
      :show-data="episode_legacy_formatted_object"
      type="episode" />
    <Hero
      v-if="!edpLoading"
      :episode="episode"
      :podcast="podcast"
      :user-bookmarks="userBookmarks"
      :show-data="podcast_legacy_formatted_object" />
    <HeroLoading v-if="edpLoading" />
    <FullCollectionCta
      v-if="!edpLoading"
      :podcast="podcast" />
    <ListenFree
      v-if="!edpLoading"
      :free-episodes="freeEpisodes"
      :podcast="podcast" />
    <WidgetWrapper
      v-if="useCmsWidgets"
      :num-loading-widgets="numLoadingWidgets"
      cms-page="sdp" />
  </div>
</template>

<script>
import * as log from 'loglevel';
import { mapGetters } from 'vuex';
// services
import Podcast from '../../services/Podcast';
import Episode from '../../services/Episode';
import Event from '../../services/PlayerEvent';
import User from '../../services/User';
// components
import PremiumUpsellBanner from '../../components/webv2/shared/PremiumUpsellBanner';
import HolidayPromoBanner from '../../components/webv2/shared/HolidayPromoBanner';
import Banner from '../../components/webv2/shared/Banner';
import BannerLoading from '../../components/webv2/shared/BannerLoading';
import FullCollectionCta from '../../components/webv2/shared/FullCollectionCta';
import ListenFree from '../../components/webv2/showDetails/ListenFree';
import WidgetWrapper from '../../components/widgets/WidgetWrapper';
import Hero from '../../components/webv2/episodeDetails/Hero';
import HeroLoading from '../../components/webv2/episodeDetails/HeroLoading';
// helpers
import showDetailsHelper from '../../components/webv2/showDetails/showDetailsHelper';

export default {
  name: 'Webv2EpisodeDetails',
  components: {
    PremiumUpsellBanner,
    HolidayPromoBanner,
    Banner,
    BannerLoading,
    FullCollectionCta,
    ListenFree,
    WidgetWrapper,
    Hero,
    HeroLoading,
  },
  data() {
    return {
      podcast_service: new Podcast(),
      episode_service: new Episode(),
      eventProvider: new Event(),
      user_service: new User(),
      podcast: {},
      podcast_legacy_formatted_object: {},
      loadingPodcast: true,
      episode: {},
      episode_legacy_formatted_object: {},
      loadingEpisode: true,
      episodes: [],
      loadingEpsiodes: true,
      freeEpisodes: [],
      userBookmarks: [],
      loadingUserBookmarks: true,
      numLoadingWidgets: 1,
    };
  },
  computed: {
    ...mapGetters([
      'isHolidayPromoEnabled',
    ]),
    edpLoading() {
      return this.loadingPodcast || this.loadingEpisode || this.loadingEpisodes;
    },
    episodeUUID() {
      return this.$route.params.episodeID;
    },
    breadcrumbTrail() {
      return [
        {
          name: this.podcast.categoryName,
          link: `/listen/category/${this.convertToSlug(this.podcast.categoryName)}/${this.podcast.categoryId}`,
        },
        {
          name: this.episode.showTitle,
          link: `/listen/${this.convertToSlug(this.podcast.publisherName)}/${this.convertToSlug(this.podcast.categoryName)}/${this.episode.showId}`,
        },
        {
          name: this.episode.title,
        },
      ];
    },
    useCmsWidgets() {
      const cms_widgets = this.$store.getters.flagWithDefault('cms_widgets', true);
      return cms_widgets;
    },
    userPremium() {
      return this.userSubscriptionState === 'premium' || this.userSubscriptionState === 'chrunedPremium';
    },
    getSortOrder() {
      if (this.userPremium) {
        return 'release_desc';
      }
      return 'free';
    },
  },
  beforeMount() {
    this.getEpisodeDetails();
    this.getUserBookmarks();
  },
  methods: {
    async getPodcastDetails(podcastId) {
      try {
        this.podcast = await this.podcast_service.getPodcastDetailsV2(podcastId);
        this.podcast_legacy_formatted_object = showDetailsHelper.formatLegacyPodcastObject(this.podcast);
        if (this.podcast.isExclusive) {
          this.setMetaInformation(
            `${showDetailsHelper.getPodcastMetaTitle(this.podcast.title, this.podcast.mediaType)}: ${this.episode.title} | Luminary Original`,
            this.$options.filters.truncate(this.$sanitize(this.episode.description), 160),
          );
        } else {
          this.setMetaInformation(
            `${showDetailsHelper.getPodcastMetaTitle(this.podcast.title, this.podcast.mediaType)}: ${this.episode.title} | Luminary`,
            this.$options.filters.truncate(this.$sanitize(this.episode.description), 160),
          );
        }
      } catch (err) {
        log.error(err);
      }
      this.loadingPodcast = false;
    },
    async getPodcastEpisodes(podcastId) {
      const params = { size: 10, sortOrder: this.getSortOrder };
      try {
        const episodesResponse = await this.podcast_service.getPodcastEpisodesV2(podcastId, params);
        this.episodes = episodesResponse.episodes;
        this.freeEpisodes = this.constructFreeEpisodes(this.episodes);
      } catch (err) {
        log.error(err);
      }
      this.loadingEpisodes = false;
    },
    async getEpisodeDetails() {
      try {
        this.episode = await this.episode_service.getEpisodeDetailsV2(this.episodeUUID);
        this.episode_legacy_formatted_object = showDetailsHelper.formatLegacyEpisodeObject(this.episode);
        this.eventProvider.triggerEpisodesDetailsViewedEvent(this.episode_legacy_formatted_object);
        // NR log success here
        this.getPodcastDetails(this.episode.showId);
        this.getPodcastEpisodes(this.episode.showId);
      } catch (err) {
        log.error(err);
        // NR log faliure here
      }
      this.loadingEpisode = false;
    },
    async getUserBookmarks() {
      const { user } = this.$store.getters;

      // -- jtabach: This endpoint is not backwards compatible. Can only use once all v2 bookmark endpoints are available --

      // if (user) { // check if user to avoid 401 response for anon user
      //   try {
      //     this.userBookmarks = await this.user_service.getUserBookmarksV2();
      //     console.log(this.userBookmarks);
      //   } catch (err) {
      //     log.error(err);
      //   }
      // }
      // this.loadingUserBookmarks = false;

      if (user) { // check if user to avoid 401 response for anon user
        try {
          this.userBookmarks = await this.episode_service.getUserLibraryEpisodeIds();
        } catch (err) {
          log.error(err);
        }
      }
      this.loadingUserBookmarks = false;
    },
    constructFreeEpisodes(episodes) {
      if (this.userPremium) {
        return [];
      }

      let freeEpisodeCount = 0;
      return episodes.filter((episode) => {
        const isFreeAndExclusive = episode.isFree && episode.isShowExclusive && episode.type !== 'trailer';
        if (isFreeAndExclusive && freeEpisodeCount < 2 && this.episode.id !== episode.id) {
          freeEpisodeCount++;
          return true;
        }
        return false;
      });
    },
  },
};
</script>

<style>

</style>
