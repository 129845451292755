import { initializeApp } from 'firebase/app';
import {
  getAuth,
  onAuthStateChanged,
  FacebookAuthProvider,
  GoogleAuthProvider,
  OAuthProvider,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
  confirmPasswordReset,
  EmailAuthProvider,
  signOut,
  signInWithPopup,
} from 'firebase/auth';

const firebaseApp = initializeApp({
  apiKey: process.env.FIREBASE_API_KEY,
  authDomain: process.env.FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.FIREBASE_DB_URL,
  projectId: process.env.FIREBASE_PROJECT_ID,
  storageBucket: process.env.FIREBASE_STORAGE_BUCKET,
});

let instance = null;

export default () => {
  if (instance) return instance;
  const auth = getAuth(firebaseApp);
  const fbProvider = new FacebookAuthProvider();
  fbProvider.setCustomParameters({
    auth_type: 'rerequest',
  });
  const googleProvider = new GoogleAuthProvider();
  const appleProvider = new OAuthProvider('apple.com');
  instance = {
    auth: () => auth,
    onAuthStateChanged: cb => onAuthStateChanged(auth, cb),
    createUserWithEmailAndPassword: (email, password) => createUserWithEmailAndPassword(auth, email, password),
    signInWithEmailAndPassword: (email, password) => signInWithEmailAndPassword(auth, email, password),
    sendPasswordResetEmail: (email, actionCodeSettings) => sendPasswordResetEmail(auth, email, actionCodeSettings),
    confirmPasswordReset: (actionCode, newPassword) => confirmPasswordReset(auth, actionCode, newPassword),
    fbProvider,
    googleProvider,
    appleProvider,
    EmailAuthProvider,
    signOut: () => signOut(auth),
    signInWithPopup: provider => signInWithPopup(auth, provider),
  };
  return instance;
};
