<template>
  <el-dialog
    :visible.sync="showModal"
    :append-to-body="true"
    class="promo-offer-modal"
    @close="close"
  >
    <el-row
      type="flex"
      class="flex-wrap">
      <el-col
        :sm="7"
        :xs="24"
        class="hero">
        <img
          class="hidden-xs-only"
          src="../assets/images/DesktopModalSidePanel.png">
        <img
          class="hidden-sm-and-up"
          src="../assets/images/MobileModalTopPanel.png">
      </el-col>
      <el-col
        :sm="17"
        :xs="24"
        class="content">
        <h2 class="promo-offer-modal__title">{{ promoMeta.header }}</h2>
        <div class="promo-offer-modal__description">
          <p
            v-html="monthlyCostDescription" />
          <p>for unlimited access to Luminary Originals.</p>
        </div>
        <el-row
          type="flex"
          class="flex-wrap"
          justify="space-between">
          <el-button
            class="promo-offer-modal__explore bg-transparent"
            type="primary"
            @click="close"
          >
            Continue Exploring
          </el-button>
          <el-button
            class="promo-offer-modal__explore"
            type="primary"
            @click="signup"
          >
            Start free trial
          </el-button>
        </el-row>
      </el-col>
    </el-row>
  </el-dialog>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    promoData: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    showModal: {
      get() {
        return this.show;
      },
      set() {

      },
    },
    isNewAcquistionFlow() {
      return this.$store.getters.flagWithDefault('acquisition_flow', false);
    },
    monthlyCostDescription() {
      const { promoCode, trialPeriod } = this.promoMeta;
      let trialText = ' after 7-day free trial';
      if (promoCode) {
        trialText = `<span class="strike-through">7-day free trial</span><br class="hidden-sm-and-up" /><span class="extended-period">${trialPeriod}-month free trial</span>`;
      }
      return trialText;
    },
  },
  methods: {
    close() {
      this.$emit('closePromoOfferModal');
    },
    signup() {
      this.$emit('closePromoOfferModal', true, this.isNewAcquistionFlow);
    },
  },
};
</script>

<style lang="scss">
@import "../assets/scss/variables.scss";

.promo-offer-modal {
  .el-dialog {
    background-color: $dark-background;
    text-align: center;
    padding: 0px;
    max-width: 713px;
    margin-top: 0 !important;
    display: inline-block;
    width: 713px;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;

    &__body {
      padding: 0;
    }

    &__header {
      padding: 0;
    }
    @media (max-width: 567px) {
      width: 95%;
    }
  }
  .content {
    padding: 81px 60px 40px 42px;
    text-align: left;
    @media (max-width: 567px) {
      padding: 25px 39px;
    }
  }

  &__title {
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 36px;
    letter-spacing: -0.5px;
    color: #ffffff;
    margin: 0;
    @media (max-width: 567px) {
      font-size: 24px;
      line-height: 30px;
      text-align: center;
    }
  }

  &__description {
    margin: 30px 0 45px;
    box-sizing: border-box;
    > p {
      color: #c4c4c4;
      font-size: 21px;
      line-height: 27px;
      @media (max-width: 567px) {
        font-size: 18px;
        line-height: 31px;
        text-align: center;
      }
    }
  }

  &__explore.el-button {
    padding: 15px 30px;
    font-size: 16px;
    width: 196px;
    &.bg-transparent {
      color: $blue;
    }
    @media (max-width: 567px) {
      width: 100%;
      margin: 8px 0 !important;
    }
  }
  .el-icon {
    color: #ffffff !important;
    font-size: 24px !important;
  }
  .extended-period {
    font-size: 21px;
    color: #ffffff !important;
    @media (max-width: 567px) {
      color: $blue !important;
    }
  }
  .strike-through {
    @media (max-width: 567px) {
      color: #ffffff !important;
      opacity: 1;
    }
  }
  .el-dialog__header {
    position: absolute;
    right: 0;
    padding: 30px;
    z-index: 2;
    .el-dialog__headerbtn {
      @media (max-width: 567px) {
        background: $dark-background;
        color: #ffffff;
        font-size: 18px;
        font-weight: bold;
        padding: 4px;
        z-index: 2;
        border-radius: 50%;
      }
    }
  }
  .hero img {
    width: 218px;
    @media (max-width: 567px) {
      width: 100%;
    }
  }
}
</style>
