<template>
  <div v-if="isLoading">
    <div
      v-loading.fullscreen.lock="isLoading"
      v-show="isLoading"
      element-loading-text="Please wait..."
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(0, 0, 0, 0.8)"
    >Loading</div>
  </div>
</template>
<script>
import {
  mapGetters,
  mapActions,
  mapMutations,
  mapState,
} from 'vuex';
import SubscriptionSuccessDialog from '@/components/subscription/SubscriptionSuccessDialog';
import SubscriptionMixin from '@/shared/subscriptionMixin';

// global constant, also used (and defined) in index.html
const { LS_KEY } = window.Luminary;

export default {
  components: {
    SubscriptionSuccessDialog,
  },
  mixins: [SubscriptionMixin],
  data() {
    return {
      isLoading: false,
    };
  },
  computed: {
    ...mapState({
      subscriptionLoadingState: state => state.subscription.subscriptionLoadingState,
      subscriptionStep2Error: state => state.subscription.subscriptionStep2Error,
      step2PaymentResult: state => state.subscription.step2PaymentResult,
    }),
    ...mapGetters([
      'userSubscription',
    ]),
    ...mapGetters('payment', [
      'lastParams',
      'lastMethod',
      'lastResponseProcessor',
      'redirectResult',
    ]),
    isFromRedirect() {
      return !!this.$route.query.redirectResult;
    },
    isFail() {
      return this.subscriptionStep2Error && this.subscriptionStep2Error.code === 500;
    },
    isAlreadyExisting() {
      return this.subscriptionStep2Error && this.subscriptionStep2Error.code === 403;
    },
    dialogHeader() {
      if (this.isFail) return 'Payment is not done';
      if (this.isAlreadyExisting) return 'Already done';
      return 'Your payment is being processed';
    },
    dialogText() {
      return this.subscriptionStep2Error && this.subscriptionStep2Error.message;
    },
    dialogButton() {
      return 'Check again';
    },
  },
  watch: {
    $route() {
      this.checkRedirectResult();
    },
  },
  mounted() {
    this.checkRedirectResult();
  },
  methods: {
    ...mapActions([
      'loadUserSubscription',
      'makeStep2Payment',
    ]),
    ...mapMutations('payment', [
      'resetPayment',
      'setRedirectResult',
    ]),
    ...mapMutations([
      'setSubscriptionStep2Error',
    ]),
    checkRedirectResult() {
      if (this.isFromRedirect) {
        const { redirectResult, proc, ...query } = this.$route.query;
        // decode and pass redirectResult to the backend, wait for the response
        const decoded = decodeURIComponent(this.$route.query.redirectResult);
        this.setRedirectResult(decoded);
        this.$router.replace({ path: this.$route.path, query });
      } else if (this.redirectResult) {
        this.getPaymentResult(this.lastMethod, {
          ...this.lastParams,
          paymentMethod: {
            redirectResult: this.redirectResult,
          },
        });
      } else if (window.localStorage.getItem(LS_KEY)) {
        const redirectResult = window.localStorage.getItem(LS_KEY);
        window.localStorage.removeItem(LS_KEY);
        this.getPaymentResult(this.lastMethod, {
          ...this.lastParams,
          paymentMethod: {
            redirectResult,
          },
        });
      }
    },
    getPaymentResult(method, data) {
      this.isLoading = true;
      const responseProcessor = this.lastResponseProcessor;
      this.resetPayment();
      this.makeStep2Payment({ method, data }).then(() => {
        // this.showDialog = !!this.subscriptionStep2Error && this.isFromRedirect && !this.isLoading;
        // this.showSubscriptionSuccess = this.userSubscription && this.isFromRedirect && !this.isLoading && !this.showDialog;
        if (this.subscriptionStep2Error) {
          this.showErrorMsg(this.dialogText);
        } else if (responseProcessor && this.step2PaymentResult) {
          this[responseProcessor](this.step2PaymentResult);
          if (method === 'createDropInSubscription') {
            this.subscribeSuccessAction();
          }
        } else {
          this.showSuccessMsg('Done');
        }
      }).finally(() => {
        this.isLoading = false;
      });
    },
    redirectBack() {
      this.$router.push(`${this.$route.path}`);
    },
    onClickSuccess() {
      this.redirectBack();
    },
    onAlertClose() {
      this.redirectBack();
    },
    onDialogAction() {
      this.checkRedirectResult();
    },
    subscribeSuccessAction() {
      const preservePaths = [
        '/account/subscription',
        '/signup/billing',
      ];
      this.trackSteps({ step: 'stepThree', flowType: 'redirect' });
      const path = preservePaths.includes(this.$route.path) ? this.$route.path : '/shows';
      this.$router.push({ path, query: { newSubscription: true } });
    },
  },
};
</script>
<style lang="scss" scoped>
@import '../../assets/scss/variables.scss';

.payment-secondary-dialog {
  & /deep/ .el-dialog {
    color: #ffffff;
    background-color: $dark-background;
    max-width: 562px;
    width: 100%;
    .el-dialog__close.el-icon {
      color: #ffffff;
      font-size: 24px;
    }
    .el-dialog__body {
      color: #ffffff;
      text-align: center;
    }
  }
  &__content {
    .header {
      font-size: 28px;
      font-family: $clear-sans;
      font-weight: bold;
      line-height: 36px;
      text-align: center;
    }
    p {
      color: #ffffff;
    }
    .el-button {
      margin-top: 32px;
    }
  }
}
</style>
