<template>
  <div class="payment">
    <payment-info-adyen
      v-if="preloadEnabled && isAdyenDropInEnabled"
      :acquisition-flow="true"
      :gifting-flow="true"
      :show-text="false"
      :default-first-name="presetFirstName"
      :default-last-name="presetLastName"
      heading=""
      @success="successAction"/>
    <add-payment-info
      v-if="preloadEnabled && !isAdyenDropInEnabled"
      :acquisition-flow="true"
      :gifting-flow="true"
      :show-text="false"
      heading=""
      @success="successAction"/>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import UpdatePaymentInfo from '../../../subscription/UpdatePaymentInfo';
import PaymentInfoAdyen from '../../../subscription/PaymentInfoAdyen';

export default {
  components: {
    'add-payment-info': UpdatePaymentInfo,
    'payment-info-adyen': PaymentInfoAdyen,
  },
  props: {
    index: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      preloadEnabled: false,
      postLoad: false,
      loadCsfAttempts: 25,
      csfAttemptCounter: 0,
    };
  },
  computed: {
    ...mapGetters([
      'isAdyenDropInEnabled',
    ]),
    presetFirstName() {
      return this.$store.getters.accordionSections[0].formData.firstName;
    },
    presetLastName() {
      return this.$store.getters.accordionSections[0].formData.lastName;
    },
  },
  beforeMount() {
    this.enablePaymentForm();
  },
  inject: ['setDropin'],
  methods: {
    successAction(dropin, paymentInfo) {
      this.$store.dispatch('saveAccordionSectionData', {
        formData: paymentInfo,
        index: this.index,
      });
      this.setDropin(dropin);
    },
    enablePaymentForm() {
      // This plan assignment and dispatch is not used by the purchase gift flow.
      // It is only here to keep the billing modal from erroring.
      const plan = {
        currency: 'USD',
        marketing_price: 2.99,
        marketing_price_str: '$2.99',
        price: 34.99,
        id: '2c92c0f96ed4a563016ed50a266b519d',
        name: 'Yearly Premium Subscription - USA',
        displayName: 'Annual',
        displayPrice: '$34.99',
        breakdownPrice: '$2.99',
        isBestValue: true,
        billingFrequency: 'annually',
        displayPaymentPeriod: '12 months',
        planTypeString: 'yearly',
        trialPeriodForDisplay: '7 days',
        trialPeriodDays: 7,
        paidPeriod: 12,
      };
      this.$store.dispatch('selectPricingPlan', plan);
      if (plan) {
        this.$store.dispatch('deselectFreePlan');
      }
      if (this.isAdyenDropInEnabled) {
        this.preloadEnabled = true;
      } else {
        // this setTimeout is a hack bc the form is trying to render before csf is loaded on the window
        this.checkForCsf();
      }
    },
    // adyen docs are buried bc we are using 1.5 (old version).
    // No known hook for when csf has loaded on the window.
    checkForCsf() {
      setTimeout(() => {
        if (this.csfAttemptCounter < this.loadCsfAttempts) {
          if (window && window.csf) {
            this.preloadEnabled = true;
          } else {
            this.csfAttemptCounter++;
            this.checkForCsf();
          }
        }
      }, 200);
    },
  },
};

</script>

<style lang="scss" scoped>
@import '../../../../assets/scss/variables.scss';

.payment {
  max-width: 480px;
  width: 100%;
  margin: 0 auto;
}

</style>
