import uuidv1 from 'uuid/v1';

const userIdKey = 'user';
const requestIdKey = 'Root';

export default {

  getAccountType() {
    const { user, userSubscription } = window.$store.getters;
    let status = '';
    if (!user || (user && !user.uuid)) {
      status = 'free_logged_out';
    } else if (userSubscription && userSubscription.status === 'PREMIUM') {
      status = 'premium';
    } else {
      status = 'free';
    }
    return status;
  },

  getUserUUID() {
    const registeredUuid = (window && window.$store && window.$store.getters.userUuid) || null;
    let segmentAnonId = (window && window.$store && window.$store.getters.segmentAnonId) || null;
    if (!segmentAnonId && window.analytics && window.analytics.user) {
      segmentAnonId = window.analytics.user().anonymousId();
    }
    const userId = registeredUuid || segmentAnonId;
    return userId;
  },

  getRequestId() {
    return uuidv1();
  },

  generateAmazonTraceId() {
    const userId = this.getUserUUID();
    const userIdValue = userId ? `${userIdKey}=${userId};` : '';
    const reqId = this.getRequestId();
    const requestIdValue = reqId ? `${requestIdKey}=${reqId};` : '';
    return `${userIdValue}${requestIdValue}`;
  },

  getTimezone() {
    let date = (new Date()).toString();
    date = date.split(' ');

    let timezone = date.find(item => item.includes('GMT'));
    const timezoneOffset = timezone.split('GMT')[1];
    timezone = `${timezoneOffset.substring(0, timezoneOffset.length - 2)}:${timezoneOffset.substring(timezoneOffset.length - 2, timezoneOffset.length)}`;
    return timezone;
  },

  getRFC3339TimeStamp(dateNow) {
    const pad = (x, n) => (Array(n).join('0') + String(x)).split('').slice(-n).join('');
    let d = dateNow || new Date();
    let tzo = 0;
    const r = /([-+]\d+)($|\s*\(\S+\)$)/.exec(d);
    if (r) {
      tzo = Number(r[1]);
      tzo = -(Math.floor(tzo / 100) * 60 + (tzo % 100));
    }
    if (typeof d === 'string') d = new Date(d);
    if (tzo) {
      d = new Date(d.valueOf() - 1000 * (tzo - d.getTimezoneOffset()) * 60);
    } else {
      tzo = d.getTimezoneOffset();
    }
    const month = pad(d.getMonth() + 1, 2);
    const date = pad(d.getDate(), 2);
    const ymd = [d.getFullYear(), month, date].join('-');
    const h = pad(d.getHours(), 2);
    const m = pad(d.getMinutes(), 2);
    const s = pad(d.getSeconds(), 2);
    const hms = [h, m, s].join(':');
    const tzs = tzo > 0 ? '-' : '+';
    const tzh = tzs + pad(Math.floor(Math.abs(tzo) / 60), 2);
    const tzm = pad(Math.abs(tzo) % 60, 2);
    return `${ymd}T${hms}${[tzh, tzm].join(':')}`;
  },

  removeEmpty(obj) {
    Object.keys(obj).forEach(key => (obj[key] == null) && delete obj[key]);
    return obj;
  },

  getQueryParamValue(query) {
    let locationSearch = window.location.search;
    const paramsObj = {};
    locationSearch = locationSearch.split('?');
    if (locationSearch.length > 1) {
      let searchParams = locationSearch[1];
      searchParams = searchParams.split('&');


      for (let i = 0; i < searchParams.length; i++) {
        const param = searchParams[i].split('=');
        const key = param[0];
        const value = param[1];
        paramsObj[key] = value;
      }
    }
    return paramsObj[query];
  },

  annualPlanFlag() {
    return window.$store && window.$store.getters.flagWithDefault('annual_plan', true);
  },

  isElementVisible(el) {
    const viewportHeight = document.body.offsetHeight;
    const rect = el.getBoundingClientRect();
    const elementPos = rect.top;
    const elementHeight = el.offsetHeight;
    const threshold = (elementHeight / 3); // part of element within viewport
    const isVisible = el.clientHeight > 0 && elementPos >= (-1 * threshold) && (elementPos + threshold) <= viewportHeight;
    return isVisible;
  },

  getDiffInMonths(d1, d2) {
    let dt1 = d1;
    let dt2 = d2;
    if (d1 < d2) {
      dt1 = d2;
      dt2 = d1;
    }
    let months = (dt1.getFullYear() - dt2.getFullYear()) * 12 + (dt1.getMonth() - dt2.getMonth());
    if (dt1.getDate() < dt2.getDate()) --months;
    return months;
  },

  getDiffInDays(d1, d2) {
    const t2 = d2.getTime();
    const t1 = d1.getTime();

    return parseInt((t2 - t1) / (24 * 3600 * 1000), 10);
  },

  isMobile() {
    const isMobileUserAgent = /Mobi/i.test(window.navigator.userAgent);
    const isDesktopScreenSize = window && window.innerWidth > 768;
    return isMobileUserAgent || !isDesktopScreenSize;
  },
  jsonSize(value) {
    try {
      const utf8Length = s => ~-encodeURI(s).split(/%..|./).length; // eslint-disable-line no-bitwise
      return utf8Length(JSON.stringify(value));
    } catch (err) {
      console.log(err); //eslint-disable-line
      return null;
    }
  },
  kebabCase: string => string
    .replace(/[^a-zA-Z\s_\-0-9]/g, '')
    .replace(/([a-z])([A-Z])/g, '$1-$2')
    .replace(/[\s_]+/g, '-')
    .replace(/[-]+/g, '-')
    .toLowerCase(),

  /**
   * make an arc
   * @param {number} startAngle   - The starting angle in radians
   * @param {number} stopAngle    - The final angle in radians
   * @param {number} radius       - Radius of the arc
   * @param {number} numPoints    - Amount of points in the arc, determines how granular will be the line
   * @return {Array} Array of a points.
   */
  makeArc: (startAngle, stopAngle, radius, numPoints) => {
    const step = (stopAngle - startAngle) / numPoints;
    const res = [];
    for (let i = 0; i <= numPoints; i++) {
      const angle = startAngle + step * i;
      const x = radius * Math.cos(angle);
      const y = radius * Math.sin(angle);
      res.push({ x, y });
    }
    return res;
  },
};
