import isNull from 'lodash/isNull';
import merge from 'lodash/merge';
import isUndefined from 'lodash/isUndefined';
import defaultGlobalConfig from '../../config/globalConfig';
import featuresConfig from '@/config/featuresConfig';

const getters = {
  featureFlag: state => (featureFlag) => {
    const flagValue = state.featureFlags[featureFlag];
    return (isNull(flagValue) || isUndefined(flagValue)) ? null : flagValue;
  },
  featureFlags: state => state.featureFlags,
  flagWithDefault: state => (featureFlag, defaultValue) => {
    const { featureFlags } = state;
    const flagValue = featureFlag in featureFlags ? featureFlags[featureFlag] : defaultValue;
    return flagValue;
  },
  featureVariable: state => (...keys) => keys.reduce((dict, k) => (dict && k in dict ? dict[k] : undefined), state.featureVariables),
  featureFlagsLastUpdated: state => state.lastUpdated,
  manualDatafileJson: state => state.manualDatafileJson,
  manualDisableOptimizely: state => state.manualDisableOptimizely,
  optimizelyDebugMode: state => state.optimizelyDebugMode,
  globalConfig: state => state.globalConfig,
  sessionFallbackFlags: state => state.sessionFallbackFlags,
  isAdyenDropInEnabled: state => state.featureFlags.adyen_drop_in,
};

const mutations = {
  updateFeatures: (state, updatedFeatureFlags) => {
    state.featureFlags = updatedFeatureFlags;
  },
  updateConfig: (state, updatedGlobalConfig) => {
    state.globalConfig = merge(state.globalConfig, updatedGlobalConfig);
  },
  setLastUpdated: (state) => {
    state.lastUpdated = Date.now();
  },
  setManualDatafileJson: (state, DatafileJson) => {
    state.manualDatafileJson = DatafileJson;
  },
  disableOptimizely: (state, bool) => {
    state.manualDisableOptimizely = bool;
  },
  toggleOptimizelyDebugMode: (state, bool) => {
    state.optimizelyDebugMode = bool;
  },
  setSessionFallbackFlags: (state, flagName) => {
    if (!state.sessionFallbackFlags.includes(flagName)) {
      state.sessionFallbackFlags = state.sessionFallbackFlags.concat([flagName]);
    }
  },
};

// DO NOT CHANGE FEATURE FLAGS UNLESS YOU'RE AWARE OF THE PROCESS
// https://www.notion.so/luminarymedia/Dev-Process-for-Feature-Flags-a9d35d529f6d4b79adf9c7daaf142538

const state = {
  featureFlags: {
    ...featuresConfig.flags,
  },
  featureVariables: {
    ...featuresConfig.variables,
  },
  lastUpdated: null,
  manualDatafileJson: null,
  manualDisableOptimizely: false,
  optimizelyDebugMode: false,
  globalConfig: defaultGlobalConfig,
  sessionFallbackFlags: [],
};

const actions = {
  updateFeatureFlags: (context, updatedFeatureFlags) => {
    context.commit('updateFeatures', updatedFeatureFlags);
    context.commit('setLastUpdated');
  },
  updateGlobalConfig: (context, updatedGlobalConfig) => {
    context.commit('updateConfig', updatedGlobalConfig);
  },
  // THESE ACTIONS ARE FOR DEV-PANEL PURPOSE ONLY
  setManualDatafile: (context, dataFileUrl) => {
    context.commit('setManualDatafileJson', dataFileUrl);
  },
  disableOptimizely: (context, bool) => {
    context.commit('disableOptimizely', bool);
  },
  toggleOptimizelyDebug: (context, bool) => {
    context.commit('toggleOptimizelyDebugMode', bool);
  },
  addFallbackFlag: (context, flagName) => {
    context.commit('setSessionFallbackFlags', flagName);
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
