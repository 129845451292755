<template>
  <div
    class="update-payment-info"
    data-test="page-updatepremium">
    <h2 class="m-b t-c font-normal heading">
      {{ heading }}
    </h2>
    <div v-if="showText">
      <div v-if="annualPlanEnabled && plansLoaded">
        <div :class="{'plan-description': !acquisitionFlow, 'acquisition-plan-description': acquisitionFlow}">
          <p
            class="m-b"
            v-html="billingInstruction"/>
          <p v-html="billingDescription"/>
        </div>
      </div>
      <div v-else>
        <div
          v-if="!isSubscribed || isCanceled"
          class="sub-head t-c">
          <div v-if="!isSubscribed && !isCanceled">Enter your billing information to start your free trial</div>
          <br>
          <div v-if="showPromoOnBilling">For a limited time, <span>Luminary Premium is only {{ promoDiscount.asStringRecurring }} </span>{{ defaultPrice.price | taxFilter(defaultPrice.currency) }} for your first 12 months when you sign up by {{ promoEndDate }}. Cancel anytime.</div>
          <div v-else>Premium is {{ defaultPrice.price | amountFilter(defaultPrice.currency) }} {{ defaultPrice.price | taxFilter(defaultPrice.currency) }}. Cancel Anytime.</div>
        </div>
      </div>
    </div>
    <el-form
      ref="paymentForm"
      :model="paymentForm"
      :rules="paymentValidationRules"
      :class="{'form-info-acquisition': acquisitionFlow}"
      div
      label-position="top"
      @keyup.enter.native="() => {
        giftingFlow ? onSaveAndContinue() : onSubmit()
      }"
    >
      <el-row :gutter="12">
        <el-col :span="12">
          <el-form-item
            id="firstName"
            prop="firstName"
            label="First Name"
          >
            <el-input
              v-model="paymentForm.firstName"
              aria-label="First name"
              placeholder="First name"
              data-test="input-firstname"
            />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item
            id="lastName"
            prop="lastName"
            label="Last Name"
          >
            <el-input
              v-model="paymentForm.lastName"
              aria-label="Last name"
              placeholder="Last name"
              data-test="input-lastname"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <div class="cards-div">
        <div
          id="js-chckt-pm__pm-holder"
          class="js-chckt-pm__pm-holder">
          <input
            type="hidden"
            name="txvariant"
            value="card"
          >
          <el-row :gutter="12">
            <el-col
              :md="15"
              :sm="14"
              :xs="16">
              <el-form-item
                label="Credit Card #"
              >
                <div class="custom-card-number d-flex flex-middle-align">
                  <div>
                    <label>
                      <span
                        id="encryptedCardNumberSpan"
                        class="input-field"
                        data-cse="encryptedCardNumber"
                        data-test="input-creditcard"
                      />
                    </label>
                  </div>
                  <div class="card-brand"><img :src="cardIcon"></div>
                </div>
              </el-form-item>
            </el-col>
            <el-col
              :md="9"
              :sm="10"
              :xs="8">
              <el-form-item
                label="Exp. Date"
                class="custom-exp-date"
              >
                <div>
                  <el-row
                    type="flex"
                    align="middle">
                    <el-col
                      :md="5"
                      :sm="5"
                      :xs="10">
                      <el-form-item class="exp-month">
                        <label>
                          <span
                            id="encryptedExpiryMonthSpan"
                            class="input-field"
                            data-cse="encryptedExpiryMonth"
                          />
                        </label>
                      </el-form-item>
                    </el-col>
                    <el-col
                      :span="2"
                      class="t-c"
                    ><div class="slash-container">/</div></el-col>
                    <el-col
                      :md="5"
                      :sm="5"
                      :xs="10">
                      <el-form-item class="exp-year">
                        <label>
                          <span
                            id="encryptedExpiryYearSpan"
                            class="input-field"
                            data-cse="encryptedExpiryYear"
                          />
                        </label>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="12">
            <el-col
              :md="4"
              :sm="8"
              :xs="12">
              <el-form-item
                label="CVV"
              >
                <div class="custom-cvv">
                  <label>
                    <span
                      class="input-field"
                      data-cse="encryptedSecurityCode"
                    />
                  </label>
                </div>
              </el-form-item>
            </el-col>
            <el-col
              :md="10"
              :sm="8"
              :xs="12">
              <el-form-item
                id="postalCode"
                :label="zipCodeLabel"
                prop="postalCode"
                label-position="top"
              >
                <el-input
                  v-model="paymentForm.postalCode"
                  :placeholder="postalCodePlaceholder"
                  aria-label="Zip code"
                  data-test="input-zipcode"
                />
              </el-form-item>
            </el-col>
            <el-col
              v-show="showState"
              :md="10"
              :sm="8"
              :xs="0">
              <el-form-item
                id="state"
                :label="stateLabel"
                prop="state"
                label-position="top">
                <el-select
                  :popper-append-to-body="false"
                  v-model="paymentForm.state"
                  :placeholder="statePlaceholder"
                  no-match-text="Not found"
                  filterable
                  data-input="select-state"
                  @keyup.native.enter="preventSubmit">
                  <el-option
                    v-for="(state, index) in states"
                    :key="index"
                    :label="state.name"
                    :value="state.code"
                    data-test="option-state"/>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col
              v-if="!showState"
              :md="10"
              :sm="8"
              :xs="12"
            >
              <el-form-item
                id="country"
                prop="country"
                label="Country"
                label-position="top">
                <el-select
                  ref="countryDD1"
                  :popper-append-to-body="false"
                  v-model="paymentForm.country"
                  placeholder="Select country"
                  no-match-text="Not found"
                  filterable
                  data-test="select-country"
                  @change="onCountryChange"
                  @keyup.native.enter="preventSubmit">
                  <el-option
                    v-for="(country, index) in countries"
                    :key="index"
                    :label="country.name"
                    :value="country.code"
                    data-test="option-country" />
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col
              v-if="showState"
              :md="0"
              :sm="0"
              :xs="12"
            >
              <el-form-item
                id="state"
                :label="stateLabel"
                prop="state"
                label-position="top">
                <el-select
                  :popper-append-to-body="false"
                  v-model="paymentForm.state"
                  :placeholder="statePlaceholder"
                  no-match-text="Not found"
                  filterable
                  @keyup.native.enter="preventSubmit">
                  <el-option
                    v-for="(state, index) in states"
                    :key="index"
                    :label="state.name"
                    :value="state.code" />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col
              v-show="showState"
              :md="9"
              :sm="9"
              :xs="12">
              <el-form-item
                id="country"
                prop="country"
                label="Country"
                label-position="top">
                <el-select
                  ref="countryDD2"
                  :popper-append-to-body="false"
                  v-model="paymentForm.country"
                  placeholder="Select country"
                  no-match-text="Not found"
                  filterable
                  data-test="select-country"
                  @change="onCountryChange"
                  @keyup.native.enter="preventSubmit">
                  <el-option
                    v-for="(country, index) in countries"
                    :key="index"
                    :label="country.name"
                    :value="country.code"
                    data-test="option-country" />
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
      </div>
    </el-form>
    <el-alert
      v-if="zuoraErrorMessage"
      :title="zuoraErrorMessage"
      type="error"
      class="m-b"
      show-icon
    />
    <div v-if="showText">
      <div v-if="!annualPlanEnabled && showPromoOnBilling">
        <p
          v-if="isNewSubscription"
          class="legal-terms">
          <span v-if="!isSubscribed && !isCanceled">By clicking "Subscribe" you are signing up for a free <span
            v-if="trailMonths > 1"
            class="line-through">1 month</span> {{ trailMonths }} {{ trailMonths > 1 ? 'months' : 'month' }} trial. Your trial will begin immediately. If you don’t cancel before the end of the trial period, you authorize Luminary to automatically charge your payment method {{ promoDiscount.asStringRecurring }} {{ defaultPrice.price | taxFilter(defaultPrice.currency) }} on a continuous monthly basis for the first 12 months of your subscription. After your first 12 months your monthly price will increase to {{ defaultPrice.price | amountFilter(defaultPrice.currency) }} {{ defaultPrice.price | taxFilter(defaultPrice.currency) }}  for as long as you remain a subscriber. The subscription automatically renews unless it is cancelled at least 24 hours before the end of the current period. Your account will be charged for renewal within 24 hours prior to the end of the current period.</span>
          You can cancel anytime by visiting your Account Settings. No partial refunds.
          <router-link
            v-button-event
            :to="termsLink"
            target="_blank"
            color="primary"
            data-test="link-additionalterms"
            class="font-normal t-u"
            aria-label="Additional terms apply"
          >
            Additional terms apply.
          </router-link>
        </p>
        <p
          v-if="isResubscribingAfterTrial"
          class="legal-terms"
        >
          By clicking on “subscribe” you are purchasing a monthly Luminary Premium subscription. Your subscription will begin and you will be charged immediately. You authorize Luminary to automatically charge your payment method {{ promoDiscount.asStringRecurring }} {{ defaultPrice.price | taxFilter(defaultPrice.currency) }} on a continuous monthly basis for the first 12 months of your subscription. After your first 12 months your monthly price will increase to {{ defaultPrice.price | amountFilter(defaultPrice.currency) }} {{ defaultPrice.price | taxFilter(defaultPrice.currency) }}  for as long as you remain a subscriber. The subscription automatically renews unless it is cancelled at least 24 hours before the end of the current period. Your account will be charged for renewal within 24 hours prior to the end of the current period. You can cancel anytime by visiting your account settings. No partial refunds.
          <router-link
            v-button-event
            :to="termsLink"
            target="_blank"
            color="primary"
            data-test="link-additionalterms"
            class="font-normal t-u"
            aria-label="Additional terms apply"
          >
            Additional terms apply.
          </router-link>
        </p>
        <p
          v-if="isResubscribingInTrial"
          class="legal-terms"
        >
          By clicking on “subscribe” you are purchasing a monthly Luminary Premium subscription. Your subscription will begin and you will be charged when your free trial ends. You authorize Luminary to automatically charge your payment method {{ promoDiscount.asStringRecurring }} {{ defaultPrice.price | taxFilter(defaultPrice.currency) }} on a continuous monthly basis for the first 12 months of your subscription. After your first 12 months your monthly price will increase to {{ defaultPrice.price | amountFilter(defaultPrice.currency) }} {{ defaultPrice.price | taxFilter(defaultPrice.currency) }}  for as long as you remain a subscriber. The subscription automatically renews unless it is cancelled at least 24 hours before the end of the current period. Your account will be charged for renewal within 24 hours prior to the end of the current period. You can cancel anytime by visiting your account settings. No partial refunds.
          <router-link
            v-button-event
            :to="termsLink"
            target="_blank"
            color="primary"
            data-test="link-additionalterms"
            class="font-normal t-u"
            aria-label="Additional terms apply"
          >
            Additional terms apply.
          </router-link>
        </p>
      </div>
      <div v-if="!annualPlanEnabled && !showPromoOnBilling">
        <p
          v-if="isNewSubscription"
          class="legal-terms">
          <span v-if="!isSubscribed && !isCanceled">By clicking "Subscribe" you are signing up for a free <span
            v-if="trailMonths > 1"
            class="line-through">1 month</span> {{ trailMonths }} {{ trailMonths > 1 ? 'months' : 'month' }} trial. Your trial will begin immediately. If you don’t cancel before the end of the trial period, you authorize Luminary to automatically charge your payment method {{ defaultPrice.price | amountFilter(defaultPrice.currency) }} {{ defaultPrice.price | taxFilter(defaultPrice.currency) }} on a continuous monthly basis for as long as you remain a subscriber. The subscription automatically renews unless it is cancelled at least 24 hours before the end of the current period. Your account will be charged for renewal within 24 hours prior to the end of the current period.</span>
          You can cancel anytime by visiting your Account Settings. No partial refunds.
          <router-link
            :to="termsLink"
            target="_blank"
            color="primary"
            data-test="link-additionalterms"
            class="font-normal t-u"
            aria-label="Additional terms apply"
          >
            Additional terms apply.
          </router-link>
        </p>
        <p
          v-if="isResubscribingAfterTrial"
          class="legal-terms"
        >
          By clicking on “subscribe” you are purchasing a monthly Luminary Premium subscription. Your subscription will begin and you will be charged immediately. You authorize Luminary to automatically charge your payment method {{ defaultPrice.price | amountFilter(defaultPrice.currency) }} {{ defaultPrice.price | taxFilter(defaultPrice.currency) }} on a continuous monthly basis for as long as you remain a subscriber. The subscription automatically renews unless it is cancelled at least 24 hours before the end of the current period. Your account will be charged for renewal within 24 hours prior to the end of the current period. You can cancel anytime by visiting your account settings. No partial refunds.
          <router-link
            v-button-event
            :to="termsLink"
            target="_blank"
            color="primary"
            data-test="link-additionalterms"
            class="font-normal t-u"
            aria-label="Additional terms apply"
          >
            Additional terms apply.
          </router-link>
        </p>
        <p
          v-if="isResubscribingInTrial"
          class="legal-terms"
        >
          By clicking on “subscribe” you are purchasing a monthly Luminary Premium subscription. Your subscription will begin and you will be charged when your free trial ends. You authorize Luminary to automatically charge your payment method {{ defaultPrice.price | amountFilter(defaultPrice.currency) }} {{ defaultPrice.price | taxFilter(defaultPrice.currency) }} on a continuous monthly basis for as long as you remain a subscriber. The subscription automatically renews unless it is cancelled at least 24 hours before the end of the current period. Your account will be charged for renewal within 24 hours prior to the end of the current period. You can cancel anytime by visiting your account settings. No partial refunds.
          <router-link
            v-button-event
            :to="termsLink"
            target="_blank"
            color="primary"
            data-test="link-additionalterms"
            class="font-normal t-u"
            aria-label="Additional terms apply"
          >
            Additional terms apply.
          </router-link>
        </p>
      </div>
      <div
        v-if="annualPlanEnabled"
        :class="{'legal-copy': !acquisitionFlow, 'acquisition-legal-copy': acquisitionFlow}">

        <p v-html="legalCopy"/>
        <div>
          <router-link
            v-button-event
            :to="termsLink"
            target="_blank"
            color="primary"
            data-test="link-additionalterms"
            class="font-normal t-u"
            aria-label="Additional terms apply"
          >
            Terms apply.
          </router-link>
        </div>
      </div>
    </div>
    <div class="action-controls">
      <el-button
        v-button-event
        v-loading="loading"
        v-if="!giftingFlow"
        :class="[{ 'action-button': true }, { 'annual-plan-btn': annualPlanEnabled }]"
        class="block-btn"
        type="primary"
        data-test="btn-subscribe"
        @click="onSubmit"
      >
        {{ buttonText }}
      </el-button>
      <el-button
        v-if="giftingFlow"
        :class="[{ 'action-button': true }, { 'annual-plan-btn': annualPlanEnabled }]"
        class="block-btn"
        type="primary"
        data-test="btn-subscribe"
        @click="onSaveAndContinue"
      >
        Save and Continue
      </el-button>
    </div>
    <vermont-modal
      :show="showVermontModal"
      @onAcceptVermontTerms="onAcceptVermontTerms"
    />
  </div>
</template>
<script>
import find from 'lodash/find';
import Subscription from '../../services/Subscription';
import User from '../../services/User';
import BranchService from '../../services/Branch';
import validationRules from '../../shared/validationRules';
import SubscriptionMixin from '../../shared/subscriptionMixin';
import countries from '../../assets/json/countries';
import usStates from '../../assets/json/usStates';
import caStates from '../../assets/json/caStates';
import usTerritories from '../../assets/json/usTerritories';
import VermontModal from '../VermontGoHardModal';
import ShapeProductsResponse from '../../shared/ShapeProductsResponse';

export default {
  name: 'UpdatePaymentInfoComponent',
  components: {
    'vermont-modal': VermontModal,
  },
  mixins: [SubscriptionMixin],
  props: {
    show_modal: {
      default: false,
      type: Boolean,
    },
    button_text: {
      default: null,
      type: String,
    },
    update: {
      default: false,
      type: Boolean,
    },
    heading: {
      default: '',
      type: String,
    },
    acquisitionFlow: {
      default: false,
      type: Boolean,
    },
    giftingFlow: {
      default: false,
      type: Boolean,
    },
    showText: {
      default: true,
      type: Boolean,
    },
  },
  data() {
    return {
      subscriptionProvider: new Subscription(),
      userProvider: new User(),
      branchService: new BranchService(),
      plan_id: 'ignite',
      paymentForm: {
        firstName: '',
        lastName: '',
        currency: '',
        name: '',
        cardNumber: '',
        expirationMonth: '',
        expirationYear: '',
        securityCode: '',
        postalCode: '',
        country: this.$store.getters.country || '',
        state: '',
      },
      rules: validationRules.payment,
      countries,
      caStates,
      usStates,
      activePlan: this.$store.getters.activePlan,
      loading: false,
      defaultPrice: this.$store.getters.pricing,
      showVermontModal: false,
      vermontTermsAccepted: false,
    };
  },
  computed: {
    buttonText() {
      if (this.annualPlanEnabled && !this.button_text) {
        return 'Agree & Subscribe';
      }
      if (!this.annualPlanEnabled && !this.button_text) {
        return 'Subscribe';
      }
      return this.button_text || 'Subscribe';
    },
    paymentValidationRules() {
      const paymentRules = validationRules.payment;

      if (this.paymentForm.country === 'US') {
        return {
          ...paymentRules,
          postalCode: [
            {
              required: true,
              pattern: '^[0-9]{5}$',
              message: 'Enter valid zip code.',
              trigger: 'blur',
            },
          ],
        };
      }

      if (this.paymentForm.country === 'CA') {
        return {
          ...paymentRules,
          state: [
            {
              required: true,
              message: 'Province is required.',
              trigger: 'change',
            },
          ],
          postalCode: [
            {
              required: true,
              validator: this.validatePostalCode,
              trigger: 'change',
            },
          ],
        };
      }

      if (this.paymentForm.country === 'UM') {
        return {
          ...paymentRules,
          state: [
            {
              required: true,
              message: 'State is required.',
              trigger: 'change',
            },
          ],
        };
      }

      if (this.paymentForm.country !== 'US' && this.paymentForm.country !== 'CA') {
        return {
          ...paymentRules,
          state: [
            {
              required: false,
            },
          ],
        };
      }
      return paymentRules;
    },
    userSubscription() {
      return this.$store.getters.userSubscription;
    },
    showPromoOnBilling() {
      return this.$store.getters.promoData.showPromoOnBilling;
    },
    activeProduct() {
      return this.$store.getters.activePlan;
    },
    usersDefaultCountry() {
      return this.$store.getters.country;
    },
    states() {
      let states;
      if (this.paymentForm.country === 'US') {
        states = usStates;
      } else if (this.paymentForm.country === 'CA') {
        states = caStates;
      } else if (this.paymentForm.country === 'UM') {
        states = usTerritories;
      }
      return states;
    },
    showState() {
      return this.paymentForm.country === 'US' || this.paymentForm.country === 'CA' || this.paymentForm.country === 'UM';
    },
    stateLabel() {
      let label = 'Billing State';
      if (this.paymentForm.country === 'CA') {
        label = 'Billing Province';
      }
      return label;
    },
    statePlaceholder() {
      let label = 'Select State';
      if (this.paymentForm.country === 'CA') {
        label = 'Select Province';
      }
      return label;
    },
    postalCodePlaceholder() {
      let placeholder = 'Enter postal code';
      if (this.paymentForm.country === 'US') {
        placeholder = 'Enter zip code';
      }
      return placeholder;
    },
    zipCodeLabel() {
      let label = 'Billing Postal Code';
      if (this.paymentForm.country === 'US') {
        label = 'Billing Zip Code';
      }
      return label;
    },
    isSubscribed() {
      return this.$store.getters.user && this.$store.getters.user.is_subscribed;
    },
    isCanceled() {
      return this.$store.getters.userSubscription && this.$store.getters.userSubscription.is_cancelled;
    },
    usersCountryPricing() {
      return this.$store.getters.pricing;
    },
    trailMonths() {
      let trialMonths = localStorage.getItem('promo_trial_period');
      if (this.isSubscribed) {
        trialMonths = 1;
      }
      return trialMonths || 1;
    },
    user() {
      return this.$store.getters.user;
    },
    isResubscribingAfterTrial() {
      const currentDate = new Date();
      const expireAt = new Date(this.userSubscription && this.userSubscription.expire_at ? this.userSubscription.expire_at : currentDate);
      let isPast = false;
      if (currentDate < expireAt) {
        isPast = false;
      } else {
        isPast = true;
      }
      return !this.update && this.user && !this.user.is_subscribed && this.user.is_on_boarded && this.userSubscription && this.userSubscription.status !== 'FREE TRIAL' && isPast;
    },
    isResubscribingInTrial() {
      const currentDate = new Date();
      const expireAt = new Date(this.userSubscription && this.userSubscription.expire_at ? this.userSubscription.expire_at : currentDate);
      let isPast = false;
      if (currentDate < expireAt) {
        isPast = false;
      } else {
        isPast = true;
      }
      let flag = false;
      if (this.user && this.user.is_subscribed) {
        flag = !this.update && this.user && this.user.is_subscribed && this.user.is_on_boarded && this.userSubscription && this.userSubscription.status === 'FREE TRIAL';
      } else {
        flag = !this.update && this.user && this.user.is_on_boarded && this.userSubscription && !isPast;
      }
      return flag;
    },
    isNewSubscription() {
      return !this.isSubscribed && !this.isCanceled && this.user && !this.user.is_on_boarded && !this.update;
    },
    billingDescription() {
      const { selectedPlan } = this.$store.getters;
      const { displayName, displayPrice, breakdownPrice } = selectedPlan;
      const { showTrial } = this.promoMeta;
      const annualTrialText = `After your free trial, your ${displayName} Plan will begin and you will automatically be charged <b>${displayPrice}</b> for your first year.`;
      const annualNoTrialText = `You will be charged <b>${displayPrice} (${breakdownPrice} x 12)</b> for your first year.`;
      const monthlyTrialText = `After your free trial, your ${displayName} Plan will automatically begin and you will automatically be charged <b>${displayPrice}/month</b>.  Cancel anytime.`;
      const monthlyNoTrialText = `You will automatically be charged <b>${displayPrice}/month</b>.  Cancel anytime.`;
      if (showTrial) {
        return displayName === 'Monthly' ? monthlyTrialText : annualTrialText;
      }
      return displayName === 'Monthly' ? monthlyNoTrialText : annualNoTrialText;
    },
    billingInstruction() {
      const {
        showTrial, promoCode, trialPeriod, trialEndDate,
      } = this.promoMeta;
      let withTrial = '<span>Enter your billing information to start your 7-day free trial.<span>';
      if (promoCode && promoCode !== '' && trialEndDate !== '') {
        withTrial = `<span>Enter your billing information to start your </span><span class="strike-through">7-day</span><span>${trialPeriod}-month free trial.</span><span>`;
      }
      const noTrial = '<span>Enter your billing information to start your Luminary Premium subscription.</span>';
      return showTrial ? withTrial : noTrial;
    },
    legalCopy() {
      const { selectedPlan } = this.$store.getters;
      const { displayName, displayPrice } = selectedPlan;
      const {
        showTrial, promoCode, trialPeriod, trialEndDate,
      } = this.promoMeta;
      let trialText = '7-day';
      if (promoCode && promoCode !== '' && trialEndDate !== '') {
        trialText = `${trialPeriod}-month`;
      }
      const annualCopyTrial = `<span>By clicking "Agree & Subscribe", you are signing up for a ${trialText} free trial. Your trial will begin immediately. <b>If you don’t cancel before the end of the ${trialText} trial period, you authorize Luminary to automatically charge your payment method ${displayPrice} plus applicable taxes for the first 12 months of your subscription. After your first 12 months, your subscription automatically renews annually at the then-current rate, charged to your payment method, unless you cancel at least 24 hours before the end of the current period.</b> You can cancel anytime by visiting your Account Settings. No partial refunds.</span>`;
      const annualCopyNoTrial = `<span>By clicking "Agree & Subscribe", <b>you authorize Luminary to automatically charge your payment method ${displayPrice} plus applicable taxes for the first 12 months of your subscription. After your first 12 months, your subscription automatically renews annually at the then-current rate, charged to your payment method, unless you cancel at least 24 hours before the end of the current period.</b> You can cancel anytime by visiting your Account Settings. No partial refunds.</span>`;
      const monthlyCopyTrial = `<span>By clicking "Agree & Subscribe" you are signing up for a free ${trialText} trial. Your trial will begin immediately. <b>If you don’t cancel before the end of the ${trialText} trial period, you authorize Luminary to automatically charge your payment method ${displayPrice}/month plus applicable taxes. Your subscription automatically renews monthly at the then-current rate, charged to your payment method, unless you cancel at least 24 hours before the end of the current period.</b> You can cancel anytime by visiting your Account Settings. No partial refunds.</span>`;
      const monthlyCopyNoTrial = `<span>By clicking "Agree & Subscribe" <b>you authorize Luminary to automatically charge your payment method ${displayPrice}/month plus applicable taxes. Your subscription automatically renews monthly at the then-current rate, charged to your payment method, unless you cancel at least 24 hours before the end of the current period.</b> You can cancel anytime by visiting your Account Settings. No partial refunds.</span>`;
      if (showTrial) {
        return displayName === 'Monthly' ? monthlyCopyTrial : annualCopyTrial;
      }
      return displayName === 'Monthly' ? monthlyCopyNoTrial : annualCopyNoTrial;
    },
    selectedPlan() {
      return this.$store.getters.selectedPlan;
    },
  },
  watch: {
    show_modal(newVal) {
      if (!newVal) {
        this.$refs.paymentForm.resetFields();
      }
      this.initAdyenForm();
      this.populateUserName();
      // this.paymentForm.country = this.$store.getters.country;
    },
    userSubscription(newVal) {
      this.subscription = newVal;
    },
    activeProduct(newVal) {
      if (newVal) {
        this.activePlan = newVal;
      }
    },
    usersDefaultCountry(newVal) {
      if (newVal) {
        this.paymentForm.country = newVal;
      }
    },
    usersCountryPricing(newVal) {
      if (newVal) {
        this.defaultPrice = newVal;
      }
    },
  },
  beforeMount() {
    const { pricingPlans, userSubscription, selectedPlan } = this.$store.getters;
    if (this.annualPlanEnabled && !selectedPlan && userSubscription) {
      const { type } = userSubscription && userSubscription.plan;
      const newSelectedPlan = find(pricingPlans, { planTypeString: type });
      this.$store.dispatch('selectPricingPlan', newSelectedPlan);
    }
  },
  mounted() {
    this.initAdyenForm();
    this.checkCountryIsWhiteListed();
    this.populateUserName();
    this.activePlan = this.$store.getters.activePlan;
  },
  beforeDestroy() {
    clearTimeout(this.adyenForm.loadWatcher); // Just to make sure setTimeout reference is cleared before leave
  },
  methods: {
    onAcceptVermontTerms() {
      this.vermontTermsAccepted = true;
      this.showVermontModal = false;
      this.onSubmit();
    },
    onSaveAndContinue() {
      const paymentMethod = this.validateForm();
      if (this.paymentForm.state && this.paymentForm.state === 'VT' && !this.vermontTermsAccepted && !this.giftingFlow) {
        this.showVermontModal = true;
        return;
      }
      const paymentInfo = this.formatUpdatePaymentRequestData(paymentMethod);
      if (paymentMethod) {
        this.$emit('success', paymentInfo);
      }
    },
    onSubmit() {
      const { showTrial } = this.promoMeta;
      const paymentMethod = this.validateForm();
      if (this.paymentForm.state && this.paymentForm.state === 'VT' && !this.vermontTermsAccepted && showTrial) {
        this.showVermontModal = true;
        return;
      }

      if (paymentMethod) {
        if (this.update) {
          this.updatePaymentInfo(paymentMethod);
        } else {
          this.subscribe(paymentMethod);
        }
      }
    },
    formatUpdatePaymentRequestData(paymentInfo) {
      const country = this.countries.find(nextItem => nextItem.code === this.paymentForm.country);
      let countryCode = this.paymentForm.country;
      let stateCode = this.paymentForm.state;
      if (countryCode === 'UM') {
        countryCode = stateCode;
        stateCode = '';
      }
      const requestData = {
        billToContact: {
          country: countryCode,
          firstName: this.paymentForm.firstName,
          lastName: this.paymentForm.lastName,
          postalCode: this.paymentForm.postalCode,
          state: stateCode,
        },
        currency: country.currency,
        paymentInfo,
        returnUrl: window.location.origin,
      };
      return requestData;
    },
    updatePaymentInfo(paymentInfo) {
      const subscriptionData = this.formatUpdatePaymentRequestData(paymentInfo);
      this.loading = true;
      this.subscriptionProvider.updatePaymentInfo(subscriptionData)
        .then(this.updatePaymentResponse)
        .catch((error) => {
          this.loading = false;
          this.showErrorMsg(error || 'D’oh. We’re experiencing technical difficulties. Please try again later or contact support@luminary.audio');
        });
    },
    updatePaymentResponse(resp) {
      this.loading = false;
      this.resetAdyenEncryptedCardFields();
      if (resp) {
        if (resp && resp.plan && !resp.plan.trial_end_date) {
          resp.plan.trial_end_date = this.calculateTrialEndDateLocally();
          resp.plan.trial_end_date_from_client = true;
        }
        this.$store.commit('setUserSubscription', resp);
        const { user } = this.$store.getters;
        user.is_subscribed = true;
        user.first_name = this.paymentForm.firstName;
        user.last_name = this.paymentForm.lastName;
        this.showSuccessMsg('Successfully updated payment info.');
        this.$store.commit('setUser', user);
        this.$emit('success');
      }
    },
    subscribe(paymentInfo) {
      const subscriptionData = this.formatSubscribeRequestData(paymentInfo);
      this.loading = true;
      const clickedID = this.getImpactClickId();

      // Pass ImpactRadius ClickedID if present query param
      if (clickedID) {
        const pricing = this.fetchTargetCountryPrice();
        subscriptionData.total_price = parseFloat(pricing.price);
        subscriptionData.ir_click_id = clickedID;
      }

      this.subscriptionProvider.createSubscription(subscriptionData)
        .then(this.subscriptionResponse)
        .catch((error) => {
          this.loading = false;
          this.showErrorMsg(error || 'D’oh. We’re experiencing technical difficulties. Please try again later or contact support@luminary.audio');
        });
    },
    formatSubscribeRequestData(paymentInfo) {
      // const { activePlan } = this.$store.getters;
      const country = this.countries.find(nextItem => nextItem.code === this.paymentForm.country);
      let countryCode = this.paymentForm.country;
      let stateCode = this.paymentForm.state;
      if (countryCode === 'UM') {
        countryCode = stateCode;
        stateCode = '';
      }
      const promoCode = localStorage.getItem('promo_code');
      const requestData = {
        accountInfo: {
          billToContact: {
            country: countryCode,
            firstName: this.paymentForm.firstName,
            lastName: this.paymentForm.lastName,
            postalCode: this.paymentForm.postalCode,
            state: stateCode,
          },
          currency: country.currency,
          name: `${this.paymentForm.firstName} ${this.paymentForm.lastName}`,
        },
        paymentInfo,
        planId: this.activePlan && this.activePlan.id ? this.activePlan.id : '',
        returnUrl: window.location.origin,
        total_price: this.defaultPrice.price,
      };
      if (this.annualPlanEnabled) {
        requestData.planId = (this.selectedPlan && this.selectedPlan.id) || '';
        requestData.planType = (this.selectedPlan && this.selectedPlan.planTypeString) || '';
        requestData.total_price = (this.selectedPlan && this.selectedPlan.price) || null;
      }
      if (promoCode) {
        requestData.promoCode = promoCode;
        requestData.branchPromo = localStorage.getItem('branch_promo');
      }
      const currentDate = new Date();
      const timeOffset = currentDate.getTimezoneOffset();
      requestData.timezoneOffset = timeOffset;
      return requestData;
    },
    subscriptionResponse(resp) {
      this.loading = false;
      this.resetAdyenEncryptedCardFields();
      if (resp && resp.is_provisional_subscription) {
        if (resp && resp.plan && !resp.plan.trial_end_date) {
          resp.plan.trial_end_date = this.calculateTrialEndDateLocally();
          resp.plan.trial_end_date_from_client = true;
          resp.plan.is_first_subscription = true;
        }

        // check if user's selected country on subscription is different from previous subscription record
        // then show default pricing of selected country throughtout app
        const userSubscription = this.userSubscription || {};
        if (userSubscription && userSubscription.plan && resp.plan && resp.plan.country !== userSubscription.plan.country) {
          const defaultPricing = {
            currency: resp.plan.currency,
            price: resp.plan.total_price,
          };
          this.$store.commit('setPricing', defaultPricing);
        }

        // If resubscribing
        if (resp.has_resubscribed) {
          this.showSuccessMsg('Successfully subscribed  to Luminary Premium.');
        }

        this.$store.commit('setUserSubscription', resp);
        const { user, featureFlag } = this.$store.getters;
        if (user && !user.is_on_boarded) {
          this.$store.dispatch('setNewSubscription', true);
          this.logConversionOnGooglePixels(resp); // Event snippet for Subscription_web_pixel conversion page
        }
        user.is_subscribed = true;
        user.is_on_boarded = true;
        user.first_name = this.paymentForm.firstName;
        user.last_name = this.paymentForm.lastName;
        this.$store.commit('setUser', user);
        if (localStorage.getItem('branch_promo') !== null) {
          const params = {
            bpromo: localStorage.getItem('branch_promo'),
            monthsfree: localStorage.getItem('promo_trial_period'),
          };
          this.$analytics.identify(user.uuid, params);
        }
        // Use this data to send to branch custom event
        this.logBranchCustomEvent(resp);
        localStorage.removeItem('promo_code');
        localStorage.removeItem('promo_trial_end_date');
        localStorage.removeItem('promo_trial_period');
        localStorage.removeItem('branch_promo');
        this.$store.dispatch('setPromoData', {
          promoCode: '',
          trialPeriod: 1,
          trialEndDate: '',
          header: '',
          subheader: '',
        }); // Removing promo code data after user
        this.$emit('success');
        const selectedPlan = (this.$store.getters.selectedPlan && this.$store.getters.selectedPlan.planTypeString) || null;
        const planType = resp.plan.type || resp.planType || selectedPlan;
        const preloadPayment = featureFlag('preloadPayment', { impression: false });
        const eventData = {
          path: (window && window.location && window.location.pathname) || null,
          preloadPayment,
          platform: planType,
          email: user.email,
        };
        this.$analytics.track('ga_subscription_created', eventData);
      }
    },
    onCountryChange() {
      const country = this.countries.find(nextItem => nextItem.code === this.paymentForm.country);
      if (country && country.currency) {
        this.fetchZuoraProduct(country.currency, country.code);
      }
    },
    openPriceAlert(targetPlan) {
      const usersCountry = this.$store.getters.country;
      const targetCountry = this.countries.find(country => country.code === this.paymentForm.country);

      this.$confirm(`You will be charged ${targetPlan.displayPrice} because your card is from ${targetCountry && targetCountry.name ? targetCountry.name : this.paymentForm.country}.`, {
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel',
        type: 'warning',
      }).then(() => {
        this.$store.dispatch('selectPricingPlan', targetPlan);
        this.verifyAddress();
      }).catch(() => {
        this.paymentForm.country = this.userSubscription && this.userSubscription.plan && this.userSubscription.plan.country ? this.userSubscription.plan.country : usersCountry;
        this.activePlan = this.$store.getters.activePlan;
        this.defaultPrice = this.fetchTargetCountryPrice();
        this.verifyAddress();
      });
    },
    fetchTargetCountryPrice() {
      const country = this.countries.find(nextItem => nextItem.code === this.paymentForm.country);
      const productRatePlanCharges = this.activePlan.productRatePlanCharges[this.activePlan.productRatePlanCharges.length - 1];
      const pricing = productRatePlanCharges.pricing.find(nextItem => nextItem.currency === country.currency);
      return pricing;
    },
    checkCountryIsWhiteListed() {
      const countryCode = this.supportedCountries.find(code => code === this.paymentForm.country);
      if (!countryCode) {
        this.paymentForm.country = '';
      }
    },
    calculateTrialEndDateLocally() {
      if (this.annualPlanEnabled) {
        const daysFromNow = this.$store.getters.trialPeriodDays;
        const dateOfTrialExpiry = new Date();
        dateOfTrialExpiry.setDate(dateOfTrialExpiry.getDay() + daysFromNow);
        return dateOfTrialExpiry;
      }
      const promoCode = localStorage.getItem('promo_code');
      const promoTrailEndDate = localStorage.getItem('promo_trial_end_date');
      let currentDate = new Date();
      currentDate.setMonth(currentDate.getMonth() + 1);
      if (promoCode && promoTrailEndDate) {
        currentDate = promoTrailEndDate;
      }
      return currentDate;
    },
    fetchZuoraProduct(currencyCode, countryCode) {
      this.loading = true;
      this.subscriptionProvider.fetchSubscriptionPlans(currencyCode, countryCode).then((resp) => {
        this.loading = false;
        if (resp.plan && resp.plan.id) {
          this.activePlan = resp.plan;
          if (this.$refs.countryDD1) {
            this.$refs.countryDD1.blur();
          } else if (this.$refs.countryDD2) {
            this.$refs.countryDD2.blur();
          }
          const shaper = new ShapeProductsResponse();
          const pricingPlans = shaper.getPricingPlans({ response: resp.originalResponse, currencyCode });
          const pln = this.$store.getters.selectedPlan;
          const targetPlan = pricingPlans.find(plan => plan.planTypeString === pln.planTypeString);
          this.openPriceAlert(targetPlan);
          this.$refs.paymentForm.clearValidate();
        }
      }).catch(() => {
        this.loading = false;
      });
    },
    populateUserName() {
      const { user } = this.$store.getters;
      if (user && user.uuid) {
        this.paymentForm.firstName = user.first_name || '';
        this.paymentForm.lastName = user.last_name || '';
        this.paymentForm.postalCode = user.postal_code || '';
        this.paymentForm.state = user.state || '';
        this.paymentForm.country = this.userSubscription && this.userSubscription.plan && this.userSubscription.plan.country ? this.userSubscription.plan.country : this.$store.getters.country;
      }
    },
    verifyAddress() {
      const { user } = this.$store.getters;
      if (this.userSubscription && this.userSubscription.plan && this.paymentForm.country !== this.userSubscription.plan.country) {
        this.paymentForm.postalCode = '';
        this.paymentForm.state = '';
      } else {
        this.paymentForm.postalCode = user.postal_code;
        this.paymentForm.state = user.state;
      }
      setTimeout(() => {
        this.$refs.paymentForm.clearValidate();
      }, 10);
    },
    validatePostalCode(rule, value, callback) {
      value = value.toString().trim();
      if (value === '') {
        callback(new Error('Postal code is required'));
      } else if (value.length > 10) {
        callback(new Error('Postal code is too long'));
      } else {
        const strongRegex = new RegExp(/^[ABCEGHJ-NPRSTVXY]\d[ABCEGHJ-NPRSTV-Z]\s?\d[ABCEGHJ-NPRSTV-Z]\d$/i);

        if (strongRegex.test(value)) {
          callback();
        } else {
          callback(new Error('Enter valid postal code.'));
        }
      }
    },
    logBranchCustomEvent(subscription) {
      const customData = {
        uuid: subscription.uuid,
        user_uuid: subscription.user_uuid,
        zuora_plan_id: subscription.zuora_plan_id,
        total_price: subscription.plan.total_price,
        promo_code: subscription.promo_code,
        currency: subscription.plan.currency,
        branch_promo: localStorage.getItem('branch_promo'),
      };
      this.branchService.logCustomEvent(this.branchService.subscriptionCreatedEventName,
        customData);
    },
    logConversionOnGooglePixels(subscription) {
      if (window && window.gtag) {
        window.gtag('event', 'conversion', { send_to: `${process.env.LUMINARY_GOOGLE_ADS_CONVERSION_ID}/${process.env.LUMINARY_GOOGLE_ADS_CONVERSION_LABEL}`, value: subscription.plan.total_price, currency: subscription.plan.currency });
      }
    },
  },
};
</script>
<style lang="scss">
@import "../../assets/scss/variables.scss";
$form-items-background-color: #ffffff;
.update-payment-info {
  color: #333333;
  .plan-description {
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    margin-bottom: 38px;
    color: #333333;
    .strike-through {
      text-decoration: line-through;
      opacity: .6;
      margin-right: 4px;
    }
    p {
      margin: 0;
      &:first-child {
        margin-bottom: 16px;
      }
    }
  }
  .acquisition-plan-description {
    font-size: 14px;
    line-height: 20px;
    text-align: left;
    margin-bottom: 38px;
    .strike-through {
      text-decoration: line-through;
      opacity: .6;
      margin-right: 4px;
    }
    p {
      margin: 0;
      color: $white !important;
      &:first-child {
        margin-bottom: 16px;
      }
    }
  }
  .el-select-dropdown__empty {
    font-size: 14px !important;
    font-weight: normal !important;
    color: #999 !important;
  }

  .heading{
    font-size: 36px;
    letter-spacing: -1;
    line-height: 49px;
    font-family: $clear-sans;
    margin-top: 0px !important;
    font-weight: bold;
    font-style: normal;
    color: #333333;
  }
  .sub-head{
    margin-bottom: 24px;
    @media(max-width: 567px) {
      text-align: left;
    }
    div:first-child{
      font-size: 14px;
      color: #111111;
    }
    div:last-child{
      font-size: 14px;
      color: #666666;
    }
  }
  .action-button{
    margin-top: 24px;
  }
  .action-controls {
    text-align: left;
    display: inline-block;
    padding-bottom: 50px;
  }
  .annual-plan-btn {
    width: 353px;
    max-width: 95%;
  }
  .form-info-acquisition {
    .el-form-item {
      .el-form-item__label {
        color: white !important;
      }
    }
  }

  .el-form{
    max-width: 480px !important;
    width: 100% !important;
    margin-top: 30px;
    .el-form-item {
      margin-bottom: 16px;
      .el-form-item__label {
        color: #333333;
        font-size: 14px;
        font-weight: normal;
        line-height: 20px;
        padding: 0 0 8px 0;
      }
      .el-form-item__label:before{
        content: none !important;
      }
      .el-input {
        .el-input__inner {
          height: 48px;
          border-radius: 8px;
          background-color: $form-items-background-color;
        }
      }
    }
  }
  .cards-div{
    .el-form-item{

      .el-form-item__label{
        padding: 0 0 8px 0;
      }
    }
    .js-iframe {
      max-height: 45px !important;
      min-height: 45px !important;
      background-color: $form-items-background-color;
      border-radius: 8px;
      .js-iframe-input.input-field {
        height: 48px;
        line-height: 48px;
        border: 1px solid #dcdfe6;
        border-radius: 8px;
      }
    }
    .custom-card-number{
      border: 1px solid #dcdfe6 !important;
      border-radius: 8px !important;
      background-color: $form-items-background-color;
      line-height: 12px;
      padding: 0 15px;
      #encryptedCardNumberSpan {
        .js-iframe {
          max-width: 100%;
          border: 0px !important;
        }
      }
    }
    .custom-exp-date {
      .el-form-item__content {
        background-color: $form-items-background-color;
        line-height: 12px;
        border: 1px solid #dcdfe6 !important;
        border-radius: 8px !important;
        padding: 0 0 0 15px;
      }
      .slash-container {
        margin: 0 6px 0 -6px;
      }
      .exp-month, .exp-year{
        margin-bottom: 0px;
        .el-form-item__content{
          background-color: transparent;
            border: 0px solid #dcdfe6 !important;
            padding: 0px;
          #encryptedExpiryYearSpan, #encryptedExpiryMonthSpan {
            line-height: 12px;
            .js-iframe {
              max-width: 100%;
              border: 0px !important;
              background-color: transparent;
              .js-iframe-input.input-field{
                height: 48px;
                line-height: 48px;
              }
            }
          }
        }
      }
    }
    .custom-cvv {
      border: 1px solid #dcdfe6 !important;
      border-radius: 8px !important;
      padding: 0 15px;
      background-color: $form-items-background-color;
      line-height: 12px;
      .js-iframe {
        max-width: 100%;
        border: 0px !important;
      }
    }
    .card-brand{
      img {
        width: 44px;
      }
    }
  }
  .legal-terms {
    color: #666 !important;
    font-size: 12px;
    line-height: 18px;

    a {
      color: #111111;
    }
  }
  .legal-copy {
    max-width: 480px !important;
    width: 100%;
    margin: auto;
    background: #ffffff;
    border-radius: 5px;
    padding: 23px 0px 28px;
    margin-top: 31px;
    margin-bottom: 8px;
    span, a, p {
      font-size: 12px;
      line-height: 18px;
      font-family: $clear-sans;
    }
    b, p b {
      color: #333333;
      font-family: $clear-sans;
      font-size: 12px;
      line-height: 18px;
    }
  }
  .acquisition-legal-copy {
    max-width: 480px !important;
    width: 100%;
    background: $bg-color;
    border-radius: 5px;
    padding: 23px 0px 28px;
    margin-top: 31px;
    margin-bottom: 8px;
    span, a, p {
      font-size: 12px;
      line-height: 18px;
      font-family: $clear-sans;
      color: $white;
    }
    b, p b {
      color: $white;
      font-family: $clear-sans;
      font-size: 12px;
      line-height: 18px;
    }
  }
}
@media (max-width: 567px) {
  .update-payment-info{
    .cards-div {
      .card-brand {
        display: none;
      }
    }
  }
}
</style>
