import { render, staticRenderFns } from "./EmailVerification.vue?vue&type=template&id=4c642eaa&scoped=true&"
import script from "./EmailVerification.vue?vue&type=script&lang=js&"
export * from "./EmailVerification.vue?vue&type=script&lang=js&"
import style0 from "./EmailVerification.vue?vue&type=style&index=0&id=4c642eaa&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4c642eaa",
  null
  
)

export default component.exports