import Vue from 'vue';

const getDefaultState = () => ({
  episodesProgress: {},
});

const getters = {
  episodesProgress: state => state.episodesProgress,
  episodeProgressByUuid: state => uuid => state.episodesProgress[uuid],
  // libraryEpisodes: state => state.libraryEpisodes,
};

const mutations = {
  setAllEpisodesProgress: (state, episodes) => {
    episodes.forEach((episode) => {
      Vue.set(state.episodesProgress, episode.episode_uuid, episode);
    });
  },
  setEpisodeProgressById: (state, episodeProgress) => {
    if (state.episodesProgress[episodeProgress.episode_uuid]) {
      state.episodesProgress[episodeProgress.episode_uuid] = episodeProgress;
    } else {
      Vue.set(state.episodesProgress, episodeProgress.episode_uuid, episodeProgress);
    }
  },
  resetEpisodeProgress: (state) => {
    Object.assign(state, getDefaultState());
  },
};

const state = getDefaultState();

const actions = {
  setAllEpisodesProgress: (context, episodes) => {
    context.commit('setAllEpisodesProgress', episodes);
  },
  setEpisodeProgressById: (context, episodeProgress) => {
    context.commit('setEpisodeProgressById', episodeProgress);
  },
  resetUserEpisodeProgressState: (context) => {
    context.commit('resetEpisodeProgress');
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
