export default {
  mounted() {
    this.triggerPageEvent();
  },
  methods: {
    triggerPageEvent() {
      setTimeout(() => {
        this.$analyticsV2.logPageView();
        console.log('page view logging'); // eslint-disable-line no-console
      }, 2000);
    },
  },
};
