<template>
  <div class="fullcard-feature-margins">
    <div class="fullcard-feature">
      <div class="container">
        <div class="content">
          <h1 class="feature-vinyl">The Miracle +<br>Vinyl Bundle</h1>
          <h2 class="feature-vinyl">Limited Offer · Only on Luminary</h2>
          <div class="feature-vinyl info-block">
            <img src="@/assets/images/web2/home/vinyl/vinyl-img.png" alt="The Midnight Miracle Vinyl"/>
            <div class="info">
              <p><span>LIMITED EDITION VINYL</span><br>Annual subscription + exclusive two-episode vinyl of The Midnight Miracle.</p>
            </div>
          </div>
          <div class="feature-vinyl cta-block" @click="onClickCTA">Get The Miracle Bundle</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    onClickCTA() {
      this.$analytics.track('Home Miracle Vinyl Click', {
        screen_name: this.$route.name,
        path: this.$route.path,
        btnId: 'home_miraclevinyl_click',
      });
      this.$analyticsV2.logEvent('cf_button_clicked', {
        is_cta: true,
        target_url: '/signup/plan?banner=top',
        button_text: 'Get The Miracle Bundle',
      });
      window.location.href = '/signup/plan?banner=top';
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../assets/scss/variables.scss";

.fullcard-feature {
  background-image: url(../../../assets/images/web2/home/vinyl/Featured-Luminary-homepage-1600x656x2_1.png),linear-gradient(180deg,#041027 0%,#041027 100%)!important;
  background-position: 0 0;
  background-size: auto 100%;
  content: "";
  top: 0;
  background-repeat: no-repeat;
  background-position: 100%;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  opacity: 1;
  padding-top: 72px;
  padding-bottom: 72px;
  height: auto;
  border-radius: 6px;
  max-width: 1600px;
  margin: 0 auto;
}

.fullcard-feature-margins {
  margin: 0 87px;
}

.fullcard-feature .content {
  flex: 0 0 420px!important;
}

.content {
  opacity: 1;
  position: relative;
  z-index: 2;
}

.container {
  height: 420px;
  max-width: 1600px;
  margin: 0 auto;
  width: auto;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  flex-flow: row nowrap;
  padding-left: 87px;
  -webkit-box-align: center;
  align-items: center;
  justify-content: left;
}

h1.feature-vinyl {
  font-size: 3.3rem!important;
  color: #ffffff;
  line-height: 4rem!important;
  font-family: patron, sans-serif!important;
  font-weight: 700!important;
  font-style: normal!important;
  padding: 0!important;
  margin: 0!important;
}

h2.feature-vinyl {
  color: #FFE500;
  font-size: 1rem;
  line-height: 1.25rem;
  font-family: patron, sans-serif!important;
  font-weight: 400!important;
  font-style: normal!important;
  margin-top:30px;
}

.feature-vinyl.info-block {
  margin: 0 auto;
  width: auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-flow: row nowrap;
  flex-flow: row nowrap;
  padding: 0 10px 0 0;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: left;
  -ms-flex-pack: left;
  justify-content: left;
  background-color: rgba(242, 241, 214, 0.3) !important;
  border-radius: 8px 8px 0px 0px;
  opacity: 1;
  backdrop-filter: blur(30px);
  -webkit-backdrop-filter: blur(30px);
  transition: background-color 0.5s cubic-bezier(0.28, 0.11, 0.32, 1);
  margin-top:40px;
}

.feature-vinyl.cta-block {
  margin: 0 auto;
  width: auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-flow: row nowrap;
  flex-flow: row nowrap;
  padding: 18px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: left;
  -ms-flex-pack: left;
  justify-content: left;
  background-color: #FFE52C !important;
  border-radius: 0px 0px 8px 8px;
  font-weight: 700!important;
  flex-flow: column-reverse;
  font-size: 16px;
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  transition: all 0.2s linear;
  cursor:pointer;
}

.feature-vinyl.cta-block:hover {
  background-color: #D3B900 !important;
  cursor: pointer;
}

.info-block img { width: 105px; height: auto; border-radius: 10px; }

.info-block p {
  font-size: 1rem!important;
  color: #fff!important;
  padding:10px;
  line-height:1.5rem!important;
}

.info-block p span {
  color: #FFE52C;
  font-size: 0.75rem;
  text-transform: uppercase;
  font-weight: 700!important;
}

/* Mobile Styles */
@media (max-width: 1177px) {
  .hide-mobile { display: none; }
  .hide-desktop { display: block; }
  .home-page { padding-bottom: 150px; }

  .fullcard-feature-margins { margin:0; }

  .fullcard-feature {
    background-image: url(../../../assets/images/web2/home/vinyl/Featured-Luminary-homepage-Mobile-678x278x2_1.png),linear-gradient(180deg,#041027 0%,#041027 100%)!important;
    background-repeat: no-repeat;
    background-position: center;
    height: auto!important;
    max-width: 356px;
    width: 91.2%;
    margin: 0 auto;
    padding-top:150px;
    padding-bottom:20px;
    flex:auto!important;
    border-radius: 6px;
  }

  .container {
    padding-left:0!important;
    height: 323px;
  }

  .content {
    max-width: 320px;
    margin: 0 auto;
    top:0;
    flex:none;
    padding: 0 20px!important;
    flex:auto!important;
  }

  h2.feature-rebrand {
    font-size: .875rem;
    line-height: 1.5rem;
    margin-top: 14px;
  }

  h1.feature-rebrand {
    font-size: 2.25rem!important;
    line-height: 2.5rem!important;
    margin: 0 auto!important;
    width: fit-content;
  }

  .container.feature {
    align-items: flex-end!important;
    margin-bottom: 30px!important;
  }

  h2.feature-vinyl {
    font-size: 1.125rem;
    text-align: center;
    margin-top: 14px;
  }

  h1.feature-vinyl {
    font-size: 2.1rem!important;
    line-height: 2.1rem!important;
    margin: 0 auto!important;
    width: fit-content;
    text-align: center;
  }

  .info-block p { padding:0!important; }
}

</style>
