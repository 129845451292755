<template>
  <div
    class="cookie-policy container"
    data-test="popup-cookiepolicy">
    <h2>Cookie Policy</h2>
    <p>
      We and our trusted partners use technologies such as cookies and mobile ad identifiers to collect and process personal data to understand our users, improve our advertising, and analyze user interactions with us, on our website and through our ads and content. By continuing to use our service you agree to our and our partners’ processing of personal data for these purposes as described in our
      <router-link
        to="/privacy"
        color="primary"
        target="_blank"
        aria-label="Privacy policy"
      >
        Privacy Policy
      </router-link> and our Partners’ Privacy Policies.
      <!-- <a
        color="primary"
        @click="manageConsents"
      >
        Manage your consents here.
      </a> -->
    </p>
    <el-button
      type="primary"
      data-test="btn-accept"
      class="btn-accept"
      @click="manageConsents"
    >
      Accept & Manage Consents
    </el-button>
    <el-button
      type="secondary"
      data-test="btn-accept-all"
      class="btn-accept-all"
      @click="acceptConsents"
    >
      Accept All
    </el-button>
  </div>
</template>

<script>
import Cookie from 'js-cookie';
import UserService from '../services/User';

export default {
  data() {
    return {
      userService: new UserService(),
    };
  },
  methods: {
    acceptCookiePolicy() {
      const { user } = this.$store.getters;
      this.$analytics.track('consent_manager_click', { step: 4 });
      if (user && user.isLoggedIn) {
        this.userService.updateUserInfo({
          is_cookie_tracking_permission_granted: true,
        });
      }
      this.$analytics.track('consent_manager_click', { step: 5 });
      this.$store.dispatch('acceptCookiePolicy');
    },
    manageConsents() {
      this.$analytics.track('consent_manager_click', { step: 1 });
      // eslint-disable-next-line no-undef
      window.consentManager.openConsentManager();
      setTimeout(() => {
        this.$analytics.track('consent_manager_click', { step: 2 });
        const buttonEle = document.querySelector('div[data-consent-manager-dialog] form');
        if (!buttonEle) {
          this.$analytics.track('consent_manager_blocked');
          return;
        }
        buttonEle.addEventListener('submit', () => {
          this.$analytics.track('consent_manager_click', { step: 3 });
          this.acceptCookiePolicy();
        });
      }, 0);
    },
    acceptConsents() {
      this.acceptCookiePolicy();
      Cookie.set('tracking-preferences', {
        version: 1,
        destinations: {},
        custom: {
          marketingAndAnalytics: true,
          advertising: true,
          functional: true,
        },
      });
    },
    rejectConsents() {
      this.acceptCookiePolicy();
      Cookie.set('tracking-preferences', {
        version: 1,
        destinations: {},
        custom: {
          marketingAndAnalytics: false,
          advertising: false,
          functional: true,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../assets/scss/variables.scss';

.cookie-policy {
  padding: 35px 60px;
  border-radius: 5px 5px 0 0;

  background-color: white;

  z-index: 10000;

  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);

  border: 1px solid $light-gray2;
  border-bottom: none;
  @media (max-width: 567px) {
    padding: 25px;
  }
  h2 {
    font-size: 20px;
    color: $black;
    margin: 0;
    line-height: normal;
  }

  p {
    margin: 16px 0 35px;

    a {
      cursor: pointer;
    }
    @media (max-width: 567px) {
      height: 30vh;
      overflow: auto;
    }
  }

  button {
    padding: 11px 20px;
    min-width: 102px;
  }
  .btn-accept{
    @media (max-width: 567px) {
      display: block;
      margin: auto;
    }
  }

  &.slide-up-enter,
  &.slide-up-leave-to {
    transform: translateY(calc(100%)) translateX(-50%);
  }

  &.slide-up-enter-active, &.slide-up-leave-active {
    transition: 300ms;
  }
}
</style>
