/* eslint-disable import/no-cycle */
import * as log from 'loglevel';
import AxiosAPI from './AxiosAPI';

class Episode extends AxiosAPI {
  getEpisodesById(episodeUUID) {
    return new Promise((resolve, reject) => {
      super.getList(`v1/episodes/${episodeUUID}`).then((resp) => {
        resolve(resp);
      }).catch((error) => {
        reject(error);
      });
    });
  }

  getEpisodeDetailsV2(episodeUuid) {
    return new Promise((resolve, reject) => {
      super.get(`v2/episodes/${episodeUuid}`).then((resp) => {
        resolve(resp);
      }).catch((error) => {
        reject(error);
      });
    });
  }

  getListenedEpisodes() {
    return new Promise((resolve, reject) => {
      super.getList('v1/episodes_listen').then((resp) => {
        resolve(resp);
      }).catch((error) => {
        reject(error);
      });
    });
  }

  getEpisodeProgressFromLocalStore(episodeUuid) {
    const episodeProgress = window.$store.getters.episodeProgressByUuid(episodeUuid);

    if (episodeProgress) {
      return episodeProgress;
    }

    return null;
  }

  // Checks for episode progress locally, if not found, gets via API
  getEpisodeProgress(episodeUuid) {
    const episodeProgress = window.$store.getters.episodeProgressByUuid(episodeUuid);

    return new Promise((resolve, reject) => {
      if (episodeProgress) {
        resolve({ ...episodeProgress });
      } else {
        this.checkEpisodeProgress(episodeUuid)
          .then((value) => {
            resolve(value.episode);
            window.$store.dispatch('setEpisodeProgressById', value.episode);
          })
          .catch((error) => {
            reject(error);
          });
      }
    });
  }

  getEpisodeById(episodeUUID) {
    return new Promise((resolve, reject) => {
      super.get(`v1/episodes/${episodeUUID}`).then((resp) => {
        resolve(resp);
      })
        .catch((error) => {
          reject(error);
        });
    });
  }

  getEpisodeBySlug(episodeSlug) {
    return new Promise((resolve, reject) => {
      super.get(`v1/episodes/slug/${episodeSlug}`).then((resp) => {
        resolve(resp);
      })
        .catch((error) => {
          reject(error);
        });
    });
  }

  checkEpisodeProgress(episodeUUID) {
    return new Promise((resolve, reject) => {
      super.get(`v1/episode_listens/${episodeUUID}`).then((resp) => {
        resolve(resp);
      })
        .catch((error) => {
          reject(error);
        });
    });
  }

  getAllPlayedEpisodes() {
    return new Promise((resolve, reject) => {
      super.get('v1/playlist', { playlist_type: 'continue_listening' }).then((resp) => {
        resolve(resp);
      })
        .catch((error) => {
          reject(error);
        });
    });
  }

  getLastPlayedEpisode() {
    return new Promise((resolve, reject) => {
      super.get('v1/episode_listens/last_played').then((resp) => {
        resolve(resp);
      })
        .catch((error) => {
          reject(error);
        });
    });
  }

  playLastPlayedEpisode() {
    const getLastPlayedEpisode = this.getLastPlayedEpisode();
    getLastPlayedEpisode.then((resp) => {
      if (resp && resp.episode) {
        const { episode } = resp;
        const { user } = window.$store.getters;
        // Edge case: last played episode is premium and user's subscription is expired then not load last played episode in player
        if (!episode.is_exclusive || (episode.is_exclusive && user.is_subscribed)) {
          this.setLastPlayedInPlayer(episode);
        }
      }
    });
  }

  async setLastPlayedInPlayer(lastEpisode) {
    try {
      const episode = await this.getEpisodeById(lastEpisode.episode_uuid, {});
      const details = await this.getEpisodeDetailsV2(lastEpisode.episode_uuid);
      if (episode) {
        episode.episode_type = 'full'; // To handle trailer as a normal episode in case when trailer's progress is saved iin CL.
        let tempTitle = 'All Caught Up';
        const progress = this.getEpisodeProgressFromLocalStore(episode.uuid) || 1;
        if (progress.progress_percent < 95) {
          tempTitle = 'Resume Episode';
        } else if (progress.progress_percent >= 95) {
          tempTitle = 'Play Next Episode';
        }
        episode.temp_title = tempTitle;
        episode.mediaType = details.mediaType;
        episode.hlsUrl = details.hlsUrl;
        window.$store.dispatch('setIsLastPlayed', episode.uuid);
        window.$store.dispatch('setEpisode', episode);
        // in case of hls is available, this media will be reloaded in HlsAudio.vue watcher
        window.$store.dispatch('setLastPlayedEpisode', episode);
      }
    } catch (error) {
      log.warn('Error on last played episode: ', error);
    }
  }

  savePlayProgress(episodeId, progress, duration, podcastUuid, episodeType) {
    return new Promise((resolve, reject) => {
      const { token } = window.$store.getters;
      if (!token) {
        reject(new Error('User is not loggedIn'));
        return;
      }
      const formData = {
        episode_uuid: episodeId,
        elapsed: progress.toString(),
        runtime: duration.toString(),
        podcast_uuid: podcastUuid,
        episode_type: episodeType,
      };
      super.post('v1/episode_listens', null, formData).then(() => {
        resolve();
      }, (error) => {
        log.warn(error);
        reject(error);
      });
    });
  }

  getEpisodesFromLibrary(limit, page) {
    return new Promise((resolve, reject) => {
      super.getList('v1.1/user/episode', { limit, p: page })
        .then((resp) => {
          resolve(resp);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  addEpisodetoLibrary(id) {
    return new Promise((resolve, reject) => {
      const formData = {
        episode_uuid: id,
      };
      super.post('v1/user/episode', formData)
        .then((resp) => {
          resolve(resp);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  deleteEpisodeFromLibrary(episodeUUID) {
    return new Promise((resolve, reject) => {
      super.delete(`v1/user/episode/${episodeUUID}`)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  updateEpisodePosition(episodeUUID, pos) {
    return new Promise((resolve, reject) => {
      const params = {
        position: pos,
      };
      super.put(`v1/user/episode/${episodeUUID}`, params)
        .then((resp) => {
          resolve(resp);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  giveEpisodeFeedback(episodeUUID, comments) {
    return new Promise((resolve, reject) => {
      const params = {
        content: comments,
      };
      super.post(`v1/user/episode_feedback/${episodeUUID}`, params)
        .then((resp) => {
          resolve(resp);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  getEpisodeFeedback(episodeUUID) {
    return new Promise((resolve, reject) => {
      super.get(`v1/user/episode_feedback/${episodeUUID}`)
        .then((resp) => {
          resolve(resp);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  getAllPodcastEpisodes(_limit, _page) {
    return new Promise((resolve, reject) => {
      super.get('v1.1/user/podcast/episodes', { limit: _limit, p: _page })
        .then((resp) => {
          resolve(resp);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  getUserLibraryEpisodeIds() {
    return new Promise((resolve, reject) => {
      super.get('v1/user/library/episodeids')
        .then((resp) => {
          resolve(resp);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  getEdpEpisodes(episodeUUID) {
    return new Promise((resolve, reject) => {
      super.getList(`v1.1/episode/${episodeUUID}/episodes`)
        .then((resp) => {
          resolve(resp);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
}

export default Episode;
