<template>
  <div :class="`loader-container${center ? ' centralize' : ''}${withMargin ? ' with-margin' : ''}${absolute ? ' absolute' : ''}${withOverlay && !overlayWhite ? ' overlay' : ''}${overlayWhite ? ' overlay-white' : ''}`">
    <div>
      <div class="loader">
        <svg
          class="circular"
          viewBox="25 25 50 50" >
          <circle
            class="path"
            cx="50"
            cy="50"
            r="20"
            fill="none"
            stroke-width="6"
            stroke-miterlimit="10"/>
        </svg>
      </div>
      <p>{{ label }}</p>
    </div>
  </div>
</template>
<script>
export default{
  name: 'Loader',
  props: {
    label: {
      type: String,
      default: '',
    },
    center: {
      type: Boolean,
      default: false,
    },
    withMargin: {
      type: Boolean,
      default: false,
    },
    absolute: {
      type: Boolean,
      default: false,
    },
    withOverlay: {
      type: Boolean,
      default: false,
    },
    overlayWhite: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../assets/scss/variables.scss";
.loader-container {
  &.absolute {
    position: absolute;
    width: 100%;
    height: 100%;

    background-color: rgba(0, 0, 0, 0.1);
    box-shadow: 0 0 5px rgba(0, 0, 0, .15);
    z-index: 9999;

    top: 0;
    left: 0;

    > div {
      height: 100%;
      width: 100%;

      .loader {
        position: absolute;

        top: 50%;
        left: 50%;

        transform: translateX(-50%) translateY(-50%);
      }
    }
  }

  .loader {
    position: relative;
    width: 32px;
    margin:0 auto;
    &:before {
      content: '';
      display: block;
      padding-top: 100%;
    }
    .circular {
      animation: rotate 2s linear infinite;
      height: 100%;
      transform-origin: center center;
      width: 100%;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
    }
    .path {
      stroke-dasharray: 1, 200;
      stroke-dashoffset: 0;
      stroke: #bababa;
      animation: dash 1.5s ease-in-out infinite;
      stroke-linecap: round;
    }
  }
  p {
    text-align: center;
    color: inherit;
  }

  &.with-margin {
    margin: 3rem 0;
  }
}
.centralize {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  > div {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
  }
}
.overlay {
  background-color: rgba(0, 0, 0, 0.75) !important;
}
.overlay-white {
  background-color: rgba(255, 255, 255, 0.75) !important;
}

.theme-background {
  .loader-container {
    color: $blue;
    background-color: $dark-background;
    .loader {
      .path {
        stroke: $blue;
      }
    }
  }
}
</style>
