import AxiosAPI from './AxiosAPI';

class Adyen extends AxiosAPI {
  getPaymentMethods({ country, currency }) {
    return super.get('v1/adyen/payment_methods', {
      country,
      currency,
    });
  }
}

export default Adyen;
