export const freePlan = {
  planTypeString: 'free',
  paidPeriod: 0,
};

export const applePodcastPlan = {
  planTypeString: 'applePodcasts',
  trialPeriodDays: 7,
  displayPrice: '$4.99/mo or $34.99/yr',
};
