import { getServices } from '@/services/serviceProvider';
import HolidayPromo from '@/services/HolidayPromo';
import SpecialPromo from '@/services/SpecialPromo';

const [
  holidayPromo,
] = getServices([HolidayPromo]);

const [
  specialPromo,
] = getServices([SpecialPromo]);

const getters = {
  user: state => state.user,
  token: state => state.token,
  loggedIn: state => state.loggedIn,
  lastLoggedIn: state => state.lastLoggedIn,
  search_data: state => state.search_data,
  acceptedCookiePolicy: state => state.acceptedCookiePolicy,
  redirect_user: state => state.redirect_user,
  creating_account: state => state.creating_account,
  country: state => state.country,
  currency: state => state.currency,
  irClickId: state => state.irClickId,
  promoData: state => state.promoData,
  segmentAnonId: state => state.segmentAnonId,
  anonymousSession: state => state.anonymousSession,
  userUuid: state => (state.user && state.user.uuid) || null,
  isNewSubscription: state => state.isNewSubscription,
  analyticsUserId: state => state.analyticsUserId,
  isCountryEligibleForPromo: () => country => holidayPromo.isEnabled && holidayPromo.affectedCountries.includes(country),
  isHolidayPromoEnabledForCountry: (state, gtrs) => country => gtrs.isCountryEligibleForPromo(country) && !(state.user && state.user.is_subscribed),
  isHolidayPromoEnabled: (state, gtrs) => gtrs.isHolidayPromoEnabledForCountry(state.country),
  isHolidayPromoGiftEnabledForCountry: (state, gtrs) => country => gtrs.isCountryEligibleForPromo(country),
  isHolidayPromoGiftEnabled: (state, gtrs) => gtrs.isHolidayPromoGiftEnabledForCountry(state.country),
  isEmailVerified: state => (state.user ? !!state.user.is_email_verified : null),
  isSpecialPromoEnabled: (state, gtrs) => specialPromo.isEnabled && specialPromo.affectedCountries.includes(state.country),
  specialPromoCodeString: (state, gtrs) => specialPromo.specialPromoCodeString,
  specialPromoCopy: (state, gtrs) => specialPromo.specialPromoCopy,
  specialPromoCopyMobile: (state, gtrs) => specialPromo.specialPromoCopyMobile,
};

const mutations = {
  setUser: (state, user) => { state.user = user; },
  setRedirectPath: (state, path) => { state.redirect_user = path; },
  setToken: (state, token) => { state.token = token; },
  setLoggedIn: (state, loggedIn) => { state.loggedIn = loggedIn; },
  setLastLoggedIn: (state, lastLoggedIn) => { state.lastLoggedIn = lastLoggedIn; },
  setRecentSearchData: (state, search) => { state.search_data = search; },
  acceptCookiePolicy: (state, accepted) => { state.acceptedCookiePolicy = accepted; },
  setSignupCompleted: (state, flag) => { state.creating_account.signup_completed = flag; },
  setInfoCompleted: (state, flag) => { state.creating_account.info_completed = flag; },
  setCategoriesSelected: (state, flag) => { state.creating_account.categories_selected = flag; },
  setUserCountry: (state, country) => { state.country = country; },
  setUserCurrency: (state, currency) => { state.currency = currency; },
  setIrClickId: (state, irClickId) => { state.irClickId = irClickId; },
  setPromoData: (state, promoData) => { state.promoData = { ...state.promoData, ...promoData }; }, // REVERT ONCE PROMO OFF
  setSegmentAnonId: (state, id) => { state.segmentAnonId = id; },
  setAnonymousSession: (state, bool) => { state.anonymousSession = bool; },
  setNewSubscription: (state, bool) => { state.isNewSubscription = bool; },
  setAnalyticsUserId: (state, userId) => { state.analyticsUserId = userId; },
  setIsEmailVerified: (state, bool) => { if (state.user) state.user.is_email_verified = bool; },
};

const state = {
  user: null,
  token: null,
  loggedIn: false,
  lastLoggedIn: null,
  is_creating_account: false,
  creating_account: {
    signup_completed: false,
    info_completed: false,
    categories_selected: false,
  },
  search_data: [],
  acceptedCookiePolicy: false,
  redirect_user: null,
  country: 'US',
  currency: 'USD',
  irClickId: null,
  promoData: {
    promoCode: '',
    trialPeriod: 1,
    trialEndDate: '',
    header: '',
    subheader: '',
    timesPrime: null,
  },
  segmentAnonId: null,
  anonymousSession: false,
  isNewSubscription: false,
  analyticsUserId: null,
};

const actions = {
  setIsCreatingAccount: (context, flag) => {
    context.commit('setCreateAccount', flag);
  },
  setCreateAccountOptions: (context, option, flag) => {
    if (option === 1) context.commit('setSignupCompleted', flag);
    else if (option === 2) context.commit('setInfoCompleted', flag);
    else if (option === 3) context.commit('setCategoriesSelected', flag);
  },
  setSubscriptionId: (context, subscriptionId) => {
    const user = context.getters.user || {};
    user.stripe_subscription_id = subscriptionId;
    context.commit('setUser', user);
  },
  setRecentSearches: (context, query) => {
    context.commit('setRecentSearchData', query);
  },
  setRedirectPath: (context, path) => {
    context.commit('setRedirectPath', path);
  },
  setUserCountry: (context, country) => {
    context.commit('setUserCountry', country);
  },
  setUserCurrency: (context, currency) => {
    context.commit('setUserCurrency', currency);
  },
  setIrClickId: (context, irClickId) => {
    context.commit('setIrClickId', irClickId);
  },
  delRecentSearches: (context) => {
    context.commit('setRecentSearchData', []);
  },
  logout: (context) => {
    context.commit('setUser', null);
    context.commit('setToken', null);
    context.commit('setUserSubscription', null);
    context.commit('setLoggedIn', false);
    context.commit('setLastLoggedIn', null);
    context.commit('setRecentSearchData', []);
    context.commit('setSignupCompleted', false);
    context.commit('setInfoCompleted', false);
    context.commit('setCategoriesSelected', false);
    context.dispatch('signupflow/dropAllSteps');
  },
  acceptCookiePolicy: (context, accepted = true) => {
    context.commit('acceptCookiePolicy', accepted);
  },
  setPromoData: (context, promoData) => {
    context.commit('setPromoData', promoData);
  },
  setSegmentAnonId: (context, id) => {
    context.commit('setSegmentAnonId', id);
  },
  setAnonymousSession: (context, boolean) => {
    context.commit('setAnonymousSession', boolean);
  },
  setNewSubscription: (context, boolean) => {
    context.commit('setNewSubscription', boolean);
  },
  setAnalyticsUserId: (context, userId) => {
    context.commit('setAnalyticsUserId', userId);
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
