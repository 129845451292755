import AxiosAPI from './AxiosAPI';

class Widget extends AxiosAPI {
  getPageWidgets(page) {
    return new Promise((resolve, reject) => {
      super.get(`v2/pages/${page}-web`)
        .then((resp) => {
          resolve(resp);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  getWidgetItems(serviceUrlFull) {
    return new Promise((resolve, reject) => {
      super.get(serviceUrlFull)
        .then((resp) => {
          resolve(resp);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
}

export default Widget;
