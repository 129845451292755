<template>
  <div class="episode-card">
    <div class="card-top">
      <div class="card-top-left">
        <ListenFreeTag :episode="episode" />
        <p class="episode-title">
          <router-link
            :to="episodeSlugLink">
            {{ episodeTitle }}
          </router-link>
        </p>
        <p class="details">{{ episodeDetails }}</p>
      </div>
      <div>
        <div class="icon-wrapper">
          <div
            v-if="showAudioIcon"
            class="audio-wrapper">
            <AudioIcon
              :episode="episode"
              :episode-legacy="formatLegacyEpisodeObject(episode)"
              :playlist-type="playlistType" />
          </div>
          <svg :class="`${isCardOpen ? 'icon-rotate' : 'icon-control'}`" svg-inline="" width="24" @click="toggleCardOpen" focusable="false" role="presentation" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 15"><path fill="#00AECB" fill-rule="evenodd" d="M21.3629 0.00123925L24 2.697L12.0104 15L0 2.69824L2.63467 0L12.008 9.6007L21.3629 0.00123925Z" clip-rule="evenodd"/></svg>
        </div>
      </div>
    </div>
    <div
      :class="`card-bottom ${isCardOpen ? 'card-show' : ''} `">
      <div class="content-wrapper">
        <p
          class="description"
          v-html="$sanitize(episode.description)" />
        <el-button
          v-if="showSubscribeCta"
          class="el-button--primary"
          @click="handleCTARouting">
          {{ subscribe_cta }}
        </el-button>
        <div
          v-if="showBookmarkCta"
          class="add-remove-btn">
          <span
            v-show="isAddingBookmark || isRemovingBookmark"
            class="processing">
            <i class="el-icon-loading" />
            {{ isRemovingBookmark ? 'Removing...' : isAddingBookmark ? 'Adding...' : '' }}</span>
          <img
            v-button-event="{buttonText: 'Add Episode', buttonLink: null}"
            v-if="!isAddingBookmark && !isRemovingBookmark"
            :src="is_in_bookmarks ? IsBookmarkedIcon : isNotBookmarkedIcon"
            width="24"
            @click="is_in_bookmarks ? removeBookmark() : addBookmark()">
          <span
            v-if="!isAddingBookmark && !isRemovingBookmark"
            @click="is_in_bookmarks ? removeBookmark() : addBookmark()">
            {{ is_in_bookmarks ? 'Added': 'Bookmark' }}</span>
        </div>
      </div>
      <div>
        <img
          v-if="hasEpisodeImage"
          :src="episodeImage"
          class="episode-image"
          alt="episode image">
      </div>
    </div>
  </div>
</template>

<script>
import * as log from 'loglevel';
import EpisodeService from '../../../services/Episode';
import SegmentEvent from '../../../services/PlayerEvent';
import AudioIcon from '../shared/AudioIcon';
import ListenFreeTag from '../shared/ListenFreeTag';
import IsBookmarkedIcon from '../../../assets/images/bookmarked.svg';
import isNotBookmarkedIcon from '../../../assets/images/ic_add_episode.svg';

import showDetailsHelper from './showDetailsHelper';

export default {
  components: {
    AudioIcon,
    ListenFreeTag,
  },
  props: {
    episode: {
      type: Object,
      default() {
        return {};
      },
    },
    podcast: {
      type: Object,
      default() {
        return {};
      },
    },
    userBookmarks: {
      type: Array,
      default() {
        return [];
      },
    },
    playlistType: {
      type: String,
      default: 'show',
    },
  },
  data() {
    return {
      episode_service: new EpisodeService(),
      eventProvider: new SegmentEvent(),
      IsBookmarkedIcon,
      isNotBookmarkedIcon,
      subscribe_cta: 'Subscribe to Listen',
      isCardOpen: false,
      bookmarked_episode: null,
      is_in_bookmarks: false,
      isAddingBookmark: false,
      isRemovingBookmark: false,
    };
  },
  computed: {
    userPremium() {
      return this.userSubscriptionState === 'premium' || this.userSubscriptionState === 'chrunedPremium';
    },
    userNotPremium() {
      return this.userSubscriptionState === 'anon' || this.userSubscriptionState === 'free' || this.userSubscriptionState === 'freeWasPremium';
    },
    userNotAnon() {
      return !(this.userSubscriptionState === 'anon');
    },
    showBookmarkCta() {
      return this.userPremium || (this.userNotAnon && !this.episode.isExclusive);
    },
    showAudioIcon() {
      return this.userPremium || (this.userNotPremium && !this.episode.isExclusive);
    },
    showSubscribeCta() {
      return this.episode.isExclusive && this.userNotPremium;
    },
    episodeTitle() {
      if (!this.episode) return '';
      // if (this.episode.mediaType === 'music') return `${this.episode.title} - ${this.episode.publisherName}`;
      return this.episode.title;
    },
    episodeDetails() {
      const episodeDetailsArray = [];
      if (this.episode.season && this.episode.number && this.episode.mediaType !== 'music') {
        const seasonText = `S${this.episode.season} E${this.episode.number}`;
        episodeDetailsArray.push(seasonText);
      }
      if (this.episode.releasedAt) {
        const date = this.$options.filters.dateFormat(this.episode.releasedAt * 1000);
        episodeDetailsArray.push(date);
      }
      if (this.episode.runtime) {
        const runtime = this.$options.filters.humanizedTimeFilter(this.episode.runtime);
        episodeDetailsArray.push(runtime);
      }
      return episodeDetailsArray.join(' | ');
    },
    episodeSlugLink() {
      const publisherSlug = this.convertToSlug(this.podcast.publisherName);
      const podcastSlug = this.convertToSlug(this.podcast.title);
      const episodeSlug = this.convertToSlug(this.episode.title);
      const episodeId = this.episode.id;

      return `/listen/${publisherSlug}/${podcastSlug}/${episodeSlug}/${episodeId}`;
    },
    hasEpisodeImage() {
      return this.episode.images.thumbnail || this.episode.images.default;
    },
    episodeImage() {
      if (this.episode.images.thumbnail) {
        return this.episode.images.thumbnail;
      }
      if (this.episode.images.default) {
        return this.episode.images.default;
      }
      return '';
    },
  },
  beforeMount() {
    this.setUserBookmark();
  },
  methods: {
    toggleCardOpen() {
      this.isCardOpen = !this.isCardOpen;
    },
    formatLegacyEpisodeObject(episode) {
      return showDetailsHelper.formatLegacyEpisodeObject(episode);
    },
    setUserBookmark() {
      this.bookmarked_episode = this.userBookmarks.find(bookmark => bookmark.episode_uuid === this.episode.id);
      if (this.bookmarked_episode) {
        this.is_in_bookmarks = true;
      } else {
        this.is_in_bookmarks = false;
      }
    },
    async addBookmark() {
      this.isAddingBookmark = true;

      try {
        const res = await this.episode_service.addEpisodetoLibrary(this.episode.id);
        this.bookmarked_episode = { ...res, episode_uuid: this.episode.id };
        this.$store.dispatch('setUserLibraryEpisode', { uuid: res.uuid, episode_uuid: this.episode.id });
        this.eventProvider.triggerEpisodeAddEvent(this.formatLegacyEpisodeObject(this.episode));
        this.is_in_bookmarks = true;
      } catch (err) {
        this.$emit('added', 0);
        this.showErrorMsg('Failed to bookmark episode');
        log.error(err.message);
      }

      this.isAddingBookmark = false;
    },
    async removeBookmark() {
      this.isRemovingBookmark = true;

      try {
        await this.episode_service.deleteEpisodeFromLibrary(this.bookmarked_episode.uuid);
        this.bookmarked_episode = null;
        this.$store.dispatch('removeUserLibraryEpisode', this.episode.id);
        this.$emit('deleted', this.episode.id);
        this.eventProvider.triggerEpisodeRemoveEvent(this.formatLegacyEpisodeObject(this.episode));
        this.is_in_bookmarks = false;
      } catch (err) {
        this.$emit('deleted', 0);
        this.showErrorMsg('Couldn\'t delete this episode from your library. Please try again.');
        log.error(err.message);
      }
      this.isRemovingBookmark = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../assets/scss/variables.scss";

.episode-card {
  margin-top: 30px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

.card-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 24px;
}

.card-top-left {
  margin-right: 20px;
}

.header {
  font-family: $patron-bold;
  color: $peach;
  font-size: 12px;
  line-height: 13px;
  letter-spacing: 1px;
  text-transform: uppercase;
  margin-top: 0;
  margin-bottom: 6px;
}

.episode-title {
  font-family: $patron-bold;
  color: $white;
  font-size: 18px;
  line-height: 24px;
  margin-top: 0;
  margin-bottom: 6px;
  @media(min-width: $sm) {
    font-size: 24px;
    margin-bottom: 10px;
  }

  a {
    color: $white;
    &:hover {
      color: $blue;
      text-decoration: underline;
    }
  }
}

.details {
  font-family: $patron;
  color: $light-gray3;
  font-size: 14px;
  line-height: 20px;
  margin-top: 0;
  margin-bottom: 0;
  @media(min-width: $sm) {
    font-size: 16px;
  }
}

.icon-wrapper {
  display: flex;
  align-items: center;

  .icon-control {
    transition-duration: 0.5s;
  }

  .icon-rotate {
    transform: rotate(-180deg);
    transition-duration: 0.5s;
  }
}

.audio-wrapper {
  margin-right: 18px;
}

.card-bottom {
  display: flex;
  justify-content: space-between;
  // -- animation --
  overflow: hidden;
  height: 0;
  // -- animation --
}

.card-show {
  margin-bottom: 34px;
  // -- animation --
  height: auto;
  transition: height 0.5s ease;
  // -- animation --
}

.content-wrapper {
  @media(min-width: $sm) {
    margin-right: 80px;
  }
  @media(min-width: $md) {
    margin-right: 142px;
  }
}

.description {
  font-family: $patron;
  color: $white;
  font-size: 14px;
  line-height: 22px;
  margin-top: 0;
  margin-bottom: 24px;
  @media(min-width: $sm) {
    font-size: 16px;
    line-height: 24px;
  }
}

.description /deep/ p {
  font-family: $patron;
  color: $white;
  font-size: 14px;
  line-height: 22px;
  margin-top: 0;
  margin-bottom: 0;
  @media(min-width: $sm) {
    font-size: 16px;
    line-height: 24px;
  }
}

.add-remove-btn {
  display: flex;
  align-items: center;
  font-family: $clear-sans;
  img, span {
    cursor: pointer;
  }
  span:hover {
    text-decoration: underline;
  }
  .processing {
    width: 150px;
    display: flex;
    align-items: center;
    color: $blue;
    font-size: 16px;
    i {
      font-size: 24px;
      margin-right: 12px;
      color: $blue;
    }
  }
  span:not(.processing) {
    margin-left: 12px;
    color: $blue;
  }
  div, svg {
    width: 24px;
    height: 24px;
  }
}

.episode-image {
  display: none;
  @media(min-width: $md) {
    display: block;
    width: 160px;
    height: 160px;
  }
}

</style>
