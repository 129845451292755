<template>
  <div>
    <ShowDetails v-if="!is_sdp_v2"/>
    <ShowDetailsV2 v-if="is_sdp_v2"/>
  </div>
</template>
<script>
import { mapActions } from 'vuex';
import ShowDetailsV2 from './ShowDetails';
import ShowDetails from '../ShowDetails';
import PageMixin from '../../shared/pageMixin';

export default {
  name: 'ShowDetailsWrapper',
  components: {
    ShowDetailsV2,
    ShowDetails,
  },
  mixins: [PageMixin],
  data() {
    return {
      is_sdp_v2: false,
    };
  },
  beforeMount() {
    this.getFeatureFlag();
    this.updatePodcastViewDate(this.$route.params.podcastID);
  },
  methods: {
    ...mapActions({
      updatePodcastViewDate: 'libraryv2/updatePodcastViewDate',
    }),
    getFeatureFlag() {
      this.is_sdp_v2 = this.$store.getters.flagWithDefault('web_v2_sdp', true);
    },
  },
};
</script>
