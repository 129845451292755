<template>
  <div class="recipient-info">
    <el-form
      ref="recipientInfoForm"
      :model="recipientInfo"
      :rules="rules"
      @keyup.enter.native="onSubmit(index)">
      <el-row>
        <grid-col
          :xs="24"
          :sm="24"
          :md="24"
          :lg="11"
          :xl="11">
          <el-form-item
            label="Recipient First Name"
            prop="firstName">
            <el-input
              v-model="recipientInfo.firstName"
              placeholder="First Name" />
          </el-form-item>
          <el-form-item
            label="Recipient Last Name"
            prop="lastName">
            <el-input
              v-model="recipientInfo.lastName"
              placeholder="Last Name" />
          </el-form-item>
          <el-form-item
            label="Recipient Email"
            prop="email">
            <el-input
              v-model="recipientInfo.email"
              placeholder="Email" />
          </el-form-item>
          <el-form-item
            label="Confirm Recipient Email"
            prop="confirmEmail">
            <el-input
              v-model="recipientInfo.confirmEmail"
              placeholder="Email" />
          </el-form-item>
        </grid-col>
        <grid-col
          :xs="24"
          :sm="24"
          :md="24"
          :lg="{ offset: 2, span: 11 }"
          :xl="{ offset: 2, span: 11 }">
          <el-form-item
            prop="delivered_at">
            <p class="delivery">Delivery Date</p>
            <el-date-picker
              :disabled="recipientInfo.sendNowChecked"
              v-model="recipientInfo.delivered_at"
              :picker-options="pickerOptions"
              type="date"
              format="MMM d yyyy"
              placeholder="Pick a day"/>
          </el-form-item>
          <el-form-item>
            <div class="delivery-checkbox">
              <el-checkbox
                v-model="recipientInfo.sendNowChecked"
                @change="overridePickerDate" />
              <p>Check this box to send immediately (today)</p>
            </div>
          </el-form-item>
          <el-form-item
            label="Personalized Message">
            <el-input
              v-model="recipientInfo.message"
              type="textarea"/>
          </el-form-item>
        </grid-col>
      </el-row>
      <el-button
        type="primary"
        class="block-btn spotlight"
        @click="() => onSubmit(index)">Save and Continue</el-button>
    </el-form>
  </div>
</template>

<script>
import * as EmailValidator from 'email-validator';

export default {
  props: {
    index: {
      type: Number,
      default: 0,
    },
  },
  data() {
    const firstNameValidator = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('First Name Required'));
      }
      callback();
    };
    const lastNameValidator = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('Last Name Required'));
      }
      callback();
    };
    const emailValidator = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('Email Required'));
      } else if (!EmailValidator.validate(value)) {
        callback(new Error('Invalid Email Format'));
        return;
      }
      callback();
    };
    const confirmEmailvalidator = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('Email Required'));
      } else if (!EmailValidator.validate(value)) {
        callback(new Error('Invalid Email Format'));
        return;
      } else if (value !== this.recipientInfo.email) {
        callback(new Error('Emails do not match'));
      }
      callback();
    };
    const dateValidator = (rule, value, callback) => {
      if (value === '' || value === null) {
        callback(new Error('Date Required'));
      }
      callback();
    };
    return {
      recipientInfo: {
        firstName: '',
        lastName: '',
        email: '',
        confirmEmail: '',
        delivered_at: '',
        sendNowChecked: false,
        message: '',
      },
      rules: {
        firstName: [{ validator: firstNameValidator, trigger: 'blur' }],
        lastName: [{ validator: lastNameValidator, trigger: 'blur' }],
        email: [{ validator: emailValidator, trigger: 'change' }],
        confirmEmail: [{ validator: confirmEmailvalidator, trigger: ['change', 'blur'] }],
        delivered_at: [{ validator: dateValidator, trigger: ['change', 'blur'] }],
      },
      pickerOptions: {
        disabledDate(time) {
          const day = 3600 * 1000 * 24;
          const startBound = Date.now() - day;
          const endBound = Date.now() + (89 * day);
          return time.getTime() < startBound || time.getTime() > endBound;
        },
      },
    };
  },
  mounted() {
    this.getInitialFormValues();
  },
  methods: {
    onSubmit(index) {
      this.$refs.recipientInfoForm.validate((valid) => {
        if (valid) {
          this.$store.dispatch('saveAccordionSectionData', {
            formData: this.recipientInfo,
            index,
          });
        }
      });
    },
    getInitialFormValues() {
      const { formData } = this.$store.getters.accordionSections[this.index];
      this.recipientInfo = formData;
    },
    overridePickerDate() {
      const dateNow = new Date(Date.now());
      this.recipientInfo.delivered_at = dateNow.toISOString();
    },
  },
};

</script>

<style lang="scss" scoped>
@import '../../../../assets/scss/variables.scss';

.recipient-info {
  max-width: 324px;
  margin: 0 auto;
  @media (min-width: $md) {
    max-width: none;
  }

  /deep/ .el-form-item {
    margin-bottom: 16px;
    &:last-of-type {
      margin-bottom: 32px;
    }
    &__label,
    .delivery {
      color: $white;
    }
    .delivery-checkbox {
      display: flex;
      p {
        font-size: 14px;
        color: $white;
      }
    }
    .el-checkbox {
      margin-right: 12px;
    }
  }
}
</style>
