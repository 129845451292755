<template>
  <div class="download-app">
    <div class="container">
      <div class="content">
        <el-row>
          <grid-col
            :xs="{ offset: 1, span: 22 }"
            :sm="{ offset: 1, span: 22 }"
            :md="{ offset: 0, span: 12 }"
            :lg="{ offset: 1, span: 10 }"
            :xl="{ offset: 1, span: 10 }"
            class="phone-col">
            <div class="phones-desktop">
              <img
                :src="srcSet('src', mobilePhones)"
                :srcset="srcSet('srcset', mobilePhones)">
              <div class="download-links">
                <appstore-links/>
              </div>
            </div>
          </grid-col>
          <grid-col
            :xs="{ offset: 0, span: 24 }"
            :sm="{ offset: 0, span: 24 }"
            :md="{ offset: 1, span: 11 }"
            :lg="{ offset: 2, span: 9 }"
            :xl="{ offset: 2, span: 9 }">
            <div class="text-link">
              <h3 class="header">{{ headerText }}</h3>
              <h1 class="title">{{ titleText }}</h1>
              <div class="phones-mobile">
                <img
                  :src="srcSet('src', mobilePhones)"
                  :srcset="srcSet('srcset', mobilePhones)">
              </div>
              <p class="subtitle">{{ subtitleText }}</p>
              <div class="inputs">
                <div
                  class="text-input-group"
                  @keyup.enter="sendSMS">
                  <span
                    ref="countryCodeElement"
                    class="country-code">
                    {{ countryCode }}
                  </span>
                  <vue-phone-number-input
                    v-model="phoneNumber"
                    :only-countries="['US','CA', 'GB', 'IE', 'NZ', 'ZA', 'NO', 'SE', 'FI', 'IS', 'DK']"
                    :class="{'waiting': sendingSMS}"
                    :translations="{
                      phoneNumberLabel: countryCodeLetters === 'US' ? '(917) 555-5555' : '',
                      example: ''
                    }"
                    @update="handlePhoneInput"/>
                  <span
                    v-if="alertMsg"
                    class="alert-text">
                    {{ alertMsg }}
                  </span>
                </div>
                <el-button
                  :class="{'block-btn submit-btn': true, 'disabled': !numberIsValid}"
                  :disabled="!numberIsValid"
                  type="primary"
                  @click="sendSMS">Text Me The App</el-button>
              </div>
              <div class="download-mobile">
                <el-button
                  class="block-btn"
                  type="primary"
                  @click="downloadApp">
                  Download the App
                </el-button>
              </div>
              <p class="support">
                {{ supportText }}
                <br>
                <a
                  :href="settingsLinkUrl"
                  class="settings">
                  {{ settingsLinkText }}
                </a>
              </p>
            </div>
          </grid-col>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
import mobilePhones1x from '../../assets/images/web2/download/mobile-phones@1x.png';
import mobilePhones2x from '../../assets/images/web2/download/mobile-phones@2x.png';

export default {
  name: 'DownloadApp',
  components: {
    'appstore-links': () => import('../../components/AppStoreLinks'),
  },
  data() {
    return {
      mobilePhones: [mobilePhones1x, mobilePhones2x],
      headerText: "You're all set!",
      titleText: "Start listening on the app. It's the best experience.",
      subtitleText: 'Available on both iOS and Android devices.',
      supportText: 'Questions about your subscription?',
      settingsLinkText: 'Visit Subscription Settings.',
      settingsLinkUrl: '/account/subscription',
      phoneNumber: null,
      alertMsg: null,
      countryCode: '+1',
      countryCodeWidth: 21,
      numberIsValid: false,
      sendingSMS: false,
      countryCodeLetters: 'US',
      metaTitle: 'Redeem Your Luminary Gift Subscription',
      metaDescription: 'Redeem your Luminary gift subscription for unlimited access to Luminary Original Podcasts from your favorite creators. Start listening now!',
    };
  },
  beforeMount() {
    this.setMetaInformation(this.metaTitle, this.metaDescription);
  },
  destroyed() {
    this.setMetaInformation();
  },
  methods: {
    downloadApp() {
      if (this.detectMobileOs === 'ios') {
        const branchBaseLink = process.env.LUMINARY_BRANCH_IO_APP_STORE_LINK || window.location.hostname || '';
        window.open(branchBaseLink);
      } else if (this.detectMobileOs === 'android') {
        const branchBaseLink = process.env.LUMINARY_BRANCH_IO_PLAY_STORE_LINK || window.location.hostname || '';
        window.open(branchBaseLink);
      } else { // if OS not detected, default to iOS
        const branchBaseLink = process.env.LUMINARY_BRANCH_IO_APP_STORE_LINK || window.location.hostname || '';
        window.open(branchBaseLink);
      }
    },
    sendSMS() {
      if (window && window.branch && window.branch.sendSMS && this.numberIsValid) {
        this.sendingSMS = true;
        this.alertMsg = null;
        window.branch.sendSMS(
          this.phoneNumber,
          {
            channel: 'Website',
            feature: 'Text-Me-The-App',
          },
          { make_new_link: false },
          (err) => {
            this.sendingSMS = false;
            if (err) {
              this.alertMsg = 'There was an error. Please try again.';
              throw err;
            }
            this.alertMsg = 'Check your messages. We sent you a link.';
            this.trackSendSMS();
            return null;
          },
        );
      } else if (!this.numberIsValid) {
        this.alertMsg = 'This number is invalid.';
      }
    },
    trackSendSMS() {
      const eventData = {
        path: (window && window.location && window.location.pathname) || null,
        isFullModalForDesktop: true,
      };
      this.$analytics.track('user_sent_sms_new_sub', eventData);
    },
    handlePhoneInput(values) {
      this.countryCodeLetters = values && values.countryCode;
      this.numberIsValid = (values && values.isValid) || false;
      const countryInput = document.getElementsByClassName('country-selector__input') && document.getElementsByClassName('country-selector__input')[0];
      const countryInputValue = (countryInput && countryInput.value) ? countryInput.value : '+1';
      this.countryCode = countryInputValue;
      setTimeout(() => {
        this.updateInputStyles();
      }, 100);
    },
    updateInputStyles() {
      const countryCodeWidth = (this.$refs.countryCodeElement && this.$refs.countryCodeElement.clientWidth) || 21;
      const numberInputPaddingLeft = countryCodeWidth + 10;
      const numberInput = document.getElementsByClassName('input-tel__input') && document.getElementsByClassName('input-tel__input')[0];
      if (numberInput) {
        numberInput.style.paddingLeft = `${numberInputPaddingLeft}px`;
      }
    },
  },
};

</script>

<style lang="scss" scoped>
@import "../../assets/scss/variables.scss";

.download-app {
  background: $bg-color;
}

.content {
  margin-top: 60px;
}

.phone-col {
  display: none;
  @media (min-width: $sm) {
    display: block;
  }
}

.phones-desktop {
  width: 100%;
  img {
    width: 100%;
  }
}

.download-links {
  margin: 0 auto;
  text-align: center;
}

.text-link {
  .header {
    color: $brand;
    font-family: $serif;
    font-size: 21px;
    line-height: 25px;
    text-align: center;
    margin-top: 0;
    margin-bottom: 8px;
    @media (min-width: $sm) {
      text-align: left;
      margin-top: 16px;
    }
  }
  .title {
    color: $white;
    font-family: $serif;
    font-size: 28px;
    line-height: 33px;
    text-align: center;
    margin-top: 0px;
    margin-bottom: 28px;
    @media (min-width: $sm) {
      text-align: left;
      margin-bottom: 24px;
    }
  }

  .phones-mobile {
    text-align: center;
    max-width: 260px;
    margin: 0 auto 16px;
    img {
      width: 100%;
    }
    @media (min-width: $sm) {
      display: none;
    }
  }

  .subtitle {
    color: $light-gray3;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    margin-bottom: 34px;
    @media (min-width: $sm) {
      text-align: left;
    }
  }

  .download-mobile {
    margin-bottom: 24px;
    @media (min-width: $sm) {
      display: none;
    }
  }

  .support {
    color: $light-gray;
    font-size: 14px;
    line-height: 22px;
    text-align: center;
    margin-top: 0;
    margin-bottom: 48px;
    @media (min-width: $sm) {
      text-align: left;
    }
  }

  .settings {
    &:hover {
      text-decoration: underline;
    }
  }
}

.inputs {
  display: none;
  .text-input-group {
    overflow-x: visible;
    position: relative;
    /deep/ .vue-phone-number-input {
      margin-bottom: 15px;
      .select-country-container, .country-selector__input, .country-selector, .input-country-selector {
        width: 59px !important;
        min-width: 59px !important;
      }
      .country-selector__input {
        border-top-right-radius: 0px;
        border-bottom-right-radius: 0px;
        background: #E5E5E5;
        color: rgba(0,0,0,0.0);
      }
      .country-selector__country-flag {
        top: 19px;
      }
      .country-selector__toggle {
        top: calc(50% - 8px);
        svg {
          width: 18px;
        }
      }
      .country-selector__label, .input-tel__label {
        display: none;
      }
      .input-tel {
        margin-left: -61px;
      }
      .input-tel__input {
        padding-top: 0px;
      }
      .country-selector__list__item {
        font-weight: normal;
        font-size: 16px;
        line-height: 20px;
      }
      input {
        background: #FCFCFC;
        border: 1px solid #BBBBBB;
        box-sizing: border-box;
        border-radius: 8px;
        width: 256px;
        height: 48px;
        font-weight: normal;
        font-size: 16px;
        line-height: 20px;
        color: #000000;
        padding-left: 32px;
        ::placeholder {
          color: #C4C4C4;
        }
      }
      &.waiting {
        animation-name: pulse;
        animation-duration: 800ms;
        animation-fill-mode: both;
        animation-iteration-count: infinite;
      }
    }
    .country-code {
      position: absolute;
      pointer-events: none;
      z-index: 2;
      left: 67px;
      top: 14px;
      font-weight: normal;
      font-size: 16px;
      line-height: 20px;
      color: #000000;
    }
    .alert-text {
      font-weight: normal;
      font-size: 14px;
      line-height: 22px;
      color: #00AECB;
    }
  }
  .submit-btn {
    margin-left: 0;
    margin-top: 24px;
    margin-bottom: 30px;
    &.disabled {
      opacity: .7;
    }
  }
  @media (min-width: $sm) {
    display: block;
  }
}

</style>
