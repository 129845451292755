<template>
  <div
    v-if="!preloadEnabled"
    class="premium-buy-page"
    data-test="page-premium">
    <div class="m-t-2">
      <payment-info-adyen
        v-if="isAdyenDropInEnabled"
        heading="Billing Information"
        @success="successAction"
      />
      <add-payment-info
        v-else
        heading="Billing Information"
        @success="successAction"
      />
    </div>
  </div>
</template>

<script>
import UpdatePaymentInfo from '../../components/subscription/UpdatePaymentInfo';
import PaymentInfoAdyen from '../../components/subscription/PaymentInfoAdyen';
import PageMixin from '../../shared/pageMixin';

export default {
  name: 'AdyenBuyPremiumPage',
  components: {
    'add-payment-info': UpdatePaymentInfo,
    PaymentInfoAdyen,
  },
  mixins: [PageMixin],
  data() {
    return {
      pageName: 'Premium Subscription',
    };
  },
  computed: {
    isAdyenDropInEnabled() {
      return this.$store.getters.featureFlag('adyen_drop_in');
    },
    user() {
      const { user } = this.$store.getters;

      return user;
    },
    preloadEnabled() {
      const { featureFlag } = this.$store.getters;
      const preloadPayment = featureFlag('preloadPayment', { impression: false });
      // Since preloadAdyenBuyPremium component mounts in the background I'm using the
      // evaluation of this computed property to trigger the preloadPayment impression
      if (preloadPayment) {
        this.$analytics.track('Preloaded Payment Viewed');
      }
      return preloadPayment;
    },
  },
  mounted() {
    window.eventBus.$emit('onboarding-step-change', -1);
  },
  methods: {
    skipCategories() {
      this.$store.commit('setCategoriesSelected', true);
      this.$router.push('/complete');

      if (this.user && this.user.is_subscribed) {
        this.$router.push({ path: '/shows', query: { newSubscription: true } });
      } else {
        this.$router.push('/shows');
      }
    },
    successAction() {
      this.trackSteps({ step: 'stepThree', flowType: 'ctrl' });
      this.$router.push({ path: '/shows', query: { newSubscription: true } });
    },
  },
};
</script>

<style lang='scss'>
.premium-buy-page{
  max-width: 468px;
  width: 100%;
  margin: auto;
  padding: 52px 0 92px 0;
}
@media (max-width: 567px) {
  .premium-buy-page{
    width: auto;
    padding: 21px 32px;
  }
}
</style>
