import uuidv1 from 'uuid/v1';

export default {
  limit: 30 * 60 * 1000, // 30 Minutes

  getSessionId() {
    const timestamp = new Date().valueOf();
    const session = this.get();

    if (!session || timestamp > (session.last_activity_at + this.limit)) {
      return this.create();
    }
    this.set(session.session_id, timestamp);
    return session.session_id;
  },
  create() {
    const sessionId = uuidv1();
    const lastActivityAt = new Date().valueOf();
    this.set(sessionId, lastActivityAt);
    return sessionId;
  },
  set(sessionId, lastActivityAt) {
    const session = {
      session_id: sessionId,
      last_activity_at: lastActivityAt,
    };
    localStorage.setItem('session', JSON.stringify(session));
  },
  get() {
    const session = localStorage.getItem('session');
    if (session) {
      return JSON.parse(session);
    }
    return false;
  },
};
