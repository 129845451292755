<template>
  <div class="bespoke-show">
    <section
      class="show-hero">
      <el-row class="hero-row">
        <grid-col
          :md="{span:12, offset:12}"
          :lg="{span:12, offset:12}"
          :xl="{span:14, offset:10}">
          <div class="hero-content">
            <img
              :src="srcSet('src', heroTitleImages)"
              :srcset="srcSet('srcset', heroTitleImages)"
              class="hero-title-image">
            <h3>A social media queen and her bestie walk into a podcast booth.</h3>
            <div class="hero-btns">
              <div
                class="play-trailer-btn"
                @click="playTrailer">
                <img
                  v-if="isPlayingTrailer"
                  src="../../assets/images/bespoke-pause-btn-blue.svg"
                  alt="Pause"
                  class="pause-btn">
                <img
                  v-else
                  :class="{'play-btn': true, 'pulse': isLoadingTrailer }"
                  src="../../assets/images/bespoke-play-btn-blue.svg"
                  alt="Play">
                <i
                  v-if="false"
                  class="el-icon-loading text-blue"/>
                <span>{{ playBtnText }}</span>
              </div>
              <div class="cta-btn">
                <button
                  ref="cta"
                  @click="() => handleCTAClick('hero')">
                  {{ heroCTAText }}
                </button>
              </div>
              <div class="cta-btn-fixed">
                <button
                  ref="cta"
                  @click="() => handleCTAClick('sticky')">
                  {{ heroCTAText }}
                </button>
              </div>
            </div>
          </div>
        </grid-col>
      </el-row>
      <img
        :src="srcSet('src', heroMobileImageSrcSets)"
        :srcset="srcSet('srcset', heroMobileImageSrcSets)"
        loop="infinite"
        class="hero-mobile-image">
    </section>
    <section class="show-feature">
      <el-row class="feature-row">
        <grid-col
          :xs="{span:24}"
          :sm="{span:24}"
          :md="{span:12}"
          :lg="{span:12, offset:6}"
          class="feature-content">
          <p class="feature-copy">{{ featureCopy }}</p>
        </grid-col>
        <grid-col
          :xs="{span:24}"
          :sm="{span:24}"
          :md="{span:12}"
          :lg="{span:12, offset:6}"
          class="feature-image">
          <img
            :src="srcSet('src', featuredImageSrcSets)"
            :srcset="srcSet('srcset', featuredImageSrcSets)">
        </grid-col>
      </el-row>
    </section>
  </div>
</template>

<script>
import * as log from 'loglevel';
import Podcast from '../../services/Podcast';
import PageMixin from '../../shared/pageMixin';
import Event from '../../services/PlayerEvent';
import heroTitleImage1x from '../../assets/images/celeste/hero-title-img@1x.png';
import heroTitleImage2x from '../../assets/images/celeste/hero-title-img@2x.png';
import featuredImage1x from '../../assets/images/celeste/featured-section@1x.gif';
import featuredImage2x from '../../assets/images/celeste/featured-section@2x.gif';
import heroMobileImage1x from '../../assets/images/celeste/hero-mobile@1x.jpg';
import heroMobileImage2x from '../../assets/images/celeste/hero-mobile@2x.jpg';

export default {
  name: 'CelesteLandingPage',
  mixins: [PageMixin],
  data() {
    return {
      heroTitleImages: [heroTitleImage1x, heroTitleImage2x],
      heroCTAText: 'START FREE TRIAL',
      season_trailers: [],
      podcast_service: new Podcast(),
      eventProvider: new Event(),
      display_image_url: 'https://img4.luminarypodcasts.com/v1/podcast/bd7c73b3-14bf-4b98-b2b0-1a955c9623d3/thumbnail/h_360,w_360/image/s--cluBnXNF--/aHR0cHM6Ly9tZWdhcGhvbmUtcHJvZC5zMy5hbWF6b25hd3MuY29tL3BvZGNhc3RzL2ZlZGRhNWJhLTQxMTktMTFlOS04ZjcwLTlmZmZkMTMzYTgxNi9pbWFnZS91cGxvYWRzXzJGMTU4Mzg1MTAzNTg1NC1zc24wbGFkcm4tNTUwY2Q0OTM4YzIxMDZiNTIxNDU4N2E5MmMyNzkzODJfMkYyMDAzMDlfQ2VsZXN0ZV9TaG93QXJ0X1Jlc2l6ZV9NZWdhcGhvbmVfMjAwMHgyMDAwLmpwZw==.jpg',
      showUUID: 'bd7c73b3-14bf-4b98-b2b0-1a955c9623d3',
      isPlaying: false,
      featureCopy: 'Listen to Season 2 for all the surprising, hilarious, relatable conversations that follow—including down-to-earth chats with some of the world’s most famous people.',
      featuredImageSrcSets: [
        featuredImage1x,
        featuredImage2x,
      ],
      heroMobileImageSrcSets: [
        heroMobileImage1x,
        heroMobileImage2x,
      ],
    };
  },
  computed: {
    playBtnText() {
      return `${this.isPlaying ? 'Pause' : 'Play'} Trailer`;
    },
    isLoadingTrailer() {
      const { status } = this.$store.getters;

      return this.season_trailers.length && status === 'loading' && this.currentlyPlaying && (this.currentlyPlaying.uuid === this.season_trailers[0].uuid);
    },
    currentlyPlaying() {
      return this.$store.getters.episode;
    },
    isPlayingTrailer() {
      const { play } = this.$store.getters;
      if (!this.season_trailers) {
        return false;
      }
      return this.season_trailers.length && play && this.currentlyPlaying && (this.currentlyPlaying.uuid === this.season_trailers[0].uuid);
    },
  },
  beforeMount() {
    this.getSeasonTrailers();
  },
  mounted() {
    this.setMetaInformation('Celeste & Her Best', 'Aussie social media queen Celeste Barber and her BFF are back for another season of down-to-earth chats with some of the world’s most famous celebrities. Listen now for always hilarious, often relatable conversations.');

    if (!this.$store.getters.hidePlayer) {
      this.togglePlayerVisiblity(true);
    }
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.togglePlayerVisiblity(true);
      vm.addNoIndexMeta();
    });
  },
  beforeRouteLeave(to, from, next) {
    this.togglePlayerVisiblity(false);
    this.removeNoIndexMeta();
    next();
  },
  destroyed() {
    this.setMetaInformation();
  },
  methods: {
    handleCTAClick(btnId) {
      const { isFreeUser, isPremiumUser } = this.promoMeta;
      const routeTo = { path: '/signup' };
      if (isFreeUser) {
        routeTo.path = '/plans';
      } else if (isPremiumUser) {
        routeTo.path = '/listen/celeste-barber/celeste-and-her-best-luminary-premium/bd7c73b3-14bf-4b98-b2b0-1a955c9623d3';
      }
      this.$analytics.track('Celeste CTA Click', {
        screen_name: this.$route.name,
        path: this.$route.path,
        btnId,
      });
      this.$router.push(routeTo);
    },
    togglePlayerVisiblity(bool) {
      this.$store.dispatch('toggleHidePlayer', bool);
    },
    playPauseTrailer() {
      this.isPlaying = !this.isPlaying;
    },
    playTrailer() {
      const trailer = this.season_trailers[0];
      trailer.episode_type = 'trailer';
      trailer.display_image_url = this.display_image_url;
      trailer.playedFrom = 'bespoke_sdp';
      // this.togglePlayerVisiblity(true);
      if (this.currentlyPlaying.uuid === trailer.uuid && this.currentlyPlaying.playedFrom === trailer.playedFrom) {
        const { play } = this.$store.getters;

        if (!play) {
          if (this.currentlyPlaying.episode_type !== 'trailer') {
            this.eventProvider.triggerEpisodePauseEvent(this.currentlyPlaying, 'trailer_select');
          }
          this.$store.dispatch('play');
          this.eventProvider.triggerTrailerPlayEvent(trailer, 'sdp_select');
        } else {
          this.$store.dispatch('pause');
          this.eventProvider.triggerTrailerPauseEvent(trailer, 'sdp_pause');
        }
      } else {
        this.$store.dispatch('setTrailerAsEpisode', false);

        if (this.currentlyPlaying.episode_type !== 'trailer') {
          this.eventProvider.triggerEpisodePauseEvent(this.currentlyPlaying, 'trailer_select');
        }
        this.$store.dispatch('setTrailerExperienceTriggered', false); // Make sure trailer experience does not trigger from here.
        this.loadMediaInPlayer(trailer.media_url, trailer.is_exclusive, trailer.episode_type, true);
        this.$store.dispatch('setNextEpisode', {}); // Empty next episode because playback should stop after trailer complete from SDP.
        this.$store.dispatch('setAndPlayEpisode', trailer);
        this.eventProvider.triggerTrailerPlayEvent(trailer, 'sdp_select');
      }
    },
    getSeasonTrailers() {
      this.podcast_service
        .getPodcastTrailers(this.showUUID)
        .then((res) => {
          this.season_trailers = res.trailers || [];
          this.season_trailers = this.season_trailers.sort((a, b) => new Date(b.released_at) - new Date(a.released_at));
        })
        .catch((error) => {
          log.error(error);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@import './BespokeCeleste.scss';
</style>
