<template>
  <div
    class="home-page"
    data-test="page-home">
    <section
      class="page-title">
      <div class="title-container">
        <h1>Luminary is a subscription podcast network with original shows from your favorite creators.</h1>
      </div>
    </section>
    <section
      class="hero-section">
      <img
        src="../../assets/images/web2/home/Luminary-homepage-3-L-1024x576.jpg"
        alt="Luminary Original Podcast Show Art">
    </section>
    <originals-section
      v-if="!useCmsWidgets"
      parent-page="Home" />
    <promo-section
      v-if="useCmsWidgets"
      parent-page="Home" />
    <widget-wrapper
      v-if="useCmsWidgets"
      :num-loading-widgets="numLoadingWidgets"
      cms-page="marketing-home"
      page-target="home" />
  </div>
</template>

<script>
import PageMixin from '../../shared/pageMixin';
import OriginalsSection from '../../components/sections/OriginalsSection';
import PromoSection from '../../components/sections/PromoSection';
import WidgetWrapper from '../../components/widgets/WidgetWrapper';
import InstallSkill from '../../components/alexa/InstallSkill';

export default {
  name: 'Home',
  components: {
    'originals-section': OriginalsSection,
    'promo-section': PromoSection,
    'widget-wrapper': WidgetWrapper,
    'install-skill': InstallSkill,
  },
  mixins: [PageMixin],
  data() {
    return {
      numLoadingWidgets: 6,
    };
  },
  computed: {
    useCmsWidgets() {
      const cms_widgets = this.$store.getters.flagWithDefault('cms_widgets', true);
      return cms_widgets;
    },
  },
  mounted() {
    this.setMetaInformation('Luminary | Listen to Luminary Original Podcasts', 'Luminary is a subscription podcast network with an award-winning collection of original shows you won\'t find anywhere else.');
  },
  destroyed() {
    this.setMetaInformation();
  },
};
</script>

<style lang="scss">
@import './Home.scss';
</style>
