
const analyticsManager = {
  newRelic: window.newrelic,

  addPageAction(name, attributes) {
    if (this.newRelic && this.newRelic.addPageAction) {
      this.newRelic.addPageAction(name, attributes);
    } else if (window && window.newrelic && window.newrelic.addPageAction) {
      this.newRelic = window.newrelic;
      this.newRelic.addPageAction(name, attributes);
    }
  },
};

export default analyticsManager;
