import * as log from 'loglevel';
import AxiosAPI from './AxiosAPI';

class Podcast extends AxiosAPI {
  loadHomeCategories() {
    const getHomeBucket = new Promise((resolve, reject) => {
      super.getList('v1/home').then((buckets) => {
        resolve(buckets);
      }).catch((error) => {
        reject(error);
      });
    });

    return getHomeBucket;
  }

  loadContinueListeningBucket() {
    return new Promise((resolve, reject) => {
      this.fetchContinueListening().then((bucket) => {
        resolve(bucket);
      }).catch((error) => {
        reject(error);
      });
    });
  }

  loadRecommendedPodcastsBucket() {
    return new Promise((resolve, reject) => {
      super.getList('v1/home/recommended-podcasts').then((bucket) => {
        resolve(bucket);
      }).catch((error) => {
        reject(error);
      });
    });
  }

  loadByBundleId(bundleUUID) {
    return new Promise((resolve, reject) => {
      super.getList(`v1/home/${bundleUUID}`)
        .then((buckets) => {
          resolve(buckets);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  loadOnboardingTopics() {
    return new Promise((resolve, reject) => {
      super.getList('v1/onboarding_topics').then((resp) => {
        resolve(resp);
      }).catch((error) => {
        reject(error);
      });
    });
  }

  loadCategories() {
    return new Promise((resolve, reject) => {
      super.getList('v1/categories').then((resp) => {
        resolve(resp);
      }).catch((error) => {
        reject(error);
      });
    });
  }

  loadCategoryPodcasts(categories) {
    return new Promise((resolve, reject) => {
      super.getList('v1/categories-uuids', { uuids: categories }).then((resp) => {
        resolve(resp);
      }).catch((error) => {
        reject(error);
      });
    });
  }

  getPodcastDetailsV2(podcastUUID) {
    return new Promise((resolve, reject) => {
      super.get(`v2/podcasts/${podcastUUID}`).then((resp) => {
        resolve(resp);
      }).catch((error) => {
        reject(error);
      });
    });
  }

  getPodcastEpisodesV2(podcastUUID, params) {
    return new Promise((resolve, reject) => {
      super.getWithHeaders(`v2/podcasts/${podcastUUID}/episodes`, params).then((resp) => {
        const { total } = resp.headers;
        resolve({
          episodes: resp.data,
          total,
        });
      }).catch((error) => {
        reject(error);
      });
    });
  }

  getPodcastTrailerV2(podcastUUID) {
    return new Promise((resolve, reject) => {
      super.get(`v2/podcasts/${podcastUUID}/trailer`).then((resp) => {
        resolve(resp);
      }).catch((error) => {
        reject(error);
      });
    });
  }

  getShowDetailsById(podcastUUID, params) {
    return new Promise((resolve, reject) => {
      super.get(`v1/show-details/${podcastUUID}`, params).then((resp) => {
        resolve(resp);
      }).catch((error) => {
        reject(error);
      });
    });
  }

  getPodcastsById(ids) {
    return new Promise((resolve, reject) => {
      super.getList('v1/podcasts-uuids', { uuids: ids }).then((resp) => {
        resolve(resp);
      }).catch((error) => {
        reject(error);
      });
    });
  }

  getPodcastById(podcastUUID) {
    return new Promise((resolve, reject) => {
      super.get(`v1.1/podcasts/${podcastUUID}`).then((resp) => {
        resolve(resp);
      }).catch((error) => {
        reject(error);
      });
    });
  }

  getPodcastBySlug(podcastSlug) {
    return new Promise((resolve, reject) => {
      super.get(`v1/podcasts/slug/${podcastSlug}`).then((resp) => {
        resolve(resp);
      }).catch((error) => {
        reject(error);
      });
    });
  }

  getPodcastByCategoryId(categoryUUID, limit, page) {
    return new Promise((resolve, reject) => {
      const params = {
        p: page,
        limit,
      };
      super.get(`v1/categories/${categoryUUID}`, params).then((resp) => {
        resolve(resp);
      }).catch((error) => {
        reject(error);
      });
    });
  }

  getPlaylistData(params) {
    return new Promise((resolve, reject) => {
      super.getList('v1/playlist', params).then((resp) => {
        resolve(resp);
      }).catch((error) => {
        reject(error);
      });
    });
  }

  sortList(podcasts) {
    podcasts.sort((item1, item2) => {
      if (item1.position < item2.position) {
        return -1;
      } if (item1.position > item2.position) {
        return 1;
      }
      return 0;
    });
    return podcasts;
  }

  getPodcastsFromLibrary(_limit, _page) {
    return new Promise((resolve, reject) => {
      super.getList('v1.1/user/podcast', { limit: _limit, p: _page })
        .then((resp) => {
          resolve(resp);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  addPodcastsToLibrary(id) {
    return new Promise((resolve, reject) => {
      const formData = {
        podcast_uuid: id,
      };
      super.post('v1/user/podcast', formData)
        .then((resp) => {
          resolve(resp);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  deletePodcastFromLibrary(podcastUUID) {
    return new Promise((resolve, reject) => {
      super.delete(`v1/user/podcast/${podcastUUID}`)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  updatePodcastViewDate(podcastUUID) {
    return new Promise((resolve, reject) => {
      const params = {
        position: null,
        last_viewed_at: 'now',
      };
      super.put(`v1/user/podcast/${podcastUUID}`, params)
        .then((resp) => {
          resolve(resp);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  updatePodcastPosition(podcastUUID, pos) {
    return new Promise((resolve, reject) => {
      const params = {
        position: pos,
        lastViewedAt: null,
      };
      super.put(`v1/user/podcast/${podcastUUID}`, params)
        .then((resp) => {
          resolve(resp);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  getPodcastTrailers(podcastUUID) {
    return new Promise((resolve, reject) => {
      super.get(`v1/podcasts/${podcastUUID}/trailers`)
        .then(resp => resolve(resp))
        .catch(error => reject(error.message));
    });
  }

  getPodcastRatings(podcastUUID) {
    return new Promise((resolve, reject) => {
      super.put(`v1/user/ratings/${podcastUUID}`)
        .then((resp) => {
          resolve(resp);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  savePodcastRating(podcastUUID, data) {
    return new Promise((resolve, reject) => {
      const params = data;

      super.put(`v1/user/ratings/${podcastUUID}`, params)
        .then((resp) => {
          resolve(resp);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  savePodcastFeedback(podcastUUID, comments) {
    return new Promise((resolve, reject) => {
      // Avoid sending feedback to zendesk on dev and preprod environment
      if (process.env.NODE_ENV !== 'production') {
        resolve({ success: true });
        return;
      }

      const params = {
        content: comments,
      };
      super.post(`v1/user/podcast_feedback/${podcastUUID}`, params)
        .then((resp) => {
          resolve(resp);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  getPodcastFeedback(podcastUUID) {
    return new Promise((resolve, reject) => {
      super.get(`v1/user/podcast_feedback/${podcastUUID}`)
        .then((resp) => {
          resolve(resp);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  getPodcastRating(podcastUUID) {
    return new Promise((resolve, reject) => {
      super.get(`v1/user/ratings/${podcastUUID}`)
        .then((resp) => {
          resolve(resp);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  getRatings() {
    return new Promise((resolve, reject) => {
      super.getList('v1/ratings')
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  updatePodcastRatings(podcastUUID, _code, _rating) {
    return new Promise((resolve, reject) => {
      super.put(`v1/user/ratings/${podcastUUID}`, { ratings: { code: _code, rating_answers: _rating } })
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  deleteRating(podcastUUID) {
    return new Promise((resolve, reject) => {
      super.delete(`v1/user/ratings/${podcastUUID}`)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  getTags() {
    return new Promise((resolve, reject) => {
      super.getList('v1/tags')
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  getPodcastTags(podcastUUID) {
    return new Promise((resolve, reject) => {
      super.get(`v1/podcast/tags/${podcastUUID}`)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  getPodcastEpisodesByPodcastId(podcastId) {
    return new Promise((resolve) => {
      this.getPodcastById(podcastId).then((resp) => {
        if (resp && resp.seasons && resp.seasons.length) {
          let podcastEpisodes = [];
          resp.seasons.forEach((season) => {
            if (season.episodes && season.episodes.length) {
              podcastEpisodes = podcastEpisodes.concat(season.episodes);
            }
          });
          podcastEpisodes.sort((a, b) => new Date(a.released_at) - new Date(b.released_at)); // ascending order episodes
          podcastEpisodes = podcastEpisodes.map((episode) => {
            const tempEpisode = episode;
            tempEpisode.season = { podcast: { uuid: resp.uuid, display_image_url: resp.display_image_url } };
            return tempEpisode;
          });
          resolve(podcastEpisodes);
        }
      }).catch((error) => {
        log.error(error);
      });
    });
  }

  fetchContinueListening() {
    return super.getList('v1/home/continuelistening');
  }

  // loadContinueListeningBucket() {
  //   return new Promise((resolve, reject) => {
  //     const { user, token } = window.$store.getters;
  //     if (!user || !token) {
  //       resolve(null);
  //     }
  //     this.fetchContinueListening().then((continueListeningBucket) => {
  //       resolve(continueListeningBucket);
  //     }).catch((error) => {
  //       reject(error);
  //     });
  //   });
  // }

  getUserLibraryPodcastIds() {
    return new Promise((resolve, reject) => {
      super.get('v1/user/library/podcastids')
        .then((resp) => {
          resolve(resp);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  getPodcastEpisodesByPodcastUuid(podcastUUID, params) {
    return new Promise((resolve, reject) => {
      super.getList(`v1.1/podcasts/${podcastUUID}/episodes`, params).then((resp) => {
        resolve(resp);
      }).catch((error) => {
        reject(error);
      });
    });
  }

  getExclusivePodcasts(params) {
    const reqParams = Object.assign(
      { p: 1, limit: 50, imagetype: 'thumb' },
      params,
    );

    return new Promise((resolve, reject) => {
      super.getList('v1/podcasts/exclusive', reqParams).then((resp) => {
        resolve(resp);
      }).catch((error) => {
        reject(error);
      });
    });
  }

  getV2Podcasts(ids) {
    const params = new URLSearchParams();
    ids.forEach(id => params.append('id', id));
    return super.getList('v2/podcasts', params);
  }

  constructPodcastEpisodeParams(current_page, page_size, sort_order) {
    return {
      from: ((current_page - 1) * page_size),
      size: page_size,
      sortOrder: sort_order,
    };
  }
}

export default Podcast;
