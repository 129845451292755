<template>
  <div class="brightback-keep">
    Keep subscription
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  mounted() {
    // process subscription here and redirect somewhere
  },
};
</script>
