<template>
  <div
    :class="['plan-card']"
    @click="onPlanSelect">
    <span class="best-value-label">
      Listen on Apple Podcasts
    </span>
    <el-row
      type="flex"
      align="middle"
      justify="space-between"
      class="plan-card-content">
      <el-col
        :xl="16"
        :lg="16"
        :sm="16"
        :xs="24"
        class="plan-card-text"
      >
        <el-row
          type="flex"
          justify="space-between"
          align="middle">
          <h3>Luminary Channel</h3>
        </el-row>
        <p class="plan-desc hidden-sm-and-up">Subscribe to the Luminary Channel on Apple Podcasts for {{ plan.displayPrice }}.</p>
        <p class="plan-sub-desc hidden-sm-and-up">{{ trialSentence }}</p>
        <p class="plan-sub-desc hidden-xs-only">Subscribe to the Luminary Channel on Apple Podcasts for {{ plan.displayPrice }}. {{ trialSentence }}</p>
      </el-col>
      <el-col
        :xl="8"
        :lg="8"
        :sm="8"
        :xs="24"
        class="button-container">
        <apple-podcasts-link
          :img-styles="{width: '100%', maxWidth: '188px'}"
          url="https://podcasts.apple.com/us/channel/luminary/id6442463604?itsct=luminary_podcasts&itscg=30200&ls=1&at=1000l36wW&ct=lum_su_flow"
        />
      </el-col>
    </el-row>
  </div>
</template>

<script>
import ApplePodcastsLink from './ApplePodcastsLink';

export default {
  name: 'ApplePodcastsCard',
  components: {
    ApplePodcastsLink,
  },
  props: {
    plan: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    trialSentence() {
      return this.plan.trialPeriodDays ? 'Auto-billed after 7-day free trial.' : '';
    },
  },
  methods: {
    onPlanSelect() {
      this.$emit('selectPlan', this.plan);
    },
  },
};
</script>
<style lang="scss">
  @import "../assets/scss/variables.scss";
  .plan-card {
    background: #FFFFFF;
    border: 1px solid #F2F2F2;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    position: relative;
    transition: border 1s;
    cursor: pointer;
    &.selected-plan {
      border: 1px solid #02AECA;
    }
    &.with-top-header {
      margin-top: 55px;
    }
    @media(min-width: $sm) {
      width: 400px;
    }
    @media(max-width: $sm) {
      width: 100%;
      max-width: 480px;
      margin-left: auto;
      margin-right: auto;
    }
    &-content {
      margin-top: 7px;
      @media(min-width: $sm) {
        padding: 28px 41px 24px 41px;
      }
      @media(max-width: $sm) {
        padding: 28px 29px 24px 29px;
      }
      @media(max-width: $xs) {
        flex-direction: column;
      }
      @media(max-width: 767px) {
        padding: 28px 10px 24px;
      }
      .top-header {
        position: absolute;
        top: -55px;
        left: 0px;
        @media(max-width: $sm) {
          font-size: 30px;
          line-height: 40px;
          letter-spacing: -1px;
        }
      }
      h3 {
        display: inline-block;
        font-family: $clear-sans;
        font-weight: bold;
        font-style: normal;
        font-size: 24px;
        line-height: 32px;
        margin: 1px 14px 8px 0;
        @media(max-width: 767px) {
          text-align: center;
          margin: 14px 0 14px 0;
          width: 100%;
        }
      }
      p {
        font-family: $clear-sans;
        margin: 0px;
      }
      .plan-desc {
        font-size: 16px;
        line-height: 22px;
        margin-bottom: 4px;
        color: $black;
        @media(max-width: $sm) {
          font-size: 16px;
          line-height: 25px;
        }
        @media(max-width: 767px) {
          text-align: center;
          margin-bottom: 5px;
        }
        .strike-through {
          text-decoration: line-through;
          opacity: .6;
          margin-right: 4px;
        }
      }
      .plan-sub-desc {
        font-size: 12px;
        line-height: 16px;
        color: #888888;
        margin-bottom: 16px;
        @media(max-width: $sm) {
          font-size: 12px;
          letter-spacing: -.2px;
          /* line-height: 24px; */
        }
        @media(max-width: 767px) {
          text-align: center;
          margin-bottom: 21px;
        }
      }
    }
    &-text {
      padding-right: 20px;
    }
    .best-value-label {
      display: block;
      background-color: #DC60F4;
      border-radius: 2px;
      font-size: 14px;
      font-weight: bold;
      line-height: 19px;
      padding: 2px 0px;
      position: absolute;
      top: 0px;
      width: 100%;
      text-align: center;
    }
    .spotlight {
      box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 1px rgba(0, 0, 0, 0.14);
    }
    .button-container {
      text-align: right;
      @media(max-width: $xs) {
        text-align: center;
      }
    }
  }
</style>
