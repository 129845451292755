<template>
  <div class="bespoke-show">
    <section
      ref="hero"
      :class="['show-hero', {'hero-above': heroAbove}]">
      <el-row>
        <grid-col
          :xs="24"
          :sm="24"
          :md="24"
          :lg="14"
          class="hero-image-container">
          <div class="image-container">
            <img src="../../assets/images/lies/hero.svg">
          </div>
        </grid-col>
        <grid-col
          :xs="24"
          :sm="24"
          :md="24"
          :lg="10">
          <h1 v-html="heroHeader"/>
          <div class="hero-btns">
            <div
              ref="playBtn"
              class="play-trailer-btn"
              @click="playTrailer">
              <img
                v-if="isPlayingTrailer"
                src="../../assets/images/ic_pause_white_icon.svg"
                alt="Pause"
                class="pause-btn">
              <img
                v-else
                :class="{'play-btn': true, 'pulse': isLoadingTrailer }"
                src="../../assets/images/bespoke-play-btn.svg"
                alt="Play">
              <i
                v-if="false"
                class="el-icon-loading text-blue"/>
              <span>{{ playBtnText }}</span>
            </div>
            <div class="cta-btn">
              <button
                ref="cta"
                @click="() => handleCTAClick(stickyTs ? 'sticky' : 'hero')">
                {{ heroCTAText }}
              </button>
            </div>
          </div>
        </grid-col>
      </el-row>
    </section>
    <section
      class="show-hero-for-space hidden">
      <el-row>
        <grid-col
          :xs="24"
          :sm="24"
          :md="24"
          :lg="14"
          class="hero-image-container">
          <div class="image-container">
            <img src="../../assets/images/lies/hero.svg">
          </div>
        </grid-col>
        <grid-col
          :xs="24"
          :sm="24"
          :md="24"
          :lg="10">
          <h1 v-html="heroHeader"/>
          <div class="hero-btns">
            <div
              class="play-trailer-btn"
              @click="playTrailer">
              <img
                v-if="isPlayingTrailer"
                src="../../assets/images/ic_pause_white_icon.svg"
                alt="Pause"
                class="pause-btn">
              <img
                v-else
                :class="{'play-btn': true, 'pulse': isLoadingTrailer }"
                src="../../assets/images/bespoke-play-btn.svg"
                alt="Play">
              <i
                v-if="false"
                class="el-icon-loading text-blue"/>
              <span>{{ playBtnText }}</span>
            </div>
            <div class="cta-btn">
              <button
                @click="() => handleCTAClick(stickyTs ? 'sticky' : 'hero')">
                {{ heroCTAText }}
              </button>
            </div>
          </div>
        </grid-col>
      </el-row>
    </section>
    <div class="cool-cards-bg">
      <div class="card-bgs">
        <div class="card-bg-container">
          <div class="card-bg-color pink"/>
          <p v-html="pinkText"/>
        </div>
        <div class="card-bg-container">
          <div class="card-bg-color cyan"/>
          <p v-html="cyanText"/>
        </div>
        <div class="card-bg-container">
          <div class="card-bg-color yellow"/>
          <p v-html="yellowText"/>
        </div>
      </div>
    </div>
    <div class="cool-cards">
      <div
        class="card-containers"
        @click="cardsClickHandler">
        <div class="card-container">
          <div
            ref="firstCard"
            class="card-tint-bg pink"/>
          <p v-html="pinkText"/>
        </div>
        <div class="card-container">
          <div class="card-tint-bg cyan"/>
          <p v-html="cyanText"/>
        </div>
        <div class="card-container">
          <div class="card-tint-bg yellow"/>
          <p v-html="yellowText"/>
        </div>
      </div>
    </div>
    <div class="card-containers-for-spacing">
      <div class="card-container">
        <div class="card-tint-bg"/>
        <p v-html="pinkText"/>
      </div>
      <div class="card-container">
        <div class="card-tint-bg"/>
        <p v-html="cyanText"/>
      </div>
      <div class="card-container">
        <div class="card-tint-bg"/>
        <p v-html="yellowText"/>
      </div>
    </div>
  </div>
</template>

<script>
import * as log from 'loglevel';
import throttle from 'lodash/throttle';
import Podcast from '../../services/Podcast';
import PageMixin from '../../shared/pageMixin';
import Event from '../../services/PlayerEvent';
import heroImage1x from '../../assets/images/trevor/podcast_art@1x.jpg';
import heroImage2x from '../../assets/images/trevor/podcast_art@2x.jpg';
import featuredImage1x from '../../assets/images/trevor/featured_image@1x.jpg';
import featuredImage2x from '../../assets/images/trevor/featured_image@2x.jpg';
import bigImage1x from '../../assets/images/trevor/big_image@1x.jpg';
import bigImage2x from '../../assets/images/trevor/big_image@2x.jpg';


export default {
  name: 'LiesWeTellLandingPage',
  mixins: [PageMixin],
  data() {
    return {
      season_trailers: [],
      stickyCTA: false,
      stickyTs: null,
      podcast_service: new Podcast(),
      eventProvider: new Event(),
      display_image_url: 'https://img4.luminarypodcasts.com/v1/podcast/29a522a2-600d-4382-9455-37f2ad3b7ec9/thumbnail/h_360,w_360/image/s--hkR90Xuy--/aHR0cHM6Ly9tZWdhcGhvbmUtcHJvZC5zMy5hbWF6b25hd3MuY29tL3BvZGNhc3RzL2Q4Zjk2ZTQ0LTU5NzYtMTFlYS04OThlLWZiODk0ZjVjYTQ4MC9pbWFnZS91cGxvYWRzXzJGMTU4MjgyNzQ5MTExNy0weGt1eHhjOW5vMW0tNmQ5YThkYTVlZDBiNjY4NDA2MThmZDBjY2NhYzQ0ZWZfMkYyMDAzMDlfTGllc1dlVGVsbENhbXBhaWduX1Nob3dfQXJ0X1Jlc2l6ZV9NZWdhcGhvbmVfMXgxLnBuZw==.png?dpr=2',
      showUUID: '29a522a2-600d-4382-9455-37f2ad3b7ec9',
      windowWidth: null,
      windowHeight: null,
      heroImages: [heroImage1x, heroImage2x],
      heroHeader: 'SOMETIMES DECEPTION CAN HELP YOU SEE THE TRUTH.',
      heroCTAText: 'START FREE TRIAL',
      bigImageSrcSets: [
        bigImage1x,
        bigImage2x,
      ],
      featuredImageSrcSets: [
        featuredImage1x,
        featuredImage2x,
      ],
      isPlaying: false,
      heroAbove: true,
      pinkTextDesktop: 'WHY DO WE LIE? <br>AND <br>WHAT DO OUR LIES REVEAL ABOUT HUMAN NATURE?',
      pinkTextTablet: 'WHY DO WE LIE? <br>AND WHAT DO OUR LIES REVEAL <br>ABOUT HUMAN NATURE?',
      pinkTextMobile: 'WHY DO WE LIE? <br>AND <br>WHAT DO OUR LIES <br>REVEAL ABOUT <br>HUMAN NATURE?',
      cyanTextDesktop: 'ACADEMY AWARD-WINNING FILMAKER ALEX GIBNEY AND<br>FORMER RADIOLAB EXECUTIVE PRODUCER ELLEN HORNE<br>ARE ON A QUEST TO FIND OUT.',
      cyanTextTablet: 'ACADEMY AWARD-WINNING<br>FILMAKER ALEX GIBNEY<br>AND FORMER RADIOLAB EXECUTIVE <br>PRODUCER ELLEN HORNE ARE<br>ON A QUEST TO FIND OUT.',
      cyanTextMobile: 'ACADEMY AWARD-<br>WINNING FILMAKER<br>ALEX GIBNEY<br>AND<br>FORMER RADIOLAB <b>EXECUTIVE PRODUCER<br>ELLEN HORNE ARE ON A<br>QUEST TO FIND OUT.',
      yellowTextDesktop: 'LISTEN AS THEY UNTANGLE A SERIES OF TWISTED,<br>CHARACTER-DRIVEN PUZZLES—EACH WITH<br>A CENTRAL LIE AT ITS CORE.',
      yellowTextTablet: 'LISTEN AS THEY UNTANGLE A<br>SERIES OF TWISTED, CHARACTER-<br>DRIVEN PUZZLES—EACH WITH A<br>CENTRAL LIE AT ITS CORE.',
      yellowTextMobile: 'LISTEN AS THEY<br>UNTANGLE A SERIES<br>OF TWISTED,<br>CHARACTER-DRIVEN<br>PUZZLES—EACH<br>WITH A CENTRAL LIE<br>AT ITS CORE.',
    };
  },
  computed: {
    pinkText() {
      if (this.isXsViewport) {
        return this.pinkTextMobile;
      }
      if (this.isSmViewport) {
        return this.pinkTextTablet;
      }
      return this.pinkTextDesktop;
    },
    cyanText() {
      if (this.isXsViewport) {
        return this.cyanTextMobile;
      }
      if (this.isSmViewport) {
        return this.cyanTextTablet;
      }
      return this.cyanTextDesktop;
    },
    yellowText() {
      if (this.isXsViewport) {
        return this.yellowTextMobile;
      }
      if (this.isSmViewport) {
        return this.yellowTextTablet;
      }
      return this.yellowTextDesktop;
    },
    isMdViewport() {
      return this.windowWidth >= 1024;
    },
    isSmViewport() {
      return this.windowWidth <= 768 && this.windowWidth > 576;
    },
    isXsViewport() {
      return this.windowWidth <= 576;
    },
    playBtnText() {
      return `${this.isPlaying ? 'Pause' : 'Play'} Trailer`;
    },
    isLoading() {
      return false;
    },
    currentlyPlaying() {
      return this.$store.getters.episode;
    },
    isLoadingTrailer() {
      const { status } = this.$store.getters;

      return this.season_trailers.length && status === 'loading' && this.currentlyPlaying && (this.currentlyPlaying.uuid === this.season_trailers[0].uuid);
    },
    isPlayingTrailer() {
      const { play } = this.$store.getters;
      if (!this.season_trailers) {
        return false;
      }
      return this.season_trailers.length && play && this.currentlyPlaying && (this.currentlyPlaying.uuid === this.season_trailers[0].uuid);
    },
  },
  beforeMount() {
    this.getSeasonTrailers();
  },
  mounted() {
    if (!this.$store.getters.hidePlayer) {
      this.togglePlayerVisiblity(true);
    }
    this.windowWidth = window.innerWidth;
    this.windowHeight = window.innerHeight;
    window.addEventListener('resize', () => {
      this.windowWidth = window.innerWidth;
      this.windowHeight = window.innerHeight;
    });
    window.addEventListener('scroll', () => {
      this.handleScrollForStyles();
      throttle(this.checkVisibleSections, 1000);
    });
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.togglePlayerVisiblity(true);
      vm.addNoIndexMeta();
    });
  },
  beforeRouteLeave(to, from, next) {
    this.togglePlayerVisiblity(false);
    this.removeNoIndexMeta();
    next();
  },
  methods: {
    isInCTABox(event) {
      const { x, y } = event;
      const {
        top, left, bottom, right,
      } = this.$refs.cta.getBoundingClientRect();
      return x >= left && x <= right && y >= top && y <= bottom;
    },
    isInPlayBox(event) {
      const { x, y } = event;
      const {
        top, left, bottom, right,
      } = this.$refs.playBtn.getBoundingClientRect();
      return x >= left && x <= right && y >= top && y <= bottom;
    },
    cardsClickHandler(e) {
      const targetClass = (e.target && e.target.className) || null;
      if (targetClass === 'card-containers' && this.isInCTABox(e)) {
        this.handleCTAClick();
      }
      if (targetClass === 'card-containers' && this.isInPlayBox(e)) {
        this.playTrailer();
      }
    },
    handleCTAClick(btnId) {
      const { isFreeUser, isPremiumUser } = this.promoMeta;
      const routeTo = { path: '/signup' };
      if (isFreeUser) {
        routeTo.path = '/plans';
      } else if (isPremiumUser) {
        routeTo.path = '/listen/jigsaw-productions/lies-we-tell/29a522a2-600d-4382-9455-37f2ad3b7ec9';
      }
      this.$analytics.track('Bespoke CTA Click', {
        screen_name: this.$route.name,
        path: this.$route.path,
        btnId,
      });
      this.$router.push(routeTo);
    },
    togglePlayerVisiblity(bool) {
      this.$store.dispatch('toggleHidePlayer', bool);
    },
    checkVisibleSections() {
      if (!this.viewTracker) {
        this.viewTracker = [...document.getElementsByTagName('section')]
          .map(section => section.className)
          .reduce((tracker, sectionName) => {
            tracker[sectionName] = false;
            return tracker;
          }, {});
      }
      const ctx = this;
      Object.entries(this.viewTracker)
        .forEach(([secName, hasViewed]) => {
          if (hasViewed) {
            return;
          }
          const e = document.getElementsByClassName(secName) && document.getElementsByClassName(secName)[0];
          const isVisible = (e && e.getBoundingClientRect() && e.getBoundingClientRect().y < window.innerHeight) || false;
          if (isVisible) {
            ctx.viewTracker[secName] = true;
            ctx.$analytics.track('Scrolled Into View', {
              screen_name: ctx.$route.name,
              path: ctx.$route.path,
              section: secName,
            });
          }
        });
    },
    handleScrollForStyles() {
      // to move fixed hero up until at top of screen
      if (!this.navbar) {
        this.navbar = (document.getElementsByClassName('app-navbar') && document.getElementsByClassName('app-navbar')[0]) || null;
      }
      const { bottom } = this.navbar.getBoundingClientRect();
      if (bottom > -1) {
        this.$refs.hero.style.top = `${bottom}px`;
      }
      // to move fixed hero up at end up page
      if (!this.footer) {
        this.footer = (document.getElementsByClassName('dark-footer') && document.getElementsByClassName('dark-footer')[0]) || null;
      }
      const heroBottom = this.$refs.hero.getBoundingClientRect().bottom;
      const heroHeight = this.$refs.hero.getBoundingClientRect().height;
      const footerTop = this.footer.getBoundingClientRect().top;
      if (footerTop <= heroBottom || (this.yOffsetToggle && window.scrollY > this.yOffsetToggle)) {
        if (!this.yOffsetToggle) {
          this.yOffsetToggle = window.scrollY;
        }
        this.$refs.hero.style.top = `${footerTop - heroHeight}px`;
      }
      // to toggle z-index of hero
      const cardsTop = this.$refs.firstCard.getBoundingClientRect().top;
      if (cardsTop < (heroBottom + 46) && this.heroAbove) {
        this.heroAbove = false;
      } else if (cardsTop > (heroBottom + 46) && !this.heroAbove) {
        this.heroAbove = true;
      }
    },
    next() {
      this.$refs.flickity.next();
    },
    previous() {
      this.$refs.flickity.previous();
    },
    playPauseTrailer() {
      this.isPlaying = !this.isPlaying;
    },
    playTrailer() {
      const trailer = this.season_trailers[0];
      trailer.episode_type = 'trailer';
      trailer.display_image_url = this.display_image_url;
      trailer.playedFrom = 'bespoke_sdp';
      this.togglePlayerVisiblity(true);
      if (this.currentlyPlaying.uuid === trailer.uuid && this.currentlyPlaying.playedFrom === trailer.playedFrom) {
        const { play } = this.$store.getters;

        if (!play) {
          if (this.currentlyPlaying.episode_type !== 'trailer') {
            this.eventProvider.triggerEpisodePauseEvent(this.currentlyPlaying, 'trailer_select');
          }
          this.$store.dispatch('play');
          this.eventProvider.triggerTrailerPlayEvent(trailer, 'sdp_select');
        } else {
          this.$store.dispatch('pause');
          this.eventProvider.triggerTrailerPauseEvent(trailer, 'sdp_pause');
        }
      } else {
        this.$store.dispatch('setTrailerAsEpisode', false);

        if (this.currentlyPlaying.episode_type !== 'trailer') {
          this.eventProvider.triggerEpisodePauseEvent(this.currentlyPlaying, 'trailer_select');
        }
        this.$store.dispatch('setTrailerExperienceTriggered', false); // Make sure trailer experience does not trigger from here.
        this.loadMediaInPlayer(trailer.media_url, trailer.is_exclusive, trailer.episode_type, true);
        this.$store.dispatch('setNextEpisode', {}); // Empty next episode because playback should stop after trailer complete from SDP.
        this.$store.dispatch('setAndPlayEpisode', trailer);
        this.eventProvider.triggerTrailerPlayEvent(trailer, 'sdp_select');
      }
    },
    getSeasonTrailers() {
      this.podcast_service
        .getPodcastTrailers(this.showUUID)
        .then((res) => {
          this.season_trailers = res.trailers || [];
          this.season_trailers = this.season_trailers.sort((a, b) => new Date(b.released_at) - new Date(a.released_at));
        })
        .catch((error) => {
          log.error(error);
        });
    },
  },
};
</script>

<style lang='scss' scoped>
@import "../../assets/scss/variables.scss";

$heroBgColor: #0f0f0f;
$ctaBtnColor: #FFFF00;
$ctaBtnTextColor: black;
$ctaBtnFontSize: 16px;
$playBtnTextColor: white;
$playBtnFontSize: 16px;
$bespokeFont: 'BebasNeueBold', sans-serif;
$cardTopSpacing: 100vh;
$cardTopSpacingDesktop: 70vh;

.bespoke-show {
  background-color: $heroBgColor;
  color: white;
  font-family: $bespokeFont;
  display: flex;
  flex-direction: column;
  isolation: isolate;
  p, h1, h3, button {
    font-family: $bespokeFont;
    color: white;
  }
  .hidden-div {
    visibility: hidden;
  }
  .sticky-div {
    position: fixed;
    .cta-btn {
      display: flex;
      @media(max-width: $md) {
        width: 100%;
        justify-content: center;
        margin-bottom: 28px;
      }
      width: 100%;
      button {
        width: 251px;
        height: 55px;
        background-color: $ctaBtnColor;
        font-size: $ctaBtnFontSize;
        text-align: center;
        border: 0;
        border-radius: 30px;
        color: $ctaBtnTextColor;
      }
    }
  }
  .hero-above {
    z-index: 4;
  }
  .show-hero, .show-hero-for-space {
    &.hidden {
      visibility: hidden;
      position: relative !important;
    }
    padding-top: 78px;
    padding-bottom: 78px;
    width: 95vw;
    max-width: 1138px;
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    @media(max-width: $xs) {
      width: 100%;
      padding-top: 5vh;
      padding-bottom: 46px;
    }
    .hero-image-container {
      .image-container {
        width: 100%;
        display: flex;
        justify-content: center;
        margin-left: auto;
        margin-right: auto;
        @media(min-width: $md) {
          max-width: 661px;
        }
        @media(max-width: $sm) {
          width: 83vw;
          margin-bottom: 19px;
        }
        img {
          width: 100%;
          align-self: flex-start;
          @media(max-width: $xs) {
            object-fit: contain;
          }
          @media(min-width: $xs) {
            width: 90%;
          }
          @media(min-width: 1024px) and (max-width: 1200px) {
            max-width: 85%;
            padding-left: 5%;
          }
        }
      }
    }
    h1 {
      font-size: 67px;
      font-weight: bold;
      line-height: 1.15;
      margin-top: 30px;
      font-size: 46px;
      font-weight: 900;
      margin-bottom: 46px;
      padding-left: 18px;
      padding-right: 18px;
      text-align: center;
      @media(max-width: $lg) {
        margin-top: 24px;
        margin-bottom: 16px;
        text-align: center;
        font-size: 35px;
        max-width: 78vw;
        margin-left: auto;
        margin-right: auto;
      }
      @media(max-width: $xs) {
        max-width: 236px;
        font-size: 27px !important;
        margin-bottom: 20px;
      }
      @media(min-width: $md) and (max-width: $lg) {
        font-size: 37px;
      }
    }
    h3 {
      font-size: 24px;
      line-height: 1.5;
      margin-bottom: 69px;
      padding-left: 18px;
      padding-right: 22px;
      text-align: left;
      @media(max-width: $xs) {
        font-size: 16px;
        padding-left: 0px;
        padding-right: 0px;
        line-height: 1.5;
        text-align: left !important;
      }
      @media(max-width: $md) {
        max-width: 80%;
        margin-left: auto;
        margin-right: auto;
        line-height: 30px;
        text-align: center;
        margin-bottom: 38px;
      }
    }
    .hero-btns {
      display: flex;
      padding-left: 18px;
      padding-right: 22px;
      flex-direction: column;
      justify-content: center;
      @media(min-width: $xs) and (max-width: $md) {
        margin-left: auto;
        margin-right: auto;
        padding-left: 0px;
        padding-right: 0px;
        width: 82vw;
        margin-top: 30px;
        flex-direction: row;
      }
      .play-trailer-btn {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 49px;
        @media(max-width: $xs) {
          width: 100%;
          justify-content: center;
          margin-bottom: 24px;
        }
        @media(min-width: $xs) and (max-width: $sm) {
          width: 50%;
          justify-content: center;
        }
        img, svg {
          width: 59px;
          height: 59px;
          margin-right: 19px;
        }
        span {
          color: $playBtnTextColor;
          font-size: 27px;
        }
        .pause-btn {
          transform: scale(.5) translateX(-3px);
        }
      }
      .cta-btn {
        display: flex;
        z-index: 9980;
        height: 55px;
        justify-content: center;
        @media(max-width: $xs) {
          width: 100%;
          justify-content: center;
        }
        @media(min-width: $xs) and (max-width: $sm) {
          width: 59%;
          justify-content: center;
        }
        width: 100%;
        button {
          padding-top: 7px;
          transition: 500ms ease-in-out all;
          width: 251px;
          height: 59px;
          background-color: $ctaBtnColor;
          font-size: 27px;
          text-align: center;
          border: 0;
          border-radius: 30px;
          color: $ctaBtnTextColor;
        }
      }
    }
  }

  .cool-cards-bg {
    margin-top: $cardTopSpacing;
    @media(min-width: $md) {
      margin-top: $cardTopSpacingDesktop;
    }
    position: absolute;
    width: 100%;
    max-width: 1200px;
    top: 0;
    margin-left: auto;
    margin-right: auto;
    left: 50%;
    transform: translateX(-50%);
    mix-blend-mode: lighten;
    .card-bgs {
      // mix-blend-mode: screen;
      z-index: 0;
      .card-bg-container {
        box-sizing: border-box;
        padding-top: 79px;
        padding-bottom: 79px;
        display: block;
        width: 90%;
        margin-left: 5%;
        margin-right: 5%;
        text-align: center;
        padding-left: 5%;
        padding-right: 5%;
        position: relative;
        margin-bottom: 200px;
        .card-bg-color {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          &.pink {
            background-color: rgba(255, 0, 255, 1);
          }
          &.cyan {
            background-color: rgba(0, 255, 255, 1);
          }
          &.yellow {
            background-color: rgba(255, 255, 0, 1);
          }
        }
        p {
          @media(max-width: $md) {
            font-size: 42px;
          }
          visibility: hidden;
          line-height: normal;
          font-size: 60px;
          font-weight: bold;
          color: black;
        }
      }
    }
  }
  .cool-cards {
    margin-top: $cardTopSpacing;
    @media(min-width: $md) {
      margin-top: $cardTopSpacingDesktop;
    }
    position: absolute;
    top: 0;
    width: 100%;
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
    left: 50%;
    transform: translateX(-50%);
    .card-containers {
      position: absolute;
      width: 100%;
      z-index: 1;
      .card-container {
        box-sizing: border-box;
        padding-top: 79px;
        padding-bottom: 79px;
        display: block;
        width: 90%;
        margin-left: 5%;
        margin-right: 5%;
        text-align: center;
        padding-left: 5%;
        padding-right: 5%;
        position: relative;
        margin-bottom: 200px;
        .card-tint-bg {
          z-index: 0;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          &.pink {
            background-color: rgba(200,0,200,0.7);
          }
          &.cyan {
            background-color: rgba(0, 200, 200, 0.3);
          }
          &.yellow {
            background-color: rgba(200, 200, 0, 0.17);
          }
        }
        p {
          @media(max-width: $md) {
            font-size: 42px;
          }
          z-index: 1;
          position: relative;
          line-height: normal;
          font-size: 60px;
          font-weight: bold;
          color: black;
        }
      }
    }
  }
  .card-containers-for-spacing {
    @media(min-width: $md) {
      margin-bottom: 70vh;
    }
    margin-bottom: 116vh;
    z-index: 1;
    visibility: hidden;
    .card-container {
      box-sizing: border-box;
      padding-top: 79px;
      padding-bottom: 79px;
      display: block;
      width: 90%;
      margin-left: 5%;
      margin-right: 5%;
      text-align: center;
      padding-left: 5%;
      padding-right: 5%;
      position: relative;
      margin-bottom: 200px;
      .card-tint-bg {
        background-color: rgba(255,0,255,0.5);
        z-index: 0;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, .3);
      }
      p {
        @media(max-width: $md) {
          font-size: 42px;
        }
        z-index: 1;
        position: relative;
        line-height: normal;
        font-size: 60px;
        font-weight: bold;
        color: black;
      }
    }
  }

  .pulse {
    animation-name: pulse;
    animation-duration: 800ms;
    animation-fill-mode: both;
    animation-iteration-count: infinite;
  }
}
@keyframes pulse {
  from {
    transform: scale3d(1, 1, 1);
  }

  50% {
    transform: scale3d(1.05, 1.05, 1.05);
  }

  to {
    transform: scale3d(1, 1, 1);
  }
}
</style>
