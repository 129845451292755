import countries from '../assets/json/countries.json';
import amountFilter from './amountFilter';


class ShapeProductsResponse {
  constructor() {
    this.countries = countries;
    this.config = {
      annualPos: 0,
      monthlyPos: 1,
    };
  }

  getCountry() {
    let countryCode = (window.$store && window.$store.getters.country) || 'US';
    countryCode = countryCode.toLowerCase();
    let country = this.countries.find(nextItem => nextItem.code.toLowerCase() === countryCode);
    // if user belongs to country other than US/UK/AU/CA then show pricing for US
    if (!country) {
      country = this.countries.find(nextItem => nextItem.code === 'US');
    }
    return country;
  }

  validatePriceLocally(pricing) {
    // if user have subscribed before and country in user's subscription record is different from default country
    // then set default pricing of country found in user's subscription record
    const userSubscription = window.$store && window.$store.getters.userSubscription;
    if (userSubscription && userSubscription.totalPrice) {
      const { totalPrice, country, currency } = userSubscription;
      const targetCountry = this.countries.find(countryCode => countryCode === country);
      if (totalPrice > 0) {
        return {
          price: totalPrice,
          currency,
        };
      }
      const defaultCountryPrice = targetCountry && targetCountry.price
        ? targetCountry.price
        : pricing.price;
      return {
        price: defaultCountryPrice,
        currency,
      };
    }
    return pricing;
  }

  getPricing(productRatePlanCharges, manualCurrencyCode) {
    const country = this.getCountry();
    const currency = manualCurrencyCode || country.currency;
    let pricing = productRatePlanCharges.pricing
      .find(item => item.currency === currency);
    pricing = this.validatePriceLocally(pricing);
    return pricing;
  }

  getPricingPlans({ response, currencyCode }) {
    const ratePlans = response.products[0].productRatePlans;
    const plans = ratePlans
      .map((ratePlan) => {
        const ratePlanId = ratePlan.id;
        const isBestValue = ratePlan.is_best_value || false;
        const productRatePlanCharges = ratePlan.productRatePlanCharges[0];
        const pricing = this.getPricing(productRatePlanCharges, currencyCode);
        let displayName = null;
        let planTypeString = null;
        let billingFrequency = 'monthly';
        let displayPaymentPeriod = 'month';
        let paidPeriod = 1;
        if (ratePlan.PlanType === 'monthly') {
          displayName = 'Monthly';
          planTypeString = ratePlan.PlanType;
        } else if (ratePlan.PlanType === 'yearly') {
          displayName = 'Annual';
          billingFrequency = 'annually';
          planTypeString = ratePlan.PlanType;
          displayPaymentPeriod = '12 months';
          paidPeriod = 12;
        } else if (ratePlan.PlanType === 'semi-annual') {
          displayName = 'Semi-Annual';
          billingFrequency = 'semi-annually';
          planTypeString = ratePlan.PlanType;
          displayPaymentPeriod = '6 months';
          paidPeriod = 6;
        }
        const displayPrice = amountFilter(pricing.price, pricing.currency, true) || '';
        const breakdownPrice = amountFilter(pricing.marketing_price, pricing.currency, true) || pricing.marketing_price_str;
        const trialPeriodForDisplay = ratePlan.TrialPeriod || '';
        const trialPeriodDays = Number((ratePlan.TrialPeriod && ratePlan.TrialPeriod.match(/\d+/)[0]) || 0);
        return {
          ...pricing,
          id: ratePlanId,
          name: ratePlan.name,
          displayName,
          displayPrice,
          breakdownPrice,
          isBestValue,
          billingFrequency,
          displayPaymentPeriod,
          planTypeString,
          trialPeriodForDisplay,
          trialPeriodDays,
          paidPeriod,
        };
      });
    const maxMarketingPrice = Math.max(...plans.map(p => p.marketing_price));
    return plans.map(p => ({
      ...p,
      discountPercent: Math.floor(((maxMarketingPrice - p.marketing_price) / maxMarketingPrice) * 100),
    }));
  }
}

export default ShapeProductsResponse;
