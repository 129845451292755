<template>
  <div class="quotes">
    <div class="quotes-header">Award-Winning Content</div>
    <div class="quotes-container">
      <flickity
        v-if="windowWidth && showFlickHack && showFlickity"
        ref="flickity"
        :options="flickityOptions"
        class="quotes-flickity"
      >
        <div
          v-for="(item, i) in items"
          :key="i"
          class="quotes-item"
        >
          <div class="quotes-logo-wrapper">
            <img
              :src="item.logo"
              :style="item.style"
              :alt="item.alt"
              class="quotes-logo"
            />
          </div>
          <div class="quotes-text" v-html="item.text" />
        </div>
      </flickity>
      <div v-else-if="!showFlickity" class="quotes-static">
        <div
          v-for="(item, i) in items"
          :key="i"
          class="flat-quotes-item"
        >
          <div class="quotes-logo-wrapper">
            <img
              :src="item.logo"
              :style="item.style"
              :alt="item.alt"
              class="quotes-logo"
            />
          </div>
          <div class="quotes-text" v-html="item.text" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Flickity from 'vue-flickity';
import debounce from 'lodash/debounce';
import adweek from '../../../assets/images/home/adweek.png';
import webby from '../../../assets/images/home/webby.png';

let degouncedResize = null;

export default {
  name: 'HomePageMediaQuotes',
  components: {
    Flickity,
  },
  data() {
    return {
      windowWidth: null,
      items: [
        {
          logo: webby, text: 'Best Series of 2020:<br/>Spooked', style: { height: '40px' }, alt: 'The Webby Awards',
        },
        {
          logo: adweek, text: 'Hosts of the Year:<br/>Hear to Slay', style: { height: '40px' }, alt: 'Adweek',
        },
        {
          logo: webby, text: 'Best Documentary of 2020:<br/>Fiasco', style: { height: '40px' }, alt: 'The Webby Awards',
        },
      ],
      showFlickHack: true,
    };
  },
  computed: {
    flickityOptions() {
      return {
        prevNextButtons: false,
        draggable: true,
        contain: true,
        pageDots: false,
        wrapAround: true,
        setGallerySize: false,
        adaptiveHeight: true,
      };
    },
    showFlickity() {
      return false; // this.windowWidth && this.windowWidth < 768;
    },
  },
  watch: {
    windowWidth() {
      if (!this.$refs.flickity) return;
      this.showFlickHack = false;
      this.$nextTick(() => {
        this.showFlickHack = true;
      });
    },
  },
  mounted() {
    this.handleResize();
    degouncedResize = debounce(this.handleResize, 200);
    window.addEventListener('resize', degouncedResize, { passive: true });
  },
  beforeDestroy() {
    window.removeEventListener('resize', degouncedResize);
  },
  methods: {
    handleResize() {
      this.windowWidth = window.innerWidth;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../assets/scss/variables.scss";
@import "../../../assets/scss/mixins.scss";

.quotes {
  text-align: center;
  z-index: 1;
  &-header {
    font-size: 40px;
    line-height: 47px;
    text-align: center;
    color: #FFFFFF;
    margin-top: 60px;
    margin-bottom: 40px;
  }
  &-container {
    margin: 0 auto;
    max-width: $max-width-threshold;
  }
  &-flickity {
    outline: none;
    height: 150px;
  }
  &-item {
    height: 150px;
    width: 50%;
    text-align: center;
  }
  &-logo-wrapper {
    height: 60px;
  }
  &-logo {
    color: white;
  }
  &-text {
    font-size: 14px;
    line-height: 22px;
    text-align: center;
    color: #FFFFFF;
    padding: 0 10px;
  }
  &-static {
    display: flex;
    flex-flow: row nowrap;
    .flat-quotes-item {
      text-align: center;
      flex: 0 0 33%;
      height: 150px;
      .quotes-text {
        max-width: 150px;
        margin: 0 auto;
      }
    }
  }
}
</style>
