<template>
  <div class="blurred-bg">
    <div class="scrollme center-blur">
      <div class="animateme center-blur" data-when="span" data-from="0" data-easing="easeinout" data-to="0.8" data-opacity="1" data-scale="10">
        <div class="center-blur">
          <div class="blur-1"></div>
        </div>
      </div>
    </div>
    <div class="scrollme center-blur">
      <div class="animateme center-blur" data-when="span" data-from="0" data-easing="easeinout" data-to="1" data-opacity="1" data-scale="4">
        <div class="center-blur">
          <div class="blur-2"></div>
        </div>
      </div>
    </div>
    <div class="scrollme center-blur">
      <div class="animateme center-blur" data-when="span" data-from="0.5" data-easing="easeinout" data-to="1" data-opacity="0" data-scale="4">
        <div class="center-blur">
          <div class="blur-3"></div>
        </div>
      </div>
    </div>
    <div class="scrollme center-blur">
      <div class="animateme center-blur" data-when="span" data-from="0.3" data-easing="easeinout" data-to="1" data-opacity="1" data-scale="4">
        <div class="center-blur">
          <div class="blur-4"></div>
        </div>
      </div>
    </div>
    <div class="scrollme center-blur">
      <div class="animateme center-blur" data-when="span" data-from="0.1" data-easing="easeinout" data-to="0.8" data-opacity="1" data-scale="5">
        <div class="center-blur">
          <div class="blur-6"></div>
        </div>
      </div>
    </div>
    <div class="scrollme center-blur">
      <div class="animateme center-blur" data-when="span" data-from="0.4" data-easing="easeinout" data-to="1" data-opacity="1" data-scale="6">
        <div class="center-blur">
          <div class="blur-5"></div>
        </div>
      </div>
    </div>
    <div class="blurred-overlay"></div>
    <div class="gradient-overlay"></div>
    <div class="midnight-foot"></div>
  </div>
</template>

<script>

export default {
  data() {
    return {
      body_height: 0,
      viewport_height: 0,
      viewport_top: 0,
      viewport_bottom: 0,
      viewport_top_previous: -1,
      elements: [],
      elements_in_view: [],
      property_defaults: {
            'opacity' : 1,
            'scale' : 1,
            'scalex' : 1,
            'scaley' : 1,
            'scalez' : 1
      },
      scrollme_selector: '.scrollme',
      animateme_selector: '.animateme',
      update_interval: 50,
      // Easing functions
      easing_functions: {
        'linear' : function( x ) {return x;},
        'easeout' : function( x ) {return x * x * x;},
        'easein' : function( x ) {x = 1 - x;return 1 - ( x * x * x );},
        'easeinout' : function( x ) {
          if( x < 0.5 ) {return ( 4 * x * x * x );}
          else {x = 1 - x;return 1 - ( 4 * x * x * x ) ;}
        }
      },
      resize_func: null,
      load_func: null,
      updateInterval: null,
    }
  },
  mounted() {
    this.init_elements();
    this.on_resize();
    window.addEventListener('scroll', this.update_elements);
    // this.updateInterval = setInterval( this.update_elements , this.update_interval );
  },
  destroyed() {
    if (this.updateInterval) clearInterval(this.updateInterval);
  },
  methods: {
    init_elements() {
      // For each reference element
      const scroll_items = this.$el.querySelectorAll(this.scrollme_selector);

      scroll_items.forEach( (item) => {
        let element = {};
        element.element = item;
        let effects = [];
        // For each animated element
        item.querySelectorAll( this.animateme_selector ).forEach( ( animate_element ) => {
          // Get effect details
          let effect = {};
          effect.element = animate_element;
          effect.when = effect.element.getAttribute("data-when");
          effect.from = effect.element.getAttribute("data-from");
          effect.to = effect.element.getAttribute("data-to");

          if( effect.element.hasAttributes( 'data-easing' ) ) effect.easing = this.easing_functions[ effect.element.getAttribute( 'data-easing' ) ]
          else effect.easing = this.easing_functions[ 'easeout' ];

          // Get animated properties
          let properties = {};
          if( effect.element.hasAttributes( 'data-opacity' ) ) properties.opacity = effect.element.getAttribute( 'data-opacity' );
          if( effect.element.hasAttributes( 'data-scale' ) )      properties.scale      = effect.element.getAttribute( 'data-scale' );
          if( effect.element.hasAttributes( 'data-scalex' ) )     properties.scalex     = effect.element.getAttribute( 'data-scalex' );
          if( effect.element.hasAttributes( 'data-scaley' ) )     properties.scaley     = effect.element.getAttribute( 'data-scaley' );
          if( effect.element.hasAttributes( 'data-scalez' ) )     properties.scalez     = effect.element.getAttribute( 'data-scalez' );

          effect.properties = properties;
          effects.push( effect );
        });

        element.effects = effects;
        this.elements.push( element );
      });
    },

    // ----------------------------------------------------------------------------------------------------
    // Animate stuff
    animate() {
      // For each element in viewport
      let elements_in_view_length = this.elements_in_view.length;
      for( let i=0 ; i<elements_in_view_length ; i++ ) {
        let element = this.elements_in_view[i];
        let effects_length = element.effects.length;
        for( let e=0 ; e<effects_length ; e++ ) {
          let effect = element.effects[e];
          let top = 320;
          let scroll = 1 - (top - this.viewport_top) / top; // ( this.viewport_top - start ) / ( end - start );

          // Get relative scroll position for effect
          let from = effect[ 'from' ];
          let to = effect[ 'to' ];
          let length = to - from;
          let scroll_relative = scroll * length;

          let scroll_eased = effect.easing( scroll_relative );

          // Get new value for each property
          let opacity    = this.animate_value( scroll , scroll_eased , from , to , effect , 'opacity' );
          let scale      = this.animate_value( scroll , scroll_eased , from , to , effect , 'scale' );
          let scalex     = this.animate_value( scroll , scroll_eased , from , to , effect , 'scalex' );
          let scaley     = this.animate_value( scroll , scroll_eased , from , to , effect , 'scaley' );
          let scalez     = this.animate_value( scroll , scroll_eased , from , to , effect , 'scalez' );

          // Override scale values
          if( 'scale' in effect.properties ) {
            scalex = scale;
            scaley = scale;
            scalez = scale;
          }

          // Update properties
          const style = "opacity:" + opacity + "; transform: scale3d(" + scalex + "," + scaley + "," + scalez + ")";
          effect.element.style = style;
        }
      }
    },

    // ----------------------------------------------------------------------------------------------------
    // Calculate property values
    animate_value( scroll , scroll_eased , from , to , effect , property ) {
      let value_default = this.property_defaults[ property ];
      // Return default value if property is not animated
      if( !( property in effect.properties ) ) return value_default;
      let value_target = effect.properties[ property ];
      let forwards = ( to > from ) ? true : false;

      // Return boundary value if outside effect boundaries
      if( scroll < from && forwards ) { return value_default; }
      if( scroll > to && forwards ) { return value_target; }
      if( scroll > from && !forwards ) { return value_default; }
      if( scroll < to && !forwards ) { return value_target; }

      // Calculate new property value
      let new_value = value_default + ( scroll_eased * ( value_target - value_default ) );

      // Round as required
      switch( property ) {
        case 'opacity'    : new_value = new_value.toFixed(2); break;
        case 'scale'      : new_value = new_value.toFixed(3); break;
        default : break;
      }

      // Done
      return new_value;
    },

    // ----------------------------------------------------------------------------------------------------
    // Update viewport position
    update_viewport_position() {
      this.viewport_top = window.pageYOffset;
      this.viewport_bottom = this.viewport_top + this.viewport_height;
    },

    // ----------------------------------------------------------------------------------------------------
    // Update list of elements in view
    update_elements_in_view() {
      this.elements_in_view = [];
      let elements_length = this.elements.length;

      for( let i=0 ; i<elements_length ; i++ ) {
        if ( ( this.elements[i].element.getBoundingClientRect().top < this.viewport_bottom ) &&
            ( this.elements[i].element.getBoundingClientRect().bottom > this.viewport_top ) ) {
          this.elements_in_view.push( this.elements[i] );
        }
      }
    },

    // ----------------------------------------------------------------------------------------------------
    // Stuff to do on resize,
    on_resize() {
      // Update viewport/element data
      this.update_viewport();

      // Update display
      this.update_viewport_position();
      this.update_elements_in_view();
      this.animate();
    },

    // ----------------------------------------------------------------------------------------------------
    // Update viewport parameters
    update_viewport() {
      let body = document.body, html = document.documentElement;
      this.body_height = Math.max( body.scrollHeight, body.offsetHeight,
          html.clientHeight, html.scrollHeight, html.offsetHeight );
      this.viewport_height = window.innerHeight;
    },

    // ----------------------------------------------------------------------------------------------------
    // Update elements
    update_elements() {
      window.requestAnimationFrame( () => {
        this.update_viewport_position();
        if( this.viewport_top_previous != this.viewport_top )
        {
          this.update_elements_in_view();
          this.animate();
        }
        this.viewport_top_previous = this.viewport_top;
      });
    },

  },
};
</script>

<style lang="scss" scoped>
/* Blurred BG */

.blurred-bg{
  height:100vh;
  width:100vw;
  position:absolute;
  overflow:hidden;
  z-index: 0;
}

.center-blur {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.blur-1 {
  height: 200px;
  width: 200px;
  background-color: #DDA40A;
  border-radius: 50%;
  top: 50%;
  left: 50%;
  opacity: 1;
}

.blur-2 {
  height: 498px;
  width: 498px;
  background-color: #5289ED;
  border-radius: 50%;
  /*            -webkit-filter:blur(50px);*/
}

.blur-3 {
  height: 460px;
  width: 460px;
  background-color: #D40363;
  border-radius: 50%;
  /*            -webkit-filter:blur(50px);*/
}

.blur-4 {
  height: 288px;
  width: 288px;
  background-color: #FFE500;
  border-radius: 50%;
  /*            -webkit-filter:blur(50px);*/
}

.blur-5 {
  height: 270px;
  width: 270px;
  background-color: #121C30;
  border-radius: 50%;
  /*            -webkit-filter:blur(50px);*/
}

.blur-6 {
  height: 270px;
  width: 270px;
  background-color: #27B88B;
  border-radius: 50%;
  /*            -webkit-filter:blur(50px);*/
}

.blurred-overlay {
  height:100vh;
  width:100vw;
  position:absolute;
  background-color: rgba(255, 255, 255, 0) !important;
  backdrop-filter: blur(30px);
  -webkit-backdrop-filter: blur(30px);
}

.gradient-overlay {
  height:70vh;
  width:100vw;
  position:absolute;
  background: linear-gradient(rgba(18,28,48,0), rgba(18,28,48,1));
}

.midnight-foot {
  height:30vh;
  width:100vw;
  position:absolute;
  background: linear-gradient(rgba(18,28,48,1), rgba(18,28,48,1));
  top:70vh;
}
</style>
