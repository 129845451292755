<template>
  <section
    v-check-visible-viewport="onVisibleInViewport"
    class="container carousel-layout carousel-has-hoverstate">
    <el-row
      type="flex"
      justify="center">
      <grid-col
        :xs="24"
        :sm="24"
        :md="23"
        :lg="22"
        :xl="22">
        <div
          class="clipped-overlay-left"/>
        <div
          class="clipped-overlay-right"/>
        <h3
          class="widget-title">
          {{ widget.title }}
        </h3>
        <loading-items
          v-if="!isItemComponentLoaded"
          :num-loading="numLoading"
        />
        <flickity
          v-if="isItemComponentLoaded"
          ref="flickity"
          :options="flickityOptions"
          :key="widget.id">
          <div
            v-for="(item, index) in widget.items"
            :ref="'container-' + row + '-' + index"
            :key="item.id"
            :data-segment-uuid="item.uuid"
            class="carousel-container"
            data-test="carousel-container">
            <component
              :ref="'card-' + row + '-' + index"
              :is="itemComponent"
              :item="item"
              :widget="widget"
              :page-id="pageId"
              :page-target="pageTarget"
              :index="index"
              :has-hover-state="true"
              :redirect="redirect"
              :first-item="index === 0" />
          </div>
        </flickity>
      </grid-col>
    </el-row>
  </section>
</template>

<script>
import * as log from 'loglevel';
import Flickity from 'vue-flickity';
import Widget from '../../../services/Widget';
import LoadingItems from './LoadingItems';
import widgetHelper from '../widgetHelper';
import widgetDictionary from '../widgetDictionary'; // eslint-disable-line
import CarouselMixin from '../../../shared/carouselMixin';

export default {
  name: 'CarouselLayout',
  components: {
    Flickity,
    'loading-items': LoadingItems,
  },
  mixins: [CarouselMixin],
  props: {
    widget: {
      type: Object,
      default() {
        return {};
      },
      id: {
        type: String,
        default: '',
      },
      title: {
        type: String,
        default: '',
      },
      layoutType: {
        type: String,
        default: 'carousel',
      },
      items: {
        type: Array || null,
        default: [],
      },
      itemStyleType: {
        type: String,
        default: 'default',
      },
      itemType: {
        type: String,
        default: 'show',
      },
      serviceType: {
        type: String,
        default: 'browse',
      },
      widgetType: {
        type: String,
        default: 'service',
      },
      isPreloaded: {
        type: Boolean,
        default: false,
      },
    },
    pageId: {
      type: String,
      default: '',
    },
    pageTarget: {
      type: String,
      default: '',
    },
    row: {
      type: Number,
      default: 0,
    },
    numLoading: {
      type: Number,
      default: 8,
    },
  },
  data() {
    return {
      widgetService: new Widget(),
      flickityOptions: {
        prevNextButtons: true,
        freeScroll: true,
        pageDots: false,
        wrapAround: false,
        cellAlign: 'left',
        initialIndex: 0,
        contain: true,
        draggable: true,
        groupCells: true,
      },
      redirect: true,
      drag_end: false,
      itemComponent: null,
      isItemComponentLoaded: false,
    };
  },
  mounted() {
    this.getCompactItems(this.widget);
    this.dragStart();
    this.carouselSettled();
    this.carouselChange();
    this.arrowVisibility();
  },
  beforeDestroy() {
    this.removeResizeEvent();
  },
  methods: {
    getCompactItems(widget) {
      if (!widget.isPreloaded) {
        const relativeIndex = widget.serviceUrlFull.indexOf('/v2/podcasts');
        const relativeServiceUrlFull = widget.serviceUrlFull.substring(relativeIndex + 1);
        this.widgetService.getWidgetItems(relativeServiceUrlFull)
          .then((resp) => {
            this.widget.items = resp;
            this.getItemComponent(this.widget);
            const { bucket, bucketItemsSeen } = widgetHelper.formatConentViewedEventData(this.pageTarget, this.widget);

            this.eventProvider.triggerContentViewedEvent(bucket, bucketItemsSeen, this.pageId);
            this.updateTabIndexes();
          })
          .catch((error) => {
            log.error(error);
          });
      } else {
        this.getItemComponent(this.widget);
        const { bucket, bucketItemsSeen } = widgetHelper.formatConentViewedEventData(this.pageTarget, this.widget);

        this.eventProvider.triggerContentViewedEvent(bucket, bucketItemsSeen, this.pageId);
        this.updateTabIndexes();
      }
    },
    getItemComponent(widget) {
      const { layoutType, itemStyleType, itemType } = widget;

      const componentString = widgetHelper.getWidgetItemString(layoutType, itemStyleType, itemType);
      this.itemComponent = widgetDictionary.items[componentString];
      this.isItemComponentLoaded = true;
    },
    dragStart() {
      this.$nextTick()
        .then(() => {
          this.$refs.flickity.on('dragStart', () => {
            this.drag_end = false;
            this.redirect = false;
          });
        })
        .catch(() => { /* noop */ });
    },
    dragEnd() {
      this.$nextTick()
        .then(() => {
          this.$refs.flickity.on('dragEnd', () => {
            this.drag_end = true;
          });
        })
        .catch(() => { /* noop */ });
    },
    carouselSettled() {
      this.$nextTick()
        .then(() => {
          this.$refs.flickity.on('settle', () => {
            this.redirect = true;
          });
        })
        .catch(() => { /* noop */ });
    },
    carouselChange() {
      this.$nextTick()
        .then(() => {
          this.$refs.flickity.on('change', () => {
            this.updateTabIndexes(750);
          });
        })
        .catch(() => { /* noop */ });
    },
    arrowVisibility() {
      this.checkWindowSizes();
      window.addEventListener('resize', this.checkWindowSizes);
    },
    removeResizeEvent() {
      window.removeEventListener('resize', this.checkWindowSizes);
    },
    checkWindowSizes() {
      if (!this.$refs.flickity) return;
      this.$nextTick()
        .then(() => {
          if (window.innerWidth < 769) {
            this.hideArrows();
          } else {
            this.showArrows();
          }
        })
        .catch(err => log.info(err.message));
    },
    hideArrows() {
      const a = this.$el.querySelector('.flickity-button.next');
      const b = this.$el.querySelector('.flickity-button.previous');
      if (a) this.$el.querySelector('.flickity-button.next').className += ' d-none';
      if (b) this.$el.querySelector('.flickity-button.previous').className += ' d-none';
    },
    showArrows() {
      const elem = this.$el.querySelectorAll('.flickity-button.next,.flickity-button.previous');
      if (!elem[0] || !elem[1]) return;
      if (elem[0].classList.contains('d-none')) {
        this.$el.querySelector('.flickity-button.next').classList.remove('d-none');
      }
      if (elem[1].classList.contains('d-none')) {
        this.$el.querySelector('.flickity-button.previous').classList.remove('d-none');
      }
    },
    updateTabIndexes(delay = 0) {
      setTimeout(() => {
        this.widget.items.forEach((item, index) => {
          const card = `card-${this.row}-${index}`;
          const container = `container-${this.row}-${index}`;
          if (!this.$refs[container] || !this.$refs[container][0] || !this.$refs[card] || !this.$refs[card][0] || !this.$refs[card][0].$el) {
            return;
          }
          const e = this.$refs[container][0].getBoundingClientRect();
          this.$refs[card][0].$el.tabIndex = ((e.left > (window.innerWidth) * 0.05) && (e.left < (window.innerWidth) * 0.8)) ? 0 : -1;
        });
      }, delay);
    },
  },
};
</script>

<style lang="scss">
@import "../../../assets/scss/variables.scss";

.carousel-layout {
  .clipped-overlay-left {
    position: absolute;
    margin-top: 38px;
    height: 320px;
    width: 30vw;
    background-color: $bg-color;
    background: linear-gradient(90deg, rgba(18,28,48,1) 87%, rgba(18,28,48,0) 100%);
    margin-left: -30vw;
    box-sizing: border-box;
    z-index: 1;
    opacity: .9;
    @media (max-width: $lg) {
      height: 310px;
    }
    @media (max-width: $md) {
      height: 280px;
    }
    @media (max-width: $sm) {
      height: 240px;
    }
    @media (max-width: 460px) {
      height: 160px;
    }
  }
  .clipped-overlay-right {
    position: absolute;
    margin-top: 38px;
    height: 320px;
    width: 30vw;
    right: calc(-30vw + 5%);
    background-color: $bg-color;
    background: linear-gradient(90deg, rgba(18,28,48,0) 0%, rgba(18,28,48,1) 14%);;
    box-sizing: border-box;
    z-index: 1;
    opacity: .9;
    @media (max-width: $lg) {
      height: 310px;
    }
    @media (max-width: $md) {
      height: 280px;
    }
    @media (max-width: $sm) {
      height: 240px;
    }
    @media (max-width: 460px) {
      height: 160px;
    }
  }
  &.carousel-has-hoverstate {
    .widget-title {
      font-size: 24px;
      line-height: 40px;
      font-weight: 500;
      // margin: 0 0 -56px; /* negative value makes room for hover state expansion */
      transition: 120ms ease-in-out;
      z-index: 90;
      color: $white;
      @media (max-width: $xs) {
        font-size: 20px;
        line-height: 23px;
      }
    }

    div.flickity-viewport {
      padding: 0 0 60px; /* add padding for hover state expansion */
      overflow: visible;

      @media (max-width: 768px) {
        padding: 0 0 30px;
      }

      @media (max-width: $xs) {
        padding: 0 0 15px;
      }
    }
  }

  .flickity-enabled {
    .flickity-button {
      z-index: 99;
      top: 38%;
      background-color: rgba(255,255,255, 0.9) !important;
      box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.2), 0px 3px 14px rgba(0, 0, 0, 0.12), 0px 8px 10px rgba(0, 0, 0, 0.14);
      @media (min-width: 768px) {
        display: block !important;
        &[disabled] {
          display: none !important;
        }
      }
      svg {
        fill: #00AECB;
        width: 50%;
        height: 50%;
        left: 25%;
        top: 25%;
      }
    }
  }
  .flickity-button.next,
  .flickity-button.previous {
    @media (max-width: 768px) {
      display: none;
    }
  }
}
</style>
