<template>
  <div class="home-page">
    <HomeV2 v-if="!isLoading && !is_homepage_v2_exp"/>
    <HomeV2Exp v-if="!isLoading && is_homepage_v2_exp"/>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import HomeV2 from './Home';
import HomeV2Exp from './HomeExp';

export default {
  name: 'HomeWrapper',
  components: {
    HomeV2,
    HomeV2Exp,
  },
  data() {
    return {
      is_homepage_v2_exp: false,
      isLoading: true,
    };
  },
  computed: {
    ...mapGetters(['featureFlag']),
  },
  beforeMount() {
    this.getFeatureFlag();
  },
  methods: {
    getFeatureFlag() {
      this.isLoading = false;
      this.is_homepage_v2_exp = this.featureFlag('homepage_v2');
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/scss/variables.scss";

.home-page {
  background-color: $bg-color;
}
</style>
