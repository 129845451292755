import AdyenCheckout from '@adyen/adyen-web';
import '@adyen/adyen-web/dist/adyen.css';

let adyenDropin;

export default {
  get isReady() {
    return !!adyenDropin;
  },
  get isValid() {
    return adyenDropin && adyenDropin.isValid;
  },
  get instance() {
    return adyenDropin;
  },
  create(checkoutConf, dropinConf) {
    const checkout = new AdyenCheckout(checkoutConf);
    adyenDropin = checkout.create('dropin', dropinConf);
    return adyenDropin;
  },
  getReturnUrl(route, location) {
    const query = [
      ...Object.entries(route.query)
        .filter(([k]) => (k !== 'proc' && k !== 'redirectResult'))
        .map(([k, v]) => `${k}=${v}`),
      'proc=payment',
    ];
    return `${location.origin}${route.path}?${query.join('&')}`;
  },
};
