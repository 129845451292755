<template>
  <div
    class="episode-details detail-page theme-background"
    data-test="page-episodedetail">
    <PremiumBanner
      v-if="shouldShowPremiumBanner"
      :show="episodeData"
      :title="episodeData.season.podcast.title"
      class="hidden-xs-only"
    />

    <loader
      v-show="!episodeDataLoaded"
      :center="true"
      label="Fetching Episode Details..."
    />
    <el-row
      v-if="episodeData && episodeDataLoaded"
      type="flex"
      class="container breadcrumb-and-social-icons">
      <el-col :span="12">
        <Breadcrumb
          v-if="breadcrumbTrail.length"
          :trail="breadcrumbTrail"
        />
      </el-col>
      <el-col :span="12">
        <SocialShareDialog
          :show-data="episodeData"
          type="episode"
          class="t-r"
        />
      </el-col>
    </el-row>
    <div
      v-if="episodeData && episodeDataLoaded"
      class="episode-details__info container"
      data-test="section-episodedetail">
      <div :class="`text ${(isShowPremium && isEpisodeFree) ? 'is-free' : ''}`">

        <FreeEpisode
          v-if="isShowPremium && isEpisodeFree"
          :absolute="false"
        />

        <div class="show-image responsive hidden-sm-and-up">
          <picture-component
            :src="episodeData.display_image_url"
            :progressive-load="true"
          />
          <PremiumIndicator
            v-if="showPremiumIndicator"
            size="small"
          />
        </div>

        <h2 data-test="episodetitle">{{ episodeData.title | truncate(60) }}</h2>
        <div class="episode-information">
          <span data-test="episode-season">{{ seasonEpisodeData }}</span>
          <span data-test="episode-releasedate"> {{ releasedAtData | dateFormat }} </span>
          <span data-test="episode-runtime"> {{ episodeData.runtime | humanizedTimeFilter }} </span>
        </div>

        <div class="description">
          <p
            v-if="showingMoreDescription"
            data-test="show-description"
            v-html="$sanitize(episodeDescription)"
          />
          <p
            v-if="!showingMoreDescription"
            data-test="show-description"
            v-html="$sanitize($options.filters.truncate(episodeDescription, 400))"
          />
          <el-button
            v-if="episodeDescription.length > 400"
            class="read-more"
            type="text"
            @click="toggleDescription">
            {{ showingMoreDescription ? 'Less' : 'More' }} Info
            <i :class="`el-icon-arrow-${showingMoreDescription ? 'up' : 'down'}`" />
          </el-button>
        </div>

        <div class="detail-page__buttons">
          <el-button
            v-if="isShowPremium && !isEpisodeFree && !isPremiumMember && !isLoggedIn"
            class="listen-with-premium large"
            type="primary"
            data-test="btn-premiumepisode"
            @click="playEpisode">
            Subscribe to Listen
          </el-button>

          <el-button
            v-if="!isAudioLoading && ((isShowPremium && isPremiumMember) || (isShowPremium && !isPremiumMember && isEpisodeFree) || !isShowPremium)"
            type="primary"
            class="m-r play-button icon-button"
            data-test="play-episode"
            v-on="{click: isPlaying ? playEpisode : episodeListened ? replayEpisode : playEpisode}">
            <img
              v-if="!isPlaying && !episodeListened"
              src="../assets/images/ic_play_symbol_white.svg"
              alt="play">
            <img
              v-if="!isPlaying && episodeListened"
              src="../assets/images/is_replay_white.svg"
              alt="replay">
            <img
              v-if="isPlaying"
              src="../assets/images/ic_pause_white_icon.svg"
              alt="pause">
            <span>{{ isPlaying ? 'Pause Episode' : episodeListened ? 'Replay' : 'Play Episode' }}</span>
          </el-button>

          <el-button
            v-if="isAudioLoading && ((isShowPremium && isPremiumMember) || (isShowPremium && !isPremiumMember && isEpisodeFree) || !isShowPremium)"
            type="primary"
            class="m-r play-button icon-button">
            <i class="el-icon-loading" />
            <span>Play Episode</span>
          </el-button>

          <el-button
            v-if="isLoggedIn && ((isShowPremium && isPremiumMember) || (isShowPremium && !isPremiumMember && isEpisodeFree) || !isShowPremium)"
            :loading="addingEpisode"
            class="add-to-playlist"
            type="text"
            @click="addEpisodeToLibrary(episodeData.uuid, episodeData.runtime)">
            <svg v-if="!addingEpisode && !inUserLibrary" svg-inline="" focusable="false" role="presentation" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"><path fill="#00AECB" d="M17 3H7C5.9 3 5.01 3.9 5.01 5L5 21L12 18L19 21V5C19 3.9 18.1 3 17 3ZM17 18L12 15.82L7 18V5H17V18Z"/></svg>
            <svg v-if="!addingEpisode && inUserLibrary" svg-inline="" focusable="false" role="presentation" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"><path fill="#00AECB" d="M17 3H7C5.9 3 5.01 3.9 5.01 5L5 21L12 18L19 21V5C19 3.9 18.1 3 17 3Z"/></svg>
            <span data-test="addToPlaylist">{{ inUserLibrary && inUserLibrary.uuid ? 'Added' : 'Add to Bookmarks' }}</span>
          </el-button>
        </div>
      </div>

      <div class="show-image hidden-xs-only">
        <picture-component
          :src="episodeData.display_image_url"
          :progressive-load="true"
        />
        <PremiumIndicator
          v-if="showPremiumIndicator"
          size="small"
        />
      </div>
    </div>

    <PremiumBanner
      v-if="shouldShowPremiumBanner"
      :show="episodeData"
      :title="episodeData.season.podcast.title"
      class="hidden-sm-and-up"
    />

    <el-alert
      v-show="episodeDataLoaded && episodeData && !episodeData.uuid"
      :closable="false"
      class="container"
      title="Episode details not found."
      type="warning"
      show-icon
      center
    />
    <login-modal
      v-if="showLogin"
      :show_modal="showLogin"
      @modalClosed="showLogin = false"
    />
  </div>
</template>

<script>
import * as log from 'loglevel';
import Podcast from '../services/Podcast';
import User from '../services/User';
import Episode from '../services/Episode';
import SegmentEvent from '../services/PlayerEvent';
import PageMixin from '../shared/pageMixin';
import sanitizeHtml from '../shared/sanitizeHtmlString';
import CardListWrapper from '../components/CardListWrapper';
import Loader from '../components/Loader';
import PremiumBanner from '../components/PremiumBanner';
import PremiumIndicator from '../components/PremiumIndicator';
import Rating from '../components/Rating';
import DetailPageBreadcrumb from '../components/DetailPageBreadcrumb';
import episodeProvider from '../shared/episodeProvider';
import showProvider from '../shared/showProvider';
import nr_analyticsManager from '../shared/new-relic/analyticsManager';
import nr_addPageActions from '../shared/new-relic/addPageActions';

export default {
  name: 'EpisodeDetail',
  components: {
    Loader,
    CardListWrapper,
    Rating,
    PremiumBanner,
    PremiumIndicator,
    Breadcrumb: DetailPageBreadcrumb,
    FreeEpisode: () => import(/* webpackChunkName: "episode-details" */ '../components/FreeEpisodeIndicator'),
    LoginModal: () => import(/* webpackChunkName: "episode-details" */ '../components/LoginModal'),
    MoreOfCategory: () => import(/* webpackChunkName: "episode-details" */ '../components/MoreOfCategory'),
    PodcastList: () => import(/* webpackChunkName: "episode-details" */ '../components/PodcastList'),
    SocialShareDialog: () => import(/* webpackChunkName: "episode-details" */ '../components/SocialShare'),
  },
  mixins: [PageMixin],
  data() {
    return {
      pageName: 'Episode Detail Page',
      episodeDataLoaded: false,
      addingEpisode: false,
      episodeData: null,
      similarEpisodes: [],
      showLogin: false,
      breadcrumbTrail: [],
      episodeCategory: null,
      eventProvider: new SegmentEvent(),
      showingMoreDescription: false,

      episodeService: new Episode(),
      podcastService: new Podcast(),
      userService: new User(),
      fetchCategoryPodcasts: false,
    };
  },
  computed: {
    shouldShowPremiumBanner() {
      const ed = this.episodeData;
      const isPodcastExclusive = ed && ed.season && ed.season.podcast && ed.season.podcast.is_exclusive;
      return ed && ed.uuid && isPodcastExclusive && !this.isPremiumMember && this.episodeDataLoaded && this.isLoggedIn;
    },
    seasonEpisodeData() {
      if (this.episodeDetailsFlag) {
        const season = (this.episodeData.season_number && this.episodeData.season_number !== 0) ? (`S${this.episodeData.season_number}`) : ('');
        const episode = (this.episodeData.number && this.episodeData.number !== 0) ? (`E${this.episodeData.number}`) : ('');
        return `${season} ${episode}`;
      }
      const season = (this.episodeData.season
          && this.episodeData.season.season_number_formatted) || '';
      const episode = this.episodeData.episode_number_formatted || this.episodeData.number || '';
      return `${season} ${episode}`;
    },
    releasedAtData() {
      if (this.episodeDetailsFlag) {
        const released = this.episodeData.released_at;
        const releasedAt = new Date(0);
        releasedAt.setUTCSeconds(released);
        return releasedAt;
      }
      return this.episodeData.released_at;
    },
    isLoggedIn() {
      return this.$store.getters.loggedIn;
    },
    isPremiumMember() {
      const { user } = this.userService;
      return user && user.is_subscribed;
    },
    showPremiumIndicator() {
      if (this.episodeData.is_exclusive || (this.episodeData.season && this.episodeData.season.podcast && this.episodeData.season.podcast.is_exclusive)) {
        if (!this.isEpisodeFree && !this.isPremiumMember) {
          return true;
        }

        if (this.isPremiumMember) {
          return true;
        }
      }

      return false;
    },
    isAudioLoading() {
      const { status } = this.$store.getters;

      return status === 'loading' && this.currentlyPlaying && (this.currentlyPlaying.uuid === this.episodeData.uuid);
    },
    isPlaying() {
      const { play } = this.$store.getters;

      return play && this.currentlyPlaying && (this.currentlyPlaying.uuid === this.episodeData.uuid);
    },
    currentlyPlaying() {
      return this.$store.getters.episode;
    },
    isShowPremium() {
      return this.episodeData.is_exclusive || (this.episodeData && this.episodeData.season && this.episodeData.season.podcast && this.episodeData.season.podcast.is_exclusive);
    },
    isEpisodePremium() {
      return this.episodeData.is_exclusive;
    },
    isEpisodeFree() {
      return this.episodeData && !this.episodeData.is_exclusive;
    },
    inUserLibrary() {
      if (!this.episodeData || !this.episodeData.uuid) {
        return null;
      }
      const userEpisode = this.$store.getters.searchEpisodeInLibrary(this.episodeData.uuid);
      if (userEpisode && userEpisode.uuid) {
        return userEpisode;
      }
      return null;
    },
    episodeListened() {
      if (this.episodeData) {
        const progress = this.$store.getters.episodeProgressByUuid(this.episodeData.uuid);

        if (progress) {
          return progress.progress_percent >= 95;
        }

        return false;
      }

      return false;
    },
    episodeDescription() {
      if (this.episodeDetailsFlag) {
        const descString = this.episodeData.description;
        return this.linkifyString(descString);
      }
      const { description } = this.episodeData;
      const descriptionHtml = this.episodeData.description_html;
      const descString = descriptionHtml ? sanitizeHtml(descriptionHtml) : description;
      const containsHTML = descriptionHtml && /<\/?[a-z][\s\S]*>/i.test(descriptionHtml);
      const containsATag = /<\/a>/i.test(descriptionHtml);
      return (!containsHTML || !containsATag) ? this.linkifyString(descString) : descString;
    },
    episodeDetailsFlag() {
      const episode_details_endpoint = this.$store.getters.flagWithDefault('episode_details_endpoint', false);
      return episode_details_endpoint;
    },
  },
  beforeMount() {
    if (this.episodeDetailsFlag) {
      this.getEpisodeDetails();
    } else {
      this.getEpisode();
    }
  },
  mounted() {
    setTimeout(() => {
      this.handleOffScreenData();
    });
  },
  destroyed() {
    this.setMetaInformation();
  },
  methods: {
    linkifyString(text) {
      const urlRegex = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig; // eslint-disable-line
      return text.replace(urlRegex, url => `<a href="${url}">${url}</a>`);
    },
    toggleDescription() {
      this.showingMoreDescription = !this.showingMoreDescription;
      setTimeout(() => {
        this.mapTargetBlankToAnchorTags('.episode-details__info .description a');
      }, 10);
    },
    setEpisodeCategory() {
      if (this.episodeData && this.episodeData.season && this.episodeData.season.podcast && this.episodeData.season.podcast.categories && this.episodeData.season.podcast.categories.length) {
        [this.episodeCategory] = this.episodeData.season.podcast.categories;
      }
    },
    constructBreadcrumbTrail() {
      if (this.episodeDetailsFlag) {
        this.breadcrumbTrail = [
          {
            name: this.episodeCategory.categories.name,
            link: `/listen/category/${this.episodeCategory.categories.slug}/${this.episodeCategory.categories.uuid}`,
          },
          {
            name: this.episodeData.show_title,
            link: `/listen/${this.episodeCategory.publisher.slug}/${this.episodeCategory.slug}/${this.episodeData.podcast_uuid}`,
          },
          {
            name: this.episodeData.title,
          },
        ];
      } else {
        this.breadcrumbTrail = [
          {
            name: this.episodeCategory.name,
            link: `/listen/category/${this.episodeCategory.slug}/${this.episodeCategory.uuid}`,
          },
          {
            name: this.episodeData.season.podcast.title,
            link: `/listen/${this.episodeData.season.podcast.publisher.slug}/${this.episodeData.season.podcast.slug}/${this.episodeData.season.podcast.uuid}`,
          },
          {
            name: this.episodeData.title,
          },
        ];
      }
    },
    getEpisode() {
      const episodeUUID = this.$route.params.episodeID;
      nr_analyticsManager.addPageAction(nr_addPageActions.Web_LoadEpisodeDetailsV1);
      this.episodeService.getEpisodeById(episodeUUID).then((res) => {
        this.episodeData = res;
        this.episodeDataLoaded = true;
        // display show level image if episode does not have image
        if (this.episodeData && this.episodeData.season
            && this.episodeData.season.podcast) {
          // TODO: Remove extraneous OR condition when API ticket goes through
          this.episodeData.display_image_url = this.episodeData.display_image_url || this.episodeData.season.podcast.display_image_url;
        }
        // this.getEDPEpisodes();
        this.setEpisodeCategory();

        if (this.episodeCategory) {
          this.constructBreadcrumbTrail();
        }
        this.eventProvider.triggerEpisodesDetailsViewedEvent(this.episodeData);

        this.setMetaInformation(
          `${this.episodeData.season && this.episodeData.season.podcast ? this.episodeData.season.podcast.title : ''}: ${this.episodeData.title} | ${this.episodeData.is_exclusive ? 'Listen Only on Luminary' : 'Luminary'}`,
          this.$options.filters.truncate(this.episodeData.description, 160),
        );
      }).catch((error) => {
        this.episodeDataLoaded = true;
        this.showErrorMsg(error.message);
      });
    },
    getEpisodeDetails() {
      const episodeUUID = this.$route.params.episodeID;
      nr_analyticsManager.addPageAction(nr_addPageActions.Web_LoadEpisodeDetailsV2);
      episodeProvider.getEpisodeDetails(episodeUUID).then((res) => {
        this.episodeData = res.episodeData;
        this.episodeDataLoaded = true;
        this.getPodcastCategoryInfo(this.episodeData);
        nr_analyticsManager.addPageAction(nr_addPageActions.Web_LoadEpisodeDetailsV2Success);
      }).catch((error) => {
        this.episodeDataLoaded = true;
        this.showErrorMsg(error.message);
        nr_analyticsManager.addPageAction(nr_addPageActions.Web_LoadEpisodeDetailsV2Failure);
      });
    },
    getPodcastCategoryInfo() {
      const uuid = this.episodeData.podcast_uuid;
      const params = { from: 0, size: 1 };
      showProvider.getShowDetails(uuid, params).then((res) => {
        this.episodeCategory = res.show_data;
        if (this.episodeCategory) {
          this.constructBreadcrumbTrail();
        }
      }).catch((error) => {
        this.showErrorMsg(error.message);
      });
    },

    fetchLeftList(list, limit) {
      const arr = [];
      for (let i = list.length - 1; i >= 0; i--) {
        if (list[i] && arr.length < limit) {
          arr.push(list[i]);
        }
      }
      arr.sort((a, b) => new Date(b.released_at) - new Date(a.released_at));
      return arr;
    },
    fetchRightList(list, limit) {
      const arr = [];
      list.forEach((item) => {
        if (item && arr.length < limit) {
          arr.push(item);
        }
      });
      return arr;
    },
    formatDate(d) {
      const a = new Date(d).toDateString().split(' ');
      return `${a[1]} ${a[2]}`;
    },
    addEpisodeToLibrary(id, runtime) {
      this.showLogin = !this.isLoggedIn;
      if (this.showLogin) return;

      // If user is not premium and show is exclusive, then show PremiumContentDialog.
      this.$store.dispatch('isAllowedPremiumEpisode', this.episodeData);
      if (this.showPremiumModal) return;

      if (this.inUserLibrary && this.inUserLibrary.uuid) {
        this.deleteEpisodeFromLibrary();
      } else {
        this.addingEpisode = true;
        this.episodeService.addEpisodetoLibrary(id, runtime)
          .then((res) => {
            if (res && res.success) {
              this.$store.dispatch('setUserLibraryEpisode', { uuid: res.uuid, episode_uuid: id });
              // this.$set(this.episodeData, 'record_id', res.uuid);
            }
            this.addingEpisode = false;
            this.$set(this.episodeData, 'in_library', true);

            // this.showSuccessMsg('Episode added to library');
            this.eventProvider.triggerEpisodeAddEvent(this.episodeData);
          })
          .catch((error) => {
            log.warn(error.message);
            this.addingEpisode = false;
            this.showErrorMsg(error.message);
          });
      }
    },
    mapEpisodesProgress() {
      if (this.episodeData && this.episodeData.season_uuid) {
        this.episodeDataLoaded = false;
        this.similarEpisodes = this.similarEpisodes.map((_episode) => {
          const episode = _episode;
          if (episode && episode.episode_listen && episode.episode_listen.elapsed) {
            episode.progress = episode.episode_listen.elapsed;
            episode.total_runtime = episode.episode_listen.runtime;
          }
          episode.season = {
            podcast: {
              uuid: (this.episodeData && this.episodeData.season) ? this.episodeData.season.podcast_uuid : null,
              is_exclusive: (this.episodeData && this.episodeData.season && this.episodeData.season.podcast) ? this.episodeData.season.podcast.is_exclusive : false,
            },
          };
          return episode;
        });
        this.episodeDataLoaded = true;
      }
    },
    playEpisode() {
      // If user is not premium and show is exclusive, then show PremiumContentDialog.
      this.$store.dispatch('isAllowedPremiumEpisode', this.episodeData);
      if (this.showPremiumModal) return;

      // this.loadMediaInPlayer(this.episodeData.media_url, this.episodeData.is_exclusive, this.episodeData.episode_type);
      this.loadEpisodeInPlayer(this.episodeData);
      this.$store.dispatch('setPlaylistType', 'show');

      if (!this.isPlaying && this.currentlyPlaying.uuid === this.episodeData.uuid) {
        this.$store.dispatch('play');
        this.eventProvider.triggerEpisodePlayEvent(this.episodeData, 'resume_episode');
        return;
      }

      if (this.episodeData && this.isPlaying) {
        // window.eventBus.$emit('pause-podcast')
        this.$store.dispatch('pause');
        this.eventProvider.triggerEpisodePauseEvent(this.episodeData);
        return;
      }
      this.$store.dispatch('setAndPlayEpisode', this.episodeData);
      this.eventProvider.triggerEpisodePlayEvent(this.episodeData);
    },
    replayEpisode() {
      const { episode } = this.$store.getters;

      if (episode.uuid === this.episodeData.uuid) {
        this.$store.dispatch('setProgress', 0);
        this.$store.dispatch('play');
      } else {
        // this.loadMediaInPlayer(this.episodeData.media_url, this.episodeData.is_exclusive, this.episodeData.episode_type);
        this.loadEpisodeInPlayer(this.episodeData);
        this.$store.dispatch('setAndPlayEpisode', this.episodeData);
        this.eventProvider.triggerEpisodePlayEvent(this.episodeData);
      }
    },
    deleteEpisodeFromLibrary() {
      this.addingEpisode = true;
      this.episodeService.deleteEpisodeFromLibrary(this.inUserLibrary.uuid).then(() => {
        this.addingEpisode = false;
        // this.$set(this.episodeData, 'in_library', false);
        this.$store.dispatch('removeUserLibraryEpisode', this.episodeData.uuid);

        // this.showSuccessMsg('Episode deleted from your library.');
        this.eventProvider.triggerEpisodeRemoveEvent(this.episodeData);
      })
        .catch((error) => {
          this.adding_podcast = false;
          log.warn(error);
        });
    },
    // Event handler to sync data after add/remove from library
    updateEpisodeStatusInLibrary(res, index, context) {
      if (this.similarEpisodes && this.similarEpisodes.length > index) {
        this.similarEpisodes[index].record_id = res.record_id;
        this.similarEpisodes[index].in_library = context === 'add';
      }
    },
    savePlaylist(episodes) {
      const sortedEpisodes = episodes.sort((a, b) => new Date(a.released_at) - new Date(b.released_at));
      this.$store.dispatch('setPlaylist', sortedEpisodes);
    },
    getEDPEpisodes() {
      this.episodeService.getEdpEpisodes(this.episodeData.uuid).then((res) => {
        if (res && res.length) {
          const episodes = res;
          if (episodes && episodes.length) {
            // this.savePlaylist(episodes); // Save show episodes list in store
            const sortedEpisodes = episodes;
            // this.similarEpisodes = res.episodes
            sortedEpisodes.sort((a, b) => new Date(b.released_at) - new Date(a.released_at));
            // this.similarEpisodes = sortedEpisodes
            const currentEpisodeIndex = sortedEpisodes.findIndex(episode => episode.uuid === this.episodeData.uuid);
            let leftArr = [];
            let rightArr = [];
            let limit = 3;
            if (currentEpisodeIndex + 1 === sortedEpisodes.length) {
              limit = 6;
            } else if (currentEpisodeIndex + 3 >= sortedEpisodes.length) {
              limit = 6 - ((currentEpisodeIndex + 3) - (sortedEpisodes.length));
            }
            leftArr = this.fetchLeftList(sortedEpisodes.slice(0, currentEpisodeIndex), limit);
            limit = 6 - leftArr.length;
            rightArr = this.fetchRightList(sortedEpisodes.slice(currentEpisodeIndex + 1), limit);
            this.similarEpisodes = leftArr.concat(rightArr);
            this.similarEpisodes = this.similarEpisodes.filter(item => item.uuid !== this.episodeData.uuid);
            // Mapping progress in Episodes
            // this.mapEpisodesProgress();
            this.mapTargetBlankToAnchorTags('.episode-details__info .description a');
          }
        }
      }).catch((error) => {
        log.info(error);
        this.mapTargetBlankToAnchorTags('.episode-details__info .description a');
      });
    },
    scrollEventHandlerForOffScreenData() {
      this.fetchCategoryPodcasts = true;
      this.removeScroll();
    },
  },
};
</script>

<style lang="scss">
@import './EpisodeDetails.scss';
</style>
