<template>
  <div class="homepage-special-promo">
    <div id="sol-feature">
      <div class="container">
        <div class="content" id="sol-offer">
          <div class="offer">
            <h2 class="highlight">Special Vinyl Bundle Offer</h2>
            <h2>$50 for 12 months</h2>
            <div class="cta">
              <button @click="onClickCTA" type="button"><span>Listen Now</span></button>
            </div>
            <p class="fine-print"><a target="_blank" href="/terms">Terms apply</a>. Auto-renews at $34.99 after promotional period. Not valid for existing annual subscribers. Available in US, CA, and UK, and redeemable only via luminarypodcasts.com. Cancel any time, no refunds. Offer ends Jul 27, 2022 at 11:59pm EDT.</p>
          </div>
        </div>
      </div>
    </div>
    <FeaturedLargeCards />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import FeaturedLargeCards from './FeaturedLargeCards';

export default {
  components: { FeaturedLargeCards },
  methods: {
    onClickCTA() {
      this.$analytics.track('Home Page Special Promo Click', {
        screen_name: this.$route.name,
        path: this.$route.path,
        btnId: 'home_special_promo_click',
      });
      window.location.href = '/signup';
    },
  },
  computed: {
    ...mapGetters({
      countryCode: 'country',
      featureFlag: 'featureFlag',
    }),
  }
};
</script>

<style lang="scss" scoped>
@import "../../../assets/scss/variables.scss";

.app-navbar {
  position: absolute!important;
  left: 0;
  right: 0;
  background-color: transparent!important;
  border-bottom: 0.5px solid rgba(252, 252, 252, .2)!important;
}

#sol-feature {
  padding-top: 72px;
}
#sol-feature, #sol-feature *  {
  -webkit-font-smoothing: auto;
  -moz-osx-font-smoothing: auto;
  text-rendering: optimizeLegibility;
}

#sol-feature .container { position: relative; }
#sol-feature .container:after {
  background: #ffe500;
  filter: blur(93px);
  border-radius: 184px;
  content: '';
  display: block;
  height: 390px;
  left: -192px;
  position: absolute;
  top: -500px;
  transform: rotate(21deg);
  width: 840px;
}

#sol-feature .content {
  position: relative;
}
#sol-feature h2 {
  color: #fff;
  font-size: 3rem;
  font-weight: bold;
  line-height: 3.5625rem;
  margin: 0;
}
#sol-feature h2.highlight {
  color: #ffe500;
}
#sol-feature h2 s {
  color: #555c6e;
  padding-right: .2rem;
  position: relative;
  font-weight: normal;
  text-decoration: none;
}
#sol-feature h2 s:after {
  background: #ff6565;
  border-radius: 2px;
  content: '$15';
  color: transparent;
  height: 4px;
  display: block;
  left: 0;
  margin-left: -4px;
  position: absolute;
  top: 50%;
  transform: rotate(-3deg);
  width: calc(100% + 8px);
}

#sol-offer {
  padding: 6.9rem 0;
}
#sol-offer p.fine-print {
  color: rgba(174,198,251,.39);
  font-size: 10px;
  line-height: 16px;
  padding-top: 1.75rem;
}
#sol-offer p.fine-print a {
  border-bottom: 1px solid rgba(174,198,251,.39);
  color: inherit;
}
#sol-offer .cta {
  margin-top: -42px;
  position: absolute;
  right: 0;
  text-align: center;
  top: 50%;
  width: 50%;
}
#sol-offer button {
  background: #5289ed -webkit-radial-gradient(at 7% -10%, rgba(255,229,0,1) -10%, rgba(255,229,0,0) 39%);
  background: #5289ed -moz-radial-gradient(at 7% -10%, rgba(255,229,0,1) -10%, rgba(255,229,0,0) 39%);
  background: #5289ed radial-gradient(at 7% -10%, rgba(255,229,0,1) -10%, rgba(255,229,0,0) 39%);
  border: none;
  border-radius: 42px;
  box-shadow: 0 6px 69px 0 rgba(18, 28, 48, .69);
  color: #121c30;
  cursor: pointer !important;
  height: 84px;
  position: relative;
  width: 420px;
  z-index: 2;
}
#sol-offer button span {
  color: #fff;
  display: block;
  font-size: 2.1rem;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 84px;
  position: relative;
  top: -2px;
}
#sol-offer .cta:before {
  background: #5289ed;
  filter: blur(93px);
  border-radius: 120px;
  content: '';
  display: block;
  height: 120px;
  left: 50%;
  margin: -69px 0 0 -240px;
  position: absolute;
  top: 50%;
  transform: rotate(-28deg);
  width: 570px;
  z-index: 1;
}

#sol-offer .offer .fine-print {
  max-width: 40%;
}

.highlighted-header { text-align: center; }

@media (max-width: 1200px) {
  #sol-offer button {
    width: 320px;
  }
  #sol-offer button span {
    font-size: 1.9rem;
  }
}

@media (max-width: 930px) {
  #sol-offer .cta {
    display: block;
    margin: 3rem 0 1rem 0;
    position: relative;
    text-align: left;
    top: auto;
    width: auto;
    z-index: 0;
  }
  #sol-offer h2, #sol-offer .fine-print {
    position: relative;
    z-index: 1;
  }
  #sol-offer .offer .fine-print {
    max-width: 75%;
  }
}

@media (max-width: 567px) {
  #sol-feature h2 {
    font-size: 7vw;
    line-height: 8.4vw;
  }
  #sol-offer button {
    border-radius: 7.5vw;
    height: 15vw;
    width: 69vw;
  }
  #sol-offer button span {
    font-size: 6vw;
    line-height: 15vw;
  }
  #sol-offer .offer .fine-print {
    max-width: 100%;
  }
}

</style>
