export default {
  payment: {
    firstName: {
      required: true,
      message: 'First name is required',
      trigger: 'change',
    },
    lastName: {
      required: true,
      message: 'Last name is required',
      trigger: 'change',
    },
    expirationMonth: [
      {
        required: true,
        message: 'Exp. month is required',
        trigger: 'change',
      },
    ],
    expirationYear: [
      {
        required: true,
        message: 'Exp. year is required',
        trigger: 'change',
      },
    ],
    securityCode: [
      {
        required: true,
        message: 'Security code is required',
        trigger: 'change',
      },
      {
        pattern: '^[0-9]{3,4}$',
        message: 'Invalid security code',
        trigger: 'blur',
      },
    ],
    address: [
      {
        required: true,
        message: 'Address is required.',
        trigger: 'blur',
      },
    ],
    postalCode: [
      {
        required: true,
        message: 'Postal code is required.',
        trigger: 'change',
      },
      { max: 10, message: 'Postal code is too long', trigger: 'change' },
    ],
    city: [
      {
        required: true,
        message: 'City is required.',
        trigger: 'blur',
      },
    ],
    state: [
      {
        required: true,
        message: 'State is required.',
        trigger: 'change',
      },
    ],
  },
};
