<template>
  <div id="special-promo-offer">
    <div id="sol-offer">
      <div id="sol-feature">
        <div class="card_text">
          <el-row>
            <grid-col
                :xs="{ span: 20, offset: 0 }"
                :sm="{ span: 20, offset: 0 }"
                :md="{ span: 20, offset: 0 }"
                :lg="{ span: 12, offset: 0 }"
                :xl="{ span: 12, offset: 0 }">
              <div>
                <div class="highlighted">LIMITED OFFER</div>
                <div class="header"><h2>Annual Luminary Subscription + Limited Edition Vinyl</h2></div>
                <div class="top_price"><h2>$50<span class="discount">Save $35</span></h2></div>
                <div class="cta desktop">
                  <button @click="action"><span>Continue</span></button>
                </div>
              </div>
            </grid-col>
            <grid-col
                :xs="{ span: 20, offset: 0 }"
                :sm="{ span: 20, offset: 0 }"
                :md="{ span: 20, offset: 0 }"
                :lg="{ span: 8, offset: 1 }"
                :xl="{ span: 8, offset: 1 }">
              <div>
                <img class="vinyl_image" src="@/assets/images/promos/vinyl_image.png">
                <p class="description">Unlimited access to Luminary for a year, plus a limited edition vinyl featuring two fan-favorite episodes of The Midnight Miracle.</p>
              </div>
            </grid-col>
          </el-row>
          <div class="cta mobile">
            <button @click="action"><span>Continue</span></button>
          </div>
          <div class="horizontal_line"></div>
          <p class="fine-print"><a target="_blank" href="/terms">Terms apply</a>. Auto-billed $34.99 annually after
            your first 12 months. Available while supplies last, for US customers only.</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SpecialPromoOffer',
  props: {
    country: {type: String, default: 'US'},
    action: {type: Function, default: ()=>{}},
  },
  methods: {
  }
};
</script>

<style lang="scss" scoped>
@import '../../assets/scss/variables.scss';

#sol-feature  .discount {
  color: #F9E064;
  margin-left: 15px;
  margin-top: 7px;
  font-size: 20px;
  font-weight: normal;
}

.cta {
  margin-top: 20px;
}

.cta:hover {
  text-decoration: none;
}

.header {
  margin-top: 20px;
}

.horizontal_line {
  border-top: 1px solid #bebebe;
  opacity: 0.2;
  margin: 12px 0;
}

#sol-feature .vinyl_image {
  width: 150%;
  margin-left: -50px;
  margin-top: -15px;
}

#sol-feature .top_price {
  margin-top: 20px;
  margin-bottom: 20px;
  opacity: 0.8;
}

#sol-feature .top_price h2 {
  display: flex;
  align-items: center;
}

#sol-feature .highlighted{
  display: flex;
  padding: 0 0;
  margin-left: 0px;
  height: 25px;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: 400;
}

#sol-feature .price {
  margin-bottom: 107px;
}

#sol-feature .plus_sign_desktop {
  position: relative;
  left: 260px;
  top: -70px;
  color: #fff;
  font-size: 1.5rem;
  font-weight: bold;
}

#sol-feature .plus_sign_mobile {
  position: relative;
  left: 220px;
  top: -63px;
  color: #fff;
  font-size: 1.5rem;
  font-weight: bold;
}


#sol-feature .highlighted {
  font-family: Patron;
  font-size: 12px;
  text-align: center;
  align-items: center;
  background-color: #F9E064;
  color: #346eac;
  padding: 2px 3px;
  border-radius: 6px;
  width: 100px;
  margin-bottom: 0px;
}

#sol-feature .description {
  font-family: $patron;
  color: #fff;
  font-size: 16px;
  line-height: 20px;
  opacity: 0.7;
  letter-spacing: 1px;
}

#sol-feature h3 {
  color: #fff;
  font-size: 14px;
  font-weight: lighter;
  margin: 0px;
  padding: 0px;
  margin-bottom: 10px;
  opacity: 0.8;
  width: 100%;
}

#sol-offer {
  background-color: #346eac;
  background-image: -webkit-radial-gradient(at 7% -24%, rgb(145,89,139) -12%, rgba(255,229,0,0) 37%), -webkit-radial-gradient(at 100% 100%, rgb(45,82,120) -21%, rgba(82,137,237,0) 48%);
  background-image: -moz-radial-gradient(at 7% -24%, rgb(145,89,139) -12%, rgba(255,229,0,0) 37%), -moz-radial-gradient(at 100% 100%, rgb(45,82,120) -21%, rgba(82,137,237,0) 48%);
  background-image: radial-gradient(at 7% -24%, rgb(145,89,139) -12%, rgba(255,229,0,0) 37%), radial-gradient(at 100% 100%, rgb(45,82,120) -21%, rgba(82,137,237,0) 48%);

  width: 632px;
  margin: 1.2rem 0 0rem 0;
  padding: 30px;
  box-sizing: border-box;
  border-radius: 6px;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);

  .mobile {
    display: none;
  }

  .desktop {
    display: block;
  }

  @media (max-width: 970px) {
    width: 300px;
    .mobile {
      display: flex;
    }
    .desktop {
      display: none;
    }
  }
}

#sol-offer-subhead {
  //font-family: ITC Clearface Bold,serif;
  font-size: 24px;
  text-align: center;
}

#sol-feature h2 {
  color: #fff;
  font-size: 28px;
  font-weight: bold;
  line-height: 1.2;
  margin: 0;
}

#sol-offer button {
  margin-top:-0px;
  border: none;
  background-color: #FFE500;
  border-radius: 24px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  cursor: pointer !important;
  height: 48px;
  position: relative;
  width: 190px;
  z-index: 2;
}

#sol-offer button span {
  color: $dark-background;
  display: block;
  font-size: 16px;
  font-weight: normal;
  letter-spacing: 0;
  line-height: 48px;
  position: relative;
  top: -2px;
}

#sol-feature .fine-print {
  color: #fff;
  font-size: 11px;
  line-height: 1rem;
  letter-spacing: 0.05em;
}

#sol-feature p.fine-print a {
  color: #fff;
  text-decoration: underline;
}

@media (max-width: 480px) {
  #sol-offer h2 {
    font-size: 24px;
    line-height: 8.1vw;
  }
}

#sol-offer {
  background-image: radial-gradient(at 7% -24%,#91598b -12%,rgba(255,229,0,0) 67%),radial-gradient(at 100% 100%,#2d5278 -21%,rgba(82,137,237,0) 48%);
}
#sol-feature .highlighted {
  border-radius: 3px;
  letter-spacing: 0.5px;
  color: #38689F;
  padding: 0px 7px!important;
}

#sol-feature h3 {
  opacity: 0.8;
}

#sol-feature .description {
  opacity: 0.7;
  letter-spacing: 0;
  width: 120%;
}
#sol-offer button span {
  color: #2A5C8C;
}
#sol-feature .fine-print {
  font-size: 12px;
  opacity: 0.6;
}

.horizontal_line {
  opacity: 0.1;
  margin: 20px 0;
}

//.alt-col-xs-20 { width :83%; }

</style>
