<template>
  <div
    v-if="showBookmarkCta"
    :class="['bookmark-cta', position]">
    <span
      v-show="isAddingBookmark || isRemovingBookmark"
      class="processing">
      <i class="el-icon-loading" />
      {{ isRemovingBookmark ? 'Removing...' : isAddingBookmark ? 'Adding...' : '' }}</span>
    <img
      v-button-event="{buttonText: 'Add Episode', buttonLink: null}"
      v-if="!isAddingBookmark && !isRemovingBookmark"
      :src="is_in_bookmarks ? IsBookmarkedIcon : isNotBookmarkedIcon"
      width="24"
      @click="is_in_bookmarks ? removeBookmark() : addBookmark()">
    <span
      v-if="!isAddingBookmark && !isRemovingBookmark"
      @click="is_in_bookmarks ? removeBookmark() : addBookmark()">
      {{ is_in_bookmarks ? 'Added': 'Bookmark' }}</span>
  </div>
</template>

<script>
import * as log from 'loglevel';
import EpisodeService from '../../../services/Episode';
import Event from '../../../services/PlayerEvent';
import IsBookmarkedIcon from '../../../assets/images/bookmarked.svg';
import isNotBookmarkedIcon from '../../../assets/images/ic_add_episode.svg';

export default {
  props: {
    episode: {
      type: Object,
      default() {
        return {};
      },
    },
    episodeLegacyFormattedObject: {
      type: Object,
      default() {
        return {};
      },
    },
    userBookmarks: {
      type: Array,
      default() {
        return [];
      },
    },
    position: {
      type: String,
      default: 'left',
      validator(value) {
        return ['left', 'center', 'right'].includes(value);
      },
    },
  },
  data() {
    return {
      episode_service: new EpisodeService(),
      eventProvider: new Event(),
      IsBookmarkedIcon,
      isNotBookmarkedIcon,
      bookmarked_episode: null,
      is_in_bookmarks: false,
      isAddingBookmark: false,
      isRemovingBookmark: false,
    };
  },
  computed: {
    userPremium() {
      return this.userSubscriptionState === 'premium' || this.userSubscriptionState === 'churnedPremium';
    },
    userNotAnon() {
      return !(this.userSubscriptionState === 'anon');
    },
    showBookmarkCta() {
      return this.userPremium || (this.userNotAnon && !this.episode.isExclusive);
    },
  },
  beforeMount() {
    this.setUserBookmark();
  },
  methods: {
    setUserBookmark() {
      this.bookmarked_episode = this.userBookmarks.find(bookmark => bookmark.episode_uuid === this.episode.id);
      if (this.bookmarked_episode) {
        this.is_in_bookmarks = true;
      } else {
        this.is_in_bookmarks = false;
      }
    },
    async addBookmark() {
      this.isAddingBookmark = true;

      try {
        const res = await this.episode_service.addEpisodetoLibrary(this.episode.id);
        this.bookmarked_episode = { ...res, episode_uuid: this.episode.id };
        this.$store.dispatch('setUserLibraryEpisode', { uuid: res.uuid, episode_uuid: this.episode.id });
        await this.eventProvider.triggerEpisodeAddEvent(this.episodeLegacyFormattedObject);
        this.is_in_bookmarks = true;
      } catch (err) {
        this.$emit('added', 0);
        this.showErrorMsg('Failed to bookmark episode');
        log.error(err.message);
      }

      this.isAddingBookmark = false;
    },
    async removeBookmark() {
      this.isRemovingBookmark = true;

      try {
        await this.episode_service.deleteEpisodeFromLibrary(this.bookmarked_episode.uuid);
        this.bookmarked_episode = null;
        this.$store.dispatch('removeUserLibraryEpisode', this.episode.id);
        this.$emit('deleted', this.episode.id);
        await this.eventProvider.triggerEpisodeRemoveEvent(this.episodeLegacyFormattedObject);
        this.is_in_bookmarks = false;
      } catch (err) {
        this.$emit('deleted', 0);
        this.showErrorMsg('Couldn\'t delete this episode from your library. Please try again.');
        log.error(err.message);
      }
      this.isRemovingBookmark = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../assets/scss/variables.scss";

.bookmark-cta {
  display: flex;
  align-items: center;
  font-family: $clear-sans;
  img, span {
    cursor: pointer;
  }
  span:hover {
    text-decoration: underline;
  }
  .processing {
    width: 150px;
    display: flex;
    align-items: center;
    color: $blue;
    font-size: 16px;
    i {
      font-size: 24px;
      margin-right: 12px;
      color: $blue;
    }
  }
  span:not(.processing) {
    margin-left: 12px;
    color: $blue;
  }
  div, svg {
    width: 24px;
    height: 24px;
  }
}

// position bookmark prop styling
.left {
  justify-content: left;
}
.center {
  justify-content: center;
}
.right {
  justify-content: right;
}

</style>
