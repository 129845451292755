<template>
  <div
    class="podcast-episodes"
    data-test="podcast-episodes">
    <div
      v-if="isLoggedIn">
      <loader
        v-show="isLoadingInitial"
        center
        label="Fetching Podcasts Episodes ..."/>
      <div class="container">
        <library-menu
          :display-list="true"
          text="Latest Episodes"/>
      </div>
      <!-- Empty Shows Box -->
      <empty-shows
        v-show="!totalItems && !isLoadingInitial"
        option="podcast-episodes"/>

      <!-- Display Podcasts Episodes-->
      <div
        v-if="totalItems">
        <card-list-wrapper
          :episodes="formattedItems"
          :bucket="{
            bucket_uuid: null,
            name: 'Latest Episodes',
            bucket_type:'episodes',
            position: 0,
            items_count: totalItems,
            media_size: 'episode_details'
          }"
          playlist-type="my-shows"
          data-test="latest-episodes"
        />
      </div>
      <loader
        v-show="isLoadingMore"
        :with-margin="true"
        label="Fetching Podcasts Episodes ..."
      />
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import kebabCase from 'lodash/kebabCase';
import PageMixin from '../../shared/pageMixin';

export default {
  name: 'Shows',
  components: {
    loader: () => import('../../components/Loader'),
    'card-list-wrapper': () => import('../../components/CardListWrapper'),
    'empty-shows': () => import('../../components/EmptyShows'),
    'library-menu': () => import('../../components/LibraryMenu'),
  },
  mixins: [PageMixin],
  data() {
    return {
      pageName: 'My Shows',
      scrollThreshold: 200,
    };
  },
  computed: {
    ...mapGetters({
      isLoggedIn: 'loggedIn',
      isLoaded: 'libraryv2/isPodcastEpisodesLoadingDone',
      isLoading: 'libraryv2/isPodcastEpisodesLoading',
      itemAllList: 'libraryv2/libraryAllPodcastEpisodeList',
      pager: 'libraryv2/podcastEpisodePager',
      totalItems: 'libraryv2/totalPodcastEpisodes',
    }),
    formattedItems() {
      return this.itemAllList.map(v => ({
        uuid: v.id,
        title: v.title,
        description: (v.description || '').replace(/<.*?>/g, ' '),
        display_image_url: v.images.default,
        media_url: v.mediaUrl,
        season: {
          number: v.season,
          podcast: {
            uuid: v.showId,
            title: v.showTitle,
            is_exclusive: v.isShowExclusive,
            slug: kebabCase(v.showTitle),
            publisher: {
              name: v.publisherName,
              slug: kebabCase(v.publisherName),
            },
          },
        },
        episode_number: v.number,
        is_exclusive: v.isExclusive,
        released_at: v.releasedAt * 1000,
        runtime: v.runtime,
        episode_type: v.type,
        slug: kebabCase(v.title),
      }));
    },
    isLoadingInitial() {
      return this.isLoading && !this.itemAllList.length;
    },
    isLoadingMore() {
      return this.isLoading && this.itemAllList.length;
    },
    isLastPage() {
      return this.pager && this.pager.currentPage === this.pager.totalPages;
    },
  },
  beforeDestroy() {
    this.unregisterInfiniteScrolling();
  },
  beforeMount() {
    if (this.isLoggedIn) {
      this.resetLibraryState();
      this.loadPodcastEpisodes(1).then(() => this.registerInfiniteScrolling());
    }
  },
  methods: {
    ...mapActions({
      loadPodcastEpisodes: 'libraryv2/loadPodcastEpisodes',
    }),
    ...mapMutations({
      resetLibraryState: 'libraryv2/resetLibraryState',
    }),
    registerInfiniteScrolling() {
      document.addEventListener('scroll', this.infiniteScrollCallback);
    },
    unregisterInfiniteScrolling() {
      document.removeEventListener('scroll', this.infiniteScrollCallback);
    },
    infiniteScrollCallback() {
      const { clientHeight, scrollTop, scrollHeight } = document.documentElement;
      if (scrollTop + clientHeight + this.scrollThreshold >= scrollHeight) {
        if (this.isLoaded && !this.isLastPage) {
          this.loadPodcastEpisodes(this.pager.currentPage + 1);
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../../assets/scss/variables.scss";

@media(max-width: 1024px) {
  .podcast-episodes {
    min-height: calc(100vh - 347px);
    .container {
      width: 100%;
      .top-bar {
        margin: 0 32px;
        padding-bottom: 13px;
      }
    }
  }
}
</style>
