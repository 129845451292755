<template>
  <a :href="url">
    <img
      :style="styleComputed"
      src="../assets/images/apple-podcasts.svg"
      alt="apple podcasts"
    />
  </a>
</template>
<script>
export default {
  name: 'ApplePodcastsLink',
  props: {
    imgStyles: {
      type: Object,
      default: () => ({}),
    },
    url: {
      type: String,
      default: 'https://podcasts.apple.com/us/channel/luminary/id6442463604?ls=1&at=1000l36wW&ct=luminary_podcasts&itscg=30200&itsct=website',
    },
  },
  computed: {
    styleComputed() {
      return { width: '240px', ...this.imgStyles };
    },
  },
};
</script>
