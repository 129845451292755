<template>
  <div class="podcast-cover">
    <div class="opacity" />
    <!-- Do not use Picture Component here because this is a background banner
    and picture component is not what we need here -->
    <img
      v-if="src"
      :src="src"
    >
  </div>
</template>

<script>
import PictureComponent from './PictureComponent';

export default {
  components: {
    PictureComponent,
  },
  props: {
    src: {
      type: String,
      default: () => '',
    },
  },
};
</script>

<style lang="scss" scoped>
.podcast-cover {
  width: 100%;
  height: 250px;

  position: absolute;
  left: 0;

  overflow: hidden;

  z-index: -1;

  img {
    object-fit: cover;
    width: 100%;

    position: static;
  }

  @media (max-width: 1023px) {
    height: 185px;
  }

  @media (max-width: 767px) {
    height: 100%;

    img {
      width: auto;
      height: 100%;
    }
  }

  .opacity {
    position: absolute;
    top: 0;
    left: 0;

    height: 100%;
    width: 100%;

    background-color: rgba(0, 0, 0, .80);
  }
}
</style>
